import { MuiThemeProvider } from '@material-ui/core';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import { Route, withRouter, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import PrivateRoute from 'Components/shared/PrivateRoute';
import ScreenLoader from 'Components/shared/ScreenLoader';
import { SnackbarProvider } from 'Components/shared/snackbarUtils';
import withUser from 'Components/withUser';
import createTheme, { SectionThemeConsumer, SectionThemeProvider } from 'Root/theme';

export const tracker = new Tracker({
  projectKey: "IgpWbJmkivvNk4LEANXv",
  ingestPoint: "https://openreplay.lenss.net/ingest",
  __DISABLE_SECURE_MODE: true
});

tracker.use(trackerAssist({
  callConfirm: true,
  controlConfirm: true
}));
const LandingPage = Loadable({
  loader: () => import('Components/Authentication/LandingPage'),
  loading: ScreenLoader
});

const Shell = Loadable({
  loader: () => import('./Shell'),
  loading: ScreenLoader
});

function App(props) {
  useEffect(() => {
    tracker.start().then(() => {
      console.log('OpenReplay started');
    });
  }, []);
  return (
    <SectionThemeProvider>
      <SectionThemeConsumer>
        {({ value: sectionTheme }) => (
          <MuiThemeProvider theme={createTheme(get(props.user, 'settings.theme'), sectionTheme)}>
            <SnackbarProvider>
              <Switch>
                <Route path={["/login", "/forgot-password", "/register"]} component={LandingPage} />
                <PrivateRoute path="/" component={Shell} />
              </Switch>
            </SnackbarProvider>
          </MuiThemeProvider>
        )}
      </SectionThemeConsumer>
    </SectionThemeProvider>
  );
}

App.propTypes = {
  user: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withUser(),
)(App);
