import * as Sentry from '@sentry/browser';
import { JsonServiceClient } from '@servicestack/client';
import 'Assets/sound/ding.wav';
import get from 'lodash/get';
import UserProvider from "Components/UserProvider";
import { createError } from 'Services/error/actions';
import { isCrappyBrowser } from './constants';
import eventEmitter from './events';
import { seClient } from './serverEventsClient';

const hostname = window && window.location && window.location.host || 'localhost:5000'; //eslint-disable-line
const protocol = window && window.location && window.location.protocol || 'http:'; //eslint-disable-line
export const API_URL = `${protocol}//${hostname}`;

const client = new JsonServiceClient(API_URL);
client.useTokenCookie = true;

const businessRuleErrorHandlers = {
  E1001: () => {
    eventEmitter.emit('access-denied');
  }
};

client.exceptionFilter = async (/** @type {Response} */ res, error) => {
    const { store } = require('Root/store/configureStore'); // eslint-disable-line

  if ((get(error, 'responseStatus.message') === 'You account is currently inactive or suspended.')) {
    await UserProvider.instance.logOff('You account is currently inactive or suspended.');
    return;
  }

  if (get(error, 'type') === 'RefreshTokenException' && get(error, 'responseStatus.message') === 'Token has expired') {
    await UserProvider.instance.logOff('Your session has expired, please log in again');
    return;
  }

  if (get(error, 'type') === 'RefreshTokenException') {
    await UserProvider.instance.logOff('There was a problem with your session, please log in again');
    return;
  }

  const userRequiresAuthentication = res && res.status === 401 && res.headers.has('WWW-Authenticate');
  const impersonatingUserTimedOut = userRequiresAuthentication &&
        UserProvider.instance.isImpersonating();

  if (impersonatingUserTimedOut) {
    await UserProvider.instance.stopImpersonatingUser();
    eventEmitter.emit('show-message', 'Impersonation time limit exceeded.');
    return;
  }

  const errorData = (get(error, 'responseStatus') || {});
  Sentry.addBreadcrumb({
    message: `Received exception from ${res?.url}`,
    category: 'client.error',
    data: errorData,
    type: 'http',
    level: Sentry.Severity.Error
  });

  Sentry.withScope(scope => {
    scope.setExtras(errorData);
    Sentry.captureException(
      Object.assign(
        new Error(get(error, 'responseStatus.message', 'Unspecified error')), {
          name: 'ClientRequestException'
        }
      )
    );
  });

  // If this was a BusinessRuleException
  if (error && error.responseStatus && error.responseStatus.message === 'An access violation has occurred. See the errors for details.') {
    const { responseStatus: { errorCode } } = error;
    const handler = businessRuleErrorHandlers[errorCode];
    if (handler) {
      handler();
      return;
    }
  }
  store.dispatch(createError(error));
};

client.requestFilter = (req) => {
  if (req.method === 'GET' && isCrappyBrowser()) {
    const url = new URL(req.url);
    url.searchParams.append('ts', new Date().getTime());
    req.url = url.href;
  }
};

client.responseFilter = (response) => {
  Sentry.addBreadcrumb({
    message: `Received client response from ${response.url}`,
    category: 'client.response',
    data: {
      status: response.status,
      statusText: response.statusText,
    },
    type: 'http'
  });

  // Reconnect if we had to refresh our JWT.
  // This happens when the browser opens and the SSE client tries to connect and gets a 401.
  if (response.url.includes('GetAccessToken') && seClient.eventSource.readyState === EventSource.CLOSED) {
    seClient.reconnectServerEvents();
  }
};

export const setToken = (bearerToken, refreshToken) => {
  client.bearerToken = bearerToken;
  client.refreshToken = refreshToken;
};

export default client;
