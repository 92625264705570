import DecodeJwt from 'jwt-decode';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import { tracker } from 'Components/App';
import {
  Authenticate,
  MarkNotificationRead,
  SearchNotifications,
  UpdateUser,
  GetBearerTokenRequest,
  GetAccessToken,
  GiveMeCookiesNomNomNom,
  GetUserSettings,
  UpdateUserSettings,
  UserSettings,
  StopImpersonation,
  GetUserSecret
} from 'Root/dtos.ts';
import { ConvertSessionToToken } from 'Root/servicestackdtos.ts';
import { history } from 'Root/store/configureStore';
import client, { setToken } from 'Services/client';
import LocalStorage from 'Services/customLocalStorage';
import eventEmitter from "Services/events";
import serverEventsClientInit, { seClient } from 'Services/serverEventsClient';

export const UserContext = React.createContext();

const initialState = {
  bearerToken: undefined,
  refreshToken: undefined,
  backupBearerToken: undefined,
  backupRefreshToken: undefined,
  currentlyImpersonating: false,
  impersonatingUserId: 0,
  roles: [],
  agencyId: null,
  permissions: [],
  accountStatus: null,
  email: '',
  firstName: '',
  lastName: '',
  hasPIN: true,
  hasPINSaved: false,
  userId: -1,
  loginError: null,
  notifications: [],
  unreadNotificationsCount: 0,
  stateUserName: '',
  statePasswordExpirationDate: '',
  fullUserLoaded: false,
  settings: new UserSettings({
    theme: 'light',
    saveDataTableFilters: true,
    defaultDashboardTab: 'Investigations',
    defaultMyRecordsTab: '/records/alert',
    defaultSystemAdminTab: '/system-management/agencies',
    defaultAgencyAdminTab: '/agency-management/agency-settings'
  })
};

export default class UserProvider extends React.Component {
  // This is assigned when the component mounts and is available globally via Shell.instance
  static instance = null;

  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  constructor() {
    super();
    UserProvider.instance = this;
  }

  state = {
    user: (() => {
      try {
        const user = JSON.parse(localStorage.getItem('user')) || {};
        user.settings = { ...initialState.settings, ...user.settings };
        return user;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn("Error loading user from local storage.", e);
        return {};
      }
    })()
  };

  componentDidMount() {
    if (this.state.user.bearerToken) {
      setToken(null, this.state.user.refreshToken);
    }
  }

  saveUser = (user) => new Promise(resolve => this.setState({
    user: {
      ...this.state.user,
      ...user
    }
  }, () => {
    localStorage.setItem('user', JSON.stringify(this.state.user));
    resolve();
  }));

  acceptTOS = async () => {
    this.saveUser({
      requiresLatestTermsOfServiceAcceptance: false
    });
  };

  getNotifications = async () => {
    const search = new SearchNotifications();
    search.take = 10;
    search.read = false;
    search.orderByDesc = 'createdDate';
    search.include = "COUNT(*)";
    const data = await client.get(search);

    this.saveUser({
      notifications: data.results,
      unreadNotificationsCount: data.total
    });
  };

  markNotificationRead = async (id) => {
    const markRead = new MarkNotificationRead();
    markRead.id = id;
    await client.put(markRead);

    const notifications = cloneDeep(this.state.user.notifications);
    let { unreadNotificationsCount } = this.state.user;
    const notification = notifications.find(x => x.id === id);
    // If the notification exists and is not read, mark it as read and decrease the unread count
    if (notification && !notification.read) {
      notification.read = true;
      unreadNotificationsCount -= 1;
    }

    this.saveUser({
      notifications,
      unreadNotificationsCount
    });
  };

  notificationReceived = (msg) => {
    this.saveUser({
      // we only want 10 in our top bar
      notifications: [msg, ...this.state.user.notifications].slice(0, 10),
      unreadNotificationsCount: this.state.user.unreadNotificationsCount + 1
    });
  };

  updateUser = async (user) => {
    const update = new UpdateUser();
    Object.assign(update, user);
    await client.put(update);

    if (user.id === this.state.user.userId) {
      this.saveUser({
        firstName: user.firstName,
        lastName: user.lastName
      });
    }
  };

  loadUserSettings = async () => {
    const getSettingsRequest = new GetUserSettings();
    const settings = await client.get(getSettingsRequest);
    await this.saveUserSettings(settings);
  };

  updateUserSettings = async (settings = this.state.user.settings) => {
    const updateSettings = new UpdateUserSettings({ settings });
    await client.put(updateSettings);
  }

  saveUserSettings = async (settings) => {
    await this.saveUser({
      settings: {
        ...this.state.user.settings,
        ...settings,
      }
    });
    await this.updateUserSettings(this.state.user.settings);
  }

  logIn = async (email, password, moreParams) => {
    const request = new Authenticate();
    request.userName = email;
    request.password = password;
    request.useTokenCookie = true;
    Object.assign(request, moreParams);
    try {
      const data = await client.post(request);

      const decodedJwt = DecodeJwt(data.bearerToken);
      const decodedRefresh = DecodeJwt(data.refreshToken);

      const jwt = this.convertJwt(decodedJwt);
      jwt.exp = new Date(decodedRefresh.exp * 1000);
      // sets the token on the client
      setToken(null, data.refreshToken);

      await client.get(new ConvertSessionToToken());
      serverEventsClientInit();
      localStorage.setItem('lastActive', new Date());
      localStorage.setItem('sessionLocked', false);
      tracker.setUserID(email);
      return this.saveUser({
        ...jwt,
        bearerToken: data.bearerToken,
        refreshToken: data.refreshToken
      });
    } catch (error) {
      await this.saveUser({
        loginError: error,
      });
      throw error;
    }
  };

  convertJwt = ({
    sub,
    email,
    given_name, // eslint-disable-line
    family_name, // eslint-disable-line
    roles,
    perms,
    AgencyId,
    StateId,
    Features,
    RequiresLatestTermsOfServiceAcceptance,
    Timezone,
    StateAbbreviation,
    CurrentlyImpersonating,
    RememberMe,
    StateUserName,
    SecretKey,
    DaysRemainingForPasswordExpiry,
    HasPIN,
    exp
  }) => ({
    userId: +sub,
    email,
    firstName: given_name,
    lastName: family_name,
    roles: roles || [],
    permissions: perms || [],
    agencyId: AgencyId,
    stateId: StateId,
    stateAbbreviation: StateAbbreviation,
    features: Features,
    requiresLatestTermsOfServiceAcceptance: RequiresLatestTermsOfServiceAcceptance === 'True',
    timezone: Timezone,
    currentlyImpersonating: CurrentlyImpersonating === 'True',
    rememberMe: RememberMe === 'True',
    stateUserName: StateUserName,
    daysRemainingForPasswordExpiry: DaysRemainingForPasswordExpiry,
    hasPIN: HasPIN === 'True',
    exp: new Date(exp * 1000),
    /**
     * On successfull login save the `secretKey` to validate the session.
     * And whenever user get updated(on PermissionsUpdate) and new token get created
     * Check if we get `secretKey` else assign existing `secretKey`.
     * As on new session(user login from different machine) it will validate the secretKey,
     * So there is no harm to assign existing `secretKey`.
     */
    secretKey: SecretKey || JSON.parse(localStorage.getItem('user'))?.secretKey
  })

  /**
   * This primarily gets called by serverEventsClient.js in the event that an Admin or Agency
   * Admin changes a users settings / permissions, he triggers a SSE "UserUpdatedMessage"
   *
   * Once the event is received from SSE, we call our endpoint to set a ss-tok, and return
   * the new bearer/refresh token.
   * @returns {Promise<void>}
   */
  refreshJwt = async () => {
    const req = new GiveMeCookiesNomNomNom();
    client.manageCookies = true;
    const resp = await client.get(req);
    const jwt = DecodeJwt(resp.bearerToken);
    const refresh = DecodeJwt(resp.refreshToken);
    const converted = this.convertJwt(jwt);
    jwt.exp = new Date(refresh.exp * 1000);
    await this.saveUser(converted);
    setToken(null, resp.refreshToken);
  }

  /**
   * Internally used to "switch" users (impersonation)
   * @param userId
   */
  changeUsers = (userId) => client.get(new GetBearerTokenRequest({ userId }))

  /**
   * Used to get a new bearerToken, which also calls changeUser to set the ss-tok.
   * @param userId
   * @returns {Promise<*>}
   */
  impersonateUser = async (userId) => {
    try {
      const resp = await this.changeUsers(userId);
      const newToken = this.convertJwt(DecodeJwt(resp.bearerToken));

      if (!newToken.permissions && !newToken.roles) {
        throw new Error('User has no roles or permissions');
      }

      this.saveUser({
        ...newToken,
        impersonatingUserId: +newToken.userId,
        bearerToken: resp.bearerToken,
        refreshToken: resp.refreshToken,
        backupBearerToken: this.state.user.bearerToken,
        backupRefreshToken: this.state.user.refreshToken,
        currentlyImpersonating: true
      });

      setToken(null, resp.refreshToken);
      seClient.reconnectServerEvents();
      eventEmitter.emit('changed-users');
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  isImpersonating = () => this.state.user.currentlyImpersonating;

  /**
   * Used to stop impersonating a user. We first use the "backupRefreshToken"
   * (which is the Admins) to generate a new bearerToken.
   *
   * Then we parse it and set the user state (roles, perms, sub, etc...)
   * @returns {Promise<void>}
   */
  stopImpersonatingUser = async () => {
    client.bearerToken = null;
    await client.post(new GetAccessToken({
      refreshToken: this.state.user.backupRefreshToken,
      useTokenCookie: true
    }));

    if (this.state.user.impersonatingUserId) {
      client.post(new StopImpersonation({ userId: this.state.user.impersonatingUserId }));
    }

    const newJwt = this.convertJwt(DecodeJwt(this.state.user.backupBearerToken));

    const newUser = {
      ...newJwt,
      currentlyImpersonating: false,
      impersonatingUserId: 0,
      backupBearerToken: undefined,
      backupRefreshToken: undefined,
      bearerToken: this.state.user.backupBearerToken,
      refreshToken: this.state.user.backupRefreshToken
    };

    setToken(null, this.state.user.backupRefreshToken);
    seClient.reconnectServerEvents();
    this.saveUser(newUser);
    eventEmitter.emit('changed-users');
  }

  logOff = async (message, mobileOrAutoLogout = false) => {
    const request = new Authenticate();
    Object.assign(request, {
      provider: 'logout',
      meta: {
        mobileOrAutoLogout
      }
    });
    await client.post(request);
    this.clearAnnouncementInterval();
    const stateHostCheckInterval = localStorage.getItem('checkStateHostInterval');

    if (stateHostCheckInterval) { clearInterval(stateHostCheckInterval); }

    localStorage.removeItem('checkStateHostInterval');

    setToken(null, null);
    localStorage.removeItem('form');
    seClient.stop();
    history.push('/login', { message });
    this.saveUser(cloneDeep(initialState));
  };

  clearAnnouncementInterval = () => {
    const { user } = this.state;
    if (!user) return;
    const announcementCheckInterval = LocalStorage.getObj(`AnnouncementStatus-${user.userId}`);
    if (announcementCheckInterval) {
      clearInterval(announcementCheckInterval);
    }
  }

  validateSecretKey = async (userId) => {
    const request = new GetUserSecret({
      userId
    });

    const { secretKey } = await client.get(request);
    const user = JSON.parse(localStorage.getItem('user')) || {};
    if (secretKey && user?.secretKey) {
      return (user.secretKey === secretKey);
    }
    return false;
  };

  /*
     *This will get removed once we test the
     * Single session functionality on the server.
     * If it works with serverEvents.
     * We don't want this method.
     */

  logOffUser = async (userId) => {
    const valid = await this.validateSecretKey(userId);
    if (!valid) {
      this.logOff("Session expired");
    }
  }

  logOffUserOnInvalidSecretKey = (secretKey) => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    if (user?.secretKey !== secretKey) {
      this.logOff("Session expired", true);
    }
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state.user,
          acceptTOS: this.acceptTOS,
          getNotifications: this.getNotifications,
          markNotificationRead: this.markNotificationRead,
          notificationReceived: this.notificationReceived,
          updateUser: this.updateUser,
          saveUser: this.saveUser,
          updateUserSettings: this.updateUserSettings,
          saveUserSettings: this.saveUserSettings,
          loadUserSettings: this.loadUserSettings,
          impersonateUser: this.impersonateUser,
          stopImpersonatingUser: this.stopImpersonatingUser,
          logIn: this.logIn,
          logOff: this.logOff,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
