import createHistory from 'history/createBrowserHistory';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import * as rootReducer from 'Services/rootReducer';

export const history = createHistory();

const persistConfig = {
  key: 'principal',
  storage,
  blacklist: ['form']
};

const reducers = { ...rootReducer };

export const store = createStore(
  persistCombineReducers(persistConfig, reducers),
  undefined,
  compose(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);
