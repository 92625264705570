import { CircularProgress } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';

function ScreenLoader(props) {
  const { isLoading, pastDelay } = props; // eslint-disable-line

  return isLoading && pastDelay && <Grid container justify='center' alignItems='center' alignContent='center' direction='column'><Grid item><CircularProgress size={100} /></Grid></Grid>; // eslint-disable-line
}

export default ScreenLoader;
