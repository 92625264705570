import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import moment from "moment";
import { GetEnvironment } from "./dtos";
import client from "./services/client";
import environment from "./services/environment";


/**
 * This handler ensures that the website does a full reload when
 * the javascript files get updated on the server, but the client
 * does not have all of the scripts downloaded already.
 * Because we lazy load some modules, the script bundle behind that module
 * may be gone between the time that the user initially loads the index page
 * and the time that they finally navigate to a part of the app that requires
 * the lazy loaded module. To see this in action take the following steps:
 * 1. Build the client for production.
 *    Your scripts should have hashes in their names under wwwroot/dist/.
 * 2. Start the Web API project in production mode that way it doesn't
 *    serve assets from the webpack middleware.
 * 3. Load the index page in your browser.
 * 4. Without closing your browser tab, stop the app,
 *    build the assets again to produce a new hash and start the app back up.
 * 5. In your browser tab that you've used before,
 *    navigate to a different module. This could be the agency admin section.
 */
const MIN_TIME_BETWEEN_RELOADS = 10;

const getLatestVersion = async () => {
  const env = await client.get(new GetEnvironment());
  return env.version;
};

const isCurrentVersion = async () => environment.version === (await getLatestVersion());

const setLastReloadTime = (date = new Date()) => {
  if (date.getTime) {
    localStorage.setItem('lastReloadTime', date.getTime());
  } else {
    Sentry.captureMessage(`Passed date ${date}`);
    Sentry.captureException(`The date '${date}' was not a valid date.`);
  }
};

const getLastReloadTime = () => {
  const lastReloadTime = Number.parseInt(localStorage.getItem('lastReloadTime'), 10);
  if (!!lastReloadTime || Number.isNaN(lastReloadTime)) {
    return null;
  }
  return new Date(lastReloadTime);
};

const canSafelyReload = () => {
  const lastReloadTime = getLastReloadTime();
  if (!lastReloadTime) {
    return true;
  }
  // We can force reload the user if it's been
  // more than a minute since the last time we reloaded them.
  // This is to prevent reload cycles which can be catastrophic
  return moment.now().isAfter(moment(lastReloadTime).add(MIN_TIME_BETWEEN_RELOADS, 'minute'));
};

const reloadIfNeeded = async () => {
  const isCurrent = await isCurrentVersion();
  if (!isCurrent && canSafelyReload()) {
    Sentry.captureMessage('Forcing reload since app update was detected.');
    setLastReloadTime();
    window.location.reload(true);
  }
};

export const ensureFailedChunksForcesAReload = () => {
  window.addEventListener('unhandledrejection', e => {
    if (get(e, 'reason.name') === 'ChunkLoadError') {
      reloadIfNeeded();
    }
  });
};

export default {
  ensureFailedChunksForcesAReload
};
