import 'Assets/img/logo_new.png';
import MomentUtils from '@date-io/moment';
import { Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import * as React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import svg4everybody from 'svg4everybody';
import UserProvider from 'Components/UserProvider';
import './app.scss';
import { ensureFailedChunksForcesAReload } from './chunkLoadErrorHandler';
import App from './components/App';
import { loadEnvironment } from './services/environment';
import { persistor, store } from './store/configureStore';
import 'Services/testChatService';

svg4everybody();
ensureFailedChunksForcesAReload();

function renderApp(Component) {
  const appElement = document.getElementById('app');

  if (!appElement) {
    return;
  }

  const appContainer = (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AppContainer>
        <UserProvider>
          <Provider store={store}>
            <PersistGate loading={<Typography>Loading...</Typography>} persistor={persistor}>
              <Router>
                <Component />
              </Router>
            </PersistGate>
          </Provider>
        </UserProvider>
      </AppContainer>
    </MuiPickersUtilsProvider>
  );

  render(appContainer, appElement);
}

renderApp(App);
loadEnvironment().then(env =>

  Sentry.init({
    dsn: env.sentryDsn,
    release: env.version,
    environment: env.environmentName || 'Production',
    beforeSend: e => {
      if (!e.extra) {
        e.extra = {};
      }
      e.extra['Application'] = 'LENSS Browser Client';

      // Ensure we don't send errors that occur during the TFA auth flow
      if (get(e, 'exception.values', []).find(x =>
        get(x, 'value', '').includes('Not Authenticated'))) {
        return null;
      }

      return e;
    }
  }));

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const nextApp = require('./components/App').default; // eslint-disable-line global-require
    renderApp(nextApp);
  });

  module.hot.addStatusHandler(status => {
    if (status === 'prepare') console.clear(); // eslint-disable-line no-console
  });
}

// if (process.env.NODE_ENV === 'production') {
//   registerServiceWorker();
// }
