/* eslint-disable */

/*
THIS FILE WAS GENERATED BY THE API.
DO NOT MAKE ANY CHANGES HERE MANUALLY BECAUSE THEY WILL BE OVERWRITTEN.
To GENERATE THIS FILE TYPE `npm run generate-ts-refs` INTO YOUR TERMINAL
SEE LENSSX.Logic.Plugins.EnumGeneratorPlugin
*/

export const AgencyTypeEnum = {
    "Admin": {
        "allowDAAssociation": false,
        "availableRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor",
            "Jailer"
        ],
        "canSetDivisionsAndAreas": false,
        "id": 1,
        "name": "Admin",
        "shortCode": "PI"
    },
    "LawEnforcement": {
        "allowDAAssociation": true,
        "availableRoles": [
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "Supervisor",
            "Jailer"
        ],
        "canSetDivisionsAndAreas": true,
        "id": 2,
        "name": "Law Enforcement",
        "shortCode": "LE"
    },
    "DistrictAttorney": {
        "allowDAAssociation": false,
        "availableRoles": [
            "District Attorney",
            "Agency Admin"
        ],
        "canSetDivisionsAndAreas": false,
        "id": 3,
        "name": "District Attorney",
        "shortCode": "DA"
    },
    "StatePolice": {
        "allowDAAssociation": true,
        "availableRoles": [
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "Supervisor",
            "Jailer"
        ],
        "canSetDivisionsAndAreas": true,
        "id": 4,
        "name": "State Police",
        "shortCode": "SP"
    }
};

export const AccountStateEnum = {
    "Active": {
        "id": 0,
        "name": "Active"
    },
    "PendingActivation": {
        "id": 1,
        "name": "Pending Activation"
    },
    "Inactive": {
        "id": 2,
        "name": "Inactive"
    },
    "Suspended": {
        "id": 3,
        "name": "Suspended"
    }
};

export const DispositionTypeEnum = {
    "Seizure": {
        "abbreviation": "SEZ",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-danger",
        "dangerRank": 2,
        "id": 1,
        "name": "Seizure",
        "requiresAdditionalInfo": true,
        "visible": false
    },
    "FieldInterview": {
        "abbreviation": "FIC",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-primary",
        "dangerRank": 0,
        "id": 2,
        "name": "FI Card",
        "requiresAdditionalInfo": true,
        "requiresFeature": "Intelligence",
        "visible": true
    },
    "IntelReport": {
        "abbreviation": "IR",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-primary",
        "dangerRank": 1,
        "id": 3,
        "name": "Intel Report",
        "requiresAdditionalInfo": true,
        "requiresFeature": "Intelligence",
        "visible": false
    },
    "DWI": {
        "abbreviation": "DWI",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-danger",
        "dangerRank": 3,
        "id": 4,
        "name": "DWI",
        "requiresAdditionalInfo": false,
        "requiresFeature": "",
        "visible": false
    },
    "Ticket": {
        "abbreviation": "TIC",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-primary",
        "dangerRank": 2,
        "id": 5,
        "name": "Ticket",
        "requiresAdditionalInfo": false,
        "requiresFeature": "",
        "visible": true
    },
    "Arrest": {
        "abbreviation": "ARR",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-warning",
        "dangerRank": 4,
        "id": 6,
        "name": "Arrest",
        "requiresAdditionalInfo": false,
        "requiresFeature": "",
        "visible": true
    },
    "InmateIn": {
        "abbreviation": "INI",
        "availableForRoles": [
            "Jailer"
        ],
        "badge": "badge-warning",
        "dangerRank": 4,
        "id": 7,
        "name": "Inmate In",
        "requiresAdditionalInfo": false,
        "visible": false
    },
    "InmateOut": {
        "abbreviation": "INO",
        "availableForRoles": [
            "Jailer"
        ],
        "badge": "badge-warning",
        "dangerRank": 4,
        "id": 8,
        "name": "Inmate Out",
        "requiresAdditionalInfo": false,
        "visible": false
    },
    "Packet": {
        "abbreviation": "PAC",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor",
            "Jailer"
        ],
        "badge": "badge-warning",
        "dangerRank": 4,
        "id": 9,
        "name": "Packet",
        "requiresAdditionalInfo": false,
        "visible": true
    },
    "Warning": {
        "abbreviation": "WAR",
        "availableForRoles": [
            "Admin",
            "Agency Admin",
            "Officer",
            "Detective",
            "Analyst",
            "Dispatcher",
            "District Attorney",
            "Supervisor"
        ],
        "badge": "badge-warning",
        "dangerRank": 4,
        "id": 10,
        "name": "Warning",
        "requiresAdditionalInfo": false,
        "requiresFeature": "",
        "visible": true
    }
};

export const EntityTypeEnum = {
    "SubjectPacket": {
        "id": 0,
        "name": "Subject Packet"
    },
    "Intel": {
        "id": 1,
        "name": "Intelligence"
    },
    "FICard": {
        "id": 2,
        "name": "Field Interview Card"
    },
    "Seizure": {
        "id": 3,
        "name": "Seizure"
    },
    "Currency": {
        "id": 4,
        "name": "Currency"
    },
    "PhotoLineUp": {
        "id": 5,
        "name": "PhotoLineUp"
    },
    "Alert": {
        "id": 6,
        "name": "Alert"
    }
};

export const EyeColorCodeEnum = {
    "Black": {
        "code": "BLK",
        "id": "BLK",
        "name": "Black"
    },
    "Blue": {
        "code": "BLU",
        "id": "BLU",
        "name": "Blue"
    },
    "Brown": {
        "code": "BRN",
        "id": "BRN",
        "name": "Brown"
    },
    "Green": {
        "code": "GRN",
        "id": "GRN",
        "name": "Green"
    },
    "Gray": {
        "code": "GRY",
        "id": "GRY",
        "name": "Gray"
    },
    "Hazel": {
        "code": "HAZ",
        "id": "HAZ",
        "name": "Hazel"
    },
    "Maroon": {
        "code": "MAR",
        "id": "MAR",
        "name": "Maroon"
    },
    "Multicolored": {
        "code": "MUL",
        "id": "MUL",
        "name": "Multicolored"
    },
    "Pink": {
        "code": "PNK",
        "id": "PNK",
        "name": "Pink"
    },
    "Unknown": {
        "code": "XXX",
        "id": "XXX",
        "name": "Unknown"
    }
};

export const PrimaryEntityTypeEnum = {
    "Packet": {
        "alternateTitleTemplate": "A LENSS Subject Packet with a criminal classification assigned to your Division was {Action}",
        "canShareWithLegacyAgency": false,
        "canShareWithLegacyUser": false,
        "id": 2,
        "longTitleTemplate": "A LENSS Subject Packet you {Owned:own|are subscribed to} was {Action}",
        "name": "SubjectPacket",
        "shareTitleTemplate": "A LENSS Subject Packet was shared with you",
        "shortTitleTemplate": "LENSS Subject Packet - {Owned:Owned|Shared} - {Action}",
        "shortUrlPath": "p"
    },
    "Intel": {
        "alternateTitleTemplate": "",
        "canShareWithLegacyAgency": true,
        "canShareWithLegacyUser": false,
        "id": 1,
        "longTitleTemplate": "A LENSS {IntelType} you {Owned:own|are subscribed to} was {Action}",
        "name": "Intel",
        "shareTitleTemplate": "A LENSS {IntelType} was shared with you",
        "shortTitleTemplate": "LENSS {IntelType} - {Owned:Owned|Shared} - {Action}",
        "shortUrlPath": "i"
    },
    "Seizure": {
        "alternateTitleTemplate": "",
        "canShareWithLegacyAgency": true,
        "canShareWithLegacyUser": true,
        "id": 3,
        "longTitleTemplate": "",
        "name": "Seizure",
        "shareTitleTemplate": "",
        "shortTitleTemplate": "",
        "shortUrlPath": ""
    },
    "Alert": {
        "alternateTitleTemplate": "",
        "canShareWithLegacyAgency": true,
        "canShareWithLegacyUser": false,
        "id": 4,
        "longTitleTemplate": "A LENSS Investigative Alert you {Owned:own|are subscribed to} was {Action}",
        "name": "Alert",
        "shareTitleTemplate": "A LENSS Investigative Alert was shared with you",
        "shortTitleTemplate": "LENSS Investigative Alert - {Owned:Owned|Shared} - {Action}",
        "shortUrlPath": "a"
    },
    "Investigation": {
        "alternateTitleTemplate": "",
        "canShareWithLegacyAgency": false,
        "canShareWithLegacyUser": false,
        "id": 5,
        "longTitleTemplate": "A LENSS Investigation you {Owned:own|are subscribed to} was {Action}",
        "name": "Investigation",
        "shareTitleTemplate": "A LENSS Investigation was shared with you",
        "shortTitleTemplate": "LENSS Investigation - {Owned:Owned|Shared} - {Action}",
        "shortUrlPath": "in"
    },
    "Currency": {
        "alternateTitleTemplate": "",
        "canShareWithLegacyAgency": false,
        "canShareWithLegacyUser": false,
        "id": 6,
        "longTitleTemplate": "A LENSS Currency Batch you {Owned:own|are subscribed to} was {Action}",
        "name": "Currency",
        "shareTitleTemplate": "A LENSS Currency Batch was shared with you",
        "shortTitleTemplate": "LENSS Currency Batch - {Owned:Owned|Shared} - {Action}",
        "shortUrlPath": "c"
    },
    "PhotoLineup": {
        "alternateTitleTemplate": "",
        "canShareWithLegacyAgency": true,
        "canShareWithLegacyUser": false,
        "id": 7,
        "longTitleTemplate": "A LENSS PhotoLineup you {Owned:own|are subscribed to} was {Action}",
        "name": "PhotoLineup",
        "shareTitleTemplate": "A LENSS PhotoLineup was shared with you",
        "shortTitleTemplate": "LENSS PhotoLineup - {Owned:Owned|Shared} - {Action}",
        "shortUrlPath": "photo"
    }
};

export const InvestigationTypeEnum = {
    "InvestigativeDetention": {
        "contextualMenuLink": "Investigative <strong>D</strong>etention",
        "id": 2,
        "isBackground": false,
        "name": "Investigative Detention"
    },
    "InvestigativeQuery": {
        "contextualMenuLink": "Investigative <strong>Q</strong>uery",
        "id": 3,
        "isBackground": false,
        "name": "Investigative Query"
    },
    "TrafficStop": {
        "contextualMenuLink": "Traffic <strong>S</strong>top",
        "id": 4,
        "isBackground": false,
        "name": "Traffic Stop"
    },
    "TrafficAccident": {
        "contextualMenuLink": "Traffic <strong>A</strong>ccident",
        "id": 5,
        "isBackground": false,
        "name": "Traffic Accident"
    },
    "BackgroundCreatePacketIntraAgency": {
        "contextualMenuLink": "",
        "id": 6,
        "isBackground": true,
        "name": "Background - Create Packet - Intra Agency"
    },
    "BackgroundCreatePacketInterAgency": {
        "contextualMenuLink": "",
        "id": 10,
        "isBackground": true,
        "name": "Background - Create Packet - Inter Agency"
    },
    "BackgroundCreateCurrency": {
        "contextualMenuLink": "",
        "id": 7,
        "isBackground": true,
        "name": "Background - Create Currency"
    },
    "CallForService": {
        "contextualMenuLink": "<strong>C</strong>all for Service",
        "id": 8,
        "isBackground": false,
        "name": "Call for Service"
    },
    "InmateEvent": {
        "contextualMenuLink": "<strong>I</strong>nmate Query",
        "id": 9,
        "isBackground": false,
        "name": "Inmate Query"
    },
    "ExternalEvent": {
        "contextualMenuLink": "",
        "id": 11,
        "isBackground": false,
        "name": "External Query"
    },
    "QuickTag": {
        "contextualMenuLink": "",
        "id": 12,
        "isBackground": false,
        "name": "Quick Tag"
    },
    "PrivateQuery": {
        "contextualMenuLink": "",
        "id": 13,
        "isBackground": false,
        "name": "Private Query"
    }
};

export const FeatureEnum = {
    "AgencyAdmin": {
        "enabledByDefault": true,
        "field": "AgencyAdminFeatureEnabled",
        "id": "Agency Admin",
        "name": "Agency Admin",
        "permissions": [
            "Generate My Agency's Reports",
            "Manage My Agency's Alternate Notifications",
            "Manage My Agency's Divisions",
            "Manage My Agency's Roles",
            "Manage My Agency's User Permissions",
            "Manage My Agency's Users",
            "Update My Agency's User Profiles",
            "Update My Agency's Settings"
        ],
        "visible": true
    },
    "Child": {
        "enabledByDefault": false,
        "field": "ChildFeatureEnabled",
        "id": "Parent Notification",
        "name": "Parent Notification",
        "permissions": [
            
        ],
        "visible": false
    },
    "Currency": {
        "enabledByDefault": false,
        "field": "MoneyBatchFeatureEnabled",
        "id": "Currency",
        "name": "Currency",
        "permissions": [
            "Create Currency Batches",
            "Reassign Currency Batches"
        ],
        "visible": true
    },
    "CurrencyOnlyAgency": {
        "enabledByDefault": false,
        "field": "CurrencyAgencyEnabled",
        "id": "Currency Only Agency",
        "name": "Currency Only Agency",
        "permissions": [
            "Create Currency Batches",
            "Reassign Currency Batches",
            "Create New Investigations",
            "Investigative Query",
            "View Incomplete Investigations Report"
        ],
        "visible": true
    },
    "DispatcherLiveLink": {
        "enabledByDefault": false,
        "field": "DispatcherLiveLinkFeatureEnabled",
        "id": "Dispatcher Live Link",
        "name": "Dispatcher Live Link",
        "permissions": [
            "Create New Investigations for Others"
        ],
        "visible": true
    },
    "FacialRecognition": {
        "enabledByDefault": false,
        "field": "FacialRecognitionEnabled",
        "id": "Facial Recognition",
        "name": "Facial Recognition",
        "permissions": [
            "Query Faces"
        ],
        "visible": true
    },
    "GlobalSearch": {
        "enabledByDefault": false,
        "field": "GlobalSearchFeatureEnabled",
        "id": "Global Search",
        "name": "Global Search",
        "permissions": [
            "Search My Agency's Records",
            "Search All Records"
        ],
        "visible": true
    },
    "InstantMessaging": {
        "enabledByDefault": false,
        "field": "InstantMessagingEnabled",
        "id": "Instant Messaging",
        "name": "Instant Messaging",
        "permissions": [
            "Start Conversations",
            "Message Other Users"
        ],
        "visible": true
    },
    "Intelligence": {
        "enabledByDefault": false,
        "field": "IntelFeatureEnabled",
        "id": "Intelligence",
        "name": "Intelligence",
        "permissions": [
            "Create Intelligence",
            "Reassign Intelligence"
        ],
        "visible": true
    },
    "Investigations": {
        "enabledByDefault": false,
        "field": "InvestigationFeatureEnabled",
        "id": "Investigations",
        "name": "Investigations",
        "permissions": [
            "Create New Investigations",
            "Call for Service",
            "Investigative Detention",
            "Investigative Query",
            "Traffic Stop",
            "Traffic Accident",
            "Quick Tag",
            "Private Query",
            "View Incomplete Investigations Report",
            "Complete Investigation Without Notes",
            "Run FBI Query"
        ],
        "visible": true
    },
    "InvestigativeAlerts": {
        "enabledByDefault": false,
        "field": "InvestigativeAlertsFeatureEnabled",
        "id": "Investigative Alerts",
        "name": "Investigative Alerts",
        "permissions": [
            "Create Investigative Alert",
            "Reassign Investigative Alert"
        ],
        "visible": true
    },
    "LPR": {
        "enabledByDefault": false,
        "field": "LPREnabled",
        "id": "LPR",
        "name": "LPR",
        "permissions": [
            "Activate License Plate Reader"
        ],
        "visible": true
    },
    "StateSwitch": {
        "enabledByDefault": false,
        "field": "StateSwitchEnabled",
        "id": "State Switch",
        "name": "State Switch",
        "permissions": [
            "Manage My Agency's ORI Assignments",
            "Perform NCIC Queries",
            "Perform Employment Queries"
        ],
        "visible": true
    },
    "SubjectPackets": {
        "enabledByDefault": false,
        "field": "SubjectPacketFeatureEnabled",
        "id": "Subject Packets",
        "name": "Subject Packets",
        "permissions": [
            "Create Subject Packets",
            "Deactivate Subject Packets",
            "Reassign Subject Packets"
        ],
        "visible": true
    },
    "SystemAdmin": {
        "enabledByDefault": false,
        "field": "SystemAdminFeatureEnabled",
        "id": "System Admin",
        "name": "System Admin",
        "permissions": [
            "Create System Admins",
            "Impersonate Users",
            "Manage Agencies",
            "Manage System Roles",
            "Manage Other Agency's User Permissions",
            "Manage Other Agency's Users",
            "Update Other Agency's Divisions",
            "Manage Other Agency’s ORI Assignments"
        ],
        "visible": true
    },
    "VoiceReadout": {
        "enabledByDefault": false,
        "field": "VoiceReadoutEnabled",
        "id": "Voice Readout",
        "name": "Voice Readout",
        "permissions": [
            "Have Query Returns Read Out"
        ],
        "visible": true
    },
    "PhotoLineup": {
        "enabledByDefault": false,
        "field": "PhotoLineupFeatureEnabled",
        "id": "Photo Lineup",
        "name": "Photo Lineup",
        "permissions": [
            "Create PhotoLineup"
        ],
        "visible": true
    },
    "Monitoring": {
        "enabledByDefault": false,
        "field": "MonitoringFeatureEnabled",
        "id": "LPR Monitoring",
        "name": "LPR Monitoring",
        "permissions": [
            "LPR Monitoring"
        ],
        "visible": true
    },
    "IPFilter": {
        "enabledByDefault": false,
        "field": "IPFilterFeatureEnabled",
        "id": "IP Address Filter",
        "name": "IP Address Filter",
        "permissions": [
            "Generate My Agency's Reports",
            "Manage My Agency's Alternate Notifications",
            "Manage My Agency's Divisions",
            "Manage My Agency's Roles",
            "Manage My Agency's User Permissions",
            "Manage My Agency's Users",
            "Update My Agency's User Profiles",
            "Update My Agency's Settings"
        ],
        "visible": true
    },
    "AllowFloatingOri": {
        "enabledByDefault": false,
        "field": "AllowFloatingOriFeatureEnabled",
        "id": "Floating Ori",
        "name": "Floating Ori",
        "permissions": [
            "Generate My Agency's Reports",
            "Manage My Agency's Alternate Notifications",
            "Manage My Agency's Divisions",
            "Manage My Agency's Roles",
            "Manage My Agency's User Permissions",
            "Manage My Agency's Users",
            "Update My Agency's User Profiles",
            "Update My Agency's Settings"
        ],
        "visible": true
    },
    "ImportSubjectPacketOrIntel": {
        "enabledByDefault": false,
        "field": "ImportSubjectPacketOrIntelFeatureEnabled",
        "id": "Import Subject Packet Or Intel",
        "name": "Import Subject Packet Or Intel",
        "permissions": [
            "Manage Subject Packet Or Intel Import"
        ],
        "visible": true
    }
};

export const SystemFeatureEnum = {
    "VINDecoding": {
        "id": "VINDecoding",
        "name": "VINDecoding"
    }
};

export const GlobalSearchResultTypeEnum = {
    "Packet": {
        "id": 0,
        "name": "Subject Packet"
    },
    "IntelReport": {
        "id": 1,
        "name": "Intel Report"
    },
    "FICard": {
        "id": 2,
        "name": "FI Card"
    },
    "Seizure": {
        "id": 3,
        "name": "Seizure"
    },
    "Investigation": {
        "id": 4,
        "name": "Investigation"
    },
    "Currency": {
        "id": 5,
        "name": "Currency"
    },
    "Alert": {
        "id": 6,
        "name": "Alert"
    },
    "PhotoLineup": {
        "id": 7,
        "name": "PhotoLineup"
    }
};

export const GlobalSearchTypeEnum = {
    "Agency": {
        "id": 0,
        "name": "Agency Search"
    },
    "Global": {
        "id": 1,
        "name": "Global Search"
    }
};

export const IdentificationTypeEnum = {
    "DriversLicense": {
        "id": 1,
        "name": "Drivers License",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": true,
        "subjectPacketTypeId": 1
    },
    "StateIdCard": {
        "id": 2,
        "name": "State Identification Card",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": true,
        "subjectPacketTypeId": 1
    },
    "SSN": {
        "id": 3,
        "name": "SSN",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 1
    },
    "StateIdNumber": {
        "id": 4,
        "name": "State Identification Number (SID)",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": true,
        "subjectPacketTypeId": 1
    },
    "IAFISNumber": {
        "id": 5,
        "name": "IAFIS Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 1
    },
    "FBINumber": {
        "id": 6,
        "name": "FBI Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 1
    },
    "LocalBookingNumber": {
        "id": 7,
        "name": "Local Booking Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 1
    },
    "LicensePlate": {
        "id": 8,
        "name": "License Plate",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": true,
        "requiresState": true,
        "subjectPacketTypeId": 2
    },
    "VIN": {
        "id": 9,
        "name": "VIN",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 2
    },
    "Address": {
        "id": 10,
        "name": "Address",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 3
    },
    "LatitudeLongitude": {
        "id": 11,
        "name": "Latitude / Longitue",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 3
    },
    "PhoneNumber": {
        "id": 12,
        "name": "Phone Number",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 4
    },
    "IMEI": {
        "id": 13,
        "name": "IMEI",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 4
    },
    "MEID": {
        "id": 14,
        "name": "MEID",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 4
    },
    "CurrencySerialNumber": {
        "id": 15,
        "name": "Currency Serial Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 5
    },
    "MoneyBatchNumber": {
        "id": 16,
        "name": "Money Batch Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 5
    },
    "WeaponSerialNumber": {
        "id": 17,
        "name": "Weapon Serial Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 6
    },
    "PersonName": {
        "id": 18,
        "name": "Name",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": true,
        "subjectPacketTypeId": 1
    },
    "BoatHullId": {
        "id": 19,
        "name": "Boat Hull Id",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 2
    },
    "BoatRegistrationNumber": {
        "id": 20,
        "name": "Boat Registration Number",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": false,
        "requiresState": true,
        "subjectPacketTypeId": 2
    },
    "LoJackCode": {
        "id": 21,
        "name": "LoJack Code",
        "onePerSubjectPacket": true,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 2
    },
    "Face": {
        "id": 23,
        "name": "Face",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 1
    },
    "ArticleSerialNumber": {
        "id": 22,
        "name": "Serial Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 7
    },
    "CreditCardNumber": {
        "id": 24,
        "name": "Credit Card Number",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 5
    },
    "Tattoo": {
        "id": 25,
        "name": "Tattoo",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 8
    },
    "SocialMediaProfile": {
        "id": 26,
        "name": "Social Media Profile",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 9
    },
    "CriminalHistoryQR": {
        "id": 27,
        "name": "Criminal History QR",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 10
    },
    "MobilePaymentApp": {
        "id": 28,
        "name": "Mobile Payment App",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 4
    },
    "CryptoCurrency": {
        "id": 29,
        "name": "Crypto Currency",
        "onePerSubjectPacket": false,
        "requiresSpecialtyPlate": false,
        "requiresState": false,
        "subjectPacketTypeId": 5
    }
};

export const IntelTypeEnum = {
    "FICard": {
        "id": 1,
        "name": "Field Interview Card",
        "shortName": "FI Card"
    },
    "IntelReport": {
        "id": 2,
        "name": "Intel Report",
        "shortName": "Intel Report"
    }
};

export const InternalResponseTypeEnum = {
    "Accept": {
        "displayName": "Accept",
        "id": "Accept",
        "name": "Accept",
        "priority": "Low",
        "visible": true
    },
    "Administrative": {
        "displayName": "Administrative",
        "id": "Administrative",
        "name": "Administrative",
        "priority": "Default",
        "visible": true
    },
    "AircraftRegistration": {
        "displayName": "Aircraft Registration",
        "id": "AircraftRegistration",
        "name": "AircraftRegistration",
        "priority": "Default",
        "visible": true
    },
    "AlarmExchangeRequest": {
        "displayName": "Alarm Exchange Request",
        "id": "AlarmExchangeRequest",
        "name": "AlarmExchangeRequest",
        "priority": "Default",
        "visible": true
    },
    "BlankResponse": {
        "displayName": "Blank Response",
        "id": "BlankResponse",
        "name": "BlankResponse",
        "priority": "Default",
        "visible": false
    },
    "BoatRegistration": {
        "displayName": "Boat Registration",
        "id": "BoatRegistration",
        "name": "BoatRegistration",
        "priority": "High",
        "visible": true
    },
    "CandidateList": {
        "displayName": "Candidate List",
        "id": "CandidateList",
        "name": "CandidateList",
        "priority": "Default",
        "visible": true
    },
    "CautionIndicatorPerson": {
        "displayName": "Caution Indicator Person",
        "id": "CautionIndicatorPerson",
        "name": "CautionIndicatorPerson",
        "priority": "Default",
        "visible": true
    },
    "ConcealedWeapons": {
        "displayName": "Concealed Weapons",
        "id": "ConcealedWeapons",
        "name": "ConcealedWeapons",
        "priority": "Default",
        "visible": true
    },
    "ConnectionError": {
        "displayName": "Connection Error",
        "id": "ConnectionError",
        "name": "ConnectionError",
        "priority": "Default",
        "visible": false
    },
    "InvalidOri": {
        "displayName": "Invalid Ori",
        "id": "InvalidOri",
        "name": "InvalidOri",
        "priority": "Default",
        "visible": false
    },
    "Corrections": {
        "displayName": "Corrections",
        "id": "Corrections",
        "name": "Corrections",
        "priority": "Default",
        "visible": true
    },
    "CriminalHistoryCandidateList": {
        "displayName": "Criminal History Candidate List",
        "id": "CriminalHistoryCandidateList",
        "name": "CriminalHistoryCandidateList",
        "priority": "High",
        "visible": true
    },
    "CriminalHistoryCandidate": {
        "displayName": "Possible Criminal History",
        "id": "CriminalHistoryCandidate",
        "name": "CriminalHistoryCandidate",
        "priority": "High",
        "visible": true
    },
    "NCICVehicle": {
        "displayName": "NCIC Vehicle",
        "id": "NCICVehicle",
        "name": "NCICVehicle",
        "priority": "Default",
        "visible": true
    },
    "NCICVehicleNoResult": {
        "displayName": "NCIC Vehicle No Result",
        "id": "NCICVehicleNoResult",
        "name": "NCICVehicleNoResult",
        "priority": "Default",
        "visible": false
    },
    "CriminalHistoryNoResults": {
        "displayName": "No Criminal History",
        "id": "CriminalHistoryNoResults",
        "name": "CriminalHistoryNoResults",
        "priority": "Default",
        "visible": true
    },
    "CriminalHistoryC4NoResults": {
        "displayName": "No Criminal History",
        "id": "CriminalHistoryC4NoResults",
        "name": "CriminalHistoryC4NoResults",
        "priority": "Default",
        "visible": false
    },
    "DeportedFelon": {
        "displayName": "Deported Felon",
        "id": "DeportedFelon",
        "name": "DeportedFelon",
        "priority": "Default",
        "visible": true
    },
    "DriverHistory": {
        "displayName": "Driver History",
        "id": "DriverHistory",
        "name": "DriverHistory",
        "priority": "High",
        "visible": true
    },
    "CriminalHistoryQH": {
        "displayName": "Possible Criminal History QR Record",
        "id": "CriminalHistoryQH",
        "name": "CriminalHistoryQH",
        "priority": "High",
        "visible": true
    },
    "DriverLicense": {
        "displayName": "Driver License",
        "id": "DriverLicense",
        "name": "DriverLicense",
        "priority": "Default",
        "visible": true
    },
    "CriminalHistoryQHNoRecord": {
        "displayName": "No QH Interstate Record",
        "id": "CriminalHistoryQHNoRecord",
        "name": "CriminalHistoryQHNoRecord",
        "priority": "High",
        "visible": false
    },
    "CriminalHistoryQRNoRecord": {
        "displayName": "No QR Interstate Record",
        "id": "CriminalHistoryQRNoRecord",
        "name": "CriminalHistoryQRNoRecord",
        "priority": "High",
        "visible": true
    },
    "AFISPhoto": {
        "displayName": "AFIS Photo Result",
        "id": "AFISPhoto",
        "name": "AFISPhoto",
        "priority": "High",
        "visible": true
    },
    "AFISPhotoNoRecord": {
        "displayName": "AFIS Photo No Record",
        "id": "AFISPhotoNoRecord",
        "name": "AFISPhotoNoRecord",
        "priority": "High",
        "visible": false
    },
    "WeaponNoRecord": {
        "displayName": "No Record For Weapon",
        "id": "WeaponNoRecord",
        "name": "WeaponNoRecord",
        "priority": "Low",
        "visible": true
    },
    "LostGun": {
        "displayName": "Lost Gun",
        "id": "LostGun",
        "name": "LostGun",
        "priority": "Low",
        "visible": true
    },
    "StolenGun": {
        "displayName": "Stolen Gun",
        "id": "StolenGun",
        "name": "StolenGun",
        "priority": "Low",
        "visible": true
    },
    "NCICWeapon": {
        "displayName": "NCIC Weapon Record",
        "id": "NCICWeapon",
        "name": "NCICWeapon",
        "priority": "Low",
        "visible": true
    },
    "Weapon": {
        "displayName": "No Record For Weapon",
        "id": "WeaponNoRecord",
        "name": "WeaponNoRecord",
        "priority": "Low",
        "visible": true
    },
    "BoatNoRecord": {
        "displayName": "Boat No Record",
        "id": "BoatNoRecord",
        "name": "BoatNoRecord",
        "priority": "Low",
        "visible": true
    },
    "NCICBoat": {
        "displayName": "NCIC Boat",
        "id": "NCICBoat",
        "name": "NCICBoat",
        "priority": "Low",
        "visible": true
    },
    "CriminalHistoryQR": {
        "displayName": "QR Interstate Record",
        "id": "CriminalHistoryQR",
        "name": "CriminalHistoryQR",
        "priority": "High",
        "visible": true
    },
    "CriminalHistoryQRState": {
        "displayName": "State Interstate Record",
        "id": "CriminalHistoryQRState",
        "name": "CriminalHistoryQRState",
        "priority": "High",
        "visible": true
    },
    "CriminalHistoryQRFBI": {
        "displayName": "FBI Interstate Record",
        "id": "CriminalHistoryQRFBI",
        "name": "CriminalHistoryQRFBI",
        "priority": "High",
        "visible": true
    },
    "DriverLicenseImage": {
        "displayName": "Driver License Image",
        "id": "DriverLicenseImage",
        "name": "DriverLicenseImage",
        "priority": "High",
        "visible": true
    },
    "DriverLicenseImageNotFound": {
        "displayName": "Driver License Image Not Found",
        "id": "DriverLicenseImageNotFound",
        "name": "DriverLicenseImageNotFound",
        "priority": "High",
        "visible": true
    },
    "DmvNameSearchCandidateList": {
        "displayName": "DMV Candidate List",
        "id": "DmvNameSearchCandidateList",
        "name": "DmvNameSearchCandidateList",
        "priority": "Default",
        "visible": true
    },
    "DmvNameSearchMoreHits": {
        "displayName": "Dmv Name Search More Hits",
        "id": "DmvNameSearchMoreHits",
        "name": "DmvNameSearchMoreHits",
        "priority": "Default",
        "visible": false
    },
    "DmvNameSearchNoMoreResults": {
        "displayName": "Dmv Name Search No More Results",
        "id": "DmvNameSearchNoMoreResults",
        "name": "DmvNameSearchNoMoreResults",
        "priority": "Default",
        "visible": false
    },
    "DmvNoRecord": {
        "displayName": "Dmv No Record",
        "id": "DmvNoRecord",
        "name": "DmvNoRecord",
        "priority": "Default",
        "visible": false
    },
    "EntryAccept": {
        "displayName": "Entry Accept",
        "id": "EntryAccept",
        "name": "EntryAccept",
        "priority": "Default",
        "visible": true
    },
    "Error": {
        "displayName": "Error",
        "id": "Error",
        "name": "Error",
        "priority": "Low",
        "visible": false
    },
    "FelonyVehicle": {
        "displayName": "Felony Vehicle",
        "id": "FelonyVehicle",
        "name": "FelonyVehicle",
        "priority": "Default",
        "visible": true
    },
    "ForeignFugitive": {
        "displayName": "Foreign Fugitive",
        "id": "ForeignFugitive",
        "name": "ForeignFugitive",
        "priority": "Default",
        "visible": true
    },
    "GunRegistration": {
        "displayName": "Gun Registration",
        "id": "GunRegistration",
        "name": "GunRegistration",
        "priority": "Default",
        "visible": true
    },
    "Hazmat": {
        "displayName": "Hazmat",
        "id": "Hazmat",
        "name": "Hazmat",
        "priority": "Default",
        "visible": true
    },
    "HuntingLicense": {
        "displayName": "Hunting License",
        "id": "HuntingLicense",
        "name": "HuntingLicense",
        "priority": "Default",
        "visible": true
    },
    "IdCard": {
        "displayName": "Id Card",
        "id": "IdCard",
        "name": "IdCard",
        "priority": "Default",
        "visible": true
    },
    "IdentityTheft": {
        "displayName": "Identity Theft",
        "id": "IdentityTheft",
        "name": "IdentityTheft",
        "priority": "Default",
        "visible": true
    },
    "Image": {
        "displayName": "Image",
        "id": "Image",
        "name": "Image",
        "priority": "Default",
        "visible": true
    },
    "ImmigrationViolation": {
        "displayName": "Immigration Violation",
        "id": "ImmigrationViolation",
        "name": "ImmigrationViolation",
        "priority": "Default",
        "visible": true
    },
    "Insurance": {
        "displayName": "Insurance",
        "id": "Insurance",
        "name": "Insurance",
        "priority": "High",
        "visible": true
    },
    "LocatedVehicle": {
        "displayName": "Located Vehicle",
        "id": "LocatedVehicle",
        "name": "LocatedVehicle",
        "priority": "Default",
        "visible": true
    },
    "LogoffAccept": {
        "displayName": "Logoff Accept",
        "id": "LogoffAccept",
        "name": "LogoffAccept",
        "priority": "Highest",
        "visible": false
    },
    "LogonCannotForDevice": {
        "displayName": "Logon Cannot For Device",
        "id": "LogonCannotForDevice",
        "name": "LogonCannotForDevice",
        "priority": "Highest",
        "visible": false
    },
    "LogoffFailed": {
        "displayName": "Logoff Failed",
        "id": "LogoffFailed",
        "name": "LogoffFailed",
        "priority": "Highest",
        "visible": false
    },
    "LogonAccept": {
        "displayName": "Logon Accept",
        "id": "LogonAccept",
        "name": "LogonAccept",
        "priority": "Highest",
        "visible": false
    },
    "LogonExpired": {
        "displayName": "Log On Expired",
        "id": "LogOnExpired",
        "name": "LogOnExpired",
        "priority": "Highest",
        "visible": false
    },
    "LogonFailed": {
        "displayName": "Logon Failed",
        "id": "LogonFailed",
        "name": "LogonFailed",
        "priority": "Highest",
        "visible": false
    },
    "LogonRequired": {
        "displayName": "Logon Required",
        "id": "LogonRequired",
        "name": "LogonRequired",
        "priority": "Highest",
        "visible": false
    },
    "LogonSuccess": {
        "displayName": "Logon Success",
        "id": "LogonSuccess",
        "name": "LogonSuccess",
        "priority": "Highest",
        "visible": false
    },
    "LogonInvalidUsername": {
        "displayName": "Logon Invalid Username",
        "id": "LogonInvalidUsername",
        "name": "LogonInvalidUsername",
        "priority": "Highest",
        "visible": false
    },
    "LogonInvalidPassword": {
        "displayName": "Logon Invalid Password",
        "id": "LogonInvalidPassword",
        "name": "LogonInvalidPassword",
        "priority": "Highest",
        "visible": false
    },
    "LogonExceed": {
        "displayName": "Logon Exceed",
        "id": "LogonExceed",
        "name": "LogonExceed",
        "priority": "Highest",
        "visible": false
    },
    "LogonAlready": {
        "displayName": "Logon Already",
        "id": "LogonAlready",
        "name": "LogonAlready",
        "priority": "Highest",
        "visible": false
    },
    "LogonCertification": {
        "displayName": "Logon Certification",
        "id": "LogonCertification",
        "name": "LogonCertification",
        "priority": "Highest",
        "visible": false
    },
    "LogonFormat": {
        "displayName": "Logon Format",
        "id": "LogonFormat",
        "name": "LogonFormat",
        "priority": "Highest",
        "visible": false
    },
    "LogonSpaces": {
        "displayName": "Logon Spaces",
        "id": "LogonSpaces",
        "name": "LogonSpaces",
        "priority": "Highest",
        "visible": false
    },
    "LogonNotAuthorized": {
        "displayName": "Logon Not Authorized",
        "id": "LogonNotAuthorized",
        "name": "LogonNotAuthorized",
        "priority": "Highest",
        "visible": false
    },
    "LogonAlreadyUsed": {
        "displayName": "Logon Already Used",
        "id": "LogonAlreadyUsed",
        "name": "LogonAlreadyUsed",
        "priority": "Highest",
        "visible": false
    },
    "LogonChanged": {
        "displayName": "Logon Changed",
        "id": "LogonChanged",
        "name": "LogonChanged",
        "priority": "Highest",
        "visible": false
    },
    "LogonWillExpire": {
        "displayName": "Logon Will Expire",
        "id": "LogonWillExpire",
        "name": "LogonWillExpire",
        "priority": "Highest",
        "visible": false
    },
    "LogonUnknownError": {
        "displayName": "Logon Unknown Error",
        "id": "LogonUnknownError",
        "name": "LogonUnknownError",
        "priority": "Highest",
        "visible": false
    },
    "LogoffSuccess": {
        "displayName": "Logoff Success",
        "id": "LogoffSuccess",
        "name": "LogoffSuccess",
        "priority": "Highest",
        "visible": false
    },
    "LostArticle": {
        "displayName": "Lost Article",
        "id": "LostArticle",
        "name": "LostArticle",
        "priority": "Default",
        "visible": true
    },
    "MissingPerson": {
        "displayName": "Missing Person",
        "id": "MissingPerson",
        "name": "MissingPerson",
        "priority": "Default",
        "visible": true
    },
    "NCIC": {
        "displayName": "N C I C",
        "id": "NCIC",
        "name": "NCIC",
        "priority": "Default",
        "visible": true
    },
    "NLETS": {
        "displayName": "N L E T S",
        "id": "NLETS",
        "name": "NLETS",
        "priority": "Default",
        "visible": true
    },
    "NLETSDriverLicense": {
        "displayName": "Driver License",
        "id": "NLETSDriverLicense",
        "name": "NLETSDriverLicense",
        "priority": "High",
        "visible": true
    },
    "NLETSVehicleRegistration": {
        "displayName": "Vehicle Registration",
        "id": "NLETSVehicleRegistration",
        "name": "NLETSVehicleRegistration",
        "priority": "High",
        "visible": true
    },
    "NoRecord": {
        "displayName": "No Record",
        "id": "NoRecord",
        "name": "NoRecord",
        "priority": "Low",
        "visible": false
    },
    "OffenderWatchRecord": {
        "displayName": "Offender Watch Record",
        "id": "OffenderWatchRecord",
        "name": "OffenderWatchRecord",
        "priority": "Default",
        "visible": true
    },
    "OffenderWatchNoRecord": {
        "displayName": "No OffenderWatch Record",
        "id": "OffenderWatchNoRecord",
        "name": "OffenderWatchNoRecord",
        "priority": "Default",
        "visible": false
    },
    "OwnerRegistration": {
        "displayName": "Owner Registration",
        "id": "OwnerRegistration",
        "name": "OwnerRegistration",
        "priority": "Default",
        "visible": true
    },
    "OwnedVehicles": {
        "displayName": "Owned Vehicles",
        "id": "OwnedVehicles",
        "name": "OwnedVehicles",
        "priority": "Default",
        "visible": true
    },
    "Parole": {
        "displayName": "Parole",
        "id": "Parole",
        "name": "Parole",
        "priority": "Default",
        "visible": true
    },
    "Probation": {
        "displayName": "Probation",
        "id": "Probation",
        "name": "Probation",
        "priority": "Default",
        "visible": true
    },
    "ProtectionOrderNoRecord": {
        "displayName": "No Protection Order",
        "id": "ProtectionOrderNoRecord",
        "name": "ProtectionOrderNoRecord",
        "priority": "Default",
        "visible": false
    },
    "InquiryHistory": {
        "displayName": "Inquiry History",
        "id": "InquiryHistory",
        "name": "InquiryHistory",
        "priority": "Default",
        "visible": true
    },
    "InvalidLicensePlate": {
        "displayName": "Unrecognized Plate",
        "id": "InvalidLicensePlate",
        "name": "InvalidLicensePlate",
        "priority": "High",
        "visible": true
    },
    "RecoveredGun": {
        "displayName": "Recovered Gun",
        "id": "RecoveredGun",
        "name": "RecoveredGun",
        "priority": "Default",
        "visible": true
    },
    "RapSheet": {
        "displayName": "Rap Sheet",
        "id": "RapSheet",
        "name": "RapSheet",
        "priority": "High",
        "visible": true
    },
    "RapSheetSummary": {
        "displayName": "Rap Sheet Summary",
        "id": "RapSheetSummary",
        "name": "RapSheetSummary",
        "priority": "High",
        "visible": true
    },
    "Registration": {
        "displayName": "Registration",
        "id": "Registration",
        "name": "Registration",
        "priority": "Default",
        "visible": true
    },
    "Response": {
        "displayName": "Response",
        "id": "Response",
        "name": "Response",
        "priority": "Default",
        "visible": true
    },
    "SecretServiceProtectorate": {
        "displayName": "Secret Service Protectorate",
        "id": "SecretServiceProtectorate",
        "name": "SecretServiceProtectorate",
        "priority": "Default",
        "visible": true
    },
    "Securities": {
        "displayName": "Securities",
        "id": "Securities",
        "name": "Securities",
        "priority": "Default",
        "visible": true
    },
    "SexualOffender": {
        "displayName": "Possible Sexual Offender",
        "id": "SexualOffender",
        "name": "SexualOffender",
        "priority": "Default",
        "visible": true
    },
    "SnowmobileRegistration": {
        "displayName": "Snowmobile Registration",
        "id": "SnowmobileRegistration",
        "name": "SnowmobileRegistration",
        "priority": "Default",
        "visible": true
    },
    "StolenArticle": {
        "displayName": "Stolen Article",
        "id": "StolenArticle",
        "name": "StolenArticle",
        "priority": "Default",
        "visible": true
    },
    "StolenBoat": {
        "displayName": "Stolen Boat",
        "id": "StolenBoat",
        "name": "StolenBoat",
        "priority": "Default",
        "visible": true
    },
    "StolenPart": {
        "displayName": "Stolen Part",
        "id": "StolenPart",
        "name": "StolenPart",
        "priority": "Default",
        "visible": true
    },
    "StolenPlate": {
        "displayName": "Possible Stolen Plate",
        "id": "StolenPlate",
        "name": "StolenPlate",
        "priority": "Default",
        "visible": true
    },
    "StolenSecurity": {
        "displayName": "Stolen Security",
        "id": "StolenSecurity",
        "name": "StolenSecurity",
        "priority": "Default",
        "visible": true
    },
    "StolenVehicle": {
        "displayName": "Possible Stolen Vehicle",
        "id": "StolenVehicle",
        "name": "StolenVehicle",
        "priority": "Default",
        "visible": true
    },
    "StoredVehicle": {
        "displayName": "Stored Vehicle",
        "id": "StoredVehicle",
        "name": "StoredVehicle",
        "priority": "Default",
        "visible": true
    },
    "SupervisedRelease": {
        "displayName": "Supervised Release",
        "id": "SupervisedRelease",
        "name": "SupervisedRelease",
        "priority": "Default",
        "visible": true
    },
    "System": {
        "displayName": "System",
        "id": "System",
        "name": "System",
        "priority": "Default",
        "visible": true
    },
    "Title": {
        "displayName": "Title",
        "id": "Title",
        "name": "Title",
        "priority": "Default",
        "visible": true
    },
    "TrafficArrest": {
        "displayName": "Traffic Arrest",
        "id": "TrafficArrest",
        "name": "TrafficArrest",
        "priority": "Default",
        "visible": true
    },
    "UnidentifiedPerson": {
        "displayName": "Unidentified Person",
        "id": "UnidentifiedPerson",
        "name": "UnidentifiedPerson",
        "priority": "Default",
        "visible": true
    },
    "Unknown": {
        "displayName": "Unknown",
        "id": "Unknown",
        "name": "Unknown",
        "priority": "Lowest",
        "visible": true
    },
    "Unsolicited": {
        "displayName": "Unsolicited",
        "id": "Unsolicited",
        "name": "Unsolicited",
        "priority": "Default",
        "visible": true
    },
    "VehicleRegistrationNotFound": {
        "displayName": "Vehicle Registration Not Found",
        "id": "VehicleRegistrationNotFound",
        "name": "VehicleRegistrationNotFound",
        "priority": "Low",
        "visible": true
    },
    "VehicleRegistration": {
        "displayName": "Vehicle Registration",
        "id": "VehicleRegistration",
        "name": "VehicleRegistration",
        "priority": "Default",
        "visible": true
    },
    "VehicleRegisteredOwner": {
        "displayName": "Registered Owner",
        "id": "VehicleRegisteredOwner",
        "name": "VehicleRegisteredOwner",
        "priority": "Default",
        "visible": true
    },
    "VehicleLien": {
        "displayName": "Vehicle Lien",
        "id": "VehicleLien",
        "name": "VehicleLien",
        "priority": "Default",
        "visible": true
    },
    "ViolentPerson": {
        "displayName": "Possible Violent Person",
        "id": "ViolentPerson",
        "name": "ViolentPerson",
        "priority": "Default",
        "visible": true
    },
    "ViolentGangOrTerrorist": {
        "displayName": "Possible Violent Gang Or Terrorist",
        "id": "ViolentGangOrTerrorist",
        "name": "ViolentGangOrTerrorist",
        "priority": "Default",
        "visible": true
    },
    "WantedPerson": {
        "displayName": "Possible Wanted Person",
        "id": "WantedPerson",
        "name": "WantedPerson",
        "priority": "Default",
        "visible": true
    },
    "NCICPersonNoResult": {
        "displayName": "NCIC Person No Result",
        "id": "NCICPersonNoResult",
        "name": "NCICPersonNoResult",
        "priority": "Default",
        "visible": false
    },
    "ProtectionOrder": {
        "displayName": "Protection Order",
        "id": "ProtectionOrder",
        "name": "ProtectionOrder",
        "priority": "Default",
        "visible": true
    },
    "NCICPerson": {
        "displayName": "NCIC Person Record",
        "id": "NCICPerson",
        "name": "NCICPerson",
        "priority": "High",
        "visible": true
    },
    "Warrant": {
        "displayName": "Warrant",
        "id": "Warrant",
        "name": "Warrant",
        "priority": "Default",
        "visible": true
    },
    "Weather": {
        "displayName": "Weather",
        "id": "Weather",
        "name": "Weather",
        "priority": "Default",
        "visible": true
    },
    "CriminalHistory": {
        "displayName": "Criminal History",
        "id": "CriminalHistory",
        "name": "CriminalHistory",
        "priority": "Default",
        "visible": true
    }
};

export const LogOnStatusEnum = {
    "LogOnSuccess": {
        "id": "LogOnSuccess",
        "message": "Logon successful",
        "name": "Logon successful",
        "regEx": "LEMS SESSION ACTIVE\\n\\n(?:\\s)*Welcome to the LEMS message switch application.\\s*$"
    },
    "LogOnInvalidUsername": {
        "id": "LogOnInvalidUsername",
        "message": "Invalid username",
        "name": "Invalid username",
        "regEx": "(ERROR-0006)"
    },
    "LogOnInvalidPassword": {
        "id": "LogOnInvalidPassword",
        "message": "Invalid password",
        "name": "Invalid password",
        "regEx": "(ERROR-0023)"
    },
    "LogOnExceed": {
        "id": "LogOnExceed",
        "message": "Exceeded the maximum number of logins for this device",
        "name": "Exceeded the maximum number of logins for this device",
        "regEx": "(ERROR-0007)"
    },
    "LogOnAlready": {
        "id": "LogOnAlready",
        "message": "Already logged on",
        "name": "Already logged on",
        "regEx": "(ERROR-0008)"
    },
    "LogOnCertification": {
        "id": "LogOnCertification",
        "message": "Certification has expired and is not allowed to logon",
        "name": "Certification has expired and is not allowed to logon",
        "regEx": "(ERROR-0024)"
    },
    "LogOnCannotForDevice": {
        "id": "LogOnCannotForDevice",
        "message": "You cannot logon using your current ORI/LDI. Ensure you have an ORI and LDI assigned and that they are valid.",
        "name": "You cannot logon using your current ORI/LDI. Ensure you have an ORI and LDI assigned and that they are valid.",
        "regEx": "(ERROR-0009)"
    },
    "LogOnFormat": {
        "id": "LogOnFormat",
        "message": "Cannot be processed because of the message format",
        "name": "Cannot be processed because of the message format",
        "regEx": "(ERROR-0002)"
    },
    "LogOnSpaces": {
        "id": "LogOnSpaces",
        "message": "User ID or password contained spaces",
        "name": "User ID or password contained spaces",
        "regEx": "(ERROR-0015)"
    },
    "LogOnNotAuthorized": {
        "id": "LogOnNotAuthorized",
        "message": "Not authorized to logon to this device",
        "name": "Not authorized to logon to this device",
        "regEx": "(ERROR-0022)"
    },
    "LogOnAlreadyUsed": {
        "id": "LogOnAlreadyUsed",
        "message": "New password rejected: already used",
        "name": "New password rejected: already used",
        "regEx": "(ERROR-0025)"
    },
    "LogOnChanged": {
        "id": "LogOnChanged",
        "message": "Password was successfully updated",
        "name": "Password was successfully updated",
        "regEx": "(WARNING-0001)"
    },
    "LogOnExpired": {
        "id": "LogOnExpired",
        "message": "Your password has expired. You need to change it before accessing the state switch.",
        "name": "Your password has expired. You need to change it before accessing the state switch.",
        "regEx": "(ERROR-0018)"
    },
    "LogOnWillExpire": {
        "id": "LogOnWillExpire",
        "message": "Logon successful, but your password will expire soon.",
        "name": "Logon successful, but your password will expire soon.",
        "regEx": "(WARNING-0003)"
    },
    "LogOnUnknownError": {
        "id": "LogOnUnknownError",
        "message": "An unknown logon error occurred.",
        "name": "An unknown logon error occurred.",
        "regEx": "(ERROR-9999)"
    },
    "LogOffSuccess": {
        "id": "LogOffSuccess",
        "message": "Logoff successful",
        "name": "Logoff successful",
        "regEx": "(LEMS SESSION COMPLETE)"
    },
    "LogOnRequired": {
        "id": "LogOnRequired",
        "message": "You are not logged in.",
        "name": "You are not logged in.",
        "regEx": "(You are required to log on to this device)"
    },
    "LogOffFailed": {
        "id": "LogOffFailed",
        "message": "Logoff failed, you are not currently logged on to this device.",
        "name": "Logoff failed, you are not currently logged on to this device.",
        "regEx": "(You are currently not logged on to this device)"
    }
};

export const CurrencyProcessingEnum = {
    "NotProcessed": {
        "id": 1,
        "name": "Not Processed"
    },
    "Processing": {
        "id": 2,
        "name": "Processing"
    },
    "Processed": {
        "id": 3,
        "name": "Processed"
    }
};

export const InvestigationStateEnum = {
    "Ongoing": {
        "id": 1,
        "name": "Ongoing"
    },
    "Finalizing": {
        "id": 2,
        "name": "Finalizing"
    },
    "Completed": {
        "id": 3,
        "name": "Completed"
    }
};

export const LicenseClassEnum = {
    "CommercialCombinationVehicles": {
        "code": "A",
        "id": "CommercialCombinationVehicles",
        "name": "Commercial Driver’s License (Combo)"
    },
    "CommercialHeavyStraightVehicle": {
        "code": "B",
        "id": "CommercialHeavyStraightVehicle",
        "name": "Commercial Driver’s License (Heavy)"
    },
    "CommercialLightVehicle": {
        "code": "C",
        "id": "CommercialLightVehicle",
        "name": "Commercial Driver’s License (Light)"
    },
    "Chauffeurs": {
        "code": "C",
        "id": "Chauffeurs",
        "name": "Chauffeurs Driver’s License"
    },
    "PersonalVehicleDriverLicense": {
        "code": "E",
        "id": "PersonalVehicleDriverLicense",
        "name": "Driver License"
    },
    "MobilityImpaired": {
        "code": "H",
        "id": "MobilityImpaired",
        "name": "Mobility Impaired ID Card"
    },
    "PersonalId": {
        "code": "I",
        "id": "PersonalId",
        "name": "Personal ID Card"
    },
    "OutOfStateTrackingNumber": {
        "code": "N",
        "id": "OutOfStateTrackingNumber",
        "name": "Out-of-State Tracking Number"
    },
    "PsuedoNumber": {
        "code": "P",
        "id": "PsuedoNumber",
        "name": "Psuedo Number"
    }
};

export const LicensePlateTypeEnum = {
    "RegularPassengerAutomobile": {
        "code": "PC",
        "description": "Regular Passenger Automobile Plates",
        "id": "PC",
        "name": "Regular"
    },
    "PersonalizedPlate": {
        "code": "PE",
        "description": "Personalized/Customized All Types of Vehicles",
        "id": "PE",
        "name": "Personalized"
    },
    "Temporary": {
        "code": "TM",
        "description": "Temporary",
        "id": "TM",
        "name": "Temporary"
    }
};

export const MediaParentTypeEnum = {
    "Investigation": {
        "id": 6,
        "name": "Investigation",
        "relativePath": "investigationMedia/"
    },
    "Intel": {
        "id": 3,
        "name": "Intel",
        "relativePath": "intelMedia/"
    },
    "IntelSubject": {
        "id": 8,
        "name": "IntelSubject",
        "relativePath": "intelSubjectMedia/"
    },
    "IntelTattoo": {
        "id": 10,
        "name": "IntelTattoo",
        "relativePath": "intelTattoo/"
    },
    "IntelVehicle": {
        "id": 13,
        "name": "IntelVehicle",
        "relativePath": "intelVehicle/"
    },
    "IntelSocialMediaProfile": {
        "id": 11,
        "name": "IntelSocialMediaProfile",
        "relativePath": "IntelSocialMediaProfile/"
    },
    "SubjectPacket": {
        "id": 1,
        "name": "SubjectPacket",
        "relativePath": "spMedia/"
    },
    "Alert": {
        "id": 2,
        "name": "Alert",
        "relativePath": "alertMedia/"
    },
    "NCICImage": {
        "id": 4,
        "name": "NCICImage",
        "relativePath": "ncicMedia/"
    },
    "PacketIdentification": {
        "id": 5,
        "name": "PacketIdentification",
        "relativePath": "packetIdentificationMedia/"
    },
    "InstantMessage": {
        "id": 9,
        "name": "InstantMessage",
        "relativePath": "instantMessageMedia/"
    },
    "PhotoLineup": {
        "id": 12,
        "name": "PhotoLineup",
        "relativePath": "PhotoLineup/"
    }
};

export const SearchTypeEnum = {
    "CCH": {
        "description": "Computerized Criminal History",
        "id": "CCH",
        "name": "Computerized Criminal History",
        "shortName": "Criminal"
    },
    "DMV": {
        "description": "Department of Motor Vehicles",
        "id": "DMV",
        "name": "Department of Motor Vehicles",
        "shortName": "DMV"
    },
    "LENSS": {
        "description": "Law Enforcement Network Sharing Solution",
        "id": "LENSS",
        "name": "Law Enforcement Network Sharing Solution",
        "shortName": "LENSS"
    }
};

export const NotificationTypeEnum = {
    "DispatcherQuery": {
        "id": 1,
        "isPublic": true,
        "name": "Dispatcher Query"
    },
    "Email": {
        "id": 2,
        "isPublic": false,
        "name": "Email"
    },
    "SMS": {
        "id": 3,
        "isPublic": false,
        "name": "SMS"
    },
    "InvestigativeAlert": {
        "id": 4,
        "isPublic": true,
        "name": "Investigative Alert"
    },
    "PendingDeactivation": {
        "id": 5,
        "isPublic": true,
        "name": "Pending Deactivation"
    },
    "MoneyImportComplete": {
        "id": 6,
        "isPublic": true,
        "name": "Money Import Complete"
    },
    "ReassignmentComplete": {
        "id": 7,
        "isPublic": true,
        "name": "Packet Reassignment Complete"
    },
    "PendingSeizure": {
        "id": 8,
        "isPublic": true,
        "name": "Pending Seizure"
    },
    "IntelUpdate": {
        "id": 9,
        "isPublic": true,
        "name": "Intelligence Update"
    },
    "SubjectPacketViewed": {
        "id": 10,
        "isPublic": true,
        "name": "Subject Packet Viewed"
    },
    "SeizureViewed": {
        "id": 11,
        "isPublic": true,
        "name": "Seizure Viewed"
    },
    "IntelViewed": {
        "id": 12,
        "isPublic": true,
        "name": "Intel Report Viewed"
    },
    "FiCardViewed": {
        "id": 13,
        "isPublic": true,
        "name": "FI Card Viewed"
    },
    "IntelShared": {
        "id": 14,
        "isPublic": true,
        "name": "Intelligence Shared"
    }
};

export const NotificationActionEnum = {
    "Queried": {
        "delayType": "0",
        "id": 1,
        "name": "Queried"
    },
    "Received": {
        "delayType": "0",
        "id": 2,
        "name": "Received"
    },
    "Updated": {
        "delayType": "0",
        "id": 3,
        "name": "Updated"
    },
    "Viewed": {
        "delayType": "0",
        "id": 4,
        "name": "Viewed"
    },
    "PasswordReset": {
        "delayType": "0",
        "id": 5,
        "name": "Password Reset"
    },
    "Washed": {
        "delayType": "0",
        "id": 6,
        "name": "Washed"
    },
    "Shared": {
        "delayType": "0",
        "id": 7,
        "name": "Shared"
    },
    "Reshared": {
        "delayType": "0",
        "id": 11,
        "name": "Re-shared"
    },
    "PendingDeactivation": {
        "delayType": "0",
        "displayText": "marked as Pending Deactivation",
        "id": 8,
        "name": "Marked for Deactivation"
    },
    "RejectPendingDeactivation": {
        "delayType": "0",
        "displayText": "rejected for Deactivation",
        "id": 9,
        "name": "Rejected for Deactivation"
    },
    "ApprovePendingDeactivation": {
        "delayType": "0",
        "displayText": "approved for Deactivation",
        "id": 10,
        "name": "Approved for Deactivation"
    }
};

export const NotificationMechanismEnum = {
    "Email": {
        "id": 1,
        "name": "Email"
    },
    "Text": {
        "id": 2,
        "name": "Text"
    },
    "Browser": {
        "id": 3,
        "name": "Browser"
    }
};

export const PacketTypeEnum = {
    "Person": {
        "directCreate": true,
        "id": 1,
        "name": "Person"
    },
    "Vehicle": {
        "directCreate": true,
        "id": 2,
        "name": "Vehicle"
    },
    "Location": {
        "directCreate": true,
        "id": 3,
        "name": "Location"
    },
    "Phone": {
        "directCreate": true,
        "id": 4,
        "name": "Phone"
    },
    "Currency": {
        "directCreate": false,
        "id": 5,
        "name": "Currency"
    },
    "Weapon": {
        "directCreate": true,
        "id": 6,
        "name": "Weapon"
    },
    "Article": {
        "directCreate": true,
        "id": 7,
        "name": "Article"
    },
    "Tattoo": {
        "directCreate": true,
        "id": 8,
        "name": "Tattoo"
    },
    "SocialMediaProfile": {
        "directCreate": true,
        "id": 9,
        "name": "Social Media Profile"
    },
    "III": {
        "directCreate": true,
        "id": 10,
        "name": "III"
    }
};

export const ReadoutEnum = {
    "Always": {
        "description": "Always",
        "id": "Always",
        "name": "Always"
    },
    "Never": {
        "description": "Never",
        "id": "Never",
        "name": "Never"
    },
    "QuickTagsOnly": {
        "description": "Quick Tags Only",
        "id": "QuickTagsOnly",
        "name": "QuickTagsOnly"
    }
};

export const RaceEnum = {
    "White": {
        "code": "W",
        "id": 1,
        "name": "White"
    },
    "Black": {
        "code": "B",
        "id": 2,
        "name": "Black"
    },
    "Asian": {
        "code": "A",
        "id": 3,
        "name": "Asian"
    },
    "AmericanIndianAlaska": {
        "code": "I",
        "id": 4,
        "name": "American Indian or Alaska Native"
    },
    "Unknown": {
        "code": "U",
        "id": 5,
        "name": "Unknown"
    },
    "Other": {
        "code": "O",
        "id": 6,
        "name": "Other"
    }
};

export const DevelopmentStatusEnum = {
    "Complete": {
        "id": 1,
        "name": "Complete"
    },
    "InDevelopment": {
        "id": 2,
        "name": "In Development"
    }
};

export const EntityOwnershipEnum = {
    "Owned": {
        "id": 1,
        "name": "Owned"
    },
    "Shared": {
        "id": 2,
        "name": "Shared"
    }
};

export const SexEnum = {
    "Male": {
        "code": "M",
        "id": 1,
        "name": "Male"
    },
    "Female": {
        "code": "F",
        "id": 2,
        "name": "Female"
    },
    "Unknown": {
        "code": "U",
        "id": 3,
        "name": "Unknown"
    }
};

export const SeizedItemTypeEnum = {
    "Cash": {
        "id": 1,
        "name": "Cash"
    },
    "Vehicle": {
        "id": 2,
        "name": "Vehicle"
    },
    "Weapon": {
        "id": 3,
        "name": "Weapon"
    },
    "Other": {
        "id": 4,
        "name": "Other"
    },
    "Drugs": {
        "id": 5,
        "name": "Drugs"
    }
};

export const SeizureStateEnum = {
    "AutoApproved": {
        "id": 1,
        "name": "Auto Approved"
    },
    "Approved": {
        "id": 2,
        "name": "Approved"
    },
    "PendingApproval": {
        "id": 3,
        "name": "Pending Approval"
    }
};

export const SeizureTypeEnum = {
    "Direct": {
        "id": 1,
        "name": "Direct"
    },
    "LENSS": {
        "id": 2,
        "name": "LENSS"
    }
};

export const StateEnum = {
    "AL": {
        "abbreviation": "AL",
        "id": 1,
        "name": "Alabama"
    },
    "AK": {
        "abbreviation": "AK",
        "id": 2,
        "name": "Alaska"
    },
    "AZ": {
        "abbreviation": "AZ",
        "id": 3,
        "name": "Arizona"
    },
    "AR": {
        "abbreviation": "AR",
        "id": 4,
        "name": "Arkansas"
    },
    "CA": {
        "abbreviation": "CA",
        "id": 5,
        "name": "California"
    },
    "CO": {
        "abbreviation": "CO",
        "id": 6,
        "name": "Colorado"
    },
    "CT": {
        "abbreviation": "CT",
        "id": 7,
        "name": "Connecticut"
    },
    "DE": {
        "abbreviation": "DE",
        "id": 8,
        "name": "Delaware"
    },
    "DC": {
        "abbreviation": "DC",
        "id": 9,
        "name": "District Of Columbia"
    },
    "FL": {
        "abbreviation": "FL",
        "id": 10,
        "name": "Florida"
    },
    "GA": {
        "abbreviation": "GA",
        "id": 11,
        "name": "Georgia"
    },
    "HI": {
        "abbreviation": "HI",
        "id": 12,
        "name": "Hawaii"
    },
    "ID": {
        "abbreviation": "ID",
        "id": 13,
        "name": "Idaho"
    },
    "IL": {
        "abbreviation": "IL",
        "id": 14,
        "name": "Illinois"
    },
    "IN": {
        "abbreviation": "IN",
        "id": 15,
        "name": "Indiana"
    },
    "IA": {
        "abbreviation": "IA",
        "id": 16,
        "name": "Iowa"
    },
    "KS": {
        "abbreviation": "KS",
        "id": 17,
        "name": "Kansas"
    },
    "KY": {
        "abbreviation": "KY",
        "id": 18,
        "name": "Kentucky"
    },
    "LA": {
        "abbreviation": "LA",
        "id": 19,
        "name": "Louisiana"
    },
    "ME": {
        "abbreviation": "ME",
        "id": 20,
        "name": "Maine"
    },
    "MD": {
        "abbreviation": "MD",
        "id": 21,
        "name": "Maryland"
    },
    "MA": {
        "abbreviation": "MA",
        "id": 22,
        "name": "Massachusetts"
    },
    "MI": {
        "abbreviation": "MI",
        "id": 23,
        "name": "Michigan"
    },
    "MN": {
        "abbreviation": "MN",
        "id": 24,
        "name": "Minnesota"
    },
    "MS": {
        "abbreviation": "MS",
        "id": 25,
        "name": "Mississippi"
    },
    "MO": {
        "abbreviation": "MO",
        "id": 26,
        "name": "Missouri"
    },
    "MT": {
        "abbreviation": "MT",
        "id": 27,
        "name": "Montana"
    },
    "NE": {
        "abbreviation": "NE",
        "id": 28,
        "name": "Nebraska"
    },
    "NV": {
        "abbreviation": "NV",
        "id": 29,
        "name": "Nevada"
    },
    "NH": {
        "abbreviation": "NH",
        "id": 30,
        "name": "New Hampshire"
    },
    "NJ": {
        "abbreviation": "NJ",
        "id": 31,
        "name": "New Jersey"
    },
    "NM": {
        "abbreviation": "NM",
        "id": 32,
        "name": "New Mexico"
    },
    "NY": {
        "abbreviation": "NY",
        "id": 33,
        "name": "New York"
    },
    "NC": {
        "abbreviation": "NC",
        "id": 34,
        "name": "North Carolina"
    },
    "ND": {
        "abbreviation": "ND",
        "id": 35,
        "name": "North Dakota"
    },
    "OH": {
        "abbreviation": "OH",
        "id": 36,
        "name": "Ohio"
    },
    "OK": {
        "abbreviation": "OK",
        "id": 37,
        "name": "Oklahoma"
    },
    "OR": {
        "abbreviation": "OR",
        "id": 38,
        "name": "Oregon"
    },
    "PA": {
        "abbreviation": "PA",
        "id": 39,
        "name": "Pennsylvania"
    },
    "RI": {
        "abbreviation": "RI",
        "id": 40,
        "name": "Rhode Island"
    },
    "SC": {
        "abbreviation": "SC",
        "id": 41,
        "name": "South Carolina"
    },
    "SD": {
        "abbreviation": "SD",
        "id": 42,
        "name": "South Dakota"
    },
    "TN": {
        "abbreviation": "TN",
        "id": 43,
        "name": "Tennessee"
    },
    "TX": {
        "abbreviation": "TX",
        "id": 44,
        "name": "Texas"
    },
    "UT": {
        "abbreviation": "UT",
        "id": 45,
        "name": "Utah"
    },
    "VT": {
        "abbreviation": "VT",
        "id": 46,
        "name": "Vermont"
    },
    "VA": {
        "abbreviation": "VA",
        "id": 47,
        "name": "Virginia"
    },
    "WA": {
        "abbreviation": "WA",
        "id": 48,
        "name": "Washington"
    },
    "WV": {
        "abbreviation": "WV",
        "id": 49,
        "name": "West Virginia"
    },
    "WI": {
        "abbreviation": "WI",
        "id": 50,
        "name": "Wisconsin"
    },
    "WY": {
        "abbreviation": "WY",
        "id": 51,
        "name": "Wyoming"
    },
    "AS": {
        "abbreviation": "AS",
        "id": 52,
        "name": "American Samoa"
    },
    "GU": {
        "abbreviation": "GU",
        "id": 53,
        "name": "Guam"
    },
    "MP": {
        "abbreviation": "MP",
        "id": 54,
        "name": "Northern Mariana Islands"
    },
    "PR": {
        "abbreviation": "PR",
        "id": 55,
        "name": "Puerto Rico"
    },
    "VI": {
        "abbreviation": "VI",
        "id": 56,
        "name": "U.S. Virgin Islands"
    }
};

export const SubjectPacketClassificationEnum = {
    "Confirmed": {
        "id": 1,
        "name": "Confirmed"
    },
    "Suspected": {
        "id": 2,
        "name": "Suspected"
    },
    "NonCriminal": {
        "id": 3,
        "name": "Non-Criminal"
    }
};

export const SubjectTypeEnum = {
    "Arsonist": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 221,
        "name": "Arsonist",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ParentNotification": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 222,
        "name": "Parent Notification",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "SovereignCitizen": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 223,
        "name": "Sovereign Citizen",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "EvidenceFunds": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 23,
        "name": "Evidence Funds",
        "outgoing": true,
        "subjectPacketTypeId": 5
    },
    "BankRobberyFunds": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 24,
        "name": "Bank Robbery Funds",
        "outgoing": true,
        "subjectPacketTypeId": 5
    },
    "SeizureFunds": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 25,
        "name": "Seizure Funds",
        "outgoing": false,
        "subjectPacketTypeId": 5
    },
    "CounterFeit": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 219,
        "name": "Counterfeit",
        "outgoing": false,
        "subjectPacketTypeId": 5
    },
    "WashedFunds": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": true,
        "id": 26,
        "name": "Washed",
        "outgoing": false,
        "subjectPacketTypeId": 5
    },
    "BannedPerson": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 108,
        "name": "Banned Person",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "BenchWarrant": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 220,
        "name": "Bench Warrant",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Burglar": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 109,
        "name": "Burglar",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "BurglarAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 113,
        "name": "Burglar Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Child": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 99,
        "name": "Child",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ChildMolester": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 46,
        "name": "Child Molester",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ChildMolesterAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 77,
        "name": "Child Molester Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ConcealedWeaponPermitCarrier": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 154,
        "name": "Concealed Weapon Permit Carrier",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Courier": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 3,
        "name": "Courier",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "CourierAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 4,
        "name": "Courier Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "NarcoticsDealer": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 1,
        "name": "Narcotics Dealer",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "NarcoticsDealerAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 2,
        "name": "Narcotics Dealer Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "NarcoticsLocation": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 22,
        "name": "Narcotics Location",
        "outgoing": true,
        "subjectPacketTypeId": 3
    },
    "NarcoticsUser": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 157,
        "name": "Narcotics User",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Fugitive": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 57,
        "name": "Fugitive",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "FugitiveAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 89,
        "name": "Fugitive Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "FieldInterview": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 90,
        "name": "Field Interview",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "GangMember": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 42,
        "name": "Gang Member",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "GangMemberAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 73,
        "name": "Gang Member Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "HumanTrafficker": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 28,
        "name": "Human Trafficker",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "HumanTraffickerAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 29,
        "name": "Human Trafficker Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Jacker": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 5,
        "name": "Jacker",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "JackerAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 6,
        "name": "Jacker Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "JuvenileProbationer": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 120,
        "name": "Juvenile Probationer",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "JuvenileProbationerAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 128,
        "name": "Juvenile Probationer Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "MentallyChallengedPerson": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 161,
        "name": "Mentally Challenged Person",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "MissingPerson": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 177,
        "name": "Missing Person",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "MissingPersonAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 181,
        "name": "Missing Person Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "MoneyLaunderer": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 30,
        "name": "Money Launderer",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "MoneyLaundererAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 61,
        "name": "Money Launderer Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Panhandler": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 160,
        "name": "Panhandler",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "FraudulentPrescriptionUser": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 158,
        "name": "Fraudulent Prescription User",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ProbationerParolee": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 137,
        "name": "Prob / Parolee",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ProbationerParoleeAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 141,
        "name": "Prob / Parolee Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Rapist": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 50,
        "name": "Rapist",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "RapistAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 81,
        "name": "Rapist Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Robber": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 145,
        "name": "Robber",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "RobberAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 150,
        "name": "Robber Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "SexOffender": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 124,
        "name": "Sex Offender",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "SexOffenderAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 132,
        "name": "Sex Offender Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ShopLifter": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 156,
        "name": "Shoplifter",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "SpecialNeedsPerson": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 164,
        "name": "Special Needs Person",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Stalker": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 34,
        "name": "Stalker",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "StalkerAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 65,
        "name": "Stalker Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "StolenVehicle": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 159,
        "name": "Stolen Vehicle",
        "outgoing": true,
        "subjectPacketTypeId": 2
    },
    "Terrorist": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 38,
        "name": "Terrorist",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "TerroristAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 69,
        "name": "Terrorist Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ThiefPerson": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 167,
        "name": "Thief",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ThiefAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 171,
        "name": "Thief Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ViolentOffender": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 27,
        "name": "Violent Offender",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "ViolentOffenderAssociate": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 85,
        "name": "Violent Offender Associate",
        "outgoing": true,
        "subjectPacketTypeId": 1
    },
    "Witness": {
        "agencyTypeIds": [
            2,
            4
        ],
        "deleted": false,
        "id": 93,
        "name": "Witness",
        "outgoing": true,
        "subjectPacketTypeId": 1
    }
};

export const EntityStateEnum = {
    "Activated": {
        "hidden": false,
        "id": 1,
        "name": "Active"
    },
    "PendingDeactivation": {
        "hidden": false,
        "id": 2,
        "name": "Pending Deactivation"
    },
    "Deactivated": {
        "hidden": true,
        "id": 3,
        "name": "Deactivated"
    },
    "Expired": {
        "hidden": true,
        "id": 4,
        "name": "Expired"
    }
};

export const VehicleColorCodeEnum = {
    "ONG": {
        "id": "ONG",
        "name": "Orange"
    },
    "AME": {
        "id": "AME",
        "name": "Amethyst"
    },
    "BGE": {
        "id": "BGE",
        "name": "Beige"
    },
    "BLK": {
        "id": "BLK",
        "name": "Black"
    },
    "BLU": {
        "id": "BLU",
        "name": "Blue"
    },
    "BRO": {
        "id": "BRO",
        "name": "Brown"
    },
    "BRZ": {
        "id": "BRZ",
        "name": "Bronze"
    },
    "COM": {
        "id": "COM",
        "name": "Chrome"
    },
    "CAM": {
        "id": "CAM",
        "name": "Camouflage"
    },
    "CPR": {
        "id": "CPR",
        "name": "Copper"
    },
    "CRM": {
        "id": "CRM",
        "name": "Cream"
    },
    "DBL": {
        "id": "DBL",
        "name": "Dark"
    },
    "DGR": {
        "id": "DGR",
        "name": "Dark"
    },
    "GLD": {
        "id": "GLD",
        "name": "Gold"
    },
    "GRN": {
        "id": "GRN",
        "name": "Green"
    },
    "GRY": {
        "id": "GRY",
        "name": "Gray"
    },
    "LAV": {
        "id": "LAV",
        "name": "Lavender-Purple"
    },
    "LBL": {
        "id": "LBL",
        "name": "Light"
    },
    "LGR": {
        "id": "LGR",
        "name": "Light"
    },
    "MAR": {
        "id": "MAR",
        "name": "Maroon"
    },
    "MVE": {
        "id": "MVE",
        "name": "Mauve"
    },
    "PLE": {
        "id": "PLE",
        "name": "Purple"
    },
    "PNK": {
        "id": "PNK",
        "name": "Pink"
    },
    "RED": {
        "id": "RED",
        "name": "Red"
    },
    "SIL": {
        "id": "SIL",
        "name": "Silver"
    },
    "TAN": {
        "id": "TAN",
        "name": "Tan"
    },
    "TEA": {
        "id": "TEA",
        "name": "Teal"
    },
    "TPE": {
        "id": "TPE",
        "name": "Taupe"
    },
    "TRQ": {
        "id": "TRQ",
        "name": "Turquoise"
    },
    "WHI": {
        "id": "WHI",
        "name": "White"
    },
    "YEL": {
        "id": "YEL",
        "name": "Yellow"
    }
};

export const VehicleMakeCodeEnum = {
    "AAA": {
        "id": "AAA",
        "name": "AAA MOBILE HOME MFG CO"
    },
    "AAAR": {
        "id": "AAAR",
        "name": "AAA RAFT TRAILERS INC"
    },
    "AAB": {
        "id": "AAB",
        "name": "AAB"
    },
    "AACC": {
        "id": "AACC",
        "name": "AMERICAN AUSTIN CAR COMPANY INDEPENDANT SUBSIDIARY OF AUSTIN VEHICLES"
    },
    "AALI": {
        "id": "AALI",
        "name": "AALITE CO"
    },
    "AAPX": {
        "id": "AAPX",
        "name": "AAPEX TRAILERS INC CHICAGO IL"
    },
    "AARC": {
        "id": "AARC",
        "name": "ALL AMERICAN RACERS SANTA ANA CA"
    },
    "AARD": {
        "id": "AARD",
        "name": "AARDVARK"
    },
    "AASE": {
        "id": "AASE",
        "name": "A AND A STEEL ENTERPRISES"
    },
    "AASS": {
        "id": "AASS",
        "name": "AA SCREEN SUPPLY INC"
    },
    "AATC": {
        "id": "AATC",
        "name": "AIRSTREAM AVENUE TOURING COACH MFG BY AIRSTREAM INC"
    },
    "AATK": {
        "id": "AATK",
        "name": "A AND A TRIKES LTD"
    },
    "AATM": {
        "id": "AATM",
        "name": "ALL AMERICAN TRAILER MFG"
    },
    "AATS": {
        "id": "AATS",
        "name": "AAA TRAILER SALES MT PLEASANT TX"
    },
    "AAVH": {
        "id": "AAVH",
        "name": "ARMET ARMORED VEHICLES LARGO FL AND ONTARIO CANADA"
    },
    "AAWS": {
        "id": "AAWS",
        "name": "AA WELDING SERVICE OR CLINT R WOODS INC MOUNT PLEASANT TX"
    },
    "ABAR": {
        "id": "ABAR",
        "name": "ABARTH"
    },
    "ABBO": {
        "id": "ABBO",
        "name": "ABBOT PAUL"
    },
    "ABBY": {
        "id": "ABBY",
        "name": "ABBY MANUFACTURING COMPANY WALNUT MS"
    },
    "ABC": {
        "id": "ABC",
        "name": "ABC ALUMINUM BODY CORP"
    },
    "ABCC": {
        "id": "ABCC",
        "name": "ABC COACH"
    },
    "ABCH": {
        "id": "ABCH",
        "name": "ABC HOMES DIV DIV"
    },
    "ABCO": {
        "id": "ABCO",
        "name": "AB"
    },
    "ABCP": {
        "id": "ABCP",
        "name": "ABC POWDER COATING CENTRAL POINT OR"
    },
    "ABER": {
        "id": "ABER",
        "name": "ABERDEEN BOAT TRAILER"
    },
    "ABFI": {
        "id": "ABFI",
        "name": "ABF INDUSTRIES NEBRASKA"
    },
    "ABIA": {
        "id": "ABIA",
        "name": "ABI LEISURE INC"
    },
    "ABIN": {
        "id": "ABIN",
        "name": "A AND B INDUSTRIES BEAUMONT TX"
    },
    "ABLE": {
        "id": "ABLE",
        "name": "ABLE BODY CORP ABLE MANUFACTURING AND ASSEMBLY LLC JOPLIN MO"
    },
    "ABST": {
        "id": "ABST",
        "name": "AIRBOAT STUFF LLC BROOKSVILLE FL"
    },
    "ABTI": {
        "id": "ABTI",
        "name": "AIRBAGGED TRAILERS INC HEMET CA"
    },
    "ABTM": {
        "id": "ABTM",
        "name": "AM"
    },
    "ABTR": {
        "id": "ABTR",
        "name": "A AND B TRAILER MFG"
    },
    "ABTS": {
        "id": "ABTS",
        "name": "ABOUT SALES INC ANDERSON CA"
    },
    "ABUT": {
        "id": "ABUT",
        "name": "ABU TRAILERS NORTH DAKOTA"
    },
    "ACAD": {
        "id": "ACAD",
        "name": "ACADIAN GM OF CANADA"
    },
    "ACAE": {
        "id": "ACAE",
        "name": "ACADEMY MOBILE HOME CORP"
    },
    "ACAI": {
        "id": "ACAI",
        "name": "ANTIQUE AND COLLECTIBLE AUTOS INC"
    },
    "ACCI": {
        "id": "ACCI",
        "name": "AC CUSTOMS INC MANCHESTER NH"
    },
    "ACCM": {
        "id": "ACCM",
        "name": "ACCESS MOTOR"
    },
    "ACCT": {
        "id": "ACCT",
        "name": "ATLANTIC COAST CUSTOM TRIKES MYRTLE BEACH SC_MOTORCYCLES AND TRIKES"
    },
    "ACCU": {
        "id": "ACCU",
        "name": "ACCU TEK INC SHREWSBURY MA"
    },
    "ACE": {
        "id": "ACE",
        "name": "ACE TRAILER OF AMERICA"
    },
    "ACEC": {
        "id": "ACEC",
        "name": "ACE TRAVELER CORP"
    },
    "ACEE": {
        "id": "ACEE",
        "name": "ACE ENTERPRISES INC MIAMI FL"
    },
    "ACEI": {
        "id": "ACEI",
        "name": "ACCURATE CYCLE ENGINEERING INC FLORIDA"
    },
    "ACEM": {
        "id": "ACEM",
        "name": "ACE MANUFATURING"
    },
    "ACEQ": {
        "id": "ACEQ",
        "name": "AMERICAN CARRIER EQUIPMENT INC FRESNO CA"
    },
    "ACET": {
        "id": "ACET",
        "name": "ACE MFG BY THOR MOTOR COACH INC"
    },
    "ACGC": {
        "id": "ACGC",
        "name": "AMERICAN CUSTOM GOLF CARS AND NEIGHBORHOOD ELECTRIC VEHICLE CALIFORNIA"
    },
    "ACKN": {
        "id": "ACKN",
        "name": "A AND C KNIGHT LTD"
    },
    "ACKR": {
        "id": "ACKR",
        "name": "ACKER DRILL COMPANY PENNSYLVANIA"
    },
    "ACME": {
        "id": "ACME",
        "name": "ACME TRAILER MFG CO"
    },
    "ACMM": {
        "id": "ACMM",
        "name": "ACME MFG"
    },
    "ACNS": {
        "id": "ACNS",
        "name": "SOUDURE ACNS INC QUEBEC CANADA"
    },
    "ACOL": {
        "id": "ACOL",
        "name": "AMERICAN COLEMAN LITTLETON CO"
    },
    "ACON": {
        "id": "ACON",
        "name": "AEROCON"
    },
    "ACOR": {
        "id": "ACOR",
        "name": "ACORN EQUIPMENT CORP"
    },
    "ACRO": {
        "id": "ACRO",
        "name": "ACRO TANK"
    },
    "ACSI": {
        "id": "ACSI",
        "name": "ALKOTA CLEANING SYSTEMS INC ALCESTER SD"
    },
    "ACTC": {
        "id": "ACTC",
        "name": "APPLIED CRYO TECHNOLOGIES HOUSTON TX"
    },
    "ACTD": {
        "id": "ACTD",
        "name": "ACTION INDUSTRIES"
    },
    "ACTE": {
        "id": "ACTE",
        "name": "ACTIVE HOMES CORP"
    },
    "ACTO": {
        "id": "ACTO",
        "name": "ACTON MOBILE INDUSTRIES INC ELBURN IL"
    },
    "ACTT": {
        "id": "ACTT",
        "name": "ACCELERATED TANKS AND TRAILERS FORT WAYNE IN"
    },
    "ACTV": {
        "id": "ACTV",
        "name": "ACTIVITY TRAILER LLC ARIZONA"
    },
    "ACUR": {
        "id": "ACUR",
        "name": "ACURA"
    },
    "ACYL": {
        "id": "ACYL",
        "name": "A1 CYCLES INC WEST PALM BEACH FL"
    },
    "ADA": {
        "id": "ADA",
        "name": "ADA HORSE TRAILER"
    },
    "ADAK": {
        "id": "ADAK",
        "name": "ADAK ADVENTURE TRAILERS LLC ST AUGUSTINE FL"
    },
    "ADAM": {
        "id": "ADAM",
        "name": "ADAMS BROTHERS OHIO ANTIQUE TRUCKS"
    },
    "ADBV": {
        "id": "ADBV",
        "name": "A D BOIVIN"
    },
    "ADCT": {
        "id": "ADCT",
        "name": "ADVANCED COMPOSITES AND TOOLING FLIPPIN AR"
    },
    "ADDI": {
        "id": "ADDI",
        "name": "ADDISON HOMES DIV OF PENTHOUSE INDUSTRIES INC ADDISON AL"
    },
    "ADEP": {
        "id": "ADEP",
        "name": "ADVANCED ENGINEERED PRODUCTS"
    },
    "ADET": {
        "id": "ADET",
        "name": "ADETTE"
    },
    "ADHD": {
        "id": "ADHD",
        "name": "AMERICAN DREAM HOT DOG CARTS INC"
    },
    "ADHM": {
        "id": "ADHM",
        "name": "ADVENTURE HOMES GARRETT IN"
    },
    "ADLR": {
        "id": "ADLR",
        "name": "ADLER"
    },
    "ADLT": {
        "id": "ADLT",
        "name": "ALEXANDER DENNIS"
    },
    "ADLY": {
        "id": "ADLY",
        "name": "ADLY MOTO LLC PRODUCED BY HER CHEE INDUSTRIAL"
    },
    "ADMD": {
        "id": "ADMD",
        "name": "ADVANCED MODULAR MANUFACTURING SALT LAKE CITY UT"
    },
    "ADME": {
        "id": "ADME",
        "name": "ADCOCK MANSELL ENTERPRISES CALIFORNIA"
    },
    "ADMH": {
        "id": "ADMH",
        "name": "ADRIAN MANUFACTURED HOMES"
    },
    "ADMI": {
        "id": "ADMI",
        "name": "ADMIRATION HOMES ELKHART IN"
    },
    "ADMR": {
        "id": "ADMR",
        "name": "ADMIRAL DRIVE SYSTEMS INC ONTARIO CANADA"
    },
    "ADMS": {
        "id": "ADMS",
        "name": "ADAMS ENGINEERING CO"
    },
    "ADMT": {
        "id": "ADMT",
        "name": "ADAMS TRAILER MFG"
    },
    "ADRN": {
        "id": "ADRN",
        "name": "ADRENALINE TRAILERS HRM UNLIMITED LLC LAKE HAVASU CITY AZ"
    },
    "ADSP": {
        "id": "ADSP",
        "name": "ADSPA MOBILE HOME MFD BY AD SPACE INC POMONA CA"
    },
    "ADTA": {
        "id": "ADTA",
        "name": "ADVANCED TECHNOLOGIES AUTOMOTIVE"
    },
    "ADTC": {
        "id": "ADTC",
        "name": "THE AMERICAN DREAM TRAILER COMPANY LLC PORTLAND OR"
    },
    "ADTM": {
        "id": "ADTM",
        "name": "ADVANTAGE TRAILER MFG ONTARIO CANADA"
    },
    "ADVA": {
        "id": "ADVA",
        "name": "ADVANCE MFG CO"
    },
    "ADVC": {
        "id": "ADVC",
        "name": "ADVANCED CONTAINMENT SYSTEMS INC TEXAS"
    },
    "ADVE": {
        "id": "ADVE",
        "name": "ADVENTURE WHEELS MOTOR HOME"
    },
    "ADVL": {
        "id": "ADVL",
        "name": "ADVENTURER LP YAKIMA WA"
    },
    "ADVN": {
        "id": "ADVN",
        "name": "ADVANCE MACHINE CO"
    },
    "ADVP": {
        "id": "ADVP",
        "name": "ADVANCE PUMP AND EQUIPMENT INC PEOSTA IA"
    },
    "ADVS": {
        "id": "ADVS",
        "name": "ADVANCED VEHICLE SYSTEM CHATTANOOGA TN"
    },
    "ADVT": {
        "id": "ADVT",
        "name": "ADVANCE TRAILER MFG ONTARIO CANADA"
    },
    "AEAG": {
        "id": "AEAG",
        "name": "AMERICAN EAGLE ALSO SEE MAKE EAGLE"
    },
    "AECD": {
        "id": "AECD",
        "name": "A AND E CUSTOM DESIGN TRAILERS"
    },
    "AECI": {
        "id": "AECI",
        "name": "AMPERE ELECTRIC CARS INC MONTEREY PARK CA"
    },
    "AEEC": {
        "id": "AEEC",
        "name": "AEE CHOPPERS PLACENTIA CA"
    },
    "AELI": {
        "id": "AELI",
        "name": "AERO LINER CO"
    },
    "AEON": {
        "id": "AEON",
        "name": "AEON MOTOR"
    },
    "AEPN": {
        "id": "AEPN",
        "name": "AEP NORTH AMERICA"
    },
    "AEQP": {
        "id": "AEQP",
        "name": "ACTION EQUIPMENT"
    },
    "AERA": {
        "id": "AERA",
        "name": "AEROCAR"
    },
    "AERG": {
        "id": "AERG",
        "name": "AERO GLASS BOAT CO"
    },
    "AERI": {
        "id": "AERI",
        "name": "AEROIL PRODUCTS"
    },
    "AERM": {
        "id": "AERM",
        "name": "AERMACCHI ITALY"
    },
    "AERO": {
        "id": "AERO",
        "name": "AIRCRAFT"
    },
    "AESP": {
        "id": "AESP",
        "name": "AERO SPECIALTIES BOISE ID"
    },
    "AETA": {
        "id": "AETA",
        "name": "AETA"
    },
    "AETL": {
        "id": "AETL",
        "name": "AERO TEARDROPS LLC TUALATIN OR"
    },
    "AEVI": {
        "id": "AEVI",
        "name": "AUSTIN EV INC"
    },
    "AFAB": {
        "id": "AFAB",
        "name": "AFAB PETROLEUM AND STEEL FABRICATION OR AFFORDABLE FABRICATION"
    },
    "AFBX": {
        "id": "AFBX",
        "name": "ACIER FABREX INC"
    },
    "AFCT": {
        "id": "AFCT",
        "name": "AIRSTREAM FLYING CLOUD TRAVEL TRAVEL MFG BY AIRSTREAM INC"
    },
    "AFFL": {
        "id": "AFFL",
        "name": "ALASKA FRONTIER FABRICATION LLC WASILLA AK"
    },
    "AFRD": {
        "id": "AFRD",
        "name": "AFFORDABLE TRAILERS LUBBOCK TX"
    },
    "AFTM": {
        "id": "AFTM",
        "name": "A AND F TRAILER MFG"
    },
    "AGCH": {
        "id": "AGCH",
        "name": "AG CHEM EQUIPMENT"
    },
    "AGCO": {
        "id": "AGCO",
        "name": "AG"
    },
    "AGDC": {
        "id": "AGDC",
        "name": "ARAGON DISTRIBUTING"
    },
    "AGDY": {
        "id": "AGDY",
        "name": "AG DRYER SERVICES INC ELM CREEK NB"
    },
    "AGIL": {
        "id": "AGIL",
        "name": "AIR NATIONAL GUARD ILLINOIS"
    },
    "AGRA": {
        "id": "AGRA",
        "name": "AG RAIN INC"
    },
    "AGRF": {
        "id": "AGRF",
        "name": "AGRIFLEET LEASING CORP AUBURNDALE FL"
    },
    "AGRI": {
        "id": "AGRI",
        "name": "AGRI SUPPLY CO"
    },
    "AGRO": {
        "id": "AGRO",
        "name": "AGROLINA SA MEXICALI BAHA CA"
    },
    "AGRP": {
        "id": "AGRP",
        "name": "AGRI PLASTICS MFG ONTARIO CANADA"
    },
    "AGSY": {
        "id": "AGSY",
        "name": "AG SYSTEMS INC HUTCHINSON MN"
    },
    "AGTR": {
        "id": "AGTR",
        "name": "AG TRONIC INC"
    },
    "AGYL": {
        "id": "AGYL",
        "name": "ARGYLE"
    },
    "AHBI": {
        "id": "AHBI",
        "name": "AFTER HOURS BIKES INC POMPANO BEACH FL"
    },
    "AHPA": {
        "id": "AHPA",
        "name": "AMERICAN HIGH PERFORMANCE ALUMINUM BOAT TRAILERS INC LAKELAND FL"
    },
    "AHRN": {
        "id": "AHRN",
        "name": "AHRENS AHRENS FOX OHIO"
    },
    "AHSC": {
        "id": "AHSC",
        "name": "AMERICAN HOMESTAR CORP BURLESON TX"
    },
    "AHTS": {
        "id": "AHTS",
        "name": "A1 HITCH AND TRAILER SALES INC SUPPLY NC"
    },
    "AIBB": {
        "id": "AIBB",
        "name": "BAMBI MFG BY AIRSTREAM INC"
    },
    "AIBC": {
        "id": "AIBC",
        "name": "BASECAMP BRAND MFG BY AIRSTREAM INC"
    },
    "AICL": {
        "id": "AICL",
        "name": "CARAVEL MFG BY AIRSTREAM INC"
    },
    "AIGT": {
        "id": "AIGT",
        "name": "GLOBETROTTER BRAND MFG BY AIRSTREAM INC"
    },
    "AIH": {
        "id": "AIH",
        "name": "AMERICAN IRONHORSE MOTORCYCLES FT WORTH TX"
    },
    "AIKK": {
        "id": "AIKK",
        "name": "AINLEY KENNELS AND FABRICATION"
    },
    "AILY": {
        "id": "AILY",
        "name": "LAND YACHT BRAND MFG BY AIRSTREAM"
    },
    "AIMM": {
        "id": "AIMM",
        "name": "ARCADE IRON MOTORCYCLE COMPANY ARCADE NY"
    },
    "AIMX": {
        "id": "AIMX",
        "name": "AIM EX VEHICLES ALSO TAOTAO INDUSTRY"
    },
    "AINS": {
        "id": "AINS",
        "name": "NEST BRAND MFG BY AIRSTREAM"
    },
    "AIPN": {
        "id": "AIPN",
        "name": "PENDLETON BRAND MFG BY AIRSTREAM INC"
    },
    "AIRB": {
        "id": "AIRB",
        "name": "AIR BURNERS INC PALM CITY FL"
    },
    "AIRC": {
        "id": "AIRC",
        "name": "AIRCAP MANUFACTURERS INC TUPELO MS"
    },
    "AIRE": {
        "id": "AIRE",
        "name": "AIRE LINE MOBILE HOMES"
    },
    "AIRF": {
        "id": "AIRF",
        "name": "AIRFLYTE MFG CO"
    },
    "AIRI": {
        "id": "AIRI",
        "name": "AIR SUPPORT INDUSTRIES GLASSBORO NJ"
    },
    "AIRM": {
        "id": "AIRM",
        "name": "AIR FLO MFG"
    },
    "AIRO": {
        "id": "AIRO",
        "name": "AIR O MOTOR HOME"
    },
    "AIRP": {
        "id": "AIRP",
        "name": "AIR O CORP"
    },
    "AIRS": {
        "id": "AIRS",
        "name": "AIRSTREAM TRAVEL TRAILERS"
    },
    "AITB": {
        "id": "AITB",
        "name": "TOMMY BAHAMA BRAND MFG BY AIRSTREAM"
    },
    "AITC": {
        "id": "AITC",
        "name": "AIRSTREAM INTERSTATE TOURING COACH MFG BY AIRSTREAM INC"
    },
    "AJAX": {
        "id": "AJAX",
        "name": "AJAX TRAILER CO"
    },
    "AJIN": {
        "id": "AJIN",
        "name": "A AND J INDUSTRIES CUSTOM FIBERGLASSING AM A AND M BOAT TRAILER"
    },
    "AJPM": {
        "id": "AJPM",
        "name": "AJP MOTOS LDA AJP MOTOS SA PORTUGAL"
    },
    "AJS": {
        "id": "AJS",
        "name": "AJS UNITED KINGDOM"
    },
    "AJTR": {
        "id": "AJTR",
        "name": "A J TRAVELUTE TRAILER MFG CO"
    },
    "AJW": {
        "id": "AJW",
        "name": "AJW"
    },
    "AJXE": {
        "id": "AJXE",
        "name": "AJAX ELECTRIC MOTOR CORP"
    },
    "AJXX": {
        "id": "AJXX",
        "name": "AJAX TOOL COMPANY LAKESIDE CA"
    },
    "AKCC": {
        "id": "AKCC",
        "name": "AK CONTAINER CABINS LLC WASILLA AK"
    },
    "AKOP": {
        "id": "AKOP",
        "name": "OUTPOST MODEL MFG BY ADAK ADVENTURE TRAILERS LLC"
    },
    "AKUM": {
        "id": "AKUM",
        "name": "AKUMA MOTORS"
    },
    "AKXT": {
        "id": "AKXT",
        "name": "ALASKA XTREME TRAILERS"
    },
    "ALAA": {
        "id": "ALAA",
        "name": "AMERICAN ALLEGIANCE MFG BY ALLIED RECREATION GROUP INC"
    },
    "ALAB": {
        "id": "ALAB",
        "name": "ALABAMA TRAILER"
    },
    "ALAC": {
        "id": "ALAC",
        "name": "ALL A CART MANUFACTURING INC COLUMBUS OH"
    },
    "ALAD": {
        "id": "ALAD",
        "name": "ALADDIN TRAILER CO"
    },
    "ALAE": {
        "id": "ALAE",
        "name": "AMERICAN EAGLE MFG BY ALLIED RECREATIONAL GROUP"
    },
    "ALAF": {
        "id": "ALAF",
        "name": "AMERICAN LAFRANCE LLC"
    },
    "ALAI": {
        "id": "ALAI",
        "name": "ALAIR COACH CORP"
    },
    "ALAM": {
        "id": "ALAM",
        "name": "ALL AMERICAN MANUFACTURING LLC WYOMING"
    },
    "ALAN": {
        "id": "ALAN",
        "name": "ALLEN CAMPER MFG"
    },
    "ALAR": {
        "id": "ALAR",
        "name": "ALL AROUND HORSE TRAILER"
    },
    "ALAS": {
        "id": "ALAS",
        "name": "ALASKAN CAMPER"
    },
    "ALAU": {
        "id": "ALAU",
        "name": "AUGUSTA LINE MFG BY ALLIED RECREATION GROUP FORMERLY FLEETWOOD AUGUSTA LX LINE"
    },
    "ALBE": {
        "id": "ALBE",
        "name": "ALBERTA TRAILER"
    },
    "ALBT": {
        "id": "ALBT",
        "name": "ALBRIGHT TRAILER MFG LEBANON TN"
    },
    "ALCA": {
        "id": "ALCA",
        "name": "ALCAN AMERICAN INC"
    },
    "ALCG": {
        "id": "ALCG",
        "name": "ALUMINUM CARGO TRAILERS INC LAGRANGE IN"
    },
    "ALCI": {
        "id": "ALCI",
        "name": "ALLEN COACHWORKS INC"
    },
    "ALCM": {
        "id": "ALCM",
        "name": "ALCOM INC MAINE"
    },
    "ALCN": {
        "id": "ALCN",
        "name": "ALCAN TRAILERS LLC ANCHORAGE AK"
    },
    "ALCO": {
        "id": "ALCO",
        "name": "ALCOA TRAILER"
    },
    "ALCT": {
        "id": "ALCT",
        "name": "ALL"
    },
    "ALDA": {
        "id": "ALDA",
        "name": "ALLISON DAYTONA"
    },
    "ALDM": {
        "id": "ALDM",
        "name": "ADMIRAL MODEL MFG BY ALLIED RECREATIONAL GROUP INC"
    },
    "ALEA": {
        "id": "ALEA",
        "name": "AMERICAN DREAM BRAND MFG BY ALLIED RECREATION GROUP"
    },
    "ALED": {
        "id": "ALED",
        "name": "ALLIED"
    },
    "ALEN": {
        "id": "ALEN",
        "name": "ALLEN ENGINEERING CORP"
    },
    "ALEV": {
        "id": "ALEV",
        "name": "ENDEAVOR XE BRAND MFG BY ALLIED RECREATION GROUP"
    },
    "ALEX": {
        "id": "ALEX",
        "name": "ALEXANDER REYNOLDS CORP"
    },
    "ALFA": {
        "id": "ALFA",
        "name": "ALFA ROMEO"
    },
    "ALFB": {
        "id": "ALFB",
        "name": "ALFAB INC"
    },
    "ALFD": {
        "id": "ALFD",
        "name": "ALFRED INDUSTRIES HENDERSON CO"
    },
    "ALFI": {
        "id": "ALFI",
        "name": "FLAIR MODEL MFG BY ALLIED RECREATIONAL GROUP INC"
    },
    "ALFL": {
        "id": "ALFL",
        "name": "ALFA OR ALFA LEISURE"
    },
    "ALFO": {
        "id": "ALFO",
        "name": "ALFORGE METALS CORP ONTARIO CANADA"
    },
    "ALIE": {
        "id": "ALIE",
        "name": "ALLIED"
    },
    "ALJA": {
        "id": "ALJA",
        "name": "ALJOA MODERNISTIC INDUSTRIES CALIFORNIA"
    },
    "ALJN": {
        "id": "ALJN",
        "name": "ALJON INC IOWA"
    },
    "ALJO": {
        "id": "ALJO",
        "name": "ALJO DIV OF SKYLINES HOMES"
    },
    "ALKO": {
        "id": "ALKO",
        "name": "ALOIS KOBER AL KO"
    },
    "ALL": {
        "id": "ALL",
        "name": "ALL AMERICAN CAMPERS"
    },
    "ALLA": {
        "id": "ALLA",
        "name": "ALLARD"
    },
    "ALLB": {
        "id": "ALLB",
        "name": "ALLIED AMERICAN BUILDERS CORP"
    },
    "ALLC": {
        "id": "ALLC",
        "name": "ALLIED MFG CO"
    },
    "ALLD": {
        "id": "ALLD",
        "name": "ALLIED PRODUCTS"
    },
    "ALLE": {
        "id": "ALLE",
        "name": "ALLEGRO MOTOR HOME MFG BY TIFFIN MOTORHOMES RED BAY AL"
    },
    "ALLF": {
        "id": "ALLF",
        "name": "ALLISONS FIBERGLASS MFG INC"
    },
    "ALLG": {
        "id": "ALLG",
        "name": "ALLEGHENY TRAILERS AKA INDUSTRIAL CORPORATION OF AMERICA CLEVELAND OH"
    },
    "ALLI": {
        "id": "ALLI",
        "name": "ALLIS CHALMERS"
    },
    "ALLK": {
        "id": "ALLK",
        "name": "ALL LAKES TRAILER"
    },
    "ALLL": {
        "id": "ALLL",
        "name": "ALLIED LEISURE INC"
    },
    "ALLM": {
        "id": "ALLM",
        "name": "ALL STEEL MFG CO"
    },
    "ALLN": {
        "id": "ALLN",
        "name": "ALLOY MACHINE PRODUCTS CORP"
    },
    "ALLO": {
        "id": "ALLO",
        "name": "ALLOY TRAILERS INC SPOKANE WA"
    },
    "ALLP": {
        "id": "ALLP",
        "name": "ALL PURPOSE TRAILER"
    },
    "ALLR": {
        "id": "ALLR",
        "name": "ALL STAR COACH INC"
    },
    "ALLS": {
        "id": "ALLS",
        "name": "ALL STATE"
    },
    "ALLT": {
        "id": "ALLT",
        "name": "ALL STATE TRAILER CO"
    },
    "ALLU": {
        "id": "ALLU",
        "name": "ALL STATES TRAILER CO"
    },
    "ALLV": {
        "id": "ALLV",
        "name": "ALL VEHICLE REG SERVICES SACRAMENTO CA"
    },
    "ALLW": {
        "id": "ALLW",
        "name": "ALLENTOWN BRAKE AND WHEEL SERVICE INC ALLENTOWN PA"
    },
    "ALMA": {
        "id": "ALMA",
        "name": "ALMA"
    },
    "ALMC": {
        "id": "ALMC",
        "name": "AL MAC PRODUCTS INC"
    },
    "ALMD": {
        "id": "ALMD",
        "name": "ALLMAND INC HOLDREGE NB"
    },
    "ALME": {
        "id": "ALME",
        "name": "ALMA TRAILER CO"
    },
    "ALMI": {
        "id": "ALMI",
        "name": "ALMAC INDUSTRIES"
    },
    "ALML": {
        "id": "ALML",
        "name": "ALUM-LINE INC CRES"
    },
    "ALMN": {
        "id": "ALMN",
        "name": "ALUMNA WELD INC FRIENDSHIP AR"
    },
    "ALMR": {
        "id": "ALMR",
        "name": "ALMAR MANUFACTURING SPA DOLLY AKA BCI MANUFACTURING BULTMAN COMPANY INC KANSAS"
    },
    "ALMU": {
        "id": "ALMU",
        "name": "ALUMCAR USA LLC TAMPA FL"
    },
    "ALMX": {
        "id": "ALMX",
        "name": "ALUMA TRAILERS AND MFG BECKER MN"
    },
    "ALNA": {
        "id": "ALNA",
        "name": "ALUMINA INC HURON OH"
    },
    "ALNM": {
        "id": "ALNM",
        "name": "ALLEN MANUFACTURING INC FORT MORGAN CO"
    },
    "ALNV": {
        "id": "ALNV",
        "name": "NAVIGATOR EX BRAND MFG BY ALLIED RECREATION GROUP"
    },
    "ALNZ": {
        "id": "ALNZ",
        "name": "ALLIANZ SWEEPERS FORMERLY KNOWN AS JOHNSTON SWEEPERS CHINO CA"
    },
    "ALOC": {
        "id": "ALOC",
        "name": "AMERICAN LOCOMOTIVE"
    },
    "ALOH": {
        "id": "ALOH",
        "name": "ALOHA TRAILER CO"
    },
    "ALOU": {
        "id": "ALOU",
        "name": "ALOUTTE"
    },
    "ALOY": {
        "id": "ALOY",
        "name": "ALLOY CUSTOM PRODUCTS INC OR ALLOY CUSTOM VACUUM PRODUCTS INC"
    },
    "ALPF": {
        "id": "ALPF",
        "name": "ALPINE MFG CO"
    },
    "ALPH": {
        "id": "ALPH",
        "name": "ALPHA PRODUCTS INTERNATIONAL INC"
    },
    "ALPI": {
        "id": "ALPI",
        "name": "ALPINE"
    },
    "ALPN": {
        "id": "ALPN",
        "name": "ALPINA SNOWMOBILES"
    },
    "ALPU": {
        "id": "ALPU",
        "name": "ALPINA USA LLC ALPINA BURKHARD BAVENSIEPEN GMBH GERMANY"
    },
    "ALPY": {
        "id": "ALPY",
        "name": "ALL PRO OF YULEE INC"
    },
    "ALRG": {
        "id": "ALRG",
        "name": "ALLIED RECREATION GROUP INC 2O16 BECAME REV RECREATION GROUP INC"
    },
    "ALRV": {
        "id": "ALRV",
        "name": "ARV BRAND MFG BY THE ALUMINUM TRAILER COMPANY"
    },
    "ALSC": {
        "id": "ALSC",
        "name": "SCEPTER MFG BY ALLIED RECREATIONAL GROUP"
    },
    "ALSE": {
        "id": "ALSE",
        "name": "ALL SEASONS MOTOR HOME"
    },
    "ALSP": {
        "id": "ALSP",
        "name": "ALSPORT STEEN ALSO SEE TRI SPORT STEEN"
    },
    "ALST": {
        "id": "ALST",
        "name": "ALLIED STEEL AND TRACTOR PRODUCTS INC"
    },
    "ALTA": {
        "id": "ALTA",
        "name": "ALTA OR ALTA CAR AND ENGINEERING"
    },
    "ALTC": {
        "id": "ALTC",
        "name": "THE ALUMINUM TRAILER COMPANY NAPPANEE IN"
    },
    "ALTE": {
        "id": "ALTE",
        "name": "ALTEC INDUSTRIES INDIANAPOLIS IN"
    },
    "ALTG": {
        "id": "ALTG",
        "name": "ALUMINUM TRAILER GROUP OCALA FL"
    },
    "ALTK": {
        "id": "ALTK",
        "name": "ALLIED TANK"
    },
    "ALTM": {
        "id": "ALTM",
        "name": "ALTA MOTORS OR FASTER FASTER INC BRISBANE CA"
    },
    "ALTO": {
        "id": "ALTO",
        "name": "ALTO BY PAR NADO INC QUEBEC CANADA"
    },
    "ALTP": {
        "id": "ALTP",
        "name": "ALTEC ENVIRONMENTAL PRODUCTS LLC CREEDMOOR NC"
    },
    "ALTR": {
        "id": "ALTR",
        "name": "TREK MFG BY ALLIED RECREATIONAL GROUP INC"
    },
    "ALTY": {
        "id": "ALTY",
        "name": "ALTURDYNE SAN DIEGO CA"
    },
    "ALUB": {
        "id": "ALUB",
        "name": "ALUMINUM BOAT TRAILERS WEST COAST ALUMUNUM BOAT TRAILERS"
    },
    "ALUC": {
        "id": "ALUC",
        "name": "ALUMA CRAFT"
    },
    "ALUE": {
        "id": "ALUE",
        "name": "ALOUETTE SEE ALOUETTE RECREATION PRODUCTS LTD"
    },
    "ALUL": {
        "id": "ALUL",
        "name": "ALUMA"
    },
    "ALUM": {
        "id": "ALUM",
        "name": "ALUMINUM TRAILERS MFG BY KIBBI LLC"
    },
    "ALUT": {
        "id": "ALUT",
        "name": "ALUTREC INC STE AGATHE QUEBEC CANADA"
    },
    "ALVI": {
        "id": "ALVI",
        "name": "ALVIS"
    },
    "ALVL": {
        "id": "ALVL",
        "name": "ALTA VENTURES LLC"
    },
    "ALVS": {
        "id": "ALVS",
        "name": "VESTA BRAND MFG BY ALLIED RECREATIONAL GROUP"
    },
    "ALWD": {
        "id": "ALWD",
        "name": "ALVEY WELDING LLC OLD TOWN FL"
    },
    "ALWW": {
        "id": "ALWW",
        "name": "ATLANTIC WESTWIND MOBILE HOME"
    },
    "ALWY": {
        "id": "ALWY",
        "name": "ALLOWAY MFG"
    },
    "ALXS": {
        "id": "ALXS",
        "name": "ALEXIS FIRE EQUIPMENT COMPANY ILLINOIS"
    },
    "ALYT": {
        "id": "ALYT",
        "name": "ALY TRAVEL TRAILERS DIV OF LAYTON HOMES CORP"
    },
    "AMAU": {
        "id": "AMAU",
        "name": "AMERICAN AUGERS INC"
    },
    "AMBA": {
        "id": "AMBA",
        "name": "AMBASSADOR"
    },
    "AMBC": {
        "id": "AMBC",
        "name": "AMERICAS BEST CORPORATION CROSSVILLE TN"
    },
    "AMC": {
        "id": "AMC",
        "name": "AMC TRAILER"
    },
    "AMCC": {
        "id": "AMCC",
        "name": "AMERICAN CLIPPER CORP MORGAN HILL CA"
    },
    "AMCE": {
        "id": "AMCE",
        "name": "AMERI CAN ENGINEERING ARGOS IN"
    },
    "AMCM": {
        "id": "AMCM",
        "name": "AMERI CAMP SYRACUSE IN"
    },
    "AMCO": {
        "id": "AMCO",
        "name": "A AND M COACH AC A C GREAT BRITIAN"
    },
    "AMCP": {
        "id": "AMCP",
        "name": "AM"
    },
    "AMCR": {
        "id": "AMCR",
        "name": "AMERICAN CRUISER MOTORHOME NAPPANEE IN"
    },
    "AMDB": {
        "id": "AMDB",
        "name": "AMERICAN DIRT BIKE INC CALIFORNIA"
    },
    "AMDI": {
        "id": "AMDI",
        "name": "AGRICULTURAL MACHINERY DIV DIV FMC"
    },
    "AMDV": {
        "id": "AMDV",
        "name": "AMAR MOTOR DEVELOPMENT OR AMD HOLLYWOOD FL"
    },
    "AME": {
        "id": "AME",
        "name": "AMERICAN TRAILERS INC DIV POLAR MFG CO"
    },
    "AMEA": {
        "id": "AMEA",
        "name": "AMERICAN BEAUTY MFD BY AMERICAN SHELTER SYSTEMS INC"
    },
    "AMEB": {
        "id": "AMEB",
        "name": "AMERICAN BODY AND TRAILER CO"
    },
    "AMEC": {
        "id": "AMEC",
        "name": "AMERICAN ALUMINUM CO"
    },
    "AMED": {
        "id": "AMED",
        "name": "AMERICAN COACH"
    },
    "AMEE": {
        "id": "AMEE",
        "name": "AMERICAN DURALITE CORP"
    },
    "AMEH": {
        "id": "AMEH",
        "name": "AMERICAN PRIDE HOMES DIV CHALLENGER HOMES"
    },
    "AMEI": {
        "id": "AMEI",
        "name": "AMERICAN PIPE AND STEEL CORP"
    },
    "AMEL": {
        "id": "AMEL",
        "name": "AMERICAN ECONOMOBILE HILIF"
    },
    "AMEM": {
        "id": "AMEM",
        "name": "AMERICAN TRAILER AND MFG"
    },
    "AMEN": {
        "id": "AMEN",
        "name": "AMEN"
    },
    "AMEP": {
        "id": "AMEP",
        "name": "AMERICAN WAY HOMES CORP"
    },
    "AMER": {
        "id": "AMER",
        "name": "AMERICAN MOTORS SEE MAKE RAMBLER FOR RAMBLERS MANUFACTURED PRIOR TO1966"
    },
    "AMES": {
        "id": "AMES",
        "name": "AMERIGO TRAVEL TRAILER"
    },
    "AMET": {
        "id": "AMET",
        "name": "AMERICAN TRAILER SPECIALISTS"
    },
    "AMF": {
        "id": "AMF",
        "name": "AMF INC"
    },
    "AMFA": {
        "id": "AMFA",
        "name": "AMERICAN DREAM MFG BY AMERICAN FAMILY HOMES INC"
    },
    "AMFR": {
        "id": "AMFR",
        "name": "AMERICAN FARRIER SYSTELS LLC TALKING ROCK GA"
    },
    "AMFT": {
        "id": "AMFT",
        "name": "AMERICAN FREEDOM TRAILERS INC"
    },
    "AMGN": {
        "id": "AMGN",
        "name": "AM GENERAL CORPORATION"
    },
    "AMHL": {
        "id": "AMHL",
        "name": "AMERICAN HAULER INDUSTRIES ELKHART IN"
    },
    "AMHO": {
        "id": "AMHO",
        "name": "AMERICAN HOIST AND DERRICK CO"
    },
    "AMI": {
        "id": "AMI",
        "name": "AMERICAN MICROCAR INC"
    },
    "AMID": {
        "id": "AMID",
        "name": "AMIDA INDUSTRIES ROCK HILL SC"
    },
    "AMIN": {
        "id": "AMIN",
        "name": "ADVANCE MIXER FORT WAYNE IN"
    },
    "AMJE": {
        "id": "AMJE",
        "name": "AMERICAN JENBACH CORP"
    },
    "AMLT": {
        "id": "AMLT",
        "name": "AMERLITE MFG BY GULF STREAM COACH INC"
    },
    "AMMA": {
        "id": "AMMA",
        "name": "AMERIND MACKISSIC INC"
    },
    "AMME": {
        "id": "AMME",
        "name": "AMMEX"
    },
    "AMMI": {
        "id": "AMMI",
        "name": "A M MACHINERY INC CANADA"
    },
    "AMMO": {
        "id": "AMMO",
        "name": "AMERICAN MANUFACTURING OPERATIONS INC TOLEDO OH"
    },
    "AMMT": {
        "id": "AMMT",
        "name": "AMERICAN MACHINE AND TOOLS CO"
    },
    "AMPA": {
        "id": "AMPA",
        "name": "PATRIOT-PATRIOT CRUISER BRAND BY AMERICAN COACH REV RECREATION GROUP"
    },
    "AMPC": {
        "id": "AMPC",
        "name": "AMP"
    },
    "AMPF": {
        "id": "AMPF",
        "name": "AMERICAN PERFORMANCE CYCLE BULLHEAD CITY AZ SPIRIT AND BIG BOY CYCLES"
    },
    "AMPH": {
        "id": "AMPH",
        "name": "AMPHICAR"
    },
    "AMPI": {
        "id": "AMPI",
        "name": "AMERICAN MADE PRODUCTS INC DELAND FL"
    },
    "AMPL": {
        "id": "AMPL",
        "name": "ADVANCED MARINE PERFORMANCE LLC"
    },
    "AMPT": {
        "id": "AMPT",
        "name": "AMPHICAT"
    },
    "AMQP": {
        "id": "AMQP",
        "name": "AMERIQUIP LAVERNE CA"
    },
    "AMQT": {
        "id": "AMQT",
        "name": "AMERICAN QUANTUM CYCLES INC MELBOURNE FL"
    },
    "AMRB": {
        "id": "AMRB",
        "name": "AMERICAN REDI BUILT TENNESSEE"
    },
    "AMRC": {
        "id": "AMRC",
        "name": "AMERICAN COUPLER SYSTEMS INC"
    },
    "AMRD": {
        "id": "AMRD",
        "name": "AMRAD PRODUCTS AND SUPPLY"
    },
    "AMRI": {
        "id": "AMRI",
        "name": "AMERICANA MOBILE HOMES INC"
    },
    "AMRO": {
        "id": "AMRO",
        "name": "AMERICAN ROAD MACHINERY INC"
    },
    "AMRP": {
        "id": "AMRP",
        "name": "AMREP INC ONTARIO CA"
    },
    "AMRR": {
        "id": "AMRR",
        "name": "AMERITRANS BY TMC GROUP ELKHART IN"
    },
    "AMSG": {
        "id": "AMSG",
        "name": "AMERICAN SIGNAL"
    },
    "AMSI": {
        "id": "AMSI",
        "name": "AMS GLOBAL INC"
    },
    "AMSP": {
        "id": "AMSP",
        "name": "MM SPIRIT"
    },
    "AMST": {
        "id": "AMST",
        "name": "AMERICAN STANDARD MANUFACTURED HOMES"
    },
    "AMSU": {
        "id": "AMSU",
        "name": "AMERICAN SURPLUS AND MANUFACTURING INC"
    },
    "AMSW": {
        "id": "AMSW",
        "name": "AMERICAN STEEL WORKS"
    },
    "AMT": {
        "id": "AMT",
        "name": "AMTEC TRAILER"
    },
    "AMTA": {
        "id": "AMTA",
        "name": "AMERITRAIL BELLVILLE TX"
    },
    "AMTC": {
        "id": "AMTC",
        "name": "AMERICAN TRANSPORTATION CORP"
    },
    "AMTE": {
        "id": "AMTE",
        "name": "AMERITEK"
    },
    "AMTH": {
        "id": "AMTH",
        "name": "AMTHOR INTERNATIONAL INC GRETNA VA"
    },
    "AMTK": {
        "id": "AMTK",
        "name": "A M TRIKES GERMANY"
    },
    "AMTL": {
        "id": "AMTL",
        "name": "AMERA TRAIL AMERA TRAIL TRAILERS"
    },
    "AMTN": {
        "id": "AMTN",
        "name": "AMERICAN TRENCHER INC"
    },
    "AMTR": {
        "id": "AMTR",
        "name": "AMERICAN TRACTOR EQUIPMENT CORP"
    },
    "AMTS": {
        "id": "AMTS",
        "name": "AMERICAN TRAVEL SYSTEMS INC ELKHART IN"
    },
    "AMTX": {
        "id": "AMTX",
        "name": "AMTEX ACQUISITION CORP"
    },
    "AMUS": {
        "id": "AMUS",
        "name": "AMUSEMENT DEVICES AND MANUFACTURING LLC AMUSEMENTS UNLIMITED"
    },
    "AMUT": {
        "id": "AMUT",
        "name": "AMERICAN UTULITY TRAILERS TRACY CA"
    },
    "AMWS": {
        "id": "AMWS",
        "name": "AMERICAN WELDING SERVICE INC BUFFALO NY"
    },
    "AMYP": {
        "id": "AMYP",
        "name": "AMERICAN YARD PRODUCTS WEEDEATER AND ELECTROLUX BRANDS"
    },
    "AMZT": {
        "id": "AMZT",
        "name": "AMAZE N TOW INC COLUMBUS GA"
    },
    "ANAI": {
        "id": "ANAI",
        "name": "ALLIANCE NORTH AMERICA INC PARAMOUNT CA"
    },
    "ANAL": {
        "id": "ANAL",
        "name": "ANA LOG"
    },
    "ANCH": {
        "id": "ANCH",
        "name": "AHOY MFD BY ANCHOR HOMES INC"
    },
    "ANDE": {
        "id": "ANDE",
        "name": "ANDER ETT INC"
    },
    "ANDH": {
        "id": "ANDH",
        "name": "ANDERSON COACH CO"
    },
    "ANDI": {
        "id": "ANDI",
        "name": "ANDERSON MOBILE HOMES MFG CO"
    },
    "ANDM": {
        "id": "ANDM",
        "name": "ANDREA MOBILE HOMES"
    },
    "ANDR": {
        "id": "ANDR",
        "name": "ANDREWS PAYLOADER"
    },
    "ANDS": {
        "id": "ANDS",
        "name": "ANDERSON MANUFACTURING CAMILLA GA"
    },
    "ANGE": {
        "id": "ANGE",
        "name": "ANGELUS TRAILER MFG CO"
    },
    "ANGL": {
        "id": "ANGL",
        "name": "ANGEL"
    },
    "ANSR": {
        "id": "ANSR",
        "name": "ANSER IND INC BRITISH COLUMBIA CANADA"
    },
    "ANTA": {
        "id": "ANTA",
        "name": "ANTARES TRAILERS LLC ACQUIRED BY DIRECT TRAILER LP TEXAS"
    },
    "ANTH": {
        "id": "ANTH",
        "name": "ANTHONY CO"
    },
    "ANTO": {
        "id": "ANTO",
        "name": "ANTON MFG CO"
    },
    "ANTR": {
        "id": "ANTR",
        "name": "ALUMINUM TRANSFER REPAIR COLEMAN TX"
    },
    "ANVL": {
        "id": "ANVL",
        "name": "ANVIL TRAILER LLC DOUGLAS GA"
    },
    "AOFI": {
        "id": "AOFI",
        "name": "APIARIES AND ORCHARD FORKLIFT INC"
    },
    "AOKT": {
        "id": "AOKT",
        "name": "A OK TRAILERS DELAND FL"
    },
    "AONE": {
        "id": "AONE",
        "name": "A1 CUSTOM TRAILER MFG INC TEXAS"
    },
    "APAC": {
        "id": "APAC",
        "name": "APACHE COACH CO"
    },
    "APAE": {
        "id": "APAE",
        "name": "APACHE CORP"
    },
    "APAH": {
        "id": "APAH",
        "name": "APACHEON COACH CO"
    },
    "APBD": {
        "id": "APBD",
        "name": "AMERICAN PORTABLE BUILDINGS"
    },
    "APCH": {
        "id": "APCH",
        "name": "APACHE TRAILERS DIV OF WORKFORCE TRAILERS INC FONTANA"
    },
    "APEC": {
        "id": "APEC",
        "name": "APE"
    },
    "APEX": {
        "id": "APEX",
        "name": "APEX TRAVELERS INC ELKHART IN"
    },
    "APIL": {
        "id": "APIL",
        "name": "CIVIAL AIR PATROL ILLINOIS"
    },
    "APIN": {
        "id": "APIN",
        "name": "ALPINE INDUSTRIAL LLC DEARY ID"
    },
    "APIT": {
        "id": "APIT",
        "name": "APPLIED INTEGRATION TECHNOLOGIES LLC"
    },
    "APJT": {
        "id": "APJT",
        "name": "APJ TRAILER WORKS LLC ODESSA FL"
    },
    "APLE": {
        "id": "APLE",
        "name": "APPLETON TRAILER"
    },
    "APLO": {
        "id": "APLO",
        "name": "APOLLO CHOPPERS II LLC KENTUCKY"
    },
    "APLU": {
        "id": "APLU",
        "name": "APLUS A PLUS TRAILERS INC SOUTHWEST RANCHES FL"
    },
    "APOL": {
        "id": "APOL",
        "name": "APOLLO HOMES"
    },
    "APPA": {
        "id": "APPA",
        "name": "APPALACHIAN MFG INC ELKHART IN"
    },
    "APPL": {
        "id": "APPL",
        "name": "APPLEBY CAMPER TRAILER"
    },
    "APPT": {
        "id": "APPT",
        "name": "APPLE TRAILERS HOT SPRINGS AR"
    },
    "APPV": {
        "id": "APPV",
        "name": "APPLESTONE VEHICLE"
    },
    "APRI": {
        "id": "APRI",
        "name": "APRILIA MOTORCYCLES"
    },
    "APSP": {
        "id": "APSP",
        "name": "APOLLO SPORTING PRODUCTS"
    },
    "APTL": {
        "id": "APTL",
        "name": "APLINE TRAILERS LLC LEWISTON ID"
    },
    "APVC": {
        "id": "APVC",
        "name": "APPLE VALLEY CARGO GEORGIA"
    },
    "APXM": {
        "id": "APXM",
        "name": "APEX MOTOR USA CHANDLER AZ"
    },
    "AQCR": {
        "id": "AQCR",
        "name": "AQUA CRUISER INC COLUMBIA TN"
    },
    "AQUA": {
        "id": "AQUA",
        "name": "AQUAFORGE WENTZVILLE MO"
    },
    "AQUT": {
        "id": "AQUT",
        "name": "AQUALAND TRAILERS DIV MID ATLANTIC METAL DISTRIBUTORS"
    },
    "ARAB": {
        "id": "ARAB",
        "name": "ARABI HOMES INC ARABI GA"
    },
    "ARBO": {
        "id": "ARBO",
        "name": "ARBOC SPECIALITY VEHICLES INDIANA"
    },
    "ARCA": {
        "id": "ARCA",
        "name": "ARCTIC CAT"
    },
    "ARCC": {
        "id": "ARCC",
        "name": "ARCT"
    },
    "ARCH": {
        "id": "ARCH",
        "name": "ARCH CARGO TRAILERS DOUGLAS GA"
    },
    "ARCM": {
        "id": "ARCM",
        "name": "ARCH MOTORCYCLE COMPANY LLC HAWTHORNE CALIFORNIA"
    },
    "ARCO": {
        "id": "ARCO",
        "name": "ARCOS BOAT TRAILER"
    },
    "ARCW": {
        "id": "ARCW",
        "name": "ARCWELD INC DADE CITY FL"
    },
    "ARDI": {
        "id": "ARDI",
        "name": "ARDIE MOTORCYCLES GERMANY"
    },
    "ARDN": {
        "id": "ARDN",
        "name": "ARDON TRAILERS CALIFORNIA"
    },
    "AREC": {
        "id": "AREC",
        "name": "AMERICAN ROAD EQUIPMENT OMAHA NB"
    },
    "ARGG": {
        "id": "ARGG",
        "name": "ARGO"
    },
    "ARGO": {
        "id": "ARGO",
        "name": "ARGONAUT STATE LIMOUSINE"
    },
    "ARGS": {
        "id": "ARGS",
        "name": "ARGOSY TRAVEL TRAILER VERSAILLES OH"
    },
    "ARGT": {
        "id": "ARGT",
        "name": "ARGO TRAILER CORP"
    },
    "ARIC": {
        "id": "ARIC",
        "name": "ARISTOCRAT TRAILER SALES DIV I B PERCH COMPANY"
    },
    "ARID": {
        "id": "ARID",
        "name": "ACTION RIDES INC"
    },
    "ARIE": {
        "id": "ARIE",
        "name": "ARIEL BRITISH LICENSED PRODUCTION TO BRAMMO_PRODUCTION TAKEN FROM BRAMMO AND LICENSED TO TRAK MOTORSPORTS INC"
    },
    "ARII": {
        "id": "ARII",
        "name": "ARISTOCRAT INDUSTRIES INC GOSHEN IN"
    },
    "ARIN": {
        "id": "ARIN",
        "name": "ARIENS CO"
    },
    "ARIS": {
        "id": "ARIS",
        "name": "ARISTOCRAT MOTOR HOME"
    },
    "ARIT": {
        "id": "ARIT",
        "name": "ARISTA"
    },
    "ARLB": {
        "id": "ARLB",
        "name": "ARLBERG"
    },
    "ARMA": {
        "id": "ARMA",
        "name": "ARMADILLO TRAILERS INC DEPORT TX"
    },
    "ARMC": {
        "id": "ARMC",
        "name": "ARMOR CHASSIS LLC RIDGELAND SC"
    },
    "ARMH": {
        "id": "ARMH",
        "name": "ARMOR HOMES OF VIRGINIA"
    },
    "ARML": {
        "id": "ARML",
        "name": "ARMOR LITE TRAILER MANUFACTURING LLC SIKESTON MO"
    },
    "ARMN": {
        "id": "ARMN",
        "name": "AIRMAN INC TRAILERS"
    },
    "ARMO": {
        "id": "ARMO",
        "name": "ARMOR MOBILE HOMES MFG"
    },
    "ARMQ": {
        "id": "ARMQ",
        "name": "ABS REMORQUES INC OR ABS TRAILERS QUEBEC CANADA"
    },
    "ARMR": {
        "id": "ARMR",
        "name": "ARMOR MOBILE"
    },
    "ARMS": {
        "id": "ARMS",
        "name": "ARMSTRONG SIDDELEY"
    },
    "ARMT": {
        "id": "ARMT",
        "name": "AMERICAN ROAD TRAILER CAMPER"
    },
    "ARMU": {
        "id": "ARMU",
        "name": "A R M"
    },
    "ARNA": {
        "id": "ARNA",
        "name": "ARNADA DIO"
    },
    "ARND": {
        "id": "ARND",
        "name": "ARNOLD TRAVEL TRAILER MFG CO"
    },
    "ARNE": {
        "id": "ARNE",
        "name": "ARNES WELDING"
    },
    "ARNG": {
        "id": "ARNG",
        "name": "ARISING INDUSTRIES INC HAZELHURST GA"
    },
    "ARNL": {
        "id": "ARNL",
        "name": "ARNOLD MANUFACTURING"
    },
    "ARNO": {
        "id": "ARNO",
        "name": "ARNOLT BRISTOL"
    },
    "ARNT": {
        "id": "ARNT",
        "name": "ARNDT TRAILERS HORSE TRAILERS"
    },
    "AROM": {
        "id": "AROM",
        "name": "AERO MANUFACTURING INC SYRACUSE IN"
    },
    "AROS": {
        "id": "AROS",
        "name": "AROS"
    },
    "ARPS": {
        "id": "ARPS",
        "name": "ARPS DIV DIV OF CHROMALLOY AMERICAN CORP"
    },
    "ARPT": {
        "id": "ARPT",
        "name": "AMERICAN TRANSPORTATION BUS"
    },
    "ARRC": {
        "id": "ARRC",
        "name": "ARROW CAMPERS INC"
    },
    "ARRM": {
        "id": "ARRM",
        "name": "ARROW MOTOR HOMES"
    },
    "ARRO": {
        "id": "ARRO",
        "name": "ARROW"
    },
    "ARRT": {
        "id": "ARRT",
        "name": "ARROW TRAILERS INC AFFILIATED WITH GREAT DANE TRAILERS MEMPHIS TN"
    },
    "ARRW": {
        "id": "ARRW",
        "name": "ARROWHEAD TRAILERS"
    },
    "ARSC": {
        "id": "ARSC",
        "name": "ASCORT"
    },
    "ARSN": {
        "id": "ARSN",
        "name": "ARSENAL TRAILER MANUFACTURING INC HARRISONBURG VA"
    },
    "ARTA": {
        "id": "ARTA",
        "name": "ART JAMES MFG"
    },
    "ARTG": {
        "id": "ARTG",
        "name": "ARTCRAFT OF GEORGIA"
    },
    "ARTH": {
        "id": "ARTH",
        "name": "ARTCRAFT MOBILE HOMES MFG CO"
    },
    "ARTI": {
        "id": "ARTI",
        "name": "ARTIE"
    },
    "ARTM": {
        "id": "ARTM",
        "name": "ART IN MOTION LLC FLORIDA"
    },
    "ARTX": {
        "id": "ARTX",
        "name": "ARTEX FABRICATORS LTD"
    },
    "ASA": {
        "id": "ASA",
        "name": "ASA"
    },
    "ASBU": {
        "id": "ASBU",
        "name": "ASBURY INDUSTRIES INC"
    },
    "ASBY": {
        "id": "ASBY",
        "name": "ASHBY BROTHERS"
    },
    "ASCN": {
        "id": "ASCN",
        "name": "ASCEND TRAILERS MFG BY EVERGREEN RECREATIONAL VEHICLES"
    },
    "ASCT": {
        "id": "ASCT",
        "name": "ALL STAR CUSTOM TRAILERS LLC MULBERRY FL"
    },
    "ASEC": {
        "id": "ASEC",
        "name": "ALL SOUTH EQUIPMENT CORPORATION BLUFFTON"
    },
    "ASGB": {
        "id": "ASGB",
        "name": "A SMITH GT BENTLEY LTD"
    },
    "ASHC": {
        "id": "ASHC",
        "name": "ASHCROFT ENTERPRISES LLC SALEM OR"
    },
    "ASHD": {
        "id": "ASHD",
        "name": "ASHDOWN MFG CORP ASHDOWN AR"
    },
    "ASHL": {
        "id": "ASHL",
        "name": "ASHLEY"
    },
    "ASHT": {
        "id": "ASHT",
        "name": "ASHTON TRAILER"
    },
    "ASI": {
        "id": "ASI",
        "name": "ASI TRAILER"
    },
    "ASIN": {
        "id": "ASIN",
        "name": "ASHLAND INDUSTRIES INC"
    },
    "ASIW": {
        "id": "ASIW",
        "name": "ASIAWING MOTORS"
    },
    "ASMF": {
        "id": "ASMF",
        "name": "AMERICAN STAR METAL FABRICATION LLC TIGARD OR"
    },
    "ASMH": {
        "id": "ASMH",
        "name": "ASTRL MOTORHOME"
    },
    "ASMO": {
        "id": "ASMO",
        "name": "AS MOTORS FRANCE"
    },
    "ASMT": {
        "id": "ASMT",
        "name": "ASM TRAILERS MOUNTAINBURG AR"
    },
    "ASPC": {
        "id": "ASPC",
        "name": "ASPEN CUSTOM TRAILERS ALBERTA CANADA"
    },
    "ASPE": {
        "id": "ASPE",
        "name": "ASPEN CAMPER INC FORMERLY KNOWN AS ASPEN METAL PRODUCTS ANDFABRICATORS"
    },
    "ASPL": {
        "id": "ASPL",
        "name": "ASPLUNDH MANUFACTURING DIV ASPLUNDH TREE EXPERT CO"
    },
    "ASPN": {
        "id": "ASPN",
        "name": "ASPEN TRAILER INC LITCHFIELD MN"
    },
    "ASPS": {
        "id": "ASPS",
        "name": "ASPES"
    },
    "ASPT": {
        "id": "ASPT",
        "name": "ASPT"
    },
    "ASPW": {
        "id": "ASPW",
        "name": "AMERICAN SPORTWORKS LLC OR ASW LLC DIV OF MANCO"
    },
    "ASQU": {
        "id": "ASQU",
        "name": "ASQUITH MOTOR CARRIAGE"
    },
    "ASSO": {
        "id": "ASSO",
        "name": "ALL STAR SOLUTIONS HUNTSVILLE AL"
    },
    "ASTA": {
        "id": "ASTA",
        "name": "ASTORIA MFG BY THOR MOTOR COACH INC"
    },
    "ASTC": {
        "id": "ASTC",
        "name": "AMERICAN SPORT TRAILER COMPANY LLC AZUSA CA"
    },
    "ASTE": {
        "id": "ASTE",
        "name": "ALL STEEL TRAILERS LLC JACKSONVILLE FL"
    },
    "ASTI": {
        "id": "ASTI",
        "name": "ASTEC IN DIV OF ASTEC INDUSTRIES CHATTANOOGA TN"
    },
    "ASTL": {
        "id": "ASTL",
        "name": "ALL STAR TRAILERS HIALEAH FL"
    },
    "ASTO": {
        "id": "ASTO",
        "name": "ASTON MARTIN"
    },
    "ASTR": {
        "id": "ASTR",
        "name": "ASTRO MOBILE HOMES MFG CO"
    },
    "ASTT": {
        "id": "ASTT",
        "name": "AIRSTREAM SPORT TRAVEL TRAILER MFG BY AIRSTREAM INC"
    },
    "ASUN": {
        "id": "ASUN",
        "name": "ASUNA"
    },
    "ASVE": {
        "id": "ASVE",
        "name": "ASSEMBLED VEHICLE SEE OPER MAN FOR DESCRIPTION"
    },
    "ATAI": {
        "id": "ATAI",
        "name": "ATLAS MFG BY AIRSTREAM"
    },
    "ATAS": {
        "id": "ATAS",
        "name": "ATLAS"
    },
    "ATCH": {
        "id": "ATCH",
        "name": "ATTACHMENT TECHNOLOGIES INC ATI ALSO INCLUDES BRADCO MAJORMCMILLEN PENGO BADGER AND C AND P ATI ACQUIRED BY NORWEST EQUITY PARTNERS"
    },
    "ATCI": {
        "id": "ATCI",
        "name": "ALL TERRAIN CAMPERS INC SACRAMENTO CA"
    },
    "ATCM": {
        "id": "ATCM",
        "name": "ALLTECH COMMUNICATIONS LLC TULSA OK"
    },
    "ATCO": {
        "id": "ATCO",
        "name": "ATLAS COP"
    },
    "ATCS": {
        "id": "ATCS",
        "name": "AMERICAN TOWER CUSTOMER SERVICE OKLAHOMA"
    },
    "ATDC": {
        "id": "ATDC",
        "name": "ACME TOW DOLLY COMPANY KERNERSVILLE NC"
    },
    "ATEC": {
        "id": "ATEC",
        "name": "ASPIRETEC INC"
    },
    "ATEK": {
        "id": "ATEK",
        "name": "ATEK LLC NEW HAMPSHIRE"
    },
    "ATEX": {
        "id": "ATEX",
        "name": "ATTEX"
    },
    "ATHE": {
        "id": "ATHE",
        "name": "ATHEY PRODUCTS CORP"
    },
    "ATHN": {
        "id": "ATHN",
        "name": "ATHENS PARK HOMES LLC ATHENS TX"
    },
    "ATII": {
        "id": "ATII",
        "name": "ART TEC INTERIORS INC BURNABY BRITISH COLUMBIA CANADA"
    },
    "ATK": {
        "id": "ATK",
        "name": "ATK AMERICA INC"
    },
    "ATKA": {
        "id": "ATKA",
        "name": "ATOKA TRAILER AND MFG LLC ATOKA OK"
    },
    "ATLA": {
        "id": "ATLA",
        "name": "ATLANTIC COAST CAMPER"
    },
    "ATLB": {
        "id": "ATLB",
        "name": "AMERICAN TRAILER BUILDERS MONTCLAIR CA"
    },
    "ATLC": {
        "id": "ATLC",
        "name": "ATLANTIC MOBILE CORP"
    },
    "ATLD": {
        "id": "ATLD",
        "name": "ATLAS DIVISION OF LONERGAN CORPORATION"
    },
    "ATLE": {
        "id": "ATLE",
        "name": "ATLAS ENTERPRISES INC GREENVILLE SC"
    },
    "ATLI": {
        "id": "ATLI",
        "name": "ATLANTIC INDUSTRIES INC HARDEEVILLE SC"
    },
    "ATLN": {
        "id": "ATLN",
        "name": "ATLANTIC MOBILE SALES"
    },
    "ATLS": {
        "id": "ATLS",
        "name": "ATLAS HOIST AND BODY INC"
    },
    "ATLT": {
        "id": "ATLT",
        "name": "ATLAS TOOL AND MFG CO"
    },
    "ATOC": {
        "id": "ATOC",
        "name": "A T O CONSTRUCTION EQUIPMENT DIV A-T-O INC"
    },
    "ATRK": {
        "id": "ATRK",
        "name": "ALUMA TREK INC DUNNELLO FL"
    },
    "ATSC": {
        "id": "ATSC",
        "name": "AMERICAN TRAILER SALES COMPANY BOISE ID"
    },
    "ATSL": {
        "id": "ATSL",
        "name": "ADVANCED TRAILER SOLUTIONS LLC CANBY OR"
    },
    "ATSP": {
        "id": "ATSP",
        "name": "ATLAS SPECIALITY PRODUCTS"
    },
    "ATTC": {
        "id": "ATTC",
        "name": "ASSOCIATED TRUCK AND TRAILER"
    },
    "ATTR": {
        "id": "ATTR",
        "name": "ADVANCED TRANSPORTATION TECHNOLOGY R AND D"
    },
    "ATV": {
        "id": "ATV",
        "name": "ALL TERRAIN VEHICLE"
    },
    "ATWC": {
        "id": "ATWC",
        "name": "ALUMA TOWER COMPANY INC VERO BEACH FL"
    },
    "ATWK": {
        "id": "ATWK",
        "name": "AMERICAN TRAILER WORKS SUMNER TEXAS"
    },
    "ATWO": {
        "id": "ATWO",
        "name": "ATWOOD MOBILE HOMES OR ATWOOD MOBILE PRODUCTS INDIANA"
    },
    "ATWW": {
        "id": "ATWW",
        "name": "ATW OHIO LLC MOUNT ORAB OH"
    },
    "AUAM": {
        "id": "AUAM",
        "name": "AMBITION BRAND MFG BY AUGUSTA RV LLC"
    },
    "AUBN": {
        "id": "AUBN",
        "name": "AUBURN HOMES"
    },
    "AUBU": {
        "id": "AUBU",
        "name": "AUBURN"
    },
    "AUDI": {
        "id": "AUDI",
        "name": "AUDI"
    },
    "AUFL": {
        "id": "AUFL",
        "name": "FLEX MFG BY AUGUSTA RV LLC BRISTOL IN"
    },
    "AUG": {
        "id": "AUG",
        "name": "AUGUSTANA"
    },
    "AUGU": {
        "id": "AUGU",
        "name": "AUGUSTA RV LLC BRISTOL IN"
    },
    "AUHA": {
        "id": "AUHA",
        "name": "AUTOHAUS OF COLORADO NORTH GLENN CO"
    },
    "AUHE": {
        "id": "AUHE",
        "name": "AUSTIN HEALY"
    },
    "AUIN": {
        "id": "AUIN",
        "name": "AUTOMOTIVE INNOVATIONS INC SMYRNA BEACH FL"
    },
    "AUKR": {
        "id": "AUKR",
        "name": "AUTOKRAFT"
    },
    "AULI": {
        "id": "AULI",
        "name": "AULICK MANUFACTURING SCOTTSBLUFF NB"
    },
    "AULX": {
        "id": "AULX",
        "name": "LUXE MODEL MFG BY AUGUSTA RV"
    },
    "AUPA": {
        "id": "AUPA",
        "name": "HONGDOU AUPA MOTORCYCLE"
    },
    "AUPR": {
        "id": "AUPR",
        "name": "AUSTIN PRODUCTS INC SUBSIDIARY AUSTIN INDUSTRIES INC"
    },
    "AURA": {
        "id": "AURA",
        "name": "AURANTHETIC CHARGER"
    },
    "AURO": {
        "id": "AURO",
        "name": "AURORA MOBILE HOMES"
    },
    "AURR": {
        "id": "AURR",
        "name": "AURORA"
    },
    "AUSA": {
        "id": "AUSA",
        "name": "AUSA US CORP EL SEGUNDO CA"
    },
    "AUST": {
        "id": "AUST",
        "name": "AUSTIN"
    },
    "AUTA": {
        "id": "AUTA",
        "name": "AUTOBIANCHI"
    },
    "AUTB": {
        "id": "AUTB",
        "name": "AUTOBIEU"
    },
    "AUTE": {
        "id": "AUTE",
        "name": "AUTOMATIC EQUIPMENT"
    },
    "AUTH": {
        "id": "AUTH",
        "name": "AUTO HOMES INDUSTRIES"
    },
    "AUTI": {
        "id": "AUTI",
        "name": "AUTO TRAILER CO"
    },
    "AUTM": {
        "id": "AUTM",
        "name": "AUTO MATE"
    },
    "AUTO": {
        "id": "AUTO",
        "name": "AUTOCAR OR AUTOCAR LLC"
    },
    "AUTR": {
        "id": "AUTR",
        "name": "AUTOCARRIER AND A C"
    },
    "AUTS": {
        "id": "AUTS",
        "name": "AUTO SKI INC LEVIS QUEBEC"
    },
    "AUTT": {
        "id": "AUTT",
        "name": "AUTOMATIC DRILLING MACHINES INC"
    },
    "AUTU": {
        "id": "AUTU",
        "name": "AUTO UNION"
    },
    "AUTW": {
        "id": "AUTW",
        "name": "AUTO TOW AUTO CAR TOW DOLLY"
    },
    "AUWE": {
        "id": "AUWE",
        "name": "AUSTIN WESTERN DIV CLARK EQUIPMENT CO"
    },
    "AVA": {
        "id": "AVA",
        "name": "AVA TRAVEL TRAILER"
    },
    "AVAC": {
        "id": "AVAC",
        "name": "AVALAIR CORP"
    },
    "AVAL": {
        "id": "AVAL",
        "name": "AVAILABLE"
    },
    "AVAN": {
        "id": "AVAN",
        "name": "AVANTI CYCLES"
    },
    "AVAO": {
        "id": "AVAO",
        "name": "AVALON MOBILE HOMES"
    },
    "AVCO": {
        "id": "AVCO",
        "name": "AV"
    },
    "AVEN": {
        "id": "AVEN",
        "name": "AVENGER"
    },
    "AVGN": {
        "id": "AVGN",
        "name": "AVENGER CORPORATION MICHIGAN"
    },
    "AVIA": {
        "id": "AVIA",
        "name": "AVIA"
    },
    "AVIO": {
        "id": "AVIO",
        "name": "AVION COACH CORP"
    },
    "AVLN": {
        "id": "AVLN",
        "name": "AVALLONE CARS BRAZIL"
    },
    "AVLT": {
        "id": "AVLT",
        "name": "AEROVAULT LLC ROBAN LAS VEGAS NV"
    },
    "AVNT": {
        "id": "AVNT",
        "name": "AVANT TOW DOLLY"
    },
    "AVRA": {
        "id": "AVRA",
        "name": "AVERA MOTORS INC FLORIDA"
    },
    "AVSP": {
        "id": "AVSP",
        "name": "ADVENTURE SPORTS PRODUCTS INC CO"
    },
    "AVTI": {
        "id": "AVTI",
        "name": "AVANTI"
    },
    "AVTO": {
        "id": "AVTO",
        "name": "AVTOVAZ RUSSIA"
    },
    "AWAY": {
        "id": "AWAY",
        "name": "AWAY WE GO"
    },
    "AWDE": {
        "id": "AWDE",
        "name": "ALL WHEEL DRIVE EQUIP MFG INC TULSA OK"
    },
    "AWRD": {
        "id": "AWRD",
        "name": "AWARD RECREATIONAL VEHICLES INC PREV KNOWN AS ABI LEISURE PRODUCTS ONTARIO CANADA"
    },
    "AXIS": {
        "id": "AXIS",
        "name": "AXIS CORPORATION OHIO"
    },
    "AXLE": {
        "id": "AXLE",
        "name": "AXLE AND EQUIPMENT SALES"
    },
    "AXUL": {
        "id": "AXUL",
        "name": "AXIS UNLIMITED LLC ELKHART IN"
    },
    "AYRW": {
        "id": "AYRW",
        "name": "AYR WAY CAMPERSDIV K V MOLDED PRODUCTS INC"
    },
    "AYTR": {
        "id": "AYTR",
        "name": "ALMIGHTY TRAILERS LLC CAPE CORAL FL"
    },
    "AZAL": {
        "id": "AZAL",
        "name": "AZALEA HOMES"
    },
    "AZCA": {
        "id": "AZCA",
        "name": "AZCAL TRAILERS"
    },
    "AZMF": {
        "id": "AZMF",
        "name": "A Z MANUFACTURING MADERA CA"
    },
    "AZML": {
        "id": "AZML",
        "name": "ARIZONA MILLWORK INC"
    },
    "AZMP": {
        "id": "AZMP",
        "name": "ARIZONA METAL PRODUCTS PHOENIX AZ"
    },
    "AZTC": {
        "id": "AZTC",
        "name": "AZTEC PRODUCTS INC MANSFIELD TX"
    },
    "AZTE": {
        "id": "AZTE",
        "name": "AZTEC MOBILE HOMES"
    },
    "AZTM": {
        "id": "AZTM",
        "name": "ARIZONA TRAILER MANUFACTURING INC BUCKEYE AZ"
    },
    "AZTS": {
        "id": "AZTS",
        "name": "ARIZONA TRAILER SPECIALIST INC TUCSON AZ"
    },
    "AZTT": {
        "id": "AZTT",
        "name": "AZTEC TRAILER INC"
    },
    "AZTX": {
        "id": "AZTX",
        "name": "AZTEX OR AZ TEX TRAILERS FONTANA CA"
    },
    "AZUR": {
        "id": "AZUR",
        "name": "AZURE DYNAMICS OAK PARK MI"
    },
    "BABC": {
        "id": "BABC",
        "name": "BABCOCKS MOBILE HOME MFG CO"
    },
    "BABL": {
        "id": "BABL",
        "name": "BAR BEL FABRICATING"
    },
    "BACC": {
        "id": "BACC",
        "name": "BAR C HORSE TRAILER"
    },
    "BACK": {
        "id": "BACK",
        "name": "BACKYARD CHOPPERS LLC DUNNELLON FL"
    },
    "BACR": {
        "id": "BACR",
        "name": "BACKROAD CHOPPERS OR BACK ROAD CHOPPERS LLC DENAIR CA"
    },
    "BADA": {
        "id": "BADA",
        "name": "BADD ASS INDUSTRIES LLC ENGLEWOOD CO"
    },
    "BADB": {
        "id": "BADB",
        "name": "BAD 2D BONE TRAILERS INC POMPANO BEACH FL"
    },
    "BADC": {
        "id": "BADC",
        "name": "BADGER CONSTRUCTION EQUIPMENT"
    },
    "BADE": {
        "id": "BADE",
        "name": "BALDERSON INC"
    },
    "BADG": {
        "id": "BADG",
        "name": "BADGER TRAILER CO"
    },
    "BADR": {
        "id": "BADR",
        "name": "BADGER MACHINE DIV RON"
    },
    "BAES": {
        "id": "BAES",
        "name": "BAE SYSTEMS LAND AND ARMAMENTS COMBAT VEHS ARTILLERY NAVAL GUNS MISSILE"
    },
    "BAGC": {
        "id": "BAGC",
        "name": "BEIJING AUTOMOTIVE GROUP"
    },
    "BAGG": {
        "id": "BAGG",
        "name": "BAGGETTS TRAILER CONECTION ALABAMA"
    },
    "BAGL": {
        "id": "BAGL",
        "name": "THE BAG LADY INC MEGGA BAGGER SAN"
    },
    "BAIH": {
        "id": "BAIH",
        "name": "BEIJING AUTOMOTIVE INDUSTRY HOLDING"
    },
    "BAIL": {
        "id": "BAIL",
        "name": "BAILLIES TRI R WELDING"
    },
    "BAIN": {
        "id": "BAIN",
        "name": "BAINBRIDGE MOTOR HOME"
    },
    "BAJA": {
        "id": "BAJA",
        "name": "BAJA USA OR BAJA MOTORSPORTS"
    },
    "BAJJ": {
        "id": "BAJJ",
        "name": "BAJAJ AUTO"
    },
    "BAKB": {
        "id": "BAKB",
        "name": "BAKER BOAT TRAILERS INC OR BAKER TRAILERS"
    },
    "BAKE": {
        "id": "BAKE",
        "name": "BAKER EQUIPMENT AND ENGINEERING CO"
    },
    "BAKI": {
        "id": "BAKI",
        "name": "BAKER TRAILER AND BODY CO"
    },
    "BAKR": {
        "id": "BAKR",
        "name": "BAKER MATERIAL HANDLING CORP."
    },
    "BALB": {
        "id": "BALB",
        "name": "BALBOA MOTOR HOME"
    },
    "BALD": {
        "id": "BALD",
        "name": "BALDWIN ENTERPRISES"
    },
    "BALE": {
        "id": "BALE",
        "name": "BALEMUVR"
    },
    "BALI": {
        "id": "BALI",
        "name": "BALKO"
    },
    "BALK": {
        "id": "BALK",
        "name": "BALKAN"
    },
    "BALL": {
        "id": "BALL",
        "name": "BALLIEN MFG."
    },
    "BALT": {
        "id": "BALT",
        "name": "BALTZ INDUSTRIES"
    },
    "BALZ": {
        "id": "BALZ",
        "name": "BALZER MANUFACTURING CORP."
    },
    "BAMA": {
        "id": "BAMA",
        "name": "BADGER-NORTHLAND"
    },
    "BAMC": {
        "id": "BAMC",
        "name": "BARON MOTORCYCLES COMPANY"
    },
    "BAME": {
        "id": "BAME",
        "name": "BAME THREE AXLE TRAILER"
    },
    "BAMH": {
        "id": "BAMH",
        "name": "BARRINGTON HOMES SUBSIDIARY FLEETWOOD ENTERPRISES"
    },
    "BAND": {
        "id": "BAND",
        "name": "BANDERA TRAILER"
    },
    "BANE": {
        "id": "BANE",
        "name": "BANENS TRAILERS"
    },
    "BANG": {
        "id": "BANG",
        "name": "BANGERT CLAIFORNIA"
    },
    "BANH": {
        "id": "BANH",
        "name": "BANKHEAD ENTERPRISES"
    },
    "BANK": {
        "id": "BANK",
        "name": "BANKHEAD WELDING SERVICE"
    },
    "BANM": {
        "id": "BANM",
        "name": "BSA BANTAM BIRMINGHAM SMALL ARMS"
    },
    "BANN": {
        "id": "BANN",
        "name": "BANNER INDUSTRIES"
    },
    "BANT": {
        "id": "BANT",
        "name": "SCHIELD-BANTAM DIV OF KOEHRING CO"
    },
    "BAOD": {
        "id": "BAOD",
        "name": "BAODI SPECIAL AUTOMOBILE MANUFACTURE"
    },
    "BAOT": {
        "id": "BAOT",
        "name": "BAOTIAN MOTORCYCLE INDUSTRIAL"
    },
    "BARA": {
        "id": "BARA",
        "name": "BAR-A-HORSE TRAILER"
    },
    "BARB": {
        "id": "BARB",
        "name": "TELSMITH DIV."
    },
    "BARC": {
        "id": "BARC",
        "name": "BARCRAFT HOMES"
    },
    "BARE": {
        "id": "BARE",
        "name": "BARRETT TRAILERS"
    },
    "BARH": {
        "id": "BARH",
        "name": "BARTH CORP"
    },
    "BARI": {
        "id": "BARI",
        "name": "BARRENTINE MFG."
    },
    "BARK": {
        "id": "BARK",
        "name": "BARKER TRAILER MANUFACTURERS"
    },
    "BARL": {
        "id": "BARL",
        "name": "BARTLETT TRAILER CORP CHICAGO"
    },
    "BARN": {
        "id": "BARN",
        "name": "BARNES PUMP DRAIN UNIT"
    },
    "BARO": {
        "id": "BARO",
        "name": "BARON HOMES"
    },
    "BARR": {
        "id": "BARR",
        "name": "BARRET"
    },
    "BART": {
        "id": "BART",
        "name": "BARTOLINI CHASSIS TRAILER"
    },
    "BASH": {
        "id": "BASH",
        "name": "BASHAN MOTOR OR BASHAN MOTORCYCLE"
    },
    "BASS": {
        "id": "BASS",
        "name": "BASS TRAIL TRAILER"
    },
    "BATA": {
        "id": "BATA",
        "name": "BATAVIA TRAILERS"
    },
    "BATB": {
        "id": "BATB",
        "name": "BATTISTI BUS MFG BY FOREST RIVER INC"
    },
    "BATC": {
        "id": "BATC",
        "name": "BAT CUSTOM TRAILERS AND WELDING ROBER FORT WORTH"
    },
    "BATL": {
        "id": "BATL",
        "name": "B & A TRAILERS SOUTH CAROLINA"
    },
    "BATM": {
        "id": "BATM",
        "name": "BANTAM CAMP TRAILER"
    },
    "BATT": {
        "id": "BATT",
        "name": "BATTISTI CUSTOMS ELKHART"
    },
    "BAUE": {
        "id": "BAUE",
        "name": "BAUER LIMITED PRODUCTION AUTO KIT CAR"
    },
    "BAUG": {
        "id": "BAUG",
        "name": "BAUGHMAN MFG."
    },
    "BAUM": {
        "id": "BAUM",
        "name": "BAUGHMAN PRODUCTS LAYTON"
    },
    "BAUR": {
        "id": "BAUR",
        "name": "BAUER COMPRESSORS NORFOLK"
    },
    "BAUT": {
        "id": "BAUT",
        "name": "BAUTISTA WELDING AND CUSTOM TRAILERS MONTICELLO GA"
    },
    "BAUX": {
        "id": "BAUX",
        "name": "BAUXLITE PINE HILL MANUFACTURING LLC GORDONVILLE PA"
    },
    "BAY": {
        "id": "BAY",
        "name": "BAY TRAILERS"
    },
    "BAYC": {
        "id": "BAYC",
        "name": "BAY CITY SHOVELS"
    },
    "BAYU": {
        "id": "BAYU",
        "name": "BAYOU TRAILERS"
    },
    "BBBC": {
        "id": "BBBC",
        "name": "BARE BONES BOBBERS AND CHOPPERS"
    },
    "BBBR": {
        "id": "BBBR",
        "name": "BOBBER SHOP"
    },
    "BBBS": {
        "id": "BBBS",
        "name": "B.B'S TRAILERS"
    },
    "BBCB": {
        "id": "BBCB",
        "name": "B & B CUSTOM BUILT TRAILERS NEWPORT ARKANSAS"
    },
    "BBCC": {
        "id": "BBCC",
        "name": "BIG BOAR CUSTOM CYCLES NEW JERSEY"
    },
    "BBCH": {
        "id": "BBCH",
        "name": "B & B COZY-HOME"
    },
    "BBCT": {
        "id": "BBCT",
        "name": "BOBCAT MFG BY LAYTON HOMES CORP"
    },
    "BBEE": {
        "id": "BBEE",
        "name": "BEEBEE SALES"
    },
    "BBEN": {
        "id": "BBEN",
        "name": "B & B ENTERPRISES ARIZONA TRAILERS"
    },
    "BBFB": {
        "id": "BBFB",
        "name": "B AND B FABRICATION LLC"
    },
    "BBLT": {
        "id": "BBLT",
        "name": "BEST BUILT TRAILER PARENT COMPANY CL WHIGHAM"
    },
    "BBMI": {
        "id": "BBMI",
        "name": "B & B MANUFACTURING INC"
    },
    "BBMW": {
        "id": "BBMW",
        "name": "BAD BOY MOWERS BATESVILLE"
    },
    "BBND": {
        "id": "BBND",
        "name": "BIG BEND TRAILERS TEXAS"
    },
    "BBOY": {
        "id": "BBOY",
        "name": "BAD BOY BUGGIES"
    },
    "BBRM": {
        "id": "BBRM",
        "name": "BROWN BROTHERS RACING MOTORSPORTS"
    },
    "BBRN": {
        "id": "BBRN",
        "name": "BIKER BARN OR BIKER BARN CYCLES NEW YORK"
    },
    "BBTM": {
        "id": "BBTM",
        "name": "B & B TRAILER MFG."
    },
    "BBW": {
        "id": "BBW",
        "name": "BOURGET'S BIKE WORKS"
    },
    "BBWI": {
        "id": "BBWI",
        "name": "BROWNELL BOAT WORKS INC."
    },
    "BCAM": {
        "id": "BCAM",
        "name": "BUS AND COACH AMERICA BCA  AZUSA"
    },
    "BCAT": {
        "id": "BCAT",
        "name": "BASS CAT"
    },
    "BCBC": {
        "id": "BCBC",
        "name": "BRAXTON CREEK MFG BY BRAXTON CREEK RV LLC SHIPSHEWANA IN"
    },
    "BCGC": {
        "id": "BCGC",
        "name": "BUDGET CUSTOM GOLF CARS LLC"
    },
    "BCIB": {
        "id": "BCIB",
        "name": "BCI OR BUS AND COACH INTERNATIONAL CHINA ALSO KNOWN AS DONGFENG YANGTSE"
    },
    "BCIO": {
        "id": "BCIO",
        "name": "BACCIO MOPEDS"
    },
    "BCKC": {
        "id": "BCKC",
        "name": "BECK CORPORATION ELKHART"
    },
    "BCKM": {
        "id": "BCKM",
        "name": "THE BUCKMOBILE"
    },
    "BCKY": {
        "id": "BCKY",
        "name": "BACKYARD TRAILERS"
    },
    "BCMH": {
        "id": "BCMH",
        "name": "BEACON MANUFACTURED HOME"
    },
    "BCMW": {
        "id": "BCMW",
        "name": "BEACON METAL WORKS"
    },
    "BCRL": {
        "id": "BCRL",
        "name": "BRAXTON CREEK RV LLC SHIPSHEWANA IN"
    },
    "BCRT": {
        "id": "BCRT",
        "name": "BLASTCRETE EQUIPMENT COMPANY ALABAMA TRAILER MOPUNTED COEQ"
    },
    "BCTI": {
        "id": "BCTI",
        "name": "BCI TRAILER MANUFACTURING"
    },
    "BDBY": {
        "id": "BDBY",
        "name": "BAD BOY"
    },
    "BDFL": {
        "id": "BDFL",
        "name": "BENDER-FLORIN"
    },
    "BDOG": {
        "id": "BDOG",
        "name": "BULLDOG CARTS BUFORD"
    },
    "BDRD": {
        "id": "BDRD",
        "name": "BEDARD TANKERS"
    },
    "BDTC": {
        "id": "BDTC",
        "name": "BULL DAWG TRAILER COMPANY LLC LEXINGTON NC"
    },
    "BEAC": {
        "id": "BEAC",
        "name": "BEACHCOMBER INDUSTRIES LTD.MORDEN"
    },
    "BEAD": {
        "id": "BEAD",
        "name": "BEARDMORE"
    },
    "BEAE": {
        "id": "BEAE",
        "name": "BEAVER MONTEREY MOTOR HOME"
    },
    "BEAI": {
        "id": "BEAI",
        "name": "BEAIRD PAYLINER SHREVEPORT LOUISIANA"
    },
    "BEAL": {
        "id": "BEAL",
        "name": "BEALL"
    },
    "BEAM": {
        "id": "BEAM",
        "name": "BEACH-CRAFT MOTOR HOMES CORP.ELKHART"
    },
    "BEAN": {
        "id": "BEAN",
        "name": "BEAR INDUSTRIAL TRAILERS"
    },
    "BEAR": {
        "id": "BEAR",
        "name": "MOTOR HOMES OF AMERICA"
    },
    "BEAS": {
        "id": "BEAS",
        "name": "BERETTA ALFREDO SRL ITALY"
    },
    "BEAT": {
        "id": "BEAT",
        "name": "BEALL TRANS-LINER"
    },
    "BEAV": {
        "id": "BEAV",
        "name": "BEAVER TRAILER"
    },
    "BEBE": {
        "id": "BEBE",
        "name": "BEBE"
    },
    "BEBO": {
        "id": "BEBO",
        "name": "BEE BOB TRAILERS"
    },
    "BEC": {
        "id": "BEC",
        "name": "BECK CAMPING TRAILER"
    },
    "BECK": {
        "id": "BECK",
        "name": "BECKER"
    },
    "BECO": {
        "id": "BECO",
        "name": "BRUTE ENGINEERING COMPANY INC GREENVILLE TX"
    },
    "BEDE": {
        "id": "BEDE",
        "name": "BEDELL TRAILERS"
    },
    "BEDF": {
        "id": "BEDF",
        "name": "BEDFORD"
    },
    "BEDX": {
        "id": "BEDX",
        "name": "BENDIX CORP.MFR. OF MOBILE HOMES AND RECREATIONALVEHICLES"
    },
    "BEEA": {
        "id": "BEEA",
        "name": "BEECHCRAFT MOBILE HOME"
    },
    "BEEC": {
        "id": "BEEC",
        "name": "BEECHWOOD INDUSTRIES"
    },
    "BEEE": {
        "id": "BEEE",
        "name": "BEE-LINE TRAVEL TRAILERS OR MOBILE HOMES INC."
    },
    "BEEG": {
        "id": "BEEG",
        "name": "BEEMER & GRUBB ENTERPRISES"
    },
    "BEEH": {
        "id": "BEEH",
        "name": "BEECHWOOD MOTOR HOME"
    },
    "BEEI": {
        "id": "BEEI",
        "name": "BEE TRAILERS INC CLIMAX"
    },
    "BEEM": {
        "id": "BEEM",
        "name": "BEEMER TRAILER MFG. & SALES"
    },
    "BEET": {
        "id": "BEET",
        "name": "BEEAT TRUCK MOUNT CAMPER"
    },
    "BEGS": {
        "id": "BEGS",
        "name": "BENAZZATO GRU SPA ITALY"
    },
    "BEIL": {
        "id": "BEIL",
        "name": "BEILER HYDRAULICS. INC. LEOLA"
    },
    "BEJE": {
        "id": "BEJE",
        "name": "BEIJING JEEP"
    },
    "BELA": {
        "id": "BELA",
        "name": "BEL-AIRE CAMPERS OR COACHES"
    },
    "BELE": {
        "id": "BELE",
        "name": "BEL-AIRE MOBILE HOMES"
    },
    "BELF": {
        "id": "BELF",
        "name": "BELL MFG. CORP."
    },
    "BELH": {
        "id": "BELH",
        "name": "BELSHE TRAILER TECUMSEH"
    },
    "BELL": {
        "id": "BELL",
        "name": "BELLA CASA"
    },
    "BELN": {
        "id": "BELN",
        "name": "BELLAMY'S MFR. & RESEARCH PRODUCTSHILTONS"
    },
    "BELP": {
        "id": "BELP",
        "name": "BELLAIRE PRODUCTS"
    },
    "BELR": {
        "id": "BELR",
        "name": "BELLEW'S PERRIS BALLEY CAMPERS"
    },
    "BELS": {
        "id": "BELS",
        "name": "BELL'S CAMP TRAILERS"
    },
    "BELT": {
        "id": "BELT",
        "name": "BELL'S CUSTOM COACHES"
    },
    "BELU": {
        "id": "BELU",
        "name": "BELARUS MACHINERY"
    },
    "BELV": {
        "id": "BELV",
        "name": "BELVEDERE MOBILE HOMES"
    },
    "BEMH": {
        "id": "BEMH",
        "name": "BELAIRE MANUFACTURED HOME"
    },
    "BEND": {
        "id": "BEND",
        "name": "BENDRON ENTERPRISES DOUGLAS"
    },
    "BENE": {
        "id": "BENE",
        "name": "BENELLI"
    },
    "BENJ": {
        "id": "BENJ",
        "name": "CHANGLING BENJIAN VEHICLE"
    },
    "BENL": {
        "id": "BENL",
        "name": "BENLO"
    },
    "BENN": {
        "id": "BENN",
        "name": "BENNCHE"
    },
    "BENO": {
        "id": "BENO",
        "name": "BENOTTO"
    },
    "BENS": {
        "id": "BENS",
        "name": "BENSON TRUCK BODIES"
    },
    "BENT": {
        "id": "BENT",
        "name": "BENTLEY"
    },
    "BEPL": {
        "id": "BEPL",
        "name": "BRUDER EXPEDITIONS PTY"
    },
    "BEPO": {
        "id": "BEPO",
        "name": "BEAIRD-POULAN DIV."
    },
    "BERE": {
        "id": "BERE",
        "name": "BERKELEY PUMP"
    },
    "BERG": {
        "id": "BERG",
        "name": "BERGANTINE"
    },
    "BERH": {
        "id": "BERH",
        "name": "BERKSHIRE INDUSTRIES"
    },
    "BERK": {
        "id": "BERK",
        "name": "BERKLEY"
    },
    "BERM": {
        "id": "BERM",
        "name": "BERMAR MFG. CORP."
    },
    "BERO": {
        "id": "BERO",
        "name": "BERTONE"
    },
    "BERR": {
        "id": "BERR",
        "name": "BERING"
    },
    "BERT": {
        "id": "BERT",
        "name": "BERTRAN TRAILER"
    },
    "BERV": {
        "id": "BERV",
        "name": "BER-VAC"
    },
    "BESA": {
        "id": "BESA",
        "name": "BESASIE AUTOMOBILE"
    },
    "BESC": {
        "id": "BESC",
        "name": "BES"
    },
    "BEST": {
        "id": "BEST",
        "name": "BEST LANE ENTERPRISES INC"
    },
    "BETA": {
        "id": "BETA",
        "name": "BETA"
    },
    "BETE": {
        "id": "BETE",
        "name": "BETTEN FRANCE TRANSPORTATION KENTUCKY"
    },
    "BETH": {
        "id": "BETH",
        "name": "BETHANY FELLOWSHIP"
    },
    "BETT": {
        "id": "BETT",
        "name": "BETTER BUILT"
    },
    "BEVI": {
        "id": "BEVI",
        "name": "BOULDER ELECTRIC VEHICLE INC BOULDER CO"
    },
    "BEVR": {
        "id": "BEVR",
        "name": "BEAVER MFG. CORP."
    },
    "BFMC": {
        "id": "BFMC",
        "name": "BF MEYERS AND COMPANY VALLEY CENTER CA"
    },
    "BFOS": {
        "id": "BFOS",
        "name": "B.FOSTER & COMPANY"
    },
    "BFSC": {
        "id": "BFSC",
        "name": "B AND F SPECIALTIES"
    },
    "BFSI": {
        "id": "BFSI",
        "name": "BLUE SHORES FABRICATION INC FREEPORT MN"
    },
    "BGBE": {
        "id": "BGBE",
        "name": "BIG BEE TRAILERS OR BIGBEE METAL MANUFATURING"
    },
    "BGCF": {
        "id": "BGCF",
        "name": "BIG CHIEF MOTORSPORTS MOTORCYCLES"
    },
    "BGCH": {
        "id": "BGCH",
        "name": "BIG BEAR CHOPPERS"
    },
    "BGDG": {
        "id": "BGDG",
        "name": "BIG DOG CUSTOM MOTORCYCLES"
    },
    "BGFT": {
        "id": "BGFT",
        "name": "BIGFOOT TRAILERS LLC MULBERRY FL"
    },
    "BGHM": {
        "id": "BGHM",
        "name": "BINGHAM"
    },
    "BGLG": {
        "id": "BGLG",
        "name": "BIGLUG TRAILER WORKS MARION"
    },
    "BGTI": {
        "id": "BGTI",
        "name": "B & G TRAILERS"
    },
    "BGVW": {
        "id": "BGVW",
        "name": "BRIDGEVILLE MFG"
    },
    "BGWT": {
        "id": "BGWT",
        "name": "BIG WATER TRAILERS PIERCE"
    },
    "BHME": {
        "id": "BHME",
        "name": "BURLINGTON HOMES OF MAINE MODULAR MOBILE HOMES"
    },
    "BHNK": {
        "id": "BHNK",
        "name": "BEHNKE ENTERPRISES"
    },
    "BHWS": {
        "id": "BHWS",
        "name": "B H WORKMAN & SONS"
    },
    "BHZD": {
        "id": "BHZD",
        "name": "BIOHAZARD CYCLES INC FLORIDA CUSTOM CYCLES"
    },
    "BIAN": {
        "id": "BIAN",
        "name": "BIANCHI"
    },
    "BIBB": {
        "id": "BIBB",
        "name": "BIBB WELDING & TRAILERS"
    },
    "BIBK": {
        "id": "BIBK",
        "name": "BIG INCH BIKES KC CREATIONS KANSAS"
    },
    "BIDD": {
        "id": "BIDD",
        "name": "BIDDLE"
    },
    "BIEW": {
        "id": "BIEW",
        "name": "BIEWER INDUSTRIES"
    },
    "BIGB": {
        "id": "BIGB",
        "name": "BIG BUBBA'S OGDEN"
    },
    "BIGC": {
        "id": "BIGC",
        "name": "BIG COUNTRY MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "BIGF": {
        "id": "BIGF",
        "name": "BIGFOOT INDUSTRIES 2O1O"
    },
    "BIGH": {
        "id": "BIGH",
        "name": "BIG HORN TRAILER"
    },
    "BIGI": {
        "id": "BIGI",
        "name": "BIG WELL INDUSTRIES"
    },
    "BIGJ": {
        "id": "BIGJ",
        "name": "BIG JOE MANUFACTURING"
    },
    "BIGM": {
        "id": "BIGM",
        "name": "BIGMAN ELECTRIC VEHICLES BARTON INVESTMENT GROUP MFG B.I.G. MAN ELEC"
    },
    "BIGT": {
        "id": "BIGT",
        "name": "BIG TEX"
    },
    "BIGV": {
        "id": "BIGV",
        "name": "BIG VALLEY TRAILERS"
    },
    "BIGW": {
        "id": "BIGW",
        "name": "BIG W SALES OR BIGW SALES STOCKTON"
    },
    "BIJN": {
        "id": "BIJN",
        "name": "BIG JOHN MFG. CORP HEBER SPRINGS"
    },
    "BILE": {
        "id": "BILE",
        "name": "BILTMORE MOBILE HOMES"
    },
    "BILK": {
        "id": "BILK",
        "name": "BILKEN ENTERPRISES MFG OF SPECIALTY TRAILER"
    },
    "BILL": {
        "id": "BILL",
        "name": "BILLS WELDING MODEL TRAILER"
    },
    "BILR": {
        "id": "BILR",
        "name": "BILTRITE MOBILE HOMES"
    },
    "BILT": {
        "id": "BILT",
        "name": "BILTWELL OR BUILTWELL"
    },
    "BIMO": {
        "id": "BIMO",
        "name": "BI-MOTOR STALLION"
    },
    "BIMT": {
        "id": "BIMT",
        "name": "BIMOTA MOTORCYCLES"
    },
    "BINK": {
        "id": "BINK",
        "name": "BINKLEY"
    },
    "BINS": {
        "id": "BINS",
        "name": "BINKS MFG."
    },
    "BINT": {
        "id": "BINT",
        "name": "BINTELLI MOTORSCOOTERS & CYCLES MANUFACTURED BY EITHER ZIEJIANG OR"
    },
    "BIOT": {
        "id": "BIOT",
        "name": "BIOT CAMPING TRAILER"
    },
    "BIRD": {
        "id": "BIRD",
        "name": "BIRD"
    },
    "BIRE": {
        "id": "BIRE",
        "name": "BIRD ENGINEERING"
    },
    "BIRK": {
        "id": "BIRK",
        "name": "BIRKIN CARS PTY"
    },
    "BIRM": {
        "id": "BIRM",
        "name": "BIRMINGHAM MFG."
    },
    "BISC": {
        "id": "BISC",
        "name": "BISCUTER PEGASIN"
    },
    "BISN": {
        "id": "BISN",
        "name": "BISON MANUFACTURING"
    },
    "BITT": {
        "id": "BITT",
        "name": "BITTER"
    },
    "BIVC": {
        "id": "BIVC",
        "name": "BIVOUAC CAMPING TRAILERS"
    },
    "BIVO": {
        "id": "BIVO",
        "name": "BIVOUAC INDUSTRIES"
    },
    "BIZZ": {
        "id": "BIZZ",
        "name": "BIZZARRINI"
    },
    "BJAA": {
        "id": "BJAA",
        "name": "BAJA CUSTOM TRAILERS"
    },
    "BJAY": {
        "id": "BJAY",
        "name": "BLUE JAY TRAILERS FITZGERALD GA"
    },
    "BJCT": {
        "id": "BJCT",
        "name": "BJ'S CUSTOM TRAILERS WOODSTOCK"
    },
    "BJEN": {
        "id": "BJEN",
        "name": "B & J ENTERPRISES"
    },
    "BJMC": {
        "id": "BJMC",
        "name": "BJ MFG."
    },
    "BJMS": {
        "id": "BJMS",
        "name": "BILL JAMES TRAILER SALESWINFIELD"
    },
    "BJNM": {
        "id": "BJNM",
        "name": "BJN MANUFACTURING LLC HASTINGS NE"
    },
    "BJTL": {
        "id": "BJTL",
        "name": "BIG JOHN TRAILERS GEORGIA FORESTRY"
    },
    "BKAW": {
        "id": "BKAW",
        "name": "BLAKELY AUTO WORKS AKA BERNARDI AUTO WORKS"
    },
    "BKDN": {
        "id": "BKDN",
        "name": "BUCK DANDY COMPANY TEXAS"
    },
    "BKHM": {
        "id": "BKHM",
        "name": "BECKHAM TRAILERS"
    },
    "BKTK": {
        "id": "BKTK",
        "name": "BACK TRACK TRAILERS ARKANSAS"
    },
    "BLAB": {
        "id": "BLAB",
        "name": "BLACK BUILT"
    },
    "BLAC": {
        "id": "BLAC",
        "name": "BLACK DIAMOND TRAILER"
    },
    "BLAD": {
        "id": "BLAD",
        "name": "BLADE"
    },
    "BLAE": {
        "id": "BLAE",
        "name": "BLAZER"
    },
    "BLAH": {
        "id": "BLAH",
        "name": "BLANCHARD FOUNDRY"
    },
    "BLAI": {
        "id": "BLAI",
        "name": "BLAIR HORSE TRAILER"
    },
    "BLAK": {
        "id": "BLAK",
        "name": "BLACK DIAMOND ENTERPRISES"
    },
    "BLAO": {
        "id": "BLAO",
        "name": "BLAW-KNOX CONSTRUCTION EQUIPMENT"
    },
    "BLAR": {
        "id": "BLAR",
        "name": "BLAZE INDUSTRIES"
    },
    "BLAS": {
        "id": "BLAS",
        "name": "BLAINE'S CUSTOM MFG. SERVICE JACKSON"
    },
    "BLAW": {
        "id": "BLAW",
        "name": "BLACKWELL BURNER"
    },
    "BLAZ": {
        "id": "BLAZ",
        "name": "BLAZON MOBILE HOMES CORP."
    },
    "BLBR": {
        "id": "BLBR",
        "name": "BLACK BROTHERS TRAILERS"
    },
    "BLBW": {
        "id": "BLBW",
        "name": "BOULDER BOAT WORKS"
    },
    "BLCH": {
        "id": "BLCH",
        "name": "BUILDERS CHOICE ANCHORAGE ALASKA MOBILE"
    },
    "BLDC": {
        "id": "BLDC",
        "name": "BLACK AND DECKER"
    },
    "BLDG": {
        "id": "BLDG",
        "name": "BULLDOG TRAILERS SALES"
    },
    "BLDM": {
        "id": "BLDM",
        "name": "BLADEMOR GRADER"
    },
    "BLGA": {
        "id": "BLGA",
        "name": "BLUE GATOR BOAT TRAILER"
    },
    "BLGR": {
        "id": "BLGR",
        "name": "BLUGRASS TRAILER SALES"
    },
    "BLGT": {
        "id": "BLGT",
        "name": "BLUE GRASS TRAILERS"
    },
    "BLIN": {
        "id": "BLIN",
        "name": "B-LINE FARM EQUIP.MFRS. CAR TOW & SNOWMOBILE TRAILERS BRISTOL VIRGINIA"
    },
    "BLIT": {
        "id": "BLIT",
        "name": "BLITZ MFG."
    },
    "BLIX": {
        "id": "BLIX",
        "name": "BLIX COACH"
    },
    "BLIZ": {
        "id": "BLIZ",
        "name": "BLIZZARD MANUFACTURING BOONEVILLE"
    },
    "BLJK": {
        "id": "BLJK",
        "name": "BLACK JACK CHOPPERS"
    },
    "BLJX": {
        "id": "BLJX",
        "name": "BIL-JAX"
    },
    "BLKB": {
        "id": "BLKB",
        "name": "BLACK BEAR MANUFACTURING WEST VIRGINIA"
    },
    "BLKD": {
        "id": "BLKD",
        "name": "BLACK DOG FABRICATING"
    },
    "BLKE": {
        "id": "BLKE",
        "name": "BULK EQUIPMENT MFR. INC.BULKINER FEED TRAILER"
    },
    "BLKH": {
        "id": "BLKH",
        "name": "BLACKHAWK MOTOR WORKS"
    },
    "BLKI": {
        "id": "BLKI",
        "name": "BULK INTERNATIONAL PRODUCTION FACILITY"
    },
    "BLKS": {
        "id": "BLKS",
        "name": "BULK SOLUTIONS"
    },
    "BLKT": {
        "id": "BLKT",
        "name": "BLACKSTONE TRAILER COMPANY"
    },
    "BLLU": {
        "id": "BLLU",
        "name": "BELLUES WELDING"
    },
    "BLLV": {
        "id": "BLLV",
        "name": "BELLVIEW CAMPER"
    },
    "BLMH": {
        "id": "BLMH",
        "name": "BELMONT MANUFACTURED HOME"
    },
    "BLMT": {
        "id": "BLMT",
        "name": "BELMONT TRAILERS LLC"
    },
    "BLMY": {
        "id": "BLMY",
        "name": "BELLAMY TRAILERS"
    },
    "BLNE": {
        "id": "BLNE",
        "name": "B-LINE OR B-LINE OF COLORADO GREELEY"
    },
    "BLOM": {
        "id": "BLOM",
        "name": "BLOOMER TRAILER MANUFACTURING INC.LAMARQUE"
    },
    "BLOO": {
        "id": "BLOO",
        "name": "BLOOM"
    },
    "BLOT": {
        "id": "BLOT",
        "name": "BALLOT"
    },
    "BLRG": {
        "id": "BLRG",
        "name": "BLUE RIDGE TRAILERS"
    },
    "BLRK": {
        "id": "BLRK",
        "name": "BLUE ROCK MFG."
    },
    "BLRM": {
        "id": "BLRM",
        "name": "BLR MOTORS LINITED UNITED KINGDON REPLICA OF LAND ROVER DEFENDER ETC"
    },
    "BLRT": {
        "id": "BLRT",
        "name": "BILT-RITE TRAILERS INC SIKESTON"
    },
    "BLST": {
        "id": "BLST",
        "name": "BLAKE TRAILERS STAR"
    },
    "BLSV": {
        "id": "BLSV",
        "name": "BAMA LOW SPEED VEHICLES LLC IDER AL"
    },
    "BLTC": {
        "id": "BLTC",
        "name": "BLASTRAC OKLAHOMA CITY OK"
    },
    "BLTL": {
        "id": "BLTL",
        "name": "BLAIR TRAILERS"
    },
    "BLTP": {
        "id": "BLTP",
        "name": "BLACK TIE PRODUCTS"
    },
    "BLTR": {
        "id": "BLTR",
        "name": "BULLET TRAILERS"
    },
    "BLTZ": {
        "id": "BLTZ",
        "name": "JOHAN BLATZ BUHLER"
    },
    "BLUB": {
        "id": "BLUB",
        "name": "BLUE BIRD BODY"
    },
    "BLUD": {
        "id": "BLUD",
        "name": "BLUE DIAMOND TRAILERS"
    },
    "BLUE": {
        "id": "BLUE",
        "name": "BLUE RIBBON COACH"
    },
    "BLUG": {
        "id": "BLUG",
        "name": "BLUE-RIDGE PRE-BUILT MOBILE HOMES"
    },
    "BLUI": {
        "id": "BLUI",
        "name": "BLUEBIRD INTERNATIONAL"
    },
    "BLUJ": {
        "id": "BLUJ",
        "name": "BLUE"
    },
    "BLUL": {
        "id": "BLUL",
        "name": "BLUELINE MANUFACTURING"
    },
    "BLUM": {
        "id": "BLUM",
        "name": "BLUM TRAILERS SAINT JOSEPH MO"
    },
    "BLUN": {
        "id": "BLUN",
        "name": "BLU LINE INDUSTRIES"
    },
    "BLUT": {
        "id": "BLUT",
        "name": "BLUE RIBBON TRAILERS LTD."
    },
    "BLWB": {
        "id": "BLWB",
        "name": "BIG LOWBOY S DE R.L DE C.V.SAN NICOLAS"
    },
    "BLYD": {
        "id": "BLYD",
        "name": "BILLY DOZIER TRAILERS CHARLESTON"
    },
    "BMBM": {
        "id": "BMBM",
        "name": "B-M-B. MFG."
    },
    "BMBR": {
        "id": "BMBR",
        "name": "BOMBARDIER INC"
    },
    "BMC": {
        "id": "BMC",
        "name": "BMC"
    },
    "BMCH": {
        "id": "BMCH",
        "name": "BREMACH SPA ITALY"
    },
    "BMCM": {
        "id": "BMCM",
        "name": "BMC MOTORCYCLE COMPANY BEND"
    },
    "BMEQ": {
        "id": "BMEQ",
        "name": "BOISE MOBILE EQUIPMENT FIRE APPARATUS"
    },
    "BMFT": {
        "id": "BMFT",
        "name": "BMF TRAILER WOORKS"
    },
    "BMMC": {
        "id": "BMMC",
        "name": "BIRMINGHAM MOTORCYCLE COMPANY"
    },
    "BMMF": {
        "id": "BMMF",
        "name": "B AND M MFG"
    },
    "BMMM": {
        "id": "BMMM",
        "name": "BEATRICE MOTOR MART"
    },
    "BMOF": {
        "id": "BMOF",
        "name": "BLUE MOUNTAIN OUTFITTERS"
    },
    "BMSM": {
        "id": "BMSM",
        "name": "BMS MOTORSPORTS CITY OF INDUSTRY"
    },
    "BMTM": {
        "id": "BMTM",
        "name": "BMT MANUFACTURING LLC JACKSBORO TN"
    },
    "BMTR": {
        "id": "BMTR",
        "name": "BOAT MASTER TRAILERS FT MYERS FLORIDA"
    },
    "BMW": {
        "id": "BMW",
        "name": "BMW"
    },
    "BMX": {
        "id": "BMX",
        "name": "BMX ATV'S"
    },
    "BNDR": {
        "id": "BNDR",
        "name": "BOUNDER MOTOR HOME MFG BY FLEETWOOD DECATUR"
    },
    "BNDT": {
        "id": "BNDT",
        "name": "BANDIT INDUSTRIES MICHIGAN"
    },
    "BNHR": {
        "id": "BNHR",
        "name": "BEN HUR"
    },
    "BNLE": {
        "id": "BNLE",
        "name": "BENLEE"
    },
    "BNLX": {
        "id": "BNLX",
        "name": "BROWN LENOX &"
    },
    "BNMT": {
        "id": "BNMT",
        "name": "B N M TRAILER SALES INC MICHIGAN PADDLE KING"
    },
    "BNPT": {
        "id": "BNPT",
        "name": "BONAPARTE USA LLC SARASOTA FL"
    },
    "BNST": {
        "id": "BNST",
        "name": "B & S BOAT TRAILER MFG."
    },
    "BNTM": {
        "id": "BNTM",
        "name": "AMERICAN BANTAM PREV AMERICAN AUSTIN"
    },
    "BNVL": {
        "id": "BNVL",
        "name": "BONNEVILLE MANUFACTURED HOME"
    },
    "BNZA": {
        "id": "BNZA",
        "name": "BONANZA"
    },
    "BNZH": {
        "id": "BNZH",
        "name": "BENZHOU VEHICLE INDUSTRY GROUP."
    },
    "BOAN": {
        "id": "BOAN",
        "name": "BOANZA MOBILE HOMES"
    },
    "BOAR": {
        "id": "BOAR",
        "name": "BOARDMAN"
    },
    "BOAS": {
        "id": "BOAS",
        "name": "BOA-SKI ALSPORT LTD"
    },
    "BOAT": {
        "id": "BOAT",
        "name": "BOATEL SKI"
    },
    "BOB": {
        "id": "BOB",
        "name": "BOB S TRAILER"
    },
    "BOBB": {
        "id": "BOBB",
        "name": "BOBBI-KAR"
    },
    "BOBC": {
        "id": "BOBC",
        "name": "BOB COONS"
    },
    "BOBK": {
        "id": "BOBK",
        "name": "BOBKO"
    },
    "BOBM": {
        "id": "BOBM",
        "name": "BOBBY'S CUSTOM CAMPER MFG."
    },
    "BOBT": {
        "id": "BOBT",
        "name": "BOBCAT COMPANY ALSO KNOWN AS BOBCAT SKID STEER LOADER MFG BY MELROE"
    },
    "BOCA": {
        "id": "BOCA",
        "name": "BOCAR"
    },
    "BOCE": {
        "id": "BOCE",
        "name": "BROCE MANUFACTURING"
    },
    "BOCK": {
        "id": "BOCK",
        "name": "BOCK PRODUCTS"
    },
    "BOCS": {
        "id": "BOCS",
        "name": "BO CATS"
    },
    "BODZ": {
        "id": "BODZ",
        "name": "BOD-EZE BOAT TRAILERS ETC"
    },
    "BOER": {
        "id": "BOER",
        "name": "BOERNE TRAILER MANUFACTURING BOERNE"
    },
    "BOHN": {
        "id": "BOHN",
        "name": "BOHNENKAMP'S WHITEWATER CUSTOMS"
    },
    "BOIS": {
        "id": "BOIS",
        "name": "BOISE CASCADE MOBILE HOMEBOISE"
    },
    "BOKM": {
        "id": "BOKM",
        "name": "BOECKMANN FAHRZEUGWERKE GMBH GERMANY"
    },
    "BOLA": {
        "id": "BOLA",
        "name": "BOLES-AERO"
    },
    "BOLG": {
        "id": "BOLG",
        "name": "BOLINGER FLATBED TRAILER"
    },
    "BOLR": {
        "id": "BOLR",
        "name": "BOLER MANUFACTURING"
    },
    "BOMA": {
        "id": "BOMA",
        "name": "BO-MAR MFG."
    },
    "BOMB": {
        "id": "BOMB",
        "name": "BOMBARDIER SKI DOO"
    },
    "BOMF": {
        "id": "BOMF",
        "name": "BOMFORD AND EVERSHED"
    },
    "BOMG": {
        "id": "BOMG",
        "name": "BOMAG TRAILER"
    },
    "BONA": {
        "id": "BONA",
        "name": "BON-AIRE HOMES"
    },
    "BONC": {
        "id": "BONC",
        "name": "BONANZA TRAVEL COACH"
    },
    "BOND": {
        "id": "BOND",
        "name": "BOND"
    },
    "BONE": {
        "id": "BONE",
        "name": "BOND MOBILE HOMES"
    },
    "BONI": {
        "id": "BONI",
        "name": "BONNIEVILLE MFG."
    },
    "BOOM": {
        "id": "BOOM",
        "name": "BOOM TRIKES USA AKA AX"
    },
    "BOON": {
        "id": "BOON",
        "name": "BOONE TRAILERS"
    },
    "BORC": {
        "id": "BORC",
        "name": "BORG"
    },
    "BORF": {
        "id": "BORF",
        "name": "BORN FREE MOTORCOACH - HUMBOLDT"
    },
    "BORG": {
        "id": "BORG",
        "name": "BORGWARD"
    },
    "BOSM": {
        "id": "BOSM",
        "name": "BOSS MOTORSPORTS MOTORCYCLES"
    },
    "BOSP": {
        "id": "BOSP",
        "name": "BOSS POWEWRSPORTS ATV'S"
    },
    "BOSS": {
        "id": "BOSS",
        "name": "BOSS HOSS CYCLE"
    },
    "BOTL": {
        "id": "BOTL",
        "name": "BOATEL TRAILER"
    },
    "BOUL": {
        "id": "BOUL",
        "name": "BOULDER TRAILER"
    },
    "BOUR": {
        "id": "BOUR",
        "name": "BOURG DISTRIBUTING"
    },
    "BOWE": {
        "id": "BOWE",
        "name": "BOWERS MFG."
    },
    "BOWI": {
        "id": "BOWI",
        "name": "BOWIE INDUSTRIES CORP.BOWIE"
    },
    "BOWL": {
        "id": "BOWL",
        "name": "BOWLUS ROAD CHIEF"
    },
    "BOWM": {
        "id": "BOWM",
        "name": "BOWMAN & SONS"
    },
    "BOWN": {
        "id": "BOWN",
        "name": "BOWEN MOBILE HOMES"
    },
    "BOWS": {
        "id": "BOWS",
        "name": "BOWSMAN TRAILERS"
    },
    "BOXR": {
        "id": "BOXR",
        "name": "BOXER TRAILER"
    },
    "BOYD": {
        "id": "BOYD",
        "name": "BOYD"
    },
    "BOYE": {
        "id": "BOYE",
        "name": "BOYESEN"
    },
    "BOYL": {
        "id": "BOYL",
        "name": "BOYLAND SALES"
    },
    "BOYR": {
        "id": "BOYR",
        "name": "BOYER INDUSTRIES"
    },
    "BOZR": {
        "id": "BOZR",
        "name": "BONANZA & RAWHIDE TRAILER"
    },
    "BPAW": {
        "id": "BPAW",
        "name": "BEARPAW CAMPERS LLC PALISADE CO"
    },
    "BPBK": {
        "id": "BPBK",
        "name": "BBQ PITS BY KLOSE HOUSTON TX"
    },
    "BPBW": {
        "id": "BPBW",
        "name": "BRADWELL MFG BY BONAPARTE USA LLC SARASOTA FL"
    },
    "BQGR": {
        "id": "BQGR",
        "name": "BQ GRILLS ELM CITY"
    },
    "BRAC": {
        "id": "BRAC",
        "name": "BRA"
    },
    "BRAD": {
        "id": "BRAD",
        "name": "BRANDT TRAILERS"
    },
    "BRAF": {
        "id": "BRAF",
        "name": "BRADFORD INDUSTRIES BRADFORD ILLINOIS"
    },
    "BRAL": {
        "id": "BRAL",
        "name": "BRALL MOTOR HOME"
    },
    "BRAM": {
        "id": "BRAM",
        "name": "BRAMMO"
    },
    "BRAN": {
        "id": "BRAN",
        "name": "BRANSTRATOR ENGINEERING CORP."
    },
    "BRAS": {
        "id": "BRAS",
        "name": "BRASINCA"
    },
    "BRAT": {
        "id": "BRAT",
        "name": "BRANTLEY MFG."
    },
    "BRAU": {
        "id": "BRAU",
        "name": "BRAUGHMS TRAVEL TRAILER"
    },
    "BRAV": {
        "id": "BRAV",
        "name": "BRAVO TRAILER"
    },
    "BRAW": {
        "id": "BRAW",
        "name": "BRANDYWINE TRAVEL TRAILER"
    },
    "BRAY": {
        "id": "BRAY",
        "name": "BRAY TRAILERS TRENTON"
    },
    "BRAZ": {
        "id": "BRAZ",
        "name": "BRAZOS TRAILER MANUFACTURING"
    },
    "BRBE": {
        "id": "BRBE",
        "name": "BROTHERS BODY AND EQUIPMENT"
    },
    "BRBG": {
        "id": "BRBG",
        "name": "BERRIEN BUGGY INC."
    },
    "BRCK": {
        "id": "BRCK",
        "name": "BROCK'S TRAILERS"
    },
    "BRCO": {
        "id": "BRCO",
        "name": "BAR"
    },
    "BRCT": {
        "id": "BRCT",
        "name": "BEAR CAT MFG."
    },
    "BRCV": {
        "id": "BRCV",
        "name": "BROWN CARGO VAN"
    },
    "BRDC": {
        "id": "BRDC",
        "name": "BRAD"
    },
    "BRDG": {
        "id": "BRDG",
        "name": "BRIDGER FIRE INC. MONTANA - FIRE APPARATUS"
    },
    "BRDK": {
        "id": "BRDK",
        "name": "BROOKS HORSE TRAILER"
    },
    "BRDL": {
        "id": "BRDL",
        "name": "BRADLEY GT"
    },
    "BRDR": {
        "id": "BRDR",
        "name": "BRODERSON MANUFACTURING CORP."
    },
    "BRDV": {
        "id": "BRDV",
        "name": "BREEDLOVE MOTOR WORKS"
    },
    "BREC": {
        "id": "BREC",
        "name": "BOR"
    },
    "BREE": {
        "id": "BREE",
        "name": "BREEZE MOTOR HOME"
    },
    "BREI": {
        "id": "BREI",
        "name": "BRENT INDUSTRIES SHELL ROCK"
    },
    "BREK": {
        "id": "BREK",
        "name": "BREAK"
    },
    "BREL": {
        "id": "BREL",
        "name": "BRAUN ELECTRIC INC ST NAZIANZ WI"
    },
    "BREM": {
        "id": "BREM",
        "name": "BREMEN SPORT EQUIPMENT"
    },
    "BREN": {
        "id": "BREN",
        "name": "BRENNER TANK"
    },
    "BREQ": {
        "id": "BREQ",
        "name": "BROTHERS EQUIPMENT INC CLEVELAND"
    },
    "BRET": {
        "id": "BRET",
        "name": "BRENTWOOD MOBILE HOMES SUBSIDIARY DEROSE INDUSTRIES INC"
    },
    "BREW": {
        "id": "BREW",
        "name": "BREWER UTILITY"
    },
    "BREZ": {
        "id": "BREZ",
        "name": "BREEZE CAMPING TRAVEL TRAILER"
    },
    "BRGH": {
        "id": "BRGH",
        "name": "BROUGH"
    },
    "BRGR": {
        "id": "BRGR",
        "name": "BRIDGERS COACHES"
    },
    "BRGS": {
        "id": "BRGS",
        "name": "BRIGGS MANUFACTURING"
    },
    "BRGT": {
        "id": "BRGT",
        "name": "BRIGHT TRAILER MANUFACTURING DENISON"
    },
    "BRGV": {
        "id": "BRGV",
        "name": "BRIDGEVILLE TRAILERS ELBA"
    },
    "BRHM": {
        "id": "BRHM",
        "name": "BRAHMAN BODIES INC BOONEVILLE"
    },
    "BRIA": {
        "id": "BRIA",
        "name": "BRIANS CHOPPERS PHOENIZ"
    },
    "BRIC": {
        "id": "BRIC",
        "name": "BRICKLIN"
    },
    "BRID": {
        "id": "BRID",
        "name": "BRIDGESTONE"
    },
    "BRIE": {
        "id": "BRIE",
        "name": "BRIDGE MFG. AND EQUIPMENT"
    },
    "BRIG": {
        "id": "BRIG",
        "name": "BRIGADIER MOBILE HOMESDIV. BRIGADIER INDUSTRIES"
    },
    "BRIH": {
        "id": "BRIH",
        "name": "BRIGHTON BUILT TRAILERS BRIGHTON IOWA"
    },
    "BRIL": {
        "id": "BRIL",
        "name": "BRILLION IRON WORKS DIV."
    },
    "BRIO": {
        "id": "BRIO",
        "name": "BRISTOL HOMESDIV. TIDWELL IND."
    },
    "BRIR": {
        "id": "BRIR",
        "name": "BRITISH RACING MOTORS"
    },
    "BRIS": {
        "id": "BRIS",
        "name": "BRISTOL"
    },
    "BRIT": {
        "id": "BRIT",
        "name": "BRISTOL MFG."
    },
    "BRKF": {
        "id": "BRKF",
        "name": "BROCKHOFF MANUFACTURING"
    },
    "BRKH": {
        "id": "BRKH",
        "name": "BURKHOLDER MANUFACTURING PENNSYLVANIA"
    },
    "BRKL": {
        "id": "BRKL",
        "name": "BROOK LINE MANUFACTURING INC. BLANCHARD"
    },
    "BRKO": {
        "id": "BRKO",
        "name": "BARKO HYDRAULICS"
    },
    "BRKS": {
        "id": "BRKS",
        "name": "BARKAS"
    },
    "BRKV": {
        "id": "BRKV",
        "name": "BROOKVILLE ROADSTER BROOKVILLE"
    },
    "BRKW": {
        "id": "BRKW",
        "name": "BROOKWOOD MOBILE HOME"
    },
    "BRLC": {
        "id": "BRLC",
        "name": "J.G. BRILL COMPANY PHILADELPHIA"
    },
    "BRLL": {
        "id": "BRLL",
        "name": "BORELLA"
    },
    "BRMH": {
        "id": "BRMH",
        "name": "BRECKENRIDGE MANUFACTURED HOMES DIV OF DAMON CORP"
    },
    "BRMR": {
        "id": "BRMR",
        "name": "BRI-MAR MANUFACTURING CHAMBERSBURG"
    },
    "BRNC": {
        "id": "BRNC",
        "name": "THE BRAUN CORPORATION"
    },
    "BRND": {
        "id": "BRND",
        "name": "BRANDY INDUSTRIESCOLON"
    },
    "BRNG": {
        "id": "BRNG",
        "name": "BERING TRUCK DISTRIBUTION"
    },
    "BRNN": {
        "id": "BRNN",
        "name": "BARRON FABRICATION"
    },
    "BRNO": {
        "id": "BRNO",
        "name": "BRUNO INDEPENDENT LIVING AIDS"
    },
    "BRNP": {
        "id": "BRNP",
        "name": "BRENDERUP TRAILERS INC. TEXAS"
    },
    "BRNW": {
        "id": "BRNW",
        "name": "BARNWELL WELDING AND TRAILER MANUFACTURER LITHIA FL"
    },
    "BROA": {
        "id": "BROA",
        "name": "BROADLANE HOMES"
    },
    "BROB": {
        "id": "BROB",
        "name": "BROBTS & ASSOCIATES"
    },
    "BROC": {
        "id": "BROC",
        "name": "BROCKWAY DISCONTINUED PRODUCTION IN 1977"
    },
    "BROD": {
        "id": "BROD",
        "name": "BROADMORE MOBILE HOMES SUBSIDIARY FLEETWOOD TRAILERS & MOBILE HOMES"
    },
    "BROE": {
        "id": "BROE",
        "name": "BROOTEN FABRICATING"
    },
    "BROH": {
        "id": "BROH",
        "name": "THE BROYHILL"
    },
    "BROK": {
        "id": "BROK",
        "name": "BROKEN ARROW MOBILE HOME"
    },
    "BROM": {
        "id": "BROM",
        "name": "BROOKS STEAM MOTORS"
    },
    "BRON": {
        "id": "BRON",
        "name": "BRONC"
    },
    "BROO": {
        "id": "BROO",
        "name": "BROOKLYN TRAILER"
    },
    "BROR": {
        "id": "BROR",
        "name": "BROCK STAR TRAILER"
    },
    "BROS": {
        "id": "BROS",
        "name": "BROS. DIV.AMERICAN HOIST DERRICK"
    },
    "BROT": {
        "id": "BROT",
        "name": "BROOKS BROTHERS TRAILERS"
    },
    "BROU": {
        "id": "BROU",
        "name": "BROUGHAM INDUSTRIES"
    },
    "BROW": {
        "id": "BROW",
        "name": "BROWN TRAILER DIV CLARK EQUIPMENT"
    },
    "BROY": {
        "id": "BROY",
        "name": "BROWN INDUSTRIES"
    },
    "BRPI": {
        "id": "BRPI",
        "name": "BRINDLE PRODUCTS INC GRABILL IN"
    },
    "BRRN": {
        "id": "BRRN",
        "name": "BRAUN INDUSTRIES"
    },
    "BRRO": {
        "id": "BRRO",
        "name": "BURRO"
    },
    "BRRT": {
        "id": "BRRT",
        "name": "BARRO TRAILER MANUFACTURING MODESTO"
    },
    "BRRY": {
        "id": "BRRY",
        "name": "BARRY'S TRAILERS SARASOTA"
    },
    "BRSD": {
        "id": "BRSD",
        "name": "BRISTER'S DESIGN AND MANUFACTURING"
    },
    "BRSH": {
        "id": "BRSH",
        "name": "BRUSH ROADSTER"
    },
    "BRSN": {
        "id": "BRSN",
        "name": "BRANSON TRAILER MFG."
    },
    "BRST": {
        "id": "BRST",
        "name": "BRIGGS & STRATTON CORP."
    },
    "BRTB": {
        "id": "BRTB",
        "name": "BOS RADIATEUREN SERVICE EN TRIKE-BOUW BV NETHERLANDS"
    },
    "BRTE": {
        "id": "BRTE",
        "name": "BRUTE EQUIPMENT INC.GLEN ALAN"
    },
    "BRTH": {
        "id": "BRTH",
        "name": "BROTHERHOOD CYCLES ALBANY NY"
    },
    "BRTI": {
        "id": "BRTI",
        "name": "BRAE TRAILERS INC"
    },
    "BRTK": {
        "id": "BRTK",
        "name": "BEAR TRACK PROD. INC TRAILERS"
    },
    "BRTL": {
        "id": "BRTL",
        "name": "BARTELL"
    },
    "BRTM": {
        "id": "BRTM",
        "name": "BARRETO MANUFACTURING"
    },
    "BRTO": {
        "id": "BRTO",
        "name": "BERTOLINI CONTAINER"
    },
    "BRTR": {
        "id": "BRTR",
        "name": "BEAR TRAILER MFG."
    },
    "BRTT": {
        "id": "BRTT",
        "name": "BARETTA"
    },
    "BRUN": {
        "id": "BRUN",
        "name": "BRUNSWICK TRAVEL TRAILER"
    },
    "BRUP": {
        "id": "BRUP",
        "name": "BRUTON EASY PULL TRAILER"
    },
    "BRUT": {
        "id": "BRUT",
        "name": "BRUTT"
    },
    "BRUZ": {
        "id": "BRUZ",
        "name": "BRUTANZA ENGINEERING"
    },
    "BRWD": {
        "id": "BRWD",
        "name": "BROWARD TRAILER OAKLAND PARK"
    },
    "BRWN": {
        "id": "BRWN",
        "name": "BROWN"
    },
    "BRWT": {
        "id": "BRWT",
        "name": "BRAWLEY WELDING & TRAILER MFG."
    },
    "BSA": {
        "id": "BSA",
        "name": "BSA UNITED KINGDOM"
    },
    "BSB": {
        "id": "BSB",
        "name": "BUSSE BROWN TRAILER"
    },
    "BSCI": {
        "id": "BSCI",
        "name": "BLACKSERIES CAMPERS INC AKA BLACK SERIES EL MONTE CA"
    },
    "BSFL": {
        "id": "BSFL",
        "name": "BULLDOG STEEL FABRICATON LLC MADISON GA"
    },
    "BSHT": {
        "id": "BSHT",
        "name": "BUSHTEC PRODUCTS CORPORATION JACKSBORO"
    },
    "BSIP": {
        "id": "BSIP",
        "name": "BSI PRODUCTS LP HOUSTON"
    },
    "BSKI": {
        "id": "BSKI",
        "name": "BOSSKI INC."
    },
    "BSMK": {
        "id": "BSMK",
        "name": "BISMARCK TRAILER SALES BISMARCK"
    },
    "BSOP": {
        "id": "BSOP",
        "name": "BOBSHOP MILWAUKEE WI"
    },
    "BSSR": {
        "id": "BSSR",
        "name": "BESSER APPCO"
    },
    "BSTB": {
        "id": "BSTB",
        "name": "BESTBILT TRAILERS HARBBRO LLC MOUNT PLEASANT"
    },
    "BSTD": {
        "id": "BSTD",
        "name": "BARSTAD AND DONICHT OAKLAND CALIFORNIA"
    },
    "BSTR": {
        "id": "BSTR",
        "name": "BEST TRAILER INC CALIFORNIA"
    },
    "BSTW": {
        "id": "BSTW",
        "name": "BESTWAY INC."
    },
    "BSUR": {
        "id": "BSUR",
        "name": "ZHEJIANG BOSUER MOTION APPARATUS"
    },
    "BTCH": {
        "id": "BTCH",
        "name": "BATTECH ENTERPRISES LLC"
    },
    "BTDI": {
        "id": "BTDI",
        "name": "BOAT TRAILERS DIRECT"
    },
    "BTEC": {
        "id": "BTEC",
        "name": "BRADTEC"
    },
    "BTHE": {
        "id": "BTHE",
        "name": "BOOTHILL CUSTOM TRAILERS SIKESTON"
    },
    "BTHL": {
        "id": "BTHL",
        "name": "BEUTHLING"
    },
    "BTLT": {
        "id": "BTLT",
        "name": "BUTLER TRAILER MFG."
    },
    "BTMA": {
        "id": "BTMA",
        "name": "PELLETIER MANUFACTURING"
    },
    "BTMH": {
        "id": "BTMH",
        "name": "BARTLETT MANUFACTURED HOME"
    },
    "BTMI": {
        "id": "BTMI",
        "name": "BOATMATE TRAILERS"
    },
    "BTMT": {
        "id": "BTMT",
        "name": "BENCHMARK TOOL AND MACHINE"
    },
    "BTNY": {
        "id": "BTNY",
        "name": "BIG TONY'S CHOPP SHOP"
    },
    "BTPI": {
        "id": "BTPI",
        "name": "BOAT TRAILERS PACIFIC"
    },
    "BTRK": {
        "id": "BTRK",
        "name": "BEAR TRACK PRODUCTS"
    },
    "BTTL": {
        "id": "BTTL",
        "name": "BEAR TEARDROP TRAILERS LLC"
    },
    "BTVS": {
        "id": "BTVS",
        "name": "BATAVUS MO-PED"
    },
    "BTWD": {
        "id": "BTWD",
        "name": "BENTLEY WELDING INC ROSEBURG OR"
    },
    "BUBC": {
        "id": "BUBC",
        "name": "BUSHBABBY CHOPPERS NAMPA"
    },
    "BUCC": {
        "id": "BUCC",
        "name": "BUCCANEER MOBILE HOMES DIV BRIGADIER INDUSTRIES"
    },
    "BUCI": {
        "id": "BUCI",
        "name": "BUCITA POP TOP TRAILER"
    },
    "BUCK": {
        "id": "BUCK",
        "name": "BUCK CAMPER"
    },
    "BUCM": {
        "id": "BUCM",
        "name": "BUCKEYE MOBILE HOMES"
    },
    "BUCN": {
        "id": "BUCN",
        "name": "BUCKEYE FLATBED TRAILER"
    },
    "BUCO": {
        "id": "BUCO",
        "name": "BUCKO"
    },
    "BUCS": {
        "id": "BUCS",
        "name": "BUCKSKIN TRAILER MFG."
    },
    "BUCY": {
        "id": "BUCY",
        "name": "BUCYRUS-ERIE"
    },
    "BUDC": {
        "id": "BUDC",
        "name": "BUDD"
    },
    "BUDD": {
        "id": "BUDD",
        "name": "BUDDY MOBILE HOMES"
    },
    "BUDG": {
        "id": "BUDG",
        "name": "BUDGER MFG."
    },
    "BUDM": {
        "id": "BUDM",
        "name": "BUDMASH KIEV"
    },
    "BUDR": {
        "id": "BUDR",
        "name": "BUCK DRAGSTER FLATBED"
    },
    "BUDS": {
        "id": "BUDS",
        "name": "BUDSON KNOBBY FLATBED TRAILER"
    },
    "BUEL": {
        "id": "BUEL",
        "name": "BUELL MOTOR"
    },
    "BUFF": {
        "id": "BUFF",
        "name": "BUFFALO-BOMAG DIV.DIV. OF KOEHRING"
    },
    "BUG": {
        "id": "BUG",
        "name": "BUG"
    },
    "BUGA": {
        "id": "BUGA",
        "name": "BUGATTI"
    },
    "BUHL": {
        "id": "BUHL",
        "name": "BUHL MACHINE WORKS"
    },
    "BUIC": {
        "id": "BUIC",
        "name": "BUICK"
    },
    "BUIL": {
        "id": "BUIL",
        "name": "BUILT RITE TRAILER"
    },
    "BULK": {
        "id": "BULK",
        "name": "BULK-HAULER TRAILER SEE KELLEBREW MFG"
    },
    "BULL": {
        "id": "BULL",
        "name": "BULLMOBILE TRAILER"
    },
    "BULM": {
        "id": "BULM",
        "name": "BULK MANUFACTURING COMPANY PLANT CITY"
    },
    "BULP": {
        "id": "BULP",
        "name": "BULLETTPROOF TRAILERS"
    },
    "BULR": {
        "id": "BULR",
        "name": "BULK RESOURCES"
    },
    "BULT": {
        "id": "BULT",
        "name": "BULTA"
    },
    "BULY": {
        "id": "BULY",
        "name": "BULLSEYE TRAILERS GEORGIA"
    },
    "BUNK": {
        "id": "BUNK",
        "name": "BUNKER-TRIKE S.L. BARCELONA"
    },
    "BUNT": {
        "id": "BUNT",
        "name": "BUNTON COMPANYLOUISVILLE"
    },
    "BUNY": {
        "id": "BUNY",
        "name": "BUNYAN"
    },
    "BURC": {
        "id": "BURC",
        "name": "BURCH TRAILER"
    },
    "BURE": {
        "id": "BURE",
        "name": "BURKEEN EQUIPMENT & SUPPLY"
    },
    "BURG": {
        "id": "BURG",
        "name": "BURG TRAILERS"
    },
    "BURI": {
        "id": "BURI",
        "name": "BURLEY IRON WORKS BURLEY"
    },
    "BURK": {
        "id": "BURK",
        "name": "BURKHART TRAILER MFG."
    },
    "BURL": {
        "id": "BURL",
        "name": "BURLINGTON MFG."
    },
    "BURN": {
        "id": "BURN",
        "name": "BURNUP AND SIMS"
    },
    "BURO": {
        "id": "BURO",
        "name": "BURRITO ALSO SEE MAKE J.C. PENNEY"
    },
    "BURR": {
        "id": "BURR",
        "name": "BURRIS STOCK TRAILER"
    },
    "BUSH": {
        "id": "BUSH",
        "name": "BUSHCRAFT TRAILER"
    },
    "BUSO": {
        "id": "BUSO",
        "name": "BUSHOG DIV.SUBSIDARY OF ALLIED PRODUCTS CORP.SELMA"
    },
    "BUSP": {
        "id": "BUSP",
        "name": "BUFFALO-SPRINGFIELD DIV.DIV. OF KOEHRING"
    },
    "BUST": {
        "id": "BUST",
        "name": "BUCKSTOP TRUCKWARE INC PRINEVILLE OR"
    },
    "BUTE": {
        "id": "BUTE",
        "name": "BUTLER"
    },
    "BUTL": {
        "id": "BUTL",
        "name": "BUTLER MFG."
    },
    "BUTR": {
        "id": "BUTR",
        "name": "BUSHTREE MANUFACTURING ORLAND"
    },
    "BUTT": {
        "id": "BUTT",
        "name": "BUTTERFIELD MUSKETEER"
    },
    "BVCR": {
        "id": "BVCR",
        "name": "BEAVER CREK ENT.  MOKENA"
    },
    "BVMC": {
        "id": "BVMC",
        "name": "BEAVER MOTORCOACH CORP BEND"
    },
    "BWAY": {
        "id": "BWAY",
        "name": "BETTER-WAY PRODUCTS"
    },
    "BWCH": {
        "id": "BWCH",
        "name": "BROADWAY CHOPPERS SCHENECTADY"
    },
    "BWCM": {
        "id": "BWCM",
        "name": "BLACK WIDOW CUSTOM MOTORCYCLE WORKS"
    },
    "BWCT": {
        "id": "BWCT",
        "name": "B & W CUSTOM TRAILERS FORT WORTH"
    },
    "BWGH": {
        "id": "BWGH",
        "name": "BETTER WEIGH MANUFACTURING TOLEDO"
    },
    "BWHO": {
        "id": "BWHO",
        "name": "B & W HOMES"
    },
    "BWIS": {
        "id": "BWIS",
        "name": "BWISE TRAILERS ALSO - ALTERNATE HEATING SYSTEMS"
    },
    "BWMY": {
        "id": "BWMY",
        "name": "BOWEN MCLAUGHLIN YORK BMY DIV OF HARAS"
    },
    "BWSM": {
        "id": "BWSM",
        "name": "BWS MANUFACTURING LTD. CANADA"
    },
    "BYDA": {
        "id": "BYDA",
        "name": "BYD AUTO OR BYD AUTO"
    },
    "BYER": {
        "id": "BYER",
        "name": "BYERLY TRAILER & MFG."
    },
    "BYHI": {
        "id": "BYHI",
        "name": "BAY HORSE INNOVATIONS"
    },
    "BYMW": {
        "id": "BYMW",
        "name": "BOYDSTUN METAL WORKS"
    },
    "BYNG": {
        "id": "BYNG",
        "name": "BUYANG GROUP"
    },
    "BYOR": {
        "id": "BYOR",
        "name": "BAKER-YORK FORKLIFT"
    },
    "BYRM": {
        "id": "BYRM",
        "name": "BYERS MFG. INC.LAWTON"
    },
    "BYRS": {
        "id": "BYRS",
        "name": "BYERS"
    },
    "BYRT": {
        "id": "BYRT",
        "name": "BYE-RITE CORPORATION"
    },
    "BYSN": {
        "id": "BYSN",
        "name": "BYSON TRAILERS"
    },
    "BYST": {
        "id": "BYST",
        "name": "BAY STAR AND BAY STAR SPORT MFG BY NEWMAR CORP"
    },
    "BZEL": {
        "id": "BZEL",
        "name": "B & Z ELECTRIC CAR"
    },
    "BZRB": {
        "id": "BZRB",
        "name": "BILLS RAZORBACK TRAILER"
    },
    "BZTI": {
        "id": "BZTI",
        "name": "BLAZING TECHNOLOGIES INC MOHNTON PA"
    },
    "CAAR": {
        "id": "CAAR",
        "name": "CARABELA MEXICO"
    },
    "CAAY": {
        "id": "CAAY",
        "name": "CARROCERIAS AYATS"
    },
    "CABA": {
        "id": "CABA",
        "name": "CABANA MOTOR HOME"
    },
    "CABK": {
        "id": "CABK",
        "name": "CAMPUS BIKE"
    },
    "CABL": {
        "id": "CABL",
        "name": "CABLE CAR CONCEPTS CAPE MAY"
    },
    "CACA": {
        "id": "CACA",
        "name": "CAR CADDY"
    },
    "CACC": {
        "id": "CACC",
        "name": "CALLAHAN CUSTOM CHOPPERS"
    },
    "CACR": {
        "id": "CACR",
        "name": "CAN-CAR CANADIAN CAR TRAILER SALES DIV.REXDALE"
    },
    "CACS": {
        "id": "CACS",
        "name": "CART AWAY CONCRETE SYSTEMS INC MCMINNVILLE OR"
    },
    "CACV": {
        "id": "CACV",
        "name": "CLASSIC ACQUISITIONS"
    },
    "CACY": {
        "id": "CACY",
        "name": "CAPER CYCLE"
    },
    "CADE": {
        "id": "CADE",
        "name": "CADET COACH CORP."
    },
    "CADF": {
        "id": "CADF",
        "name": "CADILLAC FABRICATION INC"
    },
    "CADI": {
        "id": "CADI",
        "name": "CADILLAC"
    },
    "CADM": {
        "id": "CADM",
        "name": "CADMAN POWER EQUIPMENT LIMITED"
    },
    "CADV": {
        "id": "CADV",
        "name": "CAPE ADVANCED VEHICLES PTY"
    },
    "CAEQ": {
        "id": "CAEQ",
        "name": "CAROLINA EQUIPMENT SALES"
    },
    "CAFF": {
        "id": "CAFF",
        "name": "CALIFFO"
    },
    "CAGI": {
        "id": "CAGI",
        "name": "CAGIVA OR CAGIVA MOTOR S.P.A."
    },
    "CAHA": {
        "id": "CAHA",
        "name": "CAMPBELL-HAUSFELD DIV."
    },
    "CAJN": {
        "id": "CAJN",
        "name": "CAJUN CARGO TRAILERS LLC PEARSON"
    },
    "CALB": {
        "id": "CALB",
        "name": "CALIBER TRAVEL TRAILER MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "CALC": {
        "id": "CALC",
        "name": "CALCINATOR"
    },
    "CALD": {
        "id": "CALD",
        "name": "E. L. CALDWELL & SONS"
    },
    "CALF": {
        "id": "CALF",
        "name": "CALIFORNIA CAMPER MFG."
    },
    "CALH": {
        "id": "CALH",
        "name": "CALIFORNIA MOBILE HOMES"
    },
    "CALI": {
        "id": "CALI",
        "name": "CALVIA BROTHERS TRAILER MFG."
    },
    "CALL": {
        "id": "CALL",
        "name": "CALLAHAN ENGINEERING"
    },
    "CALM": {
        "id": "CALM",
        "name": "CALUMET"
    },
    "CALN": {
        "id": "CALN",
        "name": "CALLEN CAMPER"
    },
    "CALO": {
        "id": "CALO",
        "name": "CAPRIOLO"
    },
    "CALP": {
        "id": "CALP",
        "name": "CALTHORPE MOTORCYCLE"
    },
    "CALS": {
        "id": "CALS",
        "name": "CALIS TRAVEL TRAILER"
    },
    "CALT": {
        "id": "CALT",
        "name": "CAL TRAILER MANUFACTURING SAN FERNANDO"
    },
    "CALU": {
        "id": "CALU",
        "name": "CALUMET COACH"
    },
    "CALW": {
        "id": "CALW",
        "name": "CALDWELD DIV."
    },
    "CALY": {
        "id": "CALY",
        "name": "CALYPSO"
    },
    "CAMA": {
        "id": "CAMA",
        "name": "CAMBRIDGE HOMES"
    },
    "CAMB": {
        "id": "CAMB",
        "name": "CAMBRIDGE MFG."
    },
    "CAMC": {
        "id": "CAMC",
        "name": "CAMP CRUISER MFG."
    },
    "CAMD": {
        "id": "CAMD",
        "name": "CAMEL CAMPER TRAILER"
    },
    "CAME": {
        "id": "CAME",
        "name": "CAMP EQUIPMENT"
    },
    "CAMF": {
        "id": "CAMF",
        "name": "CAMP FOUR INDUSTRIES"
    },
    "CAMI": {
        "id": "CAMI",
        "name": "CAM-PACT"
    },
    "CAML": {
        "id": "CAML",
        "name": "CAMP-A-WHILE HOMES MFG."
    },
    "CAMM": {
        "id": "CAMM",
        "name": "CAMPER CITY COACH CRAFT"
    },
    "CAMO": {
        "id": "CAMO",
        "name": "CAMPER CORP. OF AMERICA"
    },
    "CAMP": {
        "id": "CAMP",
        "name": "CAMPER DE VILLE"
    },
    "CAMR": {
        "id": "CAMR",
        "name": "CAMPERS"
    },
    "CAMS": {
        "id": "CAMS",
        "name": "CAMPERS UNLIMITED"
    },
    "CAMT": {
        "id": "CAMT",
        "name": "CAMELOT CAMPER TRAILER"
    },
    "CAMU": {
        "id": "CAMU",
        "name": "CAMP-MOR"
    },
    "CAMV": {
        "id": "CAMV",
        "name": "CAMP-N-ALL"
    },
    "CAMY": {
        "id": "CAMY",
        "name": "CAM-O-TEL CORP."
    },
    "CANA": {
        "id": "CANA",
        "name": "CAN-AM ATV'S"
    },
    "CANC": {
        "id": "CANC",
        "name": "CAN-CAR INC.TREE-FARMING EQUIPMENT DIV HAWKER-SIDDELEY ONTARIO"
    },
    "CAND": {
        "id": "CAND",
        "name": "CANADIAN TRAILMOBILE LTD."
    },
    "CANE": {
        "id": "CANE",
        "name": "CANE RIVER TRAILER"
    },
    "CANG": {
        "id": "CANG",
        "name": "CANOGA CLASSIC CAMPERS"
    },
    "CANN": {
        "id": "CANN",
        "name": "CANNONDALE CORPORATION"
    },
    "CANO": {
        "id": "CANO",
        "name": "CANOGA MFG."
    },
    "CANS": {
        "id": "CANS",
        "name": "C&S"
    },
    "CANT": {
        "id": "CANT",
        "name": "CANTON TRAILER"
    },
    "CANV": {
        "id": "CANV",
        "name": "CANADIAN ELECTRIC VEHICLES LTD. ALTERNATIVE FUEL VEHICLES AND PARTS FOR"
    },
    "CANY": {
        "id": "CANY",
        "name": "CANYON STAR MFG BY NEWMAR CORP"
    },
    "CAP": {
        "id": "CAP",
        "name": "CAPRI IMPORTED BY MERCURY PRIORTO 1979"
    },
    "CAPA": {
        "id": "CAPA",
        "name": "CAPITAL INDUSTRIES"
    },
    "CAPC": {
        "id": "CAPC",
        "name": "CAP"
    },
    "CAPE": {
        "id": "CAPE",
        "name": "CAPRECARROCERIAS PRECONSTRUIDAS SASAN NICHOLAS"
    },
    "CAPH": {
        "id": "CAPH",
        "name": "CATAPHOTEDIV. FERRO CORP."
    },
    "CAPI": {
        "id": "CAPI",
        "name": "CAPITOL"
    },
    "CAPL": {
        "id": "CAPL",
        "name": "CAPITOL TRAILER COACH"
    },
    "CAPM": {
        "id": "CAPM",
        "name": "CAPRICE HOMES MFS."
    },
    "CAPO": {
        "id": "CAPO",
        "name": "CAPONES STREET ROD MOTORCYCLES SHELBYVILLE"
    },
    "CAPR": {
        "id": "CAPR",
        "name": "CAPRI"
    },
    "CAPT": {
        "id": "CAPT",
        "name": "CAPACITY OF TEXAS"
    },
    "CARA": {
        "id": "CARA",
        "name": "CAR CAMP"
    },
    "CARB": {
        "id": "CARB",
        "name": "CARAVAN INDUSTRIES"
    },
    "CARC": {
        "id": "CARC",
        "name": "CARTER TRAILER"
    },
    "CARD": {
        "id": "CARD",
        "name": "CARAVEL INDUSTRIES WYLLIESBURG VIRGINIA"
    },
    "CARE": {
        "id": "CARE",
        "name": "CARDINAL INDUSTRIES"
    },
    "CARF": {
        "id": "CARF",
        "name": "CARE-FREE CAMPER MFG."
    },
    "CARG": {
        "id": "CARG",
        "name": "CARRIAGE"
    },
    "CARH": {
        "id": "CARH",
        "name": "CARDINAL HOMES"
    },
    "CARI": {
        "id": "CARI",
        "name": "CARIBOU CRAFT PICKUP CAMPER & TRAILER MFG"
    },
    "CARK": {
        "id": "CARK",
        "name": "CARLEY"
    },
    "CARL": {
        "id": "CARL",
        "name": "CARLISLE"
    },
    "CARM": {
        "id": "CARM",
        "name": "CARMA MFG."
    },
    "CARN": {
        "id": "CARN",
        "name": "CAROLINA MOBILE HOMES"
    },
    "CARO": {
        "id": "CARO",
        "name": "CAROLINA TRAVEL TRAILERS"
    },
    "CARP": {
        "id": "CARP",
        "name": "CARPENTER MANUFACTURING"
    },
    "CARR": {
        "id": "CARR",
        "name": "CARRIE-CRAFT DIV.DIV. THRIFT CTS. OF AMERICA"
    },
    "CARS": {
        "id": "CARS",
        "name": "CARRY CRAFT TRAILERS"
    },
    "CART": {
        "id": "CART",
        "name": "CAR-TEL CORP."
    },
    "CARW": {
        "id": "CARW",
        "name": "CARNES WELDING & FABRICATING"
    },
    "CARY": {
        "id": "CARY",
        "name": "CAREY TRAILERS"
    },
    "CASA": {
        "id": "CASA",
        "name": "CASA MANANA MFG. CORP."
    },
    "CASC": {
        "id": "CASC",
        "name": "CASCADE CORP."
    },
    "CASE": {
        "id": "CASE",
        "name": "CASE"
    },
    "CASH": {
        "id": "CASH",
        "name": "CASCADE COACH"
    },
    "CASI": {
        "id": "CASI",
        "name": "CASITA ENTERPRISES"
    },
    "CASL": {
        "id": "CASL",
        "name": "CASAL"
    },
    "CASM": {
        "id": "CASM",
        "name": "CASCADE CUSTOM MANUFACTURING"
    },
    "CASR": {
        "id": "CASR",
        "name": "CASH TRAILERS"
    },
    "CAST": {
        "id": "CAST",
        "name": "CASTLE ENTERPRISES"
    },
    "CASU": {
        "id": "CASU",
        "name": "CASUAL MOTORHOMES"
    },
    "CASY": {
        "id": "CASY",
        "name": "CONVEYOR APPLICATION SYSTEMS"
    },
    "CAT": {
        "id": "CAT",
        "name": "CATERPILLAR TRACTOR"
    },
    "CATA": {
        "id": "CATA",
        "name": "CATALINA AMPHIBIOUS HOMES"
    },
    "CATC": {
        "id": "CATC",
        "name": "CLASSIC ALUMINUM TRAILER CORP"
    },
    "CATE": {
        "id": "CATE",
        "name": "CATERHAM CARS UNITED KINGDOM"
    },
    "CATI": {
        "id": "CATI",
        "name": "CARSON TRAILER INCCARSON"
    },
    "CATL": {
        "id": "CATL",
        "name": "CATALINA MOTOR HOME"
    },
    "CATM": {
        "id": "CATM",
        "name": "CAT"
    },
    "CATO": {
        "id": "CATO",
        "name": "CATOLAC CORP."
    },
    "CATT": {
        "id": "CATT",
        "name": "CANADIAN TRUCK AND TRAILER INC ONTARIO CANADA"
    },
    "CAUL": {
        "id": "CAUL",
        "name": "CALKINS BOAT TRAILER"
    },
    "CAVA": {
        "id": "CAVA",
        "name": "CAVALIER HOMES"
    },
    "CAVC": {
        "id": "CAVC",
        "name": "CAVALIER TRAILER MFG."
    },
    "CAVE": {
        "id": "CAVE",
        "name": "CAVEMAN INDUSTRIES"
    },
    "CAVL": {
        "id": "CAVL",
        "name": "CALVACADE INDUSTRIES"
    },
    "CAWK": {
        "id": "CAWK",
        "name": "C-HAWK TRAILERS"
    },
    "CAZA": {
        "id": "CAZA",
        "name": "CAZADOR USA CARROLLTON TX PARENT COMPANY AHA MOTORSPORTS INC"
    },
    "CAZZ": {
        "id": "CAZZ",
        "name": "CAZZANI CUSTOM CYCLES"
    },
    "CBAT": {
        "id": "CBAT",
        "name": "COMBAT SUPPORT PRODUCTS INC"
    },
    "CBCC": {
        "id": "CBCC",
        "name": "CHOP BOYZ CUSTOM CYCLE CHESAPEAK VA"
    },
    "CBCM": {
        "id": "CBCM",
        "name": "C & B CUSTOM MODULAR"
    },
    "CBEQ": {
        "id": "CBEQ",
        "name": "COLUMBIA BODY & EQUIPMENT COMPANY PORTLAND"
    },
    "CBGT": {
        "id": "CBGT",
        "name": "CSTOM BUILT GOOSENECK TRAILERS"
    },
    "CBII": {
        "id": "CBII",
        "name": "COBRA INDUSTRIES INC ELKHART IN"
    },
    "CBLT": {
        "id": "CBLT",
        "name": "CUSTOM BUILT TRAILERS IRON RIVER MN"
    },
    "CBMA": {
        "id": "CBMA",
        "name": "C. B. MFG."
    },
    "CBMI": {
        "id": "CBMI",
        "name": "COLORADO BUILT MANUFACTURING"
    },
    "CBQT": {
        "id": "CBQT",
        "name": "C & B QUALITY TRAILER WORKS"
    },
    "CBRA": {
        "id": "CBRA",
        "name": "COBRA TRAILERS"
    },
    "CBRG": {
        "id": "CBRG",
        "name": "COURTNEY BERG INDUSTRIES"
    },
    "CBRO": {
        "id": "CBRO",
        "name": "CARTER BROTHERS"
    },
    "CBRT": {
        "id": "CBRT",
        "name": "C B REPAIR AND TRAILER MAINTENANCE"
    },
    "CBSK": {
        "id": "CBSK",
        "name": "CRUSTBUSTER SPEED KING"
    },
    "CBTL": {
        "id": "CBTL",
        "name": "CUSTOM BUILT TRAILERS OR CUSTOM TRIKES"
    },
    "CBTS": {
        "id": "CBTS",
        "name": "CUSTOM BUILT TRAILER SALES LUBBOCK"
    },
    "CBUT": {
        "id": "CBUT",
        "name": "BUTLER"
    },
    "CCAL": {
        "id": "CCAL",
        "name": "CEN-CAL TRAILER FRESNO"
    },
    "CCAR": {
        "id": "CCAR",
        "name": "CAROLINA CARPORTS CARDINAL TRAILERS DOBSON NC"
    },
    "CCC": {
        "id": "CCC",
        "name": "CCC CRANE CARRIER-A CCI CO"
    },
    "CCCC": {
        "id": "CCCC",
        "name": "CHARLOTTE COUNTY CUSTON CYCLES PORT CHARLOTTE"
    },
    "CCCH": {
        "id": "CCCH",
        "name": "CIRCLE CITY CHOPPERS"
    },
    "CCCY": {
        "id": "CCCY",
        "name": "CAREFREE CUSTOM CYCLES"
    },
    "CCDB": {
        "id": "CCDB",
        "name": "CLACKACRAFT DRIFT BOATS CLACKAMAS OR"
    },
    "CCDI": {
        "id": "CCDI",
        "name": "C & C DISTRIBUTORS"
    },
    "CCEN": {
        "id": "CCEN",
        "name": "CAL CENTRAL CATERING TRAILERS MODESTO"
    },
    "CCFL": {
        "id": "CCFL",
        "name": "CUSTOM CUT FABRICATION LLC JACKSON MO"
    },
    "CCFT": {
        "id": "CCFT",
        "name": "CHANDLER CUSTOM FAB EAGLE POINT OR"
    },
    "CCHR": {
        "id": "CCHR",
        "name": "CUSTOM CHROME CALIFORNIA - MOTORCYCLES"
    },
    "CCIN": {
        "id": "CCIN",
        "name": "CART CONCEPTS INTERNATIONAL"
    },
    "CCMF": {
        "id": "CCMF",
        "name": "C&C MANUFACTURING & FAB HAZLETON"
    },
    "CCMG": {
        "id": "CCMG",
        "name": "CC MANUFACTURING INC. ELKHART"
    },
    "CCMH": {
        "id": "CCMH",
        "name": "COUNTRY COACH MOTOR HOMEJUNCTION CITY"
    },
    "CCOM": {
        "id": "CCOM",
        "name": "COULSON COMMANDER TRAILERS IDAHO"
    },
    "CCOT": {
        "id": "CCOT",
        "name": "CUSTOM COTTAGES"
    },
    "CCPI": {
        "id": "CCPI",
        "name": "CAROLINA CUSTOM PRODUCTS"
    },
    "CCRI": {
        "id": "CCRI",
        "name": "CC RIDER TRAILER"
    },
    "CCRO": {
        "id": "CCRO",
        "name": "CALCROSS S DE R.L DE C.V. MEXICO"
    },
    "CCTI": {
        "id": "CCTI",
        "name": "C & C TRAILERS"
    },
    "CCTM": {
        "id": "CCTM",
        "name": "CROSS COUNTRY MANUFACTURING"
    },
    "CCTR": {
        "id": "CCTR",
        "name": "CHUYS C AND FIVE TRAILERS ENNIS TX"
    },
    "CCUC": {
        "id": "CCUC",
        "name": "CREATE-A-CUSTOM CYCLE TOMBSTONE"
    },
    "CCWI": {
        "id": "CCWI",
        "name": "CUSTOM CHOPPERWERKS"
    },
    "CCYC": {
        "id": "CCYC",
        "name": "CC CYCLES"
    },
    "CDAR": {
        "id": "CDAR",
        "name": "CEDAR RAPIDS DIV OF TEREX"
    },
    "CDFK": {
        "id": "CDFK",
        "name": "FRANKLIN RETREATS BRAND MFG BY C3 DESIGNS"
    },
    "CDRT": {
        "id": "CDRT",
        "name": "C3 RETREATS BRAND MFG BY C3 DESIGN"
    },
    "CDSN": {
        "id": "CDSN",
        "name": "C3 DESIGN"
    },
    "CEAG": {
        "id": "CEAG",
        "name": "EAGLE & EAGLE LITE MFG BY CAHRIOT EAGLE INC"
    },
    "CECC": {
        "id": "CECC",
        "name": "CREEKSIDE CABIN SERIES BRAND MFG BY CHARIOT EAGLE"
    },
    "CECL": {
        "id": "CECL",
        "name": "COASTAL COTTAGE MFG BY CHARIOT EAGLE LLC"
    },
    "CECO": {
        "id": "CECO",
        "name": "CE"
    },
    "CEDI": {
        "id": "CEDI",
        "name": "CREATIVE & ELEGANT DESIGNS"
    },
    "CEEC": {
        "id": "CEEC",
        "name": "EAGLE CREEK MODEL MFG BY CHARIOT EAGLE"
    },
    "CEEZ": {
        "id": "CEEZ",
        "name": "CZ ENGINEERING"
    },
    "CEHK": {
        "id": "CEHK",
        "name": "HAWK SERIES MFG BY CHARIOT EAGLE"
    },
    "CEIQ": {
        "id": "CEIQ",
        "name": "CEI EQUIPMENT COMPANY"
    },
    "CEKO": {
        "id": "CEKO",
        "name": "KOA LODGES BRAND MFG BY CHARIOT EAGLE"
    },
    "CELC": {
        "id": "CELC",
        "name": "LAKE CHARLES BRAND MFG BY CHARIOT EAGLE"
    },
    "CENA": {
        "id": "CENA",
        "name": "CENTRAL INDUSTRIES"
    },
    "CENH": {
        "id": "CENH",
        "name": "CENTRAL HITCH & EQUIPMENT"
    },
    "CENI": {
        "id": "CENI",
        "name": "CENTURION INTERNATIONAL"
    },
    "CENN": {
        "id": "CENN",
        "name": "CENNTRO AUTOMOTIVE CORPORATION CENTRO MOTOR CORPORATION ELEC PASS"
    },
    "CENP": {
        "id": "CENP",
        "name": "CENTRAL PURCHASING"
    },
    "CENR": {
        "id": "CENR",
        "name": "CENTURY AUTO BODY & TRAILER MFG."
    },
    "CENT": {
        "id": "CENT",
        "name": "CENTAUR"
    },
    "CENU": {
        "id": "CENU",
        "name": "CENTURION TRAVEL TRAILER"
    },
    "CENY": {
        "id": "CENY",
        "name": "CENTURY ENGINEERING CORP."
    },
    "CERT": {
        "id": "CERT",
        "name": "CERTIFIED BOAT TRAILER"
    },
    "CESC": {
        "id": "CESC",
        "name": "C E SMITH"
    },
    "CESS": {
        "id": "CESS",
        "name": "SEASHORE COTTAGE MFG BY CHARIOT EAGLE LLC"
    },
    "CETP": {
        "id": "CETP",
        "name": "TROPHY PARK MODEL MFG BY CHARIOT EAGLE"
    },
    "CEVA": {
        "id": "CEVA",
        "name": "CEVA MFG."
    },
    "CEWI": {
        "id": "CEWI",
        "name": "CHARIOT EAGLE"
    },
    "CEZE": {
        "id": "CEZE",
        "name": "CEZETTA"
    },
    "CFAB": {
        "id": "CFAB",
        "name": "CAM FAB"
    },
    "CFBT": {
        "id": "CFBT",
        "name": "CUSTOM FAB BODY BODIES ANDTRAILERS"
    },
    "CFCH": {
        "id": "CFCH",
        "name": "COLONY FACTORY CRAFTED HOMES SHIPPENSVILLS"
    },
    "CFCO": {
        "id": "CFCO",
        "name": "CRAFCO"
    },
    "CFHG": {
        "id": "CFHG",
        "name": "CHUNFENG HOLDING GROUP"
    },
    "CFLC": {
        "id": "CFLC",
        "name": "RAD CUSTOMS"
    },
    "CFLT": {
        "id": "CFLT",
        "name": "CENTRAL FLORIDA TRAILER SALES"
    },
    "CFMH": {
        "id": "CFMH",
        "name": "CLIFTON MANUFACTURED HOME"
    },
    "CFWS": {
        "id": "CFWS",
        "name": "CF'S WELDING SERVICE AND CUSTOM PRODUCTS ALEXANDRIA"
    },
    "CGMT": {
        "id": "CGMT",
        "name": "CARGOMATE TRAILER  CAMPING"
    },
    "CGSP": {
        "id": "CGSP",
        "name": "CGS PREMIER INC. NEW BERLIN"
    },
    "CGXP": {
        "id": "CGXP",
        "name": "J & L'S CARGO EXPRESS"
    },
    "CHAC": {
        "id": "CHAC",
        "name": "CHALLENGE-COOK BROTHERS"
    },
    "CHAE": {
        "id": "CHAE",
        "name": "CHALLENGER HOMES"
    },
    "CHAG": {
        "id": "CHAG",
        "name": "CHARGER PICK-UP CAMPERS"
    },
    "CHAH": {
        "id": "CHAH",
        "name": "CHARMAC TRAILERS"
    },
    "CHAI": {
        "id": "CHAI",
        "name": "CHAIKA"
    },
    "CHAK": {
        "id": "CHAK",
        "name": "ATHENS PARK EXTREME MFG BY CHAMPION HOME BUILDERS"
    },
    "CHAL": {
        "id": "CHAL",
        "name": "CHAMBERLIN TRAILERS HAMPTON"
    },
    "CHAM": {
        "id": "CHAM",
        "name": "CHAMPION HOME BUILDERS"
    },
    "CHAN": {
        "id": "CHAN",
        "name": "CHANG JIANG MOTORCYCLES DONG TIAN ENTERPRISES"
    },
    "CHAO": {
        "id": "CHAO",
        "name": "CHAOZHONG INDUSTRIAL"
    },
    "CHAP": {
        "id": "CHAP",
        "name": "CHAPARRAL"
    },
    "CHAR": {
        "id": "CHAR",
        "name": "CHARLAMOR CORP."
    },
    "CHAS": {
        "id": "CHAS",
        "name": "ATHENS PARK SELECT MFG BY CHAMPION HOME BUILDERS"
    },
    "CHAT": {
        "id": "CHAT",
        "name": "CHATEAU MFG."
    },
    "CHAU": {
        "id": "CHAU",
        "name": "CHAUSSE MANUFACTURING"
    },
    "CHBT": {
        "id": "CHBT",
        "name": "CHATTIN BOAT TRAILERS IDAHO TRAILERS"
    },
    "CHBY": {
        "id": "CHBY",
        "name": "CHINOOK BAYSIDE MFG BY CHINOOK MOTOR COACH LLC"
    },
    "CHCD": {
        "id": "CHCD",
        "name": "CASAGRANDE HYDRAULIC CRAWLER DRILL"
    },
    "CHCF": {
        "id": "CHCF",
        "name": "CRAFTSMAN MODEL MFG BY CHANPION HOME BUILDERS"
    },
    "CHCI": {
        "id": "CHCI",
        "name": "CHANCE COACH INC. WICHITA"
    },
    "CHCN": {
        "id": "CHCN",
        "name": "CHEMICAL CONTAINERS INC. FLORIDA"
    },
    "CHCO": {
        "id": "CHCO",
        "name": "CHAMP CORP."
    },
    "CHCY": {
        "id": "CHCY",
        "name": "COUNTRYSIDE BRAND MFG BY CHINOOK MOTOR COACH"
    },
    "CHEC": {
        "id": "CHEC",
        "name": "CHECKER"
    },
    "CHEE": {
        "id": "CHEE",
        "name": "CHERNE INDUSTRIAL"
    },
    "CHEM": {
        "id": "CHEM",
        "name": "CHEM-FARM"
    },
    "CHEN": {
        "id": "CHEN",
        "name": "CHENEY WEEDER"
    },
    "CHEO": {
        "id": "CHEO",
        "name": "CHEROKEE CAMPER"
    },
    "CHEP": {
        "id": "CHEP",
        "name": "CHEVRON CORP.ELKHART"
    },
    "CHER": {
        "id": "CHER",
        "name": "CHEROKEE MOBILE HOMES"
    },
    "CHES": {
        "id": "CHES",
        "name": "CHESAPEAKE MOBILE HOMES"
    },
    "CHET": {
        "id": "CHET",
        "name": "CHESTON & ESHELMAN"
    },
    "CHEV": {
        "id": "CHEV",
        "name": "CHEVROLET"
    },
    "CHEX": {
        "id": "CHEX",
        "name": "CHEVELLE MOBILE HOMES"
    },
    "CHFB": {
        "id": "CHFB",
        "name": "CHEROKEE FABRICATION"
    },
    "CHGO": {
        "id": "CHGO",
        "name": "CHICAGO SCOOTER COMPANY"
    },
    "CHGP": {
        "id": "CHGP",
        "name": "APH-GRAND PARK ATHENS PARK HOMES-NOW MFG BY CHAMPION HOME BUILDERS"
    },
    "CHIA": {
        "id": "CHIA",
        "name": "CHICAGO PNEUMATIC TOOL"
    },
    "CHIB": {
        "id": "CHIB",
        "name": "CHIBI"
    },
    "CHIC": {
        "id": "CHIC",
        "name": "CHICKASHA MOBILE HOMES"
    },
    "CHIE": {
        "id": "CHIE",
        "name": "CHIEF OCALA TRAILER MFG."
    },
    "CHIG": {
        "id": "CHIG",
        "name": "CHONGQING HUANSONG INDUSTRIES GROUP"
    },
    "CHII": {
        "id": "CHII",
        "name": "BONNAVILLA MFG BY CHIEF INDUSTRIES"
    },
    "CHIL": {
        "id": "CHIL",
        "name": "CHILTON TRAILER"
    },
    "CHIM": {
        "id": "CHIM",
        "name": "CHIPMORE MFG."
    },
    "CHIN": {
        "id": "CHIN",
        "name": "CHING-KAN-SHAN"
    },
    "CHIP": {
        "id": "CHIP",
        "name": "CHIPPEWA MOBILE HOMES CORP."
    },
    "CHIS": {
        "id": "CHIS",
        "name": "CHISOLM TRAILERS"
    },
    "CHIV": {
        "id": "CHIV",
        "name": "INOVATION MFG BY CHAMPION HOME BUILDERS"
    },
    "CHKE": {
        "id": "CHKE",
        "name": "CHEROKEE MANUFACTURING COMPANY SWEETWATER"
    },
    "CHKG": {
        "id": "CHKG",
        "name": "CHASSIS KING"
    },
    "CHLG": {
        "id": "CHLG",
        "name": "CHALLENGER TRAILER"
    },
    "CHLL": {
        "id": "CHLL",
        "name": "CAHLLENGER MFG BY THOR MOTOR COACH INC."
    },
    "CHLM": {
        "id": "CHLM",
        "name": "CHANGSHU LIGHT MOTORCYCLE FACTORY JIANGSHU PROVINCE-CHANGSHU CITY"
    },
    "CHLT": {
        "id": "CHLT",
        "name": "CHALET RV INC OREGON ALPINE"
    },
    "CHMC": {
        "id": "CHMC",
        "name": "CHEROKEE MOTORCYCLE COMPANY OKLAHOMA FORMERLY KNOWN AS ''FAST TRAC"
    },
    "CHMI": {
        "id": "CHMI",
        "name": "CHAPARRAL MANUFACTURING"
    },
    "CHMM": {
        "id": "CHMM",
        "name": "TAIZHOU CHUANL MOTORCYCLE MANUFACTURING"
    },
    "CHMN": {
        "id": "CHMN",
        "name": "MANOR MFG BY CHAMPION HOME BUILDERS PREV MFG BY ATHENS PARK HOMES"
    },
    "CHMP": {
        "id": "CHMP",
        "name": "CHAMP HORSE TRAILER"
    },
    "CHMR": {
        "id": "CHMR",
        "name": "CHIMERA CUSTOMS MILLERSTOWN"
    },
    "CHMT": {
        "id": "CHMT",
        "name": "CHAMPION TRAILERS"
    },
    "CHMW": {
        "id": "CHMW",
        "name": "CHARLES MACHINE WORKS"
    },
    "CHNA": {
        "id": "CHNA",
        "name": "CHANGZHOU NANXIASHU TOOL COMPANY"
    },
    "CHND": {
        "id": "CHND",
        "name": "CHANDLER ORIGINALS"
    },
    "CHNJ": {
        "id": "CHNJ",
        "name": "CHANGJIANG AUTOMOBILE HOLDINGS"
    },
    "CHNO": {
        "id": "CHNO",
        "name": "CHINOOK"
    },
    "CHNY": {
        "id": "CHNY",
        "name": "CHENEY RACING UNITED KINGDOM"
    },
    "CHOC": {
        "id": "CHOC",
        "name": "CHRYSLER"
    },
    "CHOI": {
        "id": "CHOI",
        "name": "CHOICE TRAILER MANUFACTURING"
    },
    "CHOP": {
        "id": "CHOP",
        "name": "CHOPPER NATION INC. MIAMI"
    },
    "CHPB": {
        "id": "CHPB",
        "name": "PALM BAY PREMIER MFG BY CHAMPION HOME BUILDERS DRYDEN MI"
    },
    "CHPL": {
        "id": "CHPL",
        "name": "CHAPERALLE HORSE TRAILER"
    },
    "CHPM": {
        "id": "CHPM",
        "name": "CHAMPION PNEUMATIC MACHINERY"
    },
    "CHPN": {
        "id": "CHPN",
        "name": "CHAMPION BUS"
    },
    "CHPP": {
        "id": "CHPP",
        "name": "CHOPPER CITY USA"
    },
    "CHPR": {
        "id": "CHPR",
        "name": "CHAPARRALSEE CHAPARRAL INDUSTRIES"
    },
    "CHPU": {
        "id": "CHPU",
        "name": "CHOPPERS UNLIMITED JACKSONVILLE"
    },
    "CHQT": {
        "id": "CHQT",
        "name": "C & H QUALITY TRAILER SALES"
    },
    "CHRC": {
        "id": "CHRC",
        "name": "RENTAL COTTAGE MFG BY CHAMPION HOME BUILDERS"
    },
    "CHRE": {
        "id": "CHRE",
        "name": "CHERY AUTOMOBILE"
    },
    "CHRI": {
        "id": "CHRI",
        "name": "CHRISTIANSON INDUSTRIES"
    },
    "CHRL": {
        "id": "CHRL",
        "name": "ROYAL MFG"
    },
    "CHRM": {
        "id": "CHRM",
        "name": "CHARMING MOTORCYCLE MANUFACTURER"
    },
    "CHRO": {
        "id": "CHRO",
        "name": "CHROMALLOY AMERICAN CORP."
    },
    "CHRS": {
        "id": "CHRS",
        "name": "CHRYSLER BOAT"
    },
    "CHRT": {
        "id": "CHRT",
        "name": "CHARIOT VANS"
    },
    "CHRV": {
        "id": "CHRV",
        "name": "CHATEAU RECREATIONAL VEHICLE DIV.CHRISTIANA"
    },
    "CHRY": {
        "id": "CHRY",
        "name": "CHRYSLER"
    },
    "CHSH": {
        "id": "CHSH",
        "name": "CHOP SHOP CUSTOMS INC."
    },
    "CHSL": {
        "id": "CHSL",
        "name": "SPORTSMAN LODGE"
    },
    "CHSP": {
        "id": "CHSP",
        "name": "SHORE PARK MFG BY CHAMPION HOME BUILDERS INC"
    },
    "CHST": {
        "id": "CHST",
        "name": "ESTATE MFG BY CHAMPION HOME BUILDERS PREV MFG BY ATHENS PARK HOMES"
    },
    "CHTA": {
        "id": "CHTA",
        "name": "TITAN ATHENS PARK EXTREME MFG BY CHAMPION HOME BUILDERS"
    },
    "CHTC": {
        "id": "CHTC",
        "name": "CHAPARRAL TRAILER"
    },
    "CHTE": {
        "id": "CHTE",
        "name": "TITAN EXTREME MFG BY CHAMPION HOME BUILDERS"
    },
    "CHTG": {
        "id": "CHTG",
        "name": "APH-COTTAGE ATHENS PARK HOMES-NOW MFG BY CHAMPION HOME BUILDERS INC VMA"
    },
    "CHTH": {
        "id": "CHTH",
        "name": "CHEETAH CHASSIS CORPORATION"
    },
    "CHTI": {
        "id": "CHTI",
        "name": "CHART"
    },
    "CHTK": {
        "id": "CHTK",
        "name": "TITAN ATHENS PARK SELECT MFG BY CHAMPION HOME BUILDERS"
    },
    "CHTM": {
        "id": "CHTM",
        "name": "CHATHAM ENTERPRISES GEORGIA"
    },
    "CHTN": {
        "id": "CHTN",
        "name": "CHRISTINI TECHNOLOGIES"
    },
    "CHTR": {
        "id": "CHTR",
        "name": "CHESTER BUILT TRAILERS"
    },
    "CHTU": {
        "id": "CHTU",
        "name": "CHATEAU & CHATEAU CITATION MFG BY THOR MOTOR COACH"
    },
    "CHUB": {
        "id": "CHUB",
        "name": "CHUBBS"
    },
    "CHUC": {
        "id": "CHUC",
        "name": "CHUCK BECK MOTORSPORTS BALDWIN PARK"
    },
    "CHUK": {
        "id": "CHUK",
        "name": "CHUKAR"
    },
    "CHUN": {
        "id": "CHUN",
        "name": "CHUNLAN MOTORCYCLE FACTORY"
    },
    "CHUY": {
        "id": "CHUY",
        "name": "CHUY'S C-5 TRAILERS ENNIS"
    },
    "CHVL": {
        "id": "CHVL",
        "name": "CHEVALLERO MOTOR HOME"
    },
    "CHWA": {
        "id": "CHWA",
        "name": "CHUCKS WAGONS RAYNHAM MA"
    },
    "CHWF": {
        "id": "CHWF",
        "name": "CHI"
    },
    "CHWK": {
        "id": "CHWK",
        "name": "HAWK & HAWK LITE BRAND MFG BY CHARIOT EAGLE"
    },
    "CHYI": {
        "id": "CHYI",
        "name": "CHIN YUAN CHI CASTING CORP.PAICHUNG"
    },
    "CHYL": {
        "id": "CHYL",
        "name": "APH-ROYAL ATHENS PARK HOMES-NOW MFG BY CHAMPION HOME BUILDERSINC. VMA"
    },
    "CHYN": {
        "id": "CHYN",
        "name": "CHEYENNE TRAILERS WHITNEY TX"
    },
    "CICC": {
        "id": "CICC",
        "name": "CIRCLE C MANUFACTURER LIVINGSTON"
    },
    "CICU": {
        "id": "CICU",
        "name": "CIRCUS CITY CUSTOM CYCLES"
    },
    "CIJE": {
        "id": "CIJE",
        "name": "CIRCLE J ENTERPRISES CORDELE"
    },
    "CIMA": {
        "id": "CIMA",
        "name": "CIMATTI"
    },
    "CIMC": {
        "id": "CIMC",
        "name": "CIMC HEAVY INDUSTRIES"
    },
    "CIML": {
        "id": "CIML",
        "name": "CIMLINE ACQUIRED EQUIP MFG. VMA"
    },
    "CIMR": {
        "id": "CIMR",
        "name": "CIMARRON MFG."
    },
    "CIRC": {
        "id": "CIRC",
        "name": "CIRCLE S STAR ROUTESULPHUR SPRINGS"
    },
    "CIRD": {
        "id": "CIRD",
        "name": "CIRCLE D TRAILER"
    },
    "CIRH": {
        "id": "CIRH",
        "name": "CIRCLE H HORSE TRAILER"
    },
    "CIRJ": {
        "id": "CIRJ",
        "name": "CIRCLE J TRAILERS"
    },
    "CIRK": {
        "id": "CIRK",
        "name": "CIRCLE K KENNIMOREOKLAHOMA CITY"
    },
    "CIRM": {
        "id": "CIRM",
        "name": "CIRCLE M TRAILERS"
    },
    "CIRR": {
        "id": "CIRR",
        "name": "CIRCLE R TRAILERS"
    },
    "CIRV": {
        "id": "CIRV",
        "name": "CIRCLE V HORSE TRAILER"
    },
    "CIRW": {
        "id": "CIRW",
        "name": "CIRCLE W TRAILERS"
    },
    "CISI": {
        "id": "CISI",
        "name": "CISITALIA CONSORZIO INDUSTRIALE SPORTIVA ITALIA"
    },
    "CITA": {
        "id": "CITA",
        "name": "CITATION TRAVEL TRAILER"
    },
    "CITC": {
        "id": "CITC",
        "name": "CITECARS"
    },
    "CITI": {
        "id": "CITI",
        "name": "CITICAR ELECTRIC CAR"
    },
    "CITR": {
        "id": "CITR",
        "name": "CITROEN"
    },
    "CITT": {
        "id": "CITT",
        "name": "CAMP-IN TRAVEL TRAILERS PENTWELL INDUSTRIES"
    },
    "CITY": {
        "id": "CITY",
        "name": "CITY DUMP TRAILER"
    },
    "CIWE": {
        "id": "CIWE",
        "name": "CITY WELDING & MFG."
    },
    "CJAY": {
        "id": "CJAY",
        "name": "CJAY TRAILERS INC MOOSE JAW"
    },
    "CJGC": {
        "id": "CJGC",
        "name": "CHINA JIANGMEN GROUP"
    },
    "CKPW": {
        "id": "CKPW",
        "name": "CK POWER ST LOUIS MO"
    },
    "CLAA": {
        "id": "CLAA",
        "name": "CLAPPER CAMPER"
    },
    "CLAB": {
        "id": "CLAB",
        "name": "B.M. CLARK"
    },
    "CLAC": {
        "id": "CLAC",
        "name": "CLASSIC ROADSTERS"
    },
    "CLAI": {
        "id": "CLAI",
        "name": "CLASSIC MOTOR CARRIAGES"
    },
    "CLAK": {
        "id": "CLAK",
        "name": "CLACKAMAS FABRICATION LLC - PORTLAND"
    },
    "CLAM": {
        "id": "CLAM",
        "name": "CLARK MFG."
    },
    "CLAN": {
        "id": "CLAN",
        "name": "CLAR-MONT MFG."
    },
    "CLAR": {
        "id": "CLAR",
        "name": "CONSTRUCTION MACHINERY DIV."
    },
    "CLAS": {
        "id": "CLAS",
        "name": "CLASSIC TRAILER MFG."
    },
    "CLAT": {
        "id": "CLAT",
        "name": "CLAYTON HOMES"
    },
    "CLAX": {
        "id": "CLAX",
        "name": "CLAXTON"
    },
    "CLAY": {
        "id": "CLAY",
        "name": "CLAY CAMPER"
    },
    "CLAZ": {
        "id": "CLAZ",
        "name": "CLAYTON CRAFT"
    },
    "CLBM": {
        "id": "CLBM",
        "name": "COLORADO BUILT MANUFACTURING"
    },
    "CLBR": {
        "id": "CLBR",
        "name": "CLEVELAND BROTHERS EQUIPMENT"
    },
    "CLBT": {
        "id": "CLBT",
        "name": "CALIBER TRAILER MFG"
    },
    "CLCC": {
        "id": "CLCC",
        "name": "CALMARC CABS OCALA"
    },
    "CLCI": {
        "id": "CLCI",
        "name": "COUNTY LINE CHOPPERS INC PHOENIX NY"
    },
    "CLCO": {
        "id": "CLCO",
        "name": "CALI"
    },
    "CLEA": {
        "id": "CLEA",
        "name": "CLEASBY"
    },
    "CLEG": {
        "id": "CLEG",
        "name": "CLEVELAND MFG."
    },
    "CLEM": {
        "id": "CLEM",
        "name": "CLEMENT-BRASWELL"
    },
    "CLEN": {
        "id": "CLEN",
        "name": "CLENET COACH WORKS"
    },
    "CLEV": {
        "id": "CLEV",
        "name": "CLEVELAND TRENCHER"
    },
    "CLFX": {
        "id": "CLFX",
        "name": "COLFAX TRAILER & REPAIR NORTH CAROLINA"
    },
    "CLGG": {
        "id": "CLGG",
        "name": "CLEGG MANUFACTURER HOMES VICTORIA"
    },
    "CLHM": {
        "id": "CLHM",
        "name": "CALHOME"
    },
    "CLIF": {
        "id": "CLIF",
        "name": "CLIFF OFFICE TRAILER"
    },
    "CLIH": {
        "id": "CLIH",
        "name": "CLIFF HALL"
    },
    "CLIN": {
        "id": "CLIN",
        "name": "CLINE"
    },
    "CLIP": {
        "id": "CLIP",
        "name": "CLIPPER MFG."
    },
    "CLIT": {
        "id": "CLIT",
        "name": "CLINTON ENGINE CORP."
    },
    "CLMB": {
        "id": "CLMB",
        "name": "COLUMBIA"
    },
    "CLMC": {
        "id": "CLMC",
        "name": "CLEM"
    },
    "CLMN": {
        "id": "CLMN",
        "name": "COLEMAN"
    },
    "CLMS": {
        "id": "CLMS",
        "name": "CLASSIC MOTORCYCLES AND SIDECARS INC.OR CMSI"
    },
    "CLMT": {
        "id": "CLMT",
        "name": "CLEMENT INDUSTRIES"
    },
    "CLNA": {
        "id": "CLNA",
        "name": "CAROLINA TRAILERS"
    },
    "CLND": {
        "id": "CLND",
        "name": "CROSS LANDER MIAMI"
    },
    "CLNS": {
        "id": "CLNS",
        "name": "COLLINS SPRINGFIELD"
    },
    "CLON": {
        "id": "CLON",
        "name": "COLONIAL FLATBED TRAILER"
    },
    "CLOU": {
        "id": "CLOU",
        "name": "CLOUGH EQUIPMENT"
    },
    "CLOV": {
        "id": "CLOV",
        "name": "CLOUD ELECTRIC VEHICLES"
    },
    "CLSC": {
        "id": "CLSC",
        "name": "CLASSIC MOTORWORKS LTD."
    },
    "CLSF": {
        "id": "CLSF",
        "name": "CLASSIC FIRE LLC OCALA"
    },
    "CLSN": {
        "id": "CLSN",
        "name": "CLASSEN MANUFACTURING"
    },
    "CLSS": {
        "id": "CLSS",
        "name": "CLASSIC MANUFACTURING"
    },
    "CLST": {
        "id": "CLST",
        "name": "CLASTER TRAILER"
    },
    "CLTI": {
        "id": "CLTI",
        "name": "CENTER-LINE TRAILERS"
    },
    "CLTL": {
        "id": "CLTL",
        "name": "CLARK TRAILER SERVICE INC. ANDALUSIA"
    },
    "CLTM": {
        "id": "CLTM",
        "name": "CLASSIC TRAILERS PLANT CITY"
    },
    "CLTN": {
        "id": "CLTN",
        "name": "C AND L TANKS TEXAS"
    },
    "CLTY": {
        "id": "CLTY",
        "name": "CLASSIC TROLLEY AKA CLASSIC INVESTORS MEDFORD"
    },
    "CLUA": {
        "id": "CLUA",
        "name": "CLUA ."
    },
    "CLUB": {
        "id": "CLUB",
        "name": "CLUB CAR"
    },
    "CLUM": {
        "id": "CLUM",
        "name": "COLUMBIA TRAILER"
    },
    "CLVA": {
        "id": "CLVA",
        "name": "CALVADE TRAILER"
    },
    "CLVR": {
        "id": "CLVR",
        "name": "CLEAVERS OF BISMARK BISMARCK ARKANSAS"
    },
    "CLWS": {
        "id": "CLWS",
        "name": "CLIFF'S WELDING SERVICE"
    },
    "CLWT": {
        "id": "CLWT",
        "name": "CLASSIC WEST TRAILERS MARSING ID"
    },
    "CLWX": {
        "id": "CLWX",
        "name": "CLARK-WILCOX"
    },
    "CLXN": {
        "id": "CLXN",
        "name": "CELLXION"
    },
    "CLYP": {
        "id": "CLYP",
        "name": "CLYPSO MOTOR HOME"
    },
    "CLYT": {
        "id": "CLYT",
        "name": "CLAY'S TRAILER SALES"
    },
    "CMAC": {
        "id": "CMAC",
        "name": "CONSTRUCTION MACHINERY"
    },
    "CMAT": {
        "id": "CMAT",
        "name": "CUSTOM MAX TRAILERS MILFORD IN"
    },
    "CMBI": {
        "id": "CMBI",
        "name": "CASE MASTER BODY"
    },
    "CMBL": {
        "id": "CMBL",
        "name": "CAMPBELL COACH"
    },
    "CMCC": {
        "id": "CMCC",
        "name": "CALIFORNIA MOTORCYCLE COMPANY GILROY"
    },
    "CMCI": {
        "id": "CMCI",
        "name": "CHARLESTON MARINE CONTAINERS"
    },
    "CMCO": {
        "id": "CMCO",
        "name": "BIRCHWOOD MFG BY CONCHEM"
    },
    "CMCW": {
        "id": "CMCW",
        "name": "CCM"
    },
    "CMDP": {
        "id": "CMDP",
        "name": "CMD POWERSYSTEMS INC HERMON ME"
    },
    "CMDR": {
        "id": "CMDR",
        "name": "COMMODORE TRAILERS"
    },
    "CMEC": {
        "id": "CMEC",
        "name": "CENTRAL MINE EQUIPMENT COMPANY EARTH CITY"
    },
    "CMEO": {
        "id": "CMEO",
        "name": "CAMEO TRAVEL TRAILER"
    },
    "CMFB": {
        "id": "CMFB",
        "name": "COM-FAB"
    },
    "CMFI": {
        "id": "CMFI",
        "name": "CMF"
    },
    "CMHM": {
        "id": "CMHM",
        "name": "CMH MANUFACTURING"
    },
    "CMIC": {
        "id": "CMIC",
        "name": "CMI CORP."
    },
    "CMIL": {
        "id": "CMIL",
        "name": "CMI LOAD KING ELK POINT SD TRAILERS"
    },
    "CMMH": {
        "id": "CMMH",
        "name": "CHARLAMORE MANUFACTURED HOME"
    },
    "CMMS": {
        "id": "CMMS",
        "name": "CUMMINS ENGINE"
    },
    "CMOR": {
        "id": "CMOR",
        "name": "C-MOR"
    },
    "CMOT": {
        "id": "CMOT",
        "name": "COLUMBIA MOTORS"
    },
    "CMPF": {
        "id": "CMPF",
        "name": "CAMPFIRE TRAVEL TRAILER"
    },
    "CMPG": {
        "id": "CMPG",
        "name": "CAMPAGNA MOTO SPORT"
    },
    "CMPM": {
        "id": "CMPM",
        "name": "CAMP MASTER LLC CLAYPOOL"
    },
    "CMPR": {
        "id": "CMPR",
        "name": "UNPUBLISHED CAMPER MFR"
    },
    "CMPS": {
        "id": "CMPS",
        "name": "CAMPSITE MFG."
    },
    "CMPT": {
        "id": "CMPT",
        "name": "COMPACT EQUIPMENT"
    },
    "CMSU": {
        "id": "CMSU",
        "name": "CAM SUPERLINE"
    },
    "CMTF": {
        "id": "CMTF",
        "name": "CAMTOURIST FAHRZEUGBAU GMBH GERMANY TRAILERS"
    },
    "CMTI": {
        "id": "CMTI",
        "name": "COBRA MOTORHOMES AND TRAILERS INC ONTARIO NY"
    },
    "CMTL": {
        "id": "CMTL",
        "name": "C & M TRAILERS LLC ENNIS"
    },
    "CMTM": {
        "id": "CMTM",
        "name": "CMT MANUFACTURING"
    },
    "CMTR": {
        "id": "CMTR",
        "name": "CM TRAILERS OR CONTRACT MANUFACTURERS"
    },
    "CMWC": {
        "id": "CMWC",
        "name": "CMWC TRAILER"
    },
    "CNAD": {
        "id": "CNAD",
        "name": "CANCADE COMPANY LIMITED BRANDON"
    },
    "CNAL": {
        "id": "CNAL",
        "name": "ALINER MFG BY COLUMBIA NORTHWEST INC"
    },
    "CNAP": {
        "id": "CNAP",
        "name": "ASCAPE MFG BY COLUMBIA NORTHWEST INC"
    },
    "CNCG": {
        "id": "CNCG",
        "name": "CONTINENTAL CARGO FR TEXAS GRUOP LP WA"
    },
    "CNCH": {
        "id": "CNCH",
        "name": "CONCHO CHOPPERS HAMILTON"
    },
    "CNCM": {
        "id": "CNCM",
        "name": "CANYCOM USA INC TUKWILA WA"
    },
    "CNDX": {
        "id": "CNDX",
        "name": "CONDUX INTERNATIONAL"
    },
    "CNGE": {
        "id": "CNGE",
        "name": "CANAGE CUSTOM TRAILERS INC"
    },
    "CNLS": {
        "id": "CNLS",
        "name": "CORNELIUS MANUFACTURING"
    },
    "CNLY": {
        "id": "CNLY",
        "name": "CONLEY FABRICATION"
    },
    "CNMH": {
        "id": "CNMH",
        "name": "CENTURY MANUFACTURED HOME"
    },
    "CNMI": {
        "id": "CNMI",
        "name": "CONTRACT MANUFACTURERS INC ALSO KNOWN AS :CM TRAILERS MADILL"
    },
    "CNNT": {
        "id": "CNNT",
        "name": "CONNTRAIL INC"
    },
    "CNQR": {
        "id": "CNQR",
        "name": "CONQUR INDUSTRIES INC. ALBERTA"
    },
    "CNRD": {
        "id": "CNRD",
        "name": "CONCORD PRODUCTS"
    },
    "CNRY": {
        "id": "CNRY",
        "name": "CONROY INDUSTRIES NEW BRAUNFELS"
    },
    "CNSS": {
        "id": "CNSS",
        "name": "SOMERSET MFG BY COLUMBIA NORTHWEST INC"
    },
    "CNST": {
        "id": "CNST",
        "name": "CONSTRUCTORE TRAILERS"
    },
    "CNTI": {
        "id": "CNTI",
        "name": "CENTURY INDUSTRIES"
    },
    "CNTK": {
        "id": "CNTK",
        "name": "COUNT'S KUSTOMS"
    },
    "CNTL": {
        "id": "CNTL",
        "name": "CONTINENTAL WHEEL & TRAILER"
    },
    "CNTM": {
        "id": "CNTM",
        "name": "CANADA TRAILER MANUFACTURING LIMITED ONTARIO"
    },
    "CNTR": {
        "id": "CNTR",
        "name": "CENTREVILLE TRAILER"
    },
    "CNTT": {
        "id": "CNTT",
        "name": "CENTURY TANK & TRAILER"
    },
    "CNTX": {
        "id": "CNTX",
        "name": "CENTEX"
    },
    "CNTY": {
        "id": "CNTY",
        "name": "COUNTRY RIDGE MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "CNVY": {
        "id": "CNVY",
        "name": "CONVEY-ALL INDUSTRIES"
    },
    "CNWA": {
        "id": "CNWA",
        "name": "CHANGCHAI NORTH-WEST AUTOMOBILE"
    },
    "CNWD": {
        "id": "CNWD",
        "name": "CONWED TRAILER"
    },
    "CNYN": {
        "id": "CNYN",
        "name": "CANYON TRAIL"
    },
    "COAC": {
        "id": "COAC",
        "name": "COACH CRAFT"
    },
    "COAH": {
        "id": "COAH",
        "name": "COACHMEN INDUSTRIES"
    },
    "COAI": {
        "id": "COAI",
        "name": "COACH HOUSE"
    },
    "COAL": {
        "id": "COAL",
        "name": "LEPRECHAUN MFG BY COACHMAN"
    },
    "COAM": {
        "id": "COAM",
        "name": "MIDDLEBURYMFD. BY COACHMEN HOMES DIV.COACHMAN INDUSTRIES"
    },
    "COAO": {
        "id": "COAO",
        "name": "ORION BRAND MFG BY COACHMAN IND"
    },
    "COAS": {
        "id": "COAS",
        "name": "COASTAL TRAILER CORP."
    },
    "COAV": {
        "id": "COAV",
        "name": "ARRIVA MODEL"
    },
    "COBM": {
        "id": "COBM",
        "name": "COBRA MOTORCYCLES"
    },
    "COBR": {
        "id": "COBR",
        "name": "AC COBRA"
    },
    "COBU": {
        "id": "COBU",
        "name": "COBURN INDUSTRIES"
    },
    "COCF": {
        "id": "COCF",
        "name": "COUNTRY COMFORT TRAILERS"
    },
    "COCH": {
        "id": "COCH",
        "name": "COCHRAN WESTERN CORP.SUBSIDIARY OF WESTERN GEAR CORP."
    },
    "COCL": {
        "id": "COCL",
        "name": "COUNTRY CLIPPER RIDING MOWERS JAZEE MODEL DIVISION OF SHIVVERS"
    },
    "COCO": {
        "id": "COCO",
        "name": "CONMACO"
    },
    "COCP": {
        "id": "COCP",
        "name": "CONCEPTOR INDUSTRIES"
    },
    "CODA": {
        "id": "CODA",
        "name": "CODA AUTOMOTIVE"
    },
    "CODY": {
        "id": "CODY",
        "name": "CODY MOTORCYCLE TRAILER"
    },
    "COEQ": {
        "id": "COEQ",
        "name": "UNPUBLISHED CONSTRUCTION EQUIPMENT MFR"
    },
    "COEU": {
        "id": "COEU",
        "name": "COLCRETE EURODRILL A KELLER COMPANY DERBYSHIRE UNITED KINGDOM"
    },
    "COFF": {
        "id": "COFF",
        "name": "COFFEE MFG LLC BAKERSFIELD CA"
    },
    "COGL": {
        "id": "COGL",
        "name": "GALLERIA BRAND MFG BY COACHMEN"
    },
    "COKR": {
        "id": "COKR",
        "name": "COKER ENTERPRISES TYLER TEXAS"
    },
    "COLB": {
        "id": "COLB",
        "name": "COLUMBIA MFG."
    },
    "COLC": {
        "id": "COLC",
        "name": "COLE MOTOR CAR COMPANY INDIANAPOLIS ANTIQUE MOTOR VEHICLES"
    },
    "COLE": {
        "id": "COLE",
        "name": "COLEMAN CAMPING TRAILERS"
    },
    "COLF": {
        "id": "COLF",
        "name": "COLONIAL MOBILE HOMES MFG."
    },
    "COLG": {
        "id": "COLG",
        "name": "COLONY MFG. & SALES CORP."
    },
    "COLL": {
        "id": "COLL",
        "name": "COLLINS BUS ACQUIRED MID BUS CORPORATION IN 2OO7"
    },
    "COLM": {
        "id": "COLM",
        "name": "COLUMBIA TRACTOR MOWER"
    },
    "COLN": {
        "id": "COLN",
        "name": "COLUMBIA NORTHWEST INC MAKER OF ALINER SPORTLINER SCOUT ALITE 3OO 4OO CABIN"
    },
    "COLO": {
        "id": "COLO",
        "name": "COLORADO MOBILE HOMES"
    },
    "COLP": {
        "id": "COLP",
        "name": "COLT CAMPER"
    },
    "COLS": {
        "id": "COLS",
        "name": "COLUMBIA MOBILE HOMES"
    },
    "COLT": {
        "id": "COLT",
        "name": "COLT"
    },
    "COLU": {
        "id": "COLU",
        "name": "COLUMBINE CAMP & COACH"
    },
    "COLW": {
        "id": "COLW",
        "name": "COLEMAN'S CUSTOM WELDING"
    },
    "COMA": {
        "id": "COMA",
        "name": "COMANCHE MOBILE HOMES"
    },
    "COMB": {
        "id": "COMB",
        "name": "COMBS TRAILER MANUFACTURING OR COMBS MANUFACTURING CONWAY"
    },
    "COMC": {
        "id": "COMC",
        "name": "COMANCHE TRAILER CORP."
    },
    "COMD": {
        "id": "COMD",
        "name": "COMMANDER MOTOR HOME"
    },
    "COME": {
        "id": "COME",
        "name": "COMET CORP.SPOKANE"
    },
    "COMF": {
        "id": "COMF",
        "name": "COMFORTS OF HOME SERVICES"
    },
    "COMH": {
        "id": "COMH",
        "name": "COLT MANUFACTURED HOME"
    },
    "COMI": {
        "id": "COMI",
        "name": "COMPETITIVE BOAT TRAILER"
    },
    "COML": {
        "id": "COML",
        "name": "COMMERCIAL STRUCTURES ANPPANEE"
    },
    "COMM": {
        "id": "COMM",
        "name": "COM-CAMP"
    },
    "COMN": {
        "id": "COMN",
        "name": "COMET CONSTRUCTION"
    },
    "COMO": {
        "id": "COMO",
        "name": "COMET MOBILE HOMES"
    },
    "COMP": {
        "id": "COMP",
        "name": "COMMODORE CORP.SYRACUSE"
    },
    "COMR": {
        "id": "COMR",
        "name": "COMMERCIAL VEHICLES"
    },
    "COMT": {
        "id": "COMT",
        "name": "COMET RIDING MOWER"
    },
    "COMU": {
        "id": "COMU",
        "name": "COMMUTER INDUSTRIES"
    },
    "COMV": {
        "id": "COMV",
        "name": "COMMUTER VEHICLES"
    },
    "COMY": {
        "id": "COMY",
        "name": "COMMUNE MFD BY COMMUNITY HOMES"
    },
    "CONA": {
        "id": "CONA",
        "name": "CONSTRUCTORS AND ASSOC LANG LAKE"
    },
    "CONC": {
        "id": "CONC",
        "name": "CONCORD MOBILE HOMES"
    },
    "COND": {
        "id": "COND",
        "name": "CONDOR"
    },
    "CONE": {
        "id": "CONE",
        "name": "CONCORD TRAVELERS"
    },
    "CONF": {
        "id": "CONF",
        "name": "CONFEDERATE MOTOR WORKS"
    },
    "CONG": {
        "id": "CONG",
        "name": "CONESTEGA MFG."
    },
    "CONH": {
        "id": "CONH",
        "name": "CONDOR MOTOR HOME"
    },
    "CONI": {
        "id": "CONI",
        "name": "CONESTOGA MOBILE HOMES"
    },
    "CONL": {
        "id": "CONL",
        "name": "CONNELL INDUSTRIES"
    },
    "CONM": {
        "id": "CONM",
        "name": "CONTEMPORI MOBILE HOMES"
    },
    "CONN": {
        "id": "CONN",
        "name": "CONNAUGHT"
    },
    "CONO": {
        "id": "CONO",
        "name": "CONDOR COACH"
    },
    "CONP": {
        "id": "CONP",
        "name": "CONSOLIDATED MOBILE INDUSTRIES"
    },
    "CONQ": {
        "id": "CONQ",
        "name": "CONQUEST TRIKE OF TAMPA BAY"
    },
    "CONR": {
        "id": "CONR",
        "name": "CONNER INDUSTRIES"
    },
    "CONS": {
        "id": "CONS",
        "name": "CONTESSA"
    },
    "CONT": {
        "id": "CONT",
        "name": "CONTINENTAL"
    },
    "CONU": {
        "id": "CONU",
        "name": "CONSULIER"
    },
    "CONV": {
        "id": "CONV",
        "name": "CONTINENTAL OF COLORADO"
    },
    "CONW": {
        "id": "CONW",
        "name": "CONTINENTAL HOMES"
    },
    "CONX": {
        "id": "CONX",
        "name": "CONTINENTAL MFG."
    },
    "CONY": {
        "id": "CONY",
        "name": "CONY TRUCK JAPAN"
    },
    "CONZ": {
        "id": "CONZ",
        "name": "CONVERTO COMBRIDGE CITY"
    },
    "COOC": {
        "id": "COOC",
        "name": "COOL CITY"
    },
    "COOE": {
        "id": "COOE",
        "name": "COOSE TRAILER MFG."
    },
    "COOK": {
        "id": "COOK",
        "name": "COOK SEMI TRAILER"
    },
    "COOL": {
        "id": "COOL",
        "name": "COOLSTER MOTORSCOOTERS BY MAXTRADE"
    },
    "COON": {
        "id": "COON",
        "name": "COON CUSTOM COACH MFG."
    },
    "COOP": {
        "id": "COOP",
        "name": "COOPER"
    },
    "COOS": {
        "id": "COOS",
        "name": "COOS-BILT TRAILERS"
    },
    "COPA": {
        "id": "COPA",
        "name": "COOPER ALPINE MOTOR HOME"
    },
    "COPC": {
        "id": "COPC",
        "name": "COP"
    },
    "COPP": {
        "id": "COPP",
        "name": "COPPERSTATE COACH"
    },
    "COPY": {
        "id": "COPY",
        "name": "COPY CATT TRAILERS HUGO"
    },
    "CORA": {
        "id": "CORA",
        "name": "CORAS WELDING SHOP"
    },
    "CORB": {
        "id": "CORB",
        "name": "CORBITT"
    },
    "CORD": {
        "id": "CORD",
        "name": "CORD"
    },
    "CORE": {
        "id": "CORE",
        "name": "CORE MOTOR HOME & CORE TRAILERS MFG BY KIBBI"
    },
    "CORG": {
        "id": "CORG",
        "name": "CORDER MFG."
    },
    "CORN": {
        "id": "CORN",
        "name": "CORN BELT MANUFACTURING"
    },
    "CORO": {
        "id": "CORO",
        "name": "CORONA COACH"
    },
    "CORR": {
        "id": "CORR",
        "name": "CORRECT CRAFT"
    },
    "CORS": {
        "id": "CORS",
        "name": "CORSAIR DIV.DIV. DIVCO-WAYNE INDUSTRIES"
    },
    "CORT": {
        "id": "CORT",
        "name": "CORTEZ HOUSE TRAILER"
    },
    "CORV": {
        "id": "CORV",
        "name": "CORVETTE TRAVEL TRAILER"
    },
    "CORY": {
        "id": "CORY",
        "name": "COREY ENTERPRISES"
    },
    "COSA": {
        "id": "COSA",
        "name": "SPIRIT OF AMERICA MFG BY COACHMEN"
    },
    "COSM": {
        "id": "COSM",
        "name": "COSMO"
    },
    "COSU": {
        "id": "COSU",
        "name": "CONCRETE SURFACING MACHINERY DIV."
    },
    "COT": {
        "id": "COT",
        "name": "CORNER UTILITY TRAILER"
    },
    "COTC": {
        "id": "COTC",
        "name": "CARRY ON TRAILER CORP LAVONIA GA"
    },
    "COTL": {
        "id": "COTL",
        "name": "COLORADO BACKCOUNTRY TRAILERS LLC BROOMFIELD CO"
    },
    "COTN": {
        "id": "COTN",
        "name": "COTTON"
    },
    "COTT": {
        "id": "COTT",
        "name": "COTTAGE-ETTE MFG."
    },
    "COTZ": {
        "id": "COTZ",
        "name": "CORTEZ MOTOR HOME"
    },
    "COUA": {
        "id": "COUA",
        "name": "COUNTRY-AIRE TRAVEL TRAILER"
    },
    "COUB": {
        "id": "COUB",
        "name": "COUNTRY BLACKSMITH LLC"
    },
    "COUI": {
        "id": "COUI",
        "name": "COUNTRYSIDE INDUSTRIES"
    },
    "COUM": {
        "id": "COUM",
        "name": "COUNTRYSIDE MANUFACTURING"
    },
    "COUN": {
        "id": "COUN",
        "name": "COUNTRY SQUIRE TRAVEL TRAILER"
    },
    "COUR": {
        "id": "COUR",
        "name": "COURTHOUSE CAMPER TRAILER"
    },
    "COUS": {
        "id": "COUS",
        "name": "COUS TRAILERS"
    },
    "COVC": {
        "id": "COVC",
        "name": "COVINGTONS CYCLE CITY"
    },
    "COVE": {
        "id": "COVE",
        "name": "COVERED WAGON TRAILER"
    },
    "COVT": {
        "id": "COVT",
        "name": "COVENANT OR COVENANT CARGO DOUGLAS GEORGIA"
    },
    "COWB": {
        "id": "COWB",
        "name": "COWBOY COACHES INC ROWLETT TX"
    },
    "COWM": {
        "id": "COWM",
        "name": "CONTINENTAL WORLD MARINE MEXI"
    },
    "COWY": {
        "id": "COWY",
        "name": "CONWAY"
    },
    "COXS": {
        "id": "COXS",
        "name": "COX TRAILERS"
    },
    "COYN": {
        "id": "COYN",
        "name": "COYN"
    },
    "COYO": {
        "id": "COYO",
        "name": "COYOTE MFG. CORP.CORONA"
    },
    "COZA": {
        "id": "COZA",
        "name": "COZAD TRAILER SALES STOCKTON"
    },
    "COZY": {
        "id": "COZY",
        "name": "COZY TRAILER"
    },
    "CPAR": {
        "id": "CPAR",
        "name": "COLUMBIA"
    },
    "CPAT": {
        "id": "CPAT",
        "name": "CPAT DISTRIBUTION INC CITY OF INDUSTRY"
    },
    "CPIU": {
        "id": "CPIU",
        "name": "CPI USA SEE CPI TAIWAN"
    },
    "CPNK": {
        "id": "CPNK",
        "name": "COMPTANK"
    },
    "CPPR": {
        "id": "CPPR",
        "name": "C"
    },
    "CPRF": {
        "id": "CPRF",
        "name": "COPPER RIVER FABRICATORS INC CONCORD NC"
    },
    "CPSP": {
        "id": "CPSP",
        "name": "CLAYPOOLE SPECIALTIES ALOHA OR"
    },
    "CPST": {
        "id": "CPST",
        "name": "CPS TRAILER"
    },
    "CPTC": {
        "id": "CPTC",
        "name": "CHARLIE PERKINS TRAILER COMPANY"
    },
    "CPTN": {
        "id": "CPTN",
        "name": "COMPETITION TRAILERS"
    },
    "CPTV": {
        "id": "CPTV",
        "name": "COMPETITIVE TRAILERS"
    },
    "CQAB": {
        "id": "CQAB",
        "name": "CHONGQING ASTRONAUTIC BASHAN BANAN DIST CHONGQUING CITY CHINA"
    },
    "CQST": {
        "id": "CQST",
        "name": "CONQUEST MFG BY GULF STREAM COACH"
    },
    "CQUR": {
        "id": "CQUR",
        "name": "CONQUEROR MANUFACTURERS PTY"
    },
    "CRAB": {
        "id": "CRAB",
        "name": "ABBOTT BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRAF": {
        "id": "CRAF",
        "name": "CAMP CRAFT"
    },
    "CRAL": {
        "id": "CRAL",
        "name": "ALTITUDE MFG BY CROSS ROADS RV"
    },
    "CRAM": {
        "id": "CRAM",
        "name": "CRAFTMADE HOMES"
    },
    "CRAN": {
        "id": "CRAN",
        "name": "CRANE & HOIST OPERATIONS SUBSIDIARY OF DRESSER INDUSTRIES INC"
    },
    "CRAW": {
        "id": "CRAW",
        "name": "CRAWLER TRAILER"
    },
    "CRBN": {
        "id": "CRBN",
        "name": "CIRBIN MOTORS CORPORATION"
    },
    "CRBO": {
        "id": "CRBO",
        "name": "BOSS BRAND MFG BY CRUISER RV"
    },
    "CRBT": {
        "id": "CRBT",
        "name": "CARIBBEAN TRAILERS CORP MIAMI FLORIDA"
    },
    "CRBW": {
        "id": "CRBW",
        "name": "BLACKWOOD MFG BY CROSSROADS"
    },
    "CRCC": {
        "id": "CRCC",
        "name": "CRANE CARRIER COMPANY TULSA OK"
    },
    "CRCF": {
        "id": "CRCF",
        "name": "CREW CHIEF MOTORHOME"
    },
    "CRCG": {
        "id": "CRCG",
        "name": "CARRIAGE MODEL MFG BY CROSSROADS RECREATIONAL VEHICLES _"
    },
    "CRCM": {
        "id": "CRCM",
        "name": "CAMEO BRAND MFG BY CROSSROADS RECREATIONAL VEHICLES"
    },
    "CRCN": {
        "id": "CRCN",
        "name": "CROSS COUNTRY MANUFACTURING GREENE"
    },
    "CRCO": {
        "id": "CRCO",
        "name": "CONNELL INDUSTRIES"
    },
    "CRCT": {
        "id": "CRCT",
        "name": "CORTES BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRCY": {
        "id": "CRCY",
        "name": "CYPRESS BRAND MFG BY CROSSROADS RECREATIONAL VEHICLES CZ CZ CZECHOSLAVAKIA-ALSO SEE MAKEJAWA"
    },
    "CREE": {
        "id": "CREE",
        "name": "CREE COACHES"
    },
    "CREL": {
        "id": "CREL",
        "name": "CORBIN ELECTRIC MOTORS"
    },
    "CREM": {
        "id": "CREM",
        "name": "EMBRACE BRAND MFG BY CRUISER RV LLC"
    },
    "CREN": {
        "id": "CREN",
        "name": "ENTERRA MFG BY CRUISER RV"
    },
    "CREO": {
        "id": "CREO",
        "name": "CREST MOBILE HOMES"
    },
    "CREQ": {
        "id": "CREQ",
        "name": "CRESCENT TRAILERS"
    },
    "CRET": {
        "id": "CRET",
        "name": "CREIGHTONS TRAILERMARYLAND"
    },
    "CREV": {
        "id": "CREV",
        "name": "ELEVATION  MFG BY CROSSROADS"
    },
    "CREX": {
        "id": "CREX",
        "name": "CABLE CRANE AND EXCAVATOR DIV.FMC CORP."
    },
    "CRFC": {
        "id": "CRFC",
        "name": "CROSS FORCE MFG BY CROSSROADS CORP"
    },
    "CRFE": {
        "id": "CRFE",
        "name": "CRAFTON EQUIPMENT INC MISSOURI TRAILERS"
    },
    "CRFF": {
        "id": "CRFF",
        "name": "FUN FINDER X"
    },
    "CRFI": {
        "id": "CRFI",
        "name": "CRAFTSMEN INDUSTRIES"
    },
    "CRFO": {
        "id": "CRFO",
        "name": "FONTANA BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRFR": {
        "id": "CRFR",
        "name": "CROSS FIRE MFG BY CROSSROADS CORP"
    },
    "CRFT": {
        "id": "CRFT",
        "name": "CRAFTSMAN"
    },
    "CRGI": {
        "id": "CRGI",
        "name": "CARRIAGE INDUSTRIES"
    },
    "CRGK": {
        "id": "CRGK",
        "name": "CARGO KING"
    },
    "CRGO": {
        "id": "CRGO",
        "name": "CARGO CRAFT"
    },
    "CRGP": {
        "id": "CRGP",
        "name": "CARGO PRO TRAILERS"
    },
    "CRGR": {
        "id": "CRGR",
        "name": "CHARGER"
    },
    "CRGS": {
        "id": "CRGS",
        "name": "CARGO SOUTH INC OCILLA"
    },
    "CRGT": {
        "id": "CRGT",
        "name": "CARGOTEC SOLUTIONS"
    },
    "CRGW": {
        "id": "CRGW",
        "name": "CARRIAGE WORKS"
    },
    "CRHM": {
        "id": "CRHM",
        "name": "HAMPTON BRAND MFG BY CROSSROADS RECREATIONAL VEHICLES"
    },
    "CRI": {
        "id": "CRI",
        "name": "CHRIS CRAFT CORP."
    },
    "CRIC": {
        "id": "CRIC",
        "name": "CRICKET CORP.ELKHART"
    },
    "CRII": {
        "id": "CRII",
        "name": "CRONKHITE INDUSTRIES"
    },
    "CRIM": {
        "id": "CRIM",
        "name": "CRIMSON HOMESDIV. WINSTON INDUSTRIES"
    },
    "CRIT": {
        "id": "CRIT",
        "name": "CRITERION HOMES MFG. INC.LARGO"
    },
    "CRKA": {
        "id": "CRKA",
        "name": "CICIRA RV LLC"
    },
    "CRKC": {
        "id": "CRKC",
        "name": "CREEKSIDE CABINS MFG BY PALM HARBOR HOMES"
    },
    "CRKG": {
        "id": "CRKG",
        "name": "CARRIER KING TRAILER"
    },
    "CRKI": {
        "id": "CRKI",
        "name": "KINGSTON MFG BY CROSSROADS"
    },
    "CRKR": {
        "id": "CRKR",
        "name": "CROCKER MOTORCYCLE COMPANY"
    },
    "CRKS": {
        "id": "CRKS",
        "name": "CREEKSIDE PRODUCTS"
    },
    "CRKW": {
        "id": "CRKW",
        "name": "CREEK HILL WELDING PENNSYLVANIA"
    },
    "CRLT": {
        "id": "CRLT",
        "name": "CRL TRAILERS TEXAS"
    },
    "CRLW": {
        "id": "CRLW",
        "name": "CROSS-L WELDING LAGRANDE"
    },
    "CRLY": {
        "id": "CRLY",
        "name": "CROWLEY MFG.MATHISTON"
    },
    "CRMH": {
        "id": "CRMH",
        "name": "CRESTRIDGE MANUFACTURED HOME"
    },
    "CRMO": {
        "id": "CRMO",
        "name": "MORRISON BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRMP": {
        "id": "CRMP",
        "name": "MPG BRAND  MFG BY CRUISER RV"
    },
    "CRMT": {
        "id": "CRMT",
        "name": "CAR MATE TRAILERS LEEPER"
    },
    "CRNA": {
        "id": "CRNA",
        "name": "CARNAI TRAILERS INC GREEN ACRES"
    },
    "CRNM": {
        "id": "CRNM",
        "name": "CORNERSTONE MANUFACTURING"
    },
    "CRNP": {
        "id": "CRNP",
        "name": "CORN PRO TRAILERS"
    },
    "CROE": {
        "id": "CROE",
        "name": "CROSS TRUCK EQUIPMENT"
    },
    "CROF": {
        "id": "CROF",
        "name": "CROFTON BUG"
    },
    "CROH": {
        "id": "CROH",
        "name": "CROSS HILL MOBILE HOMES"
    },
    "CROI": {
        "id": "CROI",
        "name": "CROSSLAND INDUSTRIES"
    },
    "CROM": {
        "id": "CROM",
        "name": "CROSSMAN TRAILER"
    },
    "CRON": {
        "id": "CRON",
        "name": "CROWN"
    },
    "CROR": {
        "id": "CROR",
        "name": "CROSSROADS RECREATIONAL VEHICLES"
    },
    "CROS": {
        "id": "CROS",
        "name": "CROSLEY"
    },
    "CROT": {
        "id": "CROT",
        "name": "CROFT-INS"
    },
    "CROW": {
        "id": "CROW",
        "name": "CROWN LINE"
    },
    "CRPM": {
        "id": "CRPM",
        "name": "CROP MASTER TRAILERS INC."
    },
    "CRPR": {
        "id": "CRPR",
        "name": "PARADISE POINT MFG BY CROSSROADS"
    },
    "CRPT": {
        "id": "CRPT",
        "name": "CARPENTER INDUSTRIES INC."
    },
    "CRRA": {
        "id": "CRRA",
        "name": "RADIANCE LINE"
    },
    "CRRE": {
        "id": "CRRE",
        "name": "CR RESORT MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "CRRN": {
        "id": "CRRN",
        "name": "CARRON TRUCK REPAIR"
    },
    "CRRS": {
        "id": "CRRS",
        "name": "CARROSERIE INDUSTRIELLE DU MAR"
    },
    "CRRU": {
        "id": "CRRU",
        "name": "RUSHMORE FW MFG BY CROSSROADS"
    },
    "CRRV": {
        "id": "CRRV",
        "name": "CURISER R.V. LLC"
    },
    "CRRZ": {
        "id": "CRRZ",
        "name": "REZERVE MFG BY CROSS ROADS RV"
    },
    "CRSC": {
        "id": "CRSC",
        "name": "SHADOW CRUISER MFG BY CRUISER RV"
    },
    "CRSG": {
        "id": "CRSG",
        "name": "SLINGSHOT MFG BY CROSSROADS"
    },
    "CRSH": {
        "id": "CRSH",
        "name": "SAVANNAH BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRSI": {
        "id": "CRSI",
        "name": "SIMPLICITY MFG BY CROSSROADS"
    },
    "CRSL": {
        "id": "CRSL",
        "name": "CROSLEY TRAILERS"
    },
    "CRSN": {
        "id": "CRSN",
        "name": "CARSON'S MANUFACTURING INC.SUPPLY"
    },
    "CRSO": {
        "id": "CRSO",
        "name": "CAROUSEL USA SAN ANTONIO ROLLER WORKS"
    },
    "CRSQ": {
        "id": "CRSQ",
        "name": "SEQUOIA BRAND MFG BY CROSSROADS RECREATIONAL VEHICLES VMA"
    },
    "CRSR": {
        "id": "CRSR",
        "name": "CRUISER BRAND MFG BY CROSSROADS RECREATIONAL VEHICLES"
    },
    "CRSS": {
        "id": "CRSS",
        "name": "CROSSINGS"
    },
    "CRST": {
        "id": "CRST",
        "name": "CREST TRAILER"
    },
    "CRSU": {
        "id": "CRSU",
        "name": "SUNSET TRAIL"
    },
    "CRSV": {
        "id": "CRSV",
        "name": "SEVILLE MFG BY CROSSROADS"
    },
    "CRSY": {
        "id": "CRSY",
        "name": "STRYKER MFG BY - CRUISER RV"
    },
    "CRTC": {
        "id": "CRTC",
        "name": "CARTER CAR"
    },
    "CRTH": {
        "id": "CRTH",
        "name": "CORNERSTONE TINY HOMES LONGWOOD"
    },
    "CRTI": {
        "id": "CRTI",
        "name": "CARSON TRAILER"
    },
    "CRTL": {
        "id": "CRTL",
        "name": "CR TRAILERS WHITE"
    },
    "CRTN": {
        "id": "CRTN",
        "name": "CRITERION TRAILERS LLC CALDWELL ID"
    },
    "CRTQ": {
        "id": "CRTQ",
        "name": "TRANQUILITY MFG BY CROSSROADS"
    },
    "CRTR": {
        "id": "CRTR",
        "name": "CROSS TERRAIN MFG BY CROSSROADS"
    },
    "CRTX": {
        "id": "CRTX",
        "name": "CAR-TEX TRAILER"
    },
    "CRUI": {
        "id": "CRUI",
        "name": "CRUISAIRE MOTOR CORP."
    },
    "CRUM": {
        "id": "CRUM",
        "name": "CRUZMASTER CAMPER"
    },
    "CRUS": {
        "id": "CRUS",
        "name": "CRUISE CAR INC LOW SPEED VEHICLES"
    },
    "CRUX": {
        "id": "CRUX",
        "name": "CRUX EXPEDITION TRAILERS DENVER CO"
    },
    "CRVF": {
        "id": "CRVF",
        "name": "VIEW FINDER MFG BY CRUISER RV"
    },
    "CRVL": {
        "id": "CRVL",
        "name": "VOLANTE BRAND MFG BY CROSSROADS RECREATIONL VEHICLES"
    },
    "CRVM": {
        "id": "CRVM",
        "name": "COUNTRY R. V. MFG."
    },
    "CRVN": {
        "id": "CRVN",
        "name": "VINCENTE BRAND MFG BY CORNERSTONE TINE HOMES"
    },
    "CRVR": {
        "id": "CRVR",
        "name": "CROW RIVER FABRICATING MINNESOTA"
    },
    "CRWD": {
        "id": "CRWD",
        "name": "REDWOOD BRAND MFG BY CROSSROADS RECREATIONL VEHICLES"
    },
    "CRWE": {
        "id": "CRWE",
        "name": "CRAFT WELD TRAILER"
    },
    "CRWL": {
        "id": "CRWL",
        "name": "WALDEN BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRWN": {
        "id": "CRWN",
        "name": "CROWN COACH BUSES & TRUCKS"
    },
    "CRWS": {
        "id": "CRWS",
        "name": "WESTBURY BRAND MFG BY CORNERSTONE TINY HOMES"
    },
    "CRWT": {
        "id": "CRWT",
        "name": "WESTCHESTER MFG BY CROSSROADS"
    },
    "CRYC": {
        "id": "CRYC",
        "name": "CRYSTAL CREEK RV LLC WARSAW"
    },
    "CRYO": {
        "id": "CRYO",
        "name": "CRYOGENIC VESSEL ALTERNATIVE MOUNT BELVIEU"
    },
    "CRYS": {
        "id": "CRYS",
        "name": "CRYSTAL WELDING INC."
    },
    "CRZG": {
        "id": "CRZG",
        "name": "ZINGER BRAND MFG BY CROSSROADS RECREATIONAL VEHICLES"
    },
    "CRZM": {
        "id": "CRZM",
        "name": "CRAZY MOUNTAIN XTREME SNOWMACHINE"
    },
    "CRZN": {
        "id": "CRZN",
        "name": "CRUZ-IN"
    },
    "CRZO": {
        "id": "CRZO",
        "name": "Z-1 MFG BY CROSSROADS"
    },
    "CRZY": {
        "id": "CRZY",
        "name": "CRAZY HORSE MOTORCYCLES"
    },
    "CSCO": {
        "id": "CSCO",
        "name": "CUS"
    },
    "CSCT": {
        "id": "CSCT",
        "name": "CALIFORNIA SCOOTER COMPANY"
    },
    "CSEQ": {
        "id": "CSEQ",
        "name": "CSL EQUIPMENT COMPANY"
    },
    "CSHK": {
        "id": "CSHK",
        "name": "COOKSHACK"
    },
    "CSHM": {
        "id": "CSHM",
        "name": "CUSHMAN"
    },
    "CSLE": {
        "id": "CSLE",
        "name": "CASTLE MANUFACTURING"
    },
    "CSMC": {
        "id": "CSMC",
        "name": "CIRCLE SUPREME MFG KNOXVILLE"
    },
    "CSMM": {
        "id": "CSMM",
        "name": "CSM MANUFACTURING"
    },
    "CSNT": {
        "id": "CSNT",
        "name": "CRESCENT MANUFACTURING"
    },
    "CSPR": {
        "id": "CSPR",
        "name": "CLEARSPRING CONVERSIONS"
    },
    "CSST": {
        "id": "CSST",
        "name": "CROSS TRAILERS"
    },
    "CSTG": {
        "id": "CSTG",
        "name": "COASTAL COTTAGE & COASTAL LODGE' MFG BY PALM HARBOR HOMES"
    },
    "CSTL": {
        "id": "CSTL",
        "name": "COASTAL METAL FAB"
    },
    "CSTM": {
        "id": "CSTM",
        "name": "COASTAL MANUFACTURING"
    },
    "CSTT": {
        "id": "CSTT",
        "name": "CUSTOM TANK TECHNOLOGY GRAFTON"
    },
    "CSTW": {
        "id": "CSTW",
        "name": "C & S TRAILER WORLD FORT WORTH"
    },
    "CTBV": {
        "id": "CTBV",
        "name": "COURAGE TRIKE BV NETHERLANDS TRIKES"
    },
    "CTBW": {
        "id": "CTBW",
        "name": "CUSTOM TRUCK AND BODY WORKS INC WOODBURY GA"
    },
    "CTCC": {
        "id": "CTCC",
        "name": "COAST 2 COAST CHOPPERS OR COAST TO COAST CHOPPERS FLORIDA"
    },
    "CTCH": {
        "id": "CTCH",
        "name": "CONTECH ENTERPRISES"
    },
    "CTCW": {
        "id": "CTCW",
        "name": "CT COACHWORKS"
    },
    "CTDT": {
        "id": "CTDT",
        "name": "COLORADO TEARDROP TRAILERS LLC"
    },
    "CTEC": {
        "id": "CTEC",
        "name": "COR-TECH MFG."
    },
    "CTFB": {
        "id": "CTFB",
        "name": "CT FABRICATION LLC BELLEVILLE KS"
    },
    "CTLM": {
        "id": "CTLM",
        "name": "CUSTOM TRAILER MANUFACTURING"
    },
    "CTMH": {
        "id": "CTMH",
        "name": "CORTLAND MANUFACTURED HOME"
    },
    "CTMS": {
        "id": "CTMS",
        "name": "CROSS TRAILER MANUFACTURING AND SALES LLC GATESVILLE"
    },
    "CTNR": {
        "id": "CTNR",
        "name": "COTNER TRAILERS"
    },
    "CTRA": {
        "id": "CTRA",
        "name": "C TRAIL TRAILER DUNNVILLE KY"
    },
    "CTRC": {
        "id": "CTRC",
        "name": "CENTRAL TRAILER CORP."
    },
    "CTRI": {
        "id": "CTRI",
        "name": "CONTRAIL INTERNATIONAL CHICAGO"
    },
    "CTRL": {
        "id": "CTRL",
        "name": "COTTRELL INC"
    },
    "CTRN": {
        "id": "CTRN",
        "name": "CENTURION BOATS INC"
    },
    "CTSI": {
        "id": "CTSI",
        "name": "CONSTRUCTION TRAILER SPECIALISTS"
    },
    "CTSV": {
        "id": "CTSV",
        "name": "CENTECH SPECIALITY VEHICLES"
    },
    "CTTM": {
        "id": "CTTM",
        "name": "CLEVELAND T-TRIKE MANUFACTURING"
    },
    "CTTT": {
        "id": "CTTT",
        "name": "CENTERLINE TANK & TRAILER MANUFACTURING SAUK CENTRE"
    },
    "CTTU": {
        "id": "CTTU",
        "name": "CT & T USA"
    },
    "CTVM": {
        "id": "CTVM",
        "name": "CREATIVE MOBILE SYSTEMS"
    },
    "CTWI": {
        "id": "CTWI",
        "name": "CALIFORNIA TRAILER WORKS"
    },
    "CUAP": {
        "id": "CUAP",
        "name": "CUSTOM AMUSEMENT PRODUCTS"
    },
    "CUAS": {
        "id": "CUAS",
        "name": "CASSMFD. BY CUSTOM ASSEMBLY"
    },
    "CUAT": {
        "id": "CUAT",
        "name": "CUSTOM ALUMINUM TRAILERS LLC WINCHESTER OR"
    },
    "CUBC": {
        "id": "CUBC",
        "name": "CUB CADET CORP.SUBSIDIARY OF MTD PRODUCTS"
    },
    "CUBS": {
        "id": "CUBS",
        "name": "CUBSTER"
    },
    "CUBT": {
        "id": "CUBT",
        "name": "CUB CAMPER TRAILERS"
    },
    "CUCH": {
        "id": "CUCH",
        "name": "CUSTOM CHASSIS PRODUCTS WAKARUSA"
    },
    "CUCT": {
        "id": "CUCT",
        "name": "CUSTOM CONCESSIONS TRAILERS"
    },
    "CUCY": {
        "id": "CUCY",
        "name": "CUSTOM CYCLE CARRIER POMPANO BEACH"
    },
    "CUES": {
        "id": "CUES",
        "name": "CUES - TRAILERS"
    },
    "CUKE": {
        "id": "CUKE",
        "name": "CUKE"
    },
    "CUKH": {
        "id": "CUKH",
        "name": "CUSTOM KING HORSE TRAILER"
    },
    "CULL": {
        "id": "CULL",
        "name": "CULLIP INDUSTRIES"
    },
    "CULN": {
        "id": "CULN",
        "name": "CULLISON MFG."
    },
    "CULP": {
        "id": "CULP",
        "name": "CULPEPPER TRAILER"
    },
    "CUMB": {
        "id": "CUMB",
        "name": "CUMBERLAND COACHES H AND W MFG"
    },
    "CUMS": {
        "id": "CUMS",
        "name": "CUSTOM MANUFACTURING SOLUTIONS"
    },
    "CUMW": {
        "id": "CUMW",
        "name": "CUSTOM MOTORCYCLE WORKS"
    },
    "CUNN": {
        "id": "CUNN",
        "name": "CUNNINGHAM"
    },
    "CURB": {
        "id": "CURB",
        "name": "CURBMASTER OF AMERICA"
    },
    "CURK": {
        "id": "CURK",
        "name": "CURB KING EQUIPMENT BRIGHAM CITY"
    },
    "CURM": {
        "id": "CURM",
        "name": "CURRENT MOTOR COMPANY FORMERLY-ELECTRIC VEHICLE MANUFACTURINGLLC ANN"
    },
    "CURR": {
        "id": "CURR",
        "name": "CURRAHEE TRAILERS"
    },
    "CURT": {
        "id": "CURT",
        "name": "CURTISDIV. GLOBEMASTER MOBILE HOMES"
    },
    "CUSA": {
        "id": "CUSA",
        "name": "CUSTOM CAMPER"
    },
    "CUSB": {
        "id": "CUSB",
        "name": "CUSTOM BOND MFG."
    },
    "CUSC": {
        "id": "CUSC",
        "name": "CUSTOM COACH"
    },
    "CUSE": {
        "id": "CUSE",
        "name": "CUSTOM ENGINEERING"
    },
    "CUSF": {
        "id": "CUSF",
        "name": "CUSTOM CRAFT MFG."
    },
    "CUSH": {
        "id": "CUSH",
        "name": "CUSHMAN"
    },
    "CUSI": {
        "id": "CUSI",
        "name": "CUSTOM HOMES"
    },
    "CUSL": {
        "id": "CUSL",
        "name": "CUSTOM PLUMBING"
    },
    "CUSM": {
        "id": "CUSM",
        "name": "CUSTOM TRAILERS"
    },
    "CUSO": {
        "id": "CUSO",
        "name": "CUSTOM FRAMES"
    },
    "CUSP": {
        "id": "CUSP",
        "name": "CUSTOM PITS AND FABRICATION"
    },
    "CUST": {
        "id": "CUST",
        "name": "CMW-CUSTOM METAL WORKS"
    },
    "CUSV": {
        "id": "CUSV",
        "name": "CUSTOM SERVICES GENOA CITY WISCONSIN"
    },
    "CUTC": {
        "id": "CUTC",
        "name": "CUSTOM TRAILER CORPORATION OCALA"
    },
    "CUTL": {
        "id": "CUTL",
        "name": "CUTLASS & CUTLASS LTD. MFG BY HOMETTE CORP AFFILIATED WWITH SKYLINE"
    },
    "CUTM": {
        "id": "CUTM",
        "name": "CUSTOM ATV & TRAILER MANUFACTURING WESTMINSTER"
    },
    "CUTU": {
        "id": "CUTU",
        "name": "CUSHMAN TURF-OMC LINCOLN"
    },
    "CUTW": {
        "id": "CUTW",
        "name": "CUSTOM TRAILER WORKS LLC WAUPACA"
    },
    "CUYL": {
        "id": "CUYL",
        "name": "CUYLER CORP."
    },
    "CVCG": {
        "id": "CVCG",
        "name": "CAMP GROUND SERIES BRAND MFG BY CAVALIER HOMES"
    },
    "CVCO": {
        "id": "CVCO",
        "name": "CAVCO INDUSTRIES"
    },
    "CVDG": {
        "id": "CVDG",
        "name": "DESIGNER SERIES BRAND MFG BY CAVALIER HOMES"
    },
    "CVLK": {
        "id": "CVLK",
        "name": "LAKESIDE SERIES BRAND MFG BY CAVALIER HOMES INC"
    },
    "CVLO": {
        "id": "CVLO",
        "name": "CAVALLO"
    },
    "CVNT": {
        "id": "CVNT",
        "name": "COVENTRY-EAGLE COVENTRY"
    },
    "CVWE": {
        "id": "CVWE",
        "name": "C & V WELDING AND FABRICATING"
    },
    "CWBY": {
        "id": "CWBY",
        "name": "COWBOY CLASSICS CONVERSIONS"
    },
    "CWCC": {
        "id": "CWCC",
        "name": "COWTOWN CUSTOMS AND COBRAS  FT. WORTH TEXAS"
    },
    "CWCF": {
        "id": "CWCF",
        "name": "CWC FEATHER-LITE MFG."
    },
    "CWJT": {
        "id": "CWJT",
        "name": "CREEKSIDE WELDING AND JAMAR TRAILERS BEDFORD"
    },
    "CWLD": {
        "id": "CWLD",
        "name": "CUSTOM WELD TRAILER GRIFFIN"
    },
    "CWOD": {
        "id": "CWOD",
        "name": "CARWOOD INDUSTRIES TRAILERS"
    },
    "CWPC": {
        "id": "CWPC",
        "name": "CHOPPER WORKS"
    },
    "CWTE": {
        "id": "CWTE",
        "name": "C & W TRUCK EQUIPMENT"
    },
    "CWTL": {
        "id": "CWTL",
        "name": "C & W TRAILRS"
    },
    "CWTR": {
        "id": "CWTR",
        "name": "C & W TRAILERS GOLDEN"
    },
    "CYAN": {
        "id": "CYAN",
        "name": "CANYON TRAILERS TISHOMINGO OKLAHOMA"
    },
    "CYCE": {
        "id": "CYCE",
        "name": "CY-CORP ENTERPRISES"
    },
    "CYCI": {
        "id": "CYCI",
        "name": "CYCLE IMPORTS"
    },
    "CYCK": {
        "id": "CYCK",
        "name": "CYCLE KAMP"
    },
    "CYCL": {
        "id": "CYCL",
        "name": "UNPUBLISHED MOTORCYCLE MFR."
    },
    "CYIM": {
        "id": "CYIM",
        "name": "CYCLE IMAGERY IMAGE CUSTOMS SANTA CRUZ"
    },
    "CYLN": {
        "id": "CYLN",
        "name": "CYCLONE TRAVEL TRAILER MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "CYLP": {
        "id": "CYLP",
        "name": "CYCLOPS TRIKE COMPONENTS LAVACA"
    },
    "CYNG": {
        "id": "CYNG",
        "name": "CYNERGY CARGO LLC NASHVILLE"
    },
    "CYOT": {
        "id": "CYOT",
        "name": "COYOTE MFG."
    },
    "CYSC": {
        "id": "CYSC",
        "name": "CYCLESCOOT"
    },
    "CYTE": {
        "id": "CYTE",
        "name": "COYOTE MFG."
    },
    "CZCV": {
        "id": "CZCV",
        "name": "COZY COVE TRAILERS LLC MICHIGAN"
    },
    "DB": {
        "id": "D.B.",
        "name": "DKHO D.K. HOSTETLER"
    },
    "DABR": {
        "id": "DABR",
        "name": "BROWN"
    },
    "DACO": {
        "id": "DACO",
        "name": "DA"
    },
    "DACU": {
        "id": "DACU",
        "name": "D AND A CUSTOM FITZGERALD GA"
    },
    "DAEC": {
        "id": "DAEC",
        "name": "DAECHANG MOTORS COMPANY"
    },
    "DAEL": {
        "id": "DAEL",
        "name": "DAELIM MOTOR CO"
    },
    "DAEW": {
        "id": "DAEW",
        "name": "DAEWOO"
    },
    "DAF": {
        "id": "DAF",
        "name": "DAF"
    },
    "DAFF": {
        "id": "DAFF",
        "name": "DAFFIN MFG."
    },
    "DAFU": {
        "id": "DAFU",
        "name": "DAFU MOTORCYCLE"
    },
    "DAIH": {
        "id": "DAIH",
        "name": "DAIHATSU"
    },
    "DAIM": {
        "id": "DAIM",
        "name": "DAIMLER"
    },
    "DAIN": {
        "id": "DAIN",
        "name": "D & A VEHICLES"
    },
    "DAIR": {
        "id": "DAIR",
        "name": "DAIRY EQUIPMENT"
    },
    "DAIX": {
        "id": "DAIX",
        "name": "DAIXI ZHENHUA TECH TRADE"
    },
    "DAKO": {
        "id": "DAKO",
        "name": "DAKOTA MFG"
    },
    "DALE": {
        "id": "DALE",
        "name": "DALESMAN UNITED KINGDOM"
    },
    "DALI": {
        "id": "DALI",
        "name": "DALTON"
    },
    "DALR": {
        "id": "DALR",
        "name": "DALOR INDUSTRIES"
    },
    "DALT": {
        "id": "DALT",
        "name": "DALTON ENTERPRISES INC."
    },
    "DALW": {
        "id": "DALW",
        "name": "DALEWOOD MANUFACTURING"
    },
    "DAMH": {
        "id": "DAMH",
        "name": "DARBY MANUFACTURED HOME"
    },
    "DAMO": {
        "id": "DAMO",
        "name": "DAMON INDUSTRIES AKA - DAMON MOTOR COACH MODELS INCLUDE DAYBREAK"
    },
    "DAMS": {
        "id": "DAMS",
        "name": "FABRICATION DAMSEN"
    },
    "DANA": {
        "id": "DANA",
        "name": "DANA TRUCK MOUNT CAMPER"
    },
    "DANB": {
        "id": "DANB",
        "name": "DANNY BOY TRAILER"
    },
    "DANC": {
        "id": "DANC",
        "name": "DAN"
    },
    "DAND": {
        "id": "DAND",
        "name": "DAN DEE INDUSTRIES"
    },
    "DANE": {
        "id": "DANE",
        "name": "DAN ENTERPRISES"
    },
    "DANM": {
        "id": "DANM",
        "name": "THE DANIELS MOTOR CAR"
    },
    "DANT": {
        "id": "DANT",
        "name": "DANDY MOBILE HOMES MFG BY DAN'S TRAILER SUPPLY"
    },
    "DANU": {
        "id": "DANU",
        "name": "DANUVIA"
    },
    "DANZ": {
        "id": "DANZ",
        "name": "DANZER INDUSTRIES & DANZER"
    },
    "DARB": {
        "id": "DARB",
        "name": "DARBY INDUSTRIES"
    },
    "DARC": {
        "id": "DARC",
        "name": "DARCELL INDUSTRIES"
    },
    "DARF": {
        "id": "DARF",
        "name": "DARF CORP."
    },
    "DARG": {
        "id": "DARG",
        "name": "DARGO MANUFACTURING"
    },
    "DART": {
        "id": "DART",
        "name": "DART"
    },
    "DARV": {
        "id": "DARV",
        "name": "DARVON DOUBLE TRAILER"
    },
    "DARW": {
        "id": "DARW",
        "name": "DARWIN"
    },
    "DATI": {
        "id": "DATI",
        "name": "DRYAIR 2OOO INC ST BRIEUX CANADA"
    },
    "DATO": {
        "id": "DATO",
        "name": "DATON"
    },
    "DATR": {
        "id": "DATR",
        "name": "DART TRUCK"
    },
    "DATS": {
        "id": "DATS",
        "name": "DATSUN"
    },
    "DATT": {
        "id": "DATT",
        "name": "D AND A TRUCK AND TRAILER INC SALT LAKE CITY UT"
    },
    "DAUP": {
        "id": "DAUP",
        "name": "DAUPHIN"
    },
    "DAVB": {
        "id": "DAVB",
        "name": "DAVE'S BOAT TRAILER SALES"
    },
    "DAVC": {
        "id": "DAVC",
        "name": "DAVENCO"
    },
    "DAVD": {
        "id": "DAVD",
        "name": "DAVID MFG."
    },
    "DAVE": {
        "id": "DAVE",
        "name": "DAVE HICKS"
    },
    "DAVI": {
        "id": "DAVI",
        "name": "DAVIS"
    },
    "DAVK": {
        "id": "DAVK",
        "name": "DA VACK MFG."
    },
    "DAVM": {
        "id": "DAVM",
        "name": "DAVIDSON ENTERPRISES"
    },
    "DAVN": {
        "id": "DAVN",
        "name": "DAVENPORT TRAILER OR MOBILE HOME"
    },
    "DAVO": {
        "id": "DAVO",
        "name": "DAVRON TRAVELER"
    },
    "DAVY": {
        "id": "DAVY",
        "name": "DAVEY COMPRESSOR"
    },
    "DAWE": {
        "id": "DAWE",
        "name": "DAWES PRODUCTS"
    },
    "DAWR": {
        "id": "DAWR",
        "name": "LA DAWRI COACHCRAFT"
    },
    "DAWS": {
        "id": "DAWS",
        "name": "DAWSON ENTERPRISES ELKHART"
    },
    "DAYB": {
        "id": "DAYB",
        "name": "COMMANDO YARD TRACTOR MFG. BY DAYBROOK-OTTAWA DIV"
    },
    "DAYO": {
        "id": "DAYO",
        "name": "DAYTONA"
    },
    "DAYS": {
        "id": "DAYS",
        "name": "DAY'S DRYDOCK NORTH WEBSTERM IN"
    },
    "DAYT": {
        "id": "DAYT",
        "name": "DAYTON ELECTRIC MFG."
    },
    "DAZN": {
        "id": "DAZN",
        "name": "ZHUHAI ZHUJIANG VEHICLE"
    },
    "DBCK": {
        "id": "DBCK",
        "name": "DIAMON"
    },
    "DBCP": {
        "id": "DBCP",
        "name": "D & B CYCLE PARTS & ACCESSORIES WARREN"
    },
    "DBDL": {
        "id": "DBDL",
        "name": "DOUBLE DELIGHT"
    },
    "DBLT": {
        "id": "DBLT",
        "name": "DOUBLE D TRAILERS KINSTON NORTH CAROLINA"
    },
    "DBSI": {
        "id": "DBSI",
        "name": "DI BIASI OF NORTH AMERICA CHEHALIS WA"
    },
    "DCAP": {
        "id": "DCAP",
        "name": "DECAP TRAILER MFG."
    },
    "DCCS": {
        "id": "DCCS",
        "name": "DEEP SOUTH SALES"
    },
    "DCHP": {
        "id": "DCHP",
        "name": "DIAMON"
    },
    "DCKS": {
        "id": "DCKS",
        "name": "DICKSON INDUSTRIES"
    },
    "DCNT": {
        "id": "DCNT",
        "name": "DISCOUNT TRAILERS FORT WORTH"
    },
    "DCRC": {
        "id": "DCRC",
        "name": "ROADCLIPPER ENTERPRISES INC"
    },
    "DCRG": {
        "id": "DCRG",
        "name": "DIAMOND CARGO"
    },
    "DCRM": {
        "id": "DCRM",
        "name": "DISCOUNT RAMPS.COM WEST BEND"
    },
    "DCSV": {
        "id": "DCSV",
        "name": "DISCOVERY CARGO TRAILERS"
    },
    "DCTH": {
        "id": "DCTH",
        "name": "DC TRAILER"
    },
    "DCTM": {
        "id": "DCTM",
        "name": "DISCOUNT TRAILER MFG. AZTEC"
    },
    "DCTR": {
        "id": "DCTR",
        "name": "DIAMOND CITY TRAILER MFG.MURFREESBORO"
    },
    "DDCC": {
        "id": "DDCC",
        "name": "DD CUSTOM CYCLE"
    },
    "DDFB": {
        "id": "DDFB",
        "name": "D & D FABRICATIONS"
    },
    "DDTI": {
        "id": "DDTI",
        "name": "D & D TRAILER INC.TRENTON"
    },
    "DEAG": {
        "id": "DEAG",
        "name": "DENNIS EAGLE INC WEST FARGO ND"
    },
    "DEAL": {
        "id": "DEAL",
        "name": "DEUTZ-ALLIS CORPORATION"
    },
    "DEAN": {
        "id": "DEAN",
        "name": "DEAN INDUSTRIES"
    },
    "DEBE": {
        "id": "DEBE",
        "name": "DELAUNAY-BELLEVILLE OR S.A.DES AUTOMOBILES DELAUNAY- BELLEVILLE"
    },
    "DEBO": {
        "id": "DEBO",
        "name": "DEBONAIR"
    },
    "DECA": {
        "id": "DECA",
        "name": "DECAMP HOMES"
    },
    "DECI": {
        "id": "DECI",
        "name": "DEMOUNTABLE CONCEPTS INC GLASSBORO NJ"
    },
    "DECO": {
        "id": "DECO",
        "name": "DECOURVILLE"
    },
    "DEDI": {
        "id": "DEDI",
        "name": "DE DION OR DE DION-BOUTON 1893-1932"
    },
    "DEEP": {
        "id": "DEEP",
        "name": "DEEP SANDERSON"
    },
    "DEER": {
        "id": "DEER",
        "name": "DEERE"
    },
    "DEEZ": {
        "id": "DEEZ",
        "name": "DEE ZEE MFG. IOWA"
    },
    "DEGE": {
        "id": "DEGE",
        "name": "DEGEEST MFG."
    },
    "DEGL": {
        "id": "DEGL",
        "name": "DEGELMAN INDUSTRIES"
    },
    "DEIC": {
        "id": "DEIC",
        "name": "DEI"
    },
    "DEKC": {
        "id": "DEKC",
        "name": "DEK"
    },
    "DELA": {
        "id": "DELA",
        "name": "DE-LAR"
    },
    "DELC": {
        "id": "DELC",
        "name": "DEL"
    },
    "DELG": {
        "id": "DELG",
        "name": "DELAGE FRANCE MERGED WITH DELAHAYE VMA"
    },
    "DELH": {
        "id": "DELH",
        "name": "DELHI METAL PRODUCTS"
    },
    "DELK": {
        "id": "DELK",
        "name": "DELKRON TRANSMISSION"
    },
    "DELL": {
        "id": "DELL",
        "name": "DELLOW"
    },
    "DELM": {
        "id": "DELM",
        "name": "DELMAR MFG."
    },
    "DELN": {
        "id": "DELN",
        "name": "DELAVAN TRAILER"
    },
    "DELO": {
        "id": "DELO",
        "name": "DELOREAN MOTOR COMPANY DUNMURRY NORTHERN IRELAND AND NEW YORK NY"
    },
    "DELP": {
        "id": "DELP",
        "name": "DELPHI BODY WORKS"
    },
    "DELR": {
        "id": "DELR",
        "name": "DEL REY INDUSTRIES"
    },
    "DELS": {
        "id": "DELS",
        "name": "DELTA HOMES CORP."
    },
    "DELT": {
        "id": "DELT",
        "name": "DELTA TRUCK TRAILER"
    },
    "DELU": {
        "id": "DELU",
        "name": "DELTO HOMES"
    },
    "DELX": {
        "id": "DELX",
        "name": "DELUXE HOMES"
    },
    "DEMC": {
        "id": "DEMC",
        "name": "DEMO HORSE TRAILER"
    },
    "DEMM": {
        "id": "DEMM",
        "name": "DEMCO"
    },
    "DEMN": {
        "id": "DEMN",
        "name": "DEMON MOTORCYCLE"
    },
    "DEMP": {
        "id": "DEMP",
        "name": "DEMPSTER"
    },
    "DEMR": {
        "id": "DEMR",
        "name": "DEMERS AMBULANCE MANUFACTURER"
    },
    "DEMT": {
        "id": "DEMT",
        "name": "DEMENTED CYCLES HEMPSTEAD TX"
    },
    "DEMU": {
        "id": "DEMU",
        "name": "DE MUTH STEEL PRODUCTS"
    },
    "DENA": {
        "id": "DENA",
        "name": "DENAIR TRAILER MANUFACTURING"
    },
    "DENC": {
        "id": "DENC",
        "name": "DEN"
    },
    "DENE": {
        "id": "DENE",
        "name": "DENVER TRAILER SUPPLY"
    },
    "DENN": {
        "id": "DENN",
        "name": "DENNISON UTILITY TRAILER"
    },
    "DENV": {
        "id": "DENV",
        "name": "DENVER CHOPPERS HENDERSON"
    },
    "DENZ": {
        "id": "DENZ",
        "name": "DENZEL"
    },
    "DERB": {
        "id": "DERB",
        "name": "DERBI MOTOR CORP."
    },
    "DERE": {
        "id": "DERE",
        "name": "DE ROSE INDUSTRIES INC.INDIANAPOLIS"
    },
    "DERH": {
        "id": "DERH",
        "name": "DENZIN & RAHN MFG."
    },
    "DERS": {
        "id": "DERS",
        "name": "DEERSKIN MFG."
    },
    "DESA": {
        "id": "DESA",
        "name": "DE SOTO COACH"
    },
    "DESG": {
        "id": "DESG",
        "name": "DESIGN INTENNT"
    },
    "DESI": {
        "id": "DESI",
        "name": "DESIGN STRUCTURES MFGS OFFICE AND SPECIAL PURPOSE TRAILERS LOMBARD"
    },
    "DESO": {
        "id": "DESO",
        "name": "DESOTO"
    },
    "DESP": {
        "id": "DESP",
        "name": "DRAGON ESP"
    },
    "DEST": {
        "id": "DEST",
        "name": "DESTINY INDUSTRIES"
    },
    "DETB": {
        "id": "DETB",
        "name": "DETROIT BROTHERS CUSTOM CYCLES OR DETROIT BROTHERS"
    },
    "DETC": {
        "id": "DETC",
        "name": "DE TECT"
    },
    "DETE": {
        "id": "DETE",
        "name": "DETROIT TRAILER"
    },
    "DETH": {
        "id": "DETH",
        "name": "DETHMERS MANUFACTURING COMPANY BOYDEN"
    },
    "DETI": {
        "id": "DETI",
        "name": "DETROITER HOMES"
    },
    "DETK": {
        "id": "DETK",
        "name": "DETAIL K2 INC D2K BURLINGTON ONTARIO CANADA"
    },
    "DETO": {
        "id": "DETO",
        "name": "DETOMASO"
    },
    "DETR": {
        "id": "DETR",
        "name": "DETROIT CHASSIS"
    },
    "DETT": {
        "id": "DETT",
        "name": "DETROIT TOOL"
    },
    "DEUT": {
        "id": "DEUT",
        "name": "DEUTZ TRACTOR"
    },
    "DEVE": {
        "id": "DEVE",
        "name": "DEVIN ENTERPRISES CALIFORNIA"
    },
    "DEVI": {
        "id": "DEVI",
        "name": "DEVILLE INDUSTRIES"
    },
    "DEVL": {
        "id": "DEVL",
        "name": "DEVIL TRAVEL TRAILER"
    },
    "DEVN": {
        "id": "DEVN",
        "name": "DEVINE'S MACHINE & DESIGN"
    },
    "DEWE": {
        "id": "DEWE",
        "name": "DEW EZE MFG."
    },
    "DEWG": {
        "id": "DEWG",
        "name": "WIRTGEN GROUP A JOHN DEERE COMPANY GERMANY"
    },
    "DEWL": {
        "id": "DEWL",
        "name": "D & E WELDING LYONS"
    },
    "DEXT": {
        "id": "DEXT",
        "name": "DEXTER TRAILER"
    },
    "DFHE": {
        "id": "DFHE",
        "name": "DFH ENTERPRISES ATLANTA"
    },
    "DFSK": {
        "id": "DFSK",
        "name": "DFSK CHONGQING CHINA"
    },
    "DGEN": {
        "id": "DGEN",
        "name": "DODGEN INDUSTRIES"
    },
    "DHLE": {
        "id": "DHLE",
        "name": "DHLE ENTERPRISES CALIFORNIA"
    },
    "DHME": {
        "id": "DHME",
        "name": "D H M ENTERPRISES"
    },
    "DHRT": {
        "id": "DHRT",
        "name": "D HART DESIGNS INC. MINNESOTA"
    },
    "DHSS": {
        "id": "DHSS",
        "name": "DHS SYSTEMS"
    },
    "DIAB": {
        "id": "DIAB",
        "name": "DIAMOND B"
    },
    "DIAC": {
        "id": "DIAC",
        "name": "DIAMOND C MT. PLEASANT"
    },
    "DIAD": {
        "id": "DIAD",
        "name": "DIAMOND MOBILE HOME MFG."
    },
    "DIAE": {
        "id": "DIAE",
        "name": "DIAMOND E MANUFACTURING"
    },
    "DIAG": {
        "id": "DIAG",
        "name": "DIAMOND G TRAILER COMPANY"
    },
    "DIAM": {
        "id": "DIAM",
        "name": "DIAMOND G EQUIPMENTOKLAHOMA CITY"
    },
    "DIAN": {
        "id": "DIAN",
        "name": "DIANA MOTOR COMPANY ST. LOUIS MISSOURI ANTIQUE AUTOMOBILES"
    },
    "DIAO": {
        "id": "DIAO",
        "name": "DIAMOND SWALTERS"
    },
    "DIAP": {
        "id": "DIAP",
        "name": "DIAMOND PRODUCTS"
    },
    "DIAQ": {
        "id": "DIAQ",
        "name": "DIAMOND QUALITY TRAILERS CHUBBUCK"
    },
    "DIAR": {
        "id": "DIAR",
        "name": "DIAMOND REO DISCONTINUED PROD IN 1974"
    },
    "DIAS": {
        "id": "DIAS",
        "name": "DIAMOND-BILT MFD BY DIAMOND STEEL"
    },
    "DIAT": {
        "id": "DIAT",
        "name": "DIAMOND T TRAILERS"
    },
    "DIBL": {
        "id": "DIBL",
        "name": "DIABLO PERFORMANCE"
    },
    "DICC": {
        "id": "DICC",
        "name": "DIAMOND COACH COMPANY KANSAS FORMERLY COONS MFG OR _COONS CUSTOM"
    },
    "DICH": {
        "id": "DICH",
        "name": "CHARLEVOIX MOBILE HOMES MFD BY DICKINSON HOMES"
    },
    "DICK": {
        "id": "DICK",
        "name": "DICKIRSON EQUIPMENT CORP.SALEM"
    },
    "DICO": {
        "id": "DICO",
        "name": "DI"
    },
    "DIDI": {
        "id": "DIDI",
        "name": "DIDIER MFG."
    },
    "DIEP": {
        "id": "DIEP",
        "name": "DIEPHOLZ TRAILERS"
    },
    "DIER": {
        "id": "DIER",
        "name": "DIERZEN KEWANEE HEAVY INDUSTRIES KEWANEE"
    },
    "DIGM": {
        "id": "DIGM",
        "name": "DIGMOR EQUIPMENT & ENGINEERING"
    },
    "DIII": {
        "id": "DIII",
        "name": "DURATECH INDUSTRIES INTERNATIONAL INC JAMESTOWN ND"
    },
    "DILL": {
        "id": "DILL",
        "name": "DILLIS TRAILER MFG."
    },
    "DILM": {
        "id": "DILM",
        "name": "DILLE & MCGUIRE MFG."
    },
    "DILO": {
        "id": "DILO",
        "name": "DILLON ENTERPRISES"
    },
    "DILY": {
        "id": "DILY",
        "name": "DILLY NELSON-DYKES CO"
    },
    "DIMD": {
        "id": "DIMD",
        "name": "DI-MOND TRAILERS"
    },
    "DIMO": {
        "id": "DIMO",
        "name": "DIAMO BRAND POWERSPORT PORDUCTS"
    },
    "DINA": {
        "id": "DINA",
        "name": "DINA CAMIONES S. A. DE C. V."
    },
    "DINK": {
        "id": "DINK",
        "name": "DINKMAR GALION OH LEAF VACUUM"
    },
    "DINL": {
        "id": "DINL",
        "name": "DINLI ATV'S"
    },
    "DION": {
        "id": "DION",
        "name": "DIONBILT LLC GRANDVIEW"
    },
    "DIPL": {
        "id": "DIPL",
        "name": "DIPLOMAT MOTOR HOME"
    },
    "DIRS": {
        "id": "DIRS",
        "name": "DIRECT TRAILER SOURCE LLC"
    },
    "DIRT": {
        "id": "DIRT",
        "name": "DIRECT TRAILER LP TEXAS"
    },
    "DISC": {
        "id": "DISC",
        "name": "DISCOVER 25 MOTOR HOME"
    },
    "DITC": {
        "id": "DITC",
        "name": "DITCH WITCH DIV.DIV. OF CHARLES MACHINE WORKS"
    },
    "DITE": {
        "id": "DITE",
        "name": "DI TELLA"
    },
    "DITR": {
        "id": "DITR",
        "name": "DIAMOND TRAILERS"
    },
    "DIVA": {
        "id": "DIVA",
        "name": "DIVA"
    },
    "DIVC": {
        "id": "DIVC",
        "name": "DIVCO"
    },
    "DIVE": {
        "id": "DIVE",
        "name": "DIVELY MFG."
    },
    "DIVO": {
        "id": "DIVO",
        "name": "DIVCO-WAYNE INDUSTRIES"
    },
    "DIXE": {
        "id": "DIXE",
        "name": "DIXIE CRAFT TRAILERS"
    },
    "DIXI": {
        "id": "DIXI",
        "name": "DIXIE CUSTOM TRAILERS AND HITCH"
    },
    "DIXO": {
        "id": "DIXO",
        "name": "DIXON INDUSTRIES"
    },
    "DJES": {
        "id": "DJES",
        "name": "DANIEL J ESSENPREIS MFG"
    },
    "DJNG": {
        "id": "DJNG",
        "name": "DEJONG PRODUCTS INC"
    },
    "DJTW": {
        "id": "DJTW",
        "name": "D J TRAILERS & WELDING"
    },
    "DKAA": {
        "id": "DKAA",
        "name": "DAKOTA AIRBOATS INC WEST MELBOURNE"
    },
    "DKDR": {
        "id": "DKDR",
        "name": "DOCK DOCTORS LLC FERRISBURGH VT"
    },
    "DKR": {
        "id": "DKR",
        "name": "DKR"
    },
    "DKTA": {
        "id": "DKTA",
        "name": "DAKOTA TRAILER MANUFACTURING"
    },
    "DKTI": {
        "id": "DKTI",
        "name": "D & K TRAILERS"
    },
    "DKW": {
        "id": "DKW",
        "name": "DKW"
    },
    "DLHY": {
        "id": "DLHY",
        "name": "DELAHAYE"
    },
    "DLLR": {
        "id": "DLLR",
        "name": "DILLER EQUIPMENT"
    },
    "DLMC": {
        "id": "DLMC",
        "name": "DOLPHIN MOTOR COACH LLC PREVIOUSLY GRAND COACH LLC NAME"
    },
    "DLPE": {
        "id": "DLPE",
        "name": "DELOUPE"
    },
    "DLPH": {
        "id": "DLPH",
        "name": "DOLPHIN MOTORHOMES UNITED KINGDOM"
    },
    "DLTA": {
        "id": "DLTA",
        "name": "DELTA MANUFACTURING NEWPORT"
    },
    "DLTN": {
        "id": "DLTN",
        "name": "DALTON KID RIDES"
    },
    "DLTO": {
        "id": "DLTO",
        "name": "DELTA OILFIELD TANK COMPANY"
    },
    "DLTS": {
        "id": "DLTS",
        "name": "DELTA STAR"
    },
    "DLVC": {
        "id": "DLVC",
        "name": "DELIVERY CONCEPTS"
    },
    "DLVO": {
        "id": "DLVO",
        "name": "DULEVO STREET SWEEPERS"
    },
    "DLWS": {
        "id": "DLWS",
        "name": "D AND L WELDING SERVICES CHRISTMAS VALLEY OR"
    },
    "DMBT": {
        "id": "DMBT",
        "name": "DMB TRAILERS TRENTON"
    },
    "DMFT": {
        "id": "DMFT",
        "name": "D.M.F TRAILER MANUFACTURING"
    },
    "DMHC": {
        "id": "DMHC",
        "name": "DMH"
    },
    "DMI": {
        "id": "DMI",
        "name": "DMI"
    },
    "DMMF": {
        "id": "DMMF",
        "name": "DM MANUFACTURING MADISON NEW JERSEY MOTORCYCLES"
    },
    "DMND": {
        "id": "DMND",
        "name": "DIAMOND D TRAILER MANUFACTURING OR DIAMOND TRAILERS MFG. BLUE RAPIDS"
    },
    "DMP": {
        "id": "DMP",
        "name": "DMP"
    },
    "DMPM": {
        "id": "DMPM",
        "name": "DUMP-MASTER INC MICHIGAN"
    },
    "DMPX": {
        "id": "DMPX",
        "name": "DUMP MAXX FAIRMONT"
    },
    "DMTL": {
        "id": "DMTL",
        "name": "DM TELAI"
    },
    "DMVE": {
        "id": "DMVE",
        "name": "D.M. & V. ENTERPRISES"
    },
    "DNAC": {
        "id": "DNAC",
        "name": "DNA CYCLE INC PORT RICHEY FL"
    },
    "DNAE": {
        "id": "DNAE",
        "name": "DNA ENTERPRISES"
    },
    "DNAI": {
        "id": "DNAI",
        "name": "DYNATEST NORTH AMERICA INC"
    },
    "DNBV": {
        "id": "DNBV",
        "name": "BAYVIEW MODEL"
    },
    "DNCA": {
        "id": "DNCA",
        "name": "CANTERBURY MODEL MFG BY DNA ENTERPRISES"
    },
    "DNGH": {
        "id": "DNGH",
        "name": "GRAND HAVEN MODEL"
    },
    "DNLW": {
        "id": "DNLW",
        "name": "DOWN LOW CUSTOMS  OREGON"
    },
    "DNMF": {
        "id": "DNMF",
        "name": "DENARDIS MOTOR FACTORY NAPLES FL"
    },
    "DNNM": {
        "id": "DNNM",
        "name": "DENNING MACHINE SHOP"
    },
    "DNNS": {
        "id": "DNNS",
        "name": "DENNIS"
    },
    "DNPR": {
        "id": "DNPR",
        "name": "DNEPR OFFICIAL NAME-KIEV MOTORZYKLY ZAVOD5RUSSIAN BRAND OF MOTORCYCLE"
    },
    "DNPV": {
        "id": "DNPV",
        "name": "PARKVIEW MODEL"
    },
    "DNRG": {
        "id": "DNRG",
        "name": "DURANGO MFG BY KZRV LP OR KZ RECREATIONAL VEHICLES"
    },
    "DOAN": {
        "id": "DOAN",
        "name": "DOANE"
    },
    "DODD": {
        "id": "DODD",
        "name": "DODD MFG."
    },
    "DODE": {
        "id": "DODE",
        "name": "DODGE WOODCRAFT"
    },
    "DODG": {
        "id": "DODG",
        "name": "DODGE"
    },
    "DOEP": {
        "id": "DOEP",
        "name": "DOEPKER INDUSTRIES LTD."
    },
    "DOFL": {
        "id": "DOFL",
        "name": "DONKEY FORKLIFT DIVISION OF HOL MAC CORP BAY SPRINGS MS"
    },
    "DOLA": {
        "id": "DOLA",
        "name": "DOLAN CORP."
    },
    "DOLI": {
        "id": "DOLI",
        "name": "DOLPHIN HOMES DIV TIDWELL IND."
    },
    "DOLP": {
        "id": "DOLP",
        "name": "DOLPHIN BOAT TRAILERS"
    },
    "DOMI": {
        "id": "DOMI",
        "name": "DOMINION ROAD MACHINERY"
    },
    "DOMO": {
        "id": "DOMO",
        "name": "DO-MOR EQUIPMENT"
    },
    "DONA": {
        "id": "DONA",
        "name": "DON-A-BELL HOMES"
    },
    "DONF": {
        "id": "DONF",
        "name": "DONGFANG LINGYUN VEHICLE MADE"
    },
    "DONG": {
        "id": "DONG",
        "name": "DONG FENG EAST WIND AUTO AND BUSES ALSO DONGFENG YANGTSE MOTORWUHAN"
    },
    "DONH": {
        "id": "DONH",
        "name": "DONHAL"
    },
    "DONL": {
        "id": "DONL",
        "name": "DONNELL BOAT TRAILER"
    },
    "DONM": {
        "id": "DONM",
        "name": "DON MAY ENTERPRISES RICHARDSON"
    },
    "DONS": {
        "id": "DONS",
        "name": "DONS BOAT TRAILER"
    },
    "DONU": {
        "id": "DONU",
        "name": "DONAHUE CORP."
    },
    "DOO": {
        "id": "DOO",
        "name": "DOO SNOWMOBILE TRAILER"
    },
    "DOOH": {
        "id": "DOOH",
        "name": "ZHEJIANG DOOHAN TECHNOLOGY"
    },
    "DOOL": {
        "id": "DOOL",
        "name": "DOOLITTLE"
    },
    "DOON": {
        "id": "DOON",
        "name": "DOONAN TRAILER CORP.GREAT BEND"
    },
    "DOOS": {
        "id": "DOOS",
        "name": "DOOSAN INTERNATIONAL USA"
    },
    "DORI": {
        "id": "DORI",
        "name": "DORMI INDUSTRIES"
    },
    "DORS": {
        "id": "DORS",
        "name": "DORSEY TRAILERS INC.ELBA"
    },
    "DORT": {
        "id": "DORT",
        "name": "DORT MOTOR CAR COMPANY FLINT"
    },
    "DOT": {
        "id": "DOT",
        "name": "DOT"
    },
    "DOUB": {
        "id": "DOUB",
        "name": "DOUBLE SHUFFLE MOBILE HOME"
    },
    "DOUD": {
        "id": "DOUD",
        "name": "DOUBLE D DISTRIBUTORS"
    },
    "DOUE": {
        "id": "DOUE",
        "name": "DOUBLE E TRAILER MFG.COMMANCHE"
    },
    "DOUG": {
        "id": "DOUG",
        "name": "DOUGLAS HOMES"
    },
    "DOUJ": {
        "id": "DOUJ",
        "name": "DOUBLE J HORSE TRAILER"
    },
    "DOUK": {
        "id": "DOUK",
        "name": "DOUBLE K"
    },
    "DOUL": {
        "id": "DOUL",
        "name": "DOUBLE L TRAILERS PARAGOULD"
    },
    "DOUM": {
        "id": "DOUM",
        "name": "DOUGLAS MARINE CORP DOUGLAS"
    },
    "DOUN": {
        "id": "DOUN",
        "name": "DOUBLE N TRAILER MANUFACTURING MOUND CITY"
    },
    "DOUR": {
        "id": "DOUR",
        "name": "DOUBLE R TRAILER MANUFACTURING"
    },
    "DOWE": {
        "id": "DOWE",
        "name": "DOWNER INDUSTRIES"
    },
    "DOWH": {
        "id": "DOWH",
        "name": "DOWNEY SHEET METAL SHOP"
    },
    "DPWC": {
        "id": "DPWC",
        "name": "D & P WELDING CO DEKALB"
    },
    "DRAG": {
        "id": "DRAG",
        "name": "DRAG MASTER UTILITY TRAILER"
    },
    "DRAK": {
        "id": "DRAK",
        "name": "DRAKE TRUCK BODIES"
    },
    "DRBI": {
        "id": "DRBI",
        "name": "DERBI NACIONAL MOTOR S.A.MOPEDS"
    },
    "DRBN": {
        "id": "DRBN",
        "name": "DURBIN'S CUSTOM WELD AND DESIGN HELEN MT"
    },
    "DRCH": {
        "id": "DRCH",
        "name": "DREAM COACH TRAILER FORMERLY DIAMOND G TRAILER"
    },
    "DRCO": {
        "id": "DRCO",
        "name": "DURA"
    },
    "DRCT": {
        "id": "DRCT",
        "name": "DELL RAPIDS CUSTOM TRAILERS"
    },
    "DRDI": {
        "id": "DRDI",
        "name": "DRI DOC INC GASSVILLE AR"
    },
    "DRDN": {
        "id": "DRDN",
        "name": "DARDON"
    },
    "DREA": {
        "id": "DREA",
        "name": "DREAMER"
    },
    "DREL": {
        "id": "DREL",
        "name": "ELITE SUITE MFG BY DRV SUITES"
    },
    "DRES": {
        "id": "DRES",
        "name": "DRESSER INDUSTRIES"
    },
    "DREW": {
        "id": "DREW",
        "name": "DREWES ENGINEERING"
    },
    "DRFH": {
        "id": "DRFH",
        "name": "FULL HOUSE BRAND MFG BY DRV LLC"
    },
    "DRGG": {
        "id": "DRGG",
        "name": "DRAGGIN CUSTOM CYCLES"
    },
    "DRGL": {
        "id": "DRGL",
        "name": "DRAGLITE TRAILER"
    },
    "DRIE": {
        "id": "DRIE",
        "name": "DRIFTER MFG."
    },
    "DRIF": {
        "id": "DRIF",
        "name": "DRIFTWOOD HOMES CORP."
    },
    "DRII": {
        "id": "DRII",
        "name": "DRI INDUSTRIES"
    },
    "DRIV": {
        "id": "DRIV",
        "name": "DRIVE ON TRAILER MFG.MISHAWAKA"
    },
    "DRMA": {
        "id": "DRMA",
        "name": "MOBILE SUITES AIRE MFG BY DRV LLC"
    },
    "DRML": {
        "id": "DRML",
        "name": "DREAMLINER TRAILER"
    },
    "DRMS": {
        "id": "DRMS",
        "name": "MOBILE SUITE MFG BY DRV SUITES"
    },
    "DROP": {
        "id": "DROP",
        "name": "DROPBOX"
    },
    "DROT": {
        "id": "DROT",
        "name": "DROTT MANUFACTURING"
    },
    "DROW": {
        "id": "DROW",
        "name": "DEATH ROW MOTORCYCLES SUGARLOAF"
    },
    "DRPT": {
        "id": "DRPT",
        "name": "DROP TAIL TRAILERS"
    },
    "DRPW": {
        "id": "DRPW",
        "name": "DONE RIGHT POWEWR EQUIPMENT DR POWER EQUIPMENT MOTORIZED"
    },
    "DRRI": {
        "id": "DRRI",
        "name": "DRR INC. OHIO PARENT COMPANY ACCESS MOTOR"
    },
    "DRRT": {
        "id": "DRRT",
        "name": "DRR ATV'S ACCESS MOTOR"
    },
    "DRSH": {
        "id": "DRSH",
        "name": "DRASH TRAILERS TRAILER MOUNTED SHELTERS"
    },
    "DRSN": {
        "id": "DRSN",
        "name": "DRESSEN CUSTOM TRAILERS"
    },
    "DRSS": {
        "id": "DRSS",
        "name": "DRS SUSTAINMENT SYSTEMS INC ST LOUIS MO"
    },
    "DRTD": {
        "id": "DRTD",
        "name": "TRADITION MFG BY DRV SUITES"
    },
    "DRTI": {
        "id": "DRTI",
        "name": "DORETTI"
    },
    "DRVS": {
        "id": "DRVS",
        "name": "DRV SUITES SUBSIDIARY OF HEARTLAND RV AND THOR INDUSTRIES INC HOWE IN"
    },
    "DRXX": {
        "id": "DRXX",
        "name": "DURUXX"
    },
    "DRYD": {
        "id": "DRYD",
        "name": "DRYDOCK TRAILER"
    },
    "DSCH": {
        "id": "DSCH",
        "name": "DRESCH MOTORCYCLES - FRANCE"
    },
    "DSCT": {
        "id": "DSCT",
        "name": "DEEP SOUTH CARGO TRAILERS LLC PEARSON"
    },
    "DSFT": {
        "id": "DSFT",
        "name": "DEEP SOUTH FIRE TRUCKS INC MISSISSIPPI"
    },
    "DSGN": {
        "id": "DSGN",
        "name": "DESIGN CONCEPTS"
    },
    "DSHE": {
        "id": "DSHE",
        "name": "DSH ENTERPRISES"
    },
    "DSPD": {
        "id": "DSPD",
        "name": "DESPERADO MOTOR RACING & MOTORCYCLES TAILRIDER"
    },
    "DSRT": {
        "id": "DSRT",
        "name": "DESERT ROSE BRAND MFG BY CAV"
    },
    "DSSC": {
        "id": "DSSC",
        "name": "SUPER COOKERMFD. BY DEEP SOUTH SALES"
    },
    "DSTB": {
        "id": "DSTB",
        "name": "D & S TRAILER BUILDERS MESA"
    },
    "DSTX": {
        "id": "DSTX",
        "name": "DEEP SOUTH TEXAS HILLSBORO TX"
    },
    "DSWI": {
        "id": "DSWI",
        "name": "DOSE STEELWORKS INC BOUTON IA"
    },
    "DSWM": {
        "id": "DSWM",
        "name": "DSW MFG."
    },
    "DSWS": {
        "id": "DSWS",
        "name": "DWS STOREWAY TRAILER CROSSVILLE"
    },
    "DTCA": {
        "id": "DTCA",
        "name": "CAMBRIDGE MFG BY DUTCH PARK HOMES"
    },
    "DTCC": {
        "id": "DTCC",
        "name": "DUTCH MFG BY NEWMAR CORP"
    },
    "DTCH": {
        "id": "DTCH",
        "name": "DUTCH PARK HOMES"
    },
    "DTCL": {
        "id": "DTCL",
        "name": "CLASSIC MFG BY DUTCH PARK HOMES"
    },
    "DTGY": {
        "id": "DTGY",
        "name": "DISCOUNT TRAILER GUYS AVONDALE AZ"
    },
    "DTHU": {
        "id": "DTHU",
        "name": "DESERT THUNDER CUSTOMS"
    },
    "DTLL": {
        "id": "DTLL",
        "name": "DIRECT TRAILER LLC WINAMAC IN"
    },
    "DTMC": {
        "id": "DTMC",
        "name": "DIAMOND T MOTOR CAR COMPANY CHICAGO IL"
    },
    "DTMO": {
        "id": "DTMO",
        "name": "MONTEREY MFG BY DUTCH PARK HOMES"
    },
    "DTPM": {
        "id": "DTPM",
        "name": "DTP MUSCLE CUSTOM CHOPPERS"
    },
    "DTRI": {
        "id": "DTRI",
        "name": "RIVERBROOK MFG BY DUTCH PARK HOMES"
    },
    "DTRL": {
        "id": "DTRL",
        "name": "DETRAIL TRAILER"
    },
    "DTRV": {
        "id": "DTRV",
        "name": "DOUBLETREE RV"
    },
    "DTSE": {
        "id": "DTSE",
        "name": "SEASIDE MFG BY DUTCH PARK HOMES"
    },
    "DTST": {
        "id": "DTST",
        "name": "STERLING"
    },
    "DTWL": {
        "id": "DTWL",
        "name": "DERSTINE TRAILERWORKS LLC THORP WI"
    },
    "DUAE": {
        "id": "DUAE",
        "name": "DUAL EVANS CORP."
    },
    "DUAG": {
        "id": "DUAG",
        "name": "DUAL-GHIA DETROIT MICHIGAN"
    },
    "DUAL": {
        "id": "DUAL",
        "name": "DUAL-WIDE"
    },
    "DUAM": {
        "id": "DUAM",
        "name": "DUAL MFG."
    },
    "DUAR": {
        "id": "DUAR",
        "name": "AEROLITE MFG BY DUTCHMEN MFG"
    },
    "DUAS": {
        "id": "DUAS",
        "name": "ASPEN TRAIL MFG BY DUTCHMEN MFG"
    },
    "DUBG": {
        "id": "DUBG",
        "name": "DOUBLE G TRAILERS FRIENDSHIP"
    },
    "DUBR": {
        "id": "DUBR",
        "name": "BAY RIDGE MFG BY DUTCHMEN MFG"
    },
    "DUBX": {
        "id": "DUBX",
        "name": "DUB BOX USA OREGON CITY"
    },
    "DUCA": {
        "id": "DUCA",
        "name": "DUCATI"
    },
    "DUCO": {
        "id": "DUCO",
        "name": "COLEMAN MFG BY DUTCHMEN MFG LLC MERGED WITH KEYSTONE RV COMPANY JAN2O14"
    },
    "DUDE": {
        "id": "DUDE",
        "name": "DUDE TRAILER"
    },
    "DUDU": {
        "id": "DUDU",
        "name": "DUTCHMEN MFG BY DUTCHMEN MFG"
    },
    "DUEL": {
        "id": "DUEL",
        "name": "DUEL"
    },
    "DUES": {
        "id": "DUES",
        "name": "DUESENBERG"
    },
    "DUFL": {
        "id": "DUFL",
        "name": "FINE LIFE COTTAGE MFG BY DUTCHMEN MFG"
    },
    "DUGA": {
        "id": "DUGA",
        "name": "DUGAN TRAILER"
    },
    "DUGO": {
        "id": "DUGO",
        "name": "DUSGO TRAILER"
    },
    "DUIN": {
        "id": "DUIN",
        "name": "INFINITY MFG BY DUTCHMEN MFG"
    },
    "DUKD": {
        "id": "DUKD",
        "name": "KODIAK MFG BY DUTCHMEN MFG"
    },
    "DUKE": {
        "id": "DUKE",
        "name": "DUKE MOBILE HOMES"
    },
    "DUKM": {
        "id": "DUKM",
        "name": "KOMFORT MFG BY DUTCHMEN MFG"
    },
    "DULF": {
        "id": "DULF",
        "name": "DUO-LIFT"
    },
    "DUNB": {
        "id": "DUNB",
        "name": "DUNBAR KAPPLE BATAVIA"
    },
    "DUNH": {
        "id": "DUNH",
        "name": "DUNHAM MFG."
    },
    "DUNL": {
        "id": "DUNL",
        "name": "DUNHAM LEHR"
    },
    "DUNR": {
        "id": "DUNR",
        "name": "DUN-RITE MANUFACTURING LLC DENVER"
    },
    "DUNS": {
        "id": "DUNS",
        "name": "DUNSTALL MOTORCYCLES GREAT BRITAIN"
    },
    "DUNT": {
        "id": "DUNT",
        "name": "DUNRIGHT TRAILER MFG."
    },
    "DUPA": {
        "id": "DUPA",
        "name": "DUPAGE COACH"
    },
    "DUPE": {
        "id": "DUPE",
        "name": "PERFECT COTTAGE MFG BY DUTCHMEN MFG"
    },
    "DUPL": {
        "id": "DUPL",
        "name": "DUPLEX TRUCK DIV.DIV. OF THE NOLAN"
    },
    "DUPN": {
        "id": "DUPN",
        "name": "DUPONT SERVICE CENTER"
    },
    "DUPX": {
        "id": "DUPX",
        "name": "DUPLEX MILL & MANUFACTURING"
    },
    "DURA": {
        "id": "DURA",
        "name": "DURANT"
    },
    "DURB": {
        "id": "DURB",
        "name": "DUROBILTEL MONTE"
    },
    "DURC": {
        "id": "DURC",
        "name": "DURCHOLZ TRAILER"
    },
    "DURE": {
        "id": "DURE",
        "name": "DURA-CRAFT MOBILE HOMES"
    },
    "DURH": {
        "id": "DURH",
        "name": "DURA-HAUL BRAND MFG BY PRO FAB US"
    },
    "DURI": {
        "id": "DURI",
        "name": "DURABILT INDUSTRIES"
    },
    "DURO": {
        "id": "DURO",
        "name": "DURO MOBILE HOMES"
    },
    "DURU": {
        "id": "DURU",
        "name": "RUBICON MFG BY DUTCHMEN MFG"
    },
    "DURZ": {
        "id": "DURZ",
        "name": "RAZORBACK TOY HAULER"
    },
    "DUST": {
        "id": "DUST",
        "name": "DUSTER CAMPER II"
    },
    "DUTC": {
        "id": "DUTC",
        "name": "DUTCHMEN MANUFACTURING"
    },
    "DUTE": {
        "id": "DUTE",
        "name": "DUTEC TRAILER"
    },
    "DUTT": {
        "id": "DUTT",
        "name": "DUTTON-LAINSON"
    },
    "DUVL": {
        "id": "DUVL",
        "name": "DUVALL EQUIPMENT"
    },
    "DUVO": {
        "id": "DUVO",
        "name": "VOLTAGE"
    },
    "DVGI": {
        "id": "DVGI",
        "name": "DYMAC VEHICLE GROUP & ELECTRIC VEHICLES INTERNATIONAL"
    },
    "DVLS": {
        "id": "DVLS",
        "name": "DEVEL'S TRIKE PLACE GERMANY CYCLES"
    },
    "DVLT": {
        "id": "DVLT",
        "name": "DEVAULT OF CALIFORNIA FOUNTAIN VALLEY"
    },
    "DVMF": {
        "id": "DVMF",
        "name": "DV MANUFACTURING"
    },
    "DVML": {
        "id": "DVML",
        "name": "DIVERSIFIED MINERALS INC OXNARD CA"
    },
    "DVMP": {
        "id": "DVMP",
        "name": "DIVERSIFIED MOBILE PRODUCTS"
    },
    "DWES": {
        "id": "DWES",
        "name": "DALES WELDING SALES TRUMANN AR"
    },
    "DWNS": {
        "id": "DWNS",
        "name": "DOWNS CLARK TRAILER"
    },
    "DWTE": {
        "id": "DWTE",
        "name": "DOWN 2 EARTH TRAILERS OR DOWN TO EARTH TRAILERS GEORGIA"
    },
    "DWTT": {
        "id": "DWTT",
        "name": "DE WITT ENTERPRISES INC"
    },
    "DXIE": {
        "id": "DXIE",
        "name": "DIXIE CHOPPER MOWERS"
    },
    "DYBK": {
        "id": "DYBK",
        "name": "DAYBREAK MFG BY THOR MOTOR COACH"
    },
    "DYCY": {
        "id": "DYCY",
        "name": "DYNACYCLE"
    },
    "DYEC": {
        "id": "DYEC",
        "name": "DYNASTY ELECTRIC CAR CORP."
    },
    "DYHL": {
        "id": "DYHL",
        "name": "DYNA HAULER LLC BLUFF CITY TN"
    },
    "DYMC": {
        "id": "DYMC",
        "name": "DYNAMIC INDUSTRIES"
    },
    "DYMX": {
        "id": "DYMX",
        "name": "DYNAMAX CORPORATION ELKHART"
    },
    "DYNA": {
        "id": "DYNA",
        "name": "DYNAHOE TRUCK"
    },
    "DYNC": {
        "id": "DYNC",
        "name": "DYNACRUISER MOUNT TRAILER"
    },
    "DYNE": {
        "id": "DYNE",
        "name": "DYNES ENTERPRISRS"
    },
    "DYNF": {
        "id": "DYNF",
        "name": "DYNAMITE MANUFACTURING"
    },
    "DYNG": {
        "id": "DYNG",
        "name": "DAYANG LUOYANG NORTHERN EK CHOR MOTORCYCLE CO"
    },
    "DYNH": {
        "id": "DYNH",
        "name": "DYNA-HAUL"
    },
    "DYNI": {
        "id": "DYNI",
        "name": "DYNAMIC MANUFACTURING"
    },
    "DYNM": {
        "id": "DYNM",
        "name": "DYNAMARK TRACTOR MOWER"
    },
    "DYNP": {
        "id": "DYNP",
        "name": "DYNAPAC INC SALT LAKE CITY UTAH TRAILERS"
    },
    "DYNS": {
        "id": "DYNS",
        "name": "DYNAMICS CORP. OF AMERICA"
    },
    "DYNV": {
        "id": "DYNV",
        "name": "DYNA-VAC EQUIPMENT STITTVILLE"
    },
    "DYNW": {
        "id": "DYNW",
        "name": "DYNAWELD TRAILER"
    },
    "DYST": {
        "id": "DYST",
        "name": "DYNASTAR"
    },
    "DYTC": {
        "id": "DYTC",
        "name": "DAYTEC CENTER HESPERIA"
    },
    "DYTR": {
        "id": "DYTR",
        "name": "DY TERRA CORPORATRION MANITOBA"
    },
    "EACR": {
        "id": "EACR",
        "name": "EAGLE CRUSHER"
    },
    "EACT": {
        "id": "EACT",
        "name": "EAST COAST TRAILERS BAYSHORE"
    },
    "EADU": {
        "id": "EADU",
        "name": "EAST DUMP TRAILER"
    },
    "EAGB": {
        "id": "EAGB",
        "name": "EAGER BEAVER TRAILER"
    },
    "EAGC": {
        "id": "EAGC",
        "name": "EAGLE CUSTOM COACH MFG."
    },
    "EAGE": {
        "id": "EAGE",
        "name": "EAGLE EQUIPMENT"
    },
    "EAGI": {
        "id": "EAGI",
        "name": "EAGLE INTERNATIONAL"
    },
    "EAGL": {
        "id": "EAGL",
        "name": "EAGLE TRAILER MFG."
    },
    "EAGR": {
        "id": "EAGR",
        "name": "EAGLE RIVER HOMES LLC"
    },
    "EALO": {
        "id": "EALO",
        "name": "EASY LOADER TRAILER"
    },
    "EAMM": {
        "id": "EAMM",
        "name": "EAM MANUFACTURING MIAMI FLORIDA AVIATION REFUELING EQUIPMENT"
    },
    "EARC": {
        "id": "EARC",
        "name": "EARTHCAM MOBILE SURVEILLANCE TRAILERS WIRELESS COMMUNICATIONLINKS"
    },
    "EARI": {
        "id": "EARI",
        "name": "EASY RIDER TRAILER"
    },
    "EARR": {
        "id": "EARR",
        "name": "EARTHROAMER FOUR-WHEEL DRIVE MOTORHOMES COLORADO"
    },
    "EASD": {
        "id": "EASD",
        "name": "EASTSIDE MACHINE"
    },
    "EASE": {
        "id": "EASE",
        "name": "EASTERN-BILT"
    },
    "EASI": {
        "id": "EASI",
        "name": "ENERGY ABSORPTION SYSTEMS"
    },
    "EASM": {
        "id": "EASM",
        "name": "EASOM ENGINEERING"
    },
    "EAST": {
        "id": "EAST",
        "name": "EASTON CAR & CONSTRUCTION"
    },
    "EASU": {
        "id": "EASU",
        "name": "EAST SE TRAILER"
    },
    "EASV": {
        "id": "EASV",
        "name": "EASY VEHICLE"
    },
    "EASW": {
        "id": "EASW",
        "name": "EASY TRAVELER TRAILER"
    },
    "EASY": {
        "id": "EASY",
        "name": "EASYOWN MFG."
    },
    "EATC": {
        "id": "EATC",
        "name": "EAGLE AMERICAN TRAILER COMPANY NEW MARKET AL"
    },
    "EATD": {
        "id": "EATD",
        "name": "EATON DRILLING CO"
    },
    "EATN": {
        "id": "EATN",
        "name": "EAST TENNESSEE TRAILERS LLC MOSHEIM"
    },
    "EATO": {
        "id": "EATO",
        "name": "EATON CORP.CLEVELAND"
    },
    "EATR": {
        "id": "EATR",
        "name": "EASY TRAIL"
    },
    "EATW": {
        "id": "EATW",
        "name": "EASY TOW BOAT TRAILERS"
    },
    "EATY": {
        "id": "EATY",
        "name": "EASY TOW BOAT TRAILERS"
    },
    "EBON": {
        "id": "EBON",
        "name": "EBONY LINE PRODUCTS"
    },
    "EBRG": {
        "id": "EBRG",
        "name": "E BERGMAN COMPANY"
    },
    "EBRR": {
        "id": "EBRR",
        "name": "ERIK BUELL RACING"
    },
    "EBUS": {
        "id": "EBUS",
        "name": "E BUS HYBRID & ELECTRIC SHUTTLES"
    },
    "ECBN": {
        "id": "ECBN",
        "name": "BANDIT"
    },
    "ECFC": {
        "id": "ECFC",
        "name": "ECO-FUELER CORPORATION BEND"
    },
    "ECHE": {
        "id": "ECHE",
        "name": "ECHELON MOTORCYCLES"
    },
    "ECHJ": {
        "id": "ECHJ",
        "name": "ECOJOHN"
    },
    "ECHO": {
        "id": "ECHO",
        "name": "ECHO MANUFACTURING"
    },
    "ECHT": {
        "id": "ECHT",
        "name": "ECHO TRAILERS"
    },
    "ECLI": {
        "id": "ECLI",
        "name": "ECLIPSE RECREATIONAL VEHICLES"
    },
    "ECLN": {
        "id": "ECLN",
        "name": "ECONOLINE UTILITY TRAILER TRAILERS"
    },
    "ECLP": {
        "id": "ECLP",
        "name": "ECLIPSE ALUMINUM TRAILERS"
    },
    "ECMI": {
        "id": "ECMI",
        "name": "ELECTRIC CITY MOTORS NORTH AMERICA"
    },
    "ECMY": {
        "id": "ECMY",
        "name": "ECONOMY CAMPER"
    },
    "ECNB": {
        "id": "ECNB",
        "name": "NORTH BANDIT"
    },
    "ECNG": {
        "id": "ECNG",
        "name": "NEW GENERATION MFG BY ECHO MANUFACTURING"
    },
    "ECNO": {
        "id": "ECNO",
        "name": "ECONOLINE TRAILERS"
    },
    "ECOB": {
        "id": "ECOB",
        "name": "ECO-BIKE ELEC MOTORCYCLE"
    },
    "ECOD": {
        "id": "ECOD",
        "name": "ECONOMY DRILLING SOLUTIONS OKLAHOMA"
    },
    "ECOF": {
        "id": "ECOF",
        "name": "ECONO FLO BULK SERVICE"
    },
    "ECOH": {
        "id": "ECOH",
        "name": "ECHOL TRAILERS"
    },
    "ECON": {
        "id": "ECON",
        "name": "ECONOMY-WISCONSIN"
    },
    "ECRT": {
        "id": "ECRT",
        "name": "ECART NAPLES FL"
    },
    "ECRU": {
        "id": "ECRU",
        "name": "EARTHCRUISER USA LLC BEND"
    },
    "ECSA": {
        "id": "ECSA",
        "name": "ECSA MEXI"
    },
    "ECSP": {
        "id": "ECSP",
        "name": "SPIRIT"
    },
    "ECST": {
        "id": "ECST",
        "name": "ECSTASY INC."
    },
    "ECTA": {
        "id": "ECTA",
        "name": "ECSTASY TRIKES OF ALLENTOWN"
    },
    "ECTR": {
        "id": "ECTR",
        "name": "ECONO TRAILER CLAYSBURG PA"
    },
    "EDEY": {
        "id": "EDEY",
        "name": "DOVEKIE MFG BY EDEY & DUFF"
    },
    "EDGM": {
        "id": "EDGM",
        "name": "EDGE MARINE INC ROSEBURG OR"
    },
    "EDGW": {
        "id": "EDGW",
        "name": "EDGEWOOD WELDING AND FABRICATION INC"
    },
    "EDKA": {
        "id": "EDKA",
        "name": "EDKA MFG INC GARFIELD WA"
    },
    "EDMX": {
        "id": "EDMX",
        "name": "ENDURAMAX LLC"
    },
    "EDRI": {
        "id": "EDRI",
        "name": "EDRIFT SCOOTERS BROOKLYN NY"
    },
    "EDSE": {
        "id": "EDSE",
        "name": "EDSEL"
    },
    "EDTL": {
        "id": "EDTL",
        "name": "ED TRAILERS CUAUTHEMOC MEXICO"
    },
    "EDWA": {
        "id": "EDWA",
        "name": "EDWARDS TRAILER"
    },
    "EDWN": {
        "id": "EDWN",
        "name": "EDWINS"
    },
    "EEE": {
        "id": "EEE",
        "name": "CANADIAN MOTORHOME"
    },
    "EEST": {
        "id": "EEST",
        "name": "EE SMITH TRAILER SALES"
    },
    "EEVM": {
        "id": "EEVM",
        "name": "EAGLE ELECTRIC VEHICLE MANUFACTURING"
    },
    "EEZY": {
        "id": "EEZY",
        "name": "EE-ZY TRAILER"
    },
    "EFTT": {
        "id": "EFTT",
        "name": "EAGLE FORD TANKS & TRAILERS"
    },
    "EGBD": {
        "id": "EGBD",
        "name": "EAGLE BODY INC."
    },
    "EGCT": {
        "id": "EGCT",
        "name": "EAGLE CARGO TRAILERS NICHOLLS"
    },
    "EGGC": {
        "id": "EGGC",
        "name": "EGG CAMPER SEALED FIBERGLASS JENISON AIRLEASE INC GRANDVILLE MI"
    },
    "EGHT": {
        "id": "EGHT",
        "name": "8O1 TRAILER MANUFACTURING LINDON"
    },
    "EGIL": {
        "id": "EGIL",
        "name": "EAGLE"
    },
    "EGLE": {
        "id": "EGLE",
        "name": "EAGLE"
    },
    "EGLM": {
        "id": "EGLM",
        "name": "EAGLE MANUFACTURING RATHDRUM"
    },
    "EGLR": {
        "id": "EGLR",
        "name": "EAGLEROCK TRAILERS"
    },
    "EGMT": {
        "id": "EGMT",
        "name": "YONGKANG EAGLE MOTOR"
    },
    "EGOV": {
        "id": "EGOV",
        "name": "EGO VEHICLES"
    },
    "EHMC": {
        "id": "EHMC",
        "name": "EHM MFG."
    },
    "EIDA": {
        "id": "EIDA",
        "name": "EIDAL INTERNATIONAL CORP."
    },
    "EIGH": {
        "id": "EIGH",
        "name": "EIGHT POINT"
    },
    "EJAY": {
        "id": "EJAY",
        "name": "EL-JAY DIV. IOWA MFG."
    },
    "EKBR": {
        "id": "EKBR",
        "name": "ERIK BUELL RACING"
    },
    "EKCH": {
        "id": "EKCH",
        "name": "EK-CHOR MOTORCYCLE COMPANY SHANGHAI"
    },
    "ELCA": {
        "id": "ELCA",
        "name": "ELCAR MOBILE HOMES"
    },
    "ELCO": {
        "id": "ELCO",
        "name": "ELCONA HOMES CORP"
    },
    "ELCT": {
        "id": "ELCT",
        "name": "ELECTRIC WHEEL"
    },
    "ELCY": {
        "id": "ELCY",
        "name": "ELECTRIC CYCLE"
    },
    "ELDC": {
        "id": "ELDC",
        "name": "EL DO-CRAFT BOAT"
    },
    "ELDE": {
        "id": "ELDE",
        "name": "ELDER TRAILER & BODY"
    },
    "ELDN": {
        "id": "ELDN",
        "name": "EL DORADO NATIONAL SUBSIDIARY OF THOR INDUSTRIES-VMA"
    },
    "ELDO": {
        "id": "ELDO",
        "name": "EL DORADO MFG."
    },
    "ELEC": {
        "id": "ELEC",
        "name": "ELEC-TRAC"
    },
    "ELET": {
        "id": "ELET",
        "name": "ELECTRA VACATION HOME"
    },
    "ELGI": {
        "id": "ELGI",
        "name": "ELGIN"
    },
    "ELGN": {
        "id": "ELGN",
        "name": "ELGIN SWEEPER COMPANY ELGIN"
    },
    "ELIB": {
        "id": "ELIB",
        "name": "ELI BRIDGE COMPANY JACKSONVILLE"
    },
    "ELIC": {
        "id": "ELIC",
        "name": "ELASTEC INC CARMI IL"
    },
    "ELIO": {
        "id": "ELIO",
        "name": "ELIO MOTORS PHOENIX ARIZONA 3 WHEEL VEHICLES"
    },
    "ELIT": {
        "id": "ELIT",
        "name": "ELITE MFG.CAMPER SHELLS MERCED"
    },
    "ELJA": {
        "id": "ELJA",
        "name": "ELJAY MFG."
    },
    "ELKA": {
        "id": "ELKA",
        "name": "ELK AUTOMOTIVE ELKHART"
    },
    "ELKC": {
        "id": "ELKC",
        "name": "ELKHART COACH ELKHART"
    },
    "ELKD": {
        "id": "ELKD",
        "name": "ELKHART CUSTOM DESIGN"
    },
    "ELKG": {
        "id": "ELKG",
        "name": "ELKRIDGE & ELKRIDGE EX MFG BY HEARTLAND RECREATIONAL VEHICLESS"
    },
    "ELKH": {
        "id": "ELKH",
        "name": "ELKHART MFG."
    },
    "ELKR": {
        "id": "ELKR",
        "name": "ELK RIVER CORPORATION ELKHART INDIANA VARIOUS STYLES OF TRAILERS"
    },
    "ELLE": {
        "id": "ELLE",
        "name": "ELGIN-LEACH CORP."
    },
    "ELLI": {
        "id": "ELLI",
        "name": "ELLIOTT MOBILE HOME MFG."
    },
    "ELLS": {
        "id": "ELLS",
        "name": "ELLIS TRAILER"
    },
    "ELLT": {
        "id": "ELLT",
        "name": "ELLIOTT TANKER TRAILERS"
    },
    "ELMA": {
        "id": "ELMA",
        "name": "ELLIS MFG."
    },
    "ELMP": {
        "id": "ELMP",
        "name": "ELITE METAL PERFORMANCE MOORESVILLE"
    },
    "ELMS": {
        "id": "ELMS",
        "name": "ELECTRIC MOTORSPORTS MOTORCYCLES CALIFORNIA"
    },
    "ELMT": {
        "id": "ELMT",
        "name": "ELEMENT - MFG BY EVERGREEN RECREATIONAL VEHICLES"
    },
    "ELSV": {
        "id": "ELSV",
        "name": "E AND L SERVICES"
    },
    "ELTC": {
        "id": "ELTC",
        "name": "ELETE TRAILER COMPANY OHIO - TRAILERS"
    },
    "ELTH": {
        "id": "ELTH",
        "name": "ELECTRO TECHNOLOGIES CHATTANOOGA"
    },
    "ELTM": {
        "id": "ELTM",
        "name": "ELITE TRAILER MANUFACTURING"
    },
    "ELTR": {
        "id": "ELTR",
        "name": "EL TIRON CUSTOM TRAILERS"
    },
    "ELTS": {
        "id": "ELTS",
        "name": "ELLIOTT TRAILER SALES AUSTIN"
    },
    "ELUS": {
        "id": "ELUS",
        "name": "ELUSIVE TRAILERS MCMINNVILLE"
    },
    "ELVA": {
        "id": "ELVA",
        "name": "ELVA"
    },
    "ELVC": {
        "id": "ELVC",
        "name": "ELECTRIC VEHICLE CORP MFRS REPLICAS MINNEAPOLIS"
    },
    "ELVT": {
        "id": "ELVT",
        "name": "ELECTRIC VEHICLE TECHNOLOGIES"
    },
    "ELWN": {
        "id": "ELWN",
        "name": "EASY LAWN"
    },
    "EMAM": {
        "id": "EMAM",
        "name": "EMA MOTORWORKS"
    },
    "EMAX": {
        "id": "EMAX",
        "name": "E-MAX MOTORCYCLES GGC-GLOBAL GENERATION CULT GMBH GERMANY"
    },
    "EMBA": {
        "id": "EMBA",
        "name": "EMBASSY HOMESDIV. OF GUERDON INDUSTRIES"
    },
    "EMBB": {
        "id": "EMBB",
        "name": "EMBASSY MOBILE HOMES DIV OF GUERDON IND."
    },
    "EMBM": {
        "id": "EMBM",
        "name": "EMB MFG INC ONTARIO CANADA LOG SPLITTERS AND TRAILERS"
    },
    "EMCH": {
        "id": "EMCH",
        "name": "E-1 MACHINE"
    },
    "EMCO": {
        "id": "EMCO",
        "name": "EMM"
    },
    "EMCT": {
        "id": "EMCT",
        "name": "EMPIRE CARGO TRAILERS DOUGLAS"
    },
    "EMER": {
        "id": "EMER",
        "name": "EMERSON TRAILERS"
    },
    "EMGI": {
        "id": "EMGI",
        "name": "EMBASSY GROUP INC NASHVILLE TN"
    },
    "EMME": {
        "id": "EMME",
        "name": "EMMERT MFG."
    },
    "EMMH": {
        "id": "EMMH",
        "name": "EMP MANUFACTURED HOME"
    },
    "EMML": {
        "id": "EMML",
        "name": "EML"
    },
    "EMON": {
        "id": "EMON",
        "name": "EMERGENCY ONE"
    },
    "EMOT": {
        "id": "EMOT",
        "name": "E-MOTO"
    },
    "EMPC": {
        "id": "EMPC",
        "name": "EMPIRE CORP."
    },
    "EMPG": {
        "id": "EMPG",
        "name": "EMPIRE GENERATOR CORP."
    },
    "EMPI": {
        "id": "EMPI",
        "name": "EMPIRE TRAILER"
    },
    "EMPL": {
        "id": "EMPL",
        "name": "EMPIRE PLOW"
    },
    "EMPR": {
        "id": "EMPR",
        "name": "EMPRESS INDUSTRIES"
    },
    "EMW": {
        "id": "EMW",
        "name": "EMW"
    },
    "EMWI": {
        "id": "EMWI",
        "name": "ECOSSE MOTO WORKS INC DENVER CO"
    },
    "ENAN": {
        "id": "ENAN",
        "name": "ENDERBY-ANDERSON"
    },
    "ENCO": {
        "id": "ENCO",
        "name": "ENCORE RIDING LAWNMOWERS"
    },
    "ENCR": {
        "id": "ENCR",
        "name": "ENCORE_VEHICLES"
    },
    "ENEQ": {
        "id": "ENEQ",
        "name": "ENGINE & EQUIPMENT COMPTON"
    },
    "ENER": {
        "id": "ENER",
        "name": "ENERGY MFG."
    },
    "ENFB": {
        "id": "ENFB",
        "name": "ENGLE FABRICATION"
    },
    "ENGA": {
        "id": "ENGA",
        "name": "ENGINEERED AIR"
    },
    "ENGF": {
        "id": "ENGF",
        "name": "ENGLISH FORD BRITISH"
    },
    "ENGI": {
        "id": "ENGI",
        "name": "ECONOMY GARDEN TRACTOR MFG.BY ENGINEERING PRODUCRTS"
    },
    "ENGL": {
        "id": "ENGL",
        "name": "ENGLISH INDUSTRIES"
    },
    "ENGM": {
        "id": "ENGM",
        "name": "ENGINEERED MANUFACTURING CORPORATION MIAMI"
    },
    "ENGN": {
        "id": "ENGN",
        "name": "GENERIC CODE FOR USE ONLY WHEN MANUFACTURER IS NOT LISTED"
    },
    "ENMC": {
        "id": "ENMC",
        "name": "ENCORE MOTORCYCLE"
    },
    "ENNS": {
        "id": "ENNS",
        "name": "ENNIS TRAILER MANUFACTURING TEXAS"
    },
    "ENPR": {
        "id": "ENPR",
        "name": "ENTERPRISE MOTOR HOME"
    },
    "ENRG": {
        "id": "ENRG",
        "name": "ENERGICA MOTOR COMPANY S.R.L ITALY"
    },
    "ENRT": {
        "id": "ENRT",
        "name": "EN ROUTE"
    },
    "ENSE": {
        "id": "ENSE",
        "name": "ENSENADA MOBILE HOMES"
    },
    "ENTG": {
        "id": "ENTG",
        "name": "ENTEGRA COACH INC"
    },
    "ENTI": {
        "id": "ENTI",
        "name": "ENVIRONMENTAL TECHNOLOGY INDUSTRIES"
    },
    "ENTW": {
        "id": "ENTW",
        "name": "THE ENTWISTLE COMPANY HUDSON MA"
    },
    "ENVA": {
        "id": "ENVA",
        "name": "ENVASES DE ACERO"
    },
    "ENVC": {
        "id": "ENVC",
        "name": "ENERVAC CORPORATION ONTARIO"
    },
    "ENVI": {
        "id": "ENVI",
        "name": "ENVIROTECH CORP.EIM"
    },
    "ENVO": {
        "id": "ENVO",
        "name": "ENVEMO BRAZIL REPLICA VEHICLES BUILT ON VW CHASSIS"
    },
    "ENVY": {
        "id": "ENVY",
        "name": "ENVOY"
    },
    "ENZM": {
        "id": "ENZM",
        "name": "ENZMANN"
    },
    "EONE": {
        "id": "EONE",
        "name": "E-ONE TRUCKS OCALA"
    },
    "EPAK": {
        "id": "EPAK",
        "name": "E-PAK MANUFACTURING"
    },
    "EPCC": {
        "id": "EPCC",
        "name": "ERWIN PRECISION CUSTOM CYCLES AKA EPI CUSTOM CYCLES"
    },
    "EPCO": {
        "id": "EPCO",
        "name": "EVANS PRODUCTS COMPANY MFG MONON TRAILERS MONON IN"
    },
    "EPEC": {
        "id": "EPEC",
        "name": "EAST POINT EQUIPTMENT COMPANY ATLANTA GA"
    },
    "EPOK": {
        "id": "EPOK",
        "name": "EPOKE NORTH AMERICA"
    },
    "EPTL": {
        "id": "EPTL",
        "name": "ESCAPOD TRAILERS LLC WANSHIP UT"
    },
    "EPUM": {
        "id": "EPUM",
        "name": "EL PUMA S.A. DE C.V. OR SEMIREMOLQUES EL PUMA S.A. DE C.V. MEXICO"
    },
    "EQIP": {
        "id": "EQIP",
        "name": "EQUIPOS INDUSTRIALES SA DE CV EMPRESAS Y EQUIPOS INDUSTRIALES SA DE CV"
    },
    "EQPR": {
        "id": "EQPR",
        "name": "EQUIPMENT PRO ALSO KNOWN AS MILRON COMPANY INC"
    },
    "EQPS": {
        "id": "EQPS",
        "name": "EQUIPMENT SOURCE"
    },
    "EQPT": {
        "id": "EQPT",
        "name": "EQUIPT TRAILERS"
    },
    "EQSP": {
        "id": "EQSP",
        "name": "EQUISPIRIT TRAILER COMPANY HORSE TRAILERS"
    },
    "EQST": {
        "id": "EQST",
        "name": "EQUEST TRAILERS PARENT COMPANY - SPECIALITY STEEL INC CANADA"
    },
    "EQTK": {
        "id": "EQTK",
        "name": "EQUI-TREK"
    },
    "EQUP": {
        "id": "EQUP",
        "name": "EQUIPTER LEOLA PA TRAILER MOUNTED LIFTING EQUIPMENT"
    },
    "ERAP": {
        "id": "ERAP",
        "name": "ERIE ALUMINUM PRODUCTS NORTH EAST PA"
    },
    "ERCE": {
        "id": "ERCE",
        "name": "ERIC ENTERPRISES"
    },
    "EREK": {
        "id": "EREK",
        "name": "ERECTORS & FABRICATORS"
    },
    "ERIC": {
        "id": "ERIC",
        "name": "ERICKSON CORP."
    },
    "ERID": {
        "id": "ERID",
        "name": "E RIDE INDUSTRIES MINNESOTA EXV2 & EXV4 MODELS"
    },
    "ERIE": {
        "id": "ERIE",
        "name": "ERIE CITY WELDING & SPRING"
    },
    "ERIG": {
        "id": "ERIG",
        "name": "ERIE-GO MFG."
    },
    "ERIN": {
        "id": "ERIN",
        "name": "ERIN TRUCK AND BODY EQUIPMENT DOLTON"
    },
    "ERIS": {
        "id": "ERIS",
        "name": "ERIE-SPRAYER"
    },
    "ERMC": {
        "id": "ERMC",
        "name": "ELK RIVER MACHINE"
    },
    "ERRA": {
        "id": "ERRA",
        "name": "ERA REPLICA AUTOMOBILES NEW BRITAIN"
    },
    "ERSK": {
        "id": "ERSK",
        "name": "ERSKINE"
    },
    "ERSS": {
        "id": "ERSS",
        "name": "ERSKINE & SONS"
    },
    "ERTH": {
        "id": "ERTH",
        "name": "EARTHBOUND RV CORP OR EARTHBOUND LLC"
    },
    "ERTL": {
        "id": "ERTL",
        "name": "E AND R TRAILERS MALIN OR"
    },
    "ERWN": {
        "id": "ERWN",
        "name": "ERWIN MANUFACTURING AZLE"
    },
    "ESCA": {
        "id": "ESCA",
        "name": "ESCAPE TRAILER"
    },
    "ESCC": {
        "id": "ESCC",
        "name": "ES"
    },
    "ESCH": {
        "id": "ESCH",
        "name": "EAST SIDE CUSTOM CHOPPERS LAUDERDALE LAKES FL"
    },
    "ESCO": {
        "id": "ESCO",
        "name": "EX"
    },
    "ESCP": {
        "id": "ESCP",
        "name": "ESCAPADE MOTOR HOME"
    },
    "ESCT": {
        "id": "ESCT",
        "name": "ESCORT TRAILER CORP.SEATTLE"
    },
    "ESEM": {
        "id": "ESEM",
        "name": "ESE MACHINES"
    },
    "ESGO": {
        "id": "ESGO",
        "name": "EASY GO LSV LLC SANTA ROSA BEACH FL"
    },
    "ESHL": {
        "id": "ESHL",
        "name": "ESHELMAN SPORTABOUT"
    },
    "ESIX": {
        "id": "ESIX",
        "name": "ESSIX"
    },
    "ESLY": {
        "id": "ESLY",
        "name": "EASLEY TRAILER MANUFACTURING"
    },
    "ESMC": {
        "id": "ESMC",
        "name": "EAST MANUFACTURING CORP."
    },
    "ESPD": {
        "id": "ESPD",
        "name": "INDUSTRIES ESPADON"
    },
    "ESQU": {
        "id": "ESQU",
        "name": "ESQUIRE INC"
    },
    "ESRV": {
        "id": "ESRV",
        "name": "ESCAPE RV RICE LAKE WI - PRIVATE PROPERTIES LLC"
    },
    "ESSE": {
        "id": "ESSE",
        "name": "ESSEX"
    },
    "ESSI": {
        "id": "ESSI",
        "name": "ESSICK"
    },
    "ESSK": {
        "id": "ESSK",
        "name": "ESSICK TRAILER"
    },
    "ESSX": {
        "id": "ESSX",
        "name": "ESSEX MFG BY NEWMAR CORP"
    },
    "ESTA": {
        "id": "ESTA",
        "name": "ESTABLISHMENT"
    },
    "ESTE": {
        "id": "ESTE",
        "name": "ESTEVAN INDUSTRIES"
    },
    "ESTI": {
        "id": "ESTI",
        "name": "ESCAPADE TRAILER"
    },
    "ESTM": {
        "id": "ESTM",
        "name": "ESTATE MFG."
    },
    "ESTT": {
        "id": "ESTT",
        "name": "ESTATE MFG BY ATHENS PARK HOMES"
    },
    "ESVE": {
        "id": "ESVE",
        "name": "ESVE SPECIAL TRAILERS"
    },
    "ESYT": {
        "id": "ESYT",
        "name": "EASY TRIKE GMBH"
    },
    "ETCH": {
        "id": "ETCH",
        "name": "EASTERN TECHNOLOGIES"
    },
    "ETCO": {
        "id": "ETCO",
        "name": "EARTH TOOL COMPANY LLC LAKE MILLS WI"
    },
    "ETLL": {
        "id": "ETLL",
        "name": "EXTREME TRAILERS LLC DOVER OH"
    },
    "ETLM": {
        "id": "ETLM",
        "name": "ERETZ TRAILERS MANUFACTURER RIALTO CA"
    },
    "ETNY": {
        "id": "ETNY",
        "name": "E. D. ETNYRE & COMPANYOREGON"
    },
    "ETON": {
        "id": "ETON",
        "name": "E-TON DYNAMICS TECHNOLOGY INDUSTRY"
    },
    "ETSL": {
        "id": "ETSL",
        "name": "ENTERPRISING SOLUTIONS RAINER OR"
    },
    "ETTX": {
        "id": "ETTX",
        "name": "EAST TEXAS TRAILERS DIV OF KLIPPENSTEIN TRAILER SALES INC PETTY TX"
    },
    "ETUK": {
        "id": "ETUK",
        "name": "ETUK USA LLC BROOMFIELD CO"
    },
    "ETXL": {
        "id": "ETXL",
        "name": "EAST TEXAS LONGHORN TRAILERS LLC EMORY"
    },
    "EUCL": {
        "id": "EUCL",
        "name": "EUCLID"
    },
    "EURO": {
        "id": "EURO",
        "name": "EURO E.A. ALUMINIO"
    },
    "EVAC": {
        "id": "EVAC",
        "name": "EVA"
    },
    "EVAG": {
        "id": "EVAG",
        "name": "ALFA GOLD"
    },
    "EVAM": {
        "id": "EVAM",
        "name": "AMPED MFG BY EVERGREEN RV"
    },
    "EVAN": {
        "id": "EVAN",
        "name": "EVANS MFG."
    },
    "EVAP": {
        "id": "EVAP",
        "name": "EVANS-PLUGGE"
    },
    "EVAS": {
        "id": "EVAS",
        "name": "EVANSTON COACH"
    },
    "EVBH": {
        "id": "EVBH",
        "name": "BAY HILL MFG BY EVERGREEN RECREATIONAL VEHICLES"
    },
    "EVCR": {
        "id": "EVCR",
        "name": "EVCOR"
    },
    "EVEN": {
        "id": "EVEN",
        "name": "EVENS TRAILER MFG."
    },
    "EVER": {
        "id": "EVER",
        "name": "EVERGREEN LOG TRAILER"
    },
    "EVES": {
        "id": "EVES",
        "name": "EVERSMAN MFG."
    },
    "EVGR": {
        "id": "EVGR",
        "name": "EVERGREEN RECREATIONAL VEHICLES"
    },
    "EVIL": {
        "id": "EVIL",
        "name": "ECOVERSE INDUSTRIES INC AVON OH"
    },
    "EVIN": {
        "id": "EVIN",
        "name": "EVINRUDE MOTORS"
    },
    "EVLF": {
        "id": "EVLF",
        "name": "LIFESTYLE MFG BY EVERGREEN RECREATIONAL VEHICLES"
    },
    "EVLT": {
        "id": "EVLT",
        "name": "EVERLITE"
    },
    "EVLV": {
        "id": "EVLV",
        "name": "EVOLVE ELECTRIC MOTORCYCLES OR EVOLVE MOTORCYCLES"
    },
    "EVNI": {
        "id": "EVNI",
        "name": "ELECTRIC VEHICLES NORTHWEST"
    },
    "EVNS": {
        "id": "EVNS",
        "name": "EVANS AUTOMOBILES SCOTTDALE"
    },
    "EVOB": {
        "id": "EVOB",
        "name": "EVOBUS GMBH GERMANY"
    },
    "EVRC": {
        "id": "EVRC",
        "name": "REACTOR MODEL"
    },
    "EVRT": {
        "id": "EVRT",
        "name": "EVERETT-MORRISON"
    },
    "EVRY": {
        "id": "EVRY",
        "name": "EVERYBODY'S MOTOR CAR MFG."
    },
    "EVSE": {
        "id": "EVSE",
        "name": "EVANS STEEL ENTERPRISES INC GRIFFIN GA"
    },
    "EVSI": {
        "id": "EVSI",
        "name": "ELECTRIC VEHICLE SYSTEMS WORCESTER"
    },
    "EVSV": {
        "id": "EVSV",
        "name": "SUN VALLEY MFG BY EVERGREEN RECREATIONAL VEHICLES"
    },
    "EVTE": {
        "id": "EVTE",
        "name": "TESLA MFG BY EVERGREEN RV"
    },
    "EVTL": {
        "id": "EVTL",
        "name": "EVOLUTION TRAILER TECHNOLOGIES"
    },
    "EVTT": {
        "id": "EVTT",
        "name": "EVT TECHNOLOGY"
    },
    "EVTX": {
        "id": "EVTX",
        "name": "ELECTRIC VEHICLES OF TEXAS"
    },
    "EVYM": {
        "id": "EVYM",
        "name": "EVERYTHING MARINE USA MIAMI"
    },
    "EWAL": {
        "id": "EWAL",
        "name": "RED EWALD"
    },
    "EWTL": {
        "id": "EWTL",
        "name": "EASYWAY TRAILERS INC DOVER FL"
    },
    "EXAI": {
        "id": "EXAI",
        "name": "EXA INDUSTRIAL S.A. DE C.V. MEXICO"
    },
    "EXC": {
        "id": "EXC",
        "name": "EXCEL MOTOR HOME TRUCK"
    },
    "EXCE": {
        "id": "EXCE",
        "name": "EXCEL WINSLOW"
    },
    "EXCH": {
        "id": "EXCH",
        "name": "EXOTIC CHOPPERS"
    },
    "EXCI": {
        "id": "EXCI",
        "name": "EXCEL INDUSTRIES"
    },
    "EXCL": {
        "id": "EXCL",
        "name": "EXCALIBUR"
    },
    "EXCN": {
        "id": "EXCN",
        "name": "EXCELLANCE"
    },
    "EXCR": {
        "id": "EXCR",
        "name": "EXCELSIOR"
    },
    "EXCY": {
        "id": "EXCY",
        "name": "EXTREME CYCLES"
    },
    "EXEC": {
        "id": "EXEC",
        "name": "EXECUTIVE INDUSTRIES"
    },
    "EXEN": {
        "id": "EXEN",
        "name": "EXOSENT ENGINEERING"
    },
    "EXEZ": {
        "id": "EXEZ",
        "name": "EXCEL EZLOAD TRAILERS"
    },
    "EXHE": {
        "id": "EXHE",
        "name": "EXCELSIOR-HENDERSON"
    },
    "EXIS": {
        "id": "EXIS",
        "name": "EXISS ALUMINAM TRAILERS"
    },
    "EXMI": {
        "id": "EXMI",
        "name": "MILES TRAILERS EL RENO"
    },
    "EXMW": {
        "id": "EXMW",
        "name": "EXPERT MARINE WELDING MELBOURNE"
    },
    "EXPD": {
        "id": "EXPD",
        "name": "EXPEDITION MOTORHOMES MANUFACTURED BY FLEETWOOD"
    },
    "EXPF": {
        "id": "EXPF",
        "name": "EXTREME PERFORMANCE SAN BERNARDINO CA"
    },
    "EXPL": {
        "id": "EXPL",
        "name": "EXPLORER MFG BY KIBBI"
    },
    "EXPR": {
        "id": "EXPR",
        "name": "EXPRESS TRAILERS FLORIDA"
    },
    "EXPT": {
        "id": "EXPT",
        "name": "EXPEDITION TRAILERS"
    },
    "EXPW": {
        "id": "EXPW",
        "name": "EXPRESS AKA-EXPRESS WELDING"
    },
    "EXRV": {
        "id": "EXRV",
        "name": "EXTREME RV'S"
    },
    "EXSO": {
        "id": "EXSO",
        "name": "SOONER TRAILER"
    },
    "EXTL": {
        "id": "EXTL",
        "name": "EXTEND-ELL INDUSTRIES"
    },
    "EXTM": {
        "id": "EXTM",
        "name": "EXTREME MAKEOVERS & RV REPAIR HEMET"
    },
    "EXTO": {
        "id": "EXTO",
        "name": "E Z TOW MANUFACTURING"
    },
    "EXTR": {
        "id": "EXTR",
        "name": "EXTREME CUSTOM TRAILERS"
    },
    "EXTV": {
        "id": "EXTV",
        "name": "EXECUTIVE MFG BY KROPF INDUSTRIES"
    },
    "EXTX": {
        "id": "EXTX",
        "name": "EXOTIX CYCLE & MOTOR WERX"
    },
    "EYEB": {
        "id": "EYEB",
        "name": "EYEBALL ENGINEERING SPRING VALLEY NY"
    },
    "EYER": {
        "id": "EYER",
        "name": "EYERLY OREGON TRAILER MOUNTED AMUSEMENT RIDES"
    },
    "EYML": {
        "id": "EYML",
        "name": "ELSEY MFG LLC MINNEOLA KS"
    },
    "EZAC": {
        "id": "EZAC",
        "name": "E.Z.A. MFG."
    },
    "EZCK": {
        "id": "EZCK",
        "name": "E-Z CAMPER OR KAMPER"
    },
    "EZCN": {
        "id": "EZCN",
        "name": "E-Z SCREEN"
    },
    "EZDU": {
        "id": "EZDU",
        "name": "E Z DUMPER TRAILERS WAYNESBORO"
    },
    "EZGO": {
        "id": "EZGO",
        "name": "E-Z GO"
    },
    "EZHA": {
        "id": "EZHA",
        "name": "E Z HAUL"
    },
    "EZLB": {
        "id": "EZLB",
        "name": "EZ LOADER CUSTOM BOAT TRAILERS INC MIDWAY AR"
    },
    "EZLD": {
        "id": "EZLD",
        "name": "E-Z LOADER BOAT TRAILER MIDWAY"
    },
    "EZSP": {
        "id": "EZSP",
        "name": "E-Z SPORTSMEN"
    },
    "EZTC": {
        "id": "EZTC",
        "name": "EZ TRAC TRAILERS"
    },
    "EZTD": {
        "id": "EZTD",
        "name": "EZ TRADE TRAILERS"
    },
    "EZTL": {
        "id": "EZTL",
        "name": "E-Z TRAIL"
    },
    "EZTT": {
        "id": "EZTT",
        "name": "EZ-TRAIL TRAILERS"
    },
    "EZUT": {
        "id": "EZUT",
        "name": "E-Z UTILITY TRAILERMFD. BY DUTCHIE"
    },
    "EZZN": {
        "id": "EZZN",
        "name": "EEZZZZ-ON"
    },
    "EZZY": {
        "id": "EZZY",
        "name": "EZZY LOAD BOAT TRAILER"
    },
    "FAAL": {
        "id": "FAAL",
        "name": "FABRICATED ALLOY BUILDING"
    },
    "FABC": {
        "id": "FABC",
        "name": "F.A.B. MFG."
    },
    "FABF": {
        "id": "FABF",
        "name": "FABFORM INDUSTRIES"
    },
    "FABO": {
        "id": "FABO",
        "name": "FAB"
    },
    "FABW": {
        "id": "FABW",
        "name": "FABWELD"
    },
    "FACC": {
        "id": "FACC",
        "name": "FAN COACH COMPANY MERGED WITH COACHMEN INDUSTRIES IN 1978"
    },
    "FACE": {
        "id": "FACE",
        "name": "FACEL-VEGA"
    },
    "FACL": {
        "id": "FACL",
        "name": "FACELLIA"
    },
    "FACT": {
        "id": "FACT",
        "name": "FACTORY OUTLET TRAILERS"
    },
    "FACY": {
        "id": "FACY",
        "name": "FACTORY TRANSPORTS INCORPORATED TERRELL"
    },
    "FAEG": {
        "id": "FAEG",
        "name": "AMERICAN EAGLE MFG BY FLEETWOOD RV"
    },
    "FAHT": {
        "id": "FAHT",
        "name": "AMERICAN HERITAGE MFG BY FLEETWOOD RV"
    },
    "FAIH": {
        "id": "FAIH",
        "name": "COLONNADE MOBILE HOMES MFG BY FAIRMONT HOMES"
    },
    "FAIM": {
        "id": "FAIM",
        "name": "FAIRMONT STEEL PRODUCTS"
    },
    "FAIR": {
        "id": "FAIR",
        "name": "FAIRTHORPE"
    },
    "FAJU": {
        "id": "FAJU",
        "name": "FAJUME S.A. DE C.V. TRAILERS"
    },
    "FALC": {
        "id": "FALC",
        "name": "FALCON BRITISH"
    },
    "FALL": {
        "id": "FALL",
        "name": "FALLSRIDING LAWN MOWER"
    },
    "FALM": {
        "id": "FALM",
        "name": "FAL"
    },
    "FALN": {
        "id": "FALN",
        "name": "FALCON COACH"
    },
    "FALP": {
        "id": "FALP",
        "name": "F A L PRODUCTS CORP. TACOMA"
    },
    "FAMI": {
        "id": "FAMI",
        "name": "FOUNDATION AMBULANCE INC FAYETTEVILLE GA"
    },
    "FAN": {
        "id": "FAN",
        "name": "FANC MOTOR HOME TRUCK"
    },
    "FANN": {
        "id": "FANN",
        "name": "FANNIN FABRICATION COMPANY"
    },
    "FARB": {
        "id": "FARB",
        "name": "FARBER SPECIALITY VEHICLES BUSES"
    },
    "FARG": {
        "id": "FARG",
        "name": "FARGO"
    },
    "FARH": {
        "id": "FARH",
        "name": "FARMHAND"
    },
    "FARI": {
        "id": "FARI",
        "name": "FARIA'S TRAILERS"
    },
    "FARK": {
        "id": "FARK",
        "name": "FARM KING"
    },
    "FARM": {
        "id": "FARM",
        "name": "UNPUBLISHED FARM OR GARDEN EQUIPMENT MFR"
    },
    "FART": {
        "id": "FART",
        "name": "FARM TOUCH"
    },
    "FARV": {
        "id": "FARV",
        "name": "AMERICAN REVOLUTION MFG BY FLEETWOOD RV"
    },
    "FARW": {
        "id": "FARW",
        "name": "FARENWALD BOAT TRAILER"
    },
    "FAST": {
        "id": "FAST",
        "name": "FAST SNOWMOBILES TO INCLUDE BLADE MODEL"
    },
    "FATB": {
        "id": "FATB",
        "name": "FATBOY"
    },
    "FATC": {
        "id": "FATC",
        "name": "FAT CITY CHOPPERS MIDDLETOWN"
    },
    "FATD": {
        "id": "FATD",
        "name": "AMERICAN TRADITION MFG BY FLEETWOOD RV"
    },
    "FAUT": {
        "id": "FAUT",
        "name": "FAUTRAS HORSE TRAILERS OR JLFD PRODUCTION FRANCE TRAILERS"
    },
    "FAWN": {
        "id": "FAWN",
        "name": "FAWN CORP."
    },
    "FAYE": {
        "id": "FAYE",
        "name": "FAYETTE TRAILER"
    },
    "FAYM": {
        "id": "FAYM",
        "name": "FAYMONVILLE DISTRIBUTION BELGIUM"
    },
    "FBCI": {
        "id": "FBCI",
        "name": "FREEBORN CUSTOMS INC CUMMING GA"
    },
    "FBIM": {
        "id": "FBIM",
        "name": "FBI MOTOR COMPANY OR FAT BAGGERS INC"
    },
    "FBLC": {
        "id": "FBLC",
        "name": "FATHOM BOATS LLC EAST MORICHES NY"
    },
    "FBND": {
        "id": "FBND",
        "name": "BOUNDER & BOUNDER CLASSIC MFG BY FLEETWOOD RV INC"
    },
    "FBST": {
        "id": "FBST",
        "name": "FIREBLAST 451 INC OR FIREBLAST CORONA"
    },
    "FCCC": {
        "id": "FCCC",
        "name": "FREIGHTLINER CUSTOM CHASSIS CORP GAFFNEY SC"
    },
    "FCCO": {
        "id": "FCCO",
        "name": "FOOD CONCEPT COMPANY NAMPA ID"
    },
    "FDLY": {
        "id": "FDLY",
        "name": "FIDELITY MANUFACTURING"
    },
    "FDRI": {
        "id": "FDRI",
        "name": "FORSYTHE AND DOWIS RIDES INC MERINO CO"
    },
    "FDSV": {
        "id": "FDSV",
        "name": "DISCOVERY MFG BY FLEETWOOD RV INC"
    },
    "FECH": {
        "id": "FECH",
        "name": "FECHTNER TRAILERS"
    },
    "FEDC": {
        "id": "FEDC",
        "name": "FEDERAL COACH LLC FORT SMITH ARKANSAS FUNERAL VEHICLES"
    },
    "FEDE": {
        "id": "FEDE",
        "name": "FEDERAL TRAILER"
    },
    "FEDL": {
        "id": "FEDL",
        "name": "FEDERAL"
    },
    "FEHR": {
        "id": "FEHR",
        "name": "KATHARINA LOEWEN LOEWEN"
    },
    "FEIL": {
        "id": "FEIL",
        "name": "FEILONG MOTOR MACHINERY"
    },
    "FEIS": {
        "id": "FEIS",
        "name": "FEISHEN VEHICLE INDUSTRY"
    },
    "FELB": {
        "id": "FELB",
        "name": "FELBER"
    },
    "FELD": {
        "id": "FELD",
        "name": "FELDMAN CUSTOM TRAILERSBOWLING GREEN"
    },
    "FELL": {
        "id": "FELL",
        "name": "FELLWON TRAILER"
    },
    "FELP": {
        "id": "FELP",
        "name": "FELPS MFG. CORP.JOHNSON CITY"
    },
    "FELR": {
        "id": "FELR",
        "name": "DL FELLER TRUCKING"
    },
    "FEMC": {
        "id": "FEMC",
        "name": "FELDMAN ENGINEERING & MFG."
    },
    "FENX": {
        "id": "FENX",
        "name": "TEAM FENEX FENEX DIVISION OF LEGGETT AND PRATT ILLINOIS"
    },
    "FERE": {
        "id": "FERE",
        "name": "FERREE TRAILER CORP.CLIMAX"
    },
    "FERG": {
        "id": "FERG",
        "name": "FERGUSON MFG."
    },
    "FERO": {
        "id": "FERO",
        "name": "FEROCITY INDUSTRIES ELKHART"
    },
    "FERR": {
        "id": "FERR",
        "name": "FERRARI"
    },
    "FERT": {
        "id": "FERT",
        "name": "FERTILIZER DEALER SUPPLY"
    },
    "FERV": {
        "id": "FERV",
        "name": "FERVES ITALY"
    },
    "FEST": {
        "id": "FEST",
        "name": "FESTIVAL HOMES OF OHIO"
    },
    "FETE": {
        "id": "FETE",
        "name": "FETERL MFG."
    },
    "FEXC": {
        "id": "FEXC",
        "name": "EXCURSION MFG BY FLEETWOOD RV INC"
    },
    "FEXP": {
        "id": "FEXP",
        "name": "EXPEDITION MFG BY FLEETWOOD RV INC"
    },
    "FEYF": {
        "id": "FEYF",
        "name": "FEY"
    },
    "FFMI": {
        "id": "FFMI",
        "name": "FRYFOGLE MANUFACTURING INC"
    },
    "FFRI": {
        "id": "FFRI",
        "name": "FACTORY FIVE RACING INC. WAREHAM"
    },
    "FGBX": {
        "id": "FGBX",
        "name": "GEARBOX RV MFG BY FLEETWOOD ENTERPRISES INC"
    },
    "FHHD": {
        "id": "FHHD",
        "name": "FISHING HOLDINGS LLC FLIPPIN AR"
    },
    "FHL": {
        "id": "FHL",
        "name": "FHL"
    },
    "FHMC": {
        "id": "FHMC",
        "name": "F & H MFG."
    },
    "FIAA": {
        "id": "FIAA",
        "name": "FIAT ABARTH"
    },
    "FIAC": {
        "id": "FIAC",
        "name": "FIELDACRE HORSE TRAILER"
    },
    "FIAL": {
        "id": "FIAL",
        "name": "FIAT-ALLIS CONSTRUCTION MACHINERY"
    },
    "FIAT": {
        "id": "FIAT",
        "name": "FIAT PART"
    },
    "FIBA": {
        "id": "FIBA",
        "name": "FIBA SALES"
    },
    "FIBE": {
        "id": "FIBE",
        "name": "FIBERFAB"
    },
    "FIBI": {
        "id": "FIBI",
        "name": "FIBERGLASS INTERNATIONAL"
    },
    "FIBL": {
        "id": "FIBL",
        "name": "FIBER-LITE CORP. ELKHART"
    },
    "FIBR": {
        "id": "FIBR",
        "name": "FIBER TRUCKS & TRAILERS"
    },
    "FICH": {
        "id": "FICH",
        "name": "FISCHER MOTOR COMPANY"
    },
    "FIEA": {
        "id": "FIEA",
        "name": "ALEX FIEGELSON"
    },
    "FIED": {
        "id": "FIED",
        "name": "FIELD OFFICE MFG."
    },
    "FIEL": {
        "id": "FIEL",
        "name": "FIELD & STREAM MANUFACTURER VACATION INDUSTRIES"
    },
    "FIEM": {
        "id": "FIEM",
        "name": "FIELDS MFG."
    },
    "FIES": {
        "id": "FIES",
        "name": "FIESTA"
    },
    "FIFE": {
        "id": "FIFE",
        "name": "FIFE TRAILER"
    },
    "FIFT": {
        "id": "FIFT",
        "name": "58TH STREET CUSTOMS FIFTYEIGHTH"
    },
    "FIGU": {
        "id": "FIGU",
        "name": "FIGURA"
    },
    "FIIR": {
        "id": "FIIR",
        "name": "FII ROADSTERS"
    },
    "FIKO": {
        "id": "FIKO",
        "name": "FIAT KOBELCO CONSTRUCTION EQUIPMENT"
    },
    "FILK": {
        "id": "FILK",
        "name": "FINGER LAKES TRAILER"
    },
    "FIND": {
        "id": "FIND",
        "name": "FARM & INDUSTRIAL SVC."
    },
    "FINL": {
        "id": "FINL",
        "name": "FINELINE TRAILERS SENECA"
    },
    "FINN": {
        "id": "FINN",
        "name": "FINN CORPORATION FAIRFIELD"
    },
    "FIRB": {
        "id": "FIRB",
        "name": "FIREBALL TRAILER MFG."
    },
    "FIRE": {
        "id": "FIRE",
        "name": "FIRESTONE TRACTOR"
    },
    "FIRN": {
        "id": "FIRN",
        "name": "FIRAN MOTORCOACH"
    },
    "FIRS": {
        "id": "FIRS",
        "name": "FIRST AMERICAN COACH"
    },
    "FIRT": {
        "id": "FIRT",
        "name": "AIR BUOYBOAT TRAILERS MFG. BY FIRESTONE TIRE & RUBBER PRODUCTS"
    },
    "FIRV": {
        "id": "FIRV",
        "name": "FIRE VENT"
    },
    "FISC": {
        "id": "FISC",
        "name": "FISCHER"
    },
    "FISD": {
        "id": "FISD",
        "name": "FIRESIDE RECREATIONAL VEHICLES BRISTOL"
    },
    "FISK": {
        "id": "FISK",
        "name": "FISK TANK CARRIER"
    },
    "FIST": {
        "id": "FIST",
        "name": "FIESTA"
    },
    "FIVA": {
        "id": "FIVA",
        "name": "FIVE A MANUFACTURING"
    },
    "FIVE": {
        "id": "FIVE",
        "name": "FIVE STAR ENGINEERING"
    },
    "FIVK": {
        "id": "FIVK",
        "name": "FIVE-K TRAILER"
    },
    "FIVT": {
        "id": "FIVT",
        "name": "FIVE STAR TRAILERS INC FRESNO CA"
    },
    "FJBR": {
        "id": "FJBR",
        "name": "JAMBOREE"
    },
    "FKWA": {
        "id": "FKWA",
        "name": "FURUKAWA CONSTRUCTION EQUIPMENT-LOADERS"
    },
    "FLAC": {
        "id": "FLAC",
        "name": "AMADAS COACH MFG BY FEATHERLITE COACHES"
    },
    "FLAG": {
        "id": "FLAG",
        "name": "FLAG SHIP BOAT TRAILER"
    },
    "FLAH": {
        "id": "FLAH",
        "name": "FLAHERTY MANUFACTURING"
    },
    "FLAI": {
        "id": "FLAI",
        "name": "FLAIR INDUSTRIES OF ILLINOIS"
    },
    "FLAM": {
        "id": "FLAM",
        "name": "FLAMINGO TRAILER MFG."
    },
    "FLAN": {
        "id": "FLAN",
        "name": "A.CLAEYS FLANDRIA BELGIUM"
    },
    "FLAS": {
        "id": "FLAS",
        "name": "FLASKO MFG."
    },
    "FLAT": {
        "id": "FLAT",
        "name": "FLAT CREEK LODGES"
    },
    "FLBR": {
        "id": "FLBR",
        "name": "FELBURN FLATBED TRAILER"
    },
    "FLCA": {
        "id": "FLCA",
        "name": "FALCAN INDUSTRIES LTD. ALBERTA"
    },
    "FLCN": {
        "id": "FLCN",
        "name": "FALCON ROAD MAINTENANCE EQUIPMENT"
    },
    "FLE": {
        "id": "FLE",
        "name": "FLEET CAP'N TRAILERS"
    },
    "FLEA": {
        "id": "FLEA",
        "name": "FLEET-AIRE CAMPER"
    },
    "FLEE": {
        "id": "FLEE",
        "name": "FLEETCRAFT CORP."
    },
    "FLEI": {
        "id": "FLEI",
        "name": "FLEXI-COIL LTD."
    },
    "FLEM": {
        "id": "FLEM",
        "name": "FLEETWING MOBILE HOMES"
    },
    "FLEN": {
        "id": "FLEN",
        "name": "FLEMING MFG."
    },
    "FLEO": {
        "id": "FLEO",
        "name": "BIG BEAR MFD. BY FLEXO PRODUCTS CO"
    },
    "FLEU": {
        "id": "FLEU",
        "name": "FLEURY INDUSTRIES"
    },
    "FLEX": {
        "id": "FLEX",
        "name": "FLEXIBLE"
    },
    "FLHF": {
        "id": "FLHF",
        "name": "FLAMING HACKSAW FABRICATION"
    },
    "FLIN": {
        "id": "FLIN",
        "name": "FLINTSTONE INDUSTRIESOCILLA"
    },
    "FLMG": {
        "id": "FLMG",
        "name": "FLEMING EQUIPMENT"
    },
    "FLMR": {
        "id": "FLMR",
        "name": "FIRST LINE REFINISHING CORINTH"
    },
    "FLNG": {
        "id": "FLNG",
        "name": "SAUK CENTRE WELDING & MACHINE WORKS"
    },
    "FLOA": {
        "id": "FLOA",
        "name": "FLOAT-ON OF FLORIDA BOAT TRAILERS FT. PIERCE"
    },
    "FLOD": {
        "id": "FLOD",
        "name": "FLORIDA TRAILER"
    },
    "FLOE": {
        "id": "FLOE",
        "name": "FLOE INTERNATIONAL"
    },
    "FLOI": {
        "id": "FLOI",
        "name": "FLORIDA WHOLESALE DISTRIBUTOR"
    },
    "FLOR": {
        "id": "FLOR",
        "name": "FLORIG EQUIPMENT"
    },
    "FLOT": {
        "id": "FLOT",
        "name": "FLOTE-AIRE CORP."
    },
    "FLOW": {
        "id": "FLOW",
        "name": "FLOW BOY"
    },
    "FLRV": {
        "id": "FLRV",
        "name": "FLEET RV SALES"
    },
    "FLTE": {
        "id": "FLTE",
        "name": "FEATHERLITE COACHES OR COACH"
    },
    "FLTI": {
        "id": "FLTI",
        "name": "FLEMING TRAILERS INC GLENDALE AZ"
    },
    "FLTW": {
        "id": "FLTW",
        "name": "FALCON TRAILER WORKS"
    },
    "FLVN": {
        "id": "FLVN",
        "name": "VANTARE MODEL"
    },
    "FLYB": {
        "id": "FLYB",
        "name": "FLYBO OR FLY BO NEV-NIEGHBORHOOD ELECTRIC VEHICLE) CHINA"
    },
    "FLYI": {
        "id": "FLYI",
        "name": "FLYING DUTCHMAN"
    },
    "FLYL": {
        "id": "FLYL",
        "name": "FLYING L TRAILER"
    },
    "FLYR": {
        "id": "FLYR",
        "name": "FLYRITE CHOPPERS"
    },
    "FLYT": {
        "id": "FLYT",
        "name": "FLYTE CAMP"
    },
    "FMC": {
        "id": "FMC",
        "name": "FMC CORP. FN FN"
    },
    "FMCO": {
        "id": "FMCO",
        "name": "FIM"
    },
    "FMMI": {
        "id": "FMMI",
        "name": "FM MANUFACTURING INC ARCHBOLD OH"
    },
    "FMSH": {
        "id": "FMSH",
        "name": "FARM SHOP"
    },
    "FMST": {
        "id": "FMST",
        "name": "FAST MASTER PRODUCTS"
    },
    "FNLN": {
        "id": "FNLN",
        "name": "FINISH LINE TRAILERS RED OAK MFG. RED OAK"
    },
    "FNM": {
        "id": "FNM",
        "name": "FNM"
    },
    "FNTM": {
        "id": "FNTM",
        "name": "FANTIC"
    },
    "FNTN": {
        "id": "FNTN",
        "name": "FONTAINE TRAILER COMPANY KENT OHIO"
    },
    "FOCU": {
        "id": "FOCU",
        "name": "FOCUS MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "FOHO": {
        "id": "FOHO",
        "name": "FOUR HORSEMAN MOTORCYCLE"
    },
    "FOLA": {
        "id": "FOLA",
        "name": "FOLAND ENTERPRISES SAN DIEGO"
    },
    "FOML": {
        "id": "FOML",
        "name": "FORMALOY FLAT SNOWMOBILE TRAILER"
    },
    "FOMO": {
        "id": "FOMO",
        "name": "FOREMOST ALSO SEE MAKE J.C.PENNEY"
    },
    "FONA": {
        "id": "FONA",
        "name": "FONTAINE TRUCK EQUIPMENT"
    },
    "FONS": {
        "id": "FONS",
        "name": "FONTAINE SPECIALIZED SPRINGVILLE"
    },
    "FONT": {
        "id": "FONT",
        "name": "FONTENELLE HOMES"
    },
    "FOOD": {
        "id": "FOOD",
        "name": "FOOD-TRAILERS"
    },
    "FOOT": {
        "id": "FOOT",
        "name": "FOOT HILL HORSE TRAILER"
    },
    "FOR": {
        "id": "FOR",
        "name": "FORTE TRAILER"
    },
    "FORA": {
        "id": "FORA",
        "name": "FORAGE KING INDUSTRIES INC."
    },
    "FORC": {
        "id": "FORC",
        "name": "FORREST CITY MACHINE WORKS"
    },
    "FORD": {
        "id": "FORD",
        "name": "FORD"
    },
    "FORE": {
        "id": "FORE",
        "name": "FORD'S MOBILE HOME MFG."
    },
    "FORF": {
        "id": "FORF",
        "name": "FOREMAN MFG."
    },
    "FORG": {
        "id": "FORG",
        "name": "FOREMOST MOBILE HOME MFG."
    },
    "FORH": {
        "id": "FORH",
        "name": "FORESTER"
    },
    "FORI": {
        "id": "FORI",
        "name": "FOREST CITY INDUSTRIES"
    },
    "FORL": {
        "id": "FORL",
        "name": "FORLYN ENGINEERING"
    },
    "FORM": {
        "id": "FORM",
        "name": "FORMCASTER INC OR FORM CASTER INC NEWPORT"
    },
    "FORN": {
        "id": "FORN",
        "name": "FORNEY HORSE TRAILER"
    },
    "FORR": {
        "id": "FORR",
        "name": "FOSTRON'S FACTORY OUTLET TRAILERS"
    },
    "FORS": {
        "id": "FORS",
        "name": "FORT LUPTON CAMPERS"
    },
    "FORT": {
        "id": "FORT",
        "name": "FORT SMITH CAMPER MFG."
    },
    "FORU": {
        "id": "FORU",
        "name": "FORTUNE HOMES CORP."
    },
    "FORW": {
        "id": "FORW",
        "name": "FORT WORTH FABRICATION"
    },
    "FOSJ": {
        "id": "FOSJ",
        "name": "FOST JOE DOG"
    },
    "FOST": {
        "id": "FOST",
        "name": "FOSTER FLATBED TRAILER"
    },
    "FOTN": {
        "id": "FOTN",
        "name": "FOTON MOTOR"
    },
    "FOTO": {
        "id": "FOTO",
        "name": "FOTON LOVOL TRAILER AND AGRICULTURAL FARM MACNINERY"
    },
    "FOTX": {
        "id": "FOTX",
        "name": "FOTOGRAFIX BOAT TRAILERS"
    },
    "FOUN": {
        "id": "FOUN",
        "name": "FOUNTAIN FLATBED TRAILER"
    },
    "FOUR": {
        "id": "FOUR",
        "name": "FOUR SEASONS TRAVEL TRAILER"
    },
    "FOUS": {
        "id": "FOUS",
        "name": "FOUR STAR COACH"
    },
    "FOUT": {
        "id": "FOUT",
        "name": "FOUR STATE INDUSTRIES"
    },
    "FOUW": {
        "id": "FOUW",
        "name": "FOUR WINDS"
    },
    "FOVR": {
        "id": "FOVR",
        "name": "FOREVER CONCESSION TRAILERS"
    },
    "FOWN": {
        "id": "FOWN",
        "name": "FOUR WINNS"
    },
    "FOX": {
        "id": "FOX",
        "name": "FOX"
    },
    "FOXI": {
        "id": "FOXI",
        "name": "FOXI"
    },
    "FPEC": {
        "id": "FPEC",
        "name": "FAIRPLAY ELECTRIC VEHICLES LSV GRAND JUNCTION"
    },
    "FPRV": {
        "id": "FPRV",
        "name": "PROVIDENCE MFG BY FLEETWOOD RV INC"
    },
    "FPWI": {
        "id": "FPWI",
        "name": "FIRST PLACE WELDING"
    },
    "FRAC": {
        "id": "FRAC",
        "name": "FRANK MOTOR HOMES"
    },
    "FRAI": {
        "id": "FRAI",
        "name": "FRANKLIN COACH"
    },
    "FRAK": {
        "id": "FRAK",
        "name": "FRANKLIN HOMES"
    },
    "FRAN": {
        "id": "FRAN",
        "name": "FRANKLIN"
    },
    "FRAZ": {
        "id": "FRAZ",
        "name": "FRAZIER"
    },
    "FRBA": {
        "id": "FRBA",
        "name": "FRANCIS-BARNETT"
    },
    "FRCS": {
        "id": "FRCS",
        "name": "FRANCIS TRAVEL TRAILER"
    },
    "FRDM": {
        "id": "FRDM",
        "name": "FREEDOM TRAILERS"
    },
    "FRDR": {
        "id": "FRDR",
        "name": "FRANKLIN DRILLING"
    },
    "FRDS": {
        "id": "FRDS",
        "name": "FORDS TRAILER SALES AND MANUFACTURING SPIRO OKLAHOMA"
    },
    "FREA": {
        "id": "FREA",
        "name": "FREEWAY"
    },
    "FRED": {
        "id": "FRED",
        "name": "FREDERICK-WILLYS"
    },
    "FREE": {
        "id": "FREE",
        "name": "FREEWAY TRAVELERS"
    },
    "FREF": {
        "id": "FREF",
        "name": "FRENCH FORD"
    },
    "FREL": {
        "id": "FREL",
        "name": "FREEDON ELITE MFG BY THOR MOTOR COACH INC."
    },
    "FREN": {
        "id": "FREN",
        "name": "FRENCH HORSE TRAILER"
    },
    "FRES": {
        "id": "FRES",
        "name": "FRIESS TRAILER"
    },
    "FRFL": {
        "id": "FRFL",
        "name": "FREE FLIGHT MFG BY TRIPLE E RECREATIONAL VEHICLES CANADA LTD"
    },
    "FRHH": {
        "id": "FRHH",
        "name": "FOUR H MFG."
    },
    "FRHT": {
        "id": "FRHT",
        "name": "FREIGHTLINER CORP."
    },
    "FRIC": {
        "id": "FRIC",
        "name": "IC37 MFG BY FORETRAVEL INC"
    },
    "FRIE": {
        "id": "FRIE",
        "name": "FRIENDSHIP MOBILE HOMES"
    },
    "FRIG": {
        "id": "FRIG",
        "name": "FRIGGSTAD MFG."
    },
    "FRIH": {
        "id": "FRIH",
        "name": "IH45 MFG BY FORETRAVEL INC"
    },
    "FRIS": {
        "id": "FRIS",
        "name": "FRISKY"
    },
    "FRKS": {
        "id": "FRKS",
        "name": "FORKS RV INC. INDIANA TRAILERS AND RV'S"
    },
    "FRLL": {
        "id": "FRLL",
        "name": "FRELL"
    },
    "FRMI": {
        "id": "FRMI",
        "name": "FARM MASTER INC."
    },
    "FRMT": {
        "id": "FRMT",
        "name": "FARMTRAC TRACTORS & CONSTRUCTION EQUIPMENT AND ATTACHMENTS"
    },
    "FRNA": {
        "id": "FRNA",
        "name": "FRAZER-NASH"
    },
    "FRNK": {
        "id": "FRNK",
        "name": "FRANKLIN EQUIPMENT"
    },
    "FRNT": {
        "id": "FRNT",
        "name": "FRONTIER CARGO ING."
    },
    "FROB": {
        "id": "FROB",
        "name": "FROST BOATS TRAILERS GRANTS PASS"
    },
    "FROL": {
        "id": "FROL",
        "name": "FROLIC HOMES"
    },
    "FRON": {
        "id": "FRON",
        "name": "FRONTIER HOMES CORP."
    },
    "FROS": {
        "id": "FROS",
        "name": "FROST TRAILER"
    },
    "FRPT": {
        "id": "FRPT",
        "name": "FRP TRAILERS GOSHEN"
    },
    "FRRA": {
        "id": "FRRA",
        "name": "FERRARA FIRE APPARATUS AERIALS"
    },
    "FRRM": {
        "id": "FRRM",
        "name": "REALM MFG BY FORETRAVEL INC"
    },
    "FRRS": {
        "id": "FRRS",
        "name": "FERRIS INDUSTRIES"
    },
    "FRRV": {
        "id": "FRRV",
        "name": "FOREST RIVER INC ELKHART IN"
    },
    "FRSN": {
        "id": "FRSN",
        "name": "FRIESEN WELDING OKEMAH"
    },
    "FRSP": {
        "id": "FRSP",
        "name": "FREE SPIRIT MFG BY TRIPLE E RECREATIONAL VEHICLES CANADA"
    },
    "FRSV": {
        "id": "FRSV",
        "name": "FRANK SIVIGLIA & COMPANY INC."
    },
    "FRTK": {
        "id": "FRTK",
        "name": "FRONTIER TANKS"
    },
    "FRTT": {
        "id": "FRTT",
        "name": "4T MFG"
    },
    "FRTV": {
        "id": "FRTV",
        "name": "FORETRAVEL INC NACOGDOCHES TX"
    },
    "FRUE": {
        "id": "FRUE",
        "name": "FRUEHAUF CORP.DETROIT"
    },
    "FRUN": {
        "id": "FRUN",
        "name": "FRUIN"
    },
    "FRWY": {
        "id": "FRWY",
        "name": "FREEWAY TRAILER SALES"
    },
    "FRYE": {
        "id": "FRYE",
        "name": "FRYE"
    },
    "FSAB": {
        "id": "FSAB",
        "name": "FASTLOAD ALUMINUM BOAT TRAILERS PLANT CITY"
    },
    "FSET": {
        "id": "FSET",
        "name": "FUSILIER ENTERPRISES LACASSINE LA"
    },
    "FSKR": {
        "id": "FSKR",
        "name": "FISKER AUTOMOTIVE"
    },
    "FSLD": {
        "id": "FSLD",
        "name": "FASTLOAD ENTERPRISE. INC FLORIDA BOAT TRAILERS _"
    },
    "FSMH": {
        "id": "FSMH",
        "name": "FOUR SEASONS MANUFACTURED HOMES"
    },
    "FSPT": {
        "id": "FSPT",
        "name": "FREESPIRIT TRAILERS LLC BEND OREGON"
    },
    "FSSI": {
        "id": "FSSI",
        "name": "FIRST STRING SPACE"
    },
    "FSTI": {
        "id": "FSTI",
        "name": "FOSTI MOTORCYCLE MANUFACTURING"
    },
    "FSTL": {
        "id": "FSTL",
        "name": "FASTLINE KANSAS"
    },
    "FSTM": {
        "id": "FSTM",
        "name": "STORM"
    },
    "FSTR": {
        "id": "FSTR",
        "name": "4 STAR TRAILERS"
    },
    "FSTW": {
        "id": "FSTW",
        "name": "SOUTHWIND MFG BY FLEETWOOD RV INC"
    },
    "FTAL": {
        "id": "FTAL",
        "name": "ALUMASCAPE BRAND MFG BY FLEETWOOD RV"
    },
    "FTAM": {
        "id": "FTAM",
        "name": "AMBASSADOR MODEL MFG BY FLEETWOOD RV"
    },
    "FTAX": {
        "id": "FTAX",
        "name": "AXON BRAND MFG BY FLEETWOOD RV"
    },
    "FTCA": {
        "id": "FTCA",
        "name": "FTCA"
    },
    "FTCG": {
        "id": "FTCG",
        "name": "FAST CARGO LLC DOUGLAS GA"
    },
    "FTCH": {
        "id": "FTCH",
        "name": "FUN TECH INDUSTRIES"
    },
    "FTDP": {
        "id": "FTDP",
        "name": "DIPLOMAT MODEL MFG BY FLEETWOOD RV"
    },
    "FTDY": {
        "id": "FTDY",
        "name": "DYNASTY MODEL MFG BY FLEETWOOD RV"
    },
    "FTEN": {
        "id": "FTEN",
        "name": "ENDEAVOR MODEL MFG BY FLEETWOOD RV"
    },
    "FTER": {
        "id": "FTER",
        "name": "TERRA OR TERRA SE MFG BY FLEETWOOD RV INC."
    },
    "FTFR": {
        "id": "FTFR",
        "name": "FAITH FORGOTTEN LLC NEW ALBANY IN"
    },
    "FTHR": {
        "id": "FTHR",
        "name": "FEATHERLITE TRAILERS"
    },
    "FTIO": {
        "id": "FTIO",
        "name": "TIOGA"
    },
    "FTKN": {
        "id": "FTKN",
        "name": "KNIGHT MODEL MFG BY FLEETWOOD RV"
    },
    "FTPR": {
        "id": "FTPR",
        "name": "PRESIDENTIAL MODEL MFG BY FLEETWOOD RV"
    },
    "FTPU": {
        "id": "FTPU",
        "name": "PULSE BRAND MFG BY FLEETWOOD RV"
    },
    "FTRC": {
        "id": "FTRC",
        "name": "FAST TRAC MANUFACTURING MOTORCYCLES"
    },
    "FTRV": {
        "id": "FTRV",
        "name": "FRONTIER RV HOME OFFICE-LONGVIEW"
    },
    "FTST": {
        "id": "FTST",
        "name": "SPRINTER MOTORHOME"
    },
    "FTSU": {
        "id": "FTSU",
        "name": "SURGE BRAND MFG BY FLEETWOOD RV"
    },
    "FTTV": {
        "id": "FTTV",
        "name": "TRAVELER TT MODEL MFG BY FLEETWOOD RV"
    },
    "FTUA": {
        "id": "FTUA",
        "name": "ALUMA-LITE TT & ALUMA-LITE ULTRA TT"
    },
    "FTVA": {
        "id": "FTVA",
        "name": "VACATIONER MODEL MFG BY FLEETWOOD RV"
    },
    "FTWD": {
        "id": "FTWD",
        "name": "FLEETWOOD ENTERPRISES"
    },
    "FTWM": {
        "id": "FTWM",
        "name": "FLEETWOOD DE MEXI"
    },
    "FUER": {
        "id": "FUER",
        "name": "FUERST BROTHERS"
    },
    "FUJI": {
        "id": "FUJI",
        "name": "FUJI ROBBT JR MFD. BY H-M VEHICLES INC"
    },
    "FULL": {
        "id": "FULL",
        "name": "FULLER MFG."
    },
    "FULM": {
        "id": "FULM",
        "name": "FULLMOON CUSTOMS AULT CO"
    },
    "FULT": {
        "id": "FULT",
        "name": "FULTON BOAT TRAILER"
    },
    "FULU": {
        "id": "FULU",
        "name": "FULU VEHICLE"
    },
    "FUMW": {
        "id": "FUMW",
        "name": "FUSION METAL WORKS CALDWELL ID"
    },
    "FUNK": {
        "id": "FUNK",
        "name": "FUNKE AND WILL AG AKA-YES VEHICLES"
    },
    "FUNL": {
        "id": "FUNL",
        "name": "FUNLINER MFG."
    },
    "FUNT": {
        "id": "FUNT",
        "name": "FUNTIME CAMPER TRAILER"
    },
    "FUQU": {
        "id": "FUQU",
        "name": "FUQUA HOMES"
    },
    "FURA": {
        "id": "FURA",
        "name": "FURATELL INDUSTRIESBRUCE"
    },
    "FUSN": {
        "id": "FUSN",
        "name": "FUSION CUSTOM TRAILERS AND MOTOR COACHES UNION NB"
    },
    "FUTA": {
        "id": "FUTA",
        "name": "FUTURA TRAILERS LIMITED NEW ZEALAND"
    },
    "FUTO": {
        "id": "FUTO",
        "name": "FUTONG MOTORCYCLE"
    },
    "FUTR": {
        "id": "FUTR",
        "name": "FUTURA MOBILE HOME"
    },
    "FUTU": {
        "id": "FUTU",
        "name": "FUTURAMA"
    },
    "FVAA": {
        "id": "FVAA",
        "name": "ALTA MFG BY FOREST RIVER INC"
    },
    "FVAC": {
        "id": "FVAC",
        "name": "ACADIA BRAND MFG BY FOREST RIVER"
    },
    "FVAD": {
        "id": "FVAD",
        "name": "ADRENALINE BRAND MFG BY FOREST RIVER"
    },
    "FVAF": {
        "id": "FVAF",
        "name": "SHASTA AIRFLYTE"
    },
    "FVAM": {
        "id": "FVAM",
        "name": "AMERITRANS BRAND MFG BY FOREST RIVER"
    },
    "FVAP": {
        "id": "FVAP",
        "name": "APEX MFG BY FOREST RIVER"
    },
    "FVAV": {
        "id": "FVAV",
        "name": "AVENGER MFG BY FOREST RIVER"
    },
    "FVBD": {
        "id": "FVBD",
        "name": "BLACK DIAMOND BRAND MFG BY FOREST RIVER"
    },
    "FVBK": {
        "id": "FVBK",
        "name": "BROOKSTONE MFG BY FOREST RIVER"
    },
    "FVBM": {
        "id": "FVBM",
        "name": "BERKSHIRE MOTOR COACH MFG BY FOREST RIVER"
    },
    "FVBR": {
        "id": "FVBR",
        "name": "BLUE RIDGE MFG BY FOREST RIVER"
    },
    "FVBT": {
        "id": "FVBT",
        "name": "BRON"
    },
    "FVBY": {
        "id": "FVBY",
        "name": "BEYOND MFG BY FOREST RIVER INC"
    },
    "FVCA": {
        "id": "FVCA",
        "name": "CARDINAL MFG BY FOREST RIVER INC"
    },
    "FVCB": {
        "id": "FVCB",
        "name": "CABIN MFG BY FOREST RIVER"
    },
    "FVCC": {
        "id": "FVCC",
        "name": "CANYON CAT MFG BY FOREST RIVER INC"
    },
    "FVCD": {
        "id": "FVCD",
        "name": "CASCADE MFG BY FOREST RIVER"
    },
    "FVCF": {
        "id": "FVCF",
        "name": "CROSSFIT BRAND MFG BY FOREST RIVER"
    },
    "FVCG": {
        "id": "FVCG",
        "name": "CONTINENTAL CARGO MFG BY FOREST RIVER"
    },
    "FVCH": {
        "id": "FVCH",
        "name": "CHEROKEE MFG BY FOREST RIVER INC"
    },
    "FVCI": {
        "id": "FVCI",
        "name": "CANDIDATE"
    },
    "FVCK": {
        "id": "FVCK",
        "name": "CEDAR CREEK"
    },
    "FVCL": {
        "id": "FVCL",
        "name": "CLIPPERTENT CAMPERS"
    },
    "FVCM": {
        "id": "FVCM",
        "name": "CARGO MATE MFG BY FOREST RIVER"
    },
    "FVCN": {
        "id": "FVCN",
        "name": "CONCORD MFG BY FOREST RIVER INC"
    },
    "FVCO": {
        "id": "FVCO",
        "name": "COLUMBUS MFG BY FOREST RIVER INC"
    },
    "FVCP": {
        "id": "FVCP",
        "name": "CHAPARRAL BRAND MFG BY FOREST RIVER"
    },
    "FVCR": {
        "id": "FVCR",
        "name": "CROSS COUNTRY MFG BY FOREST RIVER INC"
    },
    "FVCS": {
        "id": "FVCS",
        "name": "CHARLESTON MFG BY FOREST RIVER"
    },
    "FVCT": {
        "id": "FVCT",
        "name": "CATALINA MFG BY FOREST RIVER INC."
    },
    "FVCU": {
        "id": "FVCU",
        "name": "CRUSADER MFG BY FOREST RIVER INC"
    },
    "FVCY": {
        "id": "FVCY",
        "name": "CATALYST MFG BY FOREST RIVER"
    },
    "FVDT": {
        "id": "FVDT",
        "name": "DELLA TERRA BRAND MFG BY FOREST RIVER INC"
    },
    "FVDX": {
        "id": "FVDX",
        "name": "DX3 MFG BY FOREST RIVER"
    },
    "FVDY": {
        "id": "FVDY",
        "name": "DYNAQUEST ST"
    },
    "FVEN": {
        "id": "FVEN",
        "name": "ENCOUNTER MFG BY FOREST RIVER INC"
    },
    "FVEV": {
        "id": "FVEV",
        "name": "EVO LAMINATED TOWABLE BRAND MFG BY FOREST RIVER"
    },
    "FVFC": {
        "id": "FVFC",
        "name": "FORCE BRAND MFG BY FOREST RIVER"
    },
    "FVFE": {
        "id": "FVFE",
        "name": "FREEDOM EXPRESS MFG BY FOREST RIVER INC"
    },
    "FVFF": {
        "id": "FVFF",
        "name": "FR3 MFG BY FOREST RIVER"
    },
    "FVFG": {
        "id": "FVFG",
        "name": "FLAGSTAFF"
    },
    "FVFO": {
        "id": "FVFO",
        "name": "FORESTER MFG BY FOREST RIVER INC."
    },
    "FVFR": {
        "id": "FVFR",
        "name": "FREELANDER MFG BY FOREST RIVER INC"
    },
    "FVFY": {
        "id": "FVFY",
        "name": "FURY BRAND MFG BY FOREST RIVER"
    },
    "FVGL": {
        "id": "FVGL",
        "name": "GALLERIA BRAND MFG BY FOREST RIVER"
    },
    "FVGM": {
        "id": "FVGM",
        "name": "GEORGETOWN MOTORHOMES MFG BY FOREST RIVER INC."
    },
    "FVGS": {
        "id": "FVGS",
        "name": "GRAND SPORT GT & GRAND SPORT ULTRA MFG BY FOREST RIVER INC"
    },
    "FVGW": {
        "id": "FVGW",
        "name": "GREY WOLF MFG BY FOREST RIVER INC"
    },
    "FVIA": {
        "id": "FVIA",
        "name": "AVIATOR MFG BY FOREST RIVER"
    },
    "FVIM": {
        "id": "FVIM",
        "name": "IMPRESSION MFG BY FOREST RIVER INC"
    },
    "FVIS": {
        "id": "FVIS",
        "name": "ISATA ISATA E SERIES"
    },
    "FVJE": {
        "id": "FVJE",
        "name": "JUNIOR ELIMINATOR BRAND MFG BY FOREST RIVER"
    },
    "FVLA": {
        "id": "FVLA",
        "name": "LACROSSE MFG BY FOREST RIVER INC."
    },
    "FVLG": {
        "id": "FVLG",
        "name": "LEGACY"
    },
    "FVLS": {
        "id": "FVLS",
        "name": "LONE STAR VAN MFG BY FOREST RIVER INC"
    },
    "FVLX": {
        "id": "FVLX",
        "name": "LEXINGTON MFG BY FOREST RIVER"
    },
    "FVMI": {
        "id": "FVMI",
        "name": "MIRADA"
    },
    "FVMT": {
        "id": "FVMT",
        "name": "MAVERICK TRUCK CAMPER MFG BY FOREST RIVER"
    },
    "FVNA": {
        "id": "FVNA",
        "name": "NOVA MFG BY FOREST RIVER INC"
    },
    "FVNB": {
        "id": "FVNB",
        "name": "NO BOUNDARIES BRAND MFG BY FOREST RIVER"
    },
    "FVNV": {
        "id": "FVNV",
        "name": "NAVI BRAND MFG BY FOREST RIVER INC"
    },
    "FVOA": {
        "id": "FVOA",
        "name": "SHASTA OASIS. MFG BY FOREST RIVER"
    },
    "FVOM": {
        "id": "FVOM",
        "name": "SANDSTORM TOY HAULER BRAND MFG BY FOREST RIVER INC"
    },
    "FVON": {
        "id": "FVON",
        "name": "SONOMA BRAND MFG BY FOREST RIVER"
    },
    "FVOR": {
        "id": "FVOR",
        "name": "ORION MODEL"
    },
    "FVOZ": {
        "id": "FVOZ",
        "name": "OZARK MFG BY FOREST RIVER INC"
    },
    "FVPA": {
        "id": "FVPA",
        "name": "PALOMINO"
    },
    "FVPD": {
        "id": "FVPD",
        "name": "PRESIDENT SERIES MFG BY FOREST RIVER"
    },
    "FVPK": {
        "id": "FVPK",
        "name": "PARK MFG BY FOREST RIVER INC"
    },
    "FVPR": {
        "id": "FVPR",
        "name": "PRISM MFG BY FOREST RIVER INC"
    },
    "FVPS": {
        "id": "FVPS",
        "name": "PURSUIT MFG BY FOREST RIVER"
    },
    "FVPT": {
        "id": "FVPT",
        "name": "PATHFINDER MFG BY FOREST RIVER"
    },
    "FVPU": {
        "id": "FVPU",
        "name": "PUMA"
    },
    "FVPX": {
        "id": "FVPX",
        "name": "PTX BRAND MFG BY FOREST RIVER"
    },
    "FVQL": {
        "id": "FVQL",
        "name": "QUAILRIDGE MFG BY FOREST REIVER"
    },
    "FVRG": {
        "id": "FVRG",
        "name": "ROGUE TRUCK CAMPER MFG BY FOREST RIVER INC"
    },
    "FVRI": {
        "id": "FVRI",
        "name": "RIVERSTONE BRAND MFG BY FOREST RIVER"
    },
    "FVRO": {
        "id": "FVRO",
        "name": "ALL ROCKWOOD MODELS MFG BY FOREST RIVER"
    },
    "FVRP": {
        "id": "FVRP",
        "name": "R-POD MFG BY FOREST RIVER"
    },
    "FVRT": {
        "id": "FVRT",
        "name": "SPIRIT MFG BY FOREST RIVER INC"
    },
    "FVRV": {
        "id": "FVRV",
        "name": "REV MODEL"
    },
    "FVSA": {
        "id": "FVSA",
        "name": "SALEN"
    },
    "FVSB": {
        "id": "FVSB",
        "name": "SABRE"
    },
    "FVSD": {
        "id": "FVSD",
        "name": "SANDPIPER MFG BY FOREST RIVER"
    },
    "FVSE": {
        "id": "FVSE",
        "name": "SENATOR SERIES BRAND MFG BY FORERST RIVER"
    },
    "FVSF": {
        "id": "FVSF",
        "name": "SHASTA FLYTE MFG BY FOREST RIVER"
    },
    "FVSH": {
        "id": "FVSH",
        "name": "STEALTH MFG BY FOREST RIVER INC ELKHART IN"
    },
    "FVSI": {
        "id": "FVSI",
        "name": "SIERRA MFG BY FOREST RIVER"
    },
    "FVSL": {
        "id": "FVSL",
        "name": "SILVER LAKE MFG BY FOREST RIVER INC"
    },
    "FVSM": {
        "id": "FVSM",
        "name": "SUMMIT MFG BY FOREST RIVER"
    },
    "FVSN": {
        "id": "FVSN",
        "name": "SANIBEL"
    },
    "FVSO": {
        "id": "FVSO",
        "name": "SOLERA MFG BY FOREST RIVER"
    },
    "FVSP": {
        "id": "FVSP",
        "name": "SHASTA PHOENIX MODEL MFG BY FOREST RIVER"
    },
    "FVSR": {
        "id": "FVSR",
        "name": "SHASTA REVERE MFG BY FOREST RIVER"
    },
    "FVSS": {
        "id": "FVSS",
        "name": "SURVEYOR SELECT"
    },
    "FVST": {
        "id": "FVST",
        "name": "STAMPEDE BRAND MFG BY FOREST RIVER"
    },
    "FVSU": {
        "id": "FVSU",
        "name": "SUNSEEKER MFG BY FOREST RIVER INC"
    },
    "FVSV": {
        "id": "FVSV",
        "name": "STARBUS"
    },
    "FVTA": {
        "id": "FVTA",
        "name": "STARLINER MFG BY FOREST RIVER"
    },
    "FVTE": {
        "id": "FVTE",
        "name": "TOURING EDITION MFG BY FOREST RIVER INC"
    },
    "FVTK": {
        "id": "FVTK",
        "name": "CROSS TREK MFG BY FOREST RIVER INC"
    },
    "FVTL": {
        "id": "FVTL",
        "name": "TRILOGY MFG BY FOREST RIVER INC"
    },
    "FVTN": {
        "id": "FVTN",
        "name": "SPARTAN MODEL. MFG BY FORET RIVER"
    },
    "FVTR": {
        "id": "FVTR",
        "name": "TRACER MFG BY FOREST RIVER INC"
    },
    "FVTS": {
        "id": "FVTS",
        "name": "SPORTSCACH-BRAND MFG BY FOREST RIVER INC"
    },
    "FVTT": {
        "id": "FVTT",
        "name": "STARTRANS MFSAB MFG BY FOREST RIVER"
    },
    "FVUL": {
        "id": "FVUL",
        "name": "ULTRA LITE"
    },
    "FVVB": {
        "id": "FVVB",
        "name": "VIBE"
    },
    "FVVC": {
        "id": "FVVC",
        "name": "V-CROSS CLASSIC"
    },
    "FVVH": {
        "id": "FVVH",
        "name": "VALUE HAULER MFG BY FOREST RIVER"
    },
    "FVVK": {
        "id": "FVVK",
        "name": "VIKING TENT CAMPERS"
    },
    "FVVN": {
        "id": "FVVN",
        "name": "VENGENCE MFG BY FOREST RIVER"
    },
    "FVVT": {
        "id": "FVVT",
        "name": "V-TREC BRAND MFG BY FOREST RIVER"
    },
    "FVWC": {
        "id": "FVWC",
        "name": "WILDCAT"
    },
    "FVWO": {
        "id": "FVWO",
        "name": "WORK AND PLAY MFG BY FOREST RIVER INC"
    },
    "FVWP": {
        "id": "FVWP",
        "name": "WOLF PACK"
    },
    "FVWW": {
        "id": "FVWW",
        "name": "WILDWOOD"
    },
    "FVXL": {
        "id": "FVXL",
        "name": "XLR MFG BY FOREST RIVER INC"
    },
    "FVZG": {
        "id": "FVZG",
        "name": "GAZELLE MFG BY FOREST RIVER INC"
    },
    "FWAY": {
        "id": "FWAY",
        "name": "FANWAY MACHINERY MFG"
    },
    "FWD": {
        "id": "FWD",
        "name": "FWD CORP."
    },
    "FWST": {
        "id": "FWST",
        "name": "FAIR-WEST TRAILERS DIVISION OFCHANELTRACK AND TUBE-WAY IND."
    },
    "FWTI": {
        "id": "FWTI",
        "name": "F.W.T. INC' FORT WORTH TEXAS TRAILERS"
    },
    "FXBB": {
        "id": "FXBB",
        "name": "FOX BETTER BUILT TRAILERS WASHBURN"
    },
    "FXTL": {
        "id": "FXTL",
        "name": "FOX TRAILERS"
    },
    "FYCS": {
        "id": "FYCS",
        "name": "F AND Y CARGO SALES PEARSON GA"
    },
    "FZBI": {
        "id": "FZBI",
        "name": "VIBE MFG BY FOREST RIVER INC"
    },
    "FZLE": {
        "id": "FZLE",
        "name": "LEPRECHAUN MFG BY FOREST RIVER INC."
    },
    "GAAC": {
        "id": "GAAC",
        "name": "GENERAL AMER AERO COACH"
    },
    "GABB": {
        "id": "GABB",
        "name": "GABBIANO ITALY - MOTORCYCLES"
    },
    "GABI": {
        "id": "GABI",
        "name": "GABILAN WELDING"
    },
    "GABR": {
        "id": "GABR",
        "name": "GABRIEL AUTO CARRIER"
    },
    "GACH": {
        "id": "GACH",
        "name": "GREAT AMERICAN CHOPPER CLAWSON"
    },
    "GACM": {
        "id": "GACM",
        "name": "GAC MACHINE COMPANY NEW YORK"
    },
    "GADA": {
        "id": "GADA",
        "name": "GAD-ABOUT TRAILERS"
    },
    "GADB": {
        "id": "GADB",
        "name": "GADABOUT"
    },
    "GAFN": {
        "id": "GAFN",
        "name": "GAFNER MACHINE"
    },
    "GALA": {
        "id": "GALA",
        "name": "GALACTIC CORP."
    },
    "GALB": {
        "id": "GALB",
        "name": "GALBREATH"
    },
    "GALI": {
        "id": "GALI",
        "name": "GALION MANUFACTURING DIV.DIV. DRESSER INDUSTRIES"
    },
    "GALL": {
        "id": "GALL",
        "name": "GALLEGOS TRAILERS OR CARROCERIAS GALLEGOS SA DE CV TRAILERS SEMI TRAILERS"
    },
    "GALT": {
        "id": "GALT",
        "name": "GALLATIN HOMES BELGRADE MONTANA"
    },
    "GALY": {
        "id": "GALY",
        "name": "GALYEAN EQUIPMENT"
    },
    "GANN": {
        "id": "GANN",
        "name": "GANNON MANUFACTURING"
    },
    "GAPD": {
        "id": "GAPD",
        "name": "GULF ATLANTIC PUMP & DREDGE FLORIDA TRAILER MOUNTED POWER UNIT"
    },
    "GAPH": {
        "id": "GAPH",
        "name": "GAP HILL ALUMINUM SHOP GAP"
    },
    "GARB": {
        "id": "GARB",
        "name": "GAR-BRO MANUFACTURING"
    },
    "GARD": {
        "id": "GARD",
        "name": "GARDNER"
    },
    "GARE": {
        "id": "GARE",
        "name": "GARELLI"
    },
    "GARG": {
        "id": "GARG",
        "name": "GARGES CUSTOM TRAILERS"
    },
    "GARH": {
        "id": "GARH",
        "name": "GARWAY HOMES"
    },
    "GARI": {
        "id": "GARI",
        "name": "GARIA - GARIA A"
    },
    "GARL": {
        "id": "GARL",
        "name": "GARLOCK EQUIPMENT"
    },
    "GARN": {
        "id": "GARN",
        "name": "GARDNER-DENVER COOPER INDUSTRIES"
    },
    "GARO": {
        "id": "GARO",
        "name": "GARWOOD"
    },
    "GARP": {
        "id": "GARP",
        "name": "GARDNER-PACIFIC-SUNRADER"
    },
    "GARR": {
        "id": "GARR",
        "name": "GARRETT-WELD"
    },
    "GARS": {
        "id": "GARS",
        "name": "GARSON FLATBED TRAILER"
    },
    "GARY": {
        "id": "GARY",
        "name": "GARY CAROLINA"
    },
    "GASE": {
        "id": "GASE",
        "name": "GARBER SEEDERS"
    },
    "GAST": {
        "id": "GAST",
        "name": "GAST MANUFACTURING"
    },
    "GATE": {
        "id": "GATE",
        "name": "GATES"
    },
    "GATM": {
        "id": "GATM",
        "name": "GATOR MOTO LLC"
    },
    "GATO": {
        "id": "GATO",
        "name": "GATOR TRAILERS CORP."
    },
    "GATP": {
        "id": "GATP",
        "name": "GATOR PRODUCTS"
    },
    "GATR": {
        "id": "GATR",
        "name": "GATOR MADE INC SOMERSET"
    },
    "GAZ": {
        "id": "GAZ",
        "name": "GAZ"
    },
    "GBCO": {
        "id": "GBCO",
        "name": "GILSON BROTHERS COMPANY"
    },
    "GBEV": {
        "id": "GBEV",
        "name": "GREEN BEE ELECTRIC VEHICLES TECH."
    },
    "GCCC": {
        "id": "GCCC",
        "name": "CAPE CODE BRAND MFG BY GRAND COACH"
    },
    "GCDL": {
        "id": "GCDL",
        "name": "DOLPHIN BRAND MFG BY GRAND COACH"
    },
    "GCHM": {
        "id": "GCHM",
        "name": "GRECH MOTORS RIVERSIDE"
    },
    "GCIN": {
        "id": "GCIN",
        "name": "G & C INC OR G & C AUTO AND FLEET ENID OK LOW SPEED VEH'S"
    },
    "GCLI": {
        "id": "GCLI",
        "name": "GCL"
    },
    "GCMC": {
        "id": "GCMC",
        "name": "G. & C. MFG."
    },
    "GCOA": {
        "id": "GCOA",
        "name": "GRAND COACH LLC ELKHART"
    },
    "GCRK": {
        "id": "GCRK",
        "name": "GOOSE CREEK ENTERPRISES HICKORY"
    },
    "GCSS": {
        "id": "GCSS",
        "name": "SIGNATURE SERIES BRAND MFG BY GRAND COACH"
    },
    "GDAN": {
        "id": "GDAN",
        "name": "GREAT DANE TRAILERS"
    },
    "GDIM": {
        "id": "GDIM",
        "name": "IMAGINE BRAND MFG BY GRAND DESIGN RECREATIONAL VEHICLES VMA"
    },
    "GDMC": {
        "id": "GDMC",
        "name": "GDM COMPANY LLC JURON"
    },
    "GDMO": {
        "id": "GDMO",
        "name": "MOMENTUM MFG"
    },
    "GDNI": {
        "id": "GDNI",
        "name": "GORDINI"
    },
    "GDRF": {
        "id": "GDRF",
        "name": "REFLECTION MFG BY GRAND DESIGN RECREATIONAL VEHICLES INDIANA"
    },
    "GDRV": {
        "id": "GDRV",
        "name": "GRAND DESIGN RECREATIONAL VEHICLES MIDDLEBURY"
    },
    "GDTL": {
        "id": "GDTL",
        "name": "GUARDIAN TRAILERS WHITE CITY OR"
    },
    "GDTS": {
        "id": "GDTS",
        "name": "TRANSCEND BRAND MFG BY GRAND DESIGN RV LLC"
    },
    "GEAF": {
        "id": "GEAF",
        "name": "GEA FARM TECHNOLOGIES CANADA INC QUEBEC CANADA"
    },
    "GEAR": {
        "id": "GEAR",
        "name": "GEAR JAMMER CUSTOMS"
    },
    "GECA": {
        "id": "GECA",
        "name": "GENERAL COACH AMERICA"
    },
    "GECI": {
        "id": "GECI",
        "name": "GENERAL ENGINES"
    },
    "GEEB": {
        "id": "GEEB",
        "name": "GEE-BEE-DEE MANUFACTURING & SUPPLY"
    },
    "GEEC": {
        "id": "GEEC",
        "name": "GEER"
    },
    "GEEL": {
        "id": "GEEL",
        "name": "GEELY GROUP CHINA"
    },
    "GEEN": {
        "id": "GEEN",
        "name": "GENERAL ENGINES"
    },
    "GEER": {
        "id": "GEER",
        "name": "GEERING INDUSTRIES"
    },
    "GEHL": {
        "id": "GEHL",
        "name": "GEHL"
    },
    "GEIS": {
        "id": "GEIS",
        "name": "GEISMAR MODERN TRACK MACHINERY INC ELGIN IL"
    },
    "GELM": {
        "id": "GELM",
        "name": "GREEN ELEC-MOTORS"
    },
    "GELT": {
        "id": "GELT",
        "name": "GELT TRAILERS"
    },
    "GEM": {
        "id": "GEM",
        "name": "GEM INDUSTRIES"
    },
    "GEMI": {
        "id": "GEMI",
        "name": "GEMINI"
    },
    "GEMT": {
        "id": "GEMT",
        "name": "GEMTOP"
    },
    "GEN": {
        "id": "GEN",
        "name": "GENERAL COACH WORKS OF CANADADIV. OF DIVCO-WAYNE INDUSTRIES"
    },
    "GENA": {
        "id": "GENA",
        "name": "GENERAL"
    },
    "GENC": {
        "id": "GENC",
        "name": "GENERAL ENGINES"
    },
    "GEND": {
        "id": "GEND",
        "name": "GENERAL DYNAMICS COMBAT SYSTEMS - ARMAMAENT TECHNICAL LAND SYSTEMS_AND"
    },
    "GENE": {
        "id": "GENE",
        "name": "GENERAL TRAILER"
    },
    "GENF": {
        "id": "GENF",
        "name": "GENERAL COACH OF FLORIDA DIV OF DIVCO-WAYNE INDUSTRIES"
    },
    "GENG": {
        "id": "GENG",
        "name": "GENERAL COACH MFG."
    },
    "GENH": {
        "id": "GENH",
        "name": "GENERAL COACH WORKS"
    },
    "GENI": {
        "id": "GENI",
        "name": "GENIE CONSTRUCTION EQUIPMENT AERIAL"
    },
    "GENM": {
        "id": "GENM",
        "name": "GENERAL HOMES DIV.DIV. OF DIVCO-WAYNE INDUSTRIES"
    },
    "GENR": {
        "id": "GENR",
        "name": "GENERAL MOPED"
    },
    "GENS": {
        "id": "GENS",
        "name": "GENESIS MOTORS-LUXURY DIV BY HYUNDAI-GENESIS FORMERLY MODEL OF HYUNDAI"
    },
    "GENT": {
        "id": "GENT",
        "name": "GENTRY"
    },
    "GENU": {
        "id": "GENU",
        "name": "GENUINE SCOOTER COMPANY"
    },
    "GENZ": {
        "id": "GENZ",
        "name": "GENZE"
    },
    "GEO": {
        "id": "GEO",
        "name": "GEO"
    },
    "GEOR": {
        "id": "GEOR",
        "name": "CRUISEMASTER MOTOR HOME MFG BY GEORGIE BOY MFG."
    },
    "GEOT": {
        "id": "GEOT",
        "name": "GEORGIA TRAILER & EQUIPMENT CORP.MACON"
    },
    "GERA": {
        "id": "GERA",
        "name": "GERARD LOWBOY TRAILER"
    },
    "GERC": {
        "id": "GERC",
        "name": "GENERAC CORP."
    },
    "GERL": {
        "id": "GERL",
        "name": "GERLINGER FOUNDRY MACHINE WORKS"
    },
    "GERR": {
        "id": "GERR",
        "name": "FOREST PARKMFD. BY GERRING INDUSTRIES"
    },
    "GETH": {
        "id": "GETH",
        "name": "GOOD EARTH ENERGY CONSERVATION"
    },
    "GETM": {
        "id": "GETM",
        "name": "GETMAN BROTHERS MFG."
    },
    "GFAM": {
        "id": "GFAM",
        "name": "AMERILITE BRAND"
    },
    "GFBI": {
        "id": "GFBI",
        "name": "GORILLA FABRICATION INC CHARLESTON SC"
    },
    "GFBT": {
        "id": "GFBT",
        "name": "BT CRUISER BRAND MFG BY GULF STREAM"
    },
    "GFCC": {
        "id": "GFCC",
        "name": "CABIN CRUISER MFG BY GULF STREAM COACH INC"
    },
    "GFCO": {
        "id": "GFCO",
        "name": "GEF"
    },
    "GFCP": {
        "id": "GFCP",
        "name": "CAPRI BRAND MFG BY GULF STREAM"
    },
    "GFCQ": {
        "id": "GFCQ",
        "name": "CONQUEST BRAND MFG. BR GULF STREAM"
    },
    "GFEV": {
        "id": "GFEV",
        "name": "ENVISION MFG BY GULF STREAM COACH INC"
    },
    "GFFR": {
        "id": "GFFR",
        "name": "FRIENDSHIP BRAND MFG BY GULF STREAM"
    },
    "GFGE": {
        "id": "GFGE",
        "name": "GEO BRAND MFG BY GULF STREAM"
    },
    "GFHT": {
        "id": "GFHT",
        "name": "G & F HORSE TRAILER REPAIR RIVERSIDE"
    },
    "GFIN": {
        "id": "GFIN",
        "name": "INNSBURCK"
    },
    "GFKI": {
        "id": "GFKI",
        "name": "KINGSPORT MODEL MFG BY GULF STREAM"
    },
    "GFPL": {
        "id": "GFPL",
        "name": "GREENFIELD PRODUCTS LLC UNION CITY TN AND HAZEL CREST IL"
    },
    "GFSD": {
        "id": "GFSD",
        "name": "GULFSIDE TRAILERS LA MARQUE TEXAS TRAILERS"
    },
    "GFSH": {
        "id": "GFSH",
        "name": "GAME FISHER"
    },
    "GFST": {
        "id": "GFST",
        "name": "GULF STREAM"
    },
    "GFTL": {
        "id": "GFTL",
        "name": "GFA TRAILERS LLC SANFORD NC"
    },
    "GFTM": {
        "id": "GFTM",
        "name": "TRAIL MASTER MFG BY GULF STREAM INC"
    },
    "GFTR": {
        "id": "GFTR",
        "name": "G & F TRAILERBOAT TRAILER MFD. IN BAINBRIDGE"
    },
    "GFTT": {
        "id": "GFTT",
        "name": "TRACK & TRAIL"
    },
    "GFUL": {
        "id": "GFUL",
        "name": "ULTRA BRAND MFG BY GULF STREAM TRAILER ADDED"
    },
    "GFVF": {
        "id": "GFVF",
        "name": "VINTAGE FRIENDSHIP BRAND MFG BY GULF STREAM"
    },
    "GFVI": {
        "id": "GFVI",
        "name": "VISTA MODEL"
    },
    "GFVT": {
        "id": "GFVT",
        "name": "VINTAGE CRUISER BRAND MFG BY GULF STREAM"
    },
    "GFWO": {
        "id": "GFWO",
        "name": "WIDE OPEN BRAND"
    },
    "GGCM": {
        "id": "GGCM",
        "name": "GRUTER GUT MOTORRADTECHNIK GMBH GG"
    },
    "GGMA": {
        "id": "GGMA",
        "name": "GAS GAS MOTORS OF AMERICA"
    },
    "GHIE": {
        "id": "GHIE",
        "name": "GHIEAPY JOL TRAILER"
    },
    "GHMI": {
        "id": "GHMI",
        "name": "G & H MANUFACTURING"
    },
    "GHOG": {
        "id": "GHOG",
        "name": "GROUND HOG"
    },
    "GIAA": {
        "id": "GIAA",
        "name": "GIANT MANUFACTURED BY CALDWELL & SONS"
    },
    "GIAN": {
        "id": "GIAN",
        "name": "GIANNINI"
    },
    "GIAT": {
        "id": "GIAT",
        "name": "OSTERLUND"
    },
    "GIBB": {
        "id": "GIBB",
        "name": "GIBBS SPORTS AMPHIBIANS"
    },
    "GIBL": {
        "id": "GIBL",
        "name": "GIBRALTAR COACH MFG."
    },
    "GIBR": {
        "id": "GIBR",
        "name": "GIBRALTAR INDUSTRIES"
    },
    "GICH": {
        "id": "GICH",
        "name": "GICHNER SHELER SYSTEMS DIV OF KRATOS COMP PENNSYLVANIA AND SOUTH"
    },
    "GIDD": {
        "id": "GIDD",
        "name": "GIDDINGS MACHINE"
    },
    "GIGI": {
        "id": "GIGI",
        "name": "GIGI INDUSTRIES"
    },
    "GILB": {
        "id": "GILB",
        "name": "GILBERN"
    },
    "GILD": {
        "id": "GILD",
        "name": "ALANMFD. BY GILES INDUSTRIES"
    },
    "GILE": {
        "id": "GILE",
        "name": "GILERA"
    },
    "GILG": {
        "id": "GILG",
        "name": "GILLIG CORPORATION HAYWARD"
    },
    "GILL": {
        "id": "GILL",
        "name": "GILL MFG."
    },
    "GILM": {
        "id": "GILM",
        "name": "GILMORE TRAILER"
    },
    "GILS": {
        "id": "GILS",
        "name": "GILSON BROTHERS"
    },
    "GILT": {
        "id": "GILT",
        "name": "GILMORE-TATGE MFG."
    },
    "GIND": {
        "id": "GIND",
        "name": "GINDY MFG. CORP.DOWNINGTOWN"
    },
    "GINE": {
        "id": "GINE",
        "name": "GINETTA"
    },
    "GINR": {
        "id": "GINR",
        "name": "GIN RAY MFG."
    },
    "GIRA": {
        "id": "GIRA",
        "name": "GIRARD'S MFG."
    },
    "GISN": {
        "id": "GISN",
        "name": "GILSON"
    },
    "GITA": {
        "id": "GITA",
        "name": "GITANE"
    },
    "GIVE": {
        "id": "GIVE",
        "name": "GIVENS MFG."
    },
    "GKMI": {
        "id": "GKMI",
        "name": "GK MACHINE INC OREGON HYDRAULIC SPECIALTY EQUIP"
    },
    "GKUM": {
        "id": "GKUM",
        "name": "GKU MANUFACTURING"
    },
    "GLAC": {
        "id": "GLAC",
        "name": "GLACIER CRAFT BOATS"
    },
    "GLAD": {
        "id": "GLAD",
        "name": "GLADDING TRAVEL TRAILER"
    },
    "GLAS": {
        "id": "GLAS",
        "name": "GLAS"
    },
    "GLAT": {
        "id": "GLAT",
        "name": "GLASSTITE"
    },
    "GLAV": {
        "id": "GLAV",
        "name": "GLAVAL BUS"
    },
    "GLBE": {
        "id": "GLBE",
        "name": "GLOBE"
    },
    "GLBL": {
        "id": "GLBL",
        "name": "GLOBAL ELECTRIC MOTOR CARS LLC MFG BY POLARIS FARGO ND"
    },
    "GLBM": {
        "id": "GLBM",
        "name": "GLOBAL MOTORSPORT GROUP"
    },
    "GLBP": {
        "id": "GLBP",
        "name": "GLOBAL ENVIRONMENTAL PRODUCTS"
    },
    "GLBT": {
        "id": "GLBT",
        "name": "GOLBE TOOLS"
    },
    "GLCG": {
        "id": "GLCG",
        "name": "GREAT LAKES CARGO LLC ELKHART"
    },
    "GLDH": {
        "id": "GLDH",
        "name": "GOLDHOFER FAHRZEUGWERK GMBH U"
    },
    "GLDI": {
        "id": "GLDI",
        "name": "GOLDEN HORSE INDUSTRY & TRADE"
    },
    "GLDO": {
        "id": "GLDO",
        "name": "GOLDEN OFFICE MFG."
    },
    "GLDS": {
        "id": "GLDS",
        "name": "GOLDEN SUNSHINE APPLIANCE"
    },
    "GLDV": {
        "id": "GLDV",
        "name": "GOLDENVALE INC. TERMINATOR SCOOTERS IMPORTED BY UNIQUEINTERNATIONAL"
    },
    "GLEA": {
        "id": "GLEA",
        "name": "GLEASON CORP."
    },
    "GLEB": {
        "id": "GLEB",
        "name": "GLENBROOK HOMES OF TENNESEE"
    },
    "GLEC": {
        "id": "GLEC",
        "name": "GLEN & CECIL MOBILE HOME MANUFACTURING& SALES"
    },
    "GLEH": {
        "id": "GLEH",
        "name": "GLEN MANOR HOMES"
    },
    "GLEL": {
        "id": "GLEL",
        "name": "GLENDALE MOBILE HOMES"
    },
    "GLEN": {
        "id": "GLEN",
        "name": "GLENHILL ROAD MACHINERY"
    },
    "GLFB": {
        "id": "GLFB",
        "name": "GULF BREEZE SPORT & XLT MFG BY GULF STREAM COACH"
    },
    "GLFC": {
        "id": "GLFC",
        "name": "GOLF CART OUTLET"
    },
    "GLFI": {
        "id": "GLFI",
        "name": "FEEDLINER MFG BY GLOBE FABRICATORS"
    },
    "GLID": {
        "id": "GLID",
        "name": "GLIDER MOBILE HOMES CORP"
    },
    "GLIT": {
        "id": "GLIT",
        "name": "GLITSCH"
    },
    "GLMB": {
        "id": "GLMB",
        "name": "GOLFMOBILE"
    },
    "GLMH": {
        "id": "GLMH",
        "name": "GLENWOOD MOBILE HOME"
    },
    "GLNA": {
        "id": "GLNA",
        "name": "GALLINA ITALY"
    },
    "GLND": {
        "id": "GLND",
        "name": "G & G TRAILERS GILLIAND & GILLIAND PRESTON GILLIAND"
    },
    "GLNL": {
        "id": "GLNL",
        "name": "GLEN-L TRAILERSLEWISTON"
    },
    "GLOB": {
        "id": "GLOB",
        "name": "GLOBE CAMPER MFG."
    },
    "GLOM": {
        "id": "GLOM",
        "name": "GLOBEMASTER MOBILE HOMES"
    },
    "GLOS": {
        "id": "GLOS",
        "name": "GLOBESTAR INDUSTRIES"
    },
    "GLOV": {
        "id": "GLOV",
        "name": "GLOVER PLASTIC SPRAYER"
    },
    "GLPC": {
        "id": "GLPC",
        "name": "GLOBAL POWER COMPONENTS MILWAUKEE WI"
    },
    "GLPR": {
        "id": "GLPR",
        "name": "GLASSPAR"
    },
    "GLPU": {
        "id": "GLPU",
        "name": "GLOBAL PUMP COMPANY LLC"
    },
    "GLRD": {
        "id": "GLRD",
        "name": "GLASRIDE TRAILERS"
    },
    "GLRV": {
        "id": "GLRV",
        "name": "GLENDALE RECREATIONAL VEHICLES STRATHROY"
    },
    "GLSC": {
        "id": "GLSC",
        "name": "GLASSIC"
    },
    "GLSS": {
        "id": "GLSS",
        "name": "GLASS STREAM TRAILERS NASHVILLE"
    },
    "GLST": {
        "id": "GLST",
        "name": "GLASTRON"
    },
    "GLTL": {
        "id": "GLTL",
        "name": "GEORGE R LAWSON TRAILERS"
    },
    "GLTM": {
        "id": "GLTM",
        "name": "GLOBE TRAILER MANUFACTURING"
    },
    "GLUT": {
        "id": "GLUT",
        "name": "G & L UTILITY TRAILERS"
    },
    "GLWO": {
        "id": "GLWO",
        "name": "EZZ PULLMFD. BY GLASS-WOOD PRODUCTS"
    },
    "GLXY": {
        "id": "GLXY",
        "name": "GALAXY AMERICA INC PORT CHARLOTTE FL"
    },
    "GMC": {
        "id": "GMC",
        "name": "GENERAL MOTORS"
    },
    "GMCO": {
        "id": "GMCO",
        "name": "GOMACO"
    },
    "GMEV": {
        "id": "GMEV",
        "name": "GUANGDONG MARSHELL ELECTRIC VEHICLE"
    },
    "GMPC": {
        "id": "GMPC",
        "name": "GENERAL MACHINE PRODUCTS"
    },
    "GMRE": {
        "id": "GMRE",
        "name": "GMR ENTERPRISES"
    },
    "GMST": {
        "id": "GMST",
        "name": "GEN STATE MFG"
    },
    "GNIS": {
        "id": "GNIS",
        "name": "GENESIS TRAILERS HOLLAND"
    },
    "GNMH": {
        "id": "GNMH",
        "name": "GREAT NORHTERN MANUFACTURED HOME"
    },
    "GNMI": {
        "id": "GNMI",
        "name": "GENERAL MARINE INDUSTRIES"
    },
    "GNPV": {
        "id": "GNPV",
        "name": "GENERAL PURPOSE VEHICLES"
    },
    "GNRE": {
        "id": "GNRE",
        "name": "GENERAL RICH ENTERPRISES RIVERSIDE"
    },
    "GNSH": {
        "id": "GNSH",
        "name": "GENERAL SHELTERS CENTER"
    },
    "GNTC": {
        "id": "GNTC",
        "name": "GIANT"
    },
    "GNTL": {
        "id": "GNTL",
        "name": "GUNNYS TRAILERS LAKE CITY FL"
    },
    "GNTW": {
        "id": "GNTW",
        "name": "GREAT NORTHERN TRAILER WORKS"
    },
    "GNTY": {
        "id": "GNTY",
        "name": "GENTRY MOTOR WORKS OF INDIANA"
    },
    "GNWF": {
        "id": "GNWF",
        "name": "GENERAL WELDING & FAB."
    },
    "GOAT": {
        "id": "GOAT",
        "name": "BILLY GOAT LAWN VACUUM"
    },
    "GOCO": {
        "id": "GOCO",
        "name": ""
    },
    "GOEB": {
        "id": "GOEB",
        "name": "GOEBEL TRAILER"
    },
    "GOEV": {
        "id": "GOEV",
        "name": "GORILLA VEHICLES"
    },
    "GOFF": {
        "id": "GOFF",
        "name": "GOFF MFG. CORP."
    },
    "GOGA": {
        "id": "GOGA",
        "name": "GO-TAG-ALONG"
    },
    "GOGE": {
        "id": "GOGE",
        "name": "GO-TEL LEASING CORP."
    },
    "GOGO": {
        "id": "GOGO",
        "name": "GOGOMOBILE"
    },
    "GOKA": {
        "id": "GOKA",
        "name": "GOKA SPORTS MOTOR"
    },
    "GOKT": {
        "id": "GOKT",
        "name": "GO KART"
    },
    "GOLA": {
        "id": "GOLA",
        "name": "GOLD LEAF ENGINEERING"
    },
    "GOLD": {
        "id": "GOLD",
        "name": "GOLD STAR MOBILE HOMES"
    },
    "GOLE": {
        "id": "GOLE",
        "name": "GOLDEN COACH MFG."
    },
    "GOLI": {
        "id": "GOLI",
        "name": "GOLIATH"
    },
    "GOLK": {
        "id": "GOLK",
        "name": "GOLDEN ISLE TRAILERS"
    },
    "GOLL": {
        "id": "GOLL",
        "name": "GOLDEN STATE MOBILE HOMES"
    },
    "GOLM": {
        "id": "GOLM",
        "name": "GOLDEN WEST MOBILE HOMES"
    },
    "GOLN": {
        "id": "GOLN",
        "name": "GO-LITE"
    },
    "GONA": {
        "id": "GONA",
        "name": "GONARD ENTERPRISES"
    },
    "GONU": {
        "id": "GONU",
        "name": "GOLDEN NUGGET TRAVEL TRAILER"
    },
    "GOOD": {
        "id": "GOOD",
        "name": "GOOD MFG."
    },
    "GOOE": {
        "id": "GOOE",
        "name": "GOODBRAND ENTERPRISES LLC SUPERIOR CO"
    },
    "GOOS": {
        "id": "GOOS",
        "name": "GOOSENECK TRAILER MFG."
    },
    "GOOY": {
        "id": "GOOY",
        "name": "GOODYEAR CAMPER MFG."
    },
    "GORB": {
        "id": "GORB",
        "name": "GORBETT BROTHERS FORT WORTH"
    },
    "GORD": {
        "id": "GORD",
        "name": "GORDON"
    },
    "GORE": {
        "id": "GORE",
        "name": "GORE TRAILER"
    },
    "GORM": {
        "id": "GORM",
        "name": "GORMAN-RUPP COMPANY"
    },
    "GORO": {
        "id": "GORO",
        "name": "GORDON SMITH &"
    },
    "GPFB": {
        "id": "GPFB",
        "name": "GREAT PLAINS FABRICATION"
    },
    "GPII": {
        "id": "GPII",
        "name": "GRIMES-PARKER INDUSTRIES"
    },
    "GPIN": {
        "id": "GPIN",
        "name": "GREAT PLAINS INDUSTRIES"
    },
    "GPRX": {
        "id": "GPRX",
        "name": "GRAND PRIX TRAILER MANUFACTURING"
    },
    "GPTL": {
        "id": "GPTL",
        "name": "GPS TRAILERS LLC BAINBRIDGE GA"
    },
    "GRAB": {
        "id": "GRAB",
        "name": "GRADY-WHITE BOAT"
    },
    "GRAC": {
        "id": "GRAC",
        "name": "GRACIELA"
    },
    "GRAD": {
        "id": "GRAD",
        "name": "GRADALL DIV OF WARNER & SWASEY"
    },
    "GRAE": {
        "id": "GRAE",
        "name": "GRACE"
    },
    "GRAF": {
        "id": "GRAF",
        "name": "GRAFFIN WELLPOINT CORP."
    },
    "GRAH": {
        "id": "GRAH",
        "name": "GRAHAM"
    },
    "GRAM": {
        "id": "GRAM",
        "name": "GRAMM"
    },
    "GRAO": {
        "id": "GRAO",
        "name": "GRACO"
    },
    "GRAP": {
        "id": "GRAP",
        "name": "GRAHAM-PAIGE"
    },
    "GRAT": {
        "id": "GRAT",
        "name": "GEORGES IRAT FRANCE"
    },
    "GRAV": {
        "id": "GRAV",
        "name": "GRAY-VELLE MOBILE HOMESDIV. OF STAHAN MANUFACTURING"
    },
    "GRBR": {
        "id": "GRBR",
        "name": "GRABER WELDING AND REPAIR LLC SO WHITLEY"
    },
    "GRCH": {
        "id": "GRCH",
        "name": "GERICH FIBERGLASS"
    },
    "GRDN": {
        "id": "GRDN",
        "name": "GREAT DANE INCORPORATED FARM & GARDEN EQUIP"
    },
    "GREA": {
        "id": "GREA",
        "name": "GREAT DIVIDE COACH MFG."
    },
    "GREB": {
        "id": "GREB",
        "name": "GREAT BEND MFG."
    },
    "GREE": {
        "id": "GREE",
        "name": "GREEN BOAT TRAILER"
    },
    "GREG": {
        "id": "GREG",
        "name": "AIR-FLO MOBILE HOME MFG BY GREGORY MFG."
    },
    "GREI": {
        "id": "GREI",
        "name": "GREENVILLAMFD. BY GREENVILLE MOBILE HOMES"
    },
    "GREK": {
        "id": "GREK",
        "name": "GREENKRAFT"
    },
    "GREL": {
        "id": "GREL",
        "name": "GREAT LAKES MOBILE HOMESDIV. OF GUERDON INDUSTRIES"
    },
    "GREM": {
        "id": "GREM",
        "name": "GREEN MFG."
    },
    "GREN": {
        "id": "GREN",
        "name": "GREENCASTLE COACH"
    },
    "GRER": {
        "id": "GRER",
        "name": "GREGORY TRAILER SALES"
    },
    "GRES": {
        "id": "GRES",
        "name": "GREAT ESCAPE"
    },
    "GRET": {
        "id": "GRET",
        "name": "GREEN TECH AUTOMOTIVE"
    },
    "GREV": {
        "id": "GREV",
        "name": "GREEVES"
    },
    "GRFM": {
        "id": "GRFM",
        "name": "GROUND FORCE MANUFACTURING"
    },
    "GRGI": {
        "id": "GRGI",
        "name": "GREGORY INDUSTRIES"
    },
    "GRGR": {
        "id": "GRGR",
        "name": "GREGOIRE FRANCE - AGRCULTURAL TRACTORS"
    },
    "GRHM": {
        "id": "GRHM",
        "name": "GRAHAM MANUFACTURED HOME"
    },
    "GRIF": {
        "id": "GRIF",
        "name": "GRIFFITH"
    },
    "GRIG": {
        "id": "GRIG",
        "name": "GRIGGS SOUTHERN SPORTZ ATLANTA TX"
    },
    "GRII": {
        "id": "GRII",
        "name": "GRADALL INDUSTRIES INC NEW PHILADELPHIA OH"
    },
    "GRIM": {
        "id": "GRIM",
        "name": "GRIMMER-SCHMIDT CORP.FRANKLIN"
    },
    "GRIS": {
        "id": "GRIS",
        "name": "GRAND ISLAND MANUFACTURING GRAND RAPIDS"
    },
    "GRIV": {
        "id": "GRIV",
        "name": "GREEN RIVER CABINS"
    },
    "GRIZ": {
        "id": "GRIZ",
        "name": "GRIZZLY MFG."
    },
    "GRLF": {
        "id": "GRLF",
        "name": "GRAND LAKE FABRICATION"
    },
    "GRLK": {
        "id": "GRLK",
        "name": "GREAT LAKE"
    },
    "GRLN": {
        "id": "GRLN",
        "name": "GARLAND TRAILER DESIGNS MONROE"
    },
    "GRMA": {
        "id": "GRMA",
        "name": "GRUMMAN ALLIED"
    },
    "GRMF": {
        "id": "GRMF",
        "name": "GERMFREE LABORATORIES"
    },
    "GRMP": {
        "id": "GRMP",
        "name": "GRUMPY'S CUSTOM MOTORCYCLES"
    },
    "GRND": {
        "id": "GRND",
        "name": "GRANDEUR CYCLES"
    },
    "GRNE": {
        "id": "GRNE",
        "name": "GREEN & GREEN MFG."
    },
    "GRNG": {
        "id": "GRNG",
        "name": "GREENGO TEK"
    },
    "GRNH": {
        "id": "GRNH",
        "name": "GREENHAVEN MOBILE HOUSE TRAILER"
    },
    "GRNV": {
        "id": "GRNV",
        "name": "GREENLAND VEHICLE"
    },
    "GROE": {
        "id": "GROE",
        "name": "GRONEWEGEN B.V.NETHERLANDS"
    },
    "GROU": {
        "id": "GROU",
        "name": "GROUT BROTHERS MASSACHUSETTS ANTIQUE VEHICLES 1899-1912"
    },
    "GROV": {
        "id": "GROV",
        "name": "GROVE"
    },
    "GRRG": {
        "id": "GRRG",
        "name": "G & R ENGINEERING"
    },
    "GRRM": {
        "id": "GRRM",
        "name": "GRM"
    },
    "GRSH": {
        "id": "GRSH",
        "name": "GOLD RUSH INC DENVER PA"
    },
    "GRSL": {
        "id": "GRSL",
        "name": "SOLITUDE"
    },
    "GRSO": {
        "id": "GRSO",
        "name": "COVENTRY MOBILE HOMEMFD. BY GREAT SOUTHWEST CORP."
    },
    "GRSP": {
        "id": "GRSP",
        "name": "GRAN SPREE MINI CAMPER"
    },
    "GRSY": {
        "id": "GRSY",
        "name": "GREASYHILL CUSTOMS SECTION"
    },
    "GRTL": {
        "id": "GRTL",
        "name": "GR TRAILERS"
    },
    "GRTR": {
        "id": "GRTR",
        "name": "GRA-TER INDUSTRIES"
    },
    "GRUE": {
        "id": "GRUE",
        "name": "GRUENDLER CRUSHER AND PULVERIZER"
    },
    "GRUM": {
        "id": "GRUM",
        "name": "GRUMMAN-OLSEN"
    },
    "GRVE": {
        "id": "GRVE",
        "name": "CLARK-GRAVELY CORP.GRAVELY TRACTORS"
    },
    "GRVL": {
        "id": "GRVL",
        "name": "GRAVELY AUTO & TRAILER SERVICE APACHE JUNCTION"
    },
    "GRVM": {
        "id": "GRVM",
        "name": "GROVE MFG."
    },
    "GRWL": {
        "id": "GRWL",
        "name": "GROWLER MANUFACTURING AND ENGINEERING STAR"
    },
    "GRYE": {
        "id": "GRYE",
        "name": "GRYCNER"
    },
    "GRYS": {
        "id": "GRYS",
        "name": "GREYSTONE MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "GRZL": {
        "id": "GRZL",
        "name": "GRIZZLY CORP."
    },
    "GSCM": {
        "id": "GSCM",
        "name": "COMMANDER BRAND MFG BY GOSHEN COACH"
    },
    "GSCR": {
        "id": "GSCR",
        "name": "GARDEN STATE CHASSIS REMANUFACTURING"
    },
    "GSEN": {
        "id": "GSEN",
        "name": "GOLD STAR ENTERPRISES SIKESTON"
    },
    "GSEQ": {
        "id": "GSEQ",
        "name": "GENERAL SAFETY EQUIPMENT"
    },
    "GSHN": {
        "id": "GSHN",
        "name": "GOSHEN COACH"
    },
    "GSIM": {
        "id": "GSIM",
        "name": "IMPULSE BRAND MFG BY GOSHEN COACH"
    },
    "GSIN": {
        "id": "GSIN",
        "name": "G. S. INDUSTRIES"
    },
    "GSM": {
        "id": "GSM",
        "name": "GSM"
    },
    "GSSS": {
        "id": "GSSS",
        "name": "GAS SERVICE AND SUPPLY"
    },
    "GSTI": {
        "id": "GSTI",
        "name": "GREY STATES INC DOUBLE SPRINGS AL"
    },
    "GSTM": {
        "id": "GSTM",
        "name": "GOLD STAR TRAILER MANUFACTURING FORT SCOTT"
    },
    "GSUP": {
        "id": "GSUP",
        "name": "GENESIS SUPREME RV"
    },
    "GTMI": {
        "id": "GTMI",
        "name": "G.T. MFG."
    },
    "GTRI": {
        "id": "GTRI",
        "name": "GATORAMP INC LAKEPORT CA"
    },
    "GTWY": {
        "id": "GTWY",
        "name": "GATEWAY MATERIALS"
    },
    "GUAN": {
        "id": "GUAN",
        "name": "GUANGYU MOTORCYCLE MANUFACTURE"
    },
    "GUEC": {
        "id": "GUEC",
        "name": "GUERRILLA CUBE MANITOBA CANADA MOBILE MARKETING TRAILER"
    },
    "GUER": {
        "id": "GUER",
        "name": "GUERDON INDUSTRIES"
    },
    "GUES": {
        "id": "GUES",
        "name": "GUEST INDUSTRIES"
    },
    "GUID": {
        "id": "GUID",
        "name": "GUIDON CAMPER TRAILER"
    },
    "GUIZ": {
        "id": "GUIZ",
        "name": "GUIZZO"
    },
    "GULA": {
        "id": "GULA",
        "name": "GUERILLA CUSTOMS"
    },
    "GULF": {
        "id": "GULF",
        "name": "GULF STATES MFG. CORP."
    },
    "GULL": {
        "id": "GULL",
        "name": "GULL WING INDUSTRIES"
    },
    "GUOY": {
        "id": "GUOY",
        "name": "GUOYU INDUSTRY AND TRADING CO"
    },
    "GUST": {
        "id": "GUST",
        "name": "GUSTAFSON MFG."
    },
    "GUTH": {
        "id": "GUTH",
        "name": "GUTHRIE TRAILER SALES"
    },
    "GUZO": {
        "id": "GUZO",
        "name": "GUAZZONI"
    },
    "GVMI": {
        "id": "GVMI",
        "name": "GVM INC PENNSYLVANIA"
    },
    "GWM": {
        "id": "GWM",
        "name": "OTTAWA TRUCK DIV."
    },
    "GWTI": {
        "id": "GWTI",
        "name": "GRANDE WEST TRANSPORTATION INTERNATIONAL"
    },
    "GWVC": {
        "id": "GWVC",
        "name": "GREAT WEST"
    },
    "GYEL": {
        "id": "GYEL",
        "name": "GYPSUM EXPRESS"
    },
    "GYPS": {
        "id": "GYPS",
        "name": "GYPSY CAMPERS"
    },
    "GZCD": {
        "id": "GZCD",
        "name": "GUNTER AND ZIMMERMAN CONSTRUCTION DIVISION INC RIPON CA"
    },
    "GZL": {
        "id": "GZL",
        "name": "GAZELLE"
    },
    "HAAI": {
        "id": "HAAI",
        "name": "HIGH ALTITUDE AVIATION INC WOODS CROSS"
    },
    "HABN": {
        "id": "HABN",
        "name": "HABAN"
    },
    "HACH": {
        "id": "HACH",
        "name": "HATCH MFG. VERMONT"
    },
    "HACK": {
        "id": "HACK",
        "name": "HACKNEY & SONS"
    },
    "HADL": {
        "id": "HADL",
        "name": "HADLEY TRAILER MFG."
    },
    "HADX": {
        "id": "HADX",
        "name": "HADDOX QUALITY TRAILERS OKLAHOMA"
    },
    "HAFA": {
        "id": "HAFA",
        "name": "HARTMAN-FABCO"
    },
    "HAFB": {
        "id": "HAFB",
        "name": "HARRIS FABRICATION"
    },
    "HAFL": {
        "id": "HAFL",
        "name": "HAFLINGER"
    },
    "HAHG": {
        "id": "HAHG",
        "name": "HAMMER HAAG STEEL"
    },
    "HAHM": {
        "id": "HAHM",
        "name": "HAHM EV CORPORATION GARDEN GROVE"
    },
    "HAHN": {
        "id": "HAHN",
        "name": "HAHN"
    },
    "HAIL": {
        "id": "HAIL",
        "name": "HAILI INDUSTRIAL"
    },
    "HAIR": {
        "id": "HAIR",
        "name": "HAIRGROVE INDUSTRIES"
    },
    "HALC": {
        "id": "HALC",
        "name": "HAL"
    },
    "HALE": {
        "id": "HALE",
        "name": "HALE HORSE TRAILER"
    },
    "HALL": {
        "id": "HALL",
        "name": "HALL"
    },
    "HALM": {
        "id": "HALM",
        "name": "HALLMARK MOTOR HOME"
    },
    "HALN": {
        "id": "HALN",
        "name": "HAULIN TRAILERS LLC"
    },
    "HALO": {
        "id": "HALO",
        "name": "HALO CYCLES"
    },
    "HALR": {
        "id": "HALR",
        "name": "HALLIBURTON"
    },
    "HALZ": {
        "id": "HALZ",
        "name": "HALS-EZ TRAILER MFG. WYMORE"
    },
    "HAMB": {
        "id": "HAMB",
        "name": "HAMBY"
    },
    "HAMH": {
        "id": "HAMH",
        "name": "HAMMERHEAD"
    },
    "HAMI": {
        "id": "HAMI",
        "name": "HAMILTON TRAILER"
    },
    "HAML": {
        "id": "HAML",
        "name": "HAMLITE"
    },
    "HAMM": {
        "id": "HAMM",
        "name": "HAMMERDOWN TRAILES & FABRICATION FLORIDA - DUMP"
    },
    "HAMP": {
        "id": "HAMP",
        "name": "HAMPTON HOMES"
    },
    "HAMR": {
        "id": "HAMR",
        "name": "HAMPTON AMUSEMENT RIDES"
    },
    "HANH": {
        "id": "HANH",
        "name": "HAN HENG ELECTROMECHANICAL"
    },
    "HANK": {
        "id": "HANK",
        "name": "HANK'S MOBILE HOME SERVICE"
    },
    "HANM": {
        "id": "HANM",
        "name": "HANMA EAGLE MODEL OF ATV ALSO SCOOTERS"
    },
    "HANN": {
        "id": "HANN",
        "name": "CLIFFORD B. HANNEY & SONS"
    },
    "HANO": {
        "id": "HANO",
        "name": "HANOVER KINGFISHER"
    },
    "HANS": {
        "id": "HANS",
        "name": "HANSON MACHINERY"
    },
    "HAOR": {
        "id": "HAOR",
        "name": "HAOREN ELECTROMECHANICAL"
    },
    "HAOS": {
        "id": "HAOS",
        "name": "HAOSEN MOTORCYCLE"
    },
    "HAPC": {
        "id": "HAPC",
        "name": "HAPPIER CAMPER"
    },
    "HAPP": {
        "id": "HAPP",
        "name": "HAPPY WANDERER INDUSTRY"
    },
    "HAPV": {
        "id": "HAPV",
        "name": "HAPPY VALLEY CAMPERS PORT MATILDA"
    },
    "HAPY": {
        "id": "HAPY",
        "name": "HAPPY TRAVL'R COACHES"
    },
    "HARB": {
        "id": "HARB",
        "name": "HARBORTOWN MOBILE HOMELEOLA"
    },
    "HARC": {
        "id": "HARC",
        "name": "HARRIS HONKER CAMPER MFG."
    },
    "HARD": {
        "id": "HARD",
        "name": "HARDEE MFG."
    },
    "HARF": {
        "id": "HARF",
        "name": "HARRISON TRAILER MFG."
    },
    "HARG": {
        "id": "HARG",
        "name": "HARDING TRAILER"
    },
    "HARH": {
        "id": "HARH",
        "name": "HART MOBILE HOMES CORP."
    },
    "HARI": {
        "id": "HARI",
        "name": "HARTFORD HOMES"
    },
    "HARK": {
        "id": "HARK",
        "name": "HARKLAU INDUSTRIES"
    },
    "HARL": {
        "id": "HARL",
        "name": "HARTLINE TRAVEL TRAILERS"
    },
    "HARM": {
        "id": "HARM",
        "name": "HART MFG."
    },
    "HARN": {
        "id": "HARN",
        "name": "HARNISCHFEGAR CORP.SUBSIDIARY OF HARNISCHFEGER P&H"
    },
    "HARR": {
        "id": "HARR",
        "name": "HARRINGTON MFG."
    },
    "HARS": {
        "id": "HARS",
        "name": "HARS"
    },
    "HART": {
        "id": "HART",
        "name": "HARTMAN"
    },
    "HARV": {
        "id": "HARV",
        "name": "HARVEST MOTOR HOME"
    },
    "HARW": {
        "id": "HARW",
        "name": "HARLOW TRAVEL TRAILER"
    },
    "HASI": {
        "id": "HASI",
        "name": "HILL AND SMITH INC"
    },
    "HAUA": {
        "id": "HAUA",
        "name": "HAUL-A-DAY TRAILERS"
    },
    "HAUI": {
        "id": "HAUI",
        "name": "HAULMARK INDUSTRIES"
    },
    "HAUL": {
        "id": "HAUL",
        "name": "HAULETTE TRAILER"
    },
    "HAUM": {
        "id": "HAUM",
        "name": "HAULMAX TRAILER COMPANY ELKHART"
    },
    "HAUR": {
        "id": "HAUR",
        "name": "HAULRITE"
    },
    "HAUT": {
        "id": "HAUT",
        "name": "HAUL-IT-ALL-FLATBED TRAILER"
    },
    "HAWG": {
        "id": "HAWG",
        "name": "HAWG TY CHOPPERS"
    },
    "HAWI": {
        "id": "HAWI",
        "name": "HAWAIIAN CHARIOT WHEELCHAIR MOTORBIKES"
    },
    "HAWK": {
        "id": "HAWK",
        "name": "HAWKEYE CAMPING TRAILER"
    },
    "HAWN": {
        "id": "HAWN",
        "name": "HAWN FREEWAY TRAILER SALES"
    },
    "HAWT": {
        "id": "HAWT",
        "name": "HAWTHORNE"
    },
    "HAWW": {
        "id": "HAWW",
        "name": "HAWKER WELL WORKS"
    },
    "HAYB": {
        "id": "HAYB",
        "name": "HAYBUSTER AGRICULTURAL PRODUCTS NORTH DAKOTA _TUB GRINDER"
    },
    "HAYE": {
        "id": "HAYE",
        "name": "ENEREXMFD. BY HAYES EQUIP. CORP."
    },
    "HAYN": {
        "id": "HAYN",
        "name": "HAYNES MFG."
    },
    "HAYS": {
        "id": "HAYS",
        "name": "HAYES LOG TRUCK"
    },
    "HAYV": {
        "id": "HAYV",
        "name": "HAYVAN OR HAY VAN"
    },
    "HBMI": {
        "id": "HBMI",
        "name": "HBM USA"
    },
    "HBTC": {
        "id": "HBTC",
        "name": "H & B TRAILER"
    },
    "HBTM": {
        "id": "HBTM",
        "name": "HUDSON BROTHERS TRAILER MFG INC INDIAN TRAIL NC"
    },
    "HBTS": {
        "id": "HBTS",
        "name": "HBT SMOKERS LLC GUNTERSVILLE AL"
    },
    "HBVW": {
        "id": "HBVW",
        "name": "HARBORVIEW CHOPPERS"
    },
    "HCAP": {
        "id": "HCAP",
        "name": "HIGH COUNTRY ALUMINUM PRODUCTS LLC HARTFORD VT"
    },
    "HCCH": {
        "id": "HCCH",
        "name": "HCCH INDUSTRIES WHITE CITY"
    },
    "HCCK": {
        "id": "HCCK",
        "name": "HEACOCK"
    },
    "HCHE": {
        "id": "HCHE",
        "name": "HER CHEE INDUSTRIAL"
    },
    "HCMH": {
        "id": "HCMH",
        "name": "HACIENDA MANUFACTURED HOME"
    },
    "HCMP": {
        "id": "HCMP",
        "name": "HIGH CAMP TRAILERS PORTLAND OR"
    },
    "HCST": {
        "id": "HCST",
        "name": "HILLCREST MANUFACTURING"
    },
    "HCTI": {
        "id": "HCTI",
        "name": "HOSS CUSTOM TRAILERS INC LAKE HAVASU CITY AZ"
    },
    "HCTK": {
        "id": "HCTK",
        "name": "HC-TRIKE"
    },
    "HDBT": {
        "id": "HDBT",
        "name": "HARDEEBILT TRAILERS INC."
    },
    "HDEL": {
        "id": "HDEL",
        "name": "H & D ELECTRICS"
    },
    "HDGC": {
        "id": "HDGC",
        "name": "HIGH DESERT GOLF CARS MADRAS OREGON LOW SPEED VEHICLES"
    },
    "HDHM": {
        "id": "HDHM",
        "name": "H. D. HUDSON MANUFACTURING"
    },
    "HDIN": {
        "id": "HDIN",
        "name": "H.D. INDUSTRIES"
    },
    "HDKP": {
        "id": "HDKP",
        "name": "HDK PLASTIC FACTORY LTD."
    },
    "HDSN": {
        "id": "HDSN",
        "name": "HUDSON TRAILER"
    },
    "HDTS": {
        "id": "HDTS",
        "name": "HIGH DESERT TRAILER SALES INC YATAHEY NEW MEXICO"
    },
    "HEAL": {
        "id": "HEAL",
        "name": "HEALD"
    },
    "HEAR": {
        "id": "HEAR",
        "name": "HEARTHSIDE MOBILE HOMESDIV. MODULINE INTERNATIONAL"
    },
    "HEAT": {
        "id": "HEAT",
        "name": "HEATHKIT"
    },
    "HECD": {
        "id": "HECD",
        "name": "HECKENDORN MFG."
    },
    "HECK": {
        "id": "HECK",
        "name": "HECKAMAN MFG."
    },
    "HECO": {
        "id": "HECO",
        "name": "HE"
    },
    "HEDW": {
        "id": "HEDW",
        "name": "HED-WAY"
    },
    "HEIL": {
        "id": "HEIL",
        "name": "HEIL"
    },
    "HEIN": {
        "id": "HEIN",
        "name": "HEINKEL"
    },
    "HEIT": {
        "id": "HEIT",
        "name": "HEILITE TRAILERS"
    },
    "HELB": {
        "id": "HELB",
        "name": "HELL BENT IRON"
    },
    "HELL": {
        "id": "HELL",
        "name": "HELL BOUND STEEL LLC"
    },
    "HELM": {
        "id": "HELM",
        "name": "HELM MFG."
    },
    "HELR": {
        "id": "HELR",
        "name": "HELMERS CUSTOM COACH"
    },
    "HELT": {
        "id": "HELT",
        "name": "HELTZEL"
    },
    "HEND": {
        "id": "HEND",
        "name": "HENDRICKSON MANUFACTURING"
    },
    "HENE": {
        "id": "HENE",
        "name": "HENRED-FRUEHAUF"
    },
    "HENK": {
        "id": "HENK",
        "name": "HENKE MANUFACTURING CORP."
    },
    "HENM": {
        "id": "HENM",
        "name": "HENDRIX MANUFACTURING"
    },
    "HENN": {
        "id": "HENN",
        "name": "HENDERSON MFG."
    },
    "HENR": {
        "id": "HENR",
        "name": "HENRY J."
    },
    "HENS": {
        "id": "HENS",
        "name": "HENSLEE MOBILE HOMES"
    },
    "HERB": {
        "id": "HERB",
        "name": "HERBST BROTHERS"
    },
    "HERC": {
        "id": "HERC",
        "name": "HERCULES"
    },
    "HERD": {
        "id": "HERD",
        "name": "HERD SEEDER"
    },
    "HERI": {
        "id": "HERI",
        "name": "HERITAGE MOBILE HOMES"
    },
    "HERM": {
        "id": "HERM",
        "name": "HERMISTON TRAILERS"
    },
    "HERO": {
        "id": "HERO",
        "name": "HERON TRAVEL CAMPERS"
    },
    "HERR": {
        "id": "HERR",
        "name": "HERRLI INDUSTRIES"
    },
    "HERT": {
        "id": "HERT",
        "name": "HERTER SNOWMOBILE TRAILER"
    },
    "HERZ": {
        "id": "HERZ",
        "name": "HERZIG MFG."
    },
    "HESC": {
        "id": "HESC",
        "name": "WOODS DIV."
    },
    "HESS": {
        "id": "HESS",
        "name": "HESSE CORP.KANSAS CITY"
    },
    "HEST": {
        "id": "HEST",
        "name": "HESTER PLOW"
    },
    "HETG": {
        "id": "HETG",
        "name": "HERITAGE-BY ABEL CORP ALSO SEE"
    },
    "HEWC": {
        "id": "HEWC",
        "name": "HEW"
    },
    "HEWE": {
        "id": "HEWE",
        "name": "HEIN-WERNER CORP."
    },
    "HEWI": {
        "id": "HEWI",
        "name": "HEWITT-LUCAS BODY"
    },
    "HEXO": {
        "id": "HEXO",
        "name": "HIGHLAND EXPEDITION OUTFITTERS COSBY TN"
    },
    "HFEC": {
        "id": "HFEC",
        "name": "HENSLEY FABRICATING & EQUIPMENT"
    },
    "HFTD": {
        "id": "HFTD",
        "name": "HATFIELD WELDING & TRAILERSALES"
    },
    "HFTY": {
        "id": "HFTY",
        "name": "HEFTY TRAILER MANUFACTURING & SALES"
    },
    "HGCI": {
        "id": "HGCI",
        "name": "HESSE CAMBLI GROUP INC ST JEAN SUR RICHEL QUEBEC CANADA"
    },
    "HGGL": {
        "id": "HGGL",
        "name": "HAGGLUNDS"
    },
    "HGHL": {
        "id": "HGHL",
        "name": "HIGHLANDER BRAND"
    },
    "HGHR": {
        "id": "HGHR",
        "name": "HIGHLAND RIDGE RV"
    },
    "HGJY": {
        "id": "HGJY",
        "name": "JOURNEYER MFG BY HIGHLAND RIDGE RV"
    },
    "HGLT": {
        "id": "HGLT",
        "name": "LIGHT"
    },
    "HGMR": {
        "id": "HGMR",
        "name": "MESA RIDGE"
    },
    "HGON": {
        "id": "HGON",
        "name": "HAGON"
    },
    "HGOR": {
        "id": "HGOR",
        "name": "OPEN RIDGE"
    },
    "HGRM": {
        "id": "HGRM",
        "name": "ROAMER"
    },
    "HGRS": {
        "id": "HGRS",
        "name": "RESIDENTIAL"
    },
    "HGRV": {
        "id": "HGRV",
        "name": "HAIRGROVE CONSTRUCTION EQUIPMENT"
    },
    "HGTO": {
        "id": "HGTO",
        "name": "HERITAGE ONE RV"
    },
    "HGYN": {
        "id": "HGYN",
        "name": "HUANGYAN SANYE GROUP"
    },
    "HHDX": {
        "id": "HHDX",
        "name": "DELUXE MFG BY HOLIDAY HOUSE RV LLC ELKHART IN"
    },
    "HHFG": {
        "id": "HHFG",
        "name": "HERMANN HARBECK FAHRZEUGBAU GMBH AND"
    },
    "HHTC": {
        "id": "HHTC",
        "name": "H & H TRAILER"
    },
    "HHTL": {
        "id": "HHTL",
        "name": "H AND H TRAILERS BY NOVAE CORP MARKLE IN"
    },
    "HHTS": {
        "id": "HHTS",
        "name": "H & H TRAILER SALES"
    },
    "HIAA": {
        "id": "HIAA",
        "name": "HIAWATHA MOBILE HOMES"
    },
    "HIAB": {
        "id": "HIAB",
        "name": "HIAB CRANES AND LOADERS"
    },
    "HIAW": {
        "id": "HIAW",
        "name": "HIAWATHA"
    },
    "HIBB": {
        "id": "HIBB",
        "name": "HIBBARD IRON WORKS OF HAMPTON VIRGINIA"
    },
    "HIBC": {
        "id": "HIBC",
        "name": "HIEBCO"
    },
    "HIBD": {
        "id": "HIBD",
        "name": "HIBDON MFG."
    },
    "HIBR": {
        "id": "HIBR",
        "name": "HI-BIRD MOTORCYCLE INDUSTRY"
    },
    "HIBT": {
        "id": "HIBT",
        "name": "HILBILT MFG."
    },
    "HICH": {
        "id": "HICH",
        "name": "HIGH CHAPARRAL"
    },
    "HICK": {
        "id": "HICK",
        "name": "HICKEY TRAIL-BLAZER"
    },
    "HICO": {
        "id": "HICO",
        "name": "HI"
    },
    "HICS": {
        "id": "HICS",
        "name": "HICKS"
    },
    "HICY": {
        "id": "HICY",
        "name": "HIGH COUNTRY TRAILERS"
    },
    "HIDE": {
        "id": "HIDE",
        "name": "HIDE-A-WAY CAMPER"
    },
    "HIDG": {
        "id": "HIDG",
        "name": "HIGHLY DANGEROUS MOTORCYCLES"
    },
    "HIGA": {
        "id": "HIGA",
        "name": "HIGHWAY CRUISERS"
    },
    "HIGC": {
        "id": "HIGC",
        "name": "HIGH COUNTTRY TRAILER SALES & MFG FAIRCHILD"
    },
    "HIGE": {
        "id": "HIGE",
        "name": "HIGHWAY SLEEPER CORP."
    },
    "HIGG": {
        "id": "HIGG",
        "name": "HIGGINS DELTA CORP OR HIGGINS-DELTA"
    },
    "HIGH": {
        "id": "HIGH",
        "name": "HIGHWAY TRAILER"
    },
    "HIGK": {
        "id": "HIGK",
        "name": "HIGHWAY TRAILER OF WISCONSIN &CALIFORNIA"
    },
    "HIGL": {
        "id": "HIGL",
        "name": "MASTER HOUSE MFG BY HIGHLANDER"
    },
    "HIGP": {
        "id": "HIGP",
        "name": "HIGH PEAK TRAILERS"
    },
    "HIGW": {
        "id": "HIGW",
        "name": "HIGHWAY MANUFACTURING"
    },
    "HIGY": {
        "id": "HIGY",
        "name": "HIGHWAY INDUSTRIESEDGERTON"
    },
    "HIHK": {
        "id": "HIHK",
        "name": "HITCH-HIKER MANUFACTURING"
    },
    "HILB": {
        "id": "HILB",
        "name": "HILLSBORO INDUSTRIES"
    },
    "HILC": {
        "id": "HILC",
        "name": "HILL COUNTRY HOMES"
    },
    "HILD": {
        "id": "HILD",
        "name": "HILL DUMP TRAILER"
    },
    "HILF": {
        "id": "HILF",
        "name": "HILL MFG."
    },
    "HILI": {
        "id": "HILI",
        "name": "HILLCO"
    },
    "HILL": {
        "id": "HILL",
        "name": "HILLMAN"
    },
    "HILM": {
        "id": "HILM",
        "name": "HILLCREST HOMES"
    },
    "HILN": {
        "id": "HILN",
        "name": "HILINE CARTS & CARS INC"
    },
    "HILO": {
        "id": "HILO",
        "name": "HILTON MOBILE HOMES"
    },
    "HILP": {
        "id": "HILP",
        "name": "HILLTOPPER MFG. CORP."
    },
    "HILS": {
        "id": "HILS",
        "name": "HILO SNYDER TRAVEL TRAILER"
    },
    "HILT": {
        "id": "HILT",
        "name": "HILLTOP MFG."
    },
    "HIND": {
        "id": "HIND",
        "name": "HINDUSTAN"
    },
    "HINE": {
        "id": "HINE",
        "name": "HINES MFG."
    },
    "HINO": {
        "id": "HINO",
        "name": "HINO"
    },
    "HINS": {
        "id": "HINS",
        "name": "HINSON MFG."
    },
    "HINT": {
        "id": "HINT",
        "name": "HINOMOTO TRACTOR SALES USA"
    },
    "HIRE": {
        "id": "HIRE",
        "name": "HIRE DUMP TRAILER"
    },
    "HIRO": {
        "id": "HIRO",
        "name": "HI ROLLIN TRAILER MFG."
    },
    "HISP": {
        "id": "HISP",
        "name": "HISPANO-SUIZA"
    },
    "HIST": {
        "id": "HIST",
        "name": "HIGHSTONE TRAILER"
    },
    "HISU": {
        "id": "HISU",
        "name": "HISUN MOTORS CORP USA MCKINNEY TX"
    },
    "HITA": {
        "id": "HITA",
        "name": "HI-TECH AUTOMOTIVE"
    },
    "HITB": {
        "id": "HITB",
        "name": "HIGH TIDE BOAT TRAILERS"
    },
    "HITC": {
        "id": "HITC",
        "name": "HITCHING RAIL TRAILER"
    },
    "HITM": {
        "id": "HITM",
        "name": "HI-TECH MARINE"
    },
    "HIUT": {
        "id": "HIUT",
        "name": "HI-U-TRAILER MFG."
    },
    "HIVW": {
        "id": "HIVW",
        "name": "HIGH VIEW MANUFACTURING ALTA VISTA"
    },
    "HIWY": {
        "id": "HIWY",
        "name": "HI-WAY STAR"
    },
    "HJVT": {
        "id": "HJVT",
        "name": "HJV TRAILERS"
    },
    "HKEG": {
        "id": "HKEG",
        "name": "TRUCK TRAILER SALES & SERVICE YANKTON SOUTH DAKOTA"
    },
    "HKET": {
        "id": "HKET",
        "name": "HAWK TRAILERS INC MARIETTA NC"
    },
    "HKRY": {
        "id": "HKRY",
        "name": "HICKORY KING HORSE TRAILER"
    },
    "HLAL": {
        "id": "HLAL",
        "name": "HAUL ALL"
    },
    "HLAR": {
        "id": "HLAR",
        "name": "HAUL-A-ROUND TRAILERS ELGIN"
    },
    "HLBG": {
        "id": "HLBG",
        "name": "BRIDGEVIEW MFG BY H.L. ENTERPRISE"
    },
    "HLDH": {
        "id": "HLDH",
        "name": "HOLANDIA HOLDER"
    },
    "HLDR": {
        "id": "HLDR",
        "name": "HELDER MFG."
    },
    "HLEI": {
        "id": "HLEI",
        "name": "HL ENTERPRISE"
    },
    "HLFM": {
        "id": "HLFM",
        "name": "HAUL - A - FAME INC. SALEM OREGON"
    },
    "HLHY": {
        "id": "HLHY",
        "name": "HYLINE MFG BY H.L. ENTERPRISE"
    },
    "HLKP": {
        "id": "HLKP",
        "name": "HOLTKAMP DIVISION CENTRALIA IL"
    },
    "HLLR": {
        "id": "HLLR",
        "name": "HELLER TRUCK BODY CORP NEW JERSEY"
    },
    "HLLT": {
        "id": "HLLT",
        "name": "HULL TRAILERS INC"
    },
    "HLME": {
        "id": "HLME",
        "name": "HOLMES TRAILERS UTILITY & LIGHT CONSTRUCTION ASHLAND"
    },
    "HLMK": {
        "id": "HLMK",
        "name": "HAULMARK INDUSTRIES INC ELKHART IN"
    },
    "HLNR": {
        "id": "HLNR",
        "name": "HIGHLINER TRAILERS"
    },
    "HLPK": {
        "id": "HLPK",
        "name": "HOLLY PARKS"
    },
    "HLRI": {
        "id": "HLRI",
        "name": "HIGH-LITE RIDES"
    },
    "HLSC": {
        "id": "HLSC",
        "name": "HLT LIMITED SPORT CLUB HUMBOLDT IA"
    },
    "HLTI": {
        "id": "HLTI",
        "name": "HAYS LIQUID TRANSPORT"
    },
    "HLTL": {
        "id": "HLTL",
        "name": "HAWKEYE LEISURE TRAILER"
    },
    "HLTM": {
        "id": "HLTM",
        "name": "HLT LIMITED HUMBOLDT"
    },
    "HLTT": {
        "id": "HLTT",
        "name": "HAULOTTE GROUP BIL JAX ARCHBOLD OH"
    },
    "HLWD": {
        "id": "HLWD",
        "name": "HELLWOOD TRAILER"
    },
    "HLZT": {
        "id": "HLZT",
        "name": "HOLZ TRAILER COMPANY LYNDEN"
    },
    "HMBT": {
        "id": "HMBT",
        "name": "HAMBLET MACHINE COMPANY ANTIQUE AUTOMOBILES"
    },
    "HMDE": {
        "id": "HMDE",
        "name": "HOMEMADE TRAILER CODE"
    },
    "HME": {
        "id": "HME",
        "name": "HME"
    },
    "HMGP": {
        "id": "HMGP",
        "name": "H AND M GOPHER PATROL TULELAKE"
    },
    "HMLT": {
        "id": "HMLT",
        "name": "HAMLET CUSTOM HAULERS"
    },
    "HMMR": {
        "id": "HMMR",
        "name": "HAMMAR LIFT INC HAMMAR MASKIN AB SWEDEN"
    },
    "HMRL": {
        "id": "HMRL",
        "name": "HAMM ROLLER DIVISION OF WIRTGEN GROUP TIRSCHENREUTH GERMANY"
    },
    "HMST": {
        "id": "HMST",
        "name": "HOMESTEADER"
    },
    "HMTR": {
        "id": "HMTR",
        "name": "HAUL MASTER MFG BY CHANGZHOU NANXIASHU TOOL FACTORY - CHINA VMA"
    },
    "HMVE": {
        "id": "HMVE",
        "name": "FREE-WAY II"
    },
    "HNLC": {
        "id": "HNLC",
        "name": "H.N.L. CORP AND OR HENRY L. LANCIANI CORP."
    },
    "HNLY": {
        "id": "HNLY",
        "name": "HENLEY'S TRAILER"
    },
    "HNMN": {
        "id": "HNMN",
        "name": "HEINEMANN - GERMANY TRAILERS"
    },
    "HNMR": {
        "id": "HNMR",
        "name": "HAHN MOTORS"
    },
    "HNNG": {
        "id": "HNNG",
        "name": "HANNIGAN MOTORSPORTS"
    },
    "HNRY": {
        "id": "HNRY",
        "name": "HENRY AND WRIGHT CORPORATION"
    },
    "HNSM": {
        "id": "HNSM",
        "name": "HENSIM USA"
    },
    "HNSN": {
        "id": "HNSN",
        "name": "HANSEN MFG. COMPANY LEBANON MO"
    },
    "HNSO": {
        "id": "HNSO",
        "name": "HANSON CAMPING TRAILER"
    },
    "HNTR": {
        "id": "HNTR",
        "name": "HUNTER MARINE ALACHUA FL"
    },
    "HOAL": {
        "id": "HOAL",
        "name": "ALTERA BRAND MFG BY HOLIDAY RAMBLER"
    },
    "HOAN": {
        "id": "HOAN",
        "name": "HOLMAN"
    },
    "HOBA": {
        "id": "HOBA",
        "name": "HOBART BROTHERS"
    },
    "HOBB": {
        "id": "HOBB",
        "name": "HOBBS TRAILERS DIV OF FRUEHAUF CORP FORT WORTH"
    },
    "HOBI": {
        "id": "HOBI",
        "name": "HOBIE"
    },
    "HOBN": {
        "id": "HOBN",
        "name": "BANDIT BRAND MFG BY HOOSIER HORSE TRAILERS"
    },
    "HOBO": {
        "id": "HOBO",
        "name": "HO-BO TRAILERS"
    },
    "HODA": {
        "id": "HODA",
        "name": "ACE"
    },
    "HODG": {
        "id": "HODG",
        "name": "HODGES CUSTOM HAULERS BENTON"
    },
    "HODP": {
        "id": "HODP",
        "name": "DESPERADO BRAND MFG BY HOOSIER HORSE TRAILERS"
    },
    "HOF": {
        "id": "HOF",
        "name": "HOFFMAN"
    },
    "HOGG": {
        "id": "HOGG",
        "name": "HOGG AND DAVIS INC LONG BEACH"
    },
    "HOGW": {
        "id": "HOGW",
        "name": "HOG WILD TRAILER"
    },
    "HOHT": {
        "id": "HOHT",
        "name": "HOOSIER HORSE TRAILERS"
    },
    "HOIN": {
        "id": "HOIN",
        "name": "HORNET INDUSTRIES"
    },
    "HOL": {
        "id": "HOL",
        "name": "HOLSCLAW BROTHERS"
    },
    "HOLA": {
        "id": "HOLA",
        "name": "HOLLAND TRANSPLANTER"
    },
    "HOLD": {
        "id": "HOLD",
        "name": "HOLDEN"
    },
    "HOLE": {
        "id": "HOLE",
        "name": "HOLDEN TRAILER MFG."
    },
    "HOLH": {
        "id": "HOLH",
        "name": "HOLIDAY HOUSE_RV LLC ELKHART IN"
    },
    "HOLI": {
        "id": "HOLI",
        "name": "HOLIDAY INDUSTRIES"
    },
    "HOLK": {
        "id": "HOLK",
        "name": "HOLMES WRECKER"
    },
    "HOLL": {
        "id": "HOLL",
        "name": "HOLLAND TRAILERS"
    },
    "HOLM": {
        "id": "HOLM",
        "name": "FAIRWAY MFG BY HOLIDAY HOMES"
    },
    "HOLN": {
        "id": "HOLN",
        "name": "HOLSTEIN MFG."
    },
    "HOLO": {
        "id": "HOLO",
        "name": "HOLIDAY ROYAL TRAVEL TRAILER"
    },
    "HOLP": {
        "id": "HOLP",
        "name": "HOLE PUMPS"
    },
    "HOLR": {
        "id": "HOLR",
        "name": "ALUMA-LITE MFG BY HOLIDAY RAMBLER CORP."
    },
    "HOLS": {
        "id": "HOLS",
        "name": "HOLIDAY TRAILER SALES & MFG."
    },
    "HOLT": {
        "id": "HOLT",
        "name": "HOLT TRAILER"
    },
    "HOLU": {
        "id": "HOLU",
        "name": "HOLLAND CAMPER"
    },
    "HOLV": {
        "id": "HOLV",
        "name": "HOLVA TRAVEL TRAILER"
    },
    "HOLW": {
        "id": "HOLW",
        "name": "HOLLYWOOD MOBILE HOMES"
    },
    "HOLY": {
        "id": "HOLY",
        "name": "HOLLY INDUSTRIES"
    },
    "HOLZ": {
        "id": "HOLZ",
        "name": "HOLMES-CRAFT"
    },
    "HOMA": {
        "id": "HOMA",
        "name": "HOLIDAY MARINE SALES"
    },
    "HOMD": {
        "id": "HOMD",
        "name": "HOMEMADE MOTORCYCLE CODE"
    },
    "HOME": {
        "id": "HOME",
        "name": "ALY ALLIANCEMFD. BY HOMETTE CORP."
    },
    "HOMG": {
        "id": "HOMG",
        "name": "HOMEGROWN TRAILERS WOODINVILLE VA"
    },
    "HOMI": {
        "id": "HOMI",
        "name": "HOMES OF MERIT INC"
    },
    "HOML": {
        "id": "HOML",
        "name": "HOMELITE DIV OF TEXTRON INC"
    },
    "HOMM": {
        "id": "HOMM",
        "name": "HOMEWAY MOBILE HOMES"
    },
    "HOMS": {
        "id": "HOMS",
        "name": "ERNEST HOLMES DIV."
    },
    "HOND": {
        "id": "HOND",
        "name": "HONDA"
    },
    "HONE": {
        "id": "HONE",
        "name": "HONEYWELL"
    },
    "HONG": {
        "id": "HONG",
        "name": "HONGKI OR HONG-CHI"
    },
    "HONL": {
        "id": "HONL",
        "name": "HONLING MOTORCYCLE CORP"
    },
    "HONO": {
        "id": "HONO",
        "name": "HONORBUILT TRAILER MFG."
    },
    "HOOD": {
        "id": "HOOD",
        "name": "HOOD EQUIPMENT"
    },
    "HOOP": {
        "id": "HOOP",
        "name": "HOOPER TRAILER SALES"
    },
    "HOOS": {
        "id": "HOOS",
        "name": "HOOSIER MOBILE HOMES"
    },
    "HOP": {
        "id": "HOP",
        "name": "HOP CAP P"
    },
    "HOPK": {
        "id": "HOPK",
        "name": "HOPKINS MFG. CORP."
    },
    "HORC": {
        "id": "HORC",
        "name": "HORCH LIMOUSINE"
    },
    "HORE": {
        "id": "HORE",
        "name": "RENO BRAND MFG BY HOLIDAY RAMBLER"
    },
    "HORG": {
        "id": "HORG",
        "name": "RENEGADE BRAND MFG BY HOOSIER HORSE TRAILERS"
    },
    "HORI": {
        "id": "HORI",
        "name": "HORIZON MOBILE HOMES"
    },
    "HORN": {
        "id": "HORN",
        "name": "HORNER-GOLEM"
    },
    "HORS": {
        "id": "HORS",
        "name": "HORSEMAN CAMPER"
    },
    "HORT": {
        "id": "HORT",
        "name": "HORNET FLATBED TRAILER"
    },
    "HORX": {
        "id": "HORX",
        "name": "HOREX"
    },
    "HORZ": {
        "id": "HORZ",
        "name": "HORIZON MOTOR HOME"
    },
    "HOST": {
        "id": "HOST",
        "name": "HOST INDUSTRIES CAMPERS AND MOTORCOACHES"
    },
    "HOTC": {
        "id": "HOTC",
        "name": "HOTCHKISS"
    },
    "HOUE": {
        "id": "HOUE",
        "name": "HOULE INDUSTRIES"
    },
    "HOUF": {
        "id": "HOUF",
        "name": "HOUSE OF ARCHITECTURE"
    },
    "HOUG": {
        "id": "HOUG",
        "name": "HOUGH BROTHERS"
    },
    "HOUH": {
        "id": "HOUH",
        "name": "HOUSE OF HARMONY"
    },
    "HOWA": {
        "id": "HOWA",
        "name": "CYCLE-SLEEPERMFD. BY HOWARD MFG."
    },
    "HOWC": {
        "id": "HOWC",
        "name": "HOWARD COMMERCIAL TURF EQUIPMENT"
    },
    "HOWD": {
        "id": "HOWD",
        "name": "HOWDAN MFG."
    },
    "HOWE": {
        "id": "HOWE",
        "name": "HOWE ENGINEERED SALES"
    },
    "HOWH": {
        "id": "HOWH",
        "name": "HOWHIT MACHNERY MANUFACTURE"
    },
    "HOWL": {
        "id": "HOWL",
        "name": "HOW-LO CAMPERS"
    },
    "HOWR": {
        "id": "HOWR",
        "name": "HOWARD ROTAVATOR"
    },
    "HOWS": {
        "id": "HOWS",
        "name": "HOWSE IMPLEMENT"
    },
    "HPMI": {
        "id": "HPMI",
        "name": "HYDRA PLATFORMS MFG INC GASTONIA NC"
    },
    "HPWC": {
        "id": "HPWC",
        "name": "HORSEPOWER & CHROME LLC RANCHO CUCAMONGA"
    },
    "HRBC": {
        "id": "HRBC",
        "name": "BRECKENRIDGE MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRBK": {
        "id": "HRBK",
        "name": "HARD BIKES LLC HERMITAGE"
    },
    "HRBN": {
        "id": "HRBN",
        "name": "HARBEN"
    },
    "HRBR": {
        "id": "HRBR",
        "name": "HARBOR HOMES"
    },
    "HRCL": {
        "id": "HRCL",
        "name": "HERCULES TRAILER INC MONMOUTH JUNCTION NJ"
    },
    "HRCY": {
        "id": "HRCY",
        "name": "CYCLONE BRAND MFG BY HEARTLAND RECREATIONAL VEHICLES LLC"
    },
    "HRDC": {
        "id": "HRDC",
        "name": "HARDCORE CHOPPERS"
    },
    "HRDK": {
        "id": "HRDK",
        "name": "HARDKORE KARTS"
    },
    "HREG": {
        "id": "HREG",
        "name": "EDGE MODEL"
    },
    "HRFA": {
        "id": "HRFA",
        "name": "FAIRFIELD MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRFL": {
        "id": "HRFL",
        "name": "FINE LIFE MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRFU": {
        "id": "HRFU",
        "name": "FUEL BRAND MANUFACTURED BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRG": {
        "id": "HRG",
        "name": "HRG"
    },
    "HRGA": {
        "id": "HRGA",
        "name": "GATEWAY MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRIG": {
        "id": "HRIG",
        "name": "HEARTLAND RIG INTERNATIONAL TRAILERS"
    },
    "HRLD": {
        "id": "HRLD",
        "name": "HEARTLAND RECREATIONAL VEHICLES LLC ELKHART"
    },
    "HRLI": {
        "id": "HRLI",
        "name": "LITHIUM MFG BY HEARTLAND RECREATIONAL VEHICLES ELKHART IN"
    },
    "HRLL": {
        "id": "HRLL",
        "name": "HARRELL MFR.NORTH CAROLINA"
    },
    "HRLM": {
        "id": "HRLM",
        "name": "HEIRLOOM INC TUALATIN OR"
    },
    "HRLV": {
        "id": "HRLV",
        "name": "LAKEVIEW MODEL MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRME": {
        "id": "HRME",
        "name": "MILESTONE MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRML": {
        "id": "HRML",
        "name": "MALLARD"
    },
    "HRMR": {
        "id": "HRMR",
        "name": "HARMAR INC."
    },
    "HRMY": {
        "id": "HRMY",
        "name": "HARLEY MURRAY INC STOCKTON CALIFORNIA - TRAILERS HD HARLEY-DAVIDSON"
    },
    "HRNP": {
        "id": "HRNP",
        "name": "NORTH PEAK BRAND MFG BY HEARTLAND RECREATIONAL VEH'S LLC"
    },
    "HRNY": {
        "id": "HRNY",
        "name": "HARNEY COACH OR HARNEY COACH WORKS MOTORHOMES & COACHES PRODUCED"
    },
    "HROK": {
        "id": "HROK",
        "name": "OAKMONT MODEL"
    },
    "HROR": {
        "id": "HROR",
        "name": "OPEN RANGE MFG BY HIGHLAND RIDGE RV A DIV OF JAY"
    },
    "HRPC": {
        "id": "HRPC",
        "name": "PERFECT COTTAGE MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRRE": {
        "id": "HRRE",
        "name": "RESORT MODEL"
    },
    "HRRM": {
        "id": "HRRM",
        "name": "HARRIMAN SALES"
    },
    "HRSE": {
        "id": "HRSE",
        "name": "HORSE CREEK MANUFACTURING COOKVILLE"
    },
    "HRTC": {
        "id": "HRTC",
        "name": "TERRY CLASSIC BRAND MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRTG": {
        "id": "HRTG",
        "name": "HERITAGE CUSTOM TRAILERS BENTON"
    },
    "HRTI": {
        "id": "HRTI",
        "name": "HERTER'S"
    },
    "HRTK": {
        "id": "HRTK",
        "name": "HARMON TANK"
    },
    "HRTL": {
        "id": "HRTL",
        "name": "HEARTLAND TRAILER MANUFACTURING & SALES"
    },
    "HRTM": {
        "id": "HRTM",
        "name": "HEARTLAND MOTORCYCLE COMPANY OR HEARTLAND MOTORCYCLE INC OMAHA"
    },
    "HRTN": {
        "id": "HRTN",
        "name": "HORTON VANS"
    },
    "HRTO": {
        "id": "HRTO",
        "name": "HORTON VANS"
    },
    "HRTR": {
        "id": "HRTR",
        "name": "HERTER"
    },
    "HRUB": {
        "id": "HRUB",
        "name": "FRANK HRUBETZ &"
    },
    "HRVT": {
        "id": "HRVT",
        "name": "HARVEY TRAILERS SALES"
    },
    "HRVY": {
        "id": "HRVY",
        "name": "FORCE MANUFACTURING"
    },
    "HRWL": {
        "id": "HRWL",
        "name": "HERRIN WELDING SERVICE"
    },
    "HRXT": {
        "id": "HRXT",
        "name": "XTENDABLES MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "HRZN": {
        "id": "HRZN",
        "name": "HORIZON 69O4"
    },
    "HRZT": {
        "id": "HRZT",
        "name": "HORIZON TRIKES ALBUQUERQUE"
    },
    "HSKE": {
        "id": "HSKE",
        "name": "HUSKEE LAWN & GARDEN EQUIPMENT"
    },
    "HSKY": {
        "id": "HSKY",
        "name": "HUS-KEY MFG."
    },
    "HSMI": {
        "id": "HSMI",
        "name": "H & S MFG."
    },
    "HSPT": {
        "id": "HSPT",
        "name": "HOT SPRINGS PERFORMANCE TRAILERS HOT SPRINGS ARKANSAS"
    },
    "HSQV": {
        "id": "HSQV",
        "name": "HUSQVARNA"
    },
    "HSTR": {
        "id": "HSTR",
        "name": "HOSTAR MARINE TRANSPORT SYSTEMS"
    },
    "HSUM": {
        "id": "HSUM",
        "name": "HOT SUMMER INDUSTRIES JACKSON"
    },
    "HSVG": {
        "id": "HSVG",
        "name": "HINES SPECIALTY VEHICLE GROUP CHASIS AND SPECIALITY VEHICLES NEW"
    },
    "HTCH": {
        "id": "HTCH",
        "name": "HITACHI"
    },
    "HTCI": {
        "id": "HTCI",
        "name": "HERRING TANK COMPANY"
    },
    "HTCK": {
        "id": "HTCK",
        "name": "HITCHCOCK"
    },
    "HTFD": {
        "id": "HTFD",
        "name": "HATFIELD WELDING & TRAILER SALES"
    },
    "HTGP": {
        "id": "HTGP",
        "name": "HERITAGE PARK MODELS - ELKHART"
    },
    "HTLC": {
        "id": "HTLC",
        "name": "HAULINIT TRAILERS LLC LAKE HAVASU CITY AZ"
    },
    "HUAD": {
        "id": "HUAD",
        "name": "HUADONG AUTOMOBILE"
    },
    "HUAM": {
        "id": "HUAM",
        "name": "HUANAN MOTORS GROUP"
    },
    "HUAN": {
        "id": "HUAN",
        "name": "HUANSONG INDUSTRIES GROUP"
    },
    "HUAW": {
        "id": "HUAW",
        "name": "HUAWIN MOTORCYCLE"
    },
    "HUBE": {
        "id": "HUBE",
        "name": "J. M. HUBER CORP."
    },
    "HUBG": {
        "id": "HUBG",
        "name": "HUB GROUP"
    },
    "HUBR": {
        "id": "HUBR",
        "name": "KEITH HUBER CORPORATION GULFPORT"
    },
    "HUBT": {
        "id": "HUBT",
        "name": "HUBERT METAL PRODUCTS"
    },
    "HUCK": {
        "id": "HUCK",
        "name": "HUCKWAGONS LLC STANWOOD WA"
    },
    "HUDS": {
        "id": "HUDS",
        "name": "HUDSON"
    },
    "HUFF": {
        "id": "HUFF",
        "name": "HUFFY"
    },
    "HUFY": {
        "id": "HUFY",
        "name": "HUFFY CORP."
    },
    "HUGH": {
        "id": "HUGH",
        "name": "HUGHES TRAILERS OF JACKSON"
    },
    "HUKY": {
        "id": "HUKY",
        "name": "HUSKY CARGO OR HUSKY CARGO"
    },
    "HULC": {
        "id": "HULC",
        "name": "HUL"
    },
    "HULL": {
        "id": "HULL",
        "name": "HULL"
    },
    "HULN": {
        "id": "HULN",
        "name": "HULL'S NEW & USED EQUIPMENT & TRAILER SALES LAMAR"
    },
    "HUMB": {
        "id": "HUMB",
        "name": "HUMBER"
    },
    "HUME": {
        "id": "HUME",
        "name": "HUMBEE SURREY"
    },
    "HUML": {
        "id": "HUML",
        "name": "HUMMEL"
    },
    "HUMM": {
        "id": "HUMM",
        "name": "HUMMER FORMERLY CODED AS AMGN"
    },
    "HUMS": {
        "id": "HUMS",
        "name": "HUMES TRUCK & TRAILER MFG. CO"
    },
    "HUNE": {
        "id": "HUNE",
        "name": "HUNTER CUSTOM TRAILERS KINGSBURY"
    },
    "HUNG": {
        "id": "HUNG",
        "name": "HUNTINGTON DISTRIBUTORS"
    },
    "HUNM": {
        "id": "HUNM",
        "name": "HUNT'S TRAILER MFG."
    },
    "HUNT": {
        "id": "HUNT",
        "name": "HUNTSMAN"
    },
    "HUPM": {
        "id": "HUPM",
        "name": "HUPMOBILE"
    },
    "HURC": {
        "id": "HURC",
        "name": "HUR"
    },
    "HURI": {
        "id": "HURI",
        "name": "HURRICANE MFG BY THOR MOTOR COACH INC."
    },
    "HURO": {
        "id": "HURO",
        "name": "HURON INDUSTRIES"
    },
    "HURR": {
        "id": "HURR",
        "name": "HURRICANE CARGO FITZGERALD"
    },
    "HURS": {
        "id": "HURS",
        "name": "HURST TRAILERS"
    },
    "HURU": {
        "id": "HURU",
        "name": "HUSTLER-RUSTLER"
    },
    "HUSA": {
        "id": "HUSA",
        "name": "HUSABERG MOTORCYCLES"
    },
    "HUSF": {
        "id": "HUSF",
        "name": "HUSKY FARM EQUIPMENT LTD.ONTARIO"
    },
    "HUSK": {
        "id": "HUSK",
        "name": "HUSKY"
    },
    "HUSS": {
        "id": "HUSS",
        "name": "HUSS SALES"
    },
    "HUST": {
        "id": "HUST",
        "name": "HUSTLER BOAT TRAILERPOWELL"
    },
    "HUTC": {
        "id": "HUTC",
        "name": "HUT"
    },
    "HUTN": {
        "id": "HUTN",
        "name": "HUTTON MANUFACTURING MANILA"
    },
    "HUTR": {
        "id": "HUTR",
        "name": "HUNTER STRUCTURES"
    },
    "HUWA": {
        "id": "HUWA",
        "name": "HUBER-WARCO"
    },
    "HUYE": {
        "id": "HUYE",
        "name": "HUYE MOTORCYCLE"
    },
    "HVAC": {
        "id": "HVAC",
        "name": "HI-VAC CORPORATION MARIETTA OH INDUSTRIAL VACUUM EQUIPMENT"
    },
    "HWHW": {
        "id": "HWHW",
        "name": "HOWE AND HOWE TECHNOLOGIES"
    },
    "HWKE": {
        "id": "HWKE",
        "name": "HAWKEYE EAGLE TRANSPORTATION EQUIPMENT"
    },
    "HWKL": {
        "id": "HWKL",
        "name": "HAWKEYE LEISURE TRAILER LTD. OR HLT LTD. HUMBOLDT"
    },
    "HWKM": {
        "id": "HWKM",
        "name": "HAWKINS MOTOR COACH HMC"
    },
    "HWKN": {
        "id": "HWKN",
        "name": "HAWKLINE"
    },
    "HWKS": {
        "id": "HWKS",
        "name": "HAWKES MANUFACTURING EAST GRAND FORKS"
    },
    "HWKT": {
        "id": "HWKT",
        "name": "HAWK TRAILERS"
    },
    "HWMC": {
        "id": "HWMC",
        "name": "H AND W MFG CO"
    },
    "HWRO": {
        "id": "HWRO",
        "name": "HEWITT-ROBINS CRUSHING & VIBRATINGDIV. LITTON SYSTEMS"
    },
    "HWTH": {
        "id": "HWTH",
        "name": "JOHN R HOLLINGSWORTH CO"
    },
    "HWTM": {
        "id": "HWTM",
        "name": "HANK WILLIAMS TRAILER MFG. SHELBYVILLE"
    },
    "HWTT": {
        "id": "HWTT",
        "name": "HOWE TRUCK & TRAILER TROY"
    },
    "HWYP": {
        "id": "HWYP",
        "name": "HIGHWAY PRODUCTS"
    },
    "HYAF": {
        "id": "HYAF",
        "name": "AMERICAN FASTBACKS MFG BY ERWIN HYMER GROUP"
    },
    "HYAK": {
        "id": "HYAK",
        "name": "AKTIV BRAND MFG BY ERWIN HYMER GROUP NORTH AMERICA INC"
    },
    "HYCA": {
        "id": "HYCA",
        "name": "HYCALOADER"
    },
    "HYCR": {
        "id": "HYCR",
        "name": "CARADO"
    },
    "HYDB": {
        "id": "HYDB",
        "name": "HYDE DRIFT BOATS IDAHO FALLS ID"
    },
    "HYDE": {
        "id": "HYDE",
        "name": "HYDE CORP.HURST"
    },
    "HYDF": {
        "id": "HYDF",
        "name": "HYDRA-FAB MANUFACTURING INC. PHENIX CITY"
    },
    "HYDO": {
        "id": "HYDO",
        "name": "HYDRO ENGINEERING"
    },
    "HYDR": {
        "id": "HYDR",
        "name": "HYDR-SPORTS"
    },
    "HYDY": {
        "id": "HYDY",
        "name": "HY-DYNAMIC DIV.BUCYRUS-ERIE"
    },
    "HYLD": {
        "id": "HYLD",
        "name": "HYLANDER UTILITY TRAILER"
    },
    "HYLN": {
        "id": "HYLN",
        "name": "HY-LINE ENTERPRISES"
    },
    "HYLT": {
        "id": "HYLT",
        "name": "HYLTON HOMES"
    },
    "HYMR": {
        "id": "HYMR",
        "name": "ERWIN HYMER GROUP NORTH AMERICA"
    },
    "HYOS": {
        "id": "HYOS",
        "name": "HYOSUNG MOTORS & MACHINERY SOUTH KOREA"
    },
    "HYSD": {
        "id": "HYSD",
        "name": "THE HYDROSEEDING COMPANY"
    },
    "HYSH": {
        "id": "HYSH",
        "name": "HAYSHED TRAILERS ALBERTA CANADA"
    },
    "HYST": {
        "id": "HYST",
        "name": "HYSTER"
    },
    "HYSU": {
        "id": "HYSU",
        "name": "SUNLIGHT BRAND MFG BY ERWIN HYMER GROUP NORTH AMERICA INC"
    },
    "HYTC": {
        "id": "HYTC",
        "name": "HYTRACKER MANUFACTURING LTD. BRITISH COLUMBIA CANADA"
    },
    "HYTK": {
        "id": "HYTK",
        "name": "HYDRO TEK CORP. REDLANDS"
    },
    "HYTR": {
        "id": "HYTR",
        "name": "HYUNDAI TRANSLEAD"
    },
    "HYTT": {
        "id": "HYTT",
        "name": "HY-TECH TRUCK & TRAILER MFG LLC FORT MORGAN CO"
    },
    "HYTX": {
        "id": "HYTX",
        "name": "HYDRO TRAXX INDIANA MFG BY SUN LAKE PRODUCTS"
    },
    "HYUN": {
        "id": "HYUN",
        "name": "HYUNDAI"
    },
    "HZLW": {
        "id": "HZLW",
        "name": "HAZLEWOOD MFG."
    },
    "IAME": {
        "id": "IAME",
        "name": "I. A. M. E."
    },
    "IASF": {
        "id": "IASF",
        "name": "IOWA STEEL FABRICATION OSCEOLA"
    },
    "IBEN": {
        "id": "IBEN",
        "name": "IBEN BOAT TRAILER"
    },
    "ICBU": {
        "id": "ICBU",
        "name": "IC BUS"
    },
    "ICEB": {
        "id": "ICEB",
        "name": "ICEBEAR"
    },
    "ICEV": {
        "id": "ICEV",
        "name": "ICON ELECTRIC VEHICLES LLC TAMPA FL"
    },
    "ICL": {
        "id": "ICL",
        "name": "ICL CARGO TRAILER"
    },
    "ICOM": {
        "id": "ICOM",
        "name": "INDICOM BUILDINGS"
    },
    "ICRP": {
        "id": "ICRP",
        "name": "IC CORPORATION"
    },
    "ICUN": {
        "id": "ICUN",
        "name": "INDUSTRIAL COATINGS UNLIMITED LLC ANCHORAGE AK"
    },
    "IDEC": {
        "id": "IDEC",
        "name": "I.D.E.C.O."
    },
    "IDEL": {
        "id": "IDEL",
        "name": "IDEAL INDUSTRIES"
    },
    "IDEN": {
        "id": "IDEN",
        "name": "IDENTITY MOTORS MANUFACTURING & ASSEMBLY PHOENIX"
    },
    "IDLE": {
        "id": "IDLE",
        "name": "IDLE-TIME CABOVER CAMPER TRAILER"
    },
    "IDMI": {
        "id": "IDMI",
        "name": "IDEAL MANUFACTURING INC BILLINGS MT"
    },
    "IDTD": {
        "id": "IDTD",
        "name": "IDAHO TOTE DOLLY"
    },
    "IETL": {
        "id": "IETL",
        "name": "IE TRAILERS AKA INLAND EMPIRE TRAILERS INC BLOOMINGTON CA"
    },
    "IGOT": {
        "id": "IGOT",
        "name": "I-GO - MFG BY EVERGREEN RECREATIONAL VEHICLES"
    },
    "IHBQ": {
        "id": "IHBQ",
        "name": "IRON HORSE BBQ"
    },
    "IHHI": {
        "id": "IHHI",
        "name": "ISHIKAWAJIMA-HARIMA HEAVY INDURSTRIES CO"
    },
    "IHTM": {
        "id": "IHTM",
        "name": "IRON HORSE TRAILER MANUFACTURING TEXAS"
    },
    "IJBD": {
        "id": "IJBD",
        "name": "INTERNATIONAL JET BOATS DE MEXI"
    },
    "IKA": {
        "id": "IKA",
        "name": "I. K. A."
    },
    "IKAR": {
        "id": "IKAR",
        "name": "IKARUS BUSES"
    },
    "IKON": {
        "id": "IKON",
        "name": "IKON MFG BY KIBBI"
    },
    "ILND": {
        "id": "ILND",
        "name": "ISLAND MFG BY KROPF INDUSTRIES"
    },
    "ILOM": {
        "id": "ILOM",
        "name": "ILO"
    },
    "IMAG": {
        "id": "IMAG",
        "name": "IMAGO COLTON"
    },
    "IMCO": {
        "id": "IMCO",
        "name": "INTERMOUNTAIN WHOLESALE"
    },
    "IMFI": {
        "id": "IMFI",
        "name": "INTERSTATE MANUFACTURING INC"
    },
    "IMPA": {
        "id": "IMPA",
        "name": "IMPALA MOBILE HOMES & TRAVEL TRAILERS"
    },
    "IMPB": {
        "id": "IMPB",
        "name": "I. M. P."
    },
    "IMPD": {
        "id": "IMPD",
        "name": "IMPERIAL INDUSTRIES"
    },
    "IMPE": {
        "id": "IMPE",
        "name": "IMPERIAL"
    },
    "IMPI": {
        "id": "IMPI",
        "name": "IMPERIAL MOBILE HOMES"
    },
    "IMPR": {
        "id": "IMPR",
        "name": "IMPERATOR HORSE TRAILERS"
    },
    "IMPT": {
        "id": "IMPT",
        "name": "IMPERIAL TRAILER CORPORATION"
    },
    "IMTR": {
        "id": "IMTR",
        "name": "INTERMOUNTAIN TRUCK REBUILDERS INC OGDEN UT"
    },
    "IND": {
        "id": "IND",
        "name": "INDIAN"
    },
    "INDA": {
        "id": "INDA",
        "name": "INDIES HOUSE"
    },
    "INDC": {
        "id": "INDC",
        "name": "INDUSTRIAL ENGINEERING COMPANY COLUMBUS"
    },
    "INDE": {
        "id": "INDE",
        "name": "INDEPENDENT PRODUCTS"
    },
    "INDI": {
        "id": "INDI",
        "name": "INDIAN MOTORCYCLE"
    },
    "INDM": {
        "id": "INDM",
        "name": "INDIO TRAILER MFG. CORP."
    },
    "INDN": {
        "id": "INDN",
        "name": "INDIAN CAMPERS"
    },
    "INDP": {
        "id": "INDP",
        "name": "INDEPENDENT MFG."
    },
    "INDT": {
        "id": "INDT",
        "name": "INDEPENDENT TRUCK TANK"
    },
    "INDU": {
        "id": "INDU",
        "name": "IMEINDUSTRIAL & MUNICIPAL ENGINEERING"
    },
    "INDW": {
        "id": "INDW",
        "name": "INDUSTRIEWERKE LUDWIGSFELDE"
    },
    "INFI": {
        "id": "INFI",
        "name": "INFINITI"
    },
    "INFM": {
        "id": "INFM",
        "name": "INFINITY MANUFACTURING"
    },
    "INFN": {
        "id": "INFN",
        "name": "INFERNO MFG BY KZRV"
    },
    "INFY": {
        "id": "INFY",
        "name": "INFINITY TRAILER SALES CANYON"
    },
    "INGE": {
        "id": "INGE",
        "name": "INGERSON MFG."
    },
    "INGI": {
        "id": "INGI",
        "name": "INERGII"
    },
    "INGL": {
        "id": "INGL",
        "name": "BLAN"
    },
    "INGR": {
        "id": "INGR",
        "name": "INGRAM MANUFACTURING"
    },
    "INGS": {
        "id": "INGS",
        "name": "INGERSOLL-RAND"
    },
    "INGT": {
        "id": "INGT",
        "name": "INGER-TE"
    },
    "ININ": {
        "id": "ININ",
        "name": "INDUSTRIAL INNOVATIONS"
    },
    "INLA": {
        "id": "INLA",
        "name": "INLAND EQUIPMENT CORP.ST. CHARLES"
    },
    "INLI": {
        "id": "INLI",
        "name": "INLINE SOUTHWEST HORSE TRAILER"
    },
    "INMA": {
        "id": "INMA",
        "name": "INSLEY MANUFACTURING"
    },
    "INMC": {
        "id": "INMC",
        "name": "INDEPENDENCE MOTORCYCLE COMPANY"
    },
    "INME": {
        "id": "INME",
        "name": "INTERMECCANICA"
    },
    "INMG": {
        "id": "INMG",
        "name": "INDUSTRIAS MET GRI MEXICO"
    },
    "INMH": {
        "id": "INMH",
        "name": "INERTIA MACHINE FREEPORT IL"
    },
    "INMN": {
        "id": "INMN",
        "name": "INMAN TRAILERS INMAN"
    },
    "INMT": {
        "id": "INMT",
        "name": "INDUSTRIA MECCANICA TRIVELLE ITALY"
    },
    "INNO": {
        "id": "INNO",
        "name": "INNOCENTI"
    },
    "INNT": {
        "id": "INNT",
        "name": "INNOVATIVE TRAILER"
    },
    "INNV": {
        "id": "INNV",
        "name": "INNOVATIVE TRAILER MANUFACTURING"
    },
    "INOR": {
        "id": "INOR",
        "name": "IDAHO NORLAND"
    },
    "INOS": {
        "id": "INOS",
        "name": "INNOVATIVE SPECIALTIES UNITY"
    },
    "INOV": {
        "id": "INOV",
        "name": "INNOVATIVE TRAILER MANUFACTURING EASTPOINTE"
    },
    "INPX": {
        "id": "INPX",
        "name": "INDIANA PHOENIX TRUCKS"
    },
    "INRP": {
        "id": "INRP",
        "name": "INTRAPID TRAILER"
    },
    "INSC": {
        "id": "INSC",
        "name": "INSANE CUSTOM CYCLE GLENDALE"
    },
    "INSG": {
        "id": "INSG",
        "name": "INSTIGATOR"
    },
    "INSL": {
        "id": "INSL",
        "name": "INSLEY FOUR WHEEL TAG TRAILER"
    },
    "INSM": {
        "id": "INSM",
        "name": "INNOVATIVE STREET MACHINES"
    },
    "INST": {
        "id": "INST",
        "name": "INTERNATIONAL SPECIALIZED TRAILER MFG."
    },
    "INTC": {
        "id": "INTC",
        "name": "INTERCONSULT MFG."
    },
    "INTE": {
        "id": "INTE",
        "name": "INTERNATIONAL COACH MFG."
    },
    "INTG": {
        "id": "INTG",
        "name": "INTEGRITY CUSTOM TRAILERS R & D FABRICATION DIV OF DF INDUSTRIES"
    },
    "INTI": {
        "id": "INTI",
        "name": "INTERNATIONAL INDUSTRIES"
    },
    "INTL": {
        "id": "INTL",
        "name": "INTERNATIONAL HARVESTER"
    },
    "INTM": {
        "id": "INTM",
        "name": "INTERNATIONAL MOBILE HOMES OF CALIFORNIA"
    },
    "INTP": {
        "id": "INTP",
        "name": "INTERSTATE PRODUCTS"
    },
    "INTR": {
        "id": "INTR",
        "name": "INTERNATIONAL TRAILER CORP."
    },
    "INTS": {
        "id": "INTS",
        "name": "INTERSTATE TRAVEL TRAILER"
    },
    "INTT": {
        "id": "INTT",
        "name": "INTERSTATE TRAILERS"
    },
    "INTW": {
        "id": "INTW",
        "name": "INTERSTATE WEST CORPORATION"
    },
    "INTY": {
        "id": "INTY",
        "name": "INTEGRITY TRAILERS"
    },
    "INVA": {
        "id": "INVA",
        "name": "INVADER TRAVEL TRAILER"
    },
    "INVC": {
        "id": "INVC",
        "name": "INVICTA CAR COMPANY UNITED KINGDOM AUTOS"
    },
    "INVO": {
        "id": "INVO",
        "name": "INNO VAN MORAINE"
    },
    "INVR": {
        "id": "INVR",
        "name": "INNOVATOR TRAILERS"
    },
    "INVT": {
        "id": "INVT",
        "name": "INDIAN VALLEY TRAILERS"
    },
    "IOMO": {
        "id": "IOMO",
        "name": "IOWA MOLD TOOLING"
    },
    "IONI": {
        "id": "IONI",
        "name": "IONI-A-HOME MFG."
    },
    "IOWA": {
        "id": "IOWA",
        "name": "IOWA MANUFACTURING"
    },
    "IPMI": {
        "id": "IPMI",
        "name": "IPT INC FRESNO"
    },
    "IPSC": {
        "id": "IPSC",
        "name": "IPSCO-LAMBTON STEEL"
    },
    "IRBI": {
        "id": "IRBI",
        "name": "IRBIT MOTORWORKS OF AMERICA"
    },
    "IRBK": {
        "id": "IRBK",
        "name": "IRABECK &"
    },
    "IRD": {
        "id": "IRD",
        "name": "IRD"
    },
    "IRDG": {
        "id": "IRDG",
        "name": "IRONDOG TRAILERS"
    },
    "IREG": {
        "id": "IREG",
        "name": "IRON EAGLE TRAILERS"
    },
    "IREL": {
        "id": "IREL",
        "name": "IRELAND TRAILER SALES"
    },
    "IRKG": {
        "id": "IRKG",
        "name": "IRONKING TRAILERS SEBRING"
    },
    "IRNH": {
        "id": "IRNH",
        "name": "IRONHORSE TRAILERS"
    },
    "IRNP": {
        "id": "IRNP",
        "name": "IRON PANTHER INCORPORATED CLOVIS"
    },
    "IROK": {
        "id": "IROK",
        "name": "IRON & OAK MINNESOTA WOOD SPLITTERS"
    },
    "IRON": {
        "id": "IRON",
        "name": "IRONWORKS MOTORCYCLE CO"
    },
    "IROX": {
        "id": "IROX",
        "name": "IRON OX TRAILERS"
    },
    "IRWI": {
        "id": "IRWI",
        "name": "IRONWORKS INC  SHAWNEE"
    },
    "ISEI": {
        "id": "ISEI",
        "name": "I.S.E."
    },
    "ISET": {
        "id": "ISET",
        "name": "ISETTA"
    },
    "ISLA": {
        "id": "ISLA",
        "name": "ISLANDER MOTOR HOME"
    },
    "ISO": {
        "id": "ISO",
        "name": "ISO"
    },
    "ISOM": {
        "id": "ISOM",
        "name": "ISOMETRICS"
    },
    "ISTG": {
        "id": "ISTG",
        "name": "INSTA-GATOR CHOPPERS"
    },
    "ISU": {
        "id": "ISU",
        "name": "ISUZU"
    },
    "ISUP": {
        "id": "ISUP",
        "name": "INTERNATIONAL SUPPLY"
    },
    "ITAF": {
        "id": "ITAF",
        "name": "ITALIAN FORD"
    },
    "ITAI": {
        "id": "ITAI",
        "name": "ITALIA"
    },
    "ITAK": {
        "id": "ITAK",
        "name": "ITALIKA MOTORCYCLES"
    },
    "ITAL": {
        "id": "ITAL",
        "name": "ITALJET"
    },
    "ITAM": {
        "id": "ITAM",
        "name": "ITALIANMOTO S.R.L ITALY MOTORCYCLES"
    },
    "ITAS": {
        "id": "ITAS",
        "name": "ITASCA MOTOR HOMES DIV WINNEBAGO IND."
    },
    "ITAT": {
        "id": "ITAT",
        "name": "ITALTELAI MFG."
    },
    "ITAV": {
        "id": "ITAV",
        "name": "ITALVELO"
    },
    "ITDI": {
        "id": "ITDI",
        "name": "INNOVATIVE TRAILER DESIGN INDUSTRIES MISSISSAUGA"
    },
    "ITEC": {
        "id": "ITEC",
        "name": "INDEPENDENT TRAILER & EQUIPMENT COMPANY YAKIMA"
    },
    "ITIT": {
        "id": "ITIT",
        "name": "ITI TRAILERS AND TRUCK BODIES"
    },
    "ITLI": {
        "id": "ITLI",
        "name": "INTEGRITY TRAILERS INC CARBONDALE PA"
    },
    "ITLR": {
        "id": "ITLR",
        "name": "INDIANA TRAILER MANUFACTURING"
    },
    "ITLU": {
        "id": "ITLU",
        "name": "LUNA BRAND MFG BY IN TECH TRAIELRS INC"
    },
    "ITMD": {
        "id": "ITMD",
        "name": "INTIMIDATOR"
    },
    "ITMI": {
        "id": "ITMI",
        "name": "INDIANA TOLL AND MACHINE"
    },
    "ITMT": {
        "id": "ITMT",
        "name": "ITM TRIKES PRODUCTION"
    },
    "ITNL": {
        "id": "ITNL",
        "name": "INTERNATIONAL TRUCK & ENGINE CORP"
    },
    "ITOM": {
        "id": "ITOM",
        "name": "ITOM"
    },
    "ITPD": {
        "id": "ITPD",
        "name": "INTREPID CYCLES INC CALIFORNIA"
    },
    "ITSO": {
        "id": "ITSO",
        "name": "SOL MFG BY IN TECH TRAILERS INC"
    },
    "ITST": {
        "id": "ITST",
        "name": "INTERSTATE TRAILERS INC."
    },
    "ITTI": {
        "id": "ITTI",
        "name": "IN TECH TRAILERS OR INTECH TRAILERS INC NAPPANEE IN"
    },
    "ITWO": {
        "id": "ITWO",
        "name": "INTO THE WILD OVERLAND LLC ARVADA CO"
    },
    "IVEC": {
        "id": "IVEC",
        "name": "IVE"
    },
    "JAAC": {
        "id": "JAAC",
        "name": "JACKSON AUTOMOBILE COMPANY JACKSON"
    },
    "JAAN": {
        "id": "JAAN",
        "name": "ANSI BRAND MFG BY JACOBSEN MANUFACTURING INC"
    },
    "JAC": {
        "id": "JAC",
        "name": "JACKSON TRAILER"
    },
    "JACB": {
        "id": "JACB",
        "name": "JACOBSEN TRAILERS INC"
    },
    "JACC": {
        "id": "JACC",
        "name": "JAC-TRAC"
    },
    "JACG": {
        "id": "JACG",
        "name": "JACK GREEN CO"
    },
    "JACK": {
        "id": "JACK",
        "name": "JACK'S TRAILER MFG."
    },
    "JACL": {
        "id": "JACL",
        "name": "JACLEN MFG."
    },
    "JACO": {
        "id": "JACO",
        "name": "ESTATE POWER MOWERS MFG.BY JACOBSEN MANUFACTURING"
    },
    "JACQ": {
        "id": "JACQ",
        "name": "JACQUES POWER SAW AND MOTOR COACH IND"
    },
    "JACS": {
        "id": "JACS",
        "name": "JACOBSEN MOBILE HOMES"
    },
    "JACT": {
        "id": "JACT",
        "name": "JACK'S TRAILER MFG. OF FLORIDA"
    },
    "JADL": {
        "id": "JADL",
        "name": "DELORO BRAND MFG BY JACOBSEN MANUFACTURING INC"
    },
    "JAEG": {
        "id": "JAEG",
        "name": "JAEGER MACHINE"
    },
    "JAGM": {
        "id": "JAGM",
        "name": "JAG MOBILE SOLUTIONS INDIANA."
    },
    "JAGU": {
        "id": "JAGU",
        "name": "JAGUAR"
    },
    "JAHN": {
        "id": "JAHN",
        "name": "JAHN FLATBED TRAILER"
    },
    "JAIL": {
        "id": "JAIL",
        "name": "JAILHOUSE CHOPPERS"
    },
    "JAIM": {
        "id": "JAIM",
        "name": "IMPERIAL BRAND MFG BY JACOBSEN MANUFACTURING INC"
    },
    "JAKL": {
        "id": "JAKL",
        "name": "JACKEL ATV'S & CYCLES MFG BY CHINA JIALING INDUSTRY"
    },
    "JALL": {
        "id": "JALL",
        "name": "JALLDEE"
    },
    "JAMA": {
        "id": "JAMA",
        "name": "JA-MAR MANUFACTURING INCSIKESTON"
    },
    "JAMB": {
        "id": "JAMB",
        "name": "JAMBOREE MOTOR HOME TRUCK"
    },
    "JAMC": {
        "id": "JAMC",
        "name": "JAM"
    },
    "JAMI": {
        "id": "JAMI",
        "name": "JAMIE'S WELDING OKLAHOMA"
    },
    "JAMM": {
        "id": "JAMM",
        "name": "JAMMER CYCLE PRODUCTS"
    },
    "JANT": {
        "id": "JANT",
        "name": "JANTZ MFG."
    },
    "JANU": {
        "id": "JANU",
        "name": "JANUS MOTORCYCLES"
    },
    "JARC": {
        "id": "JARC",
        "name": "JARCO"
    },
    "JASO": {
        "id": "JASO",
        "name": "JASON MFG.INDUSTRIES - ELKHART"
    },
    "JAST": {
        "id": "JAST",
        "name": "JAS TRAILERS TEMPLE"
    },
    "JAWA": {
        "id": "JAWA",
        "name": "BABETTA"
    },
    "JAY": {
        "id": "JAY",
        "name": "JAYCO"
    },
    "JAYD": {
        "id": "JAYD",
        "name": "JAY DEE INDUSTRIES CASSOPOLIS"
    },
    "JAYK": {
        "id": "JAYK",
        "name": "JAY-KEE MFG."
    },
    "JAYS": {
        "id": "JAYS",
        "name": "JAYS HY LIFT MANUFACTURING"
    },
    "JAYW": {
        "id": "JAYW",
        "name": "JAY WREN TRAILER"
    },
    "JBEN": {
        "id": "JBEN",
        "name": "J B ENTERPRISES ELKHART"
    },
    "JBHC": {
        "id": "JBHC",
        "name": "J.B.H CUSTOM TRAILER"
    },
    "JBLC": {
        "id": "JBLC",
        "name": "JIANGSU BAODIAO LOCOMOTIVE"
    },
    "JBMF": {
        "id": "JBMF",
        "name": "J & B MANUFACTURING A"
    },
    "JBON": {
        "id": "JBON",
        "name": "J BOND AND SONS LTD. BRITISH COLUMBIA"
    },
    "JBTS": {
        "id": "JBTS",
        "name": "J & B TRAILER SALES CUBA"
    },
    "JCBA": {
        "id": "JCBA",
        "name": "JOCOBS & ASSOCIATES HELENWOOD"
    },
    "JCBE": {
        "id": "JCBE",
        "name": "JCB EXCAVATORS"
    },
    "JCBI": {
        "id": "JCBI",
        "name": "JCB"
    },
    "JCEB": {
        "id": "JCEB",
        "name": "JIMMY CATAWBA"
    },
    "JCEC": {
        "id": "JCEC",
        "name": "JON'S CUSTOM ENGINEERED CYCLES LIBBY"
    },
    "JCHS": {
        "id": "JCHS",
        "name": "JCH SOLUTIONS"
    },
    "JCII": {
        "id": "JCII",
        "name": "JOHNSON CRUSHERS INTERNATIONAL"
    },
    "JCMI": {
        "id": "JCMI",
        "name": "J & C MFG."
    },
    "JCTM": {
        "id": "JCTM",
        "name": "JACK COUNTY TANY MANUFACTURING JACKSBORO"
    },
    "JDER": {
        "id": "JDER",
        "name": "DEERE AND COMPANY MOLINE"
    },
    "JDHS": {
        "id": "JDHS",
        "name": "JD HANDLING SYSTEMS OR D.J. STEELE & CONSTRUCTION"
    },
    "JDMC": {
        "id": "JDMC",
        "name": "JAINGSU DAFIER MOTORCYCLE"
    },
    "JDRA": {
        "id": "JDRA",
        "name": "ROYAL ALLOY MFG"
    },
    "JDSI": {
        "id": "JDSI",
        "name": "JDS INDUSTRY BERKELY SPRINGS"
    },
    "JECK": {
        "id": "JECK",
        "name": "JECK INDUSTRIES"
    },
    "JECR": {
        "id": "JECR",
        "name": "JEC RO"
    },
    "JEEP": {
        "id": "JEEP",
        "name": "JEEP"
    },
    "JEFE": {
        "id": "JEFE",
        "name": "JEFES WELDING"
    },
    "JEHM": {
        "id": "JEHM",
        "name": "JEHM POWERSPORTS"
    },
    "JEMI": {
        "id": "JEMI",
        "name": "J & E MANUFACTURING ELKHART"
    },
    "JENE": {
        "id": "JENE",
        "name": "JEN SELL CORP.ELKHART"
    },
    "JENN": {
        "id": "JENN",
        "name": "JENNINGS TRAILERS"
    },
    "JENS": {
        "id": "JENS",
        "name": "JENSEN"
    },
    "JEP": {
        "id": "JEP",
        "name": "JEEP"
    },
    "JERA": {
        "id": "JERA",
        "name": "JERA"
    },
    "JERE": {
        "id": "JERE",
        "name": "JEREH EQUIPMENT GROUP"
    },
    "JERR": {
        "id": "JERR",
        "name": "JERRYTIME CAMPER"
    },
    "JERS": {
        "id": "JERS",
        "name": "JERSEY TRAILER"
    },
    "JERW": {
        "id": "JERW",
        "name": "JERRY'S WELDING SERVICE"
    },
    "JETA": {
        "id": "JETA",
        "name": "JETA"
    },
    "JETC": {
        "id": "JETC",
        "name": "JET COMPANY"
    },
    "JETH": {
        "id": "JETH",
        "name": "JET HEAT"
    },
    "JETM": {
        "id": "JETM",
        "name": "JETMOBILE"
    },
    "JETS": {
        "id": "JETS",
        "name": "JET STREAM CAMPING TRAILER"
    },
    "JEWE": {
        "id": "JEWE",
        "name": "JEWEL TRAILER"
    },
    "JFEI": {
        "id": "JFEI",
        "name": "JOHN FRUETEL ENTERPRISES INC ALBANY OR"
    },
    "JFTL": {
        "id": "JFTL",
        "name": "J & F TRAILERS WEST SALEM"
    },
    "JFW": {
        "id": "JFW",
        "name": "J.F.W. MFG."
    },
    "JGXI": {
        "id": "JGXI",
        "name": "JIANGXI CAMPELL"
    },
    "JHGS": {
        "id": "JHGS",
        "name": "JH GLOBAL SERVICES"
    },
    "JHLG": {
        "id": "JHLG",
        "name": "J HELGESON ENTERPRISES INC OTIS ORCHARD"
    },
    "JHNS": {
        "id": "JHNS",
        "name": "JOHNS CORP."
    },
    "JHNY": {
        "id": "JHNY",
        "name": "JOHNNY PAG.COM"
    },
    "JHPN": {
        "id": "JHPN",
        "name": "JOHN PENNER TRAILER MFG ELMORE CITY"
    },
    "JIAH": {
        "id": "JIAH",
        "name": "JIANGHUAI AUTOMOTIVE"
    },
    "JIAJ": {
        "id": "JIAJ",
        "name": "JIAHUE MOTORCYCLE MANUFACTURING"
    },
    "JIAL": {
        "id": "JIAL",
        "name": "JIALING INDUSTRIES"
    },
    "JIAN": {
        "id": "JIAN",
        "name": "JIANSHE MOTORCYCLES & MOTORSCOOTERS"
    },
    "JIEE": {
        "id": "JIEE",
        "name": "JI-EE INDUSTRY COMPANY ."
    },
    "JIJM": {
        "id": "JIJM",
        "name": "JINJIE MOTOR MANUFACTURE"
    },
    "JIJU": {
        "id": "JIJU",
        "name": "JIAJIA JUNENG MOTORCYCLE OR ZHEJIANG JIANIA JUNENG MOTORCYCLE_TAIZHOU CITY ZHEJIANG CHINA_OR ZHEJINAG JIAJIA JUNENG MOTORCYCLE TECHNOLOGY"
    },
    "JIMA": {
        "id": "JIMA",
        "name": "JIM DANDY"
    },
    "JIMD": {
        "id": "JIMD",
        "name": "JIM & DAVE'S TRAILER MFG."
    },
    "JIMG": {
        "id": "JIMG",
        "name": "JIM GLO TRAILERS WILCOX"
    },
    "JIMS": {
        "id": "JIMS",
        "name": "JIM'S TRAILER SHOP PORTLAND"
    },
    "JIND": {
        "id": "JIND",
        "name": "JINDO CORPORATION SEOUL"
    },
    "JINL": {
        "id": "JINL",
        "name": "JINLING VEHICLE"
    },
    "JINS": {
        "id": "JINS",
        "name": "JINSCENG MOTORCYCLES"
    },
    "JITM": {
        "id": "JITM",
        "name": "JIM'S TRAILER MANUFACTURING TOPEKA"
    },
    "JJJ": {
        "id": "JJJ",
        "name": "J.J.J."
    },
    "JJNE": {
        "id": "JJNE",
        "name": "J-J-N ENTERPRISES SISKETON"
    },
    "JJOI": {
        "id": "JJOI",
        "name": "JJ OUTDOORS INDUSTRIES"
    },
    "JJT": {
        "id": "JJT",
        "name": "JERRY JAMES TRAILERS SIKESTON"
    },
    "JJTM": {
        "id": "JJTM",
        "name": "J & J TRAILER MANUFACTURING OR J.D.J. TRAILER MANUFACTURING"
    },
    "JJTT": {
        "id": "JJTT",
        "name": "J AND J TRAILERS AND TRUCK EQUIPMENT LLC WINCHESTER NH"
    },
    "JKTR": {
        "id": "JKTR",
        "name": "JAKOB THALER WEDDELBROOK GERMANY"
    },
    "JKVC": {
        "id": "JKVC",
        "name": "JIANGSU KINGBON VEHICLE"
    },
    "JLBK": {
        "id": "JLBK",
        "name": "JAIL BREAK CHOPPERS LLC TEMP AZ"
    },
    "JLEQ": {
        "id": "JLEQ",
        "name": "J & L EQUIPMENT"
    },
    "JLGI": {
        "id": "JLGI",
        "name": "J. L. G. INDUSTRIES"
    },
    "JLM": {
        "id": "JLM",
        "name": "JLM INDUSTRIES"
    },
    "JLMX": {
        "id": "JLMX",
        "name": "JELMAX TRAILERS MEXICO"
    },
    "JLTL": {
        "id": "JLTL",
        "name": "J & L TANK"
    },
    "JMAC": {
        "id": "JMAC",
        "name": "JOMAC CARROLTON"
    },
    "JMAR": {
        "id": "JMAR",
        "name": "JOMAR"
    },
    "JMCO": {
        "id": "JMCO",
        "name": "J AND M CONVEYOR HENDERSON CO"
    },
    "JMCY": {
        "id": "JMCY",
        "name": "JET"
    },
    "JMGL": {
        "id": "JMGL",
        "name": "JIMGLO TRAILERS & PRODUCTSPHOENIX"
    },
    "JMHT": {
        "id": "JMHT",
        "name": "JMH TRAILERS"
    },
    "JMS": {
        "id": "JMS",
        "name": "JAMES"
    },
    "JMST": {
        "id": "JMST",
        "name": "JMSTAR MOTORCYCLE"
    },
    "JMTC": {
        "id": "JMTC",
        "name": "J & M TRAILER COMPANY SIKESTON"
    },
    "JOBS": {
        "id": "JOBS",
        "name": "JOB SITE TRAILER"
    },
    "JOES": {
        "id": "JOES",
        "name": "JOE'S CUSTOM BOAT TRAILER"
    },
    "JOEW": {
        "id": "JOEW",
        "name": "JOE'S WELDING SERVICE SALEM"
    },
    "JOHM": {
        "id": "JOHM",
        "name": "JOHNSON MANUFACTURERS"
    },
    "JOHN": {
        "id": "JOHN",
        "name": "JOHNSON CORP."
    },
    "JOHO": {
        "id": "JOHO",
        "name": "JOHNSON MOTORS"
    },
    "JOHS": {
        "id": "JOHS",
        "name": "JOHNSON'S TRAILER BUILDING & REPAIR"
    },
    "JOLI": {
        "id": "JOLI",
        "name": "JOHNNY LIGHTNING"
    },
    "JOMA": {
        "id": "JOMA",
        "name": "JOHNSON MFG."
    },
    "JONE": {
        "id": "JONE",
        "name": "JONES TRAILER"
    },
    "JONS": {
        "id": "JONS",
        "name": "C. S. JOHNSON"
    },
    "JONW": {
        "id": "JONW",
        "name": "JONWAY GROUP"
    },
    "JOPL": {
        "id": "JOPL",
        "name": "JOPLIN"
    },
    "JORG": {
        "id": "JORG",
        "name": "IRA JORGENSON"
    },
    "JOSH": {
        "id": "JOSH",
        "name": "JOSHUA TRAILER"
    },
    "JOSO": {
        "id": "JOSO",
        "name": "CARL A. JOHNSON & SONS"
    },
    "JOUR": {
        "id": "JOUR",
        "name": "JOURNEY MOTOR HOMES"
    },
    "JOWE": {
        "id": "JOWE",
        "name": "JOWETT"
    },
    "JOYC": {
        "id": "JOYC",
        "name": "JOY MFG."
    },
    "JOYH": {
        "id": "JOYH",
        "name": "JOYHON MOTORCYCLE"
    },
    "JOYN": {
        "id": "JOYN",
        "name": "JOYNER ATV'S"
    },
    "JPCN": {
        "id": "JPCN",
        "name": "JP CONVEYORS SA DE CV CUAUHTEMOC CHIHUAHUA MEXICO"
    },
    "JPTR": {
        "id": "JPTR",
        "name": "JUPITER"
    },
    "JPUT": {
        "id": "JPUT",
        "name": "J.P UTILITY TRAILERS & WELDING ST. JAMES"
    },
    "JQMT": {
        "id": "JQMT",
        "name": "JINAN DALONG VEHICLE INDUSTRY"
    },
    "JQTX": {
        "id": "JQTX",
        "name": "J Q TEX INC MISHAWAKA INDIANA"
    },
    "JRCC": {
        "id": "JRCC",
        "name": "JRC CUSTOM TRAILERS"
    },
    "JRCT": {
        "id": "JRCT",
        "name": "JR CUSTOM TRAILERS"
    },
    "JRDN": {
        "id": "JRDN",
        "name": "JERR-DAN"
    },
    "JREH": {
        "id": "JREH",
        "name": "JIREH"
    },
    "JREW": {
        "id": "JREW",
        "name": "JAMES RUSSELL ENGINEERING WORKS"
    },
    "JRLC": {
        "id": "JRLC",
        "name": "JRL CYCLES"
    },
    "JRMC": {
        "id": "JRMC",
        "name": "J & R MANUFACTURING"
    },
    "JROD": {
        "id": "JROD",
        "name": "J-ROD TRAILER"
    },
    "JRSF": {
        "id": "JRSF",
        "name": "JRS CUSTOM FABRICATION"
    },
    "JRTL": {
        "id": "JRTL",
        "name": "JRTL"
    },
    "JRWT": {
        "id": "JRWT",
        "name": "WALTON TRAILERS LLC FORMERLY JRW TRAILERS INC LOGAN UT"
    },
    "JSMH": {
        "id": "JSMH",
        "name": "J.S. MOBILE HOMES"
    },
    "JSRC": {
        "id": "JSRC",
        "name": "JSR CUSTOM LLC GILBERT"
    },
    "JSWP": {
        "id": "JSWP",
        "name": "JOHNSTON SWEEPER"
    },
    "JTCI": {
        "id": "JTCI",
        "name": "JTC SALES"
    },
    "JTIL": {
        "id": "JTIL",
        "name": "J.T. IND."
    },
    "JTLL": {
        "id": "JTLL",
        "name": "JETSTREAM LLC WADENA MN"
    },
    "JTMO": {
        "id": "JTMO",
        "name": "JETMOTO MOTORS USA"
    },
    "JTSV": {
        "id": "JTSV",
        "name": "JIANGSU TIKING SPORTS VEHICLE MANUFACTURER"
    },
    "JTTR": {
        "id": "JTTR",
        "name": "JTJ TRAILERS INC ELKHART INDIANA DISSOVLED 199O"
    },
    "JUDE": {
        "id": "JUDE",
        "name": "JUDE TENT TRAILER"
    },
    "JUIL": {
        "id": "JUIL",
        "name": "FIVE-STAR ST"
    },
    "JUMP": {
        "id": "JUMP",
        "name": "JUMPING JACK INC. SALT LAKE CITY"
    },
    "JUNG": {
        "id": "JUNG",
        "name": "JUNG MAX CZECH REPUBLIC TRAILER MOUNTED AMUSEMENT RIDES"
    },
    "JUNR": {
        "id": "JUNR",
        "name": "JUNIOR CAMPING TRAILER"
    },
    "JVMA": {
        "id": "JVMA",
        "name": "JV MANUFACTURING"
    },
    "JWBC": {
        "id": "JWBC",
        "name": "CHRISTIE"
    },
    "JWSS": {
        "id": "JWSS",
        "name": "JOHNSON WELDING & STEEL SUPPLYPHILO"
    },
    "JYAC": {
        "id": "JYAC",
        "name": "ACCOLADE MFG BY JAY"
    },
    "JYAL": {
        "id": "JYAL",
        "name": "ALANTE MODEL"
    },
    "JYAN": {
        "id": "JYAN",
        "name": "ANTHEM MFG BY JAYCO"
    },
    "JYAR": {
        "id": "JYAR",
        "name": "AUTUMN RIDGE TT MFG BY JAYCO"
    },
    "JYAS": {
        "id": "JYAS",
        "name": "ASPIRE MFG BY JAYCO"
    },
    "JYAV": {
        "id": "JYAV",
        "name": "AVALON MFG BY JAY"
    },
    "JYBA": {
        "id": "JYBA",
        "name": "BAJA MFG BY JAYCO"
    },
    "JYCE": {
        "id": "JYCE",
        "name": "CENTENNIAL MFG BY JAY"
    },
    "JYCM": {
        "id": "JYCM",
        "name": "COMET MFG BY JAY"
    },
    "JYCO": {
        "id": "JYCO",
        "name": "JAY"
    },
    "JYDE": {
        "id": "JYDE",
        "name": "DESIGNER BRAND MFG BY JAY"
    },
    "JYEB": {
        "id": "JYEB",
        "name": "EMBLEM BRAND MFG BY JAY"
    },
    "JYEG": {
        "id": "JYEG",
        "name": "EAGLE FW & EAGLE TT MFG BY JAYCO"
    },
    "JYEM": {
        "id": "JYEM",
        "name": "EMBARK MFG BY JAYCO"
    },
    "JYES": {
        "id": "JYES",
        "name": "ESTEEM ENTEGRA COACH DIV OF JAY"
    },
    "JYEV": {
        "id": "JYEV",
        "name": "ENVOY BRAND MFG BY JAY"
    },
    "JYFE": {
        "id": "JYFE",
        "name": "JAY FEATHER TT MFG BY JAYCO"
    },
    "JYFL": {
        "id": "JYFL",
        "name": "JAY FLIGHT TT MFG BY JAYCO"
    },
    "JYGP": {
        "id": "JYGP",
        "name": "GPS STARCRAFT RV DIV OF JAY"
    },
    "JYGR": {
        "id": "JYGR",
        "name": "GREYHAWK MFG BY JAYCO"
    },
    "JYHU": {
        "id": "JYHU",
        "name": "HUMMINGBIRD"
    },
    "JYIN": {
        "id": "JYIN",
        "name": "INSIGNIA BRAND MFG BY JAY"
    },
    "JYJA": {
        "id": "JYJA",
        "name": "JAY MFG BY JAY"
    },
    "JYME": {
        "id": "JYME",
        "name": "MELBOURNE MFG BY JAYCO"
    },
    "JYMO": {
        "id": "JYMO",
        "name": "MOSSY OAK MFG BY JAYCO"
    },
    "JYNP": {
        "id": "JYNP",
        "name": "NORTH POINT BRAND"
    },
    "JYOC": {
        "id": "JYOC",
        "name": "OCTANE TT MFG BY JAYCO"
    },
    "JYOD": {
        "id": "JYOD",
        "name": "ODYSSEY ENTEGRA COACH DIV OF JAY"
    },
    "JYPI": {
        "id": "JYPI",
        "name": "PINNACLE FW MFG BY JAYCO"
    },
    "JYPR": {
        "id": "JYPR",
        "name": "PRECEPT MFG BY JAY"
    },
    "JYQW": {
        "id": "JYQW",
        "name": "QWEST BRAND MFG BY JAY"
    },
    "JYRA": {
        "id": "JYRA",
        "name": "REATTA MFG BY JAY"
    },
    "JYRH": {
        "id": "JYRH",
        "name": "REDHAWK MFG BY JAY"
    },
    "JYSA": {
        "id": "JYSA",
        "name": "STARCRAFT AR-ONE"
    },
    "JYSE": {
        "id": "JYSE",
        "name": "SEISMIC MFG BY JAY"
    },
    "JYSL": {
        "id": "JYSL",
        "name": "SATELLITE"
    },
    "JYSN": {
        "id": "JYSN",
        "name": "SENECA MFG BY JAYCO"
    },
    "JYSO": {
        "id": "JYSO",
        "name": "SOLSTICE MFG BY"
    },
    "JYSP": {
        "id": "JYSP",
        "name": "SUPER LITE MFG BY JAY"
    },
    "JYSR": {
        "id": "JYSR",
        "name": "STARCRAFT LAUNCH MFG BY JAYCO"
    },
    "JYST": {
        "id": "JYST",
        "name": "STARFLYER MFG BY JAY"
    },
    "JYTL": {
        "id": "JYTL",
        "name": "TALON BRAND MFG BY JAY"
    },
    "JYTR": {
        "id": "JYTR",
        "name": "TELLURIDE MFG BY JAY"
    },
    "JYTS": {
        "id": "JYTS",
        "name": "TRAVEL STAR FW & TRAVEL STAR TT MFG BY JAYCO"
    },
    "JYVI": {
        "id": "JYVI",
        "name": "VISION MFG BY JAYCO"
    },
    "JYWH": {
        "id": "JYWH",
        "name": "WHITE HAWK TT MFG BY JAY"
    },
    "JZRC": {
        "id": "JZRC",
        "name": "JZ RIDERS CUSTOM MOTORCYCLES"
    },
    "KAES": {
        "id": "KAES",
        "name": "FAHRZEUGWERKE GMBH KARLS KAESSBOHRER"
    },
    "KAHE": {
        "id": "KAHE",
        "name": "KA HELI TRAILERS CLARKSTON WA"
    },
    "KAIK": {
        "id": "KAIK",
        "name": "KAIKAI MEIDUO LOCOMOTIVE"
    },
    "KAIR": {
        "id": "KAIR",
        "name": "KAIER MOTORCYCLE MANUFACTURING"
    },
    "KAIS": {
        "id": "KAIS",
        "name": "KAISER"
    },
    "KAIT": {
        "id": "KAIT",
        "name": "KAITONG MOTORCYCLE"
    },
    "KAIZ": {
        "id": "KAIZ",
        "name": "KAIZO UNIBODIES"
    },
    "KAJU": {
        "id": "KAJU",
        "name": "KAJ'N HOMES"
    },
    "KAKI": {
        "id": "KAKI",
        "name": "KAMP KING UTOPIAN"
    },
    "KAL": {
        "id": "KAL",
        "name": "KAL-CUSTOM BOAT TRAILER"
    },
    "KALL": {
        "id": "KALL",
        "name": "KALLIO"
    },
    "KALM": {
        "id": "KALM",
        "name": "KALMAR INDUSTRIES CORP."
    },
    "KALY": {
        "id": "KALY",
        "name": "KALYN"
    },
    "KAM": {
        "id": "KAM",
        "name": "KAMA"
    },
    "KAMA": {
        "id": "KAMA",
        "name": "KAMP-A-WHILE INDUSTRIES"
    },
    "KAMI": {
        "id": "KAMI",
        "name": "KAMI"
    },
    "KAMP": {
        "id": "KAMP",
        "name": "KAMPERS KABIN"
    },
    "KANE": {
        "id": "KANE",
        "name": "KANE TRAILERS GREELEY"
    },
    "KANG": {
        "id": "KANG",
        "name": "KANDI"
    },
    "KANH": {
        "id": "KANH",
        "name": "KAN HAUL TRAILER SALES"
    },
    "KANN": {
        "id": "KANN",
        "name": "KANNON MOTORCYCLES SJH MANUFACTURING"
    },
    "KANZ": {
        "id": "KANZ",
        "name": "KANZOL ENTERPRISES"
    },
    "KARA": {
        "id": "KARA",
        "name": "KARAVAN TRAILERS"
    },
    "KARC": {
        "id": "KARC",
        "name": "KARCHER NORTH AMERICA"
    },
    "KARD": {
        "id": "KARD",
        "name": "KARD CO"
    },
    "KARI": {
        "id": "KARI",
        "name": "KARI COOL TRAILER"
    },
    "KARM": {
        "id": "KARM",
        "name": "KARMA AUTOMOTIVE"
    },
    "KARO": {
        "id": "KARO",
        "name": "KAR-GO METAL STAMPING"
    },
    "KARP": {
        "id": "KARP",
        "name": "KARP'S WELDING"
    },
    "KARR": {
        "id": "KARR",
        "name": "KAR-RITE CORPORATION FRANKLIN PARK"
    },
    "KARS": {
        "id": "KARS",
        "name": "KARSON INDUSTRIES"
    },
    "KART": {
        "id": "KART",
        "name": "KARTOTE"
    },
    "KASE": {
        "id": "KASE",
        "name": "KASEY"
    },
    "KASI": {
        "id": "KASI",
        "name": "R.FILION MANUFACTURER"
    },
    "KASL": {
        "id": "KASL",
        "name": "KASEL MFG."
    },
    "KASM": {
        "id": "KASM",
        "name": "K AND S MANUFACTURING LLC OGDEN UT"
    },
    "KASS": {
        "id": "KASS",
        "name": "KASSBORTH"
    },
    "KAST": {
        "id": "KAST",
        "name": "KASTEN MFG."
    },
    "KATL": {
        "id": "KATL",
        "name": "KATOLIGHT CORP."
    },
    "KATO": {
        "id": "KATO",
        "name": "KATO ENGINEERING"
    },
    "KAUF": {
        "id": "KAUF",
        "name": "KAUFMAN TRAILERS INC LEXINGTON NC"
    },
    "KAUS": {
        "id": "KAUS",
        "name": "KAUFMAN TRAILER SALES KEMP TX"
    },
    "KAWK": {
        "id": "KAWK",
        "name": "KAWASAKI"
    },
    "KAYD": {
        "id": "KAYD",
        "name": "KAYDEL"
    },
    "KAYF": {
        "id": "KAYF",
        "name": "KAYOUT-FORESTER TRAVEL TRAILER"
    },
    "KAYM": {
        "id": "KAYM",
        "name": "KAYO MOTOR MACHINERY"
    },
    "KAYO": {
        "id": "KAYO",
        "name": "KAYOT"
    },
    "KAYW": {
        "id": "KAYW",
        "name": "KAYWOOD HOMES"
    },
    "KAZU": {
        "id": "KAZU",
        "name": "KAZUMA STANNIC MANUFACTURING"
    },
    "KBAR": {
        "id": "KBAR",
        "name": "K-BAR INDUSTRIES"
    },
    "KBHC": {
        "id": "KBHC",
        "name": "KBH CORPORATION CLARKSDALE"
    },
    "KBMT": {
        "id": "KBMT",
        "name": "KBM-TRIKES GERMANY"
    },
    "KCBI": {
        "id": "KCBI",
        "name": "KING'S CUSTOM BUILT BUILDERS"
    },
    "KCCF": {
        "id": "KCCF",
        "name": "KILLER CHOPPER CYCLE FABRICATION"
    },
    "KCCT": {
        "id": "KCCT",
        "name": "K C CUSTOM TRAILERS NORTH CAROLINA"
    },
    "KCPW": {
        "id": "KCPW",
        "name": "KC POWERSPORTS"
    },
    "KDEE": {
        "id": "KDEE",
        "name": "K-DEE LAUNCHER OR K-DEE SUPPLY"
    },
    "KDMA": {
        "id": "KDMA",
        "name": "K-D MFG."
    },
    "KEAR": {
        "id": "KEAR",
        "name": "KEARNEY TRAILERS"
    },
    "KEEN": {
        "id": "KEEN",
        "name": "KEEN PERCEPTION INDUSTRIES"
    },
    "KEEW": {
        "id": "KEEW",
        "name": "KEEWAY AMERICA LLC"
    },
    "KELE": {
        "id": "KELE",
        "name": "KELLEY MANUFACTURINGBOGATA"
    },
    "KELL": {
        "id": "KELL",
        "name": "C.C. KELLEY & SON"
    },
    "KELM": {
        "id": "KELM",
        "name": "KELMARK"
    },
    "KELO": {
        "id": "KELO",
        "name": "KELLOGG-AMERICAN"
    },
    "KELS": {
        "id": "KELS",
        "name": "KELSON ENGINEERING"
    },
    "KEMK": {
        "id": "KEMK",
        "name": "KEMP KUSTOMS WICHITA"
    },
    "KEMP": {
        "id": "KEMP",
        "name": "KEMPF CAR HAULER"
    },
    "KEN": {
        "id": "KEN",
        "name": "KEN TRAILER"
    },
    "KENA": {
        "id": "KENA",
        "name": "KENT AIR TOOL"
    },
    "KENC": {
        "id": "KENC",
        "name": "KDEN-CRAFT PRODUCTS"
    },
    "KEND": {
        "id": "KEND",
        "name": "KENDON INDUSTRIES"
    },
    "KENM": {
        "id": "KENM",
        "name": "KENT MFG."
    },
    "KENN": {
        "id": "KENN",
        "name": "KENRON CORP."
    },
    "KENO": {
        "id": "KENO",
        "name": "KENSKILL TRAILER CORP."
    },
    "KENS": {
        "id": "KENS",
        "name": "KENSINGTON MOTOR"
    },
    "KENT": {
        "id": "KENT",
        "name": "KENTUCKY MFG."
    },
    "KENW": {
        "id": "KENW",
        "name": "KENWAY CAMPERS"
    },
    "KENY": {
        "id": "KENY",
        "name": "KENNY BOYCE MOTORCYCLES"
    },
    "KEPT": {
        "id": "KEPT",
        "name": "KEMPTER TRAILER"
    },
    "KERR": {
        "id": "KERR",
        "name": "JOHN KERR MANUFACTURING"
    },
    "KERS": {
        "id": "KERS",
        "name": "KERSTEN TRAILER SALES & SERVICE HENDERSON"
    },
    "KESL": {
        "id": "KESL",
        "name": "KESLER MANUFACTURING"
    },
    "KEVC": {
        "id": "KEVC",
        "name": "KEV"
    },
    "KEYI": {
        "id": "KEYI",
        "name": "KEY INDUSTRIES"
    },
    "KEYO": {
        "id": "KEYO",
        "name": "KEYSTONE TRAILER & EQUIPMENT"
    },
    "KEYW": {
        "id": "KEYW",
        "name": "KEYWAY FABRICATION & DESIGN NEW YORK"
    },
    "KFBC": {
        "id": "KFBC",
        "name": "KAROSSERIE FABRIK BIBERACH"
    },
    "KGHY": {
        "id": "KGHY",
        "name": "KINGS HIGHWAY"
    },
    "KGMF": {
        "id": "KGMF",
        "name": "K & G MFG."
    },
    "KGRM": {
        "id": "KGRM",
        "name": "ZHEJIANG KINGROMA INDUSTRIAL AND TRADING"
    },
    "KGTR": {
        "id": "KGTR",
        "name": "KANGAROO TRAILERS BURLINGTON ONTARIO CANADA"
    },
    "KHBW": {
        "id": "KHBW",
        "name": "KIT HOME BUILDERS WEST"
    },
    "KIA": {
        "id": "KIA",
        "name": "KIA"
    },
    "KIBB": {
        "id": "KIBB",
        "name": "KIBBI"
    },
    "KIDR": {
        "id": "KIDR",
        "name": "KIDRON TRAILERS"
    },
    "KIEF": {
        "id": "KIEF",
        "name": "KIEFER BUILT"
    },
    "KIKK": {
        "id": "KIKK",
        "name": "KIKKER HARDKNOCK MODEL OF POCKET BIKE ETC"
    },
    "KIKN": {
        "id": "KIKN",
        "name": "KICK'IN KAMPERS"
    },
    "KILG": {
        "id": "KILG",
        "name": "KILGORE INDUSTRIESPOMONA"
    },
    "KILL": {
        "id": "KILL",
        "name": "KILL BROS.DELPHOS"
    },
    "KIMB": {
        "id": "KIMB",
        "name": "KIMBLE CHASSIS NEW PHILADELPHIA"
    },
    "KIMI": {
        "id": "KIMI",
        "name": "KING MIDGET"
    },
    "KIMK": {
        "id": "KIMK",
        "name": "KIMBERLEY KAMPERS PTY."
    },
    "KINA": {
        "id": "KINA",
        "name": "KING FISH BOAT TRAILER"
    },
    "KINC": {
        "id": "KINC",
        "name": "BUCKINGHAM MFG BY KING HOMES"
    },
    "KIND": {
        "id": "KIND",
        "name": "KING RICHARDS"
    },
    "KINE": {
        "id": "KINE",
        "name": "KING TRAILER"
    },
    "KINF": {
        "id": "KINF",
        "name": "KING-CO"
    },
    "KING": {
        "id": "KING",
        "name": "CIXI KINGRING MOTORCYCLE"
    },
    "KINL": {
        "id": "KINL",
        "name": "KINLON OR CHONGQING KINLON SCIENCE & TECHNOLOGY GROUP"
    },
    "KINS": {
        "id": "KINS",
        "name": "KINGSTON HORSE TRAILER"
    },
    "KINZ": {
        "id": "KINZ",
        "name": "KINZE FARM & GARDEN EQUIPMENT"
    },
    "KIOT": {
        "id": "KIOT",
        "name": "KIOTI TRACTORS DIVISION OF DAEDONG USA"
    },
    "KIPC": {
        "id": "KIPC",
        "name": "KIPCO"
    },
    "KIRC": {
        "id": "KIRC",
        "name": "RENEGADE CLASSIC MFG BY: KIBBI"
    },
    "KIRK": {
        "id": "KIRK",
        "name": "KIRKS TRAILER MANUFACTURING"
    },
    "KISM": {
        "id": "KISM",
        "name": "KISMET MFG."
    },
    "KISS": {
        "id": "KISS",
        "name": "KISSEL MOTOR CAR COMPANY OR KISSEL INDUSTRIES"
    },
    "KIT": {
        "id": "KIT",
        "name": "KIT HOUSE TRAILER"
    },
    "KITC": {
        "id": "KITC",
        "name": "KIT CAR CENTRE"
    },
    "KITK": {
        "id": "KITK",
        "name": "KITTY CAT"
    },
    "KITM": {
        "id": "KITM",
        "name": "COMPANION MFG BY KIT MFG."
    },
    "KIVL": {
        "id": "KIVL",
        "name": "VILLAGIO MFG BY: KIBBI"
    },
    "KJAC": {
        "id": "KJAC",
        "name": "K-JACK MOTOR LLC GARDENA"
    },
    "KKSY": {
        "id": "KKSY",
        "name": "K AND K SYSTEMS INC"
    },
    "KLAE": {
        "id": "KLAE",
        "name": "KLASSEN HOMES"
    },
    "KLAS": {
        "id": "KLAS",
        "name": "KLASSIC TRAILER MFG."
    },
    "KLBO": {
        "id": "KLBO",
        "name": "KLEMM BOHRTECHNIK GMBH DROLSHAGEN GERMANY"
    },
    "KLCL": {
        "id": "KLCL",
        "name": "KELLY-CRESWALL"
    },
    "KLDT": {
        "id": "KLDT",
        "name": "KLD ENERGY TECHNOLOGIES AUSTIN"
    },
    "KLEI": {
        "id": "KLEI",
        "name": "KLEIN PRODUCTS"
    },
    "KLI": {
        "id": "KLI",
        "name": "K LINE TRAILER"
    },
    "KLIN": {
        "id": "KLIN",
        "name": "KLINGER PRODUCTS"
    },
    "KLLY": {
        "id": "KLLY",
        "name": "KELLY ENTERPRISES"
    },
    "KLMK": {
        "id": "KLMK",
        "name": "KLIMEK WELDING AND MANUFACTURINGMAPLE LAKE"
    },
    "KLMX": {
        "id": "KLMX",
        "name": "KOLMAX PLUS"
    },
    "KLPN": {
        "id": "KLPN",
        "name": "KOLPIN ATV & UTV AND ACCESSORIES"
    },
    "KLRM": {
        "id": "KLRM",
        "name": "KELLERMAN COACHWORKS"
    },
    "KMAR": {
        "id": "KMAR",
        "name": "K-MART CAMPER FOLDUP"
    },
    "KMBL": {
        "id": "KMBL",
        "name": "KIMBLE MANUFACTURING"
    },
    "KMGI": {
        "id": "KMGI",
        "name": "KMG INTERNATIONAL TANGO TRADE NAME"
    },
    "KMMC": {
        "id": "KMMC",
        "name": "K & M MFG."
    },
    "KMMN": {
        "id": "KMMN",
        "name": "KEM"
    },
    "KMNM": {
        "id": "KMNM",
        "name": "KMN MODERN FARM EQUIPMENT"
    },
    "KMPL": {
        "id": "KMPL",
        "name": "KAMPLITE MFG. CORP. MONTANA"
    },
    "KMSI": {
        "id": "KMSI",
        "name": "KELLER MARINE SERVICES"
    },
    "KMSL": {
        "id": "KMSL",
        "name": "KIEFER MFG AND SALES LLC SNEEDVILLE TN"
    },
    "KMSR": {
        "id": "KMSR",
        "name": "KAMASURA"
    },
    "KNAP": {
        "id": "KNAP",
        "name": "KNAPHEIDE TRUCK EQUIPMENT COMPANY SOUTHWEST RED OAK"
    },
    "KNBR": {
        "id": "KNBR",
        "name": "KEN-BAR GO CARTS"
    },
    "KNC": {
        "id": "KNC",
        "name": "KEN CRAFT TRAILER"
    },
    "KNCD": {
        "id": "KNCD",
        "name": "CIXI KONCED MOTORCYCLE"
    },
    "KNCO": {
        "id": "KNCO",
        "name": "KEN"
    },
    "KNDL": {
        "id": "KNDL",
        "name": "KENDALL TRAILER MFG."
    },
    "KNEM": {
        "id": "KNEM",
        "name": "KINCAID EQUIPMENT MANUFACTURING"
    },
    "KNG": {
        "id": "KNG",
        "name": "KING HORSE TRAILER"
    },
    "KNGA": {
        "id": "KNGA",
        "name": "KING AMERICAN"
    },
    "KNGG": {
        "id": "KNGG",
        "name": "KOENIGSEGG AUTOMOTIVE SWEDEN"
    },
    "KNGH": {
        "id": "KNGH",
        "name": "KINGHAM"
    },
    "KNGM": {
        "id": "KNGM",
        "name": "KNIGHT MANUFACTURING"
    },
    "KNGR": {
        "id": "KNGR",
        "name": "KING AIRE MFG BY NEWMAR CORP"
    },
    "KNGS": {
        "id": "KNGS",
        "name": "KINGSWAY TRAVEL TRAILER"
    },
    "KNGT": {
        "id": "KNGT",
        "name": "KING TRAILERS"
    },
    "KNGY": {
        "id": "KNGY",
        "name": "KINGSLEY COACH"
    },
    "KNIG": {
        "id": "KNIG",
        "name": "KNIGHT MFG. CORP."
    },
    "KNIV": {
        "id": "KNIV",
        "name": "KNIEVEL MOTORCYCLE MANUFACTURING"
    },
    "KNLH": {
        "id": "KNLH",
        "name": "KNL HOLDINGS"
    },
    "KNNM": {
        "id": "KNNM",
        "name": "KANN MANUFACTURING CORP GUTTENBERG"
    },
    "KNNW": {
        "id": "KNNW",
        "name": "KENWORTH NORTHWEST"
    },
    "KNOW": {
        "id": "KNOW",
        "name": "KNOWLES MFG."
    },
    "KNOX": {
        "id": "KNOX",
        "name": "KNOX HOMES CORP."
    },
    "KNTC": {
        "id": "KNTC",
        "name": "KINETIC ENGINEERING LIMITED PUNE INDIA"
    },
    "KNTI": {
        "id": "KNTI",
        "name": "KENT INDUSTRIES"
    },
    "KNTS": {
        "id": "KNTS",
        "name": "KNIGHT TRAILER SALES"
    },
    "KNUD": {
        "id": "KNUD",
        "name": "KNUDSEN AUTOMOTIVE"
    },
    "KNXM": {
        "id": "KNXM",
        "name": "KINROAD XINTIAN MOTORCYCLE MANUFACTURER"
    },
    "KNXX": {
        "id": "KNXX",
        "name": "KNOX AUTOMOBILE COMPANY MASSACHUSETTS"
    },
    "KOAA": {
        "id": "KOAA",
        "name": "KOA MFG BY PALM HARBOR HOMES"
    },
    "KOAL": {
        "id": "KOAL",
        "name": "KOALA MFG BY LAYTON HOMES CORP."
    },
    "KODI": {
        "id": "KODI",
        "name": "KODIAK COACH & MFG."
    },
    "KODK": {
        "id": "KODK",
        "name": "KODIAK"
    },
    "KODL": {
        "id": "KODL",
        "name": "FRED KODLIN MOTORCYCLES"
    },
    "KOEH": {
        "id": "KOEH",
        "name": "MASTER DIV."
    },
    "KOEN": {
        "id": "KOEN",
        "name": "KOEHN MFG."
    },
    "KOFF": {
        "id": "KOFF",
        "name": "KMMKOFFEL MACHINE & METAL FABRICATING"
    },
    "KOGN": {
        "id": "KOGN",
        "name": "KOGEN INDUSTRIES"
    },
    "KOHL": {
        "id": "KOHL",
        "name": "KOHLER"
    },
    "KOKO": {
        "id": "KOKO",
        "name": "KOKOPELLI TRAILERS PHOENIX"
    },
    "KOLB": {
        "id": "KOLB",
        "name": "KOLBERG MFG. CORP."
    },
    "KOMA": {
        "id": "KOMA",
        "name": "KOMATSU AMERICAN CORP."
    },
    "KOMC": {
        "id": "KOMC",
        "name": "K & O MANUFACTURING"
    },
    "KOME": {
        "id": "KOME",
        "name": "KOMETIC SEE MOTO KOMETIK"
    },
    "KOMF": {
        "id": "KOMF",
        "name": "KOMFORT TRAVEL TRAILER"
    },
    "KOMP": {
        "id": "KOMP",
        "name": "KOMPAK CAMPING TRAILER"
    },
    "KOMR": {
        "id": "KOMR",
        "name": "KOMAR"
    },
    "KOMT": {
        "id": "KOMT",
        "name": "KOMET TRAILERS"
    },
    "KONK": {
        "id": "KONK",
        "name": "KON KWEST MFG."
    },
    "KONT": {
        "id": "KONT",
        "name": "KONTIKI CAMPER TRAILER"
    },
    "KOOL": {
        "id": "KOOL",
        "name": "KOOLS BROTHERS"
    },
    "KORY": {
        "id": "KORY",
        "name": "KORY FARM EQUIPMENT DIV."
    },
    "KOSC": {
        "id": "KOSC",
        "name": "KOSCH"
    },
    "KOST": {
        "id": "KOST",
        "name": "KOSTER MFG."
    },
    "KOTR": {
        "id": "KOTR",
        "name": "KING OF THE ROAD TRAILER"
    },
    "KOUN": {
        "id": "KOUN",
        "name": "KOUNTRYAIRE TRAVEL TRAILER"
    },
    "KOVA": {
        "id": "KOVA",
        "name": "JOHN R. KOVAR MFG."
    },
    "KOZY": {
        "id": "KOZY",
        "name": "KOZY COACH"
    },
    "KPRC": {
        "id": "KPRC",
        "name": "KAY PARK-REC CORP JANESVILLE"
    },
    "KPVI": {
        "id": "KPVI",
        "name": "KOPAVI TRIKE"
    },
    "KRAE": {
        "id": "KRAE",
        "name": "KRAGER KUSTOM KOACH"
    },
    "KRAF": {
        "id": "KRAF",
        "name": "KRAFT"
    },
    "KRAU": {
        "id": "KRAU",
        "name": "KRAUSE PLOW CORP."
    },
    "KRDL": {
        "id": "KRDL",
        "name": "KREIDLER"
    },
    "KREM": {
        "id": "KREM",
        "name": "KREMIN WELDING WALNUT GROVE"
    },
    "KRFT": {
        "id": "KRFT",
        "name": "KRAFTSMAN TRAILERS INC. LEXINGTON NORTH CAROLINA - TRAILERS"
    },
    "KRGM": {
        "id": "KRGM",
        "name": "KARGO-MAX TRAILER ENCLOSURES"
    },
    "KRGO": {
        "id": "KRGO",
        "name": "KARGO TRAILERS IVYLAND PENNSYLVANIA"
    },
    "KRIS": {
        "id": "KRIS",
        "name": "KRIS KRAFT MOBILE HOMES"
    },
    "KRKM": {
        "id": "KRKM",
        "name": "KIRKHAM MOTORSPORTS UTAH"
    },
    "KRMS": {
        "id": "KRMS",
        "name": "KR MOTORS"
    },
    "KROB": {
        "id": "KROB",
        "name": "KRO-BUILT COMPANY RENO"
    },
    "KROH": {
        "id": "KROH",
        "name": "KROHNERT INDUSTRIES"
    },
    "KROM": {
        "id": "KROM",
        "name": "KROMAG"
    },
    "KROP": {
        "id": "KROP",
        "name": "KROPF MFG."
    },
    "KROS": {
        "id": "KROS",
        "name": "KROSS KOUNTRY LITTLE CHUTE WISCONSIN"
    },
    "KROW": {
        "id": "KROW",
        "name": "KROWN CAMPER"
    },
    "KRST": {
        "id": "KRST",
        "name": "KRISTI TRAILER INDUSTRIES"
    },
    "KRUG": {
        "id": "KRUG",
        "name": "KRUGER TRAILERS"
    },
    "KRUZ": {
        "id": "KRUZ",
        "name": "KRUZ"
    },
    "KRYS": {
        "id": "KRYS",
        "name": "KRYSTAL KOACH"
    },
    "KSEA": {
        "id": "KSEA",
        "name": "KASEA MOTORCYCLES"
    },
    "KSON": {
        "id": "KSON",
        "name": "KELLISON KELLISON CAR COMPANY"
    },
    "KSTL": {
        "id": "KSTL",
        "name": "KOASTAL TRAILER CHARLOTTE NC"
    },
    "KSTN": {
        "id": "KSTN",
        "name": "KARSTEN - MOBILE AND MODULAR TRAILERS"
    },
    "KTM": {
        "id": "KTM",
        "name": "KTM MOTOR FAHRZEUGBAU"
    },
    "KTMX": {
        "id": "KTMX",
        "name": "KTMMEX MOTORCYCLE MANUFACTURER"
    },
    "KTPE": {
        "id": "KTPE",
        "name": "KLEESPIE TANK & PETROLEUM EQUIPMENT INC."
    },
    "KTRL": {
        "id": "KTRL",
        "name": "KTRAIL INC MONTMAGNY QUEBEC CANADA"
    },
    "KUBO": {
        "id": "KUBO",
        "name": "KUBOTA CORP."
    },
    "KUHN": {
        "id": "KUHN",
        "name": "KUHN FARM MACHINERY"
    },
    "KUKU": {
        "id": "KUKU",
        "name": "KUNTRY KUSTOM RV LLC"
    },
    "KURM": {
        "id": "KURM",
        "name": "KURMANN TRAILER MFG."
    },
    "KURT": {
        "id": "KURT",
        "name": "KURTIS KRAFT"
    },
    "KUST": {
        "id": "KUST",
        "name": "KUSTOM KRAFT"
    },
    "KUTK": {
        "id": "KUTK",
        "name": "KUT-KWICK CORP.MFRS. INDUSTRIAL MOWERS BRUNSWICK"
    },
    "KUTZ": {
        "id": "KUTZ",
        "name": "KUTZ FARM EQUIPMENT PINE GROVE"
    },
    "KVCH": {
        "id": "KVCH",
        "name": "KOVATCH MOBILE EQUIPMENT CORP NESQUEHONING"
    },
    "KW": {
        "id": "KW",
        "name": "KENWORTH MOTOR TRUCK"
    },
    "KWDT": {
        "id": "KWDT",
        "name": "DART"
    },
    "KWIK": {
        "id": "KWIK",
        "name": "KWIK-LOC CORP."
    },
    "KWKE": {
        "id": "KWKE",
        "name": "KWIK EQUIPMENT SALES"
    },
    "KWKT": {
        "id": "KWKT",
        "name": "KWICK KIT CEMENT MIXER"
    },
    "KWLD": {
        "id": "KWLD",
        "name": "KWIK LOAD"
    },
    "KWMH": {
        "id": "KWMH",
        "name": "K & K MOBILE HOMES"
    },
    "KWNS": {
        "id": "KWNS",
        "name": "KENSINGTON WELDING & TRAILER"
    },
    "KWTS": {
        "id": "KWTS",
        "name": "K AND W TRAILER SALES"
    },
    "KXDM": {
        "id": "KXDM",
        "name": "KXD MOTO GERMANY"
    },
    "KYAL": {
        "id": "KYAL",
        "name": "ALPINE MFG BY KEYSTONE RV COMPANY"
    },
    "KYAT": {
        "id": "KYAT",
        "name": "ATLAS BRAND MFG BY KEYSTONE RV COMPANY"
    },
    "KYAV": {
        "id": "KYAV",
        "name": "AVALANCHE"
    },
    "KYBS": {
        "id": "KYBS",
        "name": "BIG SKY MFG BY KEYSTONE RV COMPANY"
    },
    "KYBU": {
        "id": "KYBU",
        "name": "BULLET MFG BY KEYSTONE RV COMPANY"
    },
    "KYCB": {
        "id": "KYCB",
        "name": "CARBON MFG BY KEYSTONE RV COMPANY"
    },
    "KYCC": {
        "id": "KYCC",
        "name": "COPPER CANYON MFG BY KEYSTONE RV COMPANY"
    },
    "KYCO": {
        "id": "KYCO",
        "name": "COUGAR MFG BY KEYSTONE RV COMPANY"
    },
    "KYDN": {
        "id": "KYDN",
        "name": "DENALI MODEL MFG BY KEYSTONE RV COMPANY"
    },
    "KYED": {
        "id": "KYED",
        "name": "ENDURANCE BRAND MFG BY KEYSTONE RV COMPANY"
    },
    "KYEN": {
        "id": "KYEN",
        "name": "ENERGY MFG BY KEYSTONE RV COMPANY"
    },
    "KYFB": {
        "id": "KYFB",
        "name": "KYFAB LLC MAYFIELD KY"
    },
    "KYFU": {
        "id": "KYFU",
        "name": "FUZION MFG BY KEYSTONE RV COMPANY"
    },
    "KYGD": {
        "id": "KYGD",
        "name": "GUIDE MFG BY KEYSTONE RV COMPANY"
    },
    "KYHI": {
        "id": "KYHI",
        "name": "HIDEOUT MODEL MFG BY - KEYSTONE RV COMPANY"
    },
    "KYHO": {
        "id": "KYHO",
        "name": "HORNET MFG BY KEYSTONE RV COMPANY"
    },
    "KYIM": {
        "id": "KYIM",
        "name": "IMPACT MFG BY KEYSTONE RV COMPANY"
    },
    "KYLA": {
        "id": "KYLA",
        "name": "LAREDO MFG BY KEYSTONE RV COMPANY"
    },
    "KYLG": {
        "id": "KYLG",
        "name": "LONGHORN BRAND MFG BY KEYSTONE RV COMPANY"
    },
    "KYMC": {
        "id": "KYMC",
        "name": "KYM"
    },
    "KYMO": {
        "id": "KYMO",
        "name": "MONTANA MFG BY KEYSTONE RV COMPANY"
    },
    "KYMT": {
        "id": "KYMT",
        "name": "MOUNTAINEER MFG BY KEYSTONE RV COMPANY"
    },
    "KYOU": {
        "id": "KYOU",
        "name": "OUTBACK MFG BY KEYSTONE RV COMPANY"
    },
    "KYPA": {
        "id": "KYPA",
        "name": "PASSPORT MFG BY KEYSTONE RV COMPANY"
    },
    "KYPR": {
        "id": "KYPR",
        "name": "PREMIER MFG BY KEYSTONE RV COMPANY"
    },
    "KYRA": {
        "id": "KYRA",
        "name": "RAPTOR MFG BY KEYSTONE RV COMPANY"
    },
    "KYRS": {
        "id": "KYRS",
        "name": "RESIDENCE MFG BY KEYSTONE RV COMPANY"
    },
    "KYRT": {
        "id": "KYRT",
        "name": "RETREAT MFG BY KEYSTONE RV COMPANY"
    },
    "KYRV": {
        "id": "KYRV",
        "name": "KEYSTONE RV COMPANY"
    },
    "KYSP": {
        "id": "KYSP",
        "name": "SPRINGDALE MFG BY KEYSTONE RV COMPANY"
    },
    "KYSR": {
        "id": "KYSR",
        "name": "SPRINTER MFG BY KEYSTONE RV COMPANY"
    },
    "KYVA": {
        "id": "KYVA",
        "name": "VANTAGE MFG BY KEYSTONE RV"
    },
    "KYZR": {
        "id": "KYZR",
        "name": "KYZER ALUMINUM INC PALMETTO FL"
    },
    "KZAI": {
        "id": "KZAI",
        "name": "KYZER ALUMINUM INC BRADENTON FL"
    },
    "KZCN": {
        "id": "KZCN",
        "name": "CONNECT BRAND MFG BY KZRC"
    },
    "KZMI": {
        "id": "KZMI",
        "name": "KEIZER-MORRIS INTERNATIONAL"
    },
    "KZPR": {
        "id": "KZPR",
        "name": "PRESTIGE MODEL"
    },
    "KZRV": {
        "id": "KZRV",
        "name": "K Z INC. OR K Z RECREATIONAL VEHICLES"
    },
    "KZSE": {
        "id": "KZSE",
        "name": "SPREE AND SPREE ESCAPE BRANDS MFG BY KZRV"
    },
    "KZSN": {
        "id": "KZSN",
        "name": "SONIC MODEL"
    },
    "KZSP": {
        "id": "KZSP",
        "name": "SPORTSMAN CLASSIC AND SPORSTER BRANDS MFG BY KZRV"
    },
    "KZSS": {
        "id": "KZSS",
        "name": "STRATUS MODEL MFG BY KZRV INDIANA"
    },
    "KZST": {
        "id": "KZST",
        "name": "KS-SPORT TREK MFG BY KZRV"
    },
    "KZSW": {
        "id": "KZSW",
        "name": "SIDEWINDER BRANF MFG BY KZRV"
    },
    "KZVN": {
        "id": "KZVN",
        "name": "VENOM MODEL MFG BY KZRV LP"
    },
    "KZVS": {
        "id": "KZVS",
        "name": "VISION"
    },
    "LABE": {
        "id": "LABE",
        "name": "LADY BEA TRAILERS MALDEN MASSACHUSETTS"
    },
    "LABT": {
        "id": "LABT",
        "name": "LOADMASTER ALUMINUM BOAT TRAILERS"
    },
    "LACC": {
        "id": "LACC",
        "name": "LACONIA CUSTOM CYCLES"
    },
    "LACG": {
        "id": "LACG",
        "name": "L A CARGO TRAILERS"
    },
    "LACH": {
        "id": "LACH",
        "name": "LAWN CHIEF"
    },
    "LACR": {
        "id": "LACR",
        "name": "LACROSSE TOWABLE - DIVISION OF FOREST RIVER"
    },
    "LACY": {
        "id": "LACY",
        "name": "LACEY"
    },
    "LADA": {
        "id": "LADA",
        "name": "LADA"
    },
    "LAEC": {
        "id": "LAEC",
        "name": "E"
    },
    "LAER": {
        "id": "LAER",
        "name": "LET'S GO AERO"
    },
    "LAFE": {
        "id": "LAFE",
        "name": "MP LAFER BRAZIL"
    },
    "LAFR": {
        "id": "LAFR",
        "name": "AMERICAN LA FRANCEDIV. OF A-T-O"
    },
    "LAFY": {
        "id": "LAFY",
        "name": "LAFAYETTE MOTOR HOME"
    },
    "LAGO": {
        "id": "LAGO",
        "name": "LAGONDA"
    },
    "LAGU": {
        "id": "LAGU",
        "name": "LAGUSA MOTOR COACH"
    },
    "LAKE": {
        "id": "LAKE",
        "name": "BESTRAIL MFG BY LAKESIDE INDUSTRIES"
    },
    "LAKL": {
        "id": "LAKL",
        "name": "LAKELAND CAMPER & MFG.DRAYTON PLAINS"
    },
    "LAKO": {
        "id": "LAKO",
        "name": "LAKOTA CORPORATION"
    },
    "LAKR": {
        "id": "LAKR",
        "name": "LAKE RAIDER"
    },
    "LAKS": {
        "id": "LAKS",
        "name": "LAKESIDE MFG BY KROPF INDUSTRIES"
    },
    "LAKT": {
        "id": "LAKT",
        "name": "LAKESIDE TRAILER MANUFACTURING INC"
    },
    "LALL": {
        "id": "LALL",
        "name": "LASALLE"
    },
    "LAMB": {
        "id": "LAMB",
        "name": "LAMBRETTA"
    },
    "LAMO": {
        "id": "LAMO",
        "name": "LAMBORGHINI"
    },
    "LAMR": {
        "id": "LAMR",
        "name": "LAMAR TRAILERS SUMMERVILLE"
    },
    "LAN": {
        "id": "LAN",
        "name": "LANE FLATBED TRAILER"
    },
    "LANA": {
        "id": "LANA",
        "name": "LANCER MOBILE HOMES"
    },
    "LANC": {
        "id": "LANC",
        "name": "LANCHESTER"
    },
    "LAND": {
        "id": "LAND",
        "name": "LANDCRAFT CORP."
    },
    "LANE": {
        "id": "LANE",
        "name": "LANE HORSE TRAILER"
    },
    "LANG": {
        "id": "LANG",
        "name": "LANGLEY MFG. DIV."
    },
    "LANH": {
        "id": "LANH",
        "name": "LANDOLA HOMES"
    },
    "LANL": {
        "id": "LANL",
        "name": "EASY RIDER MFG BY LANDOLL CORP."
    },
    "LANM": {
        "id": "LANM",
        "name": "LANDMASTER"
    },
    "LANP": {
        "id": "LANP",
        "name": "LANES PACESETTER TRAILER"
    },
    "LANR": {
        "id": "LANR",
        "name": "LANCER"
    },
    "LANS": {
        "id": "LANS",
        "name": "LANDIS STEEL"
    },
    "LANT": {
        "id": "LANT",
        "name": "LANHEIM"
    },
    "LANU": {
        "id": "LANU",
        "name": "LANDAU MOTOR HOMEANAHEIM"
    },
    "LAOT": {
        "id": "LAOT",
        "name": "BRINDLELAOTTO METAL FOBRICATING"
    },
    "LARA": {
        "id": "LARA",
        "name": "LARADO MOBILE HOMES"
    },
    "LARG": {
        "id": "LARG",
        "name": "LARGES CATTLE SERVICE OR LARGES AI NEBRASKA PORTABLE BREEDING TRAILERS"
    },
    "LARI": {
        "id": "LARI",
        "name": "LAWRIMORE MANUFACTURING"
    },
    "LARK": {
        "id": "LARK",
        "name": "LARK TRAILER"
    },
    "LARN": {
        "id": "LARN",
        "name": "W. F. LARSON"
    },
    "LARO": {
        "id": "LARO",
        "name": "LARSON MACHINE"
    },
    "LARS": {
        "id": "LARS",
        "name": "LARSEN LAPLINE TRAILER"
    },
    "LARV": {
        "id": "LARV",
        "name": "FIRMA LENKO SEE LARVIN"
    },
    "LASA": {
        "id": "LASA",
        "name": "LASALLEDIV. GLOBEMASTER M.H."
    },
    "LASE": {
        "id": "LASE",
        "name": "LASER"
    },
    "LASH": {
        "id": "LASH",
        "name": "LAKE & SHORE CAMPER"
    },
    "LASL": {
        "id": "LASL",
        "name": "LASALLE HOMES DIV OF TIDWELL INDUSTRIES"
    },
    "LATL": {
        "id": "LATL",
        "name": "LAO TRAILER MANUFACTURING TOLEDO"
    },
    "LAVE": {
        "id": "LAVE",
        "name": "LAVERDA"
    },
    "LAWC": {
        "id": "LAWC",
        "name": "L. A. WOODS"
    },
    "LAWL": {
        "id": "LAWL",
        "name": "WALKABOUT MFG BY LAYTON HOMES CORP"
    },
    "LAWN": {
        "id": "LAWN",
        "name": "LAWNDALE HOMES"
    },
    "LAWR": {
        "id": "LAWR",
        "name": "LAWRENCE TRAILER"
    },
    "LAYM": {
        "id": "LAYM",
        "name": "LAYMOR DIVISION OF REV GROUP LONGVIEW TX"
    },
    "LAYO": {
        "id": "LAYO",
        "name": "LAYTON MFG."
    },
    "LAYT": {
        "id": "LAYT",
        "name": "LAYTON HOMES CORP."
    },
    "LAZE": {
        "id": "LAZE",
        "name": "LAZER"
    },
    "LAZJ": {
        "id": "LAZJ",
        "name": "LAZY J HORSE TRAILER"
    },
    "LBAU": {
        "id": "LBAU",
        "name": "LEBEAU ENTERPRISES LEXINGTON"
    },
    "LBBG": {
        "id": "LBBG",
        "name": "LANG BAR-B-Q GRILLS"
    },
    "LBCP": {
        "id": "LBCP",
        "name": "L & B CONCEPTS"
    },
    "LBCR": {
        "id": "LBCR",
        "name": "CAMP RIVER BRAND MFG BY LIBERTY OUTDOORS LLC"
    },
    "LBCT": {
        "id": "LBCT",
        "name": "L & B CUSTOM TRAILERS MISSOURI"
    },
    "LBEE": {
        "id": "LBEE",
        "name": "LARRABEE MARINE SARASOTA"
    },
    "LBHR": {
        "id": "LBHR",
        "name": "LIEBHERR COMPANIES CRANES & CONSTRUCTION EQUIPMENT"
    },
    "LBMH": {
        "id": "LBMH",
        "name": "LYNBROOKE MANUFACTURED HOME"
    },
    "LBMX": {
        "id": "LBMX",
        "name": "MAX"
    },
    "LBMY": {
        "id": "LBMY",
        "name": "MYPOD BRAND MFG BY LIBERTY OUTDOORS LLC"
    },
    "LBOS": {
        "id": "LBOS",
        "name": "LOAD BOSS"
    },
    "LBOY": {
        "id": "LBOY",
        "name": "LAWN BOY MFG. BY OUTBOARD MARINE CORP."
    },
    "LBPL": {
        "id": "LBPL",
        "name": "PARK LINER BRAND MFG BY LIBERTY OUTDOORS LLC"
    },
    "LBRN": {
        "id": "LBRN",
        "name": "LEBARON AMBULANCES"
    },
    "LBRO": {
        "id": "LBRO",
        "name": "LIBERO MFG BY TRIPLE E RECREATIONAL VEHICLES CANADA"
    },
    "LBRY": {
        "id": "LBRY",
        "name": "LIBERTY SEAMLESS ENTERPRISES OR LIBERTY ELECTRIC BIKES"
    },
    "LBT": {
        "id": "LBT",
        "name": "LBTDIV. FRUEHAUF CORP."
    },
    "LBTR": {
        "id": "LBTR",
        "name": "LIBERATOR MOTORCYCLES"
    },
    "LBTY": {
        "id": "LBTY",
        "name": "LIBERTY"
    },
    "LBWD": {
        "id": "LBWD",
        "name": "L B WELDING BRIGHTON ONTARIO CANADA"
    },
    "LCCC": {
        "id": "LCCC",
        "name": "LC3"
    },
    "LCHG": {
        "id": "LCHG",
        "name": "LONG CHANG USA ATV'S"
    },
    "LCIN": {
        "id": "LCIN",
        "name": "LONCIN GROUP IMPORT & EXPORT"
    },
    "LCON": {
        "id": "LCON",
        "name": "LUCON"
    },
    "LCRT": {
        "id": "LCRT",
        "name": "L-CART"
    },
    "LCTS": {
        "id": "LCTS",
        "name": "LAN CHESTER"
    },
    "LD": {
        "id": "LD",
        "name": "L & D TRAILERS INCORPORATED SIKESTON"
    },
    "LDAZ": {
        "id": "LDAZ",
        "name": "LAZY DAZE MOTOR HOME MFG IN POMONA"
    },
    "LDCI": {
        "id": "LDCI",
        "name": "LDC INDUSTRIES"
    },
    "LDCO": {
        "id": "LDCO",
        "name": "LONDON EV COMPANY LIMITED"
    },
    "LDEI": {
        "id": "LDEI",
        "name": "LONG DOG ENTERPRISE"
    },
    "LDIT": {
        "id": "LDIT",
        "name": "LEADER INDUSTRIAL TIRES KNOXVILLE"
    },
    "LDJM": {
        "id": "LDJM",
        "name": "LDJ MANUFACTURING"
    },
    "LDLN": {
        "id": "LDLN",
        "name": "LOAD LINE TRAILER MANUFACTURING MANITOBA"
    },
    "LDMC": {
        "id": "LDMC",
        "name": "LOADMAC LTD"
    },
    "LDMS": {
        "id": "LDMS",
        "name": "LOADMASTER INC INDIANA"
    },
    "LDMX": {
        "id": "LDMX",
        "name": "LOAD MAX TRAILERS"
    },
    "LDTC": {
        "id": "LDTC",
        "name": "LOADMASTER TRAILER COMPANY"
    },
    "LDTL": {
        "id": "LDTL",
        "name": "LOAD TRAIL"
    },
    "LDUN": {
        "id": "LDUN",
        "name": "LADUN MOTORCYCLE"
    },
    "LDVI": {
        "id": "LDVI",
        "name": "LDV"
    },
    "LEAD": {
        "id": "LEAD",
        "name": "LEADER HORSE TRAILER"
    },
    "LEAF": {
        "id": "LEAF",
        "name": "LEA-FRANCIS"
    },
    "LEAR": {
        "id": "LEAR",
        "name": "LEAR SIEGLER"
    },
    "LEBR": {
        "id": "LEBR",
        "name": "LEBER COACH MANUFACTURING"
    },
    "LECH": {
        "id": "LECH",
        "name": "LECHMERE CONSTRUCTION"
    },
    "LECT": {
        "id": "LECT",
        "name": "LECTRACAN"
    },
    "LEDW": {
        "id": "LEDW",
        "name": "LEDWELL & SON ENTERPRISES"
    },
    "LEE": {
        "id": "LEE",
        "name": "LEE"
    },
    "LEEB": {
        "id": "LEEB",
        "name": "LEE BOY CONSTRUCTION EQUIPMENT ASPHALT & CONCRETE"
    },
    "LEEC": {
        "id": "LEEC",
        "name": "LEE COACHES"
    },
    "LEEN": {
        "id": "LEEN",
        "name": "L & E ENTERPRISES"
    },
    "LEER": {
        "id": "LEER",
        "name": "LEER"
    },
    "LEES": {
        "id": "LEES",
        "name": "LEESBURG DIV.DIV. OF DIVCO-WAYNE INDUSTRIES"
    },
    "LEGA": {
        "id": "LEGA",
        "name": "LEGACY HOUSING"
    },
    "LEHM": {
        "id": "LEHM",
        "name": "LEHMAN TRIKES USA"
    },
    "LEIR": {
        "id": "LEIR",
        "name": "LEISURE CRAFT"
    },
    "LEIS": {
        "id": "LEIS",
        "name": "LEISURETIME MOTOR HOME"
    },
    "LEIT": {
        "id": "LEIT",
        "name": "LEISURE HOME TRAILER MANUFACTURER - LEISURE TIME"
    },
    "LEIU": {
        "id": "LEIU",
        "name": "LEISURE PRODUCTS"
    },
    "LEKT": {
        "id": "LEKT",
        "name": "LEKTRACYCLE"
    },
    "LELA": {
        "id": "LELA",
        "name": "LELAND ENGINEERING"
    },
    "LELY": {
        "id": "LELY",
        "name": "THE LELY CORP."
    },
    "LEM": {
        "id": "LEM",
        "name": "LEM MOTOR"
    },
    "LEMC": {
        "id": "LEMC",
        "name": "LEM"
    },
    "LEND": {
        "id": "LEND",
        "name": "LEN DAR CAMPERS"
    },
    "LENT": {
        "id": "LENT",
        "name": "LEE ENTERPRISES MANUFACTURING"
    },
    "LEON": {
        "id": "LEON",
        "name": "LEON MANUFACTURING"
    },
    "LERY": {
        "id": "LERY",
        "name": "LE ROI DIV.DRESSER IND."
    },
    "LESA": {
        "id": "LESA",
        "name": "LES AUTOBUS M.C.I QUEBEC CANADA"
    },
    "LESC": {
        "id": "LESC",
        "name": "LESCO FARM & GARDEN EQUIPMENT"
    },
    "LESL": {
        "id": "LESL",
        "name": "LEES LEISURE IND."
    },
    "LESR": {
        "id": "LESR",
        "name": "LEISURE MANOR"
    },
    "LETO": {
        "id": "LETO",
        "name": "LETOURNEAU"
    },
    "LEVC": {
        "id": "LEVC",
        "name": "LEV"
    },
    "LEVI": {
        "id": "LEVI",
        "name": "LEVIS MOTORCYCLES GREAT BRITAIN"
    },
    "LEWA": {
        "id": "LEWA",
        "name": "LEWAUB TRAILER MFG."
    },
    "LEWI": {
        "id": "LEWI",
        "name": "LEWIS-SHEPARD"
    },
    "LEXS": {
        "id": "LEXS",
        "name": "LEXUS"
    },
    "LEYL": {
        "id": "LEYL",
        "name": "LEYLAND TRACTORS BRITISH LEYLAND DIST. BY UNIVERSAL TRACTOR EQUIPMENT CORP"
    },
    "LFET": {
        "id": "LFET",
        "name": "LIFETIME CUSTOM COACH"
    },
    "LFRV": {
        "id": "LFRV",
        "name": "LIFESTYLE RV"
    },
    "LFTA": {
        "id": "LFTA",
        "name": "LIFT-A-LOAD ILLINOIS TRAILERS"
    },
    "LFTL": {
        "id": "LFTL",
        "name": "LAFAYETTELOWBOY TRAILERS"
    },
    "LFTQ": {
        "id": "LFTQ",
        "name": "LIFTING EQUIPMENT SOLUTIONS"
    },
    "LFTY": {
        "id": "LFTY",
        "name": "LEFTY BROTHERS CYCLES"
    },
    "LFZA": {
        "id": "LFZA",
        "name": "LAFORZA UTILITY VEHICLE HAYWARD"
    },
    "LG": {
        "id": "LG",
        "name": "L & G TRAILER"
    },
    "LGAE": {
        "id": "LGAE",
        "name": "LET'S GO AERO COLORADO SPRINGS"
    },
    "LGBO": {
        "id": "LGBO",
        "name": "LONGBO"
    },
    "LGCH": {
        "id": "LGCH",
        "name": "LONG CHIH INDUSTRIAL"
    },
    "LGCY": {
        "id": "LGCY",
        "name": "LEGACY RV LLC"
    },
    "LGHT": {
        "id": "LGHT",
        "name": "LIGHTNING TRAILERS"
    },
    "LGLX": {
        "id": "LGLX",
        "name": "LEGENDARY LUXURY COACH LLC"
    },
    "LGMC": {
        "id": "LGMC",
        "name": "LOGAN MACHINE COMPANY TRAILERS WELDING & FABRICATION"
    },
    "LGND": {
        "id": "LGND",
        "name": "LEGEND TRAILERS"
    },
    "LGNM": {
        "id": "LGNM",
        "name": "LEGEND MANUFACTURING"
    },
    "LGSE": {
        "id": "LGSE",
        "name": "LAGASSE RIDES HAVERHILL"
    },
    "LGSP": {
        "id": "LGSP",
        "name": "LANG SPECIALTY TRAILERS LLC LATROBE PA"
    },
    "LGTL": {
        "id": "LGTL",
        "name": "LIGHTLINE PRODUCTS INC SYRACUSE"
    },
    "LGTX": {
        "id": "LGTX",
        "name": "LEGEND TRAILERS OF TEXAS"
    },
    "LHRN": {
        "id": "LHRN",
        "name": "LONGHORN TANK & TRAILER"
    },
    "LHRV": {
        "id": "LHRV",
        "name": "LIGHTHOUSE RV MANUFACTURING LLC WAWAKA"
    },
    "LIBA": {
        "id": "LIBA",
        "name": "LIBERTY COACH"
    },
    "LIBB": {
        "id": "LIBB",
        "name": "LIBBY CORPORATION - TRAILER MOUNTED GENERATORS"
    },
    "LIBC": {
        "id": "LIBC",
        "name": "LIBERTY TRAILER COMPANY MICHIGAN"
    },
    "LIBE": {
        "id": "LIBE",
        "name": "LIBERTY TRAVEL TRAILERS"
    },
    "LIBH": {
        "id": "LIBH",
        "name": "LIBERTY HOMES"
    },
    "LIBI": {
        "id": "LIBI",
        "name": "LIBERTY INC"
    },
    "LIBO": {
        "id": "LIBO",
        "name": "LIBERTY OUTDOORS LLC UNIONTOWN OH"
    },
    "LIBT": {
        "id": "LIBT",
        "name": "LIBERATOR BOATS CROWLEY"
    },
    "LIBY": {
        "id": "LIBY",
        "name": "LIBERTY INDUSTRIES CLAYTON"
    },
    "LICH": {
        "id": "LICH",
        "name": "LICHTY'S BLACKSMITHING"
    },
    "LICO": {
        "id": "LICO",
        "name": "LIN"
    },
    "LIEB": {
        "id": "LIEB",
        "name": "LIEBER INDUSTRIES"
    },
    "LIFG": {
        "id": "LIFG",
        "name": "LIFETIME PRODUCTS GROUP"
    },
    "LIFL": {
        "id": "LIFL",
        "name": "LIFE LINE EMERGENCY VEHICLES"
    },
    "LIFM": {
        "id": "LIFM",
        "name": "LIFTMASTER"
    },
    "LIFN": {
        "id": "LIFN",
        "name": "LIFAN INDUSTRY GROUP"
    },
    "LIFT": {
        "id": "LIFT",
        "name": "LIFETIME PRODUCTS INC."
    },
    "LIGC": {
        "id": "LIGC",
        "name": "LITTLE GIANT CRANE & SHOVEL"
    },
    "LIGH": {
        "id": "LIGH",
        "name": "LIGHT EQUIPMENT DIV.DIV. OF J. I. CASE"
    },
    "LIGN": {
        "id": "LIGN",
        "name": "LIGHTNING ROD MOTORCYCLES"
    },
    "LIGP": {
        "id": "LIGP",
        "name": "LITTLE GIANT PRODUCTS"
    },
    "LIGT": {
        "id": "LIGT",
        "name": "LIGHTER-BILT TRAILERS"
    },
    "LIKN": {
        "id": "LIKN",
        "name": "LIKENS TRANSFER & DUMP TRUCK OR EMPIRE LIKENS"
    },
    "LILA": {
        "id": "LILA",
        "name": "LILAC"
    },
    "LILB": {
        "id": "LILB",
        "name": "LIL' BUBBA"
    },
    "LILC": {
        "id": "LILC",
        "name": "LIL' CAT"
    },
    "LILL": {
        "id": "LILL",
        "name": "LILLISTON CORP.ALBANY"
    },
    "LILN": {
        "id": "LILN",
        "name": "LIL INDIAN"
    },
    "LILS": {
        "id": "LILS",
        "name": "LIL SNOOZY"
    },
    "LILT": {
        "id": "LILT",
        "name": "LIL-TAG-ALONG TRAILERS"
    },
    "LILZ": {
        "id": "LILZ",
        "name": "LIL'Z MANUFACTURED BY ZIEMAN PRODUCTS"
    },
    "LIME": {
        "id": "LIME",
        "name": "LIME CITY EQUIPMENT"
    },
    "LIMO": {
        "id": "LIMO",
        "name": "LIMO TRIKES AUSTRAILIA AUSTRAILIA"
    },
    "LINC": {
        "id": "LINC",
        "name": "LINCOLN-CONTINENTAL"
    },
    "LIND": {
        "id": "LIND",
        "name": "LINDE"
    },
    "LINF": {
        "id": "LINF",
        "name": "LINCRAFT INDUSTRIES"
    },
    "LINH": {
        "id": "LINH",
        "name": "LINHAI MOPEDS"
    },
    "LINK": {
        "id": "LINK",
        "name": "LINK BELT"
    },
    "LINM": {
        "id": "LINM",
        "name": "APACHEMFD. BY LINDIG MFG. CORP."
    },
    "LINP": {
        "id": "LINP",
        "name": "LINCOLN PARK MOBILE HOMES"
    },
    "LINT": {
        "id": "LINT",
        "name": "LINTZCRAFT TRAVEL TRAILER"
    },
    "LINV": {
        "id": "LINV",
        "name": "LINVILLE HORSE TRAILER"
    },
    "LINW": {
        "id": "LINW",
        "name": "LINWOOD MFG."
    },
    "LIOB": {
        "id": "LIOB",
        "name": "AUTOBUS LION INC"
    },
    "LIOL": {
        "id": "LIOL",
        "name": "LIONAL ENTERPRISES"
    },
    "LION": {
        "id": "LION",
        "name": "LIFTALL FORKLIFT MFG BY LION MFG."
    },
    "LIPP": {
        "id": "LIPP",
        "name": "LIPPERT COMPONENTS MFG."
    },
    "LIPR": {
        "id": "LIPR",
        "name": "LITTLE PROSPECTOR MOTOR HOME"
    },
    "LIQU": {
        "id": "LIQU",
        "name": "LIQUID & BULK TANK DIV.DIV. OF FRUEHAUF CORP.--OMAHA"
    },
    "LISL": {
        "id": "LISL",
        "name": "LISLET FOUNDRIES"
    },
    "LITC": {
        "id": "LITC",
        "name": "LITTLE CHUM MFG."
    },
    "LITD": {
        "id": "LITD",
        "name": "LITTLE DUDE TRAILER"
    },
    "LITF": {
        "id": "LITF",
        "name": "LITTLE FOX CAMPERS"
    },
    "LITG": {
        "id": "LITG",
        "name": "LITTLE GUY RV"
    },
    "LITK": {
        "id": "LITK",
        "name": "LIFT TRUCK"
    },
    "LITP": {
        "id": "LITP",
        "name": "LITTLE PRINCE TRAVEL TRAILERMFRS. BOAT TRAILERS"
    },
    "LITS": {
        "id": "LITS",
        "name": "LITTLE SPORT ENTERPRISES"
    },
    "LITT": {
        "id": "LITT",
        "name": "LITTLEFORD BROTHERS"
    },
    "LIVN": {
        "id": "LIVN",
        "name": "LIVINGSTON CUSTOM BOAT TRAILERS PALMDALE CALIFORNIA"
    },
    "LIWK": {
        "id": "LIWK",
        "name": "LIGHT WORKS SHEPHERD'S TABLE POST FALLS"
    },
    "LJWE": {
        "id": "LJWE",
        "name": "LJW ENTERPRISES"
    },
    "LKTK": {
        "id": "LKTK",
        "name": "LEKTRIKE ESCONDIDO"
    },
    "LKWD": {
        "id": "LKWD",
        "name": "LAKEWOOD"
    },
    "LLFS": {
        "id": "LLFS",
        "name": "L & L FABRICATION & STEEL COMPANY PERHAM"
    },
    "LLIL": {
        "id": "LLIL",
        "name": "LEES LEISURE INDUSTRIES LIMITED"
    },
    "LLMO": {
        "id": "LLMO",
        "name": "LECTRIC LIMO"
    },
    "LLOD": {
        "id": "LLOD",
        "name": "LLOYD'S TRAILER FINISHING"
    },
    "LLOY": {
        "id": "LLOY",
        "name": "LLOYD MOTOREN WERKE GERMANY"
    },
    "LLTI": {
        "id": "LLTI",
        "name": "LAKELAND TRAILERS INC HILL CITY MN"
    },
    "LLTR": {
        "id": "LLTR",
        "name": "L & L TRAILERS"
    },
    "LMC": {
        "id": "LMC",
        "name": "LMC"
    },
    "LMCL": {
        "id": "LMCL",
        "name": "LONG MACHINE COMPANY LLC MAIDEN NC"
    },
    "LMLC": {
        "id": "LMLC",
        "name": "LML CORP."
    },
    "LMLL": {
        "id": "LMLL",
        "name": "LML LIMITED CHICAGO"
    },
    "LMMC": {
        "id": "LMMC",
        "name": "L & M TRAILER MFG."
    },
    "LMOT": {
        "id": "LMOT",
        "name": "THE LITTLE MOTOR CAR"
    },
    "LMRK": {
        "id": "LMRK",
        "name": "LANDMARK MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "LMTL": {
        "id": "LMTL",
        "name": "LOAD MAX TRAILERS"
    },
    "LNCE": {
        "id": "LNCE",
        "name": "LANCE TRUCK CAMPERS AND TRAVEL TRAILERS CALIFORNIA 2O12"
    },
    "LNCI": {
        "id": "LNCI",
        "name": "LANCIA"
    },
    "LNCO": {
        "id": "LNCO",
        "name": "LINCOLN ELECTRIC"
    },
    "LNCP": {
        "id": "LNCP",
        "name": "LANCE POWERSPORTS"
    },
    "LNCT": {
        "id": "LNCT",
        "name": "LANCASTER MFG."
    },
    "LNDA": {
        "id": "LNDA",
        "name": "LANDA CLEANING SYSTEMS"
    },
    "LNDI": {
        "id": "LNDI",
        "name": "LANDINI SPA ITALY"
    },
    "LNDN": {
        "id": "LNDN",
        "name": "LONDON TAXIS OF NORTH AMERICA SUDBURY"
    },
    "LNDR": {
        "id": "LNDR",
        "name": "LAND ROVER"
    },
    "LNEW": {
        "id": "LNEW",
        "name": "LA NEW INDUSTRIES"
    },
    "LNGB": {
        "id": "LNGB",
        "name": "LANDGREBE MANUFACTURING"
    },
    "LNGH": {
        "id": "LNGH",
        "name": "LONGHORN TRAILERS"
    },
    "LNGN": {
        "id": "LNGN",
        "name": "LINGTIAN MOTORCYCLE"
    },
    "LNGO": {
        "id": "LNGO",
        "name": "LOAD N GO TRAILER MFG. OMAHA"
    },
    "LNGT": {
        "id": "LNGT",
        "name": "LONGTING POWER EQUIPMENT"
    },
    "LNGV": {
        "id": "LNGV",
        "name": "LINGYU VEHICLE INDUSTRY"
    },
    "LNGZ": {
        "id": "LNGZ",
        "name": "LIANGZI POWER"
    },
    "LNKW": {
        "id": "LNKW",
        "name": "LINKLETTER'S WELDING"
    },
    "LNMR": {
        "id": "LNMR",
        "name": "LINAMAR CONSUMER PRODUCTS USA"
    },
    "LNPD": {
        "id": "LNPD",
        "name": "LAND PRIDE FARM & GARDENING EQUIPMENT MOWERS"
    },
    "LNPR": {
        "id": "LNPR",
        "name": "LAND PRIDE DIVISION OF GREAT PLAINS MANUFACTURING MAKERS OF FARM"
    },
    "LNRD": {
        "id": "LNRD",
        "name": "LEINARD ALUMINUM BUILDINGS"
    },
    "LNST": {
        "id": "LNST",
        "name": "LONE STAR MANUFACTURING VALLEY VIEW TEXAS"
    },
    "LNWC": {
        "id": "LNWC",
        "name": "LINE WARD CORP BUFFALO NY"
    },
    "LNWF": {
        "id": "LNWF",
        "name": "LONE WOLFE"
    },
    "LOAC": {
        "id": "LOAC",
        "name": "LOADCRAFT BRADY"
    },
    "LOAD": {
        "id": "LOAD",
        "name": "LOAD RITE TRAILERSDIV. OF PENNSBURY MFG."
    },
    "LOAK": {
        "id": "LOAK",
        "name": "LOAD KING TRAILER"
    },
    "LOCK": {
        "id": "LOCK",
        "name": "LOCKE ENTERPRISES OF NEW YORK"
    },
    "LOCM": {
        "id": "LOCM",
        "name": "LOCAL MOTORS PNOENIX AZ"
    },
    "LOCO": {
        "id": "LOCO",
        "name": "LOCOMOBILE"
    },
    "LODA": {
        "id": "LODA",
        "name": "LODAL"
    },
    "LODC": {
        "id": "LODC",
        "name": "BUSHOG"
    },
    "LODE": {
        "id": "LODE",
        "name": "LOAD STAR CORP.MACON"
    },
    "LODK": {
        "id": "LODK",
        "name": "LODE KING CANADA"
    },
    "LODZ": {
        "id": "LODZ",
        "name": "LOAD-EAZ TRAILER INC.BENSALEM"
    },
    "LOFT": {
        "id": "LOFT",
        "name": "LOFTNESS MFG."
    },
    "LOGI": {
        "id": "LOGI",
        "name": "LOGIC MOTOR"
    },
    "LOGN": {
        "id": "LOGN",
        "name": "LOGAN COACH"
    },
    "LOLA": {
        "id": "LOLA",
        "name": "LOLA"
    },
    "LOM": {
        "id": "LOM",
        "name": "LOMBARD"
    },
    "LOMC": {
        "id": "LOMC",
        "name": "LOMAC BOAT TRAILER"
    },
    "LOMH": {
        "id": "LOMH",
        "name": "LIVE OAK MOBILE HOME"
    },
    "LONA": {
        "id": "LONA",
        "name": "LONAIRE MFG. CORP."
    },
    "LONB": {
        "id": "LONB",
        "name": "LONE STAR BOAT MFG."
    },
    "LONC": {
        "id": "LONC",
        "name": "LONE STAR CLASSICS"
    },
    "LOND": {
        "id": "LOND",
        "name": "LONDON MOTORS"
    },
    "LONE": {
        "id": "LONE",
        "name": "CRESTLANE MFG BY LONERGAN CORP."
    },
    "LONF": {
        "id": "LONF",
        "name": "F. A. LONG"
    },
    "LONG": {
        "id": "LONG",
        "name": "LONG MFG. N.C."
    },
    "LONH": {
        "id": "LONH",
        "name": "LONGMARK MOBILE HOMES"
    },
    "LONL": {
        "id": "LONL",
        "name": "LONGLIFE"
    },
    "LONN": {
        "id": "LONN",
        "name": "LONNIE'S TRAILER SALES"
    },
    "LONR": {
        "id": "LONR",
        "name": "LONGRUN"
    },
    "LONT": {
        "id": "LONT",
        "name": "LONG TRAILER"
    },
    "LOOD": {
        "id": "LOOD",
        "name": "LOODCRAFT"
    },
    "LOOK": {
        "id": "LOOK",
        "name": "LGS INDUSTRIES INC"
    },
    "LOPR": {
        "id": "LOPR",
        "name": "LOPROFILE BOAT TRAILER"
    },
    "LORA": {
        "id": "LORA",
        "name": "LORAIN DIV.DIV. OF KOEHRING"
    },
    "LORK": {
        "id": "LORK",
        "name": "LORAK"
    },
    "LOTU": {
        "id": "LOTU",
        "name": "LOTUS"
    },
    "LOUD": {
        "id": "LOUD",
        "name": "LOUDO ENTERPRISES TRAILERS"
    },
    "LOVB": {
        "id": "LOVB",
        "name": "LOVEBUG TRAVEL TRAILER"
    },
    "LOVE": {
        "id": "LOVE",
        "name": "J. E. LOVE"
    },
    "LOWB": {
        "id": "LOWB",
        "name": "LOW BOY TRAILER"
    },
    "LOWE": {
        "id": "LOWE",
        "name": "LOWE INDUSTRIES LEBANAN"
    },
    "LOWP": {
        "id": "LOWP",
        "name": "LOW PRO CUSTOM TRAILER"
    },
    "LOXE": {
        "id": "LOXE",
        "name": "LOX EQUIPMENT COMPANY LIVERMORE"
    },
    "LRCI": {
        "id": "LRCI",
        "name": "LONESOME ROAD CHOPPERS INC MORRISTOWN NJ"
    },
    "LRCT": {
        "id": "LRCT",
        "name": "LARSON CABLE TRAILERS"
    },
    "LRDT": {
        "id": "LRDT",
        "name": "LORRAINE DIETRICH"
    },
    "LRGT": {
        "id": "LRGT",
        "name": "LRG TECHNOLOGIES"
    },
    "LRKM": {
        "id": "LRKM",
        "name": "LARK UNITED MANUFACTURING OF TEXAS"
    },
    "LRNG": {
        "id": "LRNG",
        "name": "LORANGER ENTERPRISES"
    },
    "LRSN": {
        "id": "LRSN",
        "name": "LARSON INTERNATIONAL"
    },
    "LSCT": {
        "id": "LSCT",
        "name": "LONE STAR CUSTOM TRIKES AMARILLO"
    },
    "LSEA": {
        "id": "LSEA",
        "name": "LAND AND SEA TRAILER SHOPPE CORAM NEW YORK"
    },
    "LSKP": {
        "id": "LSKP",
        "name": "LORI ENGINEERING CORP."
    },
    "LSLN": {
        "id": "LSLN",
        "name": "L & S LINE MFG. BRISTOL"
    },
    "LSTS": {
        "id": "LSTS",
        "name": "L AND S TRAILERS & SUPPLY LLC HAWTHORNE"
    },
    "LSUR": {
        "id": "LSUR",
        "name": "LEISURE TRAVEL VANS"
    },
    "LTCH": {
        "id": "LTCH",
        "name": "LEITCHFIELD TRUCK EQUIPMENT"
    },
    "LTCO": {
        "id": "LTCO",
        "name": "LAT"
    },
    "LTEC": {
        "id": "LTEC",
        "name": "LOAD TECHNOLOGY INC"
    },
    "LTED": {
        "id": "LTED",
        "name": "LIMITED EDITION"
    },
    "LTHD": {
        "id": "LTHD",
        "name": "LEVEL THREE HOMES AND DESIGN LLC GRESHAM OR"
    },
    "LTLR": {
        "id": "LTLR",
        "name": "LANE TRAILER MANUFACTURING COMPANY BOONE"
    },
    "LTTX": {
        "id": "LTTX",
        "name": "LITE TRAX"
    },
    "LTVA": {
        "id": "LTVA",
        "name": "LTV AEROSPACE"
    },
    "LUBB": {
        "id": "LUBB",
        "name": "LUBBOCK"
    },
    "LUBE": {
        "id": "LUBE",
        "name": "LUBE MOTORCYCLES SPAIN MOTORCYCLES"
    },
    "LUCA": {
        "id": "LUCA",
        "name": "LUCAS TRAILERS"
    },
    "LUCK": {
        "id": "LUCK",
        "name": "JAMES L LUCKY ENTERPRISES MISSION HILLS"
    },
    "LUCR": {
        "id": "LUCR",
        "name": "LUCRA CARS"
    },
    "LUDW": {
        "id": "LUDW",
        "name": "LUDWIGSEN MOTORCYCLES HOPE MILLS NC"
    },
    "LUED": {
        "id": "LUED",
        "name": "LUEDTKE MFG."
    },
    "LUFK": {
        "id": "LUFK",
        "name": "LUFKIN TRAILERSDIV. OF LUFKIN INDUSTRIES--LUFKIN"
    },
    "LUGR": {
        "id": "LUGR",
        "name": "LUGER INDUSTRIES"
    },
    "LUKY": {
        "id": "LUKY",
        "name": "LUCKY"
    },
    "LULL": {
        "id": "LULL",
        "name": "LULL ENGINEERING"
    },
    "LUMI": {
        "id": "LUMI",
        "name": "LUTTIG MANUFACTURING INC EMMETT KS"
    },
    "LUND": {
        "id": "LUND",
        "name": "LUNDELL MFG."
    },
    "LUNG": {
        "id": "LUNG",
        "name": "LUNDGREEN FLATBED"
    },
    "LUVI": {
        "id": "LUVI",
        "name": "LUV-IT MFG."
    },
    "LUXO": {
        "id": "LUXO",
        "name": "LUXOR-LEFFINGWELL COACH"
    },
    "LUXU": {
        "id": "LUXU",
        "name": "LUXURY HOMES"
    },
    "LUYU": {
        "id": "LUYU",
        "name": "LUYUAN INDUSTRIAL & TRADING"
    },
    "LVAC": {
        "id": "LVAC",
        "name": "A.C.E MODEL MFG BY LIVIN' LITE"
    },
    "LVAX": {
        "id": "LVAX",
        "name": "AXXESS MODEL MFG BY LIVIN' LITE"
    },
    "LVBC": {
        "id": "LVBC",
        "name": "BEARCAT MODEL MFG BY LIVIN' LITE"
    },
    "LVCA": {
        "id": "LVCA",
        "name": "CAMPLITE MFG BY LIVIN' LITE CORP"
    },
    "LVCH": {
        "id": "LVCH",
        "name": "CHALLENGER MODEL MFG BY LIVIN' LITE"
    },
    "LVFD": {
        "id": "LVFD",
        "name": "FORD BRAND"
    },
    "LVFE": {
        "id": "LVFE",
        "name": "FREEDON ELITE MODEL"
    },
    "LVIN": {
        "id": "LVIN",
        "name": "LIVIN' LITE CORP OR LIVIN' LITE RV"
    },
    "LVJP": {
        "id": "LVJP",
        "name": "JEEP MODEL MFG BY LIVIN' LITE"
    },
    "LVPO": {
        "id": "LVPO",
        "name": "POLARIS MODEL"
    },
    "LVQI": {
        "id": "LVQI",
        "name": "QUICKSILVER MFG BY LIVIN' LITE CORP"
    },
    "LVSI": {
        "id": "LVSI",
        "name": "SIESTA MODEL"
    },
    "LVTG": {
        "id": "LVTG",
        "name": "LVTONG GOLF & SIGHTSEEING CAR"
    },
    "LVVG": {
        "id": "LVVG",
        "name": "VEGAS MODEL BRAND MFG BY LIVIN' LITE"
    },
    "LVVR": {
        "id": "LVVR",
        "name": "VRV MFG BY LIVIN' LITE CORP"
    },
    "LVWS": {
        "id": "LVWS",
        "name": "WINDSPORT CLASS A MODEL MFG BY LIVIN' LITE"
    },
    "LWLF": {
        "id": "LWLF",
        "name": "LONE WOLF TRAILER"
    },
    "LWMR": {
        "id": "LWMR",
        "name": "LAW MAR TRAILERS"
    },
    "LYAS": {
        "id": "LYAS",
        "name": "ALUMA SKY MODEL MFG BY LAYTON HOMES CORP"
    },
    "LYBM": {
        "id": "LYBM",
        "name": "LEYBA MANUFACTURING ANTLERS OK"
    },
    "LYDR": {
        "id": "LYDR",
        "name": "DART MFG BY LAYTON HOMES CORP."
    },
    "LYJV": {
        "id": "LYJV",
        "name": "JAVELIN MFG BY LAYTON HOMES CORP."
    },
    "LYLX": {
        "id": "LYLX",
        "name": "LEXION FW & LEXION TT MFG BY JAYCO"
    },
    "LYMA": {
        "id": "LYMA",
        "name": "LYMAN METAL PRODUCTS CORP."
    },
    "LYNC": {
        "id": "LYNC",
        "name": "LYNCOACH & TRUCK"
    },
    "LYNH": {
        "id": "LYNH",
        "name": "FLOTRAIL MFG BY LYNCH MANUFACTURING"
    },
    "LYNN": {
        "id": "LYNN",
        "name": "LYNNTON MFG."
    },
    "LYNR": {
        "id": "LYNR",
        "name": "LYNN-TOW TRUCK"
    },
    "LYNW": {
        "id": "LYNW",
        "name": "LYNWOOD DUMP TRAILERS"
    },
    "LYNX": {
        "id": "LYNX",
        "name": "LYNX BRAND MFG BY BRP"
    },
    "LYTD": {
        "id": "LYTD",
        "name": "TRIDENT MFG BY LAYTON HOMES CORP."
    },
    "LZMW": {
        "id": "LZMW",
        "name": "LANTZ'S MOBILE WELDING LLC DEFUNIAK SPRINGS FL"
    },
    "MACA": {
        "id": "MACA",
        "name": "MACDONALD CAMPER KIT"
    },
    "MACC": {
        "id": "MACC",
        "name": "MCDONALD CAMPER KIT DISTRIBUTOR"
    },
    "MACD": {
        "id": "MACD",
        "name": "MACDONALD'S MOBILE HOMES"
    },
    "MACG": {
        "id": "MACG",
        "name": "MACGREGOR YACHT CORP.COSTA MESA"
    },
    "MACH": {
        "id": "MACH",
        "name": "MACH 1 TRAILERS CAVE CREEK"
    },
    "MACI": {
        "id": "MACI",
        "name": "MANITOWOC CRANES INC MANITOWOC WI"
    },
    "MACK": {
        "id": "MACK",
        "name": "MACK TRUCKS"
    },
    "MACL": {
        "id": "MACL",
        "name": "MAC-LANDER"
    },
    "MACM": {
        "id": "MACM",
        "name": "MAC MANUFACTURING"
    },
    "MACO": {
        "id": "MACO",
        "name": "MACOMA ENGINEERING"
    },
    "MACR": {
        "id": "MACR",
        "name": "MASTER CRAFT INDUSTRIAL EQUIP. CORP."
    },
    "MACS": {
        "id": "MACS",
        "name": "MACS METAL MATERIALS PORT CHARLOTTE"
    },
    "MADD": {
        "id": "MADD",
        "name": "MADDEN TRAILER ASHDOWN ARKANSAS"
    },
    "MADF": {
        "id": "MADF",
        "name": "MAD FABRICATION"
    },
    "MADM": {
        "id": "MADM",
        "name": "MADAMI MOTOR SCOOTERS"
    },
    "MADP": {
        "id": "MADP",
        "name": "MAD PRODUCTS GAINESVILLE TX"
    },
    "MADR": {
        "id": "MADR",
        "name": "DEL RIOMFD. BY MADRID HOMES"
    },
    "MAEN": {
        "id": "MAEN",
        "name": "MAYS ENTERPRISES"
    },
    "MAGA": {
        "id": "MAGA",
        "name": "MAGNA AMERICAN CORP."
    },
    "MAGC": {
        "id": "MAGC",
        "name": "MAGIC TILT TRAILER MFG."
    },
    "MAGI": {
        "id": "MAGI",
        "name": "MAGIC TOUCH"
    },
    "MAGL": {
        "id": "MAGL",
        "name": "MAGLINE"
    },
    "MAGM": {
        "id": "MAGM",
        "name": "MAGNUM PRODUCTS"
    },
    "MAGN": {
        "id": "MAGN",
        "name": "MAGNOLIA MOBILE HOMES MFG."
    },
    "MAGO": {
        "id": "MAGO",
        "name": "MAGNOLIA TRAVEL TRAILER"
    },
    "MAGS": {
        "id": "MAGS",
        "name": "MAGSTER SCOOTERS BAHAMA MODEL"
    },
    "MAGT": {
        "id": "MAGT",
        "name": "MAGNUM CUSTOM TRAILER MFG."
    },
    "MAGY": {
        "id": "MAGY",
        "name": "MAGNA STEYR AUSTRIA"
    },
    "MAHA": {
        "id": "MAHA",
        "name": "MARMON HARRINGTON"
    },
    "MAHI": {
        "id": "MAHI",
        "name": "MAHINDRA TOMBALL"
    },
    "MAHO": {
        "id": "MAHO",
        "name": "MAHONING HOMES"
    },
    "MAI": {
        "id": "MAI",
        "name": "MAI MFG."
    },
    "MAIC": {
        "id": "MAIC",
        "name": "MAI"
    },
    "MAIN": {
        "id": "MAIN",
        "name": "MARK LINE INDUSTRIES"
    },
    "MAIR": {
        "id": "MAIR",
        "name": "MAIR & SON."
    },
    "MAJE": {
        "id": "MAJE",
        "name": "MAJESTIC CORP."
    },
    "MAJO": {
        "id": "MAJO",
        "name": "MAJOR WAY"
    },
    "MAJR": {
        "id": "MAJR",
        "name": "MAJESTIC RIDES MFG."
    },
    "MAKV": {
        "id": "MAKV",
        "name": "MARK V TRAILER"
    },
    "MAL": {
        "id": "MAL",
        "name": "MAL TRACTOR"
    },
    "MALA": {
        "id": "MALA",
        "name": "MALAGUTI"
    },
    "MALB": {
        "id": "MALB",
        "name": "MALIBU BOATS LLC LOUDON TENNESSEE"
    },
    "MALE": {
        "id": "MALE",
        "name": "MARATHON LE TOURNEAU"
    },
    "MALH": {
        "id": "MALH",
        "name": "MALHEUR MOBILE HOMES"
    },
    "MALI": {
        "id": "MALI",
        "name": "MALIBU CAMPERS"
    },
    "MALL": {
        "id": "MALL",
        "name": "MALLARD COACH CORP."
    },
    "MALN": {
        "id": "MALN",
        "name": "MALANCA"
    },
    "MALO": {
        "id": "MALO",
        "name": "MALRO USA DISTRIBUTORS HERBER CA RECREATIONAL TRAILER"
    },
    "MALR": {
        "id": "MALR",
        "name": "MALLARD"
    },
    "MALS": {
        "id": "MALS",
        "name": "MARLISS INDUSTRIES"
    },
    "MALY": {
        "id": "MALY",
        "name": "MALYETTE"
    },
    "MAMD": {
        "id": "MAMD",
        "name": "MID AMERICAN MARINE DIV SYRACUSE IN"
    },
    "MAMH": {
        "id": "MAMH",
        "name": "MASTERPIECE MANUFACTURED HOME"
    },
    "MAMM": {
        "id": "MAMM",
        "name": "MAMMOTH TRAILERS"
    },
    "MANA": {
        "id": "MANA",
        "name": "MANAC"
    },
    "MANC": {
        "id": "MANC",
        "name": "MAN"
    },
    "MANE": {
        "id": "MANE",
        "name": "MANET"
    },
    "MANG": {
        "id": "MANG",
        "name": "MANGAR"
    },
    "MANI": {
        "id": "MANI",
        "name": "MANITOWC ENGINEERING"
    },
    "MANK": {
        "id": "MANK",
        "name": "MANKATO MOBILE HOMES"
    },
    "MANM": {
        "id": "MANM",
        "name": "MANN MADE PRODUCTS INC. TRAILMANN TRAILERS"
    },
    "MANN": {
        "id": "MANN",
        "name": "MANNING"
    },
    "MANO": {
        "id": "MANO",
        "name": "MANOR HOMES"
    },
    "MANR": {
        "id": "MANR",
        "name": "MANNING MARINE"
    },
    "MANS": {
        "id": "MANS",
        "name": "MANSE MANUFACTURING CORP LAS VEGAS"
    },
    "MANT": {
        "id": "MANT",
        "name": "MANATEE HOMES COMMERCE"
    },
    "MAOA": {
        "id": "MAOA",
        "name": "MAN OAF GERMANY"
    },
    "MAPC": {
        "id": "MAPC",
        "name": "POTAIN CRANE MFG BY MANITOWOC CRANES INC MANITOWOC WI"
    },
    "MAPL": {
        "id": "MAPL",
        "name": "MAPLE LEAF"
    },
    "MAPO": {
        "id": "MAPO",
        "name": "MARION POWER SHOVEL"
    },
    "MAQI": {
        "id": "MAQI",
        "name": "MARQUE"
    },
    "MARA": {
        "id": "MARA",
        "name": "MARK TWAIN MFG."
    },
    "MARB": {
        "id": "MARB",
        "name": "MARBROUGH BOAT TRAILER"
    },
    "MARC": {
        "id": "MARC",
        "name": "MARCOS"
    },
    "MARF": {
        "id": "MARF",
        "name": "LONG HAUL"
    },
    "MARG": {
        "id": "MARG",
        "name": "MARINE GROUP LLC MURFREESBORO"
    },
    "MARH": {
        "id": "MARH",
        "name": "ALEXANDRIA MOBILE HOMEMFD. BY MARIETTA HOMES"
    },
    "MARI": {
        "id": "MARI",
        "name": "MARINE CAMPER TRAILER"
    },
    "MARK": {
        "id": "MARK",
        "name": "MARKING MFG."
    },
    "MARL": {
        "id": "MARL",
        "name": "MARSHALL"
    },
    "MARM": {
        "id": "MARM",
        "name": "MARMON"
    },
    "MARN": {
        "id": "MARN",
        "name": "MARION METAL PRODUCTS"
    },
    "MARO": {
        "id": "MARO",
        "name": "MARLETTE HOMES"
    },
    "MARP": {
        "id": "MARP",
        "name": "MARATHON HOMES CORP.ELKHART"
    },
    "MARQ": {
        "id": "MARQ",
        "name": "MARQUETTE CUSTOM BOAT TRAILER"
    },
    "MARR": {
        "id": "MARR",
        "name": "MARLIN MFG."
    },
    "MARS": {
        "id": "MARS",
        "name": "MARS CAMPER"
    },
    "MART": {
        "id": "MART",
        "name": "MARTIN TRAILERS"
    },
    "MARU": {
        "id": "MARU",
        "name": "MARUSHO"
    },
    "MARV": {
        "id": "MARV",
        "name": "MAR-VAL INDUSTRIES"
    },
    "MARW": {
        "id": "MARW",
        "name": "MARSHFIELD HOMES"
    },
    "MASC": {
        "id": "MASC",
        "name": "MASCOT HOMES"
    },
    "MASE": {
        "id": "MASE",
        "name": "MASERATI"
    },
    "MASG": {
        "id": "MASG",
        "name": "MASTER LINER MFG."
    },
    "MASL": {
        "id": "MASL",
        "name": "MASTERBILT TRAILERS"
    },
    "MASN": {
        "id": "MASN",
        "name": "MASSON'S WELDING"
    },
    "MASP": {
        "id": "MASP",
        "name": "MAS RACING PRODUCTS"
    },
    "MASR": {
        "id": "MASR",
        "name": "MASTERCRAFT BRYAN METAL PRODUCTS"
    },
    "MASS": {
        "id": "MASS",
        "name": "MASSEY-FERGUSON"
    },
    "MAST": {
        "id": "MAST",
        "name": "MASTERLINE"
    },
    "MASV": {
        "id": "MASV",
        "name": "MASTER VIEW"
    },
    "MATA": {
        "id": "MATA",
        "name": "MATRA"
    },
    "MATE": {
        "id": "MATE",
        "name": "MATE"
    },
    "MATL": {
        "id": "MATL",
        "name": "MATLOCK TRAILER CORP.NASHVILLE"
    },
    "MATM": {
        "id": "MATM",
        "name": "MID-ATLANTIC TRAILER MANUFACTURING"
    },
    "MATR": {
        "id": "MATR",
        "name": "MATRETTE"
    },
    "MATT": {
        "id": "MATT",
        "name": "MATTMAN GLOBAL SPECIALTY VEHICLES TEMECULA"
    },
    "MAUL": {
        "id": "MAUL",
        "name": "MAULDIN MFG."
    },
    "MAUM": {
        "id": "MAUM",
        "name": "MAURER MANUFACTURING IOWA"
    },
    "MAUR": {
        "id": "MAUR",
        "name": "MAURELL TRAILER"
    },
    "MAV": {
        "id": "MAV",
        "name": "MAVERICK"
    },
    "MAVE": {
        "id": "MAVE",
        "name": "MAVERICK MOBILE HOME"
    },
    "MAVI": {
        "id": "MAVI",
        "name": "MAGINNISS VIBRATOR EQUIP"
    },
    "MAVL": {
        "id": "MAVL",
        "name": "MARVELL MOBILE HOME"
    },
    "MAVN": {
        "id": "MAVN",
        "name": "MARVIN LANDPLANE"
    },
    "MAXC": {
        "id": "MAXC",
        "name": "MAXCRAFT TRAILER MFG."
    },
    "MAXE": {
        "id": "MAXE",
        "name": "MAXEY MFG"
    },
    "MAXF": {
        "id": "MAXF",
        "name": "MAXFINE LIMITED CHINA"
    },
    "MAXI": {
        "id": "MAXI",
        "name": "MAXIM IND."
    },
    "MAXL": {
        "id": "MAXL",
        "name": "MAXWELL"
    },
    "MAXM": {
        "id": "MAXM",
        "name": "MUXUM TRAILERS"
    },
    "MAXO": {
        "id": "MAXO",
        "name": "MAXON EAGLEMFD. BY MAXON INDUSTRIES"
    },
    "MAXP": {
        "id": "MAXP",
        "name": "MAXI PRODUCTS CO"
    },
    "MAYA": {
        "id": "MAYA",
        "name": "MAYFAIR MOBILE HOMEDIV. OF DEROSE IND."
    },
    "MAYB": {
        "id": "MAYB",
        "name": "MAYBACH BRAND WITHIN DAIMLER-CHRYSLER MERCEDES-BENZ"
    },
    "MAYC": {
        "id": "MAYC",
        "name": "MAY"
    },
    "MAYF": {
        "id": "MAYF",
        "name": "MAYFLOWER TRAILERS OR HOMES"
    },
    "MAYH": {
        "id": "MAYH",
        "name": "MAYHEM MANUFACTURING CHAMBERSBURG"
    },
    "MAYN": {
        "id": "MAYN",
        "name": "MAYNARD FABRICATING AND REPAIR LLC ELKHART"
    },
    "MAYR": {
        "id": "MAYR",
        "name": "MAYRATH INDUSTRIES"
    },
    "MAYS": {
        "id": "MAYS",
        "name": "MAYS INDUSTRIES"
    },
    "MAYT": {
        "id": "MAYT",
        "name": "MAY TRAILER MANUFACTURING"
    },
    "MAZD": {
        "id": "MAZD",
        "name": "MAZDA"
    },
    "MBBC": {
        "id": "MBBC",
        "name": "MB BOWEN COMPANY LLC NEWPORT AR"
    },
    "MBCC": {
        "id": "MBCC",
        "name": "MCBURNIE COACH CRAFT"
    },
    "MBCG": {
        "id": "MBCG",
        "name": "M-B CO"
    },
    "MBCO": {
        "id": "MBCO",
        "name": "M-B"
    },
    "MBCP": {
        "id": "MBCP",
        "name": "MOBILE CONCEPTS BY SCOTTY MT. PLEASANT"
    },
    "MBEE": {
        "id": "MBEE",
        "name": "MOTOBEE"
    },
    "MBHE": {
        "id": "MBHE",
        "name": "MINUTEMAN BOAT HANDLING EQUIPMENT"
    },
    "MBHM": {
        "id": "MBHM",
        "name": "UNPUBLISHED MOBILE HOME MFR"
    },
    "MBIE": {
        "id": "MBIE",
        "name": "MOBILE INTERNATIONAL TULSA OKLAHOMA"
    },
    "MBIL": {
        "id": "MBIL",
        "name": "MOBILE TRAVELER"
    },
    "MBKI": {
        "id": "MBKI",
        "name": "MBK INDUSTRIE FRANCE - MOTORCYCLES"
    },
    "MBM": {
        "id": "MBM",
        "name": "M.B.M."
    },
    "MBSI": {
        "id": "MBSI",
        "name": "MOBILE SPECIALTIES"
    },
    "MBTC": {
        "id": "MBTC",
        "name": "MOBILITY TECHNOLOGIES"
    },
    "MBTL": {
        "id": "MBTL",
        "name": "MAX BUILT TRAILERS LLC CHATSWORTH GA"
    },
    "MBTR": {
        "id": "MBTR",
        "name": "MOBILE TRAVELER"
    },
    "MBTS": {
        "id": "MBTS",
        "name": "MOBILITY TRANSPORTATION SERVICES CANTON MI"
    },
    "MBTT": {
        "id": "MBTT",
        "name": "MOBILE TECH TRAILERS"
    },
    "MBVO": {
        "id": "MBVO",
        "name": "MOTO BRAVO"
    },
    "MBWI": {
        "id": "MBWI",
        "name": "M-B-W"
    },
    "MCAF": {
        "id": "MCAF",
        "name": "M. P. MCCAFFREY"
    },
    "MCAH": {
        "id": "MCAH",
        "name": "MICAH'S CUSTOM WORKS MALAGA"
    },
    "MCBM": {
        "id": "MCBM",
        "name": "MCCLOSKEY BROTHERS MANUFACTURING CANADA LIT.ONTARIO"
    },
    "MCCA": {
        "id": "MCCA",
        "name": "MCCAIN INDUSTRIES"
    },
    "MCCB": {
        "id": "MCCB",
        "name": "MCCABE-POWERS BODY"
    },
    "MCCK": {
        "id": "MCCK",
        "name": "MCCOOK MFG."
    },
    "MCCL": {
        "id": "MCCL",
        "name": "MCCLAIN TRAILER MFG."
    },
    "MCCM": {
        "id": "MCCM",
        "name": "MCCRABB MFG."
    },
    "MCCO": {
        "id": "MCCO",
        "name": "MCCOY MFG. & SALES"
    },
    "MCCR": {
        "id": "MCCR",
        "name": "MCCARTHY TRAILERS"
    },
    "MCCU": {
        "id": "MCCU",
        "name": "MCCULLOCH MITE-E-LITE"
    },
    "MCCW": {
        "id": "MCCW",
        "name": "MACOMB COUNTY CYCLES WORKS WASHINGTON"
    },
    "MCCY": {
        "id": "MCCY",
        "name": "MCCLENNY MACHINE"
    },
    "MCEL": {
        "id": "MCEL",
        "name": "MCELRATH TRAILERS SOUTH CAROLINA"
    },
    "MCER": {
        "id": "MCER",
        "name": "MERCER"
    },
    "MCFA": {
        "id": "MCFA",
        "name": "MCFARLANE MFG."
    },
    "MCFL": {
        "id": "MCFL",
        "name": "MCFARLAN CONNERSVILLE IN"
    },
    "MCGE": {
        "id": "MCGE",
        "name": "MCGEE TRAILER SALES"
    },
    "MCGU": {
        "id": "MCGU",
        "name": "MC GUBER MEXICO"
    },
    "MCHD": {
        "id": "MCHD",
        "name": "MACHADO MANUFACTURING TULARE CALIFORNIA"
    },
    "MCHL": {
        "id": "MCHL",
        "name": "MITCHELL AND SONS COLORADO"
    },
    "MCHM": {
        "id": "MCHM",
        "name": "MITCHAM TRIKES TOWNVILLE"
    },
    "MCHP": {
        "id": "MCHP",
        "name": "MAGNUM CHOPPERS"
    },
    "MCIN": {
        "id": "MCIN",
        "name": "MCISEE MOTOR COACH INDUSTRIES"
    },
    "MCIT": {
        "id": "MCIT",
        "name": "MCINTYRE"
    },
    "MCKA": {
        "id": "MCKA",
        "name": "JOHN A. MCKAY"
    },
    "MCKE": {
        "id": "MCKE",
        "name": "MACKEY TRAILER TEMPE"
    },
    "MCKT": {
        "id": "MCKT",
        "name": "MACK TRAILER MFG."
    },
    "MCKY": {
        "id": "MCKY",
        "name": "MCKAY MFG."
    },
    "MCLA": {
        "id": "MCLA",
        "name": "MCLAREN AUTOMOTIVE"
    },
    "MCLI": {
        "id": "MCLI",
        "name": "MAC LTT INC KENT OH"
    },
    "MCLN": {
        "id": "MCLN",
        "name": "MCLENDON TRAILERS OR MCLENDON TRAILERS LLC ALABAMA"
    },
    "MCMH": {
        "id": "MCMH",
        "name": "MONTCLAIR MOBILE HOME"
    },
    "MCNA": {
        "id": "MCNA",
        "name": "MCNAMEE COACH CORP."
    },
    "MCNL": {
        "id": "MCNL",
        "name": "MCNEILUS TRUCK AND MANUFACTURING DODGE CENTER"
    },
    "MCNM": {
        "id": "MCNM",
        "name": "MCN MOBILE HOMES CORP."
    },
    "MCOA": {
        "id": "MCOA",
        "name": "MAC CORPORATION OF AMERICA"
    },
    "MCOL": {
        "id": "MCOL",
        "name": "MCCOLLOUGH CORPORATION BROOKFIELD MISSOURI"
    },
    "MCOX": {
        "id": "MCOX",
        "name": "MECOX RESOURCES SA DE CV MEXICO"
    },
    "MCOY": {
        "id": "MCOY",
        "name": "MCCOY"
    },
    "MCQU": {
        "id": "MCQU",
        "name": "MCQUERRY TAN TRAILER"
    },
    "MCQV": {
        "id": "MCQV",
        "name": "MCQUERRY HORSE TRAILER"
    },
    "MCRD": {
        "id": "MCRD",
        "name": "MCCORD MANUFACTURING"
    },
    "MCRL": {
        "id": "MCRL",
        "name": "MICRO-LITE TRAILER MANUFACTURING"
    },
    "MCRR": {
        "id": "MCRR",
        "name": "MC KEE ROUGHRIDER"
    },
    "MCRY": {
        "id": "MCRY",
        "name": "MC ELROY COMPANY"
    },
    "MCSI": {
        "id": "MCSI",
        "name": "THE MARINE CRADLE SHOP"
    },
    "MCSK": {
        "id": "MCSK",
        "name": "MCCLOSKEY INTERNATIONAL ONTARIO"
    },
    "MCTF": {
        "id": "MCTF",
        "name": "MASTERCRAFT TOOLS FLORIDA"
    },
    "MCTL": {
        "id": "MCTL",
        "name": "M AND C TRAILER SALES PREV RAY TRAILER"
    },
    "MCTM": {
        "id": "MCTM",
        "name": "MC TRAILER MANUFACTURING WINDOM"
    },
    "MCTY": {
        "id": "MCTY",
        "name": "MERCOTY TRAILER"
    },
    "MCYM": {
        "id": "MCYM",
        "name": "MCCOY MILLER AMBULANCES & LIGHT RESCUE VEHICLES ELKHART"
    },
    "MDAM": {
        "id": "MDAM",
        "name": "MID AMERICA MFG. INC. BERTRAND"
    },
    "MDAT": {
        "id": "MDAT",
        "name": "MID-AMERICA TRAILER MFG"
    },
    "MDCN": {
        "id": "MDCN",
        "name": "UNPUBLISHED MODULAR CONSTRUCTION BUILDING OR HOME MFR SEE OPERATING"
    },
    "MDEP": {
        "id": "MDEP",
        "name": "MD ENTERPRISES CALIFORNIA"
    },
    "MDLR": {
        "id": "MDLR",
        "name": "MODULAR SYSTEMS"
    },
    "MDMF": {
        "id": "MDMF",
        "name": "MIDLAND MANUFACTURING"
    },
    "MDMI": {
        "id": "MDMI",
        "name": "MIDSOTA MANUFACTURING INC AVON MN"
    },
    "MDML": {
        "id": "MDML",
        "name": "MEDIEVAL METALWERX MANKATO MN"
    },
    "MDNA": {
        "id": "MDNA",
        "name": "MODENA"
    },
    "MDOW": {
        "id": "MDOW",
        "name": "MEADOW CREEK WELDING"
    },
    "MDPD": {
        "id": "MDPD",
        "name": "MD PRODUCTS INC MASON CITY IA"
    },
    "MDQT": {
        "id": "MDQT",
        "name": "MDQ TRAILER MANUFACTURING"
    },
    "MDRD": {
        "id": "MDRD",
        "name": "MADRID MANUFACTURING"
    },
    "MDS": {
        "id": "MDS",
        "name": "MDS"
    },
    "MDST": {
        "id": "MDST",
        "name": "MID STATES CAMPING TRAILER"
    },
    "MDTR": {
        "id": "MDTR",
        "name": "M-D TRAILER"
    },
    "MDWI": {
        "id": "MDWI",
        "name": "MDW"
    },
    "MDYN": {
        "id": "MDYN",
        "name": "3 M DYNAMIC MESSAGE SYSTEMS SPOKANE"
    },
    "MEAD": {
        "id": "MEAD",
        "name": "MEADE"
    },
    "MEAL": {
        "id": "MEAL",
        "name": "ALUMASTAR"
    },
    "MEAN": {
        "id": "MEAN",
        "name": "MEAN"
    },
    "MEBT": {
        "id": "MEBT",
        "name": "MEB TRAILER SALES WALNUT RIDGE"
    },
    "MECH": {
        "id": "MECH",
        "name": "MECH HANDLING"
    },
    "MEDA": {
        "id": "MEDA",
        "name": "MEDALLION MOBILE HOMES"
    },
    "MEDF": {
        "id": "MEDF",
        "name": "MEDFORD INDUSTRIES"
    },
    "MEDI": {
        "id": "MEDI",
        "name": "MEDICAL COACHES"
    },
    "MEDT": {
        "id": "MEDT",
        "name": "MEDTEC AMBULANCE CORPORATION GOSHEN"
    },
    "MEDX": {
        "id": "MEDX",
        "name": "MEDIX SPECIALITY VEHICLES"
    },
    "MEGA": {
        "id": "MEGA",
        "name": "MEGA TRAILERS BELL"
    },
    "MEGS": {
        "id": "MEGS",
        "name": "MEGASPORT MALONE MEGASPORT A C.E.SMITH COMPANY"
    },
    "MEIC": {
        "id": "MEIC",
        "name": "MEI CYBERCORP TRAILER MOROC"
    },
    "MEID": {
        "id": "MEID",
        "name": "MEIDUO MOTORCYCLE"
    },
    "MEIT": {
        "id": "MEIT",
        "name": "SHANGHAI MEITIAN MOTORCYCLE CO"
    },
    "MELA": {
        "id": "MELA",
        "name": "MELMAR MOTOR HOME"
    },
    "MELC": {
        "id": "MELC",
        "name": "MARATHON ELECTRIC"
    },
    "MELG": {
        "id": "MELG",
        "name": "MELGES BOAT WORKS"
    },
    "MELI": {
        "id": "MELI",
        "name": "MELISSA'S GOLF CARTS INC BROOKSVILLE FL"
    },
    "MELM": {
        "id": "MELM",
        "name": "MELMAK MOTOR HOME"
    },
    "MELO": {
        "id": "MELO",
        "name": "MELODY HOME MFG."
    },
    "MELR": {
        "id": "MELR",
        "name": "BOBCAT SKID STEER LOADER MANUFACTURED BY MELROE DIV NOW BRANDED"
    },
    "MENG": {
        "id": "MENG",
        "name": "MENGDELI ELECTRICAL"
    },
    "MENS": {
        "id": "MENS",
        "name": "MACANISMOS ENSAMBLADOS S. A. DE C. V.MEXICO"
    },
    "MENX": {
        "id": "MENX",
        "name": "NEXT GENERATION BRAND MFG BY MERHOW INDUSTRIES"
    },
    "MERB": {
        "id": "MERB",
        "name": "MERCURY BOAT"
    },
    "MERC": {
        "id": "MERC",
        "name": "MERCURY"
    },
    "MERD": {
        "id": "MERD",
        "name": "MERIDIAN SPECIALTY VEHICLES"
    },
    "MERH": {
        "id": "MERH",
        "name": "MERCURY COACH CORP."
    },
    "MERI": {
        "id": "MERI",
        "name": "MERRITT EQUIPMENT"
    },
    "MERK": {
        "id": "MERK",
        "name": "MERKUR"
    },
    "MERM": {
        "id": "MERM",
        "name": "CHARISMAMFD"
    },
    "MERR": {
        "id": "MERR",
        "name": "MERCURY TRAILER INDUSTRIES"
    },
    "MERT": {
        "id": "MERT",
        "name": "MERIT TANK & BODY BERKELEY"
    },
    "MERW": {
        "id": "MERW",
        "name": "MERHOW INDUSTRIES BRISTOL INDIANA TRAILERS"
    },
    "MERY": {
        "id": "MERY",
        "name": "MERRY MFG. CORP.MARYSVILLE"
    },
    "MERZ": {
        "id": "MERZ",
        "name": "MERCEDES-BENZ"
    },
    "MESA": {
        "id": "MESA",
        "name": "MESA III TRAVEL TRAILER"
    },
    "MESS": {
        "id": "MESS",
        "name": "MESSERSCHMITT"
    },
    "META": {
        "id": "META",
        "name": "METAL CRAFT MANUFACTURING GREENVILLE NORTH CAROLINA"
    },
    "METE": {
        "id": "METE",
        "name": "METEOR"
    },
    "METI": {
        "id": "METI",
        "name": "METALITE INDUSTRIES"
    },
    "METL": {
        "id": "METL",
        "name": "MERCANTILE MFG."
    },
    "METM": {
        "id": "METM",
        "name": "METRO METAL & DESIGN"
    },
    "METO": {
        "id": "METO",
        "name": "METOO TRAVEL TRAILER"
    },
    "METR": {
        "id": "METR",
        "name": "METROPOLITAN"
    },
    "METV": {
        "id": "METV",
        "name": "METAVIC TRAILERS ST. PIERRE-BAPTISTE"
    },
    "METZ": {
        "id": "METZ",
        "name": "METZENDORF TRAILER MFG."
    },
    "MEVH": {
        "id": "MEVH",
        "name": "MOTO ELECTRIC VEHICLES FLORIDA"
    },
    "MEYE": {
        "id": "MEYE",
        "name": "MEYER MORTON"
    },
    "MEYR": {
        "id": "MEYR",
        "name": "MEYER MANUFACTURING CORP."
    },
    "MFAB": {
        "id": "MFAB",
        "name": "METAL FABRICATION"
    },
    "MFAR": {
        "id": "MFAR",
        "name": "MCFARLAN MOTOR CORP INDIANA"
    },
    "MFBS": {
        "id": "MFBS",
        "name": "MAINTENANCE & FABRICATION SERVICES SAN PABLO"
    },
    "MFGI": {
        "id": "MFGI",
        "name": "1954 MANUFACTURING"
    },
    "MFGP": {
        "id": "MFGP",
        "name": "MARINE FIBERGLASS PRODUCTS"
    },
    "MFHM": {
        "id": "MFHM",
        "name": "UNPUBLISHED MANUFACTURED HOME MFR"
    },
    "MFTC": {
        "id": "MFTC",
        "name": "MF TRIKE CENTER GERMANY MOTORCYCLES ETC"
    },
    "MG": {
        "id": "MG",
        "name": "MG"
    },
    "MGII": {
        "id": "MGII",
        "name": "MAG INTERNATIONAL FOUNTAIN VALLEY"
    },
    "MGM": {
        "id": "MGM",
        "name": "M.G.M."
    },
    "MGNL": {
        "id": "MGNL",
        "name": "MAGNOLIA TRAILERS"
    },
    "MGNM": {
        "id": "MGNM",
        "name": "MAGNUM MANUFACTURING HARRISBURG"
    },
    "MGNO": {
        "id": "MGNO",
        "name": "MGNOL TANDEM BOAT TRAILER"
    },
    "MGNT": {
        "id": "MGNT",
        "name": "MAGENTA TRAILERS"
    },
    "MGOS": {
        "id": "MGOS",
        "name": "MONGOOSE TRAILERS"
    },
    "MGRL": {
        "id": "MGRL",
        "name": "MOBILE GRILLS"
    },
    "MGS": {
        "id": "MGS",
        "name": "MGS"
    },
    "MHEB": {
        "id": "MHEB",
        "name": "EBY"
    },
    "MHMC": {
        "id": "MHMC",
        "name": "BLAIR HOUSEMFD. BY MANUFACTURED HOUSING MANAGEMENTCORP."
    },
    "MHTI": {
        "id": "MHTI",
        "name": "M & H TRAILERS"
    },
    "MHWK": {
        "id": "MHWK",
        "name": "MOHAWK VALLEY WELDING AND STAINLESS STEEL UTICA"
    },
    "MIAM": {
        "id": "MIAM",
        "name": "MIAMI CHOPPERS"
    },
    "MICA": {
        "id": "MICA",
        "name": "MICHALKE MFG. CORP."
    },
    "MICB": {
        "id": "MICB",
        "name": "MICRO BIRD"
    },
    "MICC": {
        "id": "MICC",
        "name": "MICRO CONCEPT CARS"
    },
    "MICF": {
        "id": "MICF",
        "name": "MIC FABRICATING CORPORATION"
    },
    "MICG": {
        "id": "MICG",
        "name": "MICHIGAN CENTRAL AIRLINES"
    },
    "MICK": {
        "id": "MICK",
        "name": "MICKEY TRUCK BODIES"
    },
    "MICL": {
        "id": "MICL",
        "name": "MICHAEL MANUFACTURING"
    },
    "MIDA": {
        "id": "MIDA",
        "name": "MID-ATLANTIC HOMES"
    },
    "MIDB": {
        "id": "MIDB",
        "name": "MIDDLEBURY TRAILERS INC."
    },
    "MIDC": {
        "id": "MIDC",
        "name": "MID-CAL FORKLIFT"
    },
    "MIDD": {
        "id": "MIDD",
        "name": "MIDDLESEX EQUIPMENT"
    },
    "MIDE": {
        "id": "MIDE",
        "name": "MIDWAY ENGINEERING"
    },
    "MIDI": {
        "id": "MIDI",
        "name": "MIDWEST INDUSTRIES"
    },
    "MIDL": {
        "id": "MIDL",
        "name": "CAPELLAMFD. BY MIDLAND INDUSTRIES"
    },
    "MIDM": {
        "id": "MIDM",
        "name": "MIDMARK"
    },
    "MIDN": {
        "id": "MIDN",
        "name": "MID-NEBRASKA TRUCK AND TRAILER GRAND ISLAND"
    },
    "MIDQ": {
        "id": "MIDQ",
        "name": "MID-EQUIPMENT CORP."
    },
    "MIDS": {
        "id": "MIDS",
        "name": "MIDAS INTERNATIONAL CORP."
    },
    "MIDT": {
        "id": "MIDT",
        "name": "MIDWEST MINI-TOTE"
    },
    "MIDW": {
        "id": "MIDW",
        "name": "MIDWEST MOBILE HOMES & TRAILER MFG."
    },
    "MIEV": {
        "id": "MIEV",
        "name": "MILES ELECTRIC VEHICLES CALIFORNIA"
    },
    "MIFU": {
        "id": "MIFU",
        "name": "MITSUBISHI FUSO TRUCK OF AMERICA"
    },
    "MIKA": {
        "id": "MIKA",
        "name": "MIKASA"
    },
    "MIKD": {
        "id": "MIKD",
        "name": "MIKADO-TRIKE GBR GERMANY MOTORCYCLES"
    },
    "MIKE": {
        "id": "MIKE",
        "name": "MIKE'S WELDING & TRAILER MFG."
    },
    "MIKR": {
        "id": "MIKR",
        "name": "MIKRUS"
    },
    "MIL": {
        "id": "MIL",
        "name": "MILLER ELECTRIC MFG."
    },
    "MILA": {
        "id": "MILA",
        "name": "MILLAN FLATDECK"
    },
    "MILB": {
        "id": "MILB",
        "name": "MILBURN WAGON COMPANY"
    },
    "MILC": {
        "id": "MILC",
        "name": "MIL"
    },
    "MILE": {
        "id": "MILE",
        "name": "MILLER & SMITH"
    },
    "MILL": {
        "id": "MILL",
        "name": "MILLER TRAILERS INC.BRADENTON"
    },
    "MILN": {
        "id": "MILN",
        "name": "MILLENNIUM MFG BY UNITED COACHWORKS"
    },
    "MILR": {
        "id": "MILR",
        "name": "MILLER EQUIP. DIV."
    },
    "MILT": {
        "id": "MILT",
        "name": "MILLER TILT-TOP TRAILER INC.MILWAUKEE"
    },
    "MILU": {
        "id": "MILU",
        "name": "MILLER USA"
    },
    "MILW": {
        "id": "MILW",
        "name": "MILWAUKEE ELECTRIC TOOL CORP."
    },
    "MILY": {
        "id": "MILY",
        "name": "EXHIBITOR MFG BY MILEY TRAILER"
    },
    "MIMO": {
        "id": "MIMO",
        "name": "MINNEAPOLIS-MOLINE"
    },
    "MIND": {
        "id": "MIND",
        "name": "MILLER INDUSTRIES"
    },
    "MINI": {
        "id": "MINI",
        "name": "MINISCOOTER"
    },
    "MINN": {
        "id": "MINN",
        "name": "MINELLI"
    },
    "MINV": {
        "id": "MINV",
        "name": "MINNESOTA VALLEY ENGINEERING NEW PRAGUE"
    },
    "MIRA": {
        "id": "MIRA",
        "name": "MIRAGE MOTORHOMES"
    },
    "MIRE": {
        "id": "MIRE",
        "name": "MIRAGE ENTERPRISES"
    },
    "MISI": {
        "id": "MISI",
        "name": "MARSH INDUSTRIAL SERVICES INC KALKASKA MI"
    },
    "MISN": {
        "id": "MISN",
        "name": "MISSION TRAILERS"
    },
    "MIST": {
        "id": "MIST",
        "name": "MISTRAL"
    },
    "MIT": {
        "id": "MIT",
        "name": "MITCHELL CAMPER TRAILER"
    },
    "MITC": {
        "id": "MITC",
        "name": "MITCHELL INDUSTRIAL TIRE"
    },
    "MITS": {
        "id": "MITS",
        "name": "MITSUBISHI"
    },
    "MITT": {
        "id": "MITT",
        "name": "MITTS-MERRILL"
    },
    "MIVL": {
        "id": "MIVL",
        "name": "METALMECCANICA ITALIANA VALTROMPIO SPA"
    },
    "MJCK": {
        "id": "MJCK",
        "name": "MI-JACK PRODUCTS HAZEL CREST"
    },
    "MJME": {
        "id": "MJME",
        "name": "MJM ENTERPRISES"
    },
    "MJMT": {
        "id": "MJMT",
        "name": "M.J.M. TRAILER MFG."
    },
    "MKEE": {
        "id": "MKEE",
        "name": "MCKEE FARM TECHNOLOGIES"
    },
    "MKMH": {
        "id": "MKMH",
        "name": "MK 5-14OO MOTOR HOME"
    },
    "MKTS": {
        "id": "MKTS",
        "name": "MK TRAILER SALES"
    },
    "MLBL": {
        "id": "MLBL",
        "name": "MLBLT"
    },
    "MLGH": {
        "id": "MLGH",
        "name": "MOBILIGHT"
    },
    "MLHI": {
        "id": "MLHI",
        "name": "MAPLE LEAF HOMES"
    },
    "MLHN": {
        "id": "MLHN",
        "name": "MCLANAHAN CORPORATION HOLLIDAYSBURG PA"
    },
    "MLLB": {
        "id": "MLLB",
        "name": "MILLER BUILT TRAILERS TIFTON"
    },
    "MLLM": {
        "id": "MLLM",
        "name": "MILLENIUM TRAILERS"
    },
    "MLLR": {
        "id": "MLLR",
        "name": "MILLER"
    },
    "MLLT": {
        "id": "MLLT",
        "name": "MILLERTIME MFG"
    },
    "MLMC": {
        "id": "MLMC",
        "name": "MITCHELL LEWIS MOTOR COMPANY RACINE WI"
    },
    "MLMI": {
        "id": "MLMI",
        "name": "MAC LTT MANUFACTURING INC BILLINGS MT"
    },
    "MLNG": {
        "id": "MLNG",
        "name": "MOLING CORPORATION MAKER OF CAR KING DOLLY TRAILERS"
    },
    "MLQP": {
        "id": "MLQP",
        "name": "MULTIQUIP INC LEWISVILLE TX"
    },
    "MLRO": {
        "id": "MLRO",
        "name": "MELROE TRACTOR TRUCK"
    },
    "MLRY": {
        "id": "MLRY",
        "name": "THE MALLORY COMPANY KELSO"
    },
    "MLTF": {
        "id": "MLTF",
        "name": "MULETUF TRAILER MFG SIKESTON"
    },
    "MLTI": {
        "id": "MLTI",
        "name": "MULTITION HYDRAULIC TRUCK"
    },
    "MLTK": {
        "id": "MLTK",
        "name": "MULTITEK"
    },
    "MLTT": {
        "id": "MLTT",
        "name": "MULTI-TECH"
    },
    "MLXC": {
        "id": "MLXC",
        "name": "MOBILUX"
    },
    "MMCI": {
        "id": "MMCI",
        "name": "MERIDIAN MOTOR COMPANY"
    },
    "MMCL": {
        "id": "MMCL",
        "name": "MINI-MARCELLINO"
    },
    "MMCO": {
        "id": "MMCO",
        "name": "MILWAUKEE MOTORCYCLE COMPANY EAU CLAIRE"
    },
    "MMDI": {
        "id": "MMDI",
        "name": "METRO METAL AND DESIGN INC MATTHEWS SC"
    },
    "MMFG": {
        "id": "MMFG",
        "name": "M MANUFACTURING INC YOUNGSTOWN"
    },
    "MMID": {
        "id": "MMID",
        "name": "MAXXIM INDUSTRIES SA DE CV CLUDAD CUAUHTEMOC CHIHUA MEXICO"
    },
    "MMLJ": {
        "id": "MMLJ",
        "name": "MMLJ"
    },
    "MMMC": {
        "id": "MMMC",
        "name": "M & M MFG."
    },
    "MMOT": {
        "id": "MMOT",
        "name": "MONSTER MOTO RUSTON LA"
    },
    "MMTC": {
        "id": "MMTC",
        "name": "MORRIS MULE TRAILER COMPANY ANNISTON"
    },
    "MMTL": {
        "id": "MMTL",
        "name": "MARINE MASTER TRAILERS TENNESSEE"
    },
    "MMTR": {
        "id": "MMTR",
        "name": "MR MIKES TRAILERS AMARILLO TX"
    },
    "MMUL": {
        "id": "MMUL",
        "name": "M8 A1 MULE OFF ROAD TRAILER PHOENIX AZ"
    },
    "MNAI": {
        "id": "MNAI",
        "name": "MOUNTAIN AIRE MFG BY NEWMAR CORP"
    },
    "MNAR": {
        "id": "MNAR",
        "name": "MONARCH"
    },
    "MNDI": {
        "id": "MNDI",
        "name": "MOND INDUSTRIES"
    },
    "MNIT": {
        "id": "MNIT",
        "name": "MANITOU OR MANITOU NORTH AMERICA"
    },
    "MNLN": {
        "id": "MNLN",
        "name": "MAINLINE INCORPORATED N. TAZEWELL"
    },
    "MNMK": {
        "id": "MNMK",
        "name": "MCKENZIE"
    },
    "MNMQ": {
        "id": "MNMQ",
        "name": "MARQUIS BRAND MFG BY MONA"
    },
    "MNMW": {
        "id": "MNMW",
        "name": "MANLEY METALWORKS LLC EATONTON GA"
    },
    "MNNI": {
        "id": "MNNI",
        "name": "MINI DIVISION OF BMW MAKER OF COOPER & COOPER-S"
    },
    "MNOR": {
        "id": "MNOR",
        "name": "MANOR MFG BY ATHENS PARK HOMES"
    },
    "MNRC": {
        "id": "MNRC",
        "name": "MONARC"
    },
    "MNRK": {
        "id": "MNRK",
        "name": "MONARK"
    },
    "MNRO": {
        "id": "MNRO",
        "name": "MONROE MOTORS INC."
    },
    "MNSG": {
        "id": "MNSG",
        "name": "SIGNATURE BRAND MFG BY MONA"
    },
    "MNSK": {
        "id": "MNSK",
        "name": "MINSK OR MINSK MOTO RUSSIA MOTORCYCLES"
    },
    "MNSN": {
        "id": "MNSN",
        "name": "MONSOON"
    },
    "MNTA": {
        "id": "MNTA",
        "name": "MANTASEE THREE R INDUSTRIES"
    },
    "MNTC": {
        "id": "MNTC",
        "name": "MOUNTAIN TRAILER COMPANY LLC NEW JERSEY"
    },
    "MNTE": {
        "id": "MNTE",
        "name": "MONTEBELLO MOBILE HOME TRAILER"
    },
    "MNTG": {
        "id": "MNTG",
        "name": "MONTGOMERY"
    },
    "MNTS": {
        "id": "MNTS",
        "name": "MANTIS CHOPPERS"
    },
    "MOAL": {
        "id": "MOAL",
        "name": "MONTE ALUMINUM TRAILER DUMP"
    },
    "MOBB": {
        "id": "MOBB",
        "name": "MOBILAIRE MOBILE HOMES"
    },
    "MOBC": {
        "id": "MOBC",
        "name": "MOBILE CHAPEL TRAILER"
    },
    "MOBE": {
        "id": "MOBE",
        "name": "MOTO BETA"
    },
    "MOBF": {
        "id": "MOBF",
        "name": "MOBILE FREEZE"
    },
    "MOBG": {
        "id": "MOBG",
        "name": "MOBILE ENGINEERING"
    },
    "MOBH": {
        "id": "MOBH",
        "name": "MOBILE FACILITY ENGINEERING"
    },
    "MOBI": {
        "id": "MOBI",
        "name": "MOTOBIC"
    },
    "MOBJ": {
        "id": "MOBJ",
        "name": "MOBILE GARAGE MFG."
    },
    "MOBK": {
        "id": "MOBK",
        "name": "MOBILE HOLDING CORP."
    },
    "MOBL": {
        "id": "MOBL",
        "name": "MOBILE HOME"
    },
    "MOBM": {
        "id": "MOBM",
        "name": "MOTO BM MOTORCYCLES OR BM MOTORCYCLES ITALY"
    },
    "MOBN": {
        "id": "MOBN",
        "name": "MOBILE HOME SERVICE"
    },
    "MOBO": {
        "id": "MOBO",
        "name": "MOBILE MFG. CORP."
    },
    "MOBP": {
        "id": "MOBP",
        "name": "MOBILE OF MARYSVILLE"
    },
    "MOBR": {
        "id": "MOBR",
        "name": "MOBILE OFFICE"
    },
    "MOBS": {
        "id": "MOBS",
        "name": "MOBILE PRODUCTS"
    },
    "MOBT": {
        "id": "MOBT",
        "name": "MOBILE SCOUT MFG. CORP.ARLINGTON"
    },
    "MOBU": {
        "id": "MOBU",
        "name": "CINDERELLA MOBILE HOMES MFG BY MOBILE STRUCTURES"
    },
    "MOBV": {
        "id": "MOBV",
        "name": "MOBILE TOPS"
    },
    "MOBW": {
        "id": "MOBW",
        "name": "MOBILE UNIT MFG."
    },
    "MOBX": {
        "id": "MOBX",
        "name": "MOBILEMANOR"
    },
    "MOBY": {
        "id": "MOBY",
        "name": "MOBY 1 EXPEDITION TRAILERS LLC SPRONGVILLE"
    },
    "MOCA": {
        "id": "MOCA",
        "name": "MONTE CARLO MOBILE HOMES"
    },
    "MOCC": {
        "id": "MOCC",
        "name": "MOBLEY METAL WORKS"
    },
    "MOCR": {
        "id": "MOCR",
        "name": "MOTOCICLETAS CARABELA SA"
    },
    "MOCY": {
        "id": "MOCY",
        "name": "MOD CYCLES CORP MIAMI FL. ITALJET MOTO"
    },
    "MODE": {
        "id": "MODE",
        "name": "MODEL A AND MODEL T MOTOR CARREPRODUCTION CORP."
    },
    "MODH": {
        "id": "MODH",
        "name": "MODERN MOBILE HOMES"
    },
    "MODI": {
        "id": "MODI",
        "name": "MODERNISTIC INDUSTRIES"
    },
    "MODL": {
        "id": "MODL",
        "name": "LAMPLIGHTER MFG BY MODULINE INTERNATIONAL"
    },
    "MODN": {
        "id": "MODN",
        "name": "MODERN"
    },
    "MODR": {
        "id": "MODR",
        "name": "MODERN"
    },
    "MODU": {
        "id": "MODU",
        "name": "MODULE"
    },
    "MOEN": {
        "id": "MOEN",
        "name": "MOEN CUSTOM TRAILERS"
    },
    "MOFF": {
        "id": "MOFF",
        "name": "MOFFETT ENGINEERING"
    },
    "MOFO": {
        "id": "MOFO",
        "name": "MOTO FINO USA SCOOTERS"
    },
    "MOGA": {
        "id": "MOGA",
        "name": "MORGAN EQUIPMENT"
    },
    "MOGU": {
        "id": "MOGU",
        "name": "MOTO GUZZI"
    },
    "MOHA": {
        "id": "MOHA",
        "name": "MOHAWK"
    },
    "MOHO": {
        "id": "MOHO",
        "name": "MOBILE HOME INDUSTRIES"
    },
    "MOJA": {
        "id": "MOJA",
        "name": "MOJAVE"
    },
    "MOKE": {
        "id": "MOKE",
        "name": "MOKE MOKE AMERICA SAWGRASS FLORIDA"
    },
    "MOLL": {
        "id": "MOLL",
        "name": "MOLLOY MOBILE CRAFTS"
    },
    "MOLT": {
        "id": "MOLT",
        "name": "MOLT"
    },
    "MOLY": {
        "id": "MOLY",
        "name": "MOBILITY"
    },
    "MOMH": {
        "id": "MOMH",
        "name": "MONTROSE MANUFACTURED HOME"
    },
    "MOMO": {
        "id": "MOMO",
        "name": "MOTO MORINI"
    },
    "MONA": {
        "id": "MONA",
        "name": "MONARCH"
    },
    "MONB": {
        "id": "MONB",
        "name": "MONARCH BOAT"
    },
    "MONC": {
        "id": "MONC",
        "name": "DAYTONA MOBILE HOMES MFD. BY MONARCH INDUSTRIES"
    },
    "MOND": {
        "id": "MOND",
        "name": "MONDIAL"
    },
    "MONG": {
        "id": "MONG",
        "name": "LAC ST-JEAN MOTONEIGE"
    },
    "MONH": {
        "id": "MONH",
        "name": "MONARCH MOBILE HOMES"
    },
    "MONI": {
        "id": "MONI",
        "name": "MONITOR COACH"
    },
    "MONN": {
        "id": "MONN",
        "name": "MONON TRAILER DIV.DIV. OF EVANS PRODUCTS"
    },
    "MONO": {
        "id": "MONO",
        "name": "MONO"
    },
    "MONR": {
        "id": "MONR",
        "name": "MONROE TRACTOR"
    },
    "MONS": {
        "id": "MONS",
        "name": "MONSON & SONS TRAILER"
    },
    "MONT": {
        "id": "MONT",
        "name": "MONTE"
    },
    "MONU": {
        "id": "MONU",
        "name": "MONTGOMERY MFG."
    },
    "MONV": {
        "id": "MONV",
        "name": "MONTICLAIRE MOBILE HOMES"
    },
    "MONW": {
        "id": "MONW",
        "name": "MONTONE MFG."
    },
    "MOOD": {
        "id": "MOOD",
        "name": "MOODY MFG."
    },
    "MOON": {
        "id": "MOON",
        "name": "MOONSHINE TRAILERS KENTUCKY"
    },
    "MOOR": {
        "id": "MOOR",
        "name": "MOORE EQUIPMENT"
    },
    "MOPD": {
        "id": "MOPD",
        "name": "MOPED WORLD TAYLORS"
    },
    "MORB": {
        "id": "MORB",
        "name": "MORBARK"
    },
    "MORD": {
        "id": "MORD",
        "name": "GRASSHOPPER MFG BY MORIDGE MFG."
    },
    "MORE": {
        "id": "MORE",
        "name": "MORETTI"
    },
    "MORG": {
        "id": "MORG",
        "name": "MORGAN"
    },
    "MORI": {
        "id": "MORI",
        "name": "MORRIS BROTHERS"
    },
    "MORM": {
        "id": "MORM",
        "name": "MORGAN MOBILE"
    },
    "MORN": {
        "id": "MORN",
        "name": "MORGAN TRAILER MFG."
    },
    "MORR": {
        "id": "MORR",
        "name": "MORRIS"
    },
    "MORS": {
        "id": "MORS",
        "name": "MORSE HORSE TRAILER"
    },
    "MORT": {
        "id": "MORT",
        "name": "MORITZ"
    },
    "MORU": {
        "id": "MORU",
        "name": "MOTO RUMI"
    },
    "MORW": {
        "id": "MORW",
        "name": "MORGER WELDING & MFG."
    },
    "MOSE": {
        "id": "MOSE",
        "name": "MOSER SEE ALSO VIM TRAILER"
    },
    "MOSK": {
        "id": "MOSK",
        "name": "MOSKVITCH RUSSIA"
    },
    "MOSL": {
        "id": "MOSL",
        "name": "MOSLER AUTOMOTIVE"
    },
    "MOSW": {
        "id": "MOSW",
        "name": "MOBILE SWEEPER DIV.DIV. OF ATHEY PRODUCTS CORP."
    },
    "MOTA": {
        "id": "MOTA",
        "name": "MOTOC SEMI FLATBED TRAILER"
    },
    "MOTB": {
        "id": "MOTB",
        "name": "MOTOBECANE SCOOTERS & CYCLES"
    },
    "MOTE": {
        "id": "MOTE",
        "name": "MOTEL MOBILE CORPORATION OF AMERICA"
    },
    "MOTG": {
        "id": "MOTG",
        "name": "MOBILE TECHNOLOGIES GROUP DMS DIGITAL MANAGEMENT SOLUTIONS"
    },
    "MOTM": {
        "id": "MOTM",
        "name": "MOTOM"
    },
    "MOTN": {
        "id": "MOTN",
        "name": "MOTRON"
    },
    "MOTO": {
        "id": "MOTO",
        "name": "MOTOROAM INDUSTRIES"
    },
    "MOTR": {
        "id": "MOTR",
        "name": "MO TRAILER CORP.GOSHEN"
    },
    "MOTS": {
        "id": "MOTS",
        "name": "MONTESA"
    },
    "MOTU": {
        "id": "MOTU",
        "name": "MOTUS MOTORCYCLES BIRMINGHAM"
    },
    "MOTV": {
        "id": "MOTV",
        "name": "MOTIVE POWER INDUSTRY"
    },
    "MOUA": {
        "id": "MOUA",
        "name": "MOUNT VERNON MOBILE HOMEDIV. CONCORD MOBILE HOMES"
    },
    "MOUE": {
        "id": "MOUE",
        "name": "MOUNTAIN VALLEY ENTERPRISES"
    },
    "MOUM": {
        "id": "MOUM",
        "name": "MOUNTAIN VIEW CAMPERS"
    },
    "MOUN": {
        "id": "MOUN",
        "name": "MOUNTAINEER MFG."
    },
    "MOUT": {
        "id": "MOUT",
        "name": "MOUNTAIN MANUFACTURING"
    },
    "MOVT": {
        "id": "MOVT",
        "name": "MOBILITY VENTURES"
    },
    "MOWA": {
        "id": "MOWA",
        "name": "MONTGOMERY WARD - SNOWMOBILES"
    },
    "MOWE": {
        "id": "MOWE",
        "name": "MOWETT SALES"
    },
    "MPC": {
        "id": "MPC",
        "name": "MPC CRUISER CAMPER TRAILER"
    },
    "MPED": {
        "id": "MPED",
        "name": "MOTOPED"
    },
    "MPEQ": {
        "id": "MPEQ",
        "name": "MONSTER POWER EQUIPMENT INC OLD SAYBROOK CT"
    },
    "MPGT": {
        "id": "MPGT",
        "name": "MPG MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "MPHI": {
        "id": "MPHI",
        "name": "MPH INDUSTRIES"
    },
    "MPLE": {
        "id": "MPLE",
        "name": "MAPLELAWN COACH WORKS"
    },
    "MPRT": {
        "id": "MPRT",
        "name": "MAINE PORTAGE CARMEL"
    },
    "MQPW": {
        "id": "MQPW",
        "name": "MQ POWER"
    },
    "MRAU": {
        "id": "MRAU",
        "name": "MARAUDER TRAVELERS"
    },
    "MRCO": {
        "id": "MRCO",
        "name": "MARCO"
    },
    "MRCT": {
        "id": "MRCT",
        "name": "M & R CUSTOM TRIALERS"
    },
    "MRCU": {
        "id": "MRCU",
        "name": "MERCURY"
    },
    "MRDN": {
        "id": "MRDN",
        "name": "MERIDIAN MANUFACTURING INC. STORM LAKE"
    },
    "MRED": {
        "id": "MRED",
        "name": "MR. ED. BOAT TRAILER"
    },
    "MRGN": {
        "id": "MRGN",
        "name": "MORGAN BUILT INC"
    },
    "MRGW": {
        "id": "MRGW",
        "name": "MOTGAN THREE"
    },
    "MRGY": {
        "id": "MRGY",
        "name": "MARGAY CYCLES & KARTS"
    },
    "MRIN": {
        "id": "MRIN",
        "name": "JAMAICAMFD. BY MARION HOMES"
    },
    "MRIO": {
        "id": "MRIO",
        "name": "MARION TRAILER SALES MARION INDIANA"
    },
    "MRKS": {
        "id": "MRKS",
        "name": "MARKSMAN"
    },
    "MRND": {
        "id": "MRND",
        "name": "MIRANDA'S WELDING SERVICE"
    },
    "MRNE": {
        "id": "MRNE",
        "name": "MARINE TRANSPORTATION"
    },
    "MRPL": {
        "id": "MRPL",
        "name": "MARLON RECREATIONAL PRODUCTS LTD"
    },
    "MRQM": {
        "id": "MRQM",
        "name": "MARQUEZ MFG."
    },
    "MRRA": {
        "id": "MRRA",
        "name": "MURRAY MARINE CORP."
    },
    "MRRS": {
        "id": "MRRS",
        "name": "MORS FRANCE - 1897-1925"
    },
    "MRS": {
        "id": "MRS",
        "name": "M-R-S MANUFACTURING"
    },
    "MRSE": {
        "id": "MRSE",
        "name": "MORSE OVERLAND MARINE"
    },
    "MRST": {
        "id": "MRST",
        "name": "M & R SPECIALTY TRAILERS AND TRUCKS"
    },
    "MRTE": {
        "id": "MRTE",
        "name": "MOTORETTE"
    },
    "MRTH": {
        "id": "MRTH",
        "name": "MARATHON COACH MOTOR COACHES"
    },
    "MRTI": {
        "id": "MRTI",
        "name": "MARTIN LOWBOY SEMI TRAILER"
    },
    "MRTN": {
        "id": "MRTN",
        "name": "MARTINS ENTERPRISES DONALDS"
    },
    "MRTR": {
        "id": "MRTR",
        "name": "MR TRAILER SALES GEORGIA TRAILERS"
    },
    "MRTT": {
        "id": "MRTT",
        "name": "MERRITT TRAILERS INC HENDERSON CO"
    },
    "MRTZ": {
        "id": "MRTZ",
        "name": "MARTINEZ TRAILER"
    },
    "MRZM": {
        "id": "MRZM",
        "name": "MERTZ MANUFACTURING"
    },
    "MSBA": {
        "id": "MSBA",
        "name": "MASABA INC VERMILLION SD"
    },
    "MSDS": {
        "id": "MSDS",
        "name": "MID SOUTH DISTRIBUTION SERVICES INC DOUGLAS GA"
    },
    "MSEC": {
        "id": "MSEC",
        "name": "MANSFIELD STRUCTURAL & ERECTING COMPANY MANSFIELD"
    },
    "MSGC": {
        "id": "MSGC",
        "name": "MIDSOUTH GOLF CARTS"
    },
    "MSHN": {
        "id": "MSHN",
        "name": "MARSHIN MOTORBIKE"
    },
    "MSI": {
        "id": "MSI",
        "name": "M-SYSTEM"
    },
    "MSIM": {
        "id": "MSIM",
        "name": "MID-STATES INTERNATIONAL MOTORCROSS INC."
    },
    "MSIN": {
        "id": "MSIN",
        "name": "MARTIN STEEL INC MIFFLINBURG PA"
    },
    "MSKA": {
        "id": "MSKA",
        "name": "MISKA TRAILER FACTORY HAMILTON"
    },
    "MSKC": {
        "id": "MSKC",
        "name": "MAKSIM KORCHAK"
    },
    "MSKN": {
        "id": "MSKN",
        "name": "MISKIN SCRAPER WORKS"
    },
    "MSMO": {
        "id": "MSMO",
        "name": "MASSIMO MOTORSPORTS"
    },
    "MSMW": {
        "id": "MSMW",
        "name": "M.S"
    },
    "MSOT": {
        "id": "MSOT",
        "name": "MIDSOTA MFG."
    },
    "MSPC": {
        "id": "MSPC",
        "name": "MILITARY SPEC. MANUFACTURING FORT COLLINS"
    },
    "MSTG": {
        "id": "MSTG",
        "name": "MUSTANG CONSTRUCTION EQUIPMENT ETC."
    },
    "MSTI": {
        "id": "MSTI",
        "name": "MOTORSPORT TRAILERS INC MORGANTOWN PA"
    },
    "MSTS": {
        "id": "MSTS",
        "name": "MASTER SOLUTIONS"
    },
    "MSTT": {
        "id": "MSTT",
        "name": "MASTERYDE TRAILERS"
    },
    "MSTW": {
        "id": "MSTW",
        "name": "MASTER TOW INC. FAYETTEVILLE"
    },
    "MSTY": {
        "id": "MSTY",
        "name": "MISTY MEADOW TRAILER SALES BERMONT - TRAILERS"
    },
    "MTBE": {
        "id": "MTBE",
        "name": "LEMOPED"
    },
    "MTBI": {
        "id": "MTBI",
        "name": "MOTOBI ITALY MOTORCYCLES"
    },
    "MTCH": {
        "id": "MTCH",
        "name": "MATCHLESS"
    },
    "MTCI": {
        "id": "MTCI",
        "name": "MISSISSIPPI TANK"
    },
    "MTD": {
        "id": "MTD",
        "name": "LAWN FLITE MFG BY MTD PRODUCTS"
    },
    "MTEA": {
        "id": "MTEA",
        "name": "MT EATON TRAILER"
    },
    "MTFF": {
        "id": "MTFF",
        "name": "MASTIFF TRAILERS GAINESBORO TN"
    },
    "MTLC": {
        "id": "MTLC",
        "name": "METAL CRAFT TRAILERS SALT LAKE CITY"
    },
    "MTLM": {
        "id": "MTLM",
        "name": "MAC TRAILER MANUFACTURING ALLIANCE OH"
    },
    "MTML": {
        "id": "MTML",
        "name": "MACPHERSON TRAILER MANUFACTURING LLC BUSHNELL FL"
    },
    "MTMV": {
        "id": "MTMV",
        "name": "MIGHTY MOVER TRAILERS"
    },
    "MTNA": {
        "id": "MTNA",
        "name": "MONTANA"
    },
    "MTNE": {
        "id": "MTNE",
        "name": "MONOTONE TRAILER COMPANY SOUTH CAROLINA"
    },
    "MTNH": {
        "id": "MTNH",
        "name": "MOUNTAINHIGH COACHWORKS"
    },
    "MTNI": {
        "id": "MTNI",
        "name": "MARTIN INDUSTRIES"
    },
    "MTNK": {
        "id": "MTNK",
        "name": "MONSTER TANKS"
    },
    "MTNW": {
        "id": "MTNW",
        "name": "MOUNTAIN WEST INDUSTRIES TOOELE"
    },
    "MTPA": {
        "id": "MTPA",
        "name": "MAXWELL TRAILERS & PICKUP ACCESSORIES MEXICO"
    },
    "MTPI": {
        "id": "MTPI",
        "name": "COLUMBIA SEE MTD PRODUCTS"
    },
    "MTRA": {
        "id": "MTRA",
        "name": "C & C INDUSTRIESMFG. OF MASTER TRACK F.B. TRAILER"
    },
    "MTRL": {
        "id": "MTRL",
        "name": "METRO WORLDWIDE LLC METRO LINK"
    },
    "MTRO": {
        "id": "MTRO",
        "name": "METRO RIDER LLC"
    },
    "MTRX": {
        "id": "MTRX",
        "name": "MATRIX MANUFACTURING COMPANY PRINCETON"
    },
    "MTRY": {
        "id": "MTRY",
        "name": "TRAILERS DE MONTERREY"
    },
    "MTSE": {
        "id": "MTSE",
        "name": "MONTROSE TRAILERS MICHIGAN"
    },
    "MTTI": {
        "id": "MTTI",
        "name": "M.T. TRAILERS"
    },
    "MTTM": {
        "id": "MTTM",
        "name": "MT MANUFACTURING"
    },
    "MTTS": {
        "id": "MTTS",
        "name": "MANUFACTURE TENT SALES"
    },
    "MTVX": {
        "id": "MTVX",
        "name": "MOTOVOX"
    },
    "MTZG": {
        "id": "MTZG",
        "name": "EVERITT AUTOMOBILE AND METZGER MOTOR CAR"
    },
    "MTZR": {
        "id": "MTZR",
        "name": "MENTZER CUSTOM TRAILER MFG. NEWVILLE"
    },
    "MUDC": {
        "id": "MUDC",
        "name": "MUD CAT DIV."
    },
    "MUDD": {
        "id": "MUDD",
        "name": "MUDD-OX"
    },
    "MUDS": {
        "id": "MUDS",
        "name": "MUDSLAYER MANUFACTURING"
    },
    "MUDT": {
        "id": "MUDT",
        "name": "MUD TECHNICAL INTERNATIONAL"
    },
    "MUDY": {
        "id": "MUDY",
        "name": "MUDDY RIVER"
    },
    "MUHL": {
        "id": "MUHL",
        "name": "MUHLBERG"
    },
    "MULE": {
        "id": "MULE",
        "name": "MULTECH CORP.CANTON"
    },
    "MULG": {
        "id": "MULG",
        "name": "MULLIGAN MANUFACTURING"
    },
    "MULL": {
        "id": "MULL",
        "name": "MULLER MACHINERY"
    },
    "MULQ": {
        "id": "MULQ",
        "name": "MULTIQUIP"
    },
    "MULT": {
        "id": "MULT",
        "name": "MULTI TRAILER"
    },
    "MUNC": {
        "id": "MUNC",
        "name": "MUNCY HOMES"
    },
    "MUNT": {
        "id": "MUNT",
        "name": "MUNTZ"
    },
    "MURE": {
        "id": "MURE",
        "name": "MURENA"
    },
    "MURM": {
        "id": "MURM",
        "name": "CARRY-ALL SCRAPERS"
    },
    "MURP": {
        "id": "MURP",
        "name": "MURPHY ENGINEERING"
    },
    "MURR": {
        "id": "MURR",
        "name": "MURRAY OHIO MANUFACTURING"
    },
    "MURT": {
        "id": "MURT",
        "name": "MURRAY TRAILERSCALDWELL"
    },
    "MUSG": {
        "id": "MUSG",
        "name": "MUSTANG TRAILER MFG."
    },
    "MUST": {
        "id": "MUST",
        "name": "MUSTANG"
    },
    "MUTG": {
        "id": "MUTG",
        "name": "MUSTANG TRAILERS GLASSPORT"
    },
    "MUVA": {
        "id": "MUVA",
        "name": "MUV-ALL TRAILERS MULTECH CORP."
    },
    "MVAC": {
        "id": "MVAC",
        "name": "MADVAC SPECIALITY SWEEPERS & VACUUMS"
    },
    "MVAU": {
        "id": "MVAU",
        "name": "M.V. AGUSTA"
    },
    "MVEN": {
        "id": "MVEN",
        "name": "MARINE VENTURE ENTERPRISERS"
    },
    "MVMI": {
        "id": "MVMI",
        "name": "MOUNTAIN VIEW MFG INC TOFIELD ALBERTA CANADA"
    },
    "MVMS": {
        "id": "MVMS",
        "name": "MVM7 MOUNT PLEASANT"
    },
    "MVPI": {
        "id": "MVPI",
        "name": "MVP RV INC AKA THOR CALIFORNIA INC BURBANK AND MORENO VALLEY CA"
    },
    "MWAD": {
        "id": "MWAD",
        "name": "MIDWEST AUTOMOTIVE DESIGNS ELKHART"
    },
    "MWCH": {
        "id": "MWCH",
        "name": "MID-WEST CHOPPERS"
    },
    "MWDY": {
        "id": "MWDY",
        "name": "DAYCRUISER MODEL"
    },
    "MWGE": {
        "id": "MWGE",
        "name": "M & W GEAR"
    },
    "MWHC": {
        "id": "MWHC",
        "name": "MAINE WOOD HEAT COMPANY SKOWHEGAN"
    },
    "MWIR": {
        "id": "MWIR",
        "name": "IROK BRAND BY MIDWEST AUTOMOTIVE DESIGNS"
    },
    "MWMC": {
        "id": "MWMC",
        "name": "M & W MFG."
    },
    "MWMM": {
        "id": "MWMM",
        "name": "MIDWEST MOTORCYCLES MANUFACTURERS"
    },
    "MWST": {
        "id": "MWST",
        "name": "MART"
    },
    "MWTM": {
        "id": "MWTM",
        "name": "MIDWEST TRAILER MANUFACTURING"
    },
    "MWTR": {
        "id": "MWTR",
        "name": "MAKINA WELDING TRAILERS ARAB AL"
    },
    "MWWK": {
        "id": "MWWK",
        "name": "WEEKEND MODEL"
    },
    "MXAT": {
        "id": "MXAT",
        "name": "MAX-ATLAS EQUIPMENT INTERNATIONAL"
    },
    "MXRL": {
        "id": "MXRL",
        "name": "MAXI-ROULE"
    },
    "MXTT": {
        "id": "MXTT",
        "name": "MXT MFG BY KZRV"
    },
    "MXYT": {
        "id": "MXYT",
        "name": "MAXEY TRAILER MFG.INC TEXAS"
    },
    "MYCO": {
        "id": "MYCO",
        "name": "MY"
    },
    "MYFA": {
        "id": "MYFA",
        "name": "MAYFAIRER MOTOR HOME"
    },
    "MYHI": {
        "id": "MYHI",
        "name": "MYERS & SONS HI-WAY SAFETY INC. OR HI-WAY SAFETY INC. CHINO"
    },
    "MYND": {
        "id": "MYND",
        "name": "MAYNARD AND"
    },
    "MYSP": {
        "id": "MYSP",
        "name": "MYERS-SETH PUMP"
    },
    "MYST": {
        "id": "MYST",
        "name": "MYSTICAL CUSTOM CYCLES"
    },
    "MYWA": {
        "id": "MYWA",
        "name": "MY-WAY CORP.LONGWOOD"
    },
    "MYWL": {
        "id": "MYWL",
        "name": "MYERS WELDING SPARTA"
    },
    "MZ": {
        "id": "MZ",
        "name": "MZ"
    },
    "MZMA": {
        "id": "MZMA",
        "name": "MZMA"
    },
    "NABI": {
        "id": "NABI",
        "name": "NORTH AMERICAN BUS INDSUTRIES"
    },
    "NABO": {
        "id": "NABO",
        "name": "NABORS TRAILERS"
    },
    "NACB": {
        "id": "NACB",
        "name": "NORTH ALABAMA TRAILER"
    },
    "NACH": {
        "id": "NACH",
        "name": "NANCHANG AIRCRAFT MANUFACTURING COMPANY NANCHANG"
    },
    "NACL": {
        "id": "NACL",
        "name": "NORTH AMERICAN CARGO"
    },
    "NACR": {
        "id": "NACR",
        "name": "NATIONAL CRANE CORPORATION"
    },
    "NAHE": {
        "id": "NAHE",
        "name": "NASH-HEALY"
    },
    "NAIR": {
        "id": "NAIR",
        "name": "NAIROBI TRAILER"
    },
    "NAJO": {
        "id": "NAJO",
        "name": "NAJO"
    },
    "NALB": {
        "id": "NALB",
        "name": "NORTH ALABAMA TRAILER"
    },
    "NAMC": {
        "id": "NAMC",
        "name": "N & HA MFG."
    },
    "NAMO": {
        "id": "NAMO",
        "name": "NAM"
    },
    "NANJ": {
        "id": "NANJ",
        "name": "NANJING AUTOMOBILE"
    },
    "NANL": {
        "id": "NANL",
        "name": "NANLONG GROUP"
    },
    "NANZ": {
        "id": "NANZ",
        "name": "NANA'Z TRAILERS"
    },
    "NAPI": {
        "id": "NAPI",
        "name": "NAPIER & SON LIMITED ANTIQUE AUTOS"
    },
    "NARD": {
        "id": "NARD",
        "name": "NARDI-DANESE"
    },
    "NARV": {
        "id": "NARV",
        "name": "NATIONAL RV INC."
    },
    "NASA": {
        "id": "NASA",
        "name": "NASAN TRAILER"
    },
    "NASH": {
        "id": "NASH",
        "name": "NASH"
    },
    "NASM": {
        "id": "NASM",
        "name": "NASH MFG."
    },
    "NASU": {
        "id": "NASU",
        "name": "NASHUA MFG."
    },
    "NATL": {
        "id": "NATL",
        "name": "NATIONAL MOBILE HOMES"
    },
    "NATM": {
        "id": "NATM",
        "name": "NATIONAL MANUFACTURING VALLEY CITY"
    },
    "NATT": {
        "id": "NATT",
        "name": "NATIONAL TRAILER INC OCALA FLORIDA"
    },
    "NATV": {
        "id": "NATV",
        "name": "NORTH AMERICAN TRAVELER LLC"
    },
    "NATW": {
        "id": "NATW",
        "name": "NATIONWIDE"
    },
    "NAUT": {
        "id": "NAUT",
        "name": "NAUTICAL AMERICA - STATESVILLE"
    },
    "NAVI": {
        "id": "NAVI",
        "name": "NAVISTAR"
    },
    "NAVJ": {
        "id": "NAVJ",
        "name": "NAVAJO HORSE TRAILER"
    },
    "NBAY": {
        "id": "NBAY",
        "name": "NORTHERN BAY"
    },
    "NBCC": {
        "id": "NBCC",
        "name": "NEBRASKA CUSTOM CYCLES"
    },
    "NBCT": {
        "id": "NBCT",
        "name": "NBC TRUCK EQUIPMENT"
    },
    "NBLE": {
        "id": "NBLE",
        "name": "NOBLE AUTOMOTIVE LTD"
    },
    "NCHM": {
        "id": "NCHM",
        "name": "NICHOLSON MANUFACTURING INC NMI IXONIA WI"
    },
    "NCKB": {
        "id": "NCKB",
        "name": "NICKELS BOAT WORKS"
    },
    "NCMW": {
        "id": "NCMW",
        "name": "NATIONAL CUSTOM MOTORWORKS SPOKANE"
    },
    "NCTB": {
        "id": "NCTB",
        "name": "NORTH COAST TRUCK BODY LLC"
    },
    "NCTR": {
        "id": "NCTR",
        "name": "NC TRAIL RUNNER MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "NDMC": {
        "id": "NDMC",
        "name": "NISSAN DIESEL MOTOR"
    },
    "NEAL": {
        "id": "NEAL",
        "name": "NEAL MFG."
    },
    "NEBD": {
        "id": "NEBD",
        "name": "BENTLEY DIAMOND MFG BY NEXUS RVS LLC"
    },
    "NEBM": {
        "id": "NEBM",
        "name": "NEW BAME TRAILER"
    },
    "NEBU": {
        "id": "NEBU",
        "name": "TAIZHOU NEBULA POWER"
    },
    "NECK": {
        "id": "NECK",
        "name": "NECKAR"
    },
    "NECT": {
        "id": "NECT",
        "name": "NECKOVER TRAILER MFG."
    },
    "NEDL": {
        "id": "NEDL",
        "name": "NEDLAND INDUSTRIES"
    },
    "NEEV": {
        "id": "NEEV",
        "name": "EVOQUE MFG BY NEXUS RVS LLC"
    },
    "NEGH": {
        "id": "NEGH",
        "name": "GHOST MODEL MFG BY NEXUS RVS"
    },
    "NEGR": {
        "id": "NEGR",
        "name": "HARVARD"
    },
    "NEHO": {
        "id": "NEHO",
        "name": "NEW HOLLAND HAYBINE"
    },
    "NELL": {
        "id": "NELL",
        "name": "NALLEJ OF FLORIDA"
    },
    "NELN": {
        "id": "NELN",
        "name": "NELSON-DYKES"
    },
    "NELS": {
        "id": "NELS",
        "name": "NELSON MFG."
    },
    "NEOC": {
        "id": "NEOC",
        "name": "NEO CLASSIC MANUFACTURING"
    },
    "NEOP": {
        "id": "NEOP",
        "name": "NEOPLAN USA CORPORATIONLAMAR"
    },
    "NEOS": {
        "id": "NEOS",
        "name": "NEOSHO CUSTOM COACH"
    },
    "NEPH": {
        "id": "NEPH",
        "name": "PHANTOM MODEL MFG BY NEXUS RVS"
    },
    "NEPT": {
        "id": "NEPT",
        "name": "NEPTUNE CORP."
    },
    "NERA": {
        "id": "NERA",
        "name": "NEW ERA TRANS"
    },
    "NERC": {
        "id": "NERC",
        "name": "NER"
    },
    "NESC": {
        "id": "NESC",
        "name": "NORTHEAST STEEL CORPORATION CONNECTICUT"
    },
    "NESS": {
        "id": "NESS",
        "name": "NESS MOTORCYCLES OR ARLEN NESS MOTORCYCLES"
    },
    "NETR": {
        "id": "NETR",
        "name": "TRIUMPH MFG BY NEXUS RVS LLC"
    },
    "NEUF": {
        "id": "NEUF",
        "name": "KARL M. NEUFELD"
    },
    "NEVA": {
        "id": "NEVA",
        "name": "NEVADA AIR PRODUCTS"
    },
    "NEVD": {
        "id": "NEVD",
        "name": "NEVADAN MFG."
    },
    "NEVI": {
        "id": "NEVI",
        "name": "NEVILLE WELDING"
    },
    "NEVL": {
        "id": "NEVL",
        "name": "NEVLEN COMPANY INC.WAKEFIELD"
    },
    "NEVM": {
        "id": "NEVM",
        "name": "NEVAL MOTORCYCLES LTD."
    },
    "NEVP": {
        "id": "NEVP",
        "name": "VIPER MODEL MFG BY NEXUS RVS"
    },
    "NEWC": {
        "id": "NEWC",
        "name": "NEW COMER INDUSTRIES"
    },
    "NEWD": {
        "id": "NEWD",
        "name": "NEW DIMENSION"
    },
    "NEWE": {
        "id": "NEWE",
        "name": "NEW ENGLAND HOMES OR TRAILERS"
    },
    "NEWH": {
        "id": "NEWH",
        "name": "SPERRY NEW HOLLAND DIV SPERRY CORP."
    },
    "NEWI": {
        "id": "NEWI",
        "name": "NEW IDEA ELECTRIC LAWN GARDEN TRACTOR"
    },
    "NEWK": {
        "id": "NEWK",
        "name": "NEW YORKER HOMES CORP."
    },
    "NEWL": {
        "id": "NEWL",
        "name": "NEWELL COACH CORP.MIAMI"
    },
    "NEWM": {
        "id": "NEWM",
        "name": "NEWHAM ENTERPRISES"
    },
    "NEWN": {
        "id": "NEWN",
        "name": "NEWMANS MANUFACTURING MINNESOTA"
    },
    "NEWP": {
        "id": "NEWP",
        "name": "NEWPORT HOMES"
    },
    "NEWS": {
        "id": "NEWS",
        "name": "NEW STYLE HOMES"
    },
    "NEWT": {
        "id": "NEWT",
        "name": "NEW PARIS TRAVELER CORP.NEW PARIS"
    },
    "NEWV": {
        "id": "NEWV",
        "name": "NEW WAVE TEARDROP BAINBRIDGE"
    },
    "NEWW": {
        "id": "NEWW",
        "name": "WEEKEND WARRIOR MFG BY NEXUS RV'S"
    },
    "NEWY": {
        "id": "NEWY",
        "name": "NEWAY DUMP TRAILER"
    },
    "NEXH": {
        "id": "NEXH",
        "name": "NEXHAUL ROCKY MOUNT"
    },
    "NEXT": {
        "id": "NEXT",
        "name": "NEXTRAIL OHIO"
    },
    "NEXU": {
        "id": "NEXU",
        "name": "NEXUS RV ELKHART"
    },
    "NFLD": {
        "id": "NFLD",
        "name": "ENFIELD INDIA LIMITED MADRAS"
    },
    "NFLY": {
        "id": "NFLY",
        "name": "NEW FLYER BUSES"
    },
    "NFSK": {
        "id": "NFSK",
        "name": "NILFISK PRESSURE-PRO LLC FORT PIERCE"
    },
    "NGBO": {
        "id": "NGBO",
        "name": "NINGBO LONGJIA MOTORCYCLE"
    },
    "NGCS": {
        "id": "NGCS",
        "name": "NOMAD GLOBAL COMMUNICATION SOLUTIONS"
    },
    "NGIL": {
        "id": "NGIL",
        "name": "NATIONAL GUARD"
    },
    "NHGT": {
        "id": "NHGT",
        "name": "NEW HEIGHTS"
    },
    "NHRZ": {
        "id": "NHRZ",
        "name": "NEW HORIZONS TRAVEL TTRAILERS JUNCTION CITY"
    },
    "NHYD": {
        "id": "NHYD",
        "name": "NORTHERN HYDROLICS"
    },
    "NIAG": {
        "id": "NIAG",
        "name": "NIAGARA TRAILER"
    },
    "NICE": {
        "id": "NICE",
        "name": "NICESON BOAT TRAILER"
    },
    "NICH": {
        "id": "NICH",
        "name": "NICHOLS MFG."
    },
    "NICK": {
        "id": "NICK",
        "name": "NICKEL & HOLMAN FENTON MICHIGAN"
    },
    "NICW": {
        "id": "NICW",
        "name": "NICHOLS CUSTOM WELDING HOME OF NICHOLS TRAILERS FARMINGTON"
    },
    "NIFT": {
        "id": "NIFT",
        "name": "NIFTYLIFT UNLIMITED UNITED KINGDOM TRAILERS"
    },
    "NIMB": {
        "id": "NIMB",
        "name": "NIMBUS COPENHAGEN DENMARK"
    },
    "NIMR": {
        "id": "NIMR",
        "name": "NIMROD TENT TRAILER"
    },
    "NIOV": {
        "id": "NIOV",
        "name": "NIO CHINA"
    },
    "NISS": {
        "id": "NISS",
        "name": "NISSAN"
    },
    "NIVI": {
        "id": "NIVI",
        "name": "NATIONAL INNOVATIVE VISIONS"
    },
    "NLBC": {
        "id": "NLBC",
        "name": "NLB CORP"
    },
    "NLSN": {
        "id": "NLSN",
        "name": "NELSON"
    },
    "NLTE": {
        "id": "NLTE",
        "name": "NORTHERN LITE MFG."
    },
    "NMMH": {
        "id": "NMMH",
        "name": "NEW MOON MANUFACTURED HOME"
    },
    "NMPR": {
        "id": "NMPR",
        "name": "NATIONAL METAL PRODUCTS"
    },
    "NMVC": {
        "id": "NMVC",
        "name": "NATIONAL MOTOR VEHICLE COMPANY INDIANA"
    },
    "NNRQ": {
        "id": "NNRQ",
        "name": "N & N REMORQUE"
    },
    "NNTM": {
        "id": "NNTM",
        "name": "NORTH NEWTON TRAILER MANUFACTURING"
    },
    "NOAT": {
        "id": "NOAT",
        "name": "NORTH AMERICAN TRAILER S.A. DE CV"
    },
    "NOBI": {
        "id": "NOBI",
        "name": "NOBILITY HOMES"
    },
    "NOBL": {
        "id": "NOBL",
        "name": "NOBLE CULTIVATORS LTD."
    },
    "NOCO": {
        "id": "NOCO",
        "name": "NORTH COUNTRY"
    },
    "NODE": {
        "id": "NODE",
        "name": "HAULER-TRAILER MFG BY NODINE MFG."
    },
    "NODI": {
        "id": "NODI",
        "name": "NORDIC"
    },
    "NOEL": {
        "id": "NOEL",
        "name": "NOEL MFG."
    },
    "NOLE": {
        "id": "NOLE",
        "name": "NOLENA FOSTER TRAILER"
    },
    "NOMA": {
        "id": "NOMA",
        "name": "NOMAD TRAILERS & MOBILE HOMES DEWEY OKLAHOMA"
    },
    "NOMD": {
        "id": "NOMD",
        "name": "NOMAD MFG BY LAYTON HOMES CORP"
    },
    "NOMN": {
        "id": "NOMN",
        "name": "NOMANCO"
    },
    "NOMO": {
        "id": "NOMO",
        "name": "NORTHWESTERN MOTOR"
    },
    "NORC": {
        "id": "NORC",
        "name": "NORCAL BOAT TRAILER"
    },
    "NORD": {
        "id": "NORD",
        "name": "NORDINE MFG."
    },
    "NORH": {
        "id": "NORH",
        "name": "NORTHWEST ENGINEERING"
    },
    "NORI": {
        "id": "NORI",
        "name": "NORRIS HOMES"
    },
    "NORJ": {
        "id": "NORJ",
        "name": "NORJACK"
    },
    "NORL": {
        "id": "NORL",
        "name": "NORTHLAND"
    },
    "NORM": {
        "id": "NORM",
        "name": "NORMAN"
    },
    "NORN": {
        "id": "NORN",
        "name": "NORTH AMERICAN MFG."
    },
    "NORO": {
        "id": "NORO",
        "name": "NORTH AMERICAN SHIPBUILDING"
    },
    "NORR": {
        "id": "NORR",
        "name": "NORTHERN CRUISERS"
    },
    "NORS": {
        "id": "NORS",
        "name": "NORSE TRAILER"
    },
    "NORT": {
        "id": "NORT",
        "name": "NORTON"
    },
    "NORU": {
        "id": "NORU",
        "name": "NORTHERN STAR MOBILE HOME"
    },
    "NORV": {
        "id": "NORV",
        "name": "NORTHLANDMAGLINE"
    },
    "NORW": {
        "id": "NORW",
        "name": "NORTHWEST TRAILERS"
    },
    "NORX": {
        "id": "NORX",
        "name": "NORTHWESTERN MOBILE HOMESDIV. RAINWAY MANUFACTURING COMPANY"
    },
    "NOSL": {
        "id": "NOSL",
        "name": "NOSLO TRAILER MANUFACTURING VERMONT"
    },
    "NOSV": {
        "id": "NOSV",
        "name": "NORTH SHREVE WELDING"
    },
    "NOTH": {
        "id": "NOTH",
        "name": "NOTHSWAY TANDEM TRAILER"
    },
    "NOTL": {
        "id": "NOTL",
        "name": "NORTAIL"
    },
    "NOTM": {
        "id": "NOTM",
        "name": "NORTHLANDER TRAILERS"
    },
    "NOTR": {
        "id": "NOTR",
        "name": "NORTH TRAIL MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "NOVA": {
        "id": "NOVA",
        "name": "NOVA FABRICATING"
    },
    "NOVB": {
        "id": "NOVB",
        "name": "NOVA BUS"
    },
    "NPTU": {
        "id": "NPTU",
        "name": "NEPTUNE MFG BY PALM HARBOR HOMES"
    },
    "NRJB": {
        "id": "NRJB",
        "name": "NORTH RIVER JET BOATS ROSEBURG OR"
    },
    "NRMD": {
        "id": "NRMD",
        "name": "NORMAND"
    },
    "NRMP": {
        "id": "NRMP",
        "name": "NORAMP"
    },
    "NRRI": {
        "id": "NRRI",
        "name": "NORRIS TRAILER MANUFACTURING MOUNT PLEASANT"
    },
    "NRRW": {
        "id": "NRRW",
        "name": "NORTH REPAIR & WELDING RINGLE"
    },
    "NRST": {
        "id": "NRST",
        "name": "NORSTAR TRAILERS"
    },
    "NRTE": {
        "id": "NRTE",
        "name": "GRUPO REMOLQUES DEL NORTE SA DE CV MEXICO"
    },
    "NRTH": {
        "id": "NRTH",
        "name": "NORTH STAR"
    },
    "NRTL": {
        "id": "NRTL",
        "name": "NORTHTRAIL TRAILERS"
    },
    "NRTS": {
        "id": "NRTS",
        "name": "NORTHWAY SEE NORTHWAY SNOWMOBILES"
    },
    "NRTW": {
        "id": "NRTW",
        "name": "NORTHWAY TRAILER"
    },
    "NRVR": {
        "id": "NRVR",
        "name": "NORTH RIVER HOMESHAMILTON"
    },
    "NRWD": {
        "id": "NRWD",
        "name": "NORTHWOOD OF VIRGINIA WINCHESTER VIRGINIA"
    },
    "NRWM": {
        "id": "NRWM",
        "name": "NORTHWOOD MANUFACTURING"
    },
    "NRWN": {
        "id": "NRWN",
        "name": "NORWIN TRAILER"
    },
    "NSCA": {
        "id": "NSCA",
        "name": "NORTH-SOUTH CONNECTION AUTO SALES"
    },
    "NSHM": {
        "id": "NSHM",
        "name": "NASH MOTORCYCLE"
    },
    "NSHT": {
        "id": "NSHT",
        "name": "NASH CAR TRAILERS"
    },
    "NSIG": {
        "id": "NSIG",
        "name": "NATIONAL SIGNAL INC"
    },
    "NSMC": {
        "id": "NSMC",
        "name": "NORTH SHORE MANUFACTURING"
    },
    "NSTR": {
        "id": "NSTR",
        "name": "NOR'EASTER BOAT TRAILERS"
    },
    "NSU": {
        "id": "NSU",
        "name": "NSU PRINZ"
    },
    "NSUF": {
        "id": "NSUF",
        "name": "NSU-FIAT"
    },
    "NTCT": {
        "id": "NTCT",
        "name": "NEW TREND CUSTOM TRAILERS"
    },
    "NTEC": {
        "id": "NTEC",
        "name": "NORTHERN TOOL AND EQUIPMENT COMPANY"
    },
    "NTFO": {
        "id": "NTFO",
        "name": "NT FOCUS MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "NTHC": {
        "id": "NTHC",
        "name": "NINTH CIRCLE CUSTOMS DELTA JUNCTION"
    },
    "NTHE": {
        "id": "NTHE",
        "name": "NORTHEAST MANUFACTURING RANDOLPH CENTER"
    },
    "NTHN": {
        "id": "NTHN",
        "name": "NORTHERN RINGLE"
    },
    "NTMI": {
        "id": "NTMI",
        "name": "NATIONAL TRAILER MANUFACTURING"
    },
    "NTNS": {
        "id": "NTNS",
        "name": "NATIONS TRAILERS"
    },
    "NUCN": {
        "id": "NUCN",
        "name": "NU-CENTURY MADERA"
    },
    "NUEL": {
        "id": "NUEL",
        "name": "NUELL COACH CORP."
    },
    "NUKO": {
        "id": "NUKO",
        "name": "NUKO INDUSTRIES INC ELK GROVE VILLAGE IL"
    },
    "NULF": {
        "id": "NULF",
        "name": "NU-LIFE ENVIRONMENTAL"
    },
    "NUON": {
        "id": "NUON",
        "name": "NUMBER ONE"
    },
    "NUTT": {
        "id": "NUTT",
        "name": "NUTTALL TRAILERS"
    },
    "NUVN": {
        "id": "NUVN",
        "name": "NU VAN TECHNOLOGY"
    },
    "NUWA": {
        "id": "NUWA",
        "name": "NU-WA CAMPERS"
    },
    "NUWE": {
        "id": "NUWE",
        "name": "NUWAY MOBILE HOME MFG."
    },
    "NUWH": {
        "id": "NUWH",
        "name": "NUWA HORIZON CAMPER TRAILER"
    },
    "NUWY": {
        "id": "NUWY",
        "name": "NU WAY MFG - TUPELO"
    },
    "NVAE": {
        "id": "NVAE",
        "name": "NOVAE CORP. FORT WAYNE INDIANA MAKER OF SURE-TRAC TRAILERS"
    },
    "NVTA": {
        "id": "NVTA",
        "name": "NVT AMERICA"
    },
    "NVYA": {
        "id": "NVYA",
        "name": "NAVYA FRANCE AND UNITED STATES"
    },
    "NWAT": {
        "id": "NWAT",
        "name": "NORTHWEST ALUMINUM TRAILERS ROSEBURG"
    },
    "NWAY": {
        "id": "NWAY",
        "name": "NEW WAY"
    },
    "NWCA": {
        "id": "NWCA",
        "name": "N.W."
    },
    "NWCC": {
        "id": "NWCC",
        "name": "NORTH WOODS CANOE COMPANY"
    },
    "NWCT": {
        "id": "NWCT",
        "name": "NORTHWEST CUSTOM TRAILER ROCHESTER"
    },
    "NWCU": {
        "id": "NWCU",
        "name": "NEW CUSTOM TRAILERS"
    },
    "NWHD": {
        "id": "NWHD",
        "name": "NWT HOLDINGS LLC HONEY GROVE TX"
    },
    "NWKS": {
        "id": "NWKS",
        "name": "KOUNTRY STAR MFG BY NEWMAR CORP"
    },
    "NWLA": {
        "id": "NWLA",
        "name": "LONDON AIRE MFG BY NEWMAR CORP"
    },
    "NWMC": {
        "id": "NWMC",
        "name": "NEW MCGRATH"
    },
    "NWMR": {
        "id": "NWMR",
        "name": "NEWMAR CORPORATION NAPPANEE IN"
    },
    "NWNA": {
        "id": "NWNA",
        "name": "NEW AIRE MFG BY NEWMAR CORP"
    },
    "NWRI": {
        "id": "NWRI",
        "name": "NANYANG ENVIRONMENT & WATER RESEARCH INSTITUTE HYDROGEN POWERED"
    },
    "NWSA": {
        "id": "NWSA",
        "name": "SUPREME AIRE MFG BY NEWMAR CORP"
    },
    "NWSS": {
        "id": "NWSS",
        "name": "SUPERSTAR MFG BY NEWMAR CORP"
    },
    "NWST": {
        "id": "NWST",
        "name": "NEW STAR GROUP"
    },
    "NWTT": {
        "id": "NWTT",
        "name": "NORTHWEST TECHNOLOGIES INC ESTACADA"
    },
    "NYCC": {
        "id": "NYCC",
        "name": "NEW YORK CITY CHOPPERS"
    },
    "OAKC": {
        "id": "OAKC",
        "name": "OAK COACH"
    },
    "OAKD": {
        "id": "OAKD",
        "name": "OAKDALE TRAILERS AND"
    },
    "OAKL": {
        "id": "OAKL",
        "name": "OAKLAND"
    },
    "OASI": {
        "id": "OASI",
        "name": "OASIS TRAVEL TRAILER"
    },
    "OBER": {
        "id": "OBER",
        "name": "OBERLIN TRAILERS"
    },
    "OBMH": {
        "id": "OBMH",
        "name": "OAKBROOK MANUFACTURED HOME"
    },
    "OBRI": {
        "id": "OBRI",
        "name": "OBRECT TRAILER"
    },
    "OCCH": {
        "id": "OCCH",
        "name": "ORANGE COUNTY CHOPPERS"
    },
    "OCIM": {
        "id": "OCIM",
        "name": "OCI MANUFACTURING"
    },
    "OCKE": {
        "id": "OCKE",
        "name": "OCKELBO INDUSTRY AB"
    },
    "OCMI": {
        "id": "OCMI",
        "name": "CLASSIC MANUFACTURING"
    },
    "OCPF": {
        "id": "OCPF",
        "name": "OCEAN PERFORMANCE TRAILERS"
    },
    "OCTN": {
        "id": "OCTN",
        "name": "OCTANE TRAILERS"
    },
    "ODDI": {
        "id": "ODDI",
        "name": "ODDI CYCLES LLC"
    },
    "ODEL": {
        "id": "ODEL",
        "name": "ODELL MANUFACTURING COMPANY LARGO"
    },
    "ODGL": {
        "id": "ODGL",
        "name": "ARGO SEE ONTARIO DRIVE & GEAR LTDD"
    },
    "ODMH": {
        "id": "ODMH",
        "name": "OAKWOOD MANUFACTURED HOME"
    },
    "ODOM": {
        "id": "ODOM",
        "name": "ODOM BOYD TRAILER MFG."
    },
    "ODSS": {
        "id": "ODSS",
        "name": "ODYSSEY"
    },
    "ODYS": {
        "id": "ODYS",
        "name": "ODYSSEY TRAILER"
    },
    "OELR": {
        "id": "OELR",
        "name": "OELRICH MFG."
    },
    "OFFC": {
        "id": "OFFC",
        "name": "OFFICE MASTER"
    },
    "OFFI": {
        "id": "OFFI",
        "name": "OFFICER"
    },
    "OFPC": {
        "id": "OFPC",
        "name": "OHIO FLUID PRODUCTS COMPANY"
    },
    "OGLE": {
        "id": "OGLE",
        "name": "OGLE"
    },
    "OGTL": {
        "id": "OGTL",
        "name": "OFF GRID TRAILERS LP EDMONTON ALBERTA CANADA"
    },
    "OHIO": {
        "id": "OHIO",
        "name": "OHIO BODY MFG."
    },
    "OHSE": {
        "id": "OHSE",
        "name": "OLINGHOUSE STEEL OKLAHOMA TRAILERS & TRUCK BODIES"
    },
    "OHST": {
        "id": "OHST",
        "name": "OHIO STEEL INDUSTRIES"
    },
    "OHTA": {
        "id": "OHTA",
        "name": "OHTA"
    },
    "OILF": {
        "id": "OILF",
        "name": "OILFIELD MANUFACTURERS WAREHOUSE"
    },
    "OKCK": {
        "id": "OKCK",
        "name": "OAK CREEK HOMES"
    },
    "OKHT": {
        "id": "OKHT",
        "name": "OK HORSE TRAILER"
    },
    "OKIR": {
        "id": "OKIR",
        "name": "OAK & IRON"
    },
    "OKLA": {
        "id": "OKLA",
        "name": "OKLAHOMA HORSE TRAILER"
    },
    "OKLH": {
        "id": "OKLH",
        "name": "OKLAHOMA TRAILER"
    },
    "OLAT": {
        "id": "OLAT",
        "name": "OLATHE MFG."
    },
    "OLDK": {
        "id": "OLDK",
        "name": "OLDENKAMP INC. IOWA"
    },
    "OLDP": {
        "id": "OLDP",
        "name": "OLD PINE COMPANY NAMIQUIPA"
    },
    "OLDS": {
        "id": "OLDS",
        "name": "OLDSMOBILE"
    },
    "OLDT": {
        "id": "OLDT",
        "name": "OLDS TRAILS TRAILER"
    },
    "OLGE": {
        "id": "OLGE",
        "name": "OLGEN MFG."
    },
    "OLIV": {
        "id": "OLIV",
        "name": "OLIVER"
    },
    "OLMX": {
        "id": "OLMX",
        "name": "OLMAX FABRICATION"
    },
    "OLOW": {
        "id": "OLOW",
        "name": "OLSON OWLSEY ENTERPRISES"
    },
    "OLSO": {
        "id": "OLSO",
        "name": "OLSON"
    },
    "OLVR": {
        "id": "OLVR",
        "name": "OLIVER FIBERGLASS PRODUCTS HOHENWALD"
    },
    "OLYM": {
        "id": "OLYM",
        "name": "OLYMPIA MOTOR HOME"
    },
    "OLYP": {
        "id": "OLYP",
        "name": "OLYMPIC TRAILERS"
    },
    "OLYT": {
        "id": "OLYT",
        "name": "OLYMPUS TECHNOLOGIES INC"
    },
    "OMAH": {
        "id": "OMAH",
        "name": "OMAHA STANDARD TRAILERS"
    },
    "OMAS": {
        "id": "OMAS",
        "name": "OMAHA TANK AND EQUIPMENT"
    },
    "OMCH": {
        "id": "OMCH",
        "name": "BOAT TRAILERS"
    },
    "OMCI": {
        "id": "OMCI",
        "name": "OMICRON  MINIBIKES"
    },
    "OMCJ": {
        "id": "OMCJ",
        "name": "OMC JOHNSONBOAT TRAILERS WAUKEGAN"
    },
    "OMEG": {
        "id": "OMEG",
        "name": "OMEGA"
    },
    "OMGA": {
        "id": "OMGA",
        "name": "OMEGA RV"
    },
    "OMJC": {
        "id": "OMJC",
        "name": "OMJC SIGNAL INC WATERLOO IA"
    },
    "OMMH": {
        "id": "OMMH",
        "name": "OAK MANOR MANUFACTURED HOME"
    },
    "OMNG": {
        "id": "OMNG",
        "name": "OMING MOTORCYCLE"
    },
    "OMNI": {
        "id": "OMNI",
        "name": "OMNI MOTOR SPORTS"
    },
    "OMST": {
        "id": "OMST",
        "name": "OMSTEEL PRODUCTS CORP."
    },
    "OMVS": {
        "id": "OMVS",
        "name": "OFFICINE MECCANICHE VICARIO SPA ITALY"
    },
    "ONAN": {
        "id": "ONAN",
        "name": "ONAN CORP.MINNEAPOLIS"
    },
    "ONEA": {
        "id": "ONEA",
        "name": "O'NEAL TRAILERS"
    },
    "ONEI": {
        "id": "ONEI",
        "name": "ONEIDA COACH MFG.GRAND LEDGE"
    },
    "ONGB": {
        "id": "ONGB",
        "name": "ORANGE BLOSSOM TRAILERS"
    },
    "ONNE": {
        "id": "ONNE",
        "name": "ONNEN TRAILERS TANKER TRAILERS"
    },
    "ONOI": {
        "id": "ONOI",
        "name": "ONO"
    },
    "ONRD": {
        "id": "ONRD",
        "name": "ON THE ROAD"
    },
    "ONST": {
        "id": "ONST",
        "name": "ONE STOP TRAILER FONTANA"
    },
    "ONTA": {
        "id": "ONTA",
        "name": "ONTARIO"
    },
    "ONTR": {
        "id": "ONTR",
        "name": "ORION BUS IND."
    },
    "ONYX": {
        "id": "ONYX",
        "name": "ONYX FLYER"
    },
    "OPAI": {
        "id": "OPAI",
        "name": "OPAI"
    },
    "OPAL": {
        "id": "OPAL",
        "name": "OPAL TRAILERS"
    },
    "OPED": {
        "id": "OPED",
        "name": "BOLENS MFD BY OUTDOOR POWER EQUIPMENT DIV.FMC"
    },
    "OPEL": {
        "id": "OPEL",
        "name": "OPEL IMPORTED BY BUICK"
    },
    "OPEN": {
        "id": "OPEN",
        "name": "OPEN ROAD INDUSTRIES"
    },
    "OPER": {
        "id": "OPER",
        "name": "OPEN ROADSTERS OF TEXAS"
    },
    "OPL": {
        "id": "OPL",
        "name": "OPEL"
    },
    "OPNR": {
        "id": "OPNR",
        "name": "OPEN ROAD CAMPERS"
    },
    "OPTI": {
        "id": "OPTI",
        "name": "OPTIMA INDUSTRIES"
    },
    "OPTM": {
        "id": "OPTM",
        "name": "OPTIMA BUS LLC WICHITA"
    },
    "OPUS": {
        "id": "OPUS",
        "name": "OPUS CAMPER"
    },
    "OQMM": {
        "id": "OQMM",
        "name": "OQUIRRH MOUNTAIN MANUFACTURING TOOELE"
    },
    "OQWK": {
        "id": "OQWK",
        "name": "OQUAWAK BOATS AND FABRICATION"
    },
    "ORBI": {
        "id": "ORBI",
        "name": "ORBIT INDUSTRIES"
    },
    "ORCH": {
        "id": "ORCH",
        "name": "ORCHARD HILL WELDING LLC MILLMONT"
    },
    "ORCO": {
        "id": "ORCO",
        "name": "ORCON INCUSTRIES"
    },
    "OREG": {
        "id": "OREG",
        "name": "MANGMFD. BY OREGON MFG."
    },
    "OREI": {
        "id": "OREI",
        "name": "OREION MOTORS"
    },
    "OREV": {
        "id": "OREV",
        "name": "ORANGE EV RIVERSIDE MO"
    },
    "ORIG": {
        "id": "ORIG",
        "name": "ORIGINAL EQUIPMENT MANUFACTURING"
    },
    "ORIO": {
        "id": "ORIO",
        "name": "ORIOLE TRAILER MFG."
    },
    "ORJO": {
        "id": "ORJO",
        "name": "JOURNEYER MFG BY OPEN ROAD RC COMPANY"
    },
    "ORLA": {
        "id": "ORLA",
        "name": "ORLANDO BOAR"
    },
    "ORLI": {
        "id": "ORLI",
        "name": "LIGHT MFG BY OPEN ROAD RV COMPANY"
    },
    "ORLN": {
        "id": "ORLN",
        "name": "ORLAND MANUFACTURING"
    },
    "ORMR": {
        "id": "ORMR",
        "name": "MESA RIDGE MFG BY OPEN ROAD RV COMPANY"
    },
    "ORMW": {
        "id": "ORMW",
        "name": "OREGON MACHINE WORKS  CANBY"
    },
    "OROR": {
        "id": "OROR",
        "name": "OPEN RANGE MFG BY OPEN ROAD RV COMPANY"
    },
    "ORRE": {
        "id": "ORRE",
        "name": "RESIDENTIAL MFG BY OPEN ROAD RV COMPANY"
    },
    "ORRO": {
        "id": "ORRO",
        "name": "ROAMER MFG BY OPEN ROAD RV COMPANY"
    },
    "ORRV": {
        "id": "ORRV",
        "name": "OPEN RANG RV COMPANY INDIANA"
    },
    "ORST": {
        "id": "ORST",
        "name": "ORST TRAILERS"
    },
    "ORTH": {
        "id": "ORTH",
        "name": "ORTHMAN MFG."
    },
    "ORTL": {
        "id": "ORTL",
        "name": "OREGON TRAILER"
    },
    "ORVM": {
        "id": "ORVM",
        "name": "OUTDOORS RV MANUFACTURING"
    },
    "OSAG": {
        "id": "OSAG",
        "name": "OSAGE TRAILER MFG."
    },
    "OSBO": {
        "id": "OSBO",
        "name": "C. D. OSBORN & SON GRAND RAPIDS MI"
    },
    "OSCA": {
        "id": "OSCA",
        "name": "OSCA"
    },
    "OSHK": {
        "id": "OSHK",
        "name": "OSHKOSH MOTOR TRUCK"
    },
    "OSI": {
        "id": "OSI",
        "name": "OSI"
    },
    "OSMC": {
        "id": "OSMC",
        "name": "OLD SCHOOL MOTORCYCLE COMPANY LLC PHOENIX AZ"
    },
    "OSSA": {
        "id": "OSSA",
        "name": "OSSA SPAIN"
    },
    "OSTD": {
        "id": "OSTD",
        "name": "OSHKOSH TRAILER DIVISION BRADENTON"
    },
    "OSWE": {
        "id": "OSWE",
        "name": "OSW EQUIPMENT & REPAIR"
    },
    "OTAS": {
        "id": "OTAS",
        "name": "OTASCO"
    },
    "OTLW": {
        "id": "OTLW",
        "name": "OUTLAW TRAILER"
    },
    "OTOS": {
        "id": "OTOS",
        "name": "OTOSAN"
    },
    "OTPE": {
        "id": "OTPE",
        "name": "OUTDOOR POWER EQUIPMENT"
    },
    "OTPL": {
        "id": "OTPL",
        "name": "PLATINUM BRAND MFG BY OUTLAW TRAILER CONVERSIONS LP"
    },
    "OTTE": {
        "id": "OTTE",
        "name": "OTTERBACHER MFG."
    },
    "OTTI": {
        "id": "OTTI",
        "name": "OLIVER TRAVEL TRAILERS INC HOHENWALD TN"
    },
    "OTTW": {
        "id": "OTTW",
        "name": "TWISTER BRAND MFG BY OUTLAW TRAILER CONVERSIONS LP"
    },
    "OTWA": {
        "id": "OTWA",
        "name": "OTTAWA TRUCK"
    },
    "OUTB": {
        "id": "OUTB",
        "name": "OUTBACK SPECIALTIES"
    },
    "OUTD": {
        "id": "OUTD",
        "name": "OUTDOOR EQUIPMENT"
    },
    "OUTK": {
        "id": "OUTK",
        "name": "OUTKAST KUSTOM CYCLES INC"
    },
    "OUTL": {
        "id": "OUTL",
        "name": "OUTLAW MFG BY THOR MOTOR COACH INC."
    },
    "OUTM": {
        "id": "OUTM",
        "name": "EVINRUDE SEE OUTBOARD MARINE CORPORATION"
    },
    "OVBL": {
        "id": "OVBL",
        "name": "OVERBUILT INC"
    },
    "OVBT": {
        "id": "OVBT",
        "name": "OVERBILT TRAILER COMPANY DRUMRIGHT"
    },
    "OVEL": {
        "id": "OVEL",
        "name": "OVERLAND MFG."
    },
    "OVER": {
        "id": "OVER",
        "name": "OVERLAND"
    },
    "OVET": {
        "id": "OVET",
        "name": "OVERLAND TANK"
    },
    "OVRL": {
        "id": "OVRL",
        "name": "OVER-LOWE"
    },
    "OWAT": {
        "id": "OWAT",
        "name": "OWATONNA MANUFACTURING"
    },
    "OWED": {
        "id": "OWED",
        "name": "OWEN DIV."
    },
    "OWES": {
        "id": "OWES",
        "name": "MIGHTY MIDGETMFD. BY OWENS-CLASSIC"
    },
    "OWNA": {
        "id": "OWNA",
        "name": "OWNAHOME"
    },
    "OWNG": {
        "id": "OWNG",
        "name": "OWENS CARGO"
    },
    "OWNS": {
        "id": "OWNS",
        "name": "OWENS MFG."
    },
    "OWNT": {
        "id": "OWNT",
        "name": "OWEN TRAILERS"
    },
    "OWSM": {
        "id": "OWSM",
        "name": "OWENS & SONS MARINE"
    },
    "OXIN": {
        "id": "OXIN",
        "name": "OX INDUSTRIAL"
    },
    "OYMP": {
        "id": "OYMP",
        "name": "OLYMPIC TRAILER MANUFACTURING"
    },
    "OZAR": {
        "id": "OZAR",
        "name": "OZARK TRAILER & MOBILE HOMES"
    },
    "OZBK": {
        "id": "OZBK",
        "name": "OZBIKE"
    },
    "OZTK": {
        "id": "OZTK",
        "name": "OZ-TRIKES PTY LTD"
    },
    "PACB": {
        "id": "PACB",
        "name": "PACEMAKER BOAT TRAILER"
    },
    "PACC": {
        "id": "PACC",
        "name": "PACEMAKER MOBILE HOMES & TRAVEL TRAILERDIV. LONERGAN CORPORATION"
    },
    "PACE": {
        "id": "PACE",
        "name": "PACEMAKER"
    },
    "PACI": {
        "id": "PACI",
        "name": "PACIFIC CAMPERS"
    },
    "PACK": {
        "id": "PACK",
        "name": "PACKARD"
    },
    "PACS": {
        "id": "PACS",
        "name": "PACESETTER"
    },
    "PACW": {
        "id": "PACW",
        "name": "PAC WEST COMPANY NORTH HIGHLANDS"
    },
    "PADA": {
        "id": "PADA",
        "name": "PANDA MOTOR HOME"
    },
    "PADG": {
        "id": "PADG",
        "name": "PADGETT"
    },
    "PADK": {
        "id": "PADK",
        "name": "PADDLE KING"
    },
    "PAEN": {
        "id": "PAEN",
        "name": "PAGE ENGINEERING"
    },
    "PAGE": {
        "id": "PAGE",
        "name": "PAGE TRAILER"
    },
    "PAGN": {
        "id": "PAGN",
        "name": "PAGANI AUTOMOBILI SPA ITALY PASSENGER CARS"
    },
    "PAGO": {
        "id": "PAGO",
        "name": "PAGOTA POWER TECHNOLOGY"
    },
    "PAGS": {
        "id": "PAGS",
        "name": "PAGSTA MOTORCYCLES"
    },
    "PAIN": {
        "id": "PAIN",
        "name": "PACCAR INCORPORATED BELLVUE"
    },
    "PAIO": {
        "id": "PAIO",
        "name": "PAIOLI"
    },
    "PAIS": {
        "id": "PAIS",
        "name": "PARIS"
    },
    "PAIU": {
        "id": "PAIU",
        "name": "PAIUTE TRAILERS"
    },
    "PAKH": {
        "id": "PAKH",
        "name": "PARKHURST MFG."
    },
    "PAKM": {
        "id": "PAKM",
        "name": "PAK-MOR MFG."
    },
    "PALC": {
        "id": "PALC",
        "name": "PAL MFG."
    },
    "PALE": {
        "id": "PALE",
        "name": "PALACE CORP."
    },
    "PALH": {
        "id": "PALH",
        "name": "PALM HARBOR HOMES TEXAS"
    },
    "PALL": {
        "id": "PALL",
        "name": "PALLISER"
    },
    "PALM": {
        "id": "PALM",
        "name": "PALM TRAILERS"
    },
    "PALN": {
        "id": "PALN",
        "name": "PALMETTO SALES OF LAURENS"
    },
    "PALO": {
        "id": "PALO",
        "name": "PALOMINO CAMPING TRAILER"
    },
    "PALW": {
        "id": "PALW",
        "name": "PALMER MACHINE WORKS"
    },
    "PAMA": {
        "id": "PAMA",
        "name": "PAMA CAMPER"
    },
    "PAMC": {
        "id": "PAMC",
        "name": "PAM"
    },
    "PAMM": {
        "id": "PAMM",
        "name": "PAN AMERICAN MANUFACTURING INC BELTON TX"
    },
    "PAMR": {
        "id": "PAMR",
        "name": "PACE AMERICAN"
    },
    "PAMU": {
        "id": "PAMU",
        "name": "PACE AMERICAN OF UTAH"
    },
    "PANA": {
        "id": "PANA",
        "name": "PAN AMERICAN MOBILE HOMES DIV OF DIVCO-WAYNE INDUSTRIES"
    },
    "PAND": {
        "id": "PAND",
        "name": "PANDA MOTOR SPORTS NORTH AMERICA"
    },
    "PANE": {
        "id": "PANE",
        "name": "PANTHER WESTWINDS"
    },
    "PANH": {
        "id": "PANH",
        "name": "PANHARD"
    },
    "PANN": {
        "id": "PANN",
        "name": "PANNONIA"
    },
    "PANO": {
        "id": "PANO",
        "name": "PANORAMA HOMES"
    },
    "PANT": {
        "id": "PANT",
        "name": "PANTHER"
    },
    "PANZ": {
        "id": "PANZ",
        "name": "PANOZ AUTO DEVELOPMENT"
    },
    "PAPV": {
        "id": "PAPV",
        "name": "PARKVIEW RV MFG BY PALM HARBOR HOMES"
    },
    "PARC": {
        "id": "PARC",
        "name": "PARIS TRAILER"
    },
    "PARD": {
        "id": "PARD",
        "name": "PARDONNET MFG."
    },
    "PARE": {
        "id": "PARE",
        "name": "PARK ESTATES HOMES"
    },
    "PARH": {
        "id": "PARH",
        "name": "BUNKHOUSE BRAND OF SIESTA MFG.BY PARK HOMES"
    },
    "PARI": {
        "id": "PARI",
        "name": "PARILLA"
    },
    "PARK": {
        "id": "PARK",
        "name": "PARK LANE MOBILE HOMES"
    },
    "PARL": {
        "id": "PARL",
        "name": "PARK ROYAL"
    },
    "PARM": {
        "id": "PARM",
        "name": "PARMITER"
    },
    "PARN": {
        "id": "PARN",
        "name": "PAR NADO"
    },
    "PARO": {
        "id": "PARO",
        "name": "PACE ARROW"
    },
    "PARP": {
        "id": "PARP",
        "name": "PARK TRAILER CORP."
    },
    "PARR": {
        "id": "PARR",
        "name": "PARKER"
    },
    "PARS": {
        "id": "PARS",
        "name": "PARKMASTERDIV. AMERICAN STERLING ENTERPRISES"
    },
    "PART": {
        "id": "PART",
        "name": "PART GENERIC CODE FOR USE ONLY WHEN MANUFACTURER IS NOT LISTED"
    },
    "PARU": {
        "id": "PARU",
        "name": "PARKWAY MFG."
    },
    "PARW": {
        "id": "PARW",
        "name": "PARKWOOD HOMES"
    },
    "PARX": {
        "id": "PARX",
        "name": "PARKWOOD MOBILE HOMES OF FLORIDA"
    },
    "PASI": {
        "id": "PASI",
        "name": "PERFECTION ARCHITECTURAL SYSTEMS"
    },
    "PASO": {
        "id": "PASO",
        "name": "PARSON"
    },
    "PASQ": {
        "id": "PASQ",
        "name": "PASQUALI"
    },
    "PASS": {
        "id": "PASS",
        "name": "PASSPORT"
    },
    "PAST": {
        "id": "PAST",
        "name": "PASTIME MFG."
    },
    "PATH": {
        "id": "PATH",
        "name": "PATHFINDER MOBILE HOMES"
    },
    "PATR": {
        "id": "PATR",
        "name": "PATRIOT MOTORCYCLE CORP."
    },
    "PATT": {
        "id": "PATT",
        "name": "PATTERSON WELDING"
    },
    "PATZ": {
        "id": "PATZ",
        "name": "PATZ"
    },
    "PAUG": {
        "id": "PAUG",
        "name": "PAUGHCO"
    },
    "PAUL": {
        "id": "PAUL",
        "name": "PAULI COOLING SYSTEMS"
    },
    "PAUS": {
        "id": "PAUS",
        "name": "HERMANN PAUS MASCHINENFABRI K GMBH GERMANY"
    },
    "PBCC": {
        "id": "PBCC",
        "name": "PARKER BROTHERS CUSTOM CHOPPERS MELBOURNE FL"
    },
    "PBCS": {
        "id": "PBCS",
        "name": "PHIL BORRIELLO CHOPPER SHOP"
    },
    "PBKA": {
        "id": "PBKA",
        "name": "PBK AUTOMOTIVE LLC NEWBURGH IN"
    },
    "PBLT": {
        "id": "PBLT",
        "name": "PROBILT BODY AND TRAILER INC GLADE SPRING VIRGINIA"
    },
    "PBLY": {
        "id": "PBLY",
        "name": "PISTEN BULLY"
    },
    "PBMS": {
        "id": "PBMS",
        "name": "PBM SUPPLY & MANUFACTURING INC CHI"
    },
    "PBTI": {
        "id": "PBTI",
        "name": "PACIFIC BOAT TRAILERS"
    },
    "PCCW": {
        "id": "PCCW",
        "name": "PACIFIC COACHWORKS"
    },
    "PCCY": {
        "id": "PCCY",
        "name": "PARAMOUNT CUSTOM CYCLES"
    },
    "PCFC": {
        "id": "PCFC",
        "name": "PACIFIC MANUFACTURING CORPORATION - ROSEBURG"
    },
    "PCFW": {
        "id": "PCFW",
        "name": "PACIFIC WEST TRAILERS SAN JOSE"
    },
    "PCIM": {
        "id": "PCIM",
        "name": "PCI MANUFACTURING"
    },
    "PCIN": {
        "id": "PCIN",
        "name": "PC INDUSTRIES"
    },
    "PCM": {
        "id": "PCM",
        "name": "PCM"
    },
    "PCPL": {
        "id": "PCPL",
        "name": "PATRIOT CAMPERS PTY"
    },
    "PCTI": {
        "id": "PCTI",
        "name": "PAROS CUSTOM TRAILER INC."
    },
    "PCTK": {
        "id": "PCTK",
        "name": "PACIFIC TEK ANAHEIM CA"
    },
    "PCTL": {
        "id": "PCTL",
        "name": "PARIS CUSTOM TRAILER"
    },
    "PCUT": {
        "id": "PCUT",
        "name": "PERFORMANCE CUSTOM TRAILERS"
    },
    "PDRS": {
        "id": "PDRS",
        "name": "PEDERSON"
    },
    "PDV": {
        "id": "PDV",
        "name": "PDV"
    },
    "PEAB": {
        "id": "PEAB",
        "name": "PEABODY SOLID WASTE MANAGEMENT"
    },
    "PEAC": {
        "id": "PEAC",
        "name": "PEACE GROUP INDUSTRY MOTOR SCOOTERS"
    },
    "PEAG": {
        "id": "PEAG",
        "name": "PEACH CARGO"
    },
    "PEAK": {
        "id": "PEAK",
        "name": "PEAK MANUFACTURING"
    },
    "PEAR": {
        "id": "PEAR",
        "name": "BEN PEARSON MFG."
    },
    "PECO": {
        "id": "PECO",
        "name": "POWER & ELECTRIC"
    },
    "PECT": {
        "id": "PECT",
        "name": "PENNSTYLE CAMPERS"
    },
    "PEDA": {
        "id": "PEDA",
        "name": "PEDALPOWER ELECTROPED"
    },
    "PEDD": {
        "id": "PEDD",
        "name": "PEDDLERS CHOICE"
    },
    "PEEK": {
        "id": "PEEK",
        "name": "PEEK"
    },
    "PEEL": {
        "id": "PEEL",
        "name": "PEEL"
    },
    "PEER": {
        "id": "PEER",
        "name": "PEERLESS"
    },
    "PEGA": {
        "id": "PEGA",
        "name": "PEGASO"
    },
    "PELI": {
        "id": "PELI",
        "name": "PELICAN ALUMINUM MFG."
    },
    "PELS": {
        "id": "PELS",
        "name": "PELSUE ENGLEWOOD"
    },
    "PELT": {
        "id": "PELT",
        "name": "P.M.I."
    },
    "PEMB": {
        "id": "PEMB",
        "name": "PEMBERTON FABRICATORS"
    },
    "PEMC": {
        "id": "PEMC",
        "name": "PENNSBURY MANUFACTURING CORPBENSALEM"
    },
    "PEMF": {
        "id": "PEMF",
        "name": "PEMFAB TRUCKS RANCOCAS"
    },
    "PEMM": {
        "id": "PEMM",
        "name": "P.E.M. MOTORCYCLE"
    },
    "PENC": {
        "id": "PENC",
        "name": "PENN"
    },
    "PEND": {
        "id": "PEND",
        "name": "PENDLAY"
    },
    "PENF": {
        "id": "PENF",
        "name": "PENN METAL FABRICATORS EBENSBURG"
    },
    "PENI": {
        "id": "PENI",
        "name": "PENINSULA CAMPER MFG."
    },
    "PENN": {
        "id": "PENN",
        "name": "J. C. PENNEY"
    },
    "PENS": {
        "id": "PENS",
        "name": "PENNSYLVANIA FURNACE AND IRONMFRS. TANKERS"
    },
    "PENT": {
        "id": "PENT",
        "name": "PENTON"
    },
    "PENU": {
        "id": "PENU",
        "name": "PENN-CUPIT INDUSTRIES LTD."
    },
    "PEOR": {
        "id": "PEOR",
        "name": "PEORIA CUSTOM COOKERS PEORIA"
    },
    "PEQU": {
        "id": "PEQU",
        "name": "PEQUA GORDONVILLE PENNSYLVANIA"
    },
    "PERC": {
        "id": "PERC",
        "name": "I.B. PERCH"
    },
    "PERF": {
        "id": "PERF",
        "name": "PERFORMANCE PRODUCTS"
    },
    "PERL": {
        "id": "PERL",
        "name": "PEERLESS DIVISION DIV OF LEAR SIEGLER TUALATIN"
    },
    "PERM": {
        "id": "PERM",
        "name": "PERMA TENT CAMPER"
    },
    "PERO": {
        "id": "PERO",
        "name": "PERONE TRAILER"
    },
    "PERR": {
        "id": "PERR",
        "name": "PERRIS VALLEY CAMPERS"
    },
    "PERS": {
        "id": "PERS",
        "name": "PEERLESS INTERNATIONAL"
    },
    "PETB": {
        "id": "PETB",
        "name": "PETER BERGEN INDUASTRIES INC OF CANADA _TRAILERS - ADDED"
    },
    "PETE": {
        "id": "PETE",
        "name": "PETERSON INDUSTRIES"
    },
    "PETM": {
        "id": "PETM",
        "name": "PETTIBONE MICHIGAN CORP."
    },
    "PETR": {
        "id": "PETR",
        "name": "PETER PIRSCH & SONS"
    },
    "PETT": {
        "id": "PETT",
        "name": "PETTIBONE MERCURY"
    },
    "PETY": {
        "id": "PETY",
        "name": "PETTY FARM TRAILERS"
    },
    "PEUG": {
        "id": "PEUG",
        "name": "PEUGEOT"
    },
    "PEZZ": {
        "id": "PEZZ",
        "name": "PEZZAIOLI"
    },
    "PFAB": {
        "id": "PFAB",
        "name": "PRO FAB IND."
    },
    "PFAP": {
        "id": "PFAP",
        "name": "PRECISION FIRE APPARATUS CANDENTON"
    },
    "PFAU": {
        "id": "PFAU",
        "name": "PFAUDLER"
    },
    "PFBM": {
        "id": "PFBM",
        "name": "PROFAB MEX S.A.DE C.V. CHIHUAHUA"
    },
    "PFMX": {
        "id": "PFMX",
        "name": "PERFORMAX WYLIE"
    },
    "PFUS": {
        "id": "PFUS",
        "name": "PRO FAB US CUAUHTEMOC CHIHUAHUA MEXICO"
    },
    "PGAS": {
        "id": "PGAS",
        "name": "PEGASUS VANS & TRAILERS"
    },
    "PGDT": {
        "id": "PGDT",
        "name": "PAIGE DETROIT MOTOR CAR COMPANY DETROIT MI"
    },
    "PGRM": {
        "id": "PGRM",
        "name": "PILGRAM INTERNATIONAL"
    },
    "PGSM": {
        "id": "PGSM",
        "name": "PIGGYBACK SMOKERS LLC BELLS TN"
    },
    "PGSS": {
        "id": "PGSS",
        "name": "PEAGUSAS"
    },
    "PHAA": {
        "id": "PHAA",
        "name": "P&H"
    },
    "PHAE": {
        "id": "PHAE",
        "name": "PHAETON MOTORHOME MFG BY TIFFIN MOTORHOMES"
    },
    "PHEL": {
        "id": "PHEL",
        "name": "PHELAN MFG."
    },
    "PHEP": {
        "id": "PHEP",
        "name": "PHELPS HORSE TRAILER"
    },
    "PHII": {
        "id": "PHII",
        "name": "PHILLIPS TRAILERSCHICKASHA"
    },
    "PHIL": {
        "id": "PHIL",
        "name": "PHILL TRAILER"
    },
    "PHIM": {
        "id": "PHIM",
        "name": "PHILLIPS MFG."
    },
    "PHLI": {
        "id": "PHLI",
        "name": "PHILIFT EQUIPMENT SALES"
    },
    "PHNX": {
        "id": "PHNX",
        "name": "PHOENIX USA INC PHOENIX CRUISER ELKHART INDIANA"
    },
    "PHOE": {
        "id": "PHOE",
        "name": "PHOENIX CAMPING TRAILER"
    },
    "PHTM": {
        "id": "PHTM",
        "name": "PHANTOM TRIKES BRIDGEPORT"
    },
    "PHTW": {
        "id": "PHTW",
        "name": "PHOENIX TRIKE WORKS MESA"
    },
    "PHXT": {
        "id": "PHXT",
        "name": "PHOENIX TRAILERS"
    },
    "PIAG": {
        "id": "PIAG",
        "name": "PIAGGIO GROUP AMERICAS INC"
    },
    "PICK": {
        "id": "PICK",
        "name": "PICK-UP TOP MFG."
    },
    "PICW": {
        "id": "PICW",
        "name": "PICKWICK"
    },
    "PIED": {
        "id": "PIED",
        "name": "PIEDMONT DIV OF CONCORD MOBILE HOMES"
    },
    "PIER": {
        "id": "PIER",
        "name": "PIERCE LOWBOY TRAILER"
    },
    "PIKE": {
        "id": "PIKE",
        "name": "PIKE TRAILER COSOUTH GATE"
    },
    "PIKR": {
        "id": "PIKR",
        "name": "PIK RITE"
    },
    "PILG": {
        "id": "PILG",
        "name": "PILGRIM MFG."
    },
    "PILT": {
        "id": "PILT",
        "name": "PILOT TRAILERS FITZGERALD"
    },
    "PINC": {
        "id": "PINC",
        "name": "PINCOR PRODUCTS"
    },
    "PINE": {
        "id": "PINE",
        "name": "PINES TRAILER CORP CHICAGO"
    },
    "PINI": {
        "id": "PINI",
        "name": "PINIFARINA"
    },
    "PINS": {
        "id": "PINS",
        "name": "PINSON TRUCK EQUIPMENT"
    },
    "PINT": {
        "id": "PINT",
        "name": "PINTO"
    },
    "PIOE": {
        "id": "PIOE",
        "name": "PIONEER COACH MFG."
    },
    "PION": {
        "id": "PION",
        "name": "PIONEER SALES & MFG."
    },
    "PIOP": {
        "id": "PIOP",
        "name": "PIONEER PUMP"
    },
    "PIPE": {
        "id": "PIPE",
        "name": "PIPESTONE TANDEM BOAT TRAILER"
    },
    "PIPR": {
        "id": "PIPR",
        "name": "PIPER INDUSTRIES"
    },
    "PIQU": {
        "id": "PIQU",
        "name": "PIQUA ENGINEERING"
    },
    "PIRC": {
        "id": "PIRC",
        "name": "PIERCE MFG."
    },
    "PIST": {
        "id": "PIST",
        "name": "PITSTER PRO MINI DIRT BIKES & MOTORCYCLES"
    },
    "PITD": {
        "id": "PITD",
        "name": "PITMAN DIV."
    },
    "PITJ": {
        "id": "PITJ",
        "name": "PITS BY JJ HOUSTON"
    },
    "PITK": {
        "id": "PITK",
        "name": "PIONEER TRUCKWELD INC. SALEM"
    },
    "PITL": {
        "id": "PITL",
        "name": "PITTS ENTERPRISES"
    },
    "PITM": {
        "id": "PITM",
        "name": "PITMAN BROTHERS"
    },
    "PITN": {
        "id": "PITN",
        "name": "PITMAN DIV."
    },
    "PITT": {
        "id": "PITT",
        "name": "PITTSBURGH FORGING"
    },
    "PITZ": {
        "id": "PITZ",
        "name": "JOHN PITZER MFG."
    },
    "PIXI": {
        "id": "PIXI",
        "name": "PIXIE PIKER TRAVEL TRAILER"
    },
    "PJRD": {
        "id": "PJRD",
        "name": "PAUL JR DESIGNS"
    },
    "PJTM": {
        "id": "PJTM",
        "name": "P.J. TRAILER MANUFACTURING"
    },
    "PKLC": {
        "id": "PKLC",
        "name": "LINDSAY"
    },
    "PKTS": {
        "id": "PKTS",
        "name": "PARKER TRAILER SALES"
    },
    "PLAC": {
        "id": "PLAC",
        "name": "PLASTIC ENGS. & CONSULTANTS"
    },
    "PLAG": {
        "id": "PLAG",
        "name": "PLATTSBURG MFG."
    },
    "PLAI": {
        "id": "PLAI",
        "name": "PLAINS INDUSTRIES"
    },
    "PLAL": {
        "id": "PLAL",
        "name": "PLAY-MOR TRAILERS"
    },
    "PLAM": {
        "id": "PLAM",
        "name": "PLAYMATE COACHES"
    },
    "PLAN": {
        "id": "PLAN",
        "name": "PLANET PLOWS"
    },
    "PLAP": {
        "id": "PLAP",
        "name": "PLAY PAC - RV TRAILERS"
    },
    "PLAS": {
        "id": "PLAS",
        "name": "PLASTIC FORMING"
    },
    "PLAT": {
        "id": "PLAT",
        "name": "PLATT TRAILER"
    },
    "PLAY": {
        "id": "PLAY",
        "name": "PLAYBOY_AUTOMOBILE COMPANY"
    },
    "PLBT": {
        "id": "PLBT",
        "name": "PAULIBUILT"
    },
    "PLBY": {
        "id": "PLBY",
        "name": "PLAYBUOY PONTOON MFG."
    },
    "PLCB": {
        "id": "PLCB",
        "name": "PL CUSTOM BODY & EQUIPMENT"
    },
    "PLCL": {
        "id": "PLCL",
        "name": "PLATINUM COTTAGES LLC MALAKOFF TX"
    },
    "PLEA": {
        "id": "PLEA",
        "name": "PLEASURE HOMES MFG."
    },
    "PLEM": {
        "id": "PLEM",
        "name": "PLEASUREWAY MFG."
    },
    "PLES": {
        "id": "PLES",
        "name": "PLEASUREMATE INDUSTRIES"
    },
    "PLJY": {
        "id": "PLJY",
        "name": "POLYJOHN ENTERPRISES CORP TRAILERS"
    },
    "PLKG": {
        "id": "PLKG",
        "name": "POLAR KING INTERNATIONAL"
    },
    "PLMA": {
        "id": "PLMA",
        "name": "PALM AIRE"
    },
    "PLMB": {
        "id": "PLMB",
        "name": "PALM BEACH GOLF CARS"
    },
    "PLMC": {
        "id": "PLMC",
        "name": "PEERLESS MANUFACTURING COMPANY"
    },
    "PLML": {
        "id": "PLML",
        "name": "PAUL MUELLER COMPANY SPRINGFIELD"
    },
    "PLMM": {
        "id": "PLMM",
        "name": "PALMER MANUFACTURING"
    },
    "PLMO": {
        "id": "PLMO",
        "name": "PALOMINO MANUFACTURING CORPORATION"
    },
    "PLMT": {
        "id": "PLMT",
        "name": "PARLIAMENT COACH CORPORATION FLORIDA"
    },
    "PLOT": {
        "id": "PLOT",
        "name": "PLOT USA"
    },
    "PLRN": {
        "id": "PLRN",
        "name": "POLORON"
    },
    "PLRT": {
        "id": "PLRT",
        "name": "PULLRITE TRAILERS ONTARIO CANADA TRAILERS"
    },
    "PLTM": {
        "id": "PLTM",
        "name": "PLATINUM MANUFACTURING LLC OKLAHOMA CITY"
    },
    "PLTN": {
        "id": "PLTN",
        "name": "PLATINUM & PLATINUM II MFG BY COACH HOUSE INC MOTORHOMES"
    },
    "PLUT": {
        "id": "PLUT",
        "name": "PLUTCHAK FAB"
    },
    "PLYC": {
        "id": "PLYC",
        "name": "PLAYCAT INDUSTRIES"
    },
    "PLYJ": {
        "id": "PLYJ",
        "name": "POLYJOHN ENTERPRISES WHITING"
    },
    "PLYM": {
        "id": "PLYM",
        "name": "PLYMOUTH"
    },
    "PLYP": {
        "id": "PLYP",
        "name": "PLAY PEN PRODUCTS"
    },
    "PLZZ": {
        "id": "PLZZ",
        "name": "PALAZZO MFG BY THOR MOTOR COACH INC."
    },
    "PMAK": {
        "id": "PMAK",
        "name": "PITMAKER HOUSTON"
    },
    "PMMI": {
        "id": "PMMI",
        "name": "PARK MODELS MANUFACTURING"
    },
    "PMUS": {
        "id": "PMUS",
        "name": "PIONEER MOTORS USA"
    },
    "PNCC": {
        "id": "PNCC",
        "name": "PIONEER CYCLE CAR"
    },
    "PNCN": {
        "id": "PNCN",
        "name": "PINE CONE LOW SPEED"
    },
    "PNDA": {
        "id": "PNDA",
        "name": "PANDERRA ENTERPRISES INC OTHELLO WA"
    },
    "PNER": {
        "id": "PNER",
        "name": "PIONEER MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "PNHD": {
        "id": "PNHD",
        "name": "PANHANDLE TRAILERS IDAHO - TRAILERS"
    },
    "PNHL": {
        "id": "PNHL",
        "name": "PINE-HILL MANUFACTURING"
    },
    "PNNT": {
        "id": "PNNT",
        "name": "PENN TRAILER"
    },
    "PNTA": {
        "id": "PNTA",
        "name": "PANTERRA MOTORSCOOTER"
    },
    "PNZR": {
        "id": "PNZR",
        "name": "PANZER MOTORCYCLE WORKS"
    },
    "POCL": {
        "id": "POCL",
        "name": "POCLAIN DIV."
    },
    "POIE": {
        "id": "POIE",
        "name": "POINTER-WILLAMETTE"
    },
    "POIN": {
        "id": "POIN",
        "name": "POINTER"
    },
    "POIR": {
        "id": "POIR",
        "name": "POIRIER"
    },
    "POLA": {
        "id": "POLA",
        "name": "POLAR MFG."
    },
    "POLB": {
        "id": "POLB",
        "name": "RAYBON MANUFACTURING COMPANYWALLINGFORD"
    },
    "POLI": {
        "id": "POLI",
        "name": "POLINI MOTORCYCLES"
    },
    "POLL": {
        "id": "POLL",
        "name": "POLLOCK INDUSTRIES"
    },
    "POLO": {
        "id": "POLO",
        "name": "POLORON PRODUCTSMFRS. RIDING MOWERS--HARRISON"
    },
    "POLR": {
        "id": "POLR",
        "name": "POLORON HOMES OF PA."
    },
    "POLS": {
        "id": "POLS",
        "name": "E-Z-GO SEE POLARIS IND. INC ALSO E-Z GO AND TEXTRON"
    },
    "POLY": {
        "id": "POLY",
        "name": "POLY-COAT SYSTEMS"
    },
    "POND": {
        "id": "POND",
        "name": "PONDEROSA HOMES"
    },
    "PONE": {
        "id": "PONE",
        "name": "PONDEROSA INDUSTRIES"
    },
    "PONI": {
        "id": "PONI",
        "name": "PONTIAC"
    },
    "PONT": {
        "id": "PONT",
        "name": "PONTIAC"
    },
    "PONX": {
        "id": "PONX",
        "name": "PONY XPRESS"
    },
    "PONY": {
        "id": "PONY",
        "name": "PONYCYCLE"
    },
    "POOL": {
        "id": "POOL",
        "name": "POOLE"
    },
    "POPC": {
        "id": "POPC",
        "name": "POPCYCLE MOTORS"
    },
    "POPE": {
        "id": "POPE",
        "name": "POPE MANUFACTURING COMPANY"
    },
    "POR": {
        "id": "POR",
        "name": "POR TRAILER"
    },
    "PORB": {
        "id": "PORB",
        "name": "PORTABLE ELEVATOR MFG. DIV."
    },
    "PORC": {
        "id": "PORC",
        "name": "PIONEER DIV."
    },
    "PORD": {
        "id": "PORD",
        "name": "PORTA-DOCK"
    },
    "PORE": {
        "id": "PORE",
        "name": "PORTABLE STRUCTURES"
    },
    "PORK": {
        "id": "PORK",
        "name": "PORTA-KAMP MFG."
    },
    "PORL": {
        "id": "PORL",
        "name": "PORTLAND WIRE & IRON WORKS"
    },
    "PORS": {
        "id": "PORS",
        "name": "PORSCHE"
    },
    "PORT": {
        "id": "PORT",
        "name": "PORTA-BUILT INDUSTRIES"
    },
    "POTL": {
        "id": "POTL",
        "name": "PONTRAIL"
    },
    "POTT": {
        "id": "POTT",
        "name": "POLAR TANK TRAILER SPRINGFIELD"
    },
    "POWE": {
        "id": "POWE",
        "name": "POWERLINE PRODUCTS DIV OF WHITE OAK"
    },
    "POWL": {
        "id": "POWL",
        "name": "POWELL"
    },
    "POWM": {
        "id": "POWM",
        "name": "POWELL MFG."
    },
    "POWR": {
        "id": "POWR",
        "name": "POWER CURBER"
    },
    "PPAL": {
        "id": "PPAL",
        "name": "PORTAGE PAL TRAILER"
    },
    "PPLU": {
        "id": "PPLU",
        "name": "PREMIER PLUS ENTERPRISES OREGON TRAILERS"
    },
    "PPNP": {
        "id": "PPNP",
        "name": "PREMIER PUMP AND POWER LLC VANCOUVER WA"
    },
    "PPOD": {
        "id": "PPOD",
        "name": "PARTY PODS"
    },
    "PPRF": {
        "id": "PPRF",
        "name": "PRO-PERFORMANCE MANUFACTURING"
    },
    "PPSP": {
        "id": "PPSP",
        "name": "PARADISE POWER SPORTS"
    },
    "PRAE": {
        "id": "PRAE",
        "name": "PRAIRIE TRAILERS SALES & SERVICE"
    },
    "PRAI": {
        "id": "PRAI",
        "name": "PRAIRIE SCHOONER"
    },
    "PRAM": {
        "id": "PRAM",
        "name": "PRESTON AMUSEMENTS"
    },
    "PRAR": {
        "id": "PRAR",
        "name": "PRARIE SCHOONER"
    },
    "PRAT": {
        "id": "PRAT",
        "name": "PRATT INDUSTRIES"
    },
    "PRBW": {
        "id": "PRBW",
        "name": "PRECISION BOATWORKS SITKA"
    },
    "PRCA": {
        "id": "PRCA",
        "name": "PIERCE ARROW"
    },
    "PRCG": {
        "id": "PRCG",
        "name": "PRECISION CARGO LLC DOUGLAS GA"
    },
    "PRCW": {
        "id": "PRCW",
        "name": "PRECISION CYCLE WORKS CARO"
    },
    "PRDE": {
        "id": "PRDE",
        "name": "PRIDE HEAVY VEHICLE IND PRIDE ENTERPRISES _"
    },
    "PREB": {
        "id": "PREB",
        "name": "PREBUILT MFG."
    },
    "PREC": {
        "id": "PREC",
        "name": "KEEL-HAULER MFD BY PRECISION DEBURRING & FABRICATING"
    },
    "PREI": {
        "id": "PREI",
        "name": "PRE-BUILT STRUCTURES"
    },
    "PREM": {
        "id": "PREM",
        "name": "PREMIER TRAILER"
    },
    "PREO": {
        "id": "PREO",
        "name": "PREVOST CAR"
    },
    "PREQ": {
        "id": "PREQ",
        "name": "PRECISION EQUIPMENT MANUFACTURING"
    },
    "PRES": {
        "id": "PRES",
        "name": "PRESIDENTIAL MOBILE HOMESDIV. U. S. ALUMINUM COMPANY"
    },
    "PREV": {
        "id": "PREV",
        "name": "PRESVAC SYSTEMS"
    },
    "PREW": {
        "id": "PREW",
        "name": "PREW PRO"
    },
    "PRGT": {
        "id": "PRGT",
        "name": "PROGRESSIVE TANK ARTHUR"
    },
    "PRGV": {
        "id": "PRGV",
        "name": "PROGRESSIVE INDUSTRIES OR PROGRESSIVE TRAILERS PHOENIZ"
    },
    "PRHL": {
        "id": "PRHL",
        "name": "PRO HAULER TRAILER INC. SOUTH CAROLINA"
    },
    "PRHT": {
        "id": "PRHT",
        "name": "PARKHURST"
    },
    "PRIB": {
        "id": "PRIB",
        "name": "PRIBBS STEEL & MFG."
    },
    "PRIC": {
        "id": "PRIC",
        "name": "ELKTON MFD BY PRICE-MEYERS CORP."
    },
    "PRIE": {
        "id": "PRIE",
        "name": "PRIEFERT MFG"
    },
    "PRIJ": {
        "id": "PRIJ",
        "name": "PRIDE & JOY MINI MOTOR HOME"
    },
    "PRIM": {
        "id": "PRIM",
        "name": "PRIME MOVER"
    },
    "PRIN": {
        "id": "PRIN",
        "name": "PRINCESS HOMES"
    },
    "PRIO": {
        "id": "PRIO",
        "name": "PRIOR PRODUCTS"
    },
    "PRIT": {
        "id": "PRIT",
        "name": "PRIME TIME MANUFACTURING"
    },
    "PRIV": {
        "id": "PRIV",
        "name": "PRIVATE COACHDIV. F & L CONSTRUCTION"
    },
    "PRKA": {
        "id": "PRKA",
        "name": "PINES TRAILER CORP. PINE GROVE PENNSYLVANIA"
    },
    "PRKL": {
        "id": "PRKL",
        "name": "PARK LINER"
    },
    "PRKN": {
        "id": "PRKN",
        "name": "PAR-KAN COMPANY SILVER LAKE"
    },
    "PRLP": {
        "id": "PRLP",
        "name": "PROLINE PRODUCTS"
    },
    "PRLT": {
        "id": "PRLT",
        "name": "PRO LITE TRAILERS PECULIAR"
    },
    "PRMA": {
        "id": "PRMA",
        "name": "PARMA COMPANY PARMA"
    },
    "PRMC": {
        "id": "PRMC",
        "name": "PREMCO PRODUCTS"
    },
    "PRME": {
        "id": "PRME",
        "name": "PRIME TRAILER PRODUCTS MCKINNEY"
    },
    "PRMO": {
        "id": "PRMO",
        "name": "PRINCE MOTORS"
    },
    "PRMT": {
        "id": "PRMT",
        "name": "PARAMOUNT TANK"
    },
    "PRNC": {
        "id": "PRNC",
        "name": "PRINCETON DELIVERY SYSTEMS"
    },
    "PRNE": {
        "id": "PRNE",
        "name": "PRO ONE MOTORCYCLES & PARTS"
    },
    "PRNW": {
        "id": "PRNW",
        "name": "PREMIER NORTHWEST LLC HERMISTON OR"
    },
    "PROC": {
        "id": "PROC",
        "name": "PROCONESSIONS TRAILERS AUGUSTA"
    },
    "PROF": {
        "id": "PROF",
        "name": "PROFORM TRAILERS INC."
    },
    "PROG": {
        "id": "PROG",
        "name": "PROGRESS"
    },
    "PROL": {
        "id": "PROL",
        "name": "PRO LINE TRAILERS COCOA"
    },
    "PROM": {
        "id": "PROM",
        "name": "PROMARK PRODUCTS CORP."
    },
    "PRON": {
        "id": "PRON",
        "name": "LES MACHINERIES PRONOVOST"
    },
    "PROP": {
        "id": "PROP",
        "name": "PROPER CHOPPER MOTORCYCLES"
    },
    "PROT": {
        "id": "PROT",
        "name": "PRO-TRAIL"
    },
    "PROW": {
        "id": "PROW",
        "name": "PROWLER INDUSTRIES"
    },
    "PRPC": {
        "id": "PRPC",
        "name": "PURPLE CHOPPER"
    },
    "PRPU": {
        "id": "PRPU",
        "name": "PRO PULL"
    },
    "PRPW": {
        "id": "PRPW",
        "name": "PRECESION POWERSPORTS DISCOVERY LINE OF UTILITY TASK VEHICLES"
    },
    "PRRD": {
        "id": "PRRD",
        "name": "PRORIDE TRAILERS"
    },
    "PRST": {
        "id": "PRST",
        "name": "PRESTIGE CUSTOM TRAILERS CHRISTOPHER ILLINOIS"
    },
    "PRTA": {
        "id": "PRTA",
        "name": "PROTERRA INC GOLDEN"
    },
    "PRTF": {
        "id": "PRTF",
        "name": "PRO-TECH FABRICATORS"
    },
    "PRTG": {
        "id": "PRTG",
        "name": "PRESTIGE HOUSING CONWAY ARKANSAS"
    },
    "PRTH": {
        "id": "PRTH",
        "name": "PRATHER ENGINEERING"
    },
    "PRTL": {
        "id": "PRTL",
        "name": "PORT CITY TRAILERS INC MOBILE"
    },
    "PRTN": {
        "id": "PRTN",
        "name": "PROTAINER"
    },
    "PRTR": {
        "id": "PRTR",
        "name": "PORTER MANUFACTURING CORP."
    },
    "PRTS": {
        "id": "PRTS",
        "name": "PROTECTION SERVICES"
    },
    "PRTT": {
        "id": "PRTT",
        "name": "PORT TRAILER MFG."
    },
    "PRVN": {
        "id": "PRVN",
        "name": "PROVAN INDUSTRIES"
    },
    "PRWL": {
        "id": "PRWL",
        "name": "PROWLER"
    },
    "PRYE": {
        "id": "PRYE",
        "name": "PRYER INDUSTRIES"
    },
    "PSCC": {
        "id": "PSCC",
        "name": "PURE STEEL CUSTOM CYCLES"
    },
    "PSFW": {
        "id": "PSFW",
        "name": "PRO-STREET FRAMEWORKS"
    },
    "PSNO": {
        "id": "PSNO",
        "name": "EL PAISANO S.A. DE C.V. MEXI"
    },
    "PSTI": {
        "id": "PSTI",
        "name": "PORTERSVILLE SALES & TESING"
    },
    "PSTK": {
        "id": "PSTK",
        "name": "PRO STOCK TRAILER MANUFACTURING"
    },
    "PSTR": {
        "id": "PSTR",
        "name": "PROSTAR"
    },
    "PTCC": {
        "id": "PTCC",
        "name": "PORTABLE CEDAR CABINS SPIRIT LAKE ID"
    },
    "PTCH": {
        "id": "PTCH",
        "name": "PRODUCTION TECHNOLOGIES"
    },
    "PTMC": {
        "id": "PTMC",
        "name": "POTOMAC RV ELKHART"
    },
    "PTMG": {
        "id": "PTMG",
        "name": "PRIMO TRAILER MANUFACTURING CORP"
    },
    "PTMI": {
        "id": "PTMI",
        "name": "PETERSON MOTOR INDUSTRIAL"
    },
    "PTMT": {
        "id": "PTMT",
        "name": "PARALLEL INTELLIGENT TRANSPORTATION"
    },
    "PTRB": {
        "id": "PTRB",
        "name": "PETERBILT MOTORS"
    },
    "PTRK": {
        "id": "PTRK",
        "name": "PRO-TRAK TTRAILERS"
    },
    "PTRO": {
        "id": "PTRO",
        "name": "PETRO 2 GO"
    },
    "PTRT": {
        "id": "PTRT",
        "name": "INDEPENDENCEMFD. BY PATRIOT HOMES"
    },
    "PTSN": {
        "id": "PTSN",
        "name": "PETERSON TRAILERS GIBSONTON"
    },
    "PTTS": {
        "id": "PTTS",
        "name": "P & T TRAILER SALES"
    },
    "PTTW": {
        "id": "PTTW",
        "name": "PRO-TRAC TRAILERS WEST RANCHO CORDOVA"
    },
    "PTV": {
        "id": "PTV",
        "name": "PTV"
    },
    "PTWL": {
        "id": "PTWL",
        "name": "PT WELDING"
    },
    "PUCH": {
        "id": "PUCH",
        "name": "PUCH DIV OF STEYR DAIMLER PUCH&AUSTRIA"
    },
    "PUCK": {
        "id": "PUCK",
        "name": "PUCKETT BROTHERS MFD. DIV."
    },
    "PUGM": {
        "id": "PUGM",
        "name": "PUGMILL SYSTEMS INC COLUMBIA TN PORTABLE AND STATIONARY MIXERS"
    },
    "PULD": {
        "id": "PULD",
        "name": "PULL-DO TRAILERS"
    },
    "PULL": {
        "id": "PULL",
        "name": "PULL A LONG MFG. & SALES"
    },
    "PULP": {
        "id": "PULP",
        "name": "PULLERS PRIDE"
    },
    "PULT": {
        "id": "PULT",
        "name": "PUL-TOY TRAILERS"
    },
    "PUMA": {
        "id": "PUMA",
        "name": "PUMA CAMPER SALES DIV AMERICAN STERLING ENTERPRISES"
    },
    "PUMI": {
        "id": "PUMI",
        "name": "PULLMAN MODULAR INDUSTRIES"
    },
    "PUMM": {
        "id": "PUMM",
        "name": "PUMA"
    },
    "PURD": {
        "id": "PURD",
        "name": "PURDY ADVENTURE TEARDROP TRAILERS LLC SILVERTON OR"
    },
    "PUTZ": {
        "id": "PUTZ",
        "name": "PUTZMEISTER WISCONSIN & CALIFORNIA MFG"
    },
    "PUZE": {
        "id": "PUZE",
        "name": "PUZEY BROS."
    },
    "PVCO": {
        "id": "PVCO",
        "name": "PIV"
    },
    "PVCR": {
        "id": "PVCR",
        "name": "CIRRUS BRAND MFG BY PLEASANT VALLEY TEARDROP TRAILERS LLC"
    },
    "PVHI": {
        "id": "PVHI",
        "name": "PLEASANT VALLEY HOMES"
    },
    "PVJO": {
        "id": "PVJO",
        "name": "JOEY 4*7"
    },
    "PVMY": {
        "id": "PVMY",
        "name": "MYPOD MFG BY"
    },
    "PVRA": {
        "id": "PVRA",
        "name": "RASCAL MFG BY PLEASANT VALLEY TEARDROP TRAILERS"
    },
    "PVRR": {
        "id": "PVRR",
        "name": "5"
    },
    "PVSS": {
        "id": "PVSS",
        "name": "4*8"
    },
    "PVTG": {
        "id": "PVTG",
        "name": "T AND G BRAND MFG BY PLEASANT VALLEY TEARDROP TRAILERS"
    },
    "PVTL": {
        "id": "PVTL",
        "name": "PORTLAND VINTAGE TRAILER WORKS"
    },
    "PVTT": {
        "id": "PVTT",
        "name": "PLEASANT VALLEY TEARDROP TRAILERS"
    },
    "PVTW": {
        "id": "PVTW",
        "name": "PREVOST MFG BY TRADEWINDS COACH SALES LLC"
    },
    "PWAS": {
        "id": "PWAS",
        "name": "ASCENT BRAND MFG BY PLEASURE-WAY INDUSTRIES LTD"
    },
    "PWAY": {
        "id": "PWAY",
        "name": "PLEASURE-WAY INDUSTRIES"
    },
    "PWDY": {
        "id": "PWDY",
        "name": "POWER DYNE"
    },
    "PWLT": {
        "id": "PWLT",
        "name": "POWERLITE BRAND MFG BY PACIFIC COACHWORKS"
    },
    "PWSS": {
        "id": "PWSS",
        "name": "POWEELL'S SALES AND SERVICE CLARKS SUMMIT PA"
    },
    "PWTC": {
        "id": "PWTC",
        "name": "POWER TRAC MACHINERY"
    },
    "PWTF": {
        "id": "PWTF",
        "name": "TOFINO MFG BY PLEASURE WAY INDUSTRIES LTD"
    },
    "PWTR": {
        "id": "PWTR",
        "name": "PW TRAILER"
    },
    "PWTZ": {
        "id": "PWTZ",
        "name": "PEREWITZ MOTORCYCLES BRIDGEWATER"
    },
    "PWYB": {
        "id": "PWYB",
        "name": "BASIS MFG BY PLEASURE-WAY"
    },
    "PWYE": {
        "id": "PWYE",
        "name": "EXCEL"
    },
    "PWYL": {
        "id": "PWYL",
        "name": "LEXOR MFG BY PLEASURE-WAY"
    },
    "PWYP": {
        "id": "PWYP",
        "name": "PLATEAU MFG BY PLEASURE-WAY"
    },
    "PWYT": {
        "id": "PWYT",
        "name": "TRAVERSE MFG BY PLEASURE-WAY"
    },
    "PXMD": {
        "id": "PXMD",
        "name": "PHOENIX MODULAR INC PHOENIX AZ"
    },
    "PYLE": {
        "id": "PYLE",
        "name": "PYLE HAUL-IT-ALL FLATBED"
    },
    "PYMC": {
        "id": "PYMC",
        "name": "PYRAMID MANUFACTURING"
    },
    "PYRA": {
        "id": "PYRA",
        "name": "PYRAMID MOBIL HOMES"
    },
    "QCMF": {
        "id": "QCMF",
        "name": "Q C METAL FAB."
    },
    "QE": {
        "id": "QE",
        "name": "QE MFG."
    },
    "QING": {
        "id": "QING",
        "name": "CHINA QINGQI GROUP"
    },
    "QINJ": {
        "id": "QINJ",
        "name": "QIANJIANG MOTORCYCLE GROUP CORP. P.R. CHINA QIANG MOTORCYCLESOR ZHEJIANG"
    },
    "QIPA": {
        "id": "QIPA",
        "name": "QIPAI MOTORCYCLE"
    },
    "QIYE": {
        "id": "QIYE",
        "name": "QIYE SCOOTER"
    },
    "QLCG": {
        "id": "QLCG",
        "name": "QUALITY CARGO NASHVILLE"
    },
    "QLNK": {
        "id": "QLNK",
        "name": "Q LINK OR QLINK LP"
    },
    "QLTC": {
        "id": "QLTC",
        "name": "QUALITEC MANUFACTURING"
    },
    "QLTY": {
        "id": "QLTY",
        "name": "QUALITY BUILT TRAILER"
    },
    "QLYP": {
        "id": "QLYP",
        "name": "QUALITY PRODUCTS"
    },
    "QNGH": {
        "id": "QNGH",
        "name": "CHANGZHI QINGHUA MACHINERY FACTORY"
    },
    "QNGX": {
        "id": "QNGX",
        "name": "QINGXIN LIANTONG INDUSTRY LIMITED GUANGDONG"
    },
    "QNTY": {
        "id": "QNTY",
        "name": "QUANTYA SA - MOTORCYCLES"
    },
    "QSAP": {
        "id": "QSAP",
        "name": "QUALITY STEEL & ALUMINUM PRODUCTS ELKHART"
    },
    "QSIV": {
        "id": "QSIV",
        "name": "QINGDOA SINGAMAS INDUSTRIAL VEHICLE"
    },
    "QUAD": {
        "id": "QUAD",
        "name": "QUADRAX"
    },
    "QUAK": {
        "id": "QUAK",
        "name": "QUAKER CITY IRON WORKS"
    },
    "QUAL": {
        "id": "QUAL",
        "name": "QUALITY TRAILER"
    },
    "QUAT": {
        "id": "QUAT",
        "name": "QUALITY TRAILER MANUFACTURY"
    },
    "QUIC": {
        "id": "QUIC",
        "name": "QUICKLOAD_CUSTOM BUILT TRAILERS FLORIDA"
    },
    "QUIK": {
        "id": "QUIK",
        "name": "QUIK MANUFACTURING"
    },
    "QUIN": {
        "id": "QUIN",
        "name": "QUINCY COMPRESSOR DIV.COLT IND"
    },
    "QUNC": {
        "id": "QUNC",
        "name": "QUALITY TRAILERS OF NC LEXINGTON"
    },
    "QUNE": {
        "id": "QUNE",
        "name": "QUALITY TRAILERS OF NE INC BEAVER CITY"
    },
    "QUTY": {
        "id": "QUTY",
        "name": "QUALITY MFG."
    },
    "QVAL": {
        "id": "QVAL",
        "name": "QVALE AUTOMITIVE GROUP LTD."
    },
    "QWSE": {
        "id": "QWSE",
        "name": "QWS EXPRESS TRAILERS"
    },
    "QYEV": {
        "id": "QYEV",
        "name": "QINGYUAN ELECTRIC VEHICLE"
    },
    "RABB": {
        "id": "RABB",
        "name": "RABBIT"
    },
    "RABJ": {
        "id": "RABJ",
        "name": "RABBIT"
    },
    "RACE": {
        "id": "RACE",
        "name": "515O RACE TRAILERS"
    },
    "RACH": {
        "id": "RACH",
        "name": "RACH WELDINGST. JOSEPH"
    },
    "RACI": {
        "id": "RACI",
        "name": "RACINE CONSTRUCTION TOOL DIV."
    },
    "RACK": {
        "id": "RACK",
        "name": "THE RACK FACTORY  FERRISBURGH"
    },
    "RACM": {
        "id": "RACM",
        "name": "RACMAC TRAILER"
    },
    "RADC": {
        "id": "RADC",
        "name": "ROADCRAFT MFG. & LEASING"
    },
    "RADE": {
        "id": "RADE",
        "name": "RADAMACHER TRAILER"
    },
    "RADI": {
        "id": "RADI",
        "name": "RAD INDUSTRIES MOTORCYCLE TRAILERS MOTORCYCLE LIFTS"
    },
    "RADS": {
        "id": "RADS",
        "name": "RADICAL SPORTSCARS U.K. PETERBOROUGH"
    },
    "RAEC": {
        "id": "RAEC",
        "name": "RA ENGINEERING"
    },
    "RAES": {
        "id": "RAES",
        "name": "RAES UTILITY TRAILER"
    },
    "RAFC": {
        "id": "RAFC",
        "name": "RAFTER D TRAILER MFG. CHARLESTON"
    },
    "RAGE": {
        "id": "RAGE",
        "name": "RAGE'N INC"
    },
    "RAGL": {
        "id": "RAGL",
        "name": "RAGLAN INDUSTRIES"
    },
    "RAIB": {
        "id": "RAIB",
        "name": "RAINBOW CAMPER"
    },
    "RAIC": {
        "id": "RAIC",
        "name": "RAINBOW CORP."
    },
    "RAID": {
        "id": "RAID",
        "name": "LEISURE VEHICLES"
    },
    "RAIN": {
        "id": "RAIN",
        "name": "RAINWAY MFG."
    },
    "RAIT": {
        "id": "RAIT",
        "name": "RAIDTRAC CANADA MULTI WHEELED AND TRACKED ATV VEHICLES AND SNOWMACHINES"
    },
    "RAJA": {
        "id": "RAJA",
        "name": "RAJA TRAILER & EQUIPMENT SALES LTD. BRITISH COLUMBIA"
    },
    "RAJY": {
        "id": "RAJY",
        "name": "RAJYSAN INCORPORATED VAN NUYS"
    },
    "RALL": {
        "id": "RALL",
        "name": "RALLYMASTER TRAVEL TRAILER ARLINGTON TEXAS"
    },
    "RAM": {
        "id": "RAM",
        "name": "RAM"
    },
    "RAMA": {
        "id": "RAMA",
        "name": "RAMADA TENT CAMPING TRAILER"
    },
    "RAMB": {
        "id": "RAMB",
        "name": "RAMBLER"
    },
    "RAME": {
        "id": "RAME",
        "name": "RAM ENTERPRISES"
    },
    "RAMK": {
        "id": "RAMK",
        "name": "RAMIKA INDUSTRIES CHATSWORTH"
    },
    "RAMN": {
        "id": "RAMN",
        "name": "RAM-LIN CUSTOM TRAILERS"
    },
    "RAMP": {
        "id": "RAMP",
        "name": "R & A MANUFACTURING PARTENRS"
    },
    "RAMR": {
        "id": "RAMR",
        "name": "RAMSEY TRAILERS"
    },
    "RAMS": {
        "id": "RAMS",
        "name": "RAMSES"
    },
    "RANA": {
        "id": "RANA",
        "name": "RANCHETTE TRAVEL TRAILER"
    },
    "RANC": {
        "id": "RANC",
        "name": "RANCHO TRAILERS"
    },
    "RAND": {
        "id": "RAND",
        "name": "RANDALL"
    },
    "RANE": {
        "id": "RANE",
        "name": "RANDOLPH COACH"
    },
    "RANG": {
        "id": "RANG",
        "name": "RANGER BOATS & TRAILERS"
    },
    "RANH": {
        "id": "RANH",
        "name": "RANCH KING ENTERPRISES"
    },
    "RANL": {
        "id": "RANL",
        "name": "R-ANELL HOMES"
    },
    "RANS": {
        "id": "RANS",
        "name": "RANSOME LIFT EQUIPMENT"
    },
    "RANW": {
        "id": "RANW",
        "name": "RANEW'S TRUCK AND EQUIPMENT COMPANY LLC MILNER GEORGIA"
    },
    "RAPI": {
        "id": "RAPI",
        "name": "RAPID TRAVELER COACHES"
    },
    "RAPS": {
        "id": "RAPS",
        "name": "RAPSURE"
    },
    "RATC": {
        "id": "RATC",
        "name": "RAT"
    },
    "RATK": {
        "id": "RATK",
        "name": "RAINIER TRUCK AND SHASSIS LLC YAKIMA WA"
    },
    "RATR": {
        "id": "RATR",
        "name": "RAT RACE TRIKES PHOENIX"
    },
    "RATZ": {
        "id": "RATZ",
        "name": "RATZLAFF TRAILERS TENT TRAILERS"
    },
    "RAVE": {
        "id": "RAVE",
        "name": "RAVENS-METAL PRODUCTS"
    },
    "RAVN": {
        "id": "RAVN",
        "name": "RAVENS"
    },
    "RAVO": {
        "id": "RAVO",
        "name": "RAVO INTERNATIONAL BV NETHERLANDS TRUCKS STREET SWEEPERS ETC"
    },
    "RAW": {
        "id": "RAW",
        "name": "ARKANSAS TRAVELER MFG BY RAWHIDE STOCK AND HORSE TRAILER MFG INC"
    },
    "RAWS": {
        "id": "RAWS",
        "name": "RAW STRIKER LTD. UNITED KINGDON"
    },
    "RAYC": {
        "id": "RAYC",
        "name": "RAY"
    },
    "RAYE": {
        "id": "RAYE",
        "name": "RAYNE PLANE"
    },
    "RAYF": {
        "id": "RAYF",
        "name": "RAYFAB INC."
    },
    "RAYG": {
        "id": "RAYG",
        "name": "RAYGO"
    },
    "RAYI": {
        "id": "RAYI",
        "name": "RAYMOND PRODUCTS"
    },
    "RAYM": {
        "id": "RAYM",
        "name": "RAYMUR ACCEPTANCE CORP."
    },
    "RAYN": {
        "id": "RAYN",
        "name": "RAYNELL CAMPERS"
    },
    "RAYT": {
        "id": "RAYT",
        "name": "RAY-TECH INFRARED CORP NEW HAMPSHIRE TRAILERS"
    },
    "RAZR": {
        "id": "RAZR",
        "name": "RAZOR MFG"
    },
    "RBBP": {
        "id": "RBBP",
        "name": "BAY POINT MFG BY RECREATION BY DESIGN"
    },
    "RBCT": {
        "id": "RBCT",
        "name": "RACKLEY BILT CUSTOM TRAILERS INC STOCKTON CA"
    },
    "RBMC": {
        "id": "RBMC",
        "name": "MONTE CARLO MFG BY RECREATION BY DESIGN"
    },
    "RBMF": {
        "id": "RBMF",
        "name": "RBM MFG FORT FAIRFILED"
    },
    "RBMG": {
        "id": "RBMG",
        "name": "R-B MANUFACTURING"
    },
    "RBMH": {
        "id": "RBMH",
        "name": "RIVER BEND MANUFACTURED HOME"
    },
    "RBNA": {
        "id": "RBNA",
        "name": "NORTH AMERICAN MFG BY RECREATION BY DESIGN"
    },
    "RBNS": {
        "id": "RBNS",
        "name": "ROBBINS MANUFACTURING INC FALL RIVER WI"
    },
    "RBOS": {
        "id": "RBOS",
        "name": "ROAD BOSS TRAILER MFG."
    },
    "RBRC": {
        "id": "RBRC",
        "name": "MINI-CRUISERMFD. BY R.B.R. CORP."
    },
    "RBRT": {
        "id": "RBRT",
        "name": "ROYAL TRAVEL MFG BY RECREATION BY DESIGN"
    },
    "RBSN": {
        "id": "RBSN",
        "name": "ROBINSON METAL INC."
    },
    "RBTS": {
        "id": "RBTS",
        "name": "ROBERTS METAL MANUFACTURING"
    },
    "RBYD": {
        "id": "RBYD",
        "name": "RECREATION BY DESIGN LLC. ELKHART"
    },
    "RCAR": {
        "id": "RCAR",
        "name": "RACE CAR REPLICAS CLINTON TOWNSHIP"
    },
    "RCBH": {
        "id": "RCBH",
        "name": "REC BOAT HOLDINGS"
    },
    "RCCI": {
        "id": "RCCI",
        "name": "ROOSTER CUSTOM CYCLES"
    },
    "RCCM": {
        "id": "RCCM",
        "name": "RC COMPONENTS"
    },
    "RCCT": {
        "id": "RCCT",
        "name": "ROCKLAND CUSTOM TRAILERS SCOTTSDALE AZ"
    },
    "RCFO": {
        "id": "RCFO",
        "name": "R"
    },
    "RCHS": {
        "id": "RCHS",
        "name": "RICHARDS UTILITY TRAILER"
    },
    "RCHY": {
        "id": "RCHY",
        "name": "RICHWAY INDUSTRIES JANESVILLE"
    },
    "RCIN": {
        "id": "RCIN",
        "name": "R.C. INDUSTRIES"
    },
    "RCKC": {
        "id": "RCKC",
        "name": "ROCKING CHAIR MANUFACTURING ANTLERS"
    },
    "RCKF": {
        "id": "RCKF",
        "name": "ROCKFORD COMMERCIAL WAREHOUSE"
    },
    "RCKH": {
        "id": "RCKH",
        "name": "ROCK HILL BODY CO"
    },
    "RCKL": {
        "id": "RCKL",
        "name": "BIG A MFG BY RICKEL MFG. CORP."
    },
    "RCKM": {
        "id": "RCKM",
        "name": "ROCKY MOUNTAIN TRAILERS AURORA"
    },
    "RCKN": {
        "id": "RCKN",
        "name": "ROCKIN L. RIGGIN CORP. GRAHAM"
    },
    "RCKP": {
        "id": "RCKP",
        "name": "ROCKPORT MFG BY FOREST RIVER VMA"
    },
    "RCKT": {
        "id": "RCKT",
        "name": "ROCKET CITY TRAILERS INC"
    },
    "RCKY": {
        "id": "RCKY",
        "name": "ROCKY TOP TRAILERS"
    },
    "RCLK": {
        "id": "RCLK",
        "name": "R CLARK ENTERPRISES PORTLAND"
    },
    "RCLP": {
        "id": "RCLP",
        "name": "ROAD CLIPPER BY DIAMOND C TRAILER MANUFACTURING MT. PLEASANT"
    },
    "RCMC": {
        "id": "RCMC",
        "name": "ROCHESTER CARRIAGE MOTOR COMPANY"
    },
    "RCMH": {
        "id": "RCMH",
        "name": "RC MOTOR HOME"
    },
    "RCMS": {
        "id": "RCMS",
        "name": "RC MOTORSPORTS OR RC MOTORS"
    },
    "RCOA": {
        "id": "RCOA",
        "name": "RACE COACHES"
    },
    "RCON": {
        "id": "RCON",
        "name": "RECONSTRUCTED TRAILERS"
    },
    "RCTL": {
        "id": "RCTL",
        "name": "RC TRAILERS"
    },
    "RDBT": {
        "id": "RDBT",
        "name": "R AND D BALER TRAILERS"
    },
    "RDBW": {
        "id": "RDBW",
        "name": "READING BODY WORKS"
    },
    "RDCC": {
        "id": "RDCC",
        "name": "RADICAL CURVES CUSTOM MOTORCYCLES LAKE WORTH"
    },
    "RDER": {
        "id": "RDER",
        "name": "RAIDER"
    },
    "RDEU": {
        "id": "RDEU",
        "name": "RIDEAU"
    },
    "RDGF": {
        "id": "RDGF",
        "name": "RIDGEFILED CUSTOM TRAILERS RIDGEFIELD WA"
    },
    "RDGL": {
        "id": "RDGL",
        "name": "RIDGELINE"
    },
    "RDGN": {
        "id": "RDGN",
        "name": "REDRAGON"
    },
    "RDHM": {
        "id": "RDHM",
        "name": "RDH MANUFACTURING"
    },
    "RDKG": {
        "id": "RDKG",
        "name": "ROAD KING TRAVEL TRAILERS"
    },
    "RDLY": {
        "id": "RDLY",
        "name": "RIDLEY MOTORCYCLE COMPANY OKLAHOMA CITY"
    },
    "RDMF": {
        "id": "RDMF",
        "name": "REID MANUFACTURING LLC CARROLLTON"
    },
    "RDMN": {
        "id": "RDMN",
        "name": "ROADMAN CAMPERS"
    },
    "RDOG": {
        "id": "RDOG",
        "name": "ROAD DOG TRAILERS ROYALTON MN"
    },
    "RDOK": {
        "id": "RDOK",
        "name": "RED OAK MFG"
    },
    "RDRN": {
        "id": "RDRN",
        "name": "ROAD RUNER MANUFACTURING LLC LEVANT KS"
    },
    "RDSK": {
        "id": "RDSK",
        "name": "RED STREAK SCOOTERS PRODUCT OF MEITIAN MOTORCYCLE"
    },
    "RDTE": {
        "id": "RDTE",
        "name": "R & D TRAILER & EQUIPMENT SALES TEXAS"
    },
    "REA": {
        "id": "REA",
        "name": "REA TRAILER"
    },
    "READ": {
        "id": "READ",
        "name": "READI-BUILT TRAILERS THOMASVILLE"
    },
    "REAL": {
        "id": "REAL",
        "name": "REAL"
    },
    "REAR": {
        "id": "REAR",
        "name": "REAR'S MFG."
    },
    "REBE": {
        "id": "REBE",
        "name": "REBEL MOBILE HOMEDIV. SKYLINE HOMES"
    },
    "REBL": {
        "id": "REBL",
        "name": "REBEL BOAT TRAILER"
    },
    "REBM": {
        "id": "REBM",
        "name": "BRIGHTON MANOR MFG BY REDMAN HOMES INC"
    },
    "REBW": {
        "id": "REBW",
        "name": "REBEL WEST POWERSPORTS LLC"
    },
    "RECB": {
        "id": "RECB",
        "name": "R.E. CUSTOM BOAT TRAILERS WEST COLUMBIA"
    },
    "RECG": {
        "id": "RECG",
        "name": "RE"
    },
    "RECO": {
        "id": "RECO",
        "name": "RECONSTRUCTED MOTORCYCLE"
    },
    "RECR": {
        "id": "RECR",
        "name": "RECREATIVE INDUSTRIES"
    },
    "RECS": {
        "id": "RECS",
        "name": "REC-SHA TRAILER"
    },
    "REDA": {
        "id": "REDA",
        "name": "RED ARROW TRAILER"
    },
    "REDB": {
        "id": "REDB",
        "name": "RED BARON CHOPPERS RED BARON MOTORS PHOENIX AZ"
    },
    "REDC": {
        "id": "REDC",
        "name": "REDCAT MOTORS JEFFERSON"
    },
    "REDD": {
        "id": "REDD",
        "name": "RED DALE COACH"
    },
    "REDG": {
        "id": "REDG",
        "name": "RED GOOSER HORSE TRAILER"
    },
    "REDH": {
        "id": "REDH",
        "name": "RED HORSE MOTORWORKS LENEXA"
    },
    "REDI": {
        "id": "REDI",
        "name": "REDI-GO TRAVELER"
    },
    "REDK": {
        "id": "REDK",
        "name": "REDDICK EQUIPMENT"
    },
    "REDM": {
        "id": "REDM",
        "name": "COUNTRY ESTATE MFD BY REDMAN HOMES"
    },
    "REDN": {
        "id": "REDN",
        "name": "REDNECK ENGINEERING SOUTH CAROLINA MOTORCYCLES ETC."
    },
    "REDR": {
        "id": "REDR",
        "name": "RED RIVER CUSTOM TRAILER"
    },
    "REDS": {
        "id": "REDS",
        "name": "REDSTONE COACH"
    },
    "REDT": {
        "id": "REDT",
        "name": "RED BARON TRAILERS PHOENIX"
    },
    "REED": {
        "id": "REED",
        "name": "REED ENTERPRISES"
    },
    "REEG": {
        "id": "REEG",
        "name": "REED MFG."
    },
    "REEL": {
        "id": "REEL",
        "name": "REEL TRAILERS"
    },
    "REES": {
        "id": "REES",
        "name": "REESE PRODUCTS"
    },
    "REEV": {
        "id": "REEV",
        "name": "REEVES TRAILER"
    },
    "REGE": {
        "id": "REGE",
        "name": "REGENT HOMES CORP."
    },
    "REGI": {
        "id": "REGI",
        "name": "REGIS CORP."
    },
    "REGL": {
        "id": "REGL",
        "name": "REGAL MOTOR CAR COMPANY DETROIT MI"
    },
    "REGM": {
        "id": "REGM",
        "name": "REGARD MACHINERY"
    },
    "REGN": {
        "id": "REGN",
        "name": "REGENCY GT MFG BY TRIPLE E RECREATIONAL VEHICLES CANADA"
    },
    "REGW": {
        "id": "REGW",
        "name": "REGION WELDING"
    },
    "REID": {
        "id": "REID",
        "name": "REIDS TRAILERS"
    },
    "REIN": {
        "id": "REIN",
        "name": "REINELL BOAT TRAILER"
    },
    "REIS": {
        "id": "REIS",
        "name": "REISER MANUFACTURING"
    },
    "REIT": {
        "id": "REIT",
        "name": "REITNOUER"
    },
    "RELA": {
        "id": "RELA",
        "name": "RELIANT"
    },
    "RELB": {
        "id": "RELB",
        "name": "RELIABLE TANK INC.RHONE"
    },
    "RELC": {
        "id": "RELC",
        "name": "REL"
    },
    "RELG": {
        "id": "RELG",
        "name": "LEGEND BRAND MFG BY RV RECREATION GROUP"
    },
    "RELI": {
        "id": "RELI",
        "name": "RELIANCE TRAILER MFG.DIV. REDWOOD RELIANCE SALES"
    },
    "RELM": {
        "id": "RELM",
        "name": "RELIART TRAILER MFG."
    },
    "RELT": {
        "id": "RELT",
        "name": "REALITE"
    },
    "REMA": {
        "id": "REMA",
        "name": "REMACKEL WELDING AND MANUFACTURING"
    },
    "REMB": {
        "id": "REMB",
        "name": "REMBRANT MOBILE HOME"
    },
    "REMC": {
        "id": "REMC",
        "name": "REM"
    },
    "REMI": {
        "id": "REMI",
        "name": "COUNTRYSIDE MOBILE HOME MFG BY REMIC INDUSTRIES"
    },
    "REMK": {
        "id": "REMK",
        "name": "REMKE TRAILER"
    },
    "REMO": {
        "id": "REMO",
        "name": "REMORQUES"
    },
    "REMQ": {
        "id": "REMQ",
        "name": "REMEQ"
    },
    "REMT": {
        "id": "REMT",
        "name": "REMTEC COLUMBIA-REMTEC"
    },
    "RENA": {
        "id": "RENA",
        "name": "RENAULT"
    },
    "RENE": {
        "id": "RENE",
        "name": "RENEGADE TRIKES"
    },
    "RENT": {
        "id": "RENT",
        "name": "RENT-A-TRAILER CORP."
    },
    "REPD": {
        "id": "REPD",
        "name": "PRODIGY REV RECREATION GROUP INC"
    },
    "REPS": {
        "id": "REPS",
        "name": "PASSAGE BRAND MFG BY RV RECREATION GROUP"
    },
    "RESP": {
        "id": "RESP",
        "name": "RESPO HAAGISED AS ESTONIA"
    },
    "REST": {
        "id": "REST",
        "name": "A RESTROOM TRAILER COMPANY"
    },
    "RETL": {
        "id": "RETL",
        "name": "RE'S TRAILERS STARKE"
    },
    "RETT": {
        "id": "RETT",
        "name": "RETTIG ENTERPRISES SIKESTON"
    },
    "REVC": {
        "id": "REVC",
        "name": "REVOLUTION CYCLE COMPANY MARIETTA"
    },
    "REVE": {
        "id": "REVE",
        "name": "REVELLA CAMPING TRAILER"
    },
    "REVN": {
        "id": "REVN",
        "name": "REVCON"
    },
    "REVO": {
        "id": "REVO",
        "name": "REVO MOTOR COMPANY OR REVO NORTH AMERICA"
    },
    "REVR": {
        "id": "REVR",
        "name": "REV RECREATION GROUP INC PREV ALLIED RECREATION GROUP INC"
    },
    "REVS": {
        "id": "REVS",
        "name": "REEVES ROOFING EQUIPMENT"
    },
    "REWA": {
        "id": "REWA",
        "name": "REWA"
    },
    "REX": {
        "id": "REX",
        "name": "REX"
    },
    "REXH": {
        "id": "REXH",
        "name": "REXHALL MANUFACTURER OF AERBUS"
    },
    "REXM": {
        "id": "REXM",
        "name": "REX MOBILE HOMES DIV OF DIVCO-WAYNE INDUSTRIES"
    },
    "REXN": {
        "id": "REXN",
        "name": "REXNORD"
    },
    "REYN": {
        "id": "REYN",
        "name": "REYNOLDS MFG."
    },
    "RFCM": {
        "id": "RFCM",
        "name": "RFC MFG."
    },
    "RFDI": {
        "id": "RFDI",
        "name": "RED FERN DYNAMICS INC CLEVELAND GA"
    },
    "RFXT": {
        "id": "RFXT",
        "name": "RON'S FIX-IT SHOP NEW HAMPSHIRE"
    },
    "RGAA": {
        "id": "RGAA",
        "name": "R&G"
    },
    "RGCO": {
        "id": "RGCO",
        "name": "CONCEPT ONE BRAND MFG BY REGENCY CONVERSIONS"
    },
    "RGGT": {
        "id": "RGGT",
        "name": "GETAWAY BRAND MFG BY REGENCY CONVERSIONS"
    },
    "RGHT": {
        "id": "RGHT",
        "name": "RIGHT MANUFACTURING SYSTEMS INC LINDON UT"
    },
    "RGNC": {
        "id": "RGNC",
        "name": "REGENCY CONVERSIONS FT. WORTH TX"
    },
    "RGNT": {
        "id": "RGNT",
        "name": "NATIONAL TRAILER MFG BY REGENCY CONVERSION INC"
    },
    "RGUB": {
        "id": "RGUB",
        "name": "ULTRA BROUGHAM MFG BY REGENCY CONVERSIONS INC"
    },
    "RGUE": {
        "id": "RGUE",
        "name": "ROGUE INDUSTRIES"
    },
    "RGXL": {
        "id": "RGXL",
        "name": "XALTA"
    },
    "RHEA": {
        "id": "RHEA",
        "name": "RHEA'S TRAILER SALES"
    },
    "RHFE": {
        "id": "RHFE",
        "name": "RINGO HILL FARMS EQUIPMENT COMPANY"
    },
    "RHIN": {
        "id": "RHIN",
        "name": "RHINO TOOL COMPANY"
    },
    "RHML": {
        "id": "RHML",
        "name": "RYAN HOWELL MARKETING"
    },
    "RHNO": {
        "id": "RHNO",
        "name": "RHINO TRAILER MANUFACTURING MANOR"
    },
    "RHOD": {
        "id": "RHOD",
        "name": "RHODES TRAILERS AND TRUCK BODIES"
    },
    "RHON": {
        "id": "RHON",
        "name": "RHON MOTORCYCLE"
    },
    "RICA": {
        "id": "RICA",
        "name": "RICHARD PICHE"
    },
    "RICD": {
        "id": "RICD",
        "name": "DARIAN MOBILE HOMES MFD BY RICHARDSON DIV. GREAT SOUTHWESTERN CORP"
    },
    "RICE": {
        "id": "RICE",
        "name": "RICE TRAILER CO"
    },
    "RICH": {
        "id": "RICH",
        "name": "RICHARDSON HOMES CORP."
    },
    "RICK": {
        "id": "RICK",
        "name": "RICKMAN TRAILER"
    },
    "RICL": {
        "id": "RICL",
        "name": "RICHARDS & CLARK MFG."
    },
    "RICN": {
        "id": "RICN",
        "name": "RICHLAND HOMES MFG."
    },
    "RICO": {
        "id": "RICO",
        "name": "RI"
    },
    "RICP": {
        "id": "RICP",
        "name": "RICH'S PORTABLE CABINS"
    },
    "RICR": {
        "id": "RICR",
        "name": "RICHARDSON MFG."
    },
    "RICT": {
        "id": "RICT",
        "name": "RICE TRAILERS HARVIELL MISSOURI"
    },
    "RIDE": {
        "id": "RIDE",
        "name": "RIDE-ON"
    },
    "RIDG": {
        "id": "RIDG",
        "name": "RIDGECRAFT CORP.BRECKENRIDGE"
    },
    "RIDR": {
        "id": "RIDR",
        "name": "DREAM BRAND MFG BY RIVERSIDE TRAVEL TRAILER INC"
    },
    "RIGH": {
        "id": "RIGH",
        "name": "RIGHT PRODUCTS"
    },
    "RIIN": {
        "id": "RIIN",
        "name": "R & I INDUSTRIES CITY OF INDUSTRY CALIFORNIA"
    },
    "RIIT": {
        "id": "RIIT",
        "name": "INTREPID MFG BY RIVERSIDE RV INC"
    },
    "RILE": {
        "id": "RILE",
        "name": "RILEY"
    },
    "RILY": {
        "id": "RILY",
        "name": "RILEY FORESTRY EQUIPMENT MONTEZUMA"
    },
    "RIME": {
        "id": "RIME",
        "name": "RIMER"
    },
    "RIMH": {
        "id": "RIMH",
        "name": "RIV MANUFACTURED HOME"
    },
    "RIND": {
        "id": "RIND",
        "name": "RICH INDUSTRIES"
    },
    "RING": {
        "id": "RING",
        "name": "RING-O-MATIC MFG."
    },
    "RINK": {
        "id": "RINK",
        "name": "RINK MFG."
    },
    "RIPC": {
        "id": "RIPC",
        "name": "RIPCO"
    },
    "RIRI": {
        "id": "RIRI",
        "name": "RIVER RIM TEARDROPS"
    },
    "RIRP": {
        "id": "RIRP",
        "name": "RPM BRAND MFG BY RIVERSIDE TRAVEL TRAILER INC"
    },
    "RITB": {
        "id": "RITB",
        "name": "RITE-BILT"
    },
    "RITE": {
        "id": "RITE",
        "name": "RITE-ON TRAILERS DIV OF WINDWARD MARKETING"
    },
    "RITT": {
        "id": "RITT",
        "name": "RIVER TRAIL TRAILERS LITTLE ROCK ARKANSAS"
    },
    "RITW": {
        "id": "RITW",
        "name": "RITE-WAY TRAILER"
    },
    "RITZ": {
        "id": "RITZ",
        "name": "RANGER MOBILE HOMES MFD BY RITZ-CRAFT"
    },
    "RIVB": {
        "id": "RIVB",
        "name": "RIVERBIRCH MOBILE"
    },
    "RIVC": {
        "id": "RIVC",
        "name": "RIVERCRAFT BOAT TRAILERS"
    },
    "RIVE": {
        "id": "RIVE",
        "name": "RIVERDALE STEEL WORKS"
    },
    "RIVI": {
        "id": "RIVI",
        "name": "RIVIERA"
    },
    "RIVM": {
        "id": "RIVM",
        "name": "THE RIVERMAN BOATWORKS"
    },
    "RIVN": {
        "id": "RIVN",
        "name": "BROWN TRENCHER DIV."
    },
    "RIVR": {
        "id": "RIVR",
        "name": "BILL RIVERS"
    },
    "RIVS": {
        "id": "RIVS",
        "name": "RIVERSIDE TRAVEL TRAILER"
    },
    "RIVT": {
        "id": "RIVT",
        "name": "RIVERSIDE TRAVEL TRAILER"
    },
    "RIVU": {
        "id": "RIVU",
        "name": "RIVER RUN"
    },
    "RIVW": {
        "id": "RIVW",
        "name": "RIVIERA MFG."
    },
    "RIYA": {
        "id": "RIYA",
        "name": "RIYA MOTORCYCLE"
    },
    "RJMS": {
        "id": "RJMS",
        "name": "REMORQUES JMS TRAILERS"
    },
    "RJNK": {
        "id": "RJNK",
        "name": "R JENKINS TRAILERS OR RJ'S WELDING SOUTH CAROLINA"
    },
    "RJTE": {
        "id": "RJTE",
        "name": "R AND J TEXAS ENTERPRISES INC"
    },
    "RJTL": {
        "id": "RJTL",
        "name": "R & J TRAILERS"
    },
    "RJWC": {
        "id": "RJWC",
        "name": "R AND J WELDING CUSTOM TRAILERS HEMET CA"
    },
    "RKMC": {
        "id": "RKMC",
        "name": "RICKMAN MOTORCYCLES ENGLAND"
    },
    "RKOE": {
        "id": "RKOE",
        "name": "RKO ENTERPRISES MADISON"
    },
    "RKPG": {
        "id": "RKPG",
        "name": "R AND K PRECISION GRIND ALBIA IA"
    },
    "RKRL": {
        "id": "RKRL",
        "name": "ROCK AND ROLL"
    },
    "RKTA": {
        "id": "RKTA",
        "name": "ROKETA MOTOR SCOOTERS"
    },
    "RKWL": {
        "id": "RKWL",
        "name": "ROCKWELL"
    },
    "RLAC": {
        "id": "RLAC",
        "name": "ALLEN"
    },
    "RLBL": {
        "id": "RLBL",
        "name": "RELIABLE CUSATOM TRAILERS"
    },
    "RLGY": {
        "id": "RLGY",
        "name": "REVOLOGY CARS INC"
    },
    "RLLN": {
        "id": "RLLN",
        "name": "ROLLIN-S TRAILERS INC MARTIN GA"
    },
    "RLPI": {
        "id": "RLPI",
        "name": "ROCK LINE PRODUCTS"
    },
    "RLRT": {
        "id": "RLRT",
        "name": "ROLLRITE TRAILER MANUFACTURING LODI"
    },
    "RLST": {
        "id": "RLST",
        "name": "ROLLINGSTAR"
    },
    "RLTT": {
        "id": "RLTT",
        "name": "ROULOTTES PROLITE"
    },
    "RLUX": {
        "id": "RLUX",
        "name": "ROLUX"
    },
    "RMAC": {
        "id": "RMAC",
        "name": "RIMAC AUTOMOBILI CROATIA"
    },
    "RMJO": {
        "id": "RMJO",
        "name": "RM JOHNSON"
    },
    "RMKR": {
        "id": "RMKR",
        "name": "REMKOR TRAILER PADEN OK"
    },
    "RMPL": {
        "id": "RMPL",
        "name": "RAMPLESS MOTORCYCLE TRAILERS FT. LAUDERDALE"
    },
    "RMPT": {
        "id": "RMPT",
        "name": "RAMPANT TRAILERS"
    },
    "RMSL": {
        "id": "RMSL",
        "name": "REGAL MOBILE SOLUTIONS LLC"
    },
    "RMST": {
        "id": "RMST",
        "name": "ROADMASTER LLC"
    },
    "RMTL": {
        "id": "RMTL",
        "name": "R M TRAILER"
    },
    "RMTM": {
        "id": "RMTM",
        "name": "R & M TRAILER MFG.INC OMAHA"
    },
    "RMWC": {
        "id": "RMWC",
        "name": "COUNTRY-BOY MFD BY REID'S MFG. & WELDING"
    },
    "RMWF": {
        "id": "RMWF",
        "name": "ROCKY MOUNTAIN WELDING AND FABRICATING PLEASANT GROVE"
    },
    "RMWM": {
        "id": "RMWM",
        "name": "RM WARREN MANUFACTURING SUTHERLIN"
    },
    "RNCE": {
        "id": "RNCE",
        "name": "RANCE ALUMINUM"
    },
    "RNCO": {
        "id": "RNCO",
        "name": "RANCH MANUFACTURING COMPANY TRAILERS"
    },
    "RNGD": {
        "id": "RNGD",
        "name": "RENEGADE"
    },
    "RNKE": {
        "id": "RNKE",
        "name": "REINKE MFG. INC DESCHLER"
    },
    "RNLI": {
        "id": "RNLI",
        "name": "RENLI VEHICLE"
    },
    "RNMT": {
        "id": "RNMT",
        "name": "RENAISSANCE MOTORS BECAME ZEBRA MOTORS"
    },
    "RNRT": {
        "id": "RNRT",
        "name": "REINERT CONCRETE PUMPS FLORENCE KENTUCKY CONCRETE PUMPS"
    },
    "RNSM": {
        "id": "RNSM",
        "name": "RANSOME MOWERS BOBCAT MODEL"
    },
    "RNSN": {
        "id": "RNSN",
        "name": "RONSON MANUFACTURING CORP MISSOURI"
    },
    "RNSO": {
        "id": "RNSO",
        "name": "RINSO FORKLIFT"
    },
    "RNTC": {
        "id": "RNTC",
        "name": "RENTAL COTTAGE MFG BY ATHENS PARK HOMES"
    },
    "RNTL": {
        "id": "RNTL",
        "name": "RON'S TRAILERS HOLLISTER MO"
    },
    "RNVE": {
        "id": "RNVE",
        "name": "VERONA REV RENEGADE LLC"
    },
    "RNVI": {
        "id": "RNVI",
        "name": "VIENNA REV RENEGADE LLC"
    },
    "RNVL": {
        "id": "RNVL",
        "name": "VALENCIA REV RENEGADE LLC"
    },
    "RNWY": {
        "id": "RNWY",
        "name": "RUNAWAY TRAILERS SUMMERFIELD"
    },
    "RNXL": {
        "id": "RNXL",
        "name": "XL BRAND MFG BY REV RENEGADE"
    },
    "ROAB": {
        "id": "ROAB",
        "name": "ROAM-A-BOUT CAMPER"
    },
    "ROAC": {
        "id": "ROAC",
        "name": "ROAD ROAMER CAMPERS"
    },
    "ROAD": {
        "id": "ROAD",
        "name": "ROAD MOTOR HOME"
    },
    "ROAE": {
        "id": "ROAE",
        "name": "ROADLINER MFG. DIVISION"
    },
    "ROAF": {
        "id": "ROAF",
        "name": "ROADMASTER MFG."
    },
    "ROAG": {
        "id": "ROAG",
        "name": "ROADRUNNER MFG. CORP."
    },
    "ROAL": {
        "id": "ROAL",
        "name": "ROADWAY TRAILER"
    },
    "ROAM": {
        "id": "ROAM",
        "name": "ROAMINGHOME"
    },
    "ROAR": {
        "id": "ROAR",
        "name": "ROADMASTER RAIL"
    },
    "ROAW": {
        "id": "ROAW",
        "name": "ROAD WARRIOR MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "ROBB": {
        "id": "ROBB",
        "name": "ROBB KAUFMAN"
    },
    "ROBI": {
        "id": "ROBI",
        "name": "ROBIN MFG."
    },
    "ROBM": {
        "id": "ROBM",
        "name": "ROBINHOOD MOTOR HOMES"
    },
    "ROBR": {
        "id": "ROBR",
        "name": "ROBIN HOOD TRAVEL TRAILERS"
    },
    "ROBT": {
        "id": "ROBT",
        "name": "ROBERT HORSE TRAILER"
    },
    "ROCB": {
        "id": "ROCB",
        "name": "ROCKIN-BAR TRAILERS LTD.DURANT"
    },
    "ROCE": {
        "id": "ROCE",
        "name": "ROCKET TRAILER"
    },
    "ROCF": {
        "id": "ROCF",
        "name": "ROCKFORD WELDING & MACHINE"
    },
    "ROCH": {
        "id": "ROCH",
        "name": "ROCHDALE"
    },
    "ROCI": {
        "id": "ROCI",
        "name": "ROCKET INTERNATIONAL"
    },
    "ROCK": {
        "id": "ROCK",
        "name": "ROCKFORD"
    },
    "ROCM": {
        "id": "ROCM",
        "name": "ROCKIE MOUNTAIN MFG."
    },
    "ROCN": {
        "id": "ROCN",
        "name": "ROCKLAND HOMES"
    },
    "ROCO": {
        "id": "ROCO",
        "name": "R. O. CORP."
    },
    "ROCS": {
        "id": "ROCS",
        "name": "MANITOU MOBILE HOMES MFD BY ROCHESTER HOMES"
    },
    "ROCT": {
        "id": "ROCT",
        "name": "ROCKPORT TRAILER MFG. CORP."
    },
    "RODE": {
        "id": "RODE",
        "name": "RODERUNNER BIKE TRAILERS"
    },
    "RODR": {
        "id": "RODR",
        "name": "RODS & RIDES BY TD"
    },
    "ROEN": {
        "id": "ROEN",
        "name": "ROYAL ENFIELD MOTORS SUBSIDIARY OF EICHER MOTORS LIMITED"
    },
    "ROGC": {
        "id": "ROGC",
        "name": "ROGER CARTER CORP"
    },
    "ROGE": {
        "id": "ROGE",
        "name": "ROGERS BROTHERS CORP."
    },
    "ROGM": {
        "id": "ROGM",
        "name": "ROGUE MARINE"
    },
    "ROGR": {
        "id": "ROGR",
        "name": "ROGERS TRAILERS"
    },
    "ROGU": {
        "id": "ROGU",
        "name": "ROGUE RIVER TRAILER MFG."
    },
    "ROGW": {
        "id": "ROGW",
        "name": "ROGERS WELDING HUNTSVILLE"
    },
    "ROK": {
        "id": "ROK",
        "name": "ROCKNE"
    },
    "ROKL": {
        "id": "ROKL",
        "name": "ROCKLAND MFG."
    },
    "ROKN": {
        "id": "ROKN",
        "name": "ROKON INTERNATIONAL INC ROCHESTER NH"
    },
    "ROKW": {
        "id": "ROKW",
        "name": "ROCKWOOD"
    },
    "ROL": {
        "id": "ROL",
        "name": "ROLLS-ROYCE"
    },
    "ROLA": {
        "id": "ROLA",
        "name": "ROLAND CURTAINS USA INC."
    },
    "ROLB": {
        "id": "ROLB",
        "name": "ROLLING BOAT"
    },
    "ROLC": {
        "id": "ROLC",
        "name": "ROL"
    },
    "ROLF": {
        "id": "ROLF",
        "name": "ROLL-O-FLEX"
    },
    "ROLG": {
        "id": "ROLG",
        "name": "ROLLIGON - NAV"
    },
    "ROLH": {
        "id": "ROLH",
        "name": "ROLL-A-LONG & STERLING COACH"
    },
    "ROLI": {
        "id": "ROLI",
        "name": "ROLITE"
    },
    "ROLL": {
        "id": "ROLL",
        "name": "ROLLFAST"
    },
    "ROLM": {
        "id": "ROLM",
        "name": "ROLLING HOMES"
    },
    "ROLN": {
        "id": "ROLN",
        "name": "ROLL N TRAILERS HILL CITY"
    },
    "ROLP": {
        "id": "ROLP",
        "name": "MAYBERRY MFD BY ROLLOHOME CORP."
    },
    "ROLR": {
        "id": "ROLR",
        "name": "ROLLS RITE TRAILERS"
    },
    "ROLS": {
        "id": "ROLS",
        "name": "ROLLS INTERNATIONAL TRAILER MOBILE HOME"
    },
    "ROLT": {
        "id": "ROLT",
        "name": "ROLLING THUNDER MOTORCYCLES CUSTOM"
    },
    "ROLY": {
        "id": "ROLY",
        "name": "ROYAL LAND YACHT"
    },
    "ROMA": {
        "id": "ROMA",
        "name": "ROMAE INDUSTRIESMOUNTAIN VIEW"
    },
    "ROMI": {
        "id": "ROMI",
        "name": "ROME INDUSTRIES"
    },
    "ROMW": {
        "id": "ROMW",
        "name": "ROSS MANUFACTURING & WELDING"
    },
    "RONC": {
        "id": "RONC",
        "name": "RON"
    },
    "RONN": {
        "id": "RONN",
        "name": "RONNIE'S DISCOUNT TRAILERS"
    },
    "RONS": {
        "id": "RONS",
        "name": "RON'S TRAILERS INC. OREGON"
    },
    "ROOF": {
        "id": "ROOF",
        "name": "ROOFMASTER"
    },
    "ROOS": {
        "id": "ROOS",
        "name": "ROOSE MANUFACTURING PELLA"
    },
    "ROOT": {
        "id": "ROOT",
        "name": "ROOTES"
    },
    "ROPE": {
        "id": "ROPE",
        "name": "ROPER OUTDOOR POWER PRODUCTS KANKAKEE ILLINOIS"
    },
    "RORA": {
        "id": "RORA",
        "name": "ROAD RANGER CAMPER TRAILER"
    },
    "RORF": {
        "id": "RORF",
        "name": "ROADRUNNER FABRICATION"
    },
    "RORM": {
        "id": "RORM",
        "name": "ROAR MOTORCYCLES"
    },
    "RORU": {
        "id": "RORU",
        "name": "ROAD RUNNER"
    },
    "RORV": {
        "id": "RORV",
        "name": "ROADRUNNER RV SUPPLIERS EPHRATA"
    },
    "ROSA": {
        "id": "ROSA",
        "name": "ROSA TRAILER MANUFACTURING"
    },
    "ROSC": {
        "id": "ROSC",
        "name": "ROS"
    },
    "ROSE": {
        "id": "ROSE",
        "name": "ROSEMONT MOBILE HOME DIV DEROSE INDUSTRIES"
    },
    "ROSN": {
        "id": "ROSN",
        "name": "ROSSION OR ROSSION AUTOMOTIVE - POMPANO BEACH"
    },
    "ROTC": {
        "id": "ROTC",
        "name": "ROTOCHOPPER"
    },
    "ROTE": {
        "id": "ROTE",
        "name": "ROTECH"
    },
    "ROTI": {
        "id": "ROTI",
        "name": "ROCKETT TRAILERS MEDLEY FLORIDA BOAT TRAILERS"
    },
    "ROTO": {
        "id": "ROTO",
        "name": "ROTOCRAFT BOAT TRAILER GRANTS PASS OREGON"
    },
    "ROUL": {
        "id": "ROUL",
        "name": "ROULETTE CARAVAN"
    },
    "ROUS": {
        "id": "ROUS",
        "name": "ROUST-ABOUT"
    },
    "ROV": {
        "id": "ROV",
        "name": "ROVER"
    },
    "ROWS": {
        "id": "ROWS",
        "name": "ROWSE HYDRAULIC RAKE"
    },
    "ROXN": {
        "id": "ROXN",
        "name": "ROXON OFFICINA ITALIANA S.R.L ITALY MOTORCYCLES"
    },
    "ROXR": {
        "id": "ROXR",
        "name": "ROXOR MFG BY MAHINDRA"
    },
    "ROYA": {
        "id": "ROYA",
        "name": "ROYAL BROTHERS"
    },
    "ROYC": {
        "id": "ROYC",
        "name": "ROYAL CREST"
    },
    "ROYE": {
        "id": "ROYE",
        "name": "ROYER FOUNDRY AND MACHINE"
    },
    "ROYF": {
        "id": "ROYF",
        "name": "ROY"
    },
    "ROYG": {
        "id": "ROYG",
        "name": "ROYAL CARGO TRAILERS"
    },
    "ROYH": {
        "id": "ROYH",
        "name": "ROYCRAFT COACH"
    },
    "ROYL": {
        "id": "ROYL",
        "name": "ROYAL INDUSTRIES NOBLE DIV."
    },
    "ROYS": {
        "id": "ROYS",
        "name": "ROYALS INTERNATIONAL MILLERSBURG INDIANA"
    },
    "RPII": {
        "id": "RPII",
        "name": "H & H SNOWMOBILESCLEVELAND"
    },
    "RPMC": {
        "id": "RPMC",
        "name": "RPM-"
    },
    "RPOW": {
        "id": "RPOW",
        "name": "RICKY POWER SPORTS LLC DALLAS TX"
    },
    "RPTL": {
        "id": "RPTL",
        "name": "RP TRAILERS"
    },
    "RPTR": {
        "id": "RPTR",
        "name": "RAPTOR ATV"
    },
    "RRAA": {
        "id": "RRAA",
        "name": "R & H HORSE TRAILER"
    },
    "RRFM": {
        "id": "RRFM",
        "name": "R AND R FARMS MFG WOODLAWN CA"
    },
    "RRMA": {
        "id": "RRMA",
        "name": "FAT CAT MFD BY R & R MFG."
    },
    "RRMM": {
        "id": "RRMM",
        "name": "ROYAL RIDER MOTORCYCLE MANUFACTURING"
    },
    "RRNR": {
        "id": "RRNR",
        "name": "RIDGE RUNNER INDUSTRIES"
    },
    "RROV": {
        "id": "RROV",
        "name": "RANGE ROVER OF NORTH AMERICA"
    },
    "RRSC": {
        "id": "RRSC",
        "name": "ROAD RESCUE"
    },
    "RRTI": {
        "id": "RRTI",
        "name": "R & R TRAILERS INC"
    },
    "RRTS": {
        "id": "RRTS",
        "name": "R & R TRACTOR SALES & PARTS LAKE CHARLES LOUISIANA -TRAILERS"
    },
    "RRTT": {
        "id": "RRTT",
        "name": "R & R TRUCK & TRAILER"
    },
    "RSBG": {
        "id": "RSBG",
        "name": "ROSEBURG TRAILER WORKS TRAVLER TRAILERS OREGON"
    },
    "RSCC": {
        "id": "RSCC",
        "name": "R & S CUSTOM CYCLES MODESTO"
    },
    "RSCG": {
        "id": "RSCG",
        "name": "TJCW INC"
    },
    "RSCT": {
        "id": "RSCT",
        "name": "R & S CUSTOM TRAILERS"
    },
    "RSMI": {
        "id": "RSMI",
        "name": "RS MANUFACTURING INC COLORADO SPRINGS CO"
    },
    "RSNB": {
        "id": "RSNB",
        "name": "ROSENBAUER MOTOR"
    },
    "RSPT": {
        "id": "RSPT",
        "name": "RICH SPECIALTY TRAILERS TOPEKA"
    },
    "RSSY": {
        "id": "RSSY",
        "name": "ROUSSY"
    },
    "RSTB": {
        "id": "RSTB",
        "name": "R"
    },
    "RSTD": {
        "id": "RSTD",
        "name": "RACING SCIENCE TECHNOLOGY DEV."
    },
    "RSTI": {
        "id": "RSTI",
        "name": "R & S TRAILER"
    },
    "RSTR": {
        "id": "RSTR",
        "name": "REELSTRONG INC."
    },
    "RSYS": {
        "id": "RSYS",
        "name": "ROAD SYSTEMS INC"
    },
    "RTCH": {
        "id": "RTCH",
        "name": "RAYTECH POWER SCIENCE AND TECHNOLOGY"
    },
    "RTCN": {
        "id": "RTCN",
        "name": "RUTANA CONCESSIONS NEW SPRINGFIELD OH"
    },
    "RTEK": {
        "id": "RTEK",
        "name": "RAMPTEK HORSESHOE BEND ID"
    },
    "RTHI": {
        "id": "RTHI",
        "name": "REDI HAUL TRAILERS INC FAIRMONT MN"
    },
    "RTHN": {
        "id": "RTHN",
        "name": "RAYTHEON WALTHAM"
    },
    "RTLC": {
        "id": "RTLC",
        "name": "RAMP-AGE TRAILER"
    },
    "RTMU": {
        "id": "RTMU",
        "name": "RTM URUGUAY S.A. OR RTM GROUP"
    },
    "RTRK": {
        "id": "RTRK",
        "name": "ROADTREK ONTARIO CANADA"
    },
    "RTRO": {
        "id": "RTRO",
        "name": "RETRO RIDE TEARDROPS"
    },
    "RTRR": {
        "id": "RTRR",
        "name": "R.T.R. TRTAILERS"
    },
    "RTSL": {
        "id": "RTSL",
        "name": "RELIANCE TRAILER SALES LLC STOCKTON CA"
    },
    "RTSS": {
        "id": "RTSS",
        "name": "REYES TRAILER SALES AND SERVICE HEREFORD TX"
    },
    "RTYZ": {
        "id": "RTYZ",
        "name": "ROARING TOYZ"
    },
    "RUCH": {
        "id": "RUCH",
        "name": "RUCHMORE HOMES"
    },
    "RUCK": {
        "id": "RUCK",
        "name": "RUCKER PERFORMANCE MOTORCYCLE COMPANY"
    },
    "RUDD": {
        "id": "RUDD",
        "name": "RUDD"
    },
    "RUDG": {
        "id": "RUDG",
        "name": "RUDGE WHITWORTH OR RUDGE-WHITWORTH"
    },
    "RUFA": {
        "id": "RUFA",
        "name": "RUF AUTOMOBILES OF AMERICA"
    },
    "RUFF": {
        "id": "RUFF",
        "name": "RUFF & TUFF ELECTRIC VEHICLES"
    },
    "RUGG": {
        "id": "RUGG",
        "name": "RUGG MANUFACTURING"
    },
    "RUGM": {
        "id": "RUGM",
        "name": "RUGGED MOUNTAIN CUSTOM RV NAMPA ID TINY IDAHOMES LLC"
    },
    "RUGT": {
        "id": "RUGT",
        "name": "RUGER TRAILERS"
    },
    "RUII": {
        "id": "RUII",
        "name": "RUSH INDUSTRIES"
    },
    "RULE": {
        "id": "RULE",
        "name": "RULE STEEL TANKS"
    },
    "RUMI": {
        "id": "RUMI",
        "name": "RUMI"
    },
    "RUNA": {
        "id": "RUNA",
        "name": "RUN-ABOUT"
    },
    "RUPP": {
        "id": "RUPP",
        "name": "RUPP"
    },
    "RUSE": {
        "id": "RUSE",
        "name": "FRANK RUSSELL MFG."
    },
    "RUSH": {
        "id": "RUSH",
        "name": "RUSHMORE HOMES"
    },
    "RUSI": {
        "id": "RUSI",
        "name": "RU2 SYSTEMS INC APACHE JUNCTION AZ"
    },
    "RUSO": {
        "id": "RUSO",
        "name": "RUSH & SON"
    },
    "RUSS": {
        "id": "RUSS",
        "name": "RUSS"
    },
    "RUST": {
        "id": "RUST",
        "name": "RUSTLER HORSE TRAILER"
    },
    "RUTM": {
        "id": "RUTM",
        "name": "RUTTMAN"
    },
    "RUTT": {
        "id": "RUTT",
        "name": "RUTT"
    },
    "RVBT": {
        "id": "RVBT",
        "name": "BENTLEY MFG BY NEXUS RVS LLC"
    },
    "RVDL": {
        "id": "RVDL",
        "name": "RV DIRECT LLC SAN BERNARDINO CA"
    },
    "RVFY": {
        "id": "RVFY",
        "name": "THE RV FACTORY LLC WAKARUSA IN"
    },
    "RVII": {
        "id": "RVII",
        "name": "RV INDUSTRIES"
    },
    "RVIS": {
        "id": "RVIS",
        "name": "R-VISION"
    },
    "RVKO": {
        "id": "RVKO",
        "name": "HITCH HIKERMFD.BY R. V. KOMPACTS"
    },
    "RVLA": {
        "id": "RVLA",
        "name": "RIVALSIR"
    },
    "RVMB": {
        "id": "RVMB",
        "name": "MAYBACH MFG BY NEXUS RVS LLC"
    },
    "RVMH": {
        "id": "RVMH",
        "name": "REVERE MANUFACUTURED HOME"
    },
    "RVMT": {
        "id": "RVMT",
        "name": "MT MCKINLEY BRAND MFG BY RIVERSIDE RV INC"
    },
    "RVOK": {
        "id": "RVOK",
        "name": "RIVER OAKS HOMES"
    },
    "RVRI": {
        "id": "RVRI",
        "name": "RIVERSIDE RV INC"
    },
    "RVRW": {
        "id": "RVRW",
        "name": "RIVER WOLF DRIFT BOATS ROSEBURG"
    },
    "RVTC": {
        "id": "RVTC",
        "name": "RIVER VALLEY TRAILER COMPANY BALTIMORE OH"
    },
    "RVTI": {
        "id": "RVTI",
        "name": "RANDALL'S VIP TRAILERS"
    },
    "RVWR": {
        "id": "RVWR",
        "name": "WRAITH MFG BY NEXUS RVS LLC"
    },
    "RWAY": {
        "id": "RWAY",
        "name": "R WAY TRAILER MANUFACTURING CORPORATION"
    },
    "RWMI": {
        "id": "RWMI",
        "name": "RICE & WOOLARD MFG."
    },
    "RWMX": {
        "id": "RWMX",
        "name": "RAWMAXX SA DE CV CUAUHTEMOC CHIHUAHUA MEXICO"
    },
    "RWTL": {
        "id": "RWTL",
        "name": "RENNWORKS TRAILERS LLC FERNANDINA BEACH FL"
    },
    "RXTC": {
        "id": "RXTC",
        "name": "RXT CUSTOM DESIGNS MERLIN OR"
    },
    "RXTL": {
        "id": "RXTL",
        "name": "REAL X TRAILERS MIAMI FL"
    },
    "RYAL": {
        "id": "RYAL",
        "name": "ROYAL ATHENS PARK HOMES"
    },
    "RYAN": {
        "id": "RYAN",
        "name": "RYAN MFG."
    },
    "RYCS": {
        "id": "RYCS",
        "name": "RYCSA"
    },
    "RYDR": {
        "id": "RYDR",
        "name": "RYDER"
    },
    "RYLE": {
        "id": "RYLE",
        "name": "ROYALE LIMOUSINE HAVERHILL MA - A CABOT COACH BUILDERS CO"
    },
    "RYMN": {
        "id": "RYMN",
        "name": "RAY-MAN"
    },
    "RYNR": {
        "id": "RYNR",
        "name": "RAYNER EQUIPMENT SYSTEMS SACRAMENTO"
    },
    "RYOB": {
        "id": "RYOB",
        "name": "RYOBI"
    },
    "RZRB": {
        "id": "RZRB",
        "name": "RAZORBACK TRAILERS PENNSYLVANIA"
    },
    "RZTL": {
        "id": "RZTL",
        "name": "R & Z TRAILER ST. MCMINNVILLE"
    },
    "SAA": {
        "id": "SAA",
        "name": "SAAB"
    },
    "SABE": {
        "id": "SABE",
        "name": "RECREATIVES"
    },
    "SABI": {
        "id": "SABI",
        "name": "SABINE MFG."
    },
    "SABQ": {
        "id": "SABQ",
        "name": "SABRE EQUIPMENT DIV.DIV. SABRE METAL PRODUCTS"
    },
    "SABR": {
        "id": "SABR",
        "name": "SABRA"
    },
    "SABS": {
        "id": "SABS",
        "name": "SABERS SPECIALITIES"
    },
    "SABT": {
        "id": "SABT",
        "name": "SABERTOOTH MOTOR GROUP"
    },
    "SACH": {
        "id": "SACH",
        "name": "SACHS"
    },
    "SADY": {
        "id": "SADY",
        "name": "SANJIANG DYKON MOTORCYCLE"
    },
    "SAEZ": {
        "id": "SAEZ",
        "name": "SAEZ GROUP BENIAJAN MURCIA SPAIN"
    },
    "SAFA": {
        "id": "SAFA",
        "name": "SAFARI CONDO INC"
    },
    "SAFE": {
        "id": "SAFE",
        "name": "SAFEWAY MOBILE HOMES DIV.DIV. COMMODORE CORPORATION"
    },
    "SAFT": {
        "id": "SAFT",
        "name": "SAFTI"
    },
    "SAGE": {
        "id": "SAGE",
        "name": "SAGE MFG."
    },
    "SAHA": {
        "id": "SAHA",
        "name": "SAHARA MOBILE HOMES"
    },
    "SAIC": {
        "id": "SAIC",
        "name": "ST. CLAIR CUSTOM BUILT"
    },
    "SAIJ": {
        "id": "SAIJ",
        "name": "ST. JOSEPH MARINE TRAILER"
    },
    "SAIL": {
        "id": "SAIL",
        "name": "SAILBOATTRANSPORTER TRAILER LLC MICHIGAN"
    },
    "SAIT": {
        "id": "SAIT",
        "name": "SAIL TRAILERS COLUMBUS"
    },
    "SAKI": {
        "id": "SAKI",
        "name": "SAKAI ADAIRSVILLE GA"
    },
    "SALB": {
        "id": "SALB",
        "name": "SALSBURY"
    },
    "SALC": {
        "id": "SALC",
        "name": "SALSCO"
    },
    "SALE": {
        "id": "SALE",
        "name": "SALEM MOBILE HOME TRAILER"
    },
    "SALI": {
        "id": "SALI",
        "name": "SALING MFG."
    },
    "SALM": {
        "id": "SALM",
        "name": "THE SALEM TOOL"
    },
    "SALN": {
        "id": "SALN",
        "name": "SAILIN CENTER INC. NEW JERSEY"
    },
    "SALV": {
        "id": "SALV",
        "name": "SALVACO"
    },
    "SAMC": {
        "id": "SAMC",
        "name": "SAFARI MOTOR COACHES"
    },
    "SAME": {
        "id": "SAME",
        "name": "SAME & LAMBORGHINI TRACTORS OF NORTH AMERICA INC"
    },
    "SAMP": {
        "id": "SAMP",
        "name": "SAMPSON"
    },
    "SAMS": {
        "id": "SAMS",
        "name": "SAMS"
    },
    "SANA": {
        "id": "SANA",
        "name": "SAN ANGELO CHAMBER OF COMMERCE INC SAN ANGELO"
    },
    "SAND": {
        "id": "SAND",
        "name": "SANDS WELDING INC ALBANY"
    },
    "SANG": {
        "id": "SANG",
        "name": "SANG YONG MOTOR COMPANY SOUTH KOREA"
    },
    "SANI": {
        "id": "SANI",
        "name": "SANI-CRUISER"
    },
    "SANJ": {
        "id": "SANJ",
        "name": "SAN-JO TRAILER MFG POTTERSVILLE"
    },
    "SANK": {
        "id": "SANK",
        "name": "SANKER MFG. & SUPPLY"
    },
    "SANL": {
        "id": "SANL",
        "name": "SANTA CRUZ TRAILER MFG."
    },
    "SANP": {
        "id": "SANP",
        "name": "SANTA FE SPRINGS DIV.DIV. OF DIVCO-WAYNE INDUSTRIES"
    },
    "SANR": {
        "id": "SANR",
        "name": "SANTELER BROTHERS"
    },
    "SANT": {
        "id": "SANT",
        "name": "SANTO"
    },
    "SANY": {
        "id": "SANY",
        "name": "SANY AUTOMOBILE HOISTING MACHINERY CO"
    },
    "SARA": {
        "id": "SARA",
        "name": "SARACEN"
    },
    "SARC": {
        "id": "SARC",
        "name": "STARCRAFT MFG."
    },
    "SATL": {
        "id": "SATL",
        "name": "SATELLITE INDUSTRIES"
    },
    "SATO": {
        "id": "SATO",
        "name": "SATOH JAPANESE TRACTOR"
    },
    "SATR": {
        "id": "SATR",
        "name": "SATURN CORPORATION"
    },
    "SATU": {
        "id": "SATU",
        "name": "SATURN HOMES DIV OF HARRELSON CORPORATION"
    },
    "SAUB": {
        "id": "SAUB",
        "name": "SAUBER MANUFACTURING COMPANY VIRGIL"
    },
    "SAVA": {
        "id": "SAVA",
        "name": "SAVAGE COACH MFG."
    },
    "SAVG": {
        "id": "SAVG",
        "name": "SAVAGE MFG. CORP PLEASANT GROVE UTAH"
    },
    "SAVN": {
        "id": "SAVN",
        "name": "SAVANNAH HOMES"
    },
    "SAWD": {
        "id": "SAWD",
        "name": "SOUTHERN ARC WELDING AND DESIGN TAMPA"
    },
    "SAWM": {
        "id": "SAWM",
        "name": "SAW MANUFACTURING LLC SALT LAKE CITY UTAH"
    },
    "SAXN": {
        "id": "SAXN",
        "name": "SAXON MOTORCYCLE COMPANY"
    },
    "SBBI": {
        "id": "SBBI",
        "name": "SOWAL BEACH BUGGYS INC SANTA ROSA BEACH FL"
    },
    "SBLR": {
        "id": "SBLR",
        "name": "SIEBLER"
    },
    "SBLT": {
        "id": "SBLT",
        "name": "SNOWBEAR LIMITED GUELPH ONTARIO CANADA"
    },
    "SBMH": {
        "id": "SBMH",
        "name": "SPRINGBROOK MANUFACTURED HOME"
    },
    "SBMN": {
        "id": "SBMN",
        "name": "SBM NORTHWEST"
    },
    "SBRE": {
        "id": "SBRE",
        "name": "SABRE MANUFACTURED HOME DIV OF SKYLINE HOMES"
    },
    "SBRT": {
        "id": "SBRT",
        "name": "SEABRING HOME"
    },
    "SBSW": {
        "id": "SBSW",
        "name": "SB SOUTHERN WELDING LLC GRAND PRAIRIE TX"
    },
    "SBTI": {
        "id": "SBTI",
        "name": "S & B TRAILERS"
    },
    "SBTT": {
        "id": "SBTT",
        "name": "SEBRING HOME CORP."
    },
    "SCAB": {
        "id": "SCAB",
        "name": "SCARAB"
    },
    "SCAD": {
        "id": "SCAD",
        "name": "SCAD-A-BOUT MFG. & ALUMINUM"
    },
    "SCAG": {
        "id": "SCAG",
        "name": "SCAG POWER EQUIPMENT"
    },
    "SCAM": {
        "id": "SCAM",
        "name": "SCAMP OR SCAMP EVELANDS INC. BACKUS"
    },
    "SCAN": {
        "id": "SCAN",
        "name": "SCANIA ORANGE CONNECTICUT"
    },
    "SCAP": {
        "id": "SCAP",
        "name": "SCAMPER COACH TRAILER"
    },
    "SCAR": {
        "id": "SCAR",
        "name": "SAN CAR TECHNOLOGY CALIFORNIA"
    },
    "SCAT": {
        "id": "SCAT",
        "name": "SKAT OR SKAT-KITTY"
    },
    "SCCH": {
        "id": "SCCH",
        "name": "SCORPION CHOPPERS"
    },
    "SCCI": {
        "id": "SCCI",
        "name": "SOUTHERN COMFORT CABINS"
    },
    "SCCO": {
        "id": "SCCO",
        "name": "SECO"
    },
    "SCEN": {
        "id": "SCEN",
        "name": "SCENIC CAMPERS"
    },
    "SCEP": {
        "id": "SCEP",
        "name": "SCEPTER UTILITY TRAILER"
    },
    "SCHA": {
        "id": "SCHA",
        "name": "SCHANTZ & SONS"
    },
    "SCHC": {
        "id": "SCHC",
        "name": "SCHACHT OR G.S. SCHACHT MOTOR TRUCK COMPANY"
    },
    "SCHD": {
        "id": "SCHD",
        "name": "SCHRADER DUMP TRAILER"
    },
    "SCHE": {
        "id": "SCHE",
        "name": "SCHENKEL BROTHERS MFG."
    },
    "SCHF": {
        "id": "SCHF",
        "name": "SCHIFSKY TRAILERS"
    },
    "SCHI": {
        "id": "SCHI",
        "name": "SCHERTZER EQUIPMENT"
    },
    "SCHK": {
        "id": "SCHK",
        "name": "SCHETKY EQUIPMENT CORP PORTLAND OR"
    },
    "SCHL": {
        "id": "SCHL",
        "name": "SCHEVELLE HOMES SALES CORP. OF ALABAMA"
    },
    "SCHM": {
        "id": "SCHM",
        "name": "SCHIEBOUT MFG."
    },
    "SCHN": {
        "id": "SCHN",
        "name": "SCHIEN BODY & EQUIPMENT"
    },
    "SCHR": {
        "id": "SCHR",
        "name": "SCHLEMMER BOAT"
    },
    "SCHT": {
        "id": "SCHT",
        "name": "SCHULT MOBILE HOMES CORP.MIDDLEBURY"
    },
    "SCHU": {
        "id": "SCHU",
        "name": "SCHNURE HO' TRAILER"
    },
    "SCHW": {
        "id": "SCHW",
        "name": "SCHWARTZ MFG."
    },
    "SCHZ": {
        "id": "SCHZ",
        "name": "SCHWARZE INDUSTRIES"
    },
    "SCIO": {
        "id": "SCIO",
        "name": "SCION NEW LINE"
    },
    "SCIT": {
        "id": "SCIT",
        "name": "SCI TRAILER"
    },
    "SCLR": {
        "id": "SCLR",
        "name": "SCHULER MFG. & EQUIPMENT"
    },
    "SCMF": {
        "id": "SCMF",
        "name": "SCOTT MANUFACTURING"
    },
    "SCMH": {
        "id": "SCMH",
        "name": "SUMMIT CREST MANUFACTURED HOME COLORADO"
    },
    "SCMI": {
        "id": "SCMI",
        "name": "SCOTT MIDLAND DIV.DIV. A-T-O"
    },
    "SCMM": {
        "id": "SCMM",
        "name": "SCHRAMM"
    },
    "SCMP": {
        "id": "SCMP",
        "name": "SCAMP TRAILER"
    },
    "SCMT": {
        "id": "SCMT",
        "name": "SEARCHMONT AUTOMOBILE"
    },
    "SCMW": {
        "id": "SCMW",
        "name": "SCHMUCKER'S WELDING"
    },
    "SCNL": {
        "id": "SCNL",
        "name": "SCHNELLER MANUFACTURING"
    },
    "SCOA": {
        "id": "SCOA",
        "name": "SCOOTALONG"
    },
    "SCOC": {
        "id": "SCOC",
        "name": "SCOTSMAN INDUSTRIES"
    },
    "SCOE": {
        "id": "SCOE",
        "name": "SCOTT EQUIPMENT COMPANY"
    },
    "SCOM": {
        "id": "SCOM",
        "name": "SCOTSMAN MFG."
    },
    "SCOR": {
        "id": "SCOR",
        "name": "SCORPION"
    },
    "SCOT": {
        "id": "SCOT",
        "name": "SCOTT"
    },
    "SCOU": {
        "id": "SCOU",
        "name": "SCOUT MOTOR HOME"
    },
    "SCPA": {
        "id": "SCPA",
        "name": "SCORPA"
    },
    "SCPR": {
        "id": "SCPR",
        "name": "SCORPION MOTORSPORTS"
    },
    "SCRB": {
        "id": "SCRB",
        "name": "SCARAB"
    },
    "SCRP": {
        "id": "SCRP",
        "name": "SCORPIONCROSBY"
    },
    "SCSP": {
        "id": "SCSP",
        "name": "SANTIAGO CHOPPER SPECIALTIES"
    },
    "SCTB": {
        "id": "SCTB",
        "name": "SCOUTBOAT TRAILER"
    },
    "SCTD": {
        "id": "SCTD",
        "name": "SO CAL TEARDROPS UPLAND CA"
    },
    "SCTI": {
        "id": "SCTI",
        "name": "STAR CUSTOM TRAILERS"
    },
    "SCTK": {
        "id": "SCTK",
        "name": "SCAT TRAK CONSTRUCTION EQUIPMENT"
    },
    "SCTL": {
        "id": "SCTL",
        "name": "SC TRAILERS INC LAPORTE"
    },
    "SCTM": {
        "id": "SCTM",
        "name": "SCOTT MURDOCK TRAILERS SALES LLC"
    },
    "SCTS": {
        "id": "SCTS",
        "name": "SCOTT'S RIDING MOWERS"
    },
    "SCUE": {
        "id": "SCUE",
        "name": "SCHEUERLE GERMANY TRAILERS AND TRANSPORTERS"
    },
    "SCUL": {
        "id": "SCUL",
        "name": "SCULLY COACH"
    },
    "SCUS": {
        "id": "SCUS",
        "name": "SEATING CONSTRUCTIRS USA W"
    },
    "SCVW": {
        "id": "SCVW",
        "name": "SCENIC VIEW WELDING"
    },
    "SCWZ": {
        "id": "SCWZ",
        "name": "SCHWARTZ'S TRAILERS SALES"
    },
    "SCYW": {
        "id": "SCYW",
        "name": "SOUTHERN CYCLE WORKS INC AUGUSTA GA"
    },
    "SDCH": {
        "id": "SDCH",
        "name": "SOUTH DADE CHOPPERS"
    },
    "SDCT": {
        "id": "SDCT",
        "name": "SAN DIEGO CUSTOM TRAILERS CALIFORNIA"
    },
    "SDGU": {
        "id": "SDGU",
        "name": "SDGUSA - SPEED DEFIES GRAVITY-BIKE AND ATV PARTS"
    },
    "SDII": {
        "id": "SDII",
        "name": "SPRAYING DEVICES INC AKA SDI VISALIA CALIFORNIA"
    },
    "SDMP": {
        "id": "SDMP",
        "name": "SIDUMP'R TRAILER"
    },
    "SDPM": {
        "id": "SDPM",
        "name": "SDP MANUFACTURING INC ALBANY IN"
    },
    "SDRE": {
        "id": "SDRE",
        "name": "SOUDURE J M CHANTAL INC. QUEBEC"
    },
    "SDWT": {
        "id": "SDWT",
        "name": "SDW TRANSPORT INC"
    },
    "SEAB": {
        "id": "SEAB",
        "name": "SEA BREEZE TRAILER"
    },
    "SEAC": {
        "id": "SEAC",
        "name": "SEACAMPER BOAT TRAILER"
    },
    "SEAD": {
        "id": "SEAD",
        "name": "SEAL COATERS DEPOT"
    },
    "SEAF": {
        "id": "SEAF",
        "name": "SEAGRAVE FIRE APPARATUSCLINTONVILLE"
    },
    "SEAG": {
        "id": "SEAG",
        "name": "SEAGULL"
    },
    "SEAK": {
        "id": "SEAK",
        "name": "SEA KING"
    },
    "SEAL": {
        "id": "SEAL",
        "name": "SEA LION UTILITY TRAILER"
    },
    "SEAM": {
        "id": "SEAM",
        "name": "SEAL MATEDIV. OF TATECO"
    },
    "SEAN": {
        "id": "SEAN",
        "name": "SEAMAN DIGZ-ALLMERRILL"
    },
    "SEAP": {
        "id": "SEAP",
        "name": "SEAL-RITE PRODUCTS"
    },
    "SEAR": {
        "id": "SEAR",
        "name": "SEARS"
    },
    "SEAS": {
        "id": "SEAS",
        "name": "SEA STALLION TRAILERS KLAMATH FALLS OREGON"
    },
    "SEAT": {
        "id": "SEAT",
        "name": "SEAT"
    },
    "SEAW": {
        "id": "SEAW",
        "name": "SEARS"
    },
    "SECA": {
        "id": "SECA",
        "name": "SEED CARGO DOUGLAS GA"
    },
    "SECI": {
        "id": "SECI",
        "name": "STAR ELECTRIC CARS INC"
    },
    "SECO": {
        "id": "SECO",
        "name": "SE"
    },
    "SECU": {
        "id": "SECU",
        "name": "SECURITY INDUSTRIES"
    },
    "SECY": {
        "id": "SECY",
        "name": "SERVICYCLE"
    },
    "SEDI": {
        "id": "SEDI",
        "name": "STURDY WELD EQUIPMENT AND DESIGN INC LYNNWOOD WA"
    },
    "SEEM": {
        "id": "SEEM",
        "name": "SEEMORE FLATBED TRAILER"
    },
    "SEID": {
        "id": "SEID",
        "name": "SEIDEN MOBILE HOMES"
    },
    "SELB": {
        "id": "SELB",
        "name": "SELBURN FLATBED TRAILER"
    },
    "SELC": {
        "id": "SELC",
        "name": "SELEC TRAILERS"
    },
    "SELM": {
        "id": "SELM",
        "name": "SELMA TRAILER & MFG."
    },
    "SELO": {
        "id": "SELO",
        "name": "SELOX MFG."
    },
    "SELR": {
        "id": "SELR",
        "name": "SELLERS TRAILER"
    },
    "SELY": {
        "id": "SELY",
        "name": "SELBY MFG."
    },
    "SEMA": {
        "id": "SEMA",
        "name": "SPECIALITY EQUIPMENT MARKET ASSOCIATION CALIFORNIA"
    },
    "SEMC": {
        "id": "SEMC",
        "name": "SEMLAC CORP."
    },
    "SEMN": {
        "id": "SEMN",
        "name": "SEMINOLE BOAT TRAILER"
    },
    "SEMO": {
        "id": "SEMO",
        "name": "SEMO TANK BAKER EQUIPMENT COMPANY"
    },
    "SENO": {
        "id": "SENO",
        "name": "SENORA TRAILERSYUMA"
    },
    "SEOV": {
        "id": "SEOV",
        "name": "SEAROVER TC CAMPER"
    },
    "SEPI": {
        "id": "SEPI",
        "name": "STANLEY EMERGENCY PRODUCTS INC AKA FIRE 1 MANUFACTURING INC MENDON MA"
    },
    "SERA": {
        "id": "SERA",
        "name": "SERA"
    },
    "SERR": {
        "id": "SERR",
        "name": "SCOTTY SPORTSMANMFD. BY SERRO TRAVEL TRAILER"
    },
    "SETC": {
        "id": "SETC",
        "name": "SELECT TRUCK CENTER BLACKFOOT"
    },
    "SETE": {
        "id": "SETE",
        "name": "SEA TECH TRAILERS MIAMI"
    },
    "SETR": {
        "id": "SETR",
        "name": "SETRA OF NORTH AMERICA  SETRA EVOBUS GMBH"
    },
    "SEVC": {
        "id": "SEVC",
        "name": "SUNN ELECTRIC VEHICLE COMPANY MAINE"
    },
    "SEVT": {
        "id": "SEVT",
        "name": "SEVERE TRAILERS LLC SHADY POINT OK"
    },
    "SEWP": {
        "id": "SEWP",
        "name": "JENKINS EQUIPMENT"
    },
    "SEWR": {
        "id": "SEWR",
        "name": "SEWER EQUIPMENT"
    },
    "SEXT": {
        "id": "SEXT",
        "name": "SEXTON MOTORCYCLE COMPANY"
    },
    "SFET": {
        "id": "SFET",
        "name": "SAFET CAMPER"
    },
    "SFLT": {
        "id": "SFLT",
        "name": "SOUTH FLORIDA TRAILERS"
    },
    "SFM": {
        "id": "SFM",
        "name": "SFM"
    },
    "SFRC": {
        "id": "SFRC",
        "name": "SOUTHERN FRAC"
    },
    "SFSI": {
        "id": "SFSI",
        "name": "SAFE SYSTEMS INC KENT WA"
    },
    "SFST": {
        "id": "SFST",
        "name": "SFS TRUCK SALES"
    },
    "SFTL": {
        "id": "SFTL",
        "name": "SOUTH FLORIDA TRAILER MANUFACTURER"
    },
    "SFWL": {
        "id": "SFWL",
        "name": "SOUTHERN FIELD WELDING BURLEY"
    },
    "SGAC": {
        "id": "SGAC",
        "name": "SOUTH"
    },
    "SGAT": {
        "id": "SGAT",
        "name": "STARGATE MANUFACTURING INC. ONTARIO CANADA"
    },
    "SGEQ": {
        "id": "SGEQ",
        "name": "SIGNATURE EQUIPMENT CORPORATION SALT LAKE CITY"
    },
    "SGMC": {
        "id": "SGMC",
        "name": "STRONG MANUFACTURING COMPANY PINE BLUFF AR"
    },
    "SGNA": {
        "id": "SGNA",
        "name": "SIGNA TOUR CAMPERS"
    },
    "SGNL": {
        "id": "SGNL",
        "name": "SIGNAL MOTOR TRUCK COMPANY - ORIG MFG IN DETROIT MI"
    },
    "SGTL": {
        "id": "SGTL",
        "name": "SGR TRAILERS"
    },
    "SGWY": {
        "id": "SGWY",
        "name": "SEGWAY MOTORIZED PERSONAL TRANSPORTER VMO"
    },
    "SHAC": {
        "id": "SHAC",
        "name": "SHAMROCK CAMPERSDIV. CLASSIC MFG."
    },
    "SHAD": {
        "id": "SHAD",
        "name": "SHERPA ADVENTURES LLC LIBBY MT"
    },
    "SHAM": {
        "id": "SHAM",
        "name": "GALWAY MFD BY SHAMROCK HOMES"
    },
    "SHAN": {
        "id": "SHAN",
        "name": "SHANGHAI JIALING BUSINESS"
    },
    "SHAR": {
        "id": "SHAR",
        "name": "SHARRATT MOBILE HOMES & SUPPLIES"
    },
    "SHAS": {
        "id": "SHAS",
        "name": "SHASTA INDUSTRIES"
    },
    "SHAU": {
        "id": "SHAU",
        "name": "SHOWHAULER TRUCKS"
    },
    "SHAV": {
        "id": "SHAV",
        "name": "SHAVER MFG."
    },
    "SHAW": {
        "id": "SHAW",
        "name": "SHAW-WYNN HOMES CORP."
    },
    "SHAY": {
        "id": "SHAY",
        "name": "SHAY MOTORS CORPORATION"
    },
    "SHBA": {
        "id": "SHBA",
        "name": "SHERWOOD BARONET TENT TRAILER"
    },
    "SHCT": {
        "id": "SHCT",
        "name": "SHETRON CUSTOM TRAILERS SALISBURY NC"
    },
    "SHDO": {
        "id": "SHDO",
        "name": "SHADOW TRAILERS SHADOW BOAT TRAILER GLENCOE"
    },
    "SHDW": {
        "id": "SHDW",
        "name": "SHADOW TRAILERS INC"
    },
    "SHEB": {
        "id": "SHEB",
        "name": "SHELBY AMERICAN"
    },
    "SHEL": {
        "id": "SHEL",
        "name": "SHELBY MOBILE HOMES DIV COMMODORE CORPORATION"
    },
    "SHEM": {
        "id": "SHEM",
        "name": "PULLER-TENSIONER MFD BY SHERMAN & REILLY"
    },
    "SHEN": {
        "id": "SHEN",
        "name": "SHENKE MOTORCYCLE"
    },
    "SHEP": {
        "id": "SHEP",
        "name": "SHERP"
    },
    "SHER": {
        "id": "SHER",
        "name": "SHERTZER DUMP TRAILER"
    },
    "SHET": {
        "id": "SHET",
        "name": "SHETRON MANUFACTURING"
    },
    "SHFT": {
        "id": "SHFT",
        "name": "SHIFT MOTORSPORTS LLC TEXAS"
    },
    "SHGO": {
        "id": "SHGO",
        "name": "SHORT GO"
    },
    "SHGY": {
        "id": "SHGY",
        "name": "SANYOU HOLDING GROUP YINYOU MOTORCYCLE"
    },
    "SHIB": {
        "id": "SHIB",
        "name": "SHIPBUILT VAN TRAILER"
    },
    "SHIL": {
        "id": "SHIL",
        "name": "SHILOH HOMES DOUBLE SPRINGS ALABAMA"
    },
    "SHIM": {
        "id": "SHIM",
        "name": "SHIPMATE SEMEK MFG"
    },
    "SHIN": {
        "id": "SHIN",
        "name": "SHINERAY MOTORCYCLE"
    },
    "SHIP": {
        "id": "SHIP",
        "name": "SHIPPETTE MOBILE HOMES"
    },
    "SHIR": {
        "id": "SHIR",
        "name": "SHIRAUL"
    },
    "SHIV": {
        "id": "SHIV",
        "name": "SHIVVERS MANUFACTURING"
    },
    "SHIW": {
        "id": "SHIW",
        "name": "SHIWEI VEHICLE CO"
    },
    "SHJZ": {
        "id": "SHJZ",
        "name": "SHIJIAZHUANG MFG MOTORCYCLES AND ATV'S"
    },
    "SHLF": {
        "id": "SHLF",
        "name": "SHELF ABOVE MANUFACTURING"
    },
    "SHLN": {
        "id": "SHLN",
        "name": "SHORE LAND'R IDA GROVE IA"
    },
    "SHLQ": {
        "id": "SHLQ",
        "name": "LIVING QUARTER BRAND"
    },
    "SHMH": {
        "id": "SHMH",
        "name": "SHARLO MANUFACTURED HOME"
    },
    "SHNE": {
        "id": "SHNE",
        "name": "SHENGWO NEW ENERGY VEHICLE"
    },
    "SHNG": {
        "id": "SHNG",
        "name": "SHANDONG PIONEER MOTORCYCLE"
    },
    "SHNQ": {
        "id": "SHNQ",
        "name": "SHUNQI VEHICLE INDUSTRY"
    },
    "SHOA": {
        "id": "SHOA",
        "name": "SHOALS AMERICAN INDUSTRIES"
    },
    "SHOB": {
        "id": "SHOB",
        "name": "SHOP BUILT TRAILERS"
    },
    "SHOM": {
        "id": "SHOM",
        "name": "SHO-ME CAMPERS"
    },
    "SHOO": {
        "id": "SHOO",
        "name": "SHOOPS HORSE TRAILER"
    },
    "SHOP": {
        "id": "SHOP",
        "name": "SHOPMADE FLATBED TRAILER"
    },
    "SHOV": {
        "id": "SHOV",
        "name": "SHOVEL SUPPLY"
    },
    "SHOW": {
        "id": "SHOW",
        "name": "SHOW"
    },
    "SHPK": {
        "id": "SHPK",
        "name": "SHORE PARK"
    },
    "SHQI": {
        "id": "SHQI",
        "name": "SHENGQI MOTION APPARATUS"
    },
    "SHQQ": {
        "id": "SHQQ",
        "name": "SHANGHAI SHENQQIANG PLASTIC AND HARDWARE"
    },
    "SHRA": {
        "id": "SHRA",
        "name": "SAHARA ATV'S & FUN CARTS"
    },
    "SHRC": {
        "id": "SHRC",
        "name": "SHER"
    },
    "SHRD": {
        "id": "SHRD",
        "name": "SHERROD VANS"
    },
    "SHRP": {
        "id": "SHRP",
        "name": "SHARP MFG."
    },
    "SHSH": {
        "id": "SHSH",
        "name": "SHIPSHE TRAILERS AND FAB SHIPSHEWANA INDIANA"
    },
    "SHSY": {
        "id": "SHSY",
        "name": "SHERWOOD SENTRY HORSE TRAILER"
    },
    "SHTD": {
        "id": "SHTD",
        "name": "SHANGHAI TANDEM MOTOR"
    },
    "SHTE": {
        "id": "SHTE",
        "name": "SHORTSTOP ENTERPRISES"
    },
    "SHTL": {
        "id": "SHTL",
        "name": "SHELBY TRAILER"
    },
    "SHTR": {
        "id": "SHTR",
        "name": "S & H TRAILER MFG."
    },
    "SHTW": {
        "id": "SHTW",
        "name": "SHOWTOWN MANUFACTURING"
    },
    "SHUA": {
        "id": "SHUA",
        "name": "SHUAGSHI MOTOR MFG."
    },
    "SHUO": {
        "id": "SHUO",
        "name": "SHUOPU SCIENCE AND TECHNOLOGY DEVELOPMENT"
    },
    "SHUT": {
        "id": "SHUT",
        "name": "SCHUTT INDUSTRIES CLINTONVILLE"
    },
    "SHWG": {
        "id": "SHWG",
        "name": "SCHWING AMERICA"
    },
    "SHWI": {
        "id": "SHWI",
        "name": "SCHWINN MOTOR SCOOTERS"
    },
    "SHWM": {
        "id": "SHWM",
        "name": "SHOW-ME TRAILERS"
    },
    "SHWT": {
        "id": "SHWT",
        "name": "SHOWTIME MANUFACTURING RIVERSIDE"
    },
    "SIAT": {
        "id": "SIAT",
        "name": "SIATA"
    },
    "SIBS": {
        "id": "SIBS",
        "name": "BIG SKY BRAND MFG BY SIERRA MOTOR CORPORATION"
    },
    "SICA": {
        "id": "SICA",
        "name": "CAMPFIRE BRAND MFG BY SIERRA MOTOR CORPORATION"
    },
    "SICC": {
        "id": "SICC",
        "name": "S.I.C. CHOPPERS STYLE IN CUSTOM CHOPPERS KENMORE"
    },
    "SICK": {
        "id": "SICK",
        "name": "SICKASSO CYCLE CREATIONS INC DAVIE"
    },
    "SICM": {
        "id": "SICM",
        "name": "SIC METALS AND FABRICATION LLC CLARION"
    },
    "SIDE": {
        "id": "SIDE",
        "name": "SIDESHOW'S CYCLES PEORIA AZ"
    },
    "SIDI": {
        "id": "SIDI",
        "name": "SIDE DUMP INDUSTRIES SOUTH SIOUX CITY NB"
    },
    "SIDK": {
        "id": "SIDK",
        "name": "SIDEKICK RV INC GOSHEN"
    },
    "SIEA": {
        "id": "SIEA",
        "name": "SILVER EAGLE TRAVEL TRAILER"
    },
    "SIEB": {
        "id": "SIEB",
        "name": "SIEBERT TRAILERS"
    },
    "SIEM": {
        "id": "SIEM",
        "name": "SIEMS ENTERPRISES SHELLEY"
    },
    "SIER": {
        "id": "SIER",
        "name": "SIERRA CAMPERS MFG."
    },
    "SIES": {
        "id": "SIES",
        "name": "SIESTA MFG BY THOR MOTOR COACH INC"
    },
    "SIFX": {
        "id": "SIFX",
        "name": "SILVER FOX TRAILERS FRANKTOWN CO"
    },
    "SIGA": {
        "id": "SIGA",
        "name": "SIGAME CORP.GARDENA"
    },
    "SIGN": {
        "id": "SIGN",
        "name": "LEAR SIEGLER MFD BY SIGNAL DIV. OF LEAR SIEGLER"
    },
    "SIKK": {
        "id": "SIKK",
        "name": "SIKK INC."
    },
    "SILA": {
        "id": "SILA",
        "name": "SILA AUTORETTA"
    },
    "SILC": {
        "id": "SILC",
        "name": "DORSETT MOBILE HOMES MFD BY SILVERCREST INDUSTRIES"
    },
    "SILE": {
        "id": "SILE",
        "name": "SILVER LEAF MFG."
    },
    "SILL": {
        "id": "SILL",
        "name": "SILVERLINE"
    },
    "SILM": {
        "id": "SILM",
        "name": "LARAMIE BRAND MFGBY SIERRA MOTOR CORPORATION"
    },
    "SILP": {
        "id": "SILP",
        "name": "SILVER PIGEON"
    },
    "SILR": {
        "id": "SILR",
        "name": "LARIAT BRAND MFG BY SIERRA MOTOR CORPORATION"
    },
    "SILT": {
        "id": "SILT",
        "name": "SILENT HOIST & CRANE"
    },
    "SILV": {
        "id": "SILV",
        "name": "SILVER STREAK TRAILER"
    },
    "SILW": {
        "id": "SILW",
        "name": "SILVER CROWN"
    },
    "SIM": {
        "id": "SIM",
        "name": "SIMCA"
    },
    "SIMA": {
        "id": "SIMA",
        "name": "SIMON ACCESS DOVER"
    },
    "SIMC": {
        "id": "SIMC",
        "name": "SIERRA MOTOR CORPORATION BRISTOL"
    },
    "SIME": {
        "id": "SIME",
        "name": "SIMEK MFG."
    },
    "SIMM": {
        "id": "SIMM",
        "name": "RON SIMMS BAY AREA CUSTOM CYCLES  HAYWARD"
    },
    "SIMN": {
        "id": "SIMN",
        "name": "SIMENSON MFG."
    },
    "SIMO": {
        "id": "SIMO",
        "name": "SIMON UTILITY TRAILER"
    },
    "SIMP": {
        "id": "SIMP",
        "name": "LANDLORD MFD BY SIMPLICITY MFG."
    },
    "SIMS": {
        "id": "SIMS",
        "name": "SIMSON"
    },
    "SIMT": {
        "id": "SIMT",
        "name": "SIMPSON ENTERPRISES"
    },
    "SIMW": {
        "id": "SIMW",
        "name": "SIMPSON WELDING & CUSTOM TRAILERS HOWBOY TRAILERS HUGO"
    },
    "SIN": {
        "id": "SIN",
        "name": "SINGER"
    },
    "SING": {
        "id": "SING",
        "name": "SINGLE TILT TRAILER"
    },
    "SIPR": {
        "id": "SIPR",
        "name": "PRESTIGE BRAND MFG BY SIERRA MOTOR CORPORATION"
    },
    "SIRC": {
        "id": "SIRC",
        "name": "SUN INTERNATIONAL RACING CORP.CALIFORNIA"
    },
    "SISC": {
        "id": "SISC",
        "name": "SCOTTY BRAND MFG BY SIERRA MOTOR CORPORATION"
    },
    "SIST": {
        "id": "SIST",
        "name": "SILVER STAR TRAILER"
    },
    "SISU": {
        "id": "SISU",
        "name": "SISU USA INC."
    },
    "SITE": {
        "id": "SITE",
        "name": "SIGHTSEER MOTOR HOME"
    },
    "SIWE": {
        "id": "SIWE",
        "name": "WEEKENDER BRAND MFG BY SIERRA MOTOR CORPORATION"
    },
    "SIXP": {
        "id": "SIXP",
        "name": "SIX PAC CAMPER"
    },
    "SIZE": {
        "id": "SIZE",
        "name": "SIZEMORE WELDING"
    },
    "SJCI": {
        "id": "SJCI",
        "name": "SJC INDUSTRIES CORP"
    },
    "SJEP": {
        "id": "SJEP",
        "name": "S & J ENTERPRISES MISSOURI"
    },
    "SKAG": {
        "id": "SKAG",
        "name": "SKAGIT PACIFIC CORPORATION SEDRO-WOOLLEY"
    },
    "SKAM": {
        "id": "SKAM",
        "name": "SKAMPER CORP."
    },
    "SKAN": {
        "id": "SKAN",
        "name": "SKANK BIKES BOYNTON BEACH"
    },
    "SKBT": {
        "id": "SKBT",
        "name": "SKEETER BRUSH TRUCKS LLC HILLSBORO TX"
    },
    "SKCT": {
        "id": "SKCT",
        "name": "SKYCAT MODEL MFG"
    },
    "SKEE": {
        "id": "SKEE",
        "name": "SKEETER PRODUCTS INC KILGORE TX"
    },
    "SKII": {
        "id": "SKII",
        "name": "SKILES INDISTRIES"
    },
    "SKIK": {
        "id": "SKIK",
        "name": "SKI KART"
    },
    "SKIM": {
        "id": "SKIM",
        "name": "SKIM AIR TRAILER"
    },
    "SKIP": {
        "id": "SKIP",
        "name": "SKIPPER B TRAILER"
    },
    "SKIR": {
        "id": "SKIR",
        "name": "ARIENSSEE SKIROULE LTD"
    },
    "SKIT": {
        "id": "SKIT",
        "name": "SKI-TOW MFG."
    },
    "SKMD": {
        "id": "SKMD",
        "name": "ACADEMY MOBILE HOMES MFD BY SKYLINE MOTORIZED DIV"
    },
    "SKOD": {
        "id": "SKOD",
        "name": "SKODA"
    },
    "SKPF": {
        "id": "SKPF",
        "name": "SCHWARTZKOPF EXCLUSIVE CUSTOMS MARIA DEL REY"
    },
    "SKPI": {
        "id": "SKPI",
        "name": "SKIPPER INDUSTRIES INC GLEN ROSE TEXAS"
    },
    "SKPP": {
        "id": "SKPP",
        "name": "SKIPPY TRAILER"
    },
    "SKTM": {
        "id": "SKTM",
        "name": "SKYTEAM CORPORATION"
    },
    "SKYC": {
        "id": "SKYC",
        "name": "SKYCRAFT TRAILER"
    },
    "SKYG": {
        "id": "SKYG",
        "name": "SKYGO MOTORCYCLE MANUFACTURER"
    },
    "SKYH": {
        "id": "SKYH",
        "name": "SKYHOMES"
    },
    "SKYI": {
        "id": "SKYI",
        "name": "SKYLARK INDUSTRIES"
    },
    "SKYJ": {
        "id": "SKYJ",
        "name": "SKYJACK FORK"
    },
    "SKYL": {
        "id": "SKYL",
        "name": "SKYLINE CORP.INDIANA MFG OF MULTIPLE BRANDS OF RECREATIONAL VEHICLES"
    },
    "SKYT": {
        "id": "SKYT",
        "name": "SKYTRAK CONSTRUCTION EQUIPMENT"
    },
    "SKYY": {
        "id": "SKYY",
        "name": "SKY COLTON"
    },
    "SLAK": {
        "id": "SLAK",
        "name": "SLACK'S TRAILERS PAULS VALLEY"
    },
    "SLCC": {
        "id": "SLCC",
        "name": "SLABACH CONSTRUCTION"
    },
    "SLEE": {
        "id": "SLEE",
        "name": "SALEEN INC"
    },
    "SLEL": {
        "id": "SLEL",
        "name": "ELECTRA MECCANICA VEHICLES CORP"
    },
    "SLEQ": {
        "id": "SLEQ",
        "name": "SELLICK EQUIPMENT"
    },
    "SLGT": {
        "id": "SLGT",
        "name": "STEPHAN L GREEN TRAILERS NEW JERSEY"
    },
    "SLMF": {
        "id": "SLMF",
        "name": "SEA LION METAL FABRICATORS INC PHILADELPHIA"
    },
    "SLMS": {
        "id": "SLMS",
        "name": "SEALMASTER SANDUSKY"
    },
    "SLND": {
        "id": "SLND",
        "name": "SUN LAND EXPRESS MOTORHOME TOYOTA CHASSIS"
    },
    "SLNG": {
        "id": "SLNG",
        "name": "SLINGSHOT"
    },
    "SLNR": {
        "id": "SLNR",
        "name": "SELLNER MANUFACTURING"
    },
    "SLOA": {
        "id": "SLOA",
        "name": "SLOAT MFG."
    },
    "SLON": {
        "id": "SLON",
        "name": "SLOAN'S EASY LOAD"
    },
    "SLPC": {
        "id": "SLPC",
        "name": "SHANGDONG LIANGZI POWER"
    },
    "SLPE": {
        "id": "SLPE",
        "name": "SLEEP EASY TRAILER"
    },
    "SLTC": {
        "id": "SLTC",
        "name": "SOUTHLAND TRAILER CORP LETHBRIDGE AB CANADA"
    },
    "SLTI": {
        "id": "SLTI",
        "name": "SILVERLITE TRAILERS INC SPRINGFIELD OR"
    },
    "SLVD": {
        "id": "SLVD",
        "name": "SILVERADO TRAILERS"
    },
    "SLVE": {
        "id": "SLVE",
        "name": "SILVER EAGLE MANUFACTURING PORTLAND"
    },
    "SLVO": {
        "id": "SLVO",
        "name": "SILVERADO MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "SLVT": {
        "id": "SLVT",
        "name": "SALVATION TRAILERS MFG LLC WA"
    },
    "SLVW": {
        "id": "SLVW",
        "name": "SILVERWING TRAILERS SODA SPRINGS"
    },
    "SMAC": {
        "id": "SMAC",
        "name": "SMACK TRAILER LLC"
    },
    "SMAL": {
        "id": "SMAL",
        "name": "SMALLEY MFG."
    },
    "SMAR": {
        "id": "SMAR",
        "name": "SMARTLEE"
    },
    "SMAS": {
        "id": "SMAS",
        "name": "SMALL ASS CAMPERS LLC LAS VEGAS"
    },
    "SMBT": {
        "id": "SMBT",
        "name": "SMB TELEIAMOTRE BOLOGNA"
    },
    "SMCA": {
        "id": "SMCA",
        "name": "SMC ACQUISITION CORP BRISTOL IN"
    },
    "SMCC": {
        "id": "SMCC",
        "name": "STANLEY MOTOR CARRIAGE COMPANY NEWTON MA"
    },
    "SMCI": {
        "id": "SMCI",
        "name": "SEMCO"
    },
    "SMCL": {
        "id": "SMCL",
        "name": "SUMNER MANUFACTURING"
    },
    "SMCO": {
        "id": "SMCO",
        "name": "STANDARD MOTOR CORPORATION"
    },
    "SMCY": {
        "id": "SMCY",
        "name": "SOUTHERN MOTORCYCLE WORKS MOTORCYCLE KITS AND PARTS"
    },
    "SMCZ": {
        "id": "SMCZ",
        "name": "STURGIS MOTOCRUZIN USA LLC STURGIS SD"
    },
    "SMEA": {
        "id": "SMEA",
        "name": "SMEAL FIRE APPARATUS"
    },
    "SMEV": {
        "id": "SMEV",
        "name": "SMITH ELECTRIC VEHICLES"
    },
    "SMFG": {
        "id": "SMFG",
        "name": "STL MANUFACTURING"
    },
    "SMIH": {
        "id": "SMIH",
        "name": "L.B. SMITH"
    },
    "SMIL": {
        "id": "SMIL",
        "name": "SMILY"
    },
    "SMIR": {
        "id": "SMIR",
        "name": "SMITH-ROLES LTD."
    },
    "SMIT": {
        "id": "SMIT",
        "name": "SMITH"
    },
    "SMKE": {
        "id": "SMKE",
        "name": "STAGE MOBILE KARL ECKART FAHRZEUGBAU GMBH HOFGEISMAR HUMME GERMANY"
    },
    "SMKN": {
        "id": "SMKN",
        "name": "SMOAKIN CONCEPTS ST MATTHEWS"
    },
    "SMLX": {
        "id": "SMLX",
        "name": "SIMPLEX"
    },
    "SMMC": {
        "id": "SMMC",
        "name": "TAIZHOU CITY SENLONG MOTORCYCLE MFG"
    },
    "SMMH": {
        "id": "SMMH",
        "name": "SUPERIOR MANUFACTURES MANUFACTURED HOME"
    },
    "SMMO": {
        "id": "SMMO",
        "name": "S M MOON MOTORCYLE"
    },
    "SMOK": {
        "id": "SMOK",
        "name": "SMOKEY TRAILER"
    },
    "SMPI": {
        "id": "SMPI",
        "name": "SAM PATTON INDUSTRIES"
    },
    "SMPX": {
        "id": "SMPX",
        "name": "SIMPLEX INDUSTRIES"
    },
    "SMRK": {
        "id": "SMRK",
        "name": "SEA MARK"
    },
    "SMRT": {
        "id": "SMRT",
        "name": "SMART VEHICLES"
    },
    "SMSN": {
        "id": "SMSN",
        "name": "SALMSON FRANCE"
    },
    "SMTC": {
        "id": "SMTC",
        "name": "S & M TRAILER"
    },
    "SMTI": {
        "id": "SMTI",
        "name": "SMART TRAILERS INC"
    },
    "SMTS": {
        "id": "SMTS",
        "name": "SOUTHERN MISSOURI TRAILER SALES"
    },
    "SMTT": {
        "id": "SMTT",
        "name": "SMT-TRIKEBAU INH. STEFFEN MALTRITZ GERMANY TRIKES"
    },
    "SMTY": {
        "id": "SMTY",
        "name": "SMITTY'S MANUFACTURING COMPANY AMES IOWA"
    },
    "SMWD": {
        "id": "SMWD",
        "name": "SMALLWOOD COUNTY LINE CO"
    },
    "SMWI": {
        "id": "SMWI",
        "name": "SOUTHERN MOTORCYCLE WORKS"
    },
    "SMYD": {
        "id": "SMYD",
        "name": "SOUTHMAYD EQUIPMENT CORPORATION"
    },
    "SNAK": {
        "id": "SNAK",
        "name": "SNAKE RIVER TRAILERS"
    },
    "SNAP": {
        "id": "SNAP",
        "name": "SNAPPER"
    },
    "SNAT": {
        "id": "SNAT",
        "name": "SNATCHER TRAILER COMPANY BETTENDORF"
    },
    "SNBO": {
        "id": "SNBO",
        "name": "SUNBIRD BOAT COMPANY COLUMBIA"
    },
    "SNBR": {
        "id": "SNBR",
        "name": "SUNBIRD"
    },
    "SNBT": {
        "id": "SNBT",
        "name": "SUN BELT ENERGY HOUSING"
    },
    "SNCT": {
        "id": "SNCT",
        "name": "SUN COUNTRY TRAILERS PHOENIX"
    },
    "SNDC": {
        "id": "SNDC",
        "name": "SUNDANCE"
    },
    "SNDI": {
        "id": "SNDI",
        "name": "SANDI"
    },
    "SNDP": {
        "id": "SNDP",
        "name": "SANDPIPER"
    },
    "SNDR": {
        "id": "SNDR",
        "name": "HAINAN-SUNDIRO"
    },
    "SNFL": {
        "id": "SNFL",
        "name": "SUNFLOWER TRAVEL TRAILER"
    },
    "SNHU": {
        "id": "SNHU",
        "name": "SUNHOU S & T MOTORCYCLE"
    },
    "SNIT": {
        "id": "SNIT",
        "name": "SNORKEL INTERNATIONAL LLC ELWOOD KS"
    },
    "SNLG": {
        "id": "SNLG",
        "name": "SUN L GROUP"
    },
    "SNLT": {
        "id": "SNLT",
        "name": "SUN-LITE"
    },
    "SNMH": {
        "id": "SNMH",
        "name": "SNOWFLAKE MANUFACTURED HOME"
    },
    "SNND": {
        "id": "SNND",
        "name": "SUNNY D MANUFACTURING OREGON"
    },
    "SNOB": {
        "id": "SNOB",
        "name": "SEA BIRD MFD BY SNO-BIRD TRAILER"
    },
    "SNOC": {
        "id": "SNOC",
        "name": "SNOW TRI SCAT"
    },
    "SNOE": {
        "id": "SNOE",
        "name": "SNOWFLAKE COACH INDUSTRY"
    },
    "SNOJ": {
        "id": "SNOJ",
        "name": "SABRE JET SEE SNO JET"
    },
    "SNOK": {
        "id": "SNOK",
        "name": "SNO SHACK"
    },
    "SNOM": {
        "id": "SNOM",
        "name": "SNO-BANDIT MANUFACTURING"
    },
    "SNOP": {
        "id": "SNOP",
        "name": "SNO-PRO INC DIVISION OF THULE TRAILERS INC"
    },
    "SNOW": {
        "id": "SNOW",
        "name": "UNLISTED SNOWMOBILE MANUFACTURER"
    },
    "SNPY": {
        "id": "SNPY",
        "name": "SNO-PONY . COUPARRAL COMPANY SNOW MACHINES"
    },
    "SNRG": {
        "id": "SNRG",
        "name": "SOUTHERN ENERGY HOMES"
    },
    "SNRZ": {
        "id": "SNRZ",
        "name": "REMINGTON HOMES DALLAS"
    },
    "SNSA": {
        "id": "SNSA",
        "name": "SENSATION"
    },
    "SNSI": {
        "id": "SNSI",
        "name": "SNOWBLAST-SICARD"
    },
    "SNSK": {
        "id": "SNSK",
        "name": "SINSKI SONIK MOTOR TECHNOLOGY"
    },
    "SNTA": {
        "id": "SNTA",
        "name": "SANTA FE TRAILER"
    },
    "SNTE": {
        "id": "SNTE",
        "name": "SANTEE INDUSTRIES SYLMAR"
    },
    "SNTL": {
        "id": "SNTL",
        "name": "SPARTAN TOOL LLC NILES MI"
    },
    "SNTR": {
        "id": "SNTR",
        "name": "SUN TRAILER & UTILITIES MID-WEST CITY OKLAHOMA"
    },
    "SNTT": {
        "id": "SNTT",
        "name": "SNOWTON TRAILER"
    },
    "SNTY": {
        "id": "SNTY",
        "name": "SAND TOYS"
    },
    "SNUB": {
        "id": "SNUB",
        "name": "SNUB-HARBOR OR DAY'S DRYDOCK TRAILERS INDIANA"
    },
    "SNWB": {
        "id": "SNWB",
        "name": "SNOWBEAR LIMITED ONTARIO"
    },
    "SNWC": {
        "id": "SNWC",
        "name": "SNOW"
    },
    "SNYD": {
        "id": "SNYD",
        "name": "SNYDER TRAILER"
    },
    "SOAG": {
        "id": "SOAG",
        "name": "SOUTH AG MANUFACTURING INC"
    },
    "SOCH": {
        "id": "SOCH",
        "name": "SOUTHERN OHIO CHASSIS LLC LEESBURG"
    },
    "SOCI": {
        "id": "SOCI",
        "name": "SOCIETY COACH BUILDERS"
    },
    "SOCO": {
        "id": "SOCO",
        "name": "SOUTH"
    },
    "SOCT": {
        "id": "SOCT",
        "name": "SOUTHERN CLASSIC TRAILER MFG."
    },
    "SODE": {
        "id": "SODE",
        "name": "SODERSTROM MACHINE SHOPCANTON"
    },
    "SOFT": {
        "id": "SOFT",
        "name": "SOFT TOUCH TRAILER"
    },
    "SOIL": {
        "id": "SOIL",
        "name": "SOIL MOVER MFD. DIV."
    },
    "SOL": {
        "id": "SOL",
        "name": "SOL CAT BOAT TRAILER"
    },
    "SOLA": {
        "id": "SOLA",
        "name": "SOLARES TRAILERS HEMET CALIFORNIA TRAILERS"
    },
    "SOLE": {
        "id": "SOLE",
        "name": "SOLECTRIA"
    },
    "SOLO": {
        "id": "SOLO",
        "name": "SOLO MOTORS"
    },
    "SOLS": {
        "id": "SOLS",
        "name": "SOUTHERN LIFESTYLE HOMES"
    },
    "SOLT": {
        "id": "SOLT",
        "name": "SOLAR TECHNOLOGY"
    },
    "SOMW": {
        "id": "SOMW",
        "name": "SOMERSET WELDING & STEEL SOMERSET"
    },
    "SOND": {
        "id": "SOND",
        "name": "SON-DYKE TRAILER"
    },
    "SONI": {
        "id": "SONI",
        "name": "SONI II"
    },
    "SOON": {
        "id": "SOON",
        "name": "SOONER MFG."
    },
    "SOPK": {
        "id": "SOPK",
        "name": "SOPKO MANUFACTURING"
    },
    "SOPR": {
        "id": "SOPR",
        "name": "SOUTHERN PRIDE DISTRIBUTING"
    },
    "SOSL": {
        "id": "SOSL",
        "name": "SOUTHERN SALES"
    },
    "SOTH": {
        "id": "SOTH",
        "name": "SOUTH BAY TRAILERS LOUISVILLE"
    },
    "SOTW": {
        "id": "SOTW",
        "name": "SOUTHWEST WELDING & MFG."
    },
    "SOUA": {
        "id": "SOUA",
        "name": "SOUTHEAST MFG."
    },
    "SOUC": {
        "id": "SOUC",
        "name": "SOUCY LALIBERTE"
    },
    "SOUD": {
        "id": "SOUD",
        "name": "SOUDRE KERR"
    },
    "SOUE": {
        "id": "SOUE",
        "name": "SOUTHEASTERN"
    },
    "SOUF": {
        "id": "SOUF",
        "name": "SOUTH FLORIDA CHOPPERS"
    },
    "SOUH": {
        "id": "SOUH",
        "name": "SOUTHERN TRAILERS"
    },
    "SOUN": {
        "id": "SOUN",
        "name": "SOUND LINE TRAILERS & MANUFACTURING"
    },
    "SOUT": {
        "id": "SOUT",
        "name": "SOUTHLANE HORSE TRAILER"
    },
    "SOUW": {
        "id": "SOUW",
        "name": "SOUTHWEST TRUCK BODY"
    },
    "SOVA": {
        "id": "SOVA",
        "name": "SOVAM"
    },
    "SOWI": {
        "id": "SOWI",
        "name": "SOUTHWIND MOTOR HOME"
    },
    "SPAA": {
        "id": "SPAA",
        "name": "SPACE MOTOR HOME"
    },
    "SPAC": {
        "id": "SPAC",
        "name": "SPACE AGE CAMPERS"
    },
    "SPAE": {
        "id": "SPAE",
        "name": "SPACE-CRAFT TRAILERS MFG."
    },
    "SPAH": {
        "id": "SPAH",
        "name": "SPARROWHAWK TRAILER"
    },
    "SPAM": {
        "id": "SPAM",
        "name": "SPACEMASTER COLUMBIA TENNESSEE"
    },
    "SPAN": {
        "id": "SPAN",
        "name": "SPANO CRANE SALES & SERVICE CORP."
    },
    "SPAR": {
        "id": "SPAR",
        "name": "SPARTA"
    },
    "SPAT": {
        "id": "SPAT",
        "name": "SPARTAN AIRCRAFT"
    },
    "SPAU": {
        "id": "SPAU",
        "name": "SPAULDING MFG. INC. SAGINAW"
    },
    "SPBD": {
        "id": "SPBD",
        "name": "SPEED BIRD"
    },
    "SPBL": {
        "id": "SPBL",
        "name": "SPEEDY BLEACHER CORPORATION JANESVILLE IA"
    },
    "SPBW": {
        "id": "SPBW",
        "name": "SPENCER BOWMAN CUSTOMS AKA - B & B WELDING CUSTOM MOTORCYCLES"
    },
    "SPCC": {
        "id": "SPCC",
        "name": "SPRINGERS CUSTOM CYCLES"
    },
    "SPCE": {
        "id": "SPCE",
        "name": "SPACE ASSEMBLIES"
    },
    "SPCO": {
        "id": "SPCO",
        "name": "SPEECO"
    },
    "SPCT": {
        "id": "SPCT",
        "name": "SPECTOR MANUFACTURING"
    },
    "SPCY": {
        "id": "SPCY",
        "name": "SPRINGCYCLE"
    },
    "SPEA": {
        "id": "SPEA",
        "name": "MARVIN A. SPEARS"
    },
    "SPEC": {
        "id": "SPEC",
        "name": "SPECIAL GO-CART"
    },
    "SPED": {
        "id": "SPED",
        "name": "SPEED"
    },
    "SPEE": {
        "id": "SPEE",
        "name": "SPEEDWAY TRAILER DIV.DIV. DUNPHY BOAT CORP."
    },
    "SPEG": {
        "id": "SPEG",
        "name": "SPIRIT EAGLE"
    },
    "SPEI": {
        "id": "SPEI",
        "name": "SPEICHER BROTHERS"
    },
    "SPEK": {
        "id": "SPEK",
        "name": "SPEED KING MFG."
    },
    "SPEL": {
        "id": "SPEL",
        "name": "SPECIAL ENDEAVORS"
    },
    "SPEM": {
        "id": "SPEM",
        "name": "SPECIALTY MFG."
    },
    "SPEN": {
        "id": "SPEN",
        "name": "SPEN &"
    },
    "SPEP": {
        "id": "SPEP",
        "name": "SPENCER SPORTS PRODUCTS"
    },
    "SPET": {
        "id": "SPET",
        "name": "SPEEDEX TRACTOR"
    },
    "SPGO": {
        "id": "SPGO",
        "name": "SLEEP AND GO CONROE TX GROOVY ADVENTURES INC"
    },
    "SPHA": {
        "id": "SPHA",
        "name": "SPORTS HAULERS OR SPORT HAULER"
    },
    "SPHL": {
        "id": "SPHL",
        "name": "SPORT HAULER BY SPORT CHASSIS"
    },
    "SPHV": {
        "id": "SPHV",
        "name": "SPORT HAVEN BURBANK"
    },
    "SPIE": {
        "id": "SPIE",
        "name": "SPIESSCHAERT ENTERPRISES FOREST GROVE"
    },
    "SPIN": {
        "id": "SPIN",
        "name": "SPEV"
    },
    "SPIR": {
        "id": "SPIR",
        "name": "SPIRIT CARTS LLC NEV-NEIGHBORHOOD ELEC VEH'S"
    },
    "SPLB": {
        "id": "SPLB",
        "name": "LIBERTY RV MFG BY SUNSET PARK AND RV INC SHIPSHEWANA IN"
    },
    "SPLE": {
        "id": "SPLE",
        "name": "SPECIAL EDITION"
    },
    "SPLG": {
        "id": "SPLG",
        "name": "SPORTSMAN'S LODGE MFG BY ATHENS PARK HOMES"
    },
    "SPLH": {
        "id": "SPLH",
        "name": "LIGHTHOUSE MFG BY SPACE CRAFT TRAILERS MANUFACTURING"
    },
    "SPLR": {
        "id": "SPLR",
        "name": "SPACE TRAILERS"
    },
    "SPLT": {
        "id": "SPLT",
        "name": "SPORTLINER TENT TRAILER"
    },
    "SPLZ": {
        "id": "SPLZ",
        "name": "SPECIALIZED TRAILERS WEARE"
    },
    "SPMC": {
        "id": "SPMC",
        "name": "SPEEDSTER MOTORCARS"
    },
    "SPME": {
        "id": "SPME",
        "name": "SUPERIOR MANUFACTURING AND ENGINEERING CORP DENVER CO"
    },
    "SPMN": {
        "id": "SPMN",
        "name": "SPORTSMAN"
    },
    "SPNR": {
        "id": "SPNR",
        "name": "SPRINTER VAN"
    },
    "SPNT": {
        "id": "SPNT",
        "name": "SPRINTER MOTORCYCLE"
    },
    "SPOC": {
        "id": "SPOC",
        "name": "SPORTCOACH CORPORATION OF AMERICA"
    },
    "SPOK": {
        "id": "SPOK",
        "name": "SPOKANE TRAVEL HOMES"
    },
    "SPOM": {
        "id": "SPOM",
        "name": "SHALIMAR MOBILE HOMESMFD. BY SPORTCRAFT HOMES"
    },
    "SPON": {
        "id": "SPON",
        "name": "SPORT KING CAMPERS"
    },
    "SPOR": {
        "id": "SPOR",
        "name": "SPORTSMAN LODGE MFG BY ATHENS PARK HOMES"
    },
    "SPOS": {
        "id": "SPOS",
        "name": "SPORTSMAN'S DREAM MFG."
    },
    "SPOT": {
        "id": "SPOT",
        "name": "SPORT TRAILERS SAN BERNARDINO"
    },
    "SPPI": {
        "id": "SPPI",
        "name": "SPEEDWAY PRODUCTS"
    },
    "SPPR": {
        "id": "SPPR",
        "name": "SPARTAN PRODUCTSWEST ST. PAUL"
    },
    "SPRA": {
        "id": "SPRA",
        "name": "SPRAYLINE TWO WHEEL TRAILER"
    },
    "SPRC": {
        "id": "SPRC",
        "name": "SPARTAN CARGO TRAILERS LLC ALMA GEORGIA"
    },
    "SPRD": {
        "id": "SPRD",
        "name": "SPERRY RAND"
    },
    "SPRE": {
        "id": "SPRE",
        "name": "SPRITE"
    },
    "SPRG": {
        "id": "SPRG",
        "name": "SPORTSRIG.COM"
    },
    "SPRI": {
        "id": "SPRI",
        "name": "SPRITE"
    },
    "SPRK": {
        "id": "SPRK",
        "name": "SPURLOCK VEHICLES"
    },
    "SPRM": {
        "id": "SPRM",
        "name": "SPORTMASTERS OF TENNESSEE"
    },
    "SPRN": {
        "id": "SPRN",
        "name": "SPRINGTRAIL MFG."
    },
    "SPRO": {
        "id": "SPRO",
        "name": "SPROUT"
    },
    "SPRR": {
        "id": "SPRR",
        "name": "SUPERIOR OFFICE TRAILER"
    },
    "SPRS": {
        "id": "SPRS",
        "name": "HOOSIERBOAT TRAILER MFD. BY SPREUER AND SON"
    },
    "SPRT": {
        "id": "SPRT",
        "name": "SPORTCOACH MOTOR HOME"
    },
    "SPRU": {
        "id": "SPRU",
        "name": "RUSH RV MFG BY SUNSET PARK AND RV INC SHIPSHEWANA IN"
    },
    "SPRV": {
        "id": "SPRV",
        "name": "SUPERIOR RV MANUFACTURING VANCOUVER"
    },
    "SPRY": {
        "id": "SPRY",
        "name": "SPRAYRITE MFG."
    },
    "SPSC": {
        "id": "SPSC",
        "name": "SPACE CRAFT MFG BY SPACE CRAFT TRAILERS MANUFACTURING"
    },
    "SPSI": {
        "id": "SPSI",
        "name": "SPECTRUM SPORTS INTERNATIONAL HYDE PARK"
    },
    "SPSL": {
        "id": "SPSL",
        "name": "SUN LITE RV MFG BY SUNSET PARK AND RV INC SHIPSHEWANA IN"
    },
    "SPSR": {
        "id": "SPSR",
        "name": "SUNRAY RV MFG BY SUNSET PARK AND RV INC SHIPSHEWANA IN"
    },
    "SPTA": {
        "id": "SPTA",
        "name": "SPORT TRAIL"
    },
    "SPTC": {
        "id": "SPTC",
        "name": "SPECTRE MANUFACTURING"
    },
    "SPTE": {
        "id": "SPTE",
        "name": "SPECIALIZED TRUCK EQUIPMENT LLC EDEN VALLEY MN"
    },
    "SPTI": {
        "id": "SPTI",
        "name": "SPORT BOAT TRAILERS"
    },
    "SPTK": {
        "id": "SPTK",
        "name": "SPECIAL TRUCKS"
    },
    "SPTL": {
        "id": "SPTL",
        "name": "SPORTSLINER CAMPING TRAILER"
    },
    "SPTM": {
        "id": "SPTM",
        "name": "SPECTRUM STEEL"
    },
    "SPTN": {
        "id": "SPTN",
        "name": "SPARTAN MOTORS"
    },
    "SPTR": {
        "id": "SPTR",
        "name": "SUPERIOR TRAILERS OF GA. INC.DANIELSVILLE"
    },
    "SPTT": {
        "id": "SPTT",
        "name": "SPORTSMAN TRAILERS MFG BY KIBBI"
    },
    "SPTX": {
        "id": "SPTX",
        "name": "SPORTSMOBILE WEST"
    },
    "SPTY": {
        "id": "SPTY",
        "name": "SPOTTY"
    },
    "SPVH": {
        "id": "SPVH",
        "name": "SPIRIT VEHICLES"
    },
    "SPWL": {
        "id": "SPWL",
        "name": "SPEEDWELL MOTOR CAR COMPANY DAYTON"
    },
    "SPWY": {
        "id": "SPWY",
        "name": "SPEEDWAY"
    },
    "SPYD": {
        "id": "SPYD",
        "name": "SPYDER BRAND MFG BY CAN-AM"
    },
    "SPYK": {
        "id": "SPYK",
        "name": "SPYKER"
    },
    "SQUI": {
        "id": "SQUI",
        "name": "SQUIRE TRAVEL TRAILER"
    },
    "SRAI": {
        "id": "SRAI",
        "name": "SAND RAIL FABRICATORS INC COLTON CA"
    },
    "SRAY": {
        "id": "SRAY",
        "name": "SEA RAY KNOXVILLE TENNESSEE"
    },
    "SRFX": {
        "id": "SRFX",
        "name": "SRECO-FLEXIBLE"
    },
    "SRGC": {
        "id": "SRGC",
        "name": "SRC MONTEZUMA"
    },
    "SRJP": {
        "id": "SRJP",
        "name": "SOUDURE REJEAN POMERLEAU"
    },
    "SRNO": {
        "id": "SRNO",
        "name": "SERRANO MFG BY THOR MOTOR COACH INC"
    },
    "SRNT": {
        "id": "SRNT",
        "name": "SERENITY MFG BY TRIPLE E RECREATIONAL VEHICLES CANADA"
    },
    "SRPC": {
        "id": "SRPC",
        "name": "SUPERPAC"
    },
    "SRRA": {
        "id": "SRRA",
        "name": "SIERRA MOTORCYCLE COMPANY INDIANAPOLIS"
    },
    "SRTL": {
        "id": "SRTL",
        "name": "SR TRAILERS FOLEY"
    },
    "SSDL": {
        "id": "SSDL",
        "name": "S & S DURALINE EKMA"
    },
    "SSEA": {
        "id": "SSEA",
        "name": "SOUTHEASTERN SEATING INC TAMPA FL"
    },
    "SSII": {
        "id": "SSII",
        "name": "SHREDDING SYSTEMS"
    },
    "SSIN": {
        "id": "SSIN",
        "name": "SSINSTER CHOPPERS"
    },
    "SSRM": {
        "id": "SSRM",
        "name": "SSR MOTORSPORTS"
    },
    "SSTE": {
        "id": "SSTE",
        "name": "SUNSHINE STAINLESS TANK & EQUIP CO"
    },
    "SSTK": {
        "id": "SSTK",
        "name": "SS TRIKE"
    },
    "SSTL": {
        "id": "SSTL",
        "name": "BPMFD"
    },
    "SSVH": {
        "id": "SSVH",
        "name": "SOUTHEASTERN SPECIALTY VEHICLES NORTH CAROLINA _EMERGENCY VEHICLESAMBULANCES"
    },
    "SSWL": {
        "id": "SSWL",
        "name": "S & S WELDING"
    },
    "SSWW": {
        "id": "SSWW",
        "name": "S AND S WORLDWIDE INC LOGAN UT"
    },
    "STAA": {
        "id": "STAA",
        "name": "STAR MACHINE SHOP"
    },
    "STAC": {
        "id": "STAC",
        "name": "STACK TRUCK"
    },
    "STAD": {
        "id": "STAD",
        "name": "STANDARD STEEL"
    },
    "STAF": {
        "id": "STAF",
        "name": "STAM MFG."
    },
    "STAG": {
        "id": "STAG",
        "name": "STAGE COACH MFG."
    },
    "STAH": {
        "id": "STAH",
        "name": "STAHL A SCOTT FETZER COMPANY CARDINGTON"
    },
    "STAL": {
        "id": "STAL",
        "name": "GRENADIER MOBILE HOMES MFD BY STATLER HOMES MFG"
    },
    "STAM": {
        "id": "STAM",
        "name": "STARMASTER CAMPING TRAILER"
    },
    "STAN": {
        "id": "STAN",
        "name": "STANDARD"
    },
    "STAO": {
        "id": "STAO",
        "name": "STARCRAFT CORP."
    },
    "STAP": {
        "id": "STAP",
        "name": "STAR TANK & TRAILER MFG."
    },
    "STAR": {
        "id": "STAR",
        "name": "STAR TO INCLUDE NEV-NEIGHBORHOOD ELERCTRIC VEHICLES"
    },
    "STAS": {
        "id": "STAS",
        "name": "STAR FIRE"
    },
    "STAT": {
        "id": "STAT",
        "name": "STARTREK"
    },
    "STAU": {
        "id": "STAU",
        "name": "K STAUFFER MANUFACTURING INC MARTINDALE"
    },
    "STAV": {
        "id": "STAV",
        "name": "STARLITE MFG."
    },
    "STAW": {
        "id": "STAW",
        "name": "STAR METEOR FLORIDA DIV OF DIVCO-WAYNE INDUSTRIES"
    },
    "STAX": {
        "id": "STAX",
        "name": "STAR MOBILE HOMES"
    },
    "STBI": {
        "id": "STBI",
        "name": "STURDY BUILT INC SPRINGFIELD OR"
    },
    "STBO": {
        "id": "STBO",
        "name": "STRATOS BOATS"
    },
    "STBR": {
        "id": "STBR",
        "name": "ST. BERNARD MANUFACTURING MENTOR"
    },
    "STCB": {
        "id": "STCB",
        "name": "STARCRAFT BUS - DIVISION OF FOREST RIVER"
    },
    "STCC": {
        "id": "STCC",
        "name": "STUC"
    },
    "STCF": {
        "id": "STCF",
        "name": "STEALTHCRAFT CUSTOM TRAILERS"
    },
    "STCH": {
        "id": "STCH",
        "name": "STRICTLY CHOPPER LLC NAPLES"
    },
    "STCI": {
        "id": "STCI",
        "name": "STC INTERNATIONAL"
    },
    "STCL": {
        "id": "STCL",
        "name": "STONE CANYON LODGES"
    },
    "STCO": {
        "id": "STCO",
        "name": "STARCRAFT CORPORATION"
    },
    "STCP": {
        "id": "STCP",
        "name": "SOUTHWEST"
    },
    "STCR": {
        "id": "STCR",
        "name": "STEELCRAFT"
    },
    "STDP": {
        "id": "STDP",
        "name": "STEYR-DAIMLER-PUCH"
    },
    "STDV": {
        "id": "STDV",
        "name": "STEVENS-DURYEA"
    },
    "STDY": {
        "id": "STDY",
        "name": "STANTON DYNAMICS BROOKVILLE"
    },
    "STEA": {
        "id": "STEA",
        "name": "STEADMAN CONTAINERS LTD. BRAMPTON ONTARIO"
    },
    "STEC": {
        "id": "STEC",
        "name": "STE"
    },
    "STEE": {
        "id": "STEE",
        "name": "STEED-POLLAK MFG."
    },
    "STEF": {
        "id": "STEF",
        "name": "STAINLESS TANK & EQUIPMENT COMPANY"
    },
    "STEG": {
        "id": "STEG",
        "name": "STEW-GAR"
    },
    "STEI": {
        "id": "STEI",
        "name": "STAINLESS TANK & EQUIPMENT INC. WISCONSIN"
    },
    "STEL": {
        "id": "STEL",
        "name": "STEALTH ENTERPRISES LLC ELKHART"
    },
    "STEM": {
        "id": "STEM",
        "name": "STERLING STERLING-SA"
    },
    "STEO": {
        "id": "STEO",
        "name": "STEELCO"
    },
    "STEP": {
        "id": "STEP",
        "name": "STEPPER TRAILER"
    },
    "STER": {
        "id": "STER",
        "name": "STERLING INDUSTRIAL CORP."
    },
    "STES": {
        "id": "STES",
        "name": "STEEL STALLION MOTORCYCLE COMPANY MYRTLE BEACH"
    },
    "STET": {
        "id": "STET",
        "name": "STEWART"
    },
    "STEU": {
        "id": "STEU",
        "name": "STEURY TRAILER"
    },
    "STEW": {
        "id": "STEW",
        "name": "STEWART ENGINEERING & EQUIPMENT"
    },
    "STEY": {
        "id": "STEY",
        "name": "STEYR-PUCH"
    },
    "STFG": {
        "id": "STFG",
        "name": "SCHERER TRIKES FAHRZEUGBAU GMBH GERMANY MOTORCYCLES"
    },
    "STGL": {
        "id": "STGL",
        "name": "STAGELINE SCENE MOBILE"
    },
    "STGR": {
        "id": "STGR",
        "name": "STEIGER TRACTOR MINNESOTA"
    },
    "STGT": {
        "id": "STGT",
        "name": "STAG TRAILERS MFG."
    },
    "STHG": {
        "id": "STHG",
        "name": "SOUTHERN DIMENSIONS GROUP"
    },
    "STHH": {
        "id": "STHH",
        "name": "SOUTHERN HERITAGE TRAILERS"
    },
    "STHI": {
        "id": "STHI",
        "name": "SEATTLE TINY HOMES INC WALLA WALLA"
    },
    "STHL": {
        "id": "STHL",
        "name": "STOHL TRAILERS NORTH CAROLINA"
    },
    "STHM": {
        "id": "STHM",
        "name": "SOUTHEAST MANUFACTURING"
    },
    "STHS": {
        "id": "STHS",
        "name": "STEELHORSE CUSTOM MOTORCYCLES"
    },
    "STHT": {
        "id": "STHT",
        "name": "STEHL TOW PIEDMONT"
    },
    "STHV": {
        "id": "STHV",
        "name": "SOUTHERN VAC LLC"
    },
    "STID": {
        "id": "STID",
        "name": "STIDHAM HORSE TRAILER"
    },
    "STIG": {
        "id": "STIG",
        "name": "STIGERS TRAILER SALES"
    },
    "STIL": {
        "id": "STIL",
        "name": "STILLWATER SUPPLY COMPANY SULLIVAN"
    },
    "STIN": {
        "id": "STIN",
        "name": "STELLAR INDUSTRIES INC"
    },
    "STIP": {
        "id": "STIP",
        "name": "STERLING INNOVATIVE PRODUCTS"
    },
    "STLB": {
        "id": "STLB",
        "name": "STEEL BROS"
    },
    "STLC": {
        "id": "STLC",
        "name": "STEL"
    },
    "STLD": {
        "id": "STLD",
        "name": "STREAMLINE DESIGNS"
    },
    "STLG": {
        "id": "STLG",
        "name": "STERLING"
    },
    "STLL": {
        "id": "STLL",
        "name": "STRETCHED STEEL GREENVILLE SC"
    },
    "STLM": {
        "id": "STLM",
        "name": "STEALTH MANUFACTURING LOUISIANA ELECTRIC LSV'S"
    },
    "STLN": {
        "id": "STLN",
        "name": "STERLINE FLATBED TRAILER"
    },
    "STLO": {
        "id": "STLO",
        "name": "STILO TRAILER"
    },
    "STLT": {
        "id": "STLT",
        "name": "STREAM LITE SPORT & XLT MFG BY GULF STREAM COACH"
    },
    "STLY": {
        "id": "STLY",
        "name": "STANLEY YARD & GARDEN TRACTORS MOWERS"
    },
    "STMC": {
        "id": "STMC",
        "name": "SUPERTRAIL MFG."
    },
    "STMF": {
        "id": "STMF",
        "name": "STORM MANUFACTURING ELKHART"
    },
    "STMG": {
        "id": "STMG",
        "name": "STAR MANUFACTURING CHAMBERSBURG"
    },
    "STMH": {
        "id": "STMH",
        "name": "STATLER MANUFACTURED HOME"
    },
    "STMI": {
        "id": "STMI",
        "name": "STREME TRAILER MFG."
    },
    "STMP": {
        "id": "STMP",
        "name": "STAMPINGS"
    },
    "STNH": {
        "id": "STNH",
        "name": "STANLEY HYDRAULIC TOOLS"
    },
    "STNL": {
        "id": "STNL",
        "name": "STANLEY MACHINES ONTARIO"
    },
    "STNM": {
        "id": "STNM",
        "name": "STONE'S MOTORCYCLE COMPANY NORTHBORO MA"
    },
    "STNR": {
        "id": "STNR",
        "name": "STONERIDGE MFG BY KZRV"
    },
    "STOC": {
        "id": "STOC",
        "name": "STOCKLAND"
    },
    "STOD": {
        "id": "STOD",
        "name": "STODDARD MFG."
    },
    "STOE": {
        "id": "STOE",
        "name": "STONE CONSTRUCTION EQUIPMENT INC.HONEYOYE"
    },
    "STOL": {
        "id": "STOL",
        "name": "STOLL TRAILERS"
    },
    "STON": {
        "id": "STON",
        "name": "STONER TRAIL"
    },
    "STOP": {
        "id": "STOP",
        "name": "STOPOVER TRAVELER & CAMPER"
    },
    "STOT": {
        "id": "STOT",
        "name": "STONER TOTTER TRAILER"
    },
    "STOU": {
        "id": "STOU",
        "name": "STOUGHTON TRAILERS"
    },
    "STOW": {
        "id": "STOW",
        "name": "ECONOROLLMFD. BY STOW MFG."
    },
    "STPH": {
        "id": "STPH",
        "name": "STEPHENS PNEUMATICS"
    },
    "STPK": {
        "id": "STPK",
        "name": "STEWART PARK HOMES"
    },
    "STRA": {
        "id": "STRA",
        "name": "STRALE"
    },
    "STRB": {
        "id": "STRB",
        "name": "STARBUCK TRAILER"
    },
    "STRC": {
        "id": "STRC",
        "name": "STRAHAN MFG."
    },
    "STRD": {
        "id": "STRD",
        "name": "STARDUST TRAVEL TRAILER"
    },
    "STRE": {
        "id": "STRE",
        "name": "STREAMLINE TRAILER"
    },
    "STRG": {
        "id": "STRG",
        "name": "TK STERLING"
    },
    "STRH": {
        "id": "STRH",
        "name": "STROHL CORPORATION MISHAWAKA"
    },
    "STRI": {
        "id": "STRI",
        "name": "DTRICK TRAILERS CORP.FORT WASHINGTON"
    },
    "STRK": {
        "id": "STRK",
        "name": "STAR TRAIL MFD BY STARK BROS. MOTOR SALES"
    },
    "STRL": {
        "id": "STRL",
        "name": "STARLINE TRAILER MFG."
    },
    "STRM": {
        "id": "STRM",
        "name": "STREAMLINE MOTOR HOME"
    },
    "STRN": {
        "id": "STRN",
        "name": "SATURN"
    },
    "STRO": {
        "id": "STRO",
        "name": "STRONGBACK MANUFACTURING"
    },
    "STRP": {
        "id": "STRP",
        "name": "STREAMLINE PRECISION BURLEY"
    },
    "STRR": {
        "id": "STRR",
        "name": "STARCREST MOTOR HOME"
    },
    "STRT": {
        "id": "STRT",
        "name": "STAR-RO TRAILERS"
    },
    "STSI": {
        "id": "STSI",
        "name": "SMART TRANSPORT SOLUTIONS"
    },
    "STST": {
        "id": "STST",
        "name": "STEWART & STEVENSON TRUCKS"
    },
    "STTC": {
        "id": "STTC",
        "name": "STREAMTECH LLC NAMPA ID"
    },
    "STTK": {
        "id": "STTK",
        "name": "STARTRIKE SA LEMANS"
    },
    "STTL": {
        "id": "STTL",
        "name": "STRYKER TRAILERS LLC PIEDMONT AL"
    },
    "STTR": {
        "id": "STTR",
        "name": "STARTRANS BUSES"
    },
    "STTT": {
        "id": "STTT",
        "name": "STAR TRANSPORT TRAILERS"
    },
    "STTX": {
        "id": "STTX",
        "name": "STUNTERX STUNTSHOP"
    },
    "STU": {
        "id": "STU",
        "name": "STUDEBAKER"
    },
    "STUA": {
        "id": "STUA",
        "name": "STUART"
    },
    "STUZ": {
        "id": "STUZ",
        "name": "STUTZ"
    },
    "STWA": {
        "id": "STWA",
        "name": "STEWART-AMOS SWEEPER COMPANY"
    },
    "STWB": {
        "id": "STWB",
        "name": "STEWARTBUILT FAIRBANKS AK"
    },
    "STWF": {
        "id": "STWF",
        "name": "STONEWELL FORGE OR STONEWELL BODIES & MACHINE"
    },
    "STWI": {
        "id": "STWI",
        "name": "STOWELL INDUSTRIES"
    },
    "STWL": {
        "id": "STWL",
        "name": "STEAIRS WELDING MAINE"
    },
    "STWR": {
        "id": "STWR",
        "name": "STOEWER AUTOMOBILES"
    },
    "SUBA": {
        "id": "SUBA",
        "name": "SUBARU"
    },
    "SUBK": {
        "id": "SUBK",
        "name": "SUNNY BROOK RV"
    },
    "SUBL": {
        "id": "SUBL",
        "name": "SUN BLAZER TRAVEL TRAILER"
    },
    "SUBM": {
        "id": "SUBM",
        "name": "SUBURBAN MANUFACTURING"
    },
    "SUBT": {
        "id": "SUBT",
        "name": "SUBURBAN MOTORS"
    },
    "SUDE": {
        "id": "SUDE",
        "name": "SUDENGA INDUSTRIES"
    },
    "SUDM": {
        "id": "SUDM",
        "name": "SUMMIT DESIGN & MANUFACTURING"
    },
    "SUEQ": {
        "id": "SUEQ",
        "name": "SUPERIOR EQUIPMENT"
    },
    "SUFL": {
        "id": "SUFL",
        "name": "SUPER FLEA"
    },
    "SUFW": {
        "id": "SUFW",
        "name": "SUNFLOWER MFG."
    },
    "SUGG": {
        "id": "SUGG",
        "name": "SUGGS MANUFACTURING"
    },
    "SUKP": {
        "id": "SUKP",
        "name": "SUCKERPUNCH SALLYS LLC"
    },
    "SULL": {
        "id": "SULL",
        "name": "SULLAIR CORP.MICHIGAN CITY"
    },
    "SUMA": {
        "id": "SUMA",
        "name": "SUPREME MID-ATLANTIC CORP. JONESTOWN"
    },
    "SUMI": {
        "id": "SUMI",
        "name": "SUPERIOR MFG."
    },
    "SUMM": {
        "id": "SUMM",
        "name": "SUMMERS MFG."
    },
    "SUMT": {
        "id": "SUMT",
        "name": "SUMMITT TRAILER"
    },
    "SUN": {
        "id": "SUN",
        "name": "SUN"
    },
    "SUNB": {
        "id": "SUNB",
        "name": "SUNBEAM MOTORSPORTS"
    },
    "SUNC": {
        "id": "SUNC",
        "name": "SUN COAST TRAILERS MANUFACTURERS"
    },
    "SUND": {
        "id": "SUND",
        "name": "SUNDIAL MOTOR HOME"
    },
    "SUNE": {
        "id": "SUNE",
        "name": "SUNLINER MOTOR HOME"
    },
    "SUNF": {
        "id": "SUNF",
        "name": "SUN N FUN"
    },
    "SUNH": {
        "id": "SUNH",
        "name": "SUNHOME MFG."
    },
    "SUNI": {
        "id": "SUNI",
        "name": "SUNSET MOBILE HOMES"
    },
    "SUNL": {
        "id": "SUNL",
        "name": "SUN STATE MOBILE HOMES"
    },
    "SUNM": {
        "id": "SUNM",
        "name": "FLAMEN"
    },
    "SUNN": {
        "id": "SUNN",
        "name": "SUN VALLEY"
    },
    "SUNO": {
        "id": "SUNO",
        "name": "SUNLINE COACH"
    },
    "SUNP": {
        "id": "SUNP",
        "name": "SUNSET PARK & RV"
    },
    "SUNR": {
        "id": "SUNR",
        "name": "SUN CHASER MFD BY SUN RECREATIONAL INDUSTRIES"
    },
    "SUNS": {
        "id": "SUNS",
        "name": "SUNSHINE HOMESRED BAY"
    },
    "SUNT": {
        "id": "SUNT",
        "name": "SUNSET TRAVELERS"
    },
    "SUNV": {
        "id": "SUNV",
        "name": "SUN DEVIL TRAILERS"
    },
    "SUNW": {
        "id": "SUNW",
        "name": "SUNDOWNER TRAILER"
    },
    "SUNY": {
        "id": "SUNY",
        "name": "SUNRAY RECREATIONAL VEHICLES"
    },
    "SUPB": {
        "id": "SUPB",
        "name": "SB MANUFACTURING"
    },
    "SUPC": {
        "id": "SUPC",
        "name": "SUPREME CORPORATION GOSHEN"
    },
    "SUPE": {
        "id": "SUPE",
        "name": "SUPERIOR MOTOR HOME"
    },
    "SUPF": {
        "id": "SUPF",
        "name": "SUPERFORMANCE INTERNATIONAL SOUTH AFRICA KIT CARS & REPLICAS"
    },
    "SUPH": {
        "id": "SUPH",
        "name": "SUPERIOR HANDLING EQUIPMENT ORMOND BEACH"
    },
    "SUPI": {
        "id": "SUPI",
        "name": "SUPERIOR IDEAL"
    },
    "SUPK": {
        "id": "SUPK",
        "name": "SULLIVAN PALATEK INC MICHIGAN CITY IN"
    },
    "SUPL": {
        "id": "SUPL",
        "name": "SUPERLINE TRAILERS"
    },
    "SUPM": {
        "id": "SUPM",
        "name": "SUPREME INDUSTRIES"
    },
    "SUPR": {
        "id": "SUPR",
        "name": "SUPERIOR TRAILER WORKSLOS ANGELES"
    },
    "SUPS": {
        "id": "SUPS",
        "name": "SUPERIOR STEEL PRODUCTS"
    },
    "SUPT": {
        "id": "SUPT",
        "name": "SUPER TWO"
    },
    "SUPV": {
        "id": "SUPV",
        "name": "SUPERVISION TWO"
    },
    "SUPW": {
        "id": "SUPW",
        "name": "SUPERIOR METAL WORKS SUTHERLIN"
    },
    "SURE": {
        "id": "SURE",
        "name": "SURELOAD"
    },
    "SURF": {
        "id": "SURF",
        "name": "SURF-RIDER"
    },
    "SURG": {
        "id": "SURG",
        "name": "SURGICAL-STEEDS ARIZONA MOTORCYCLES"
    },
    "SURT": {
        "id": "SURT",
        "name": "SURE-TRAC INDIANA MULTILE STYLE TRAILERS"
    },
    "SURV": {
        "id": "SURV",
        "name": "SURVEYOR MOTOR HOME"
    },
    "SUSF": {
        "id": "SUSF",
        "name": "SANTA FE BRAND MFG BY SUNDOWNER TRAILERS"
    },
    "SUSP": {
        "id": "SUSP",
        "name": "SUSPENSIONS UNLIMITED DUNE BUGGY"
    },
    "SUSQ": {
        "id": "SUSQ",
        "name": "SUSQUEHANNA-SANTEE BOAT WORKS"
    },
    "SUST": {
        "id": "SUST",
        "name": "SUPERIOR STORAGE TANKS LA MIRADA"
    },
    "SUTL": {
        "id": "SUTL",
        "name": "SUPERIOR TRAILERS"
    },
    "SUTP": {
        "id": "SUTP",
        "name": "SUTPHEN CORP.AMLIN"
    },
    "SUTR": {
        "id": "SUTR",
        "name": "SUTTER WELDING & MANUFACTURING"
    },
    "SUVE": {
        "id": "SUVE",
        "name": "SUVEGA TIGER"
    },
    "SUZI": {
        "id": "SUZI",
        "name": "SUZUKI"
    },
    "SUZU": {
        "id": "SUZU",
        "name": "SUZULIGHT SU"
    },
    "SVAC": {
        "id": "SVAC",
        "name": "SUPER VACUUM MANUFACTURING"
    },
    "SVCC": {
        "id": "SVCC",
        "name": "SUN VALLEY CAR CARRIERS OR SUN VALLEY TRAILERS PHOENIX"
    },
    "SVCH": {
        "id": "SVCH",
        "name": "SPECIALIZED VEHICLE CORPORATION"
    },
    "SVKM": {
        "id": "SVKM",
        "name": "SERVICE KING MANUFACTURING"
    },
    "SVMC": {
        "id": "SVMC",
        "name": "SHELL VALLEY MOTORS"
    },
    "SVNC": {
        "id": "SVNC",
        "name": "SEVEN CUSTOM CYCLES"
    },
    "SVTR": {
        "id": "SVTR",
        "name": "SILVER TRAIL"
    },
    "SWAE": {
        "id": "SWAE",
        "name": "SWAN INDUSTRIES"
    },
    "SWAN": {
        "id": "SWAN",
        "name": "SWANEE TRAILER MFG."
    },
    "SWCT": {
        "id": "SWCT",
        "name": "SOUTH WALTON CARTS SANTA ROSA BEACH FL"
    },
    "SWED": {
        "id": "SWED",
        "name": "SWEDE BUILT  MOTORCYCLES"
    },
    "SWEE": {
        "id": "SWEE",
        "name": "SWEETHEART MOTOR HOME"
    },
    "SWEM": {
        "id": "SWEM",
        "name": "SMP MFD BY SWEETWATER METAL PRODUCTS"
    },
    "SWEN": {
        "id": "SWEN",
        "name": "SWENSON STEEL PRODUCTS"
    },
    "SWEP": {
        "id": "SWEP",
        "name": "SWEEPSTER"
    },
    "SWEQ": {
        "id": "SWEQ",
        "name": "SOUTHWEST TRUCK RIGGING AND EQUIPMENT INC HOUSTON TX"
    },
    "SWFB": {
        "id": "SWFB",
        "name": "SWIFT BUILT TRAILERS"
    },
    "SWGN": {
        "id": "SWGN",
        "name": "SOUTHWEST GOOSENECK SAN ANTONIO"
    },
    "SWGR": {
        "id": "SWGR",
        "name": "GRISWOLD INDUSTRIES SEE SWINGER SNOWMOBILES"
    },
    "SWIF": {
        "id": "SWIF",
        "name": "SWIFT MOTORSPORTS"
    },
    "SWIM": {
        "id": "SWIM",
        "name": "SWISHER MOWER & MACHINE"
    },
    "SWIN": {
        "id": "SWIN",
        "name": "SWINGER"
    },
    "SWIS": {
        "id": "SWIS",
        "name": "SWISS COLONY TRAVELERS"
    },
    "SWIT": {
        "id": "SWIT",
        "name": "C.M.CUB"
    },
    "SWIV": {
        "id": "SWIV",
        "name": "SWIVEL"
    },
    "SWLD": {
        "id": "SWLD",
        "name": "S & W WELDING"
    },
    "SWM": {
        "id": "SWM",
        "name": "SWM"
    },
    "SWNG": {
        "id": "SWNG",
        "name": "SWINGER CAMPER MOBILE HOME"
    },
    "SWNM": {
        "id": "SWNM",
        "name": "SWINGMASTER CORPORATION FRANKLIN PARK IL"
    },
    "SWNS": {
        "id": "SWNS",
        "name": "SWANSON TILT TRAILER"
    },
    "SWPP": {
        "id": "SWPP",
        "name": "SWEAT'S PORTABLE BUILDINGS INC WRIGHTSVILLE GA"
    },
    "SWTA": {
        "id": "SWTA",
        "name": "SWAY TAMER MFG KLAMATH FALLS OR"
    },
    "SWTM": {
        "id": "SWTM",
        "name": "SOUTHWEST TRAILER MANUFACTURING INC."
    },
    "SWTZ": {
        "id": "SWTZ",
        "name": "SWARTZ WELDING & MFG."
    },
    "SWXP": {
        "id": "SWXP",
        "name": "SOUTHWEST EXPRESSLINE"
    },
    "SXET": {
        "id": "SXET",
        "name": "SAXON PAINTING"
    },
    "SYBQ": {
        "id": "SYBQ",
        "name": "SOUTHERN YANKEE BAR-B-Q"
    },
    "SYCA": {
        "id": "SYCA",
        "name": "SYCAMORE MOBILE HOMES"
    },
    "SYEL": {
        "id": "SYEL",
        "name": "SYSTEMS & ELECTRONICS INC"
    },
    "SYKE": {
        "id": "SYKE",
        "name": "SYKES AMERIPUMPS"
    },
    "SYLS": {
        "id": "SYLS",
        "name": "SYLVAN SPORT LLC"
    },
    "SYLV": {
        "id": "SYLV",
        "name": "SYLVAN DOUBLE SNOWMOBILE TRAILER"
    },
    "SYMG": {
        "id": "SYMG",
        "name": "SYM U.S.A. OR SANYANG MOTORCYCLE OR SANYANG INDUSTRY"
    },
    "SYRE": {
        "id": "SYRE",
        "name": "SYRENA"
    },
    "SZMH": {
        "id": "SZMH",
        "name": "SCHULTZ MANUFACTURED HOME"
    },
    "TACO": {
        "id": "TACO",
        "name": "TACOMA TRAILER"
    },
    "TACQ": {
        "id": "TACQ",
        "name": "TACQUITO"
    },
    "TACR": {
        "id": "TACR",
        "name": "TRACER HORSE TRAILER"
    },
    "TADN": {
        "id": "TADN",
        "name": "TECNICA AUTOMOTRIZ DEL NORESTE MEXI"
    },
    "TADO": {
        "id": "TADO",
        "name": "TADANO AMERICA CORPORATION"
    },
    "TAGA": {
        "id": "TAGA",
        "name": "TAG-A-LONG TRAILERS"
    },
    "TAGE": {
        "id": "TAGE",
        "name": "TARGET PRODUCTS DIVISION"
    },
    "TAHI": {
        "id": "TAHI",
        "name": "TAHITI SKI TRAILERS"
    },
    "TAHO": {
        "id": "TAHO",
        "name": "TAHOE MOTOR HOME"
    },
    "TAIL": {
        "id": "TAIL",
        "name": "TAILOR MAID INDUSTRIES"
    },
    "TAIZ": {
        "id": "TAIZ",
        "name": "TAIZHOU HISOURCE INTERNATIONAL TRADE"
    },
    "TAKA": {
        "id": "TAKA",
        "name": "TAKA"
    },
    "TAKE": {
        "id": "TAKE",
        "name": "TAKEUCHI"
    },
    "TAKT": {
        "id": "TAKT",
        "name": "TAKE 3 TRAILERS BRENHAM"
    },
    "TALA": {
        "id": "TALA",
        "name": "TALBOT LAGO FRANCE"
    },
    "TALB": {
        "id": "TALB",
        "name": "TALBERT MFG. INC.RENSSELAER"
    },
    "TALC": {
        "id": "TALC",
        "name": "TISHOMINGO ACQUISITION LLC TISHOMINGO MS"
    },
    "TALG": {
        "id": "TALG",
        "name": "TAILGATER"
    },
    "TALL": {
        "id": "TALL",
        "name": "TALLERES LOZANO SA DE CV - MEXICO"
    },
    "TALM": {
        "id": "TALM",
        "name": "TAYLOR METAL WORKS"
    },
    "TALR": {
        "id": "TALR",
        "name": "TAYLOR"
    },
    "TAMA": {
        "id": "TAMA",
        "name": "TAMA"
    },
    "TAMC": {
        "id": "TAMC",
        "name": "THE ADVANCE METALWORKING"
    },
    "TAMP": {
        "id": "TAMP",
        "name": "TAMPO MANUFACTURING"
    },
    "TAMT": {
        "id": "TAMT",
        "name": "TAMPA TRAILERWORKS"
    },
    "TANA": {
        "id": "TANA",
        "name": "TANA INDUSTRIES BILLINGS"
    },
    "TAND": {
        "id": "TAND",
        "name": "TANDEN BOAT TRAILER"
    },
    "TANK": {
        "id": "TANK",
        "name": "TANKRAFT TRAILER"
    },
    "TANM": {
        "id": "TANM",
        "name": "T & M ENTERPRISES COOKVILLE"
    },
    "TANN": {
        "id": "TANN",
        "name": "TANNEHILL TRAILER"
    },
    "TANO": {
        "id": "TANO",
        "name": "TANDOM BOAT TRAILER"
    },
    "TAOE": {
        "id": "TAOE",
        "name": "3 AND 1 ENTERPRISES LLC"
    },
    "TAOI": {
        "id": "TAOI",
        "name": "TAOTAO INDUSTRY"
    },
    "TAOR": {
        "id": "TAOR",
        "name": "TRACKABOUT OFF ROAD CAMPERS QUEENSLAND AUSTRALIA"
    },
    "TARA": {
        "id": "TARA",
        "name": "TARA PRODUCTS"
    },
    "TARG": {
        "id": "TARG",
        "name": "TARGET COACH MFG."
    },
    "TARN": {
        "id": "TARN",
        "name": "TARNEL USA CAR HAULER TRAILERS AND OTHER STYLES"
    },
    "TART": {
        "id": "TART",
        "name": "TARTAN CORP.DIV. AMERICAN DURALITE CORPORATION"
    },
    "TASP": {
        "id": "TASP",
        "name": "TARASPORT TRAILERS"
    },
    "TATA": {
        "id": "TATA",
        "name": "TATA ENGINEERING & LOCOMOTIVE"
    },
    "TATI": {
        "id": "TATI",
        "name": "TAT INC WHITES CREEK TN"
    },
    "TATM": {
        "id": "TATM",
        "name": "TATUM MOTOR SPORTS"
    },
    "TATR": {
        "id": "TATR",
        "name": "TATRA"
    },
    "TATS": {
        "id": "TATS",
        "name": "TATSA"
    },
    "TAUB": {
        "id": "TAUB",
        "name": "TAUBE TOOL CORP.MFD. TAUBE N TAUBE TRAILER"
    },
    "TAUN": {
        "id": "TAUN",
        "name": "TAUNUS"
    },
    "TAWA": {
        "id": "TAWA",
        "name": "TAWAS TRAILERS"
    },
    "TAXA": {
        "id": "TAXA",
        "name": "TAXA LP HOUSTON"
    },
    "TAYD": {
        "id": "TAYD",
        "name": "TAYLOR-DUNN INDUSTRIAL ELECTRIC VEHICLESANAHEIM"
    },
    "TAYI": {
        "id": "TAYI",
        "name": "TAYLOR IRONWORKS"
    },
    "TAYL": {
        "id": "TAYL",
        "name": "TAYLOR MOBILE HOMESDIV. TROY LUMBER COMPANY"
    },
    "TAYM": {
        "id": "TAYM",
        "name": "TAYLOR MADE MANUFACTURING"
    },
    "TAYO": {
        "id": "TAYO",
        "name": "TAYO MOTORCYCLE TECHNOLOGY"
    },
    "TAYR": {
        "id": "TAYR",
        "name": "TAYLOR MACHINE WORKS"
    },
    "TAYT": {
        "id": "TAYT",
        "name": "TAYLOR TRAVEL TRAILER SERVICING ONTARIO"
    },
    "TBC": {
        "id": "TBC",
        "name": "TRAVEL CRUISER"
    },
    "TBCT": {
        "id": "TBCT",
        "name": "TBC"
    },
    "TBEI": {
        "id": "TBEI",
        "name": "TEBBEN ENTERPRISES INC CLARA CITY MN"
    },
    "TBJT": {
        "id": "TBJT",
        "name": "TRIPLE B & J TRAILERS MFG."
    },
    "TBLC": {
        "id": "TBLC",
        "name": "TETON BUILDINGS LLC MILLS WY"
    },
    "TBLZ": {
        "id": "TBLZ",
        "name": "TAIZHOU TRAIL-BLAZER MANUFACTURING"
    },
    "TBTW": {
        "id": "TBTW",
        "name": "T & B TRAILERS & WELDING  LOCKWOD"
    },
    "TBWT": {
        "id": "TBWT",
        "name": "T AND B WELDING AND TRAILERS LOCKWOOD MO"
    },
    "TBWZ": {
        "id": "TBWZ",
        "name": "TOW BLAZER MFG BY- ZHONGMAO MACHINERY"
    },
    "TBZI": {
        "id": "TBZI",
        "name": "TOWBLAZER INC GREENVILLE SC"
    },
    "TCAR": {
        "id": "TCAR",
        "name": "TOMCAR USA"
    },
    "TCBC": {
        "id": "TCBC",
        "name": "TCB CHOPPERS"
    },
    "TCCF": {
        "id": "TCCF",
        "name": "3C CATTLE FEEDERS INC MILL CREEK OK"
    },
    "TCDD": {
        "id": "TCDD",
        "name": "TECHNICAL COMPONENTS DEVELOPMENT AND DESIGN"
    },
    "TCEC": {
        "id": "TCEC",
        "name": "THE CONCRETE EDGE COMPANY ORLANDO FL"
    },
    "TCHA": {
        "id": "TCHA",
        "name": "TCHAIKA"
    },
    "TCHO": {
        "id": "TCHO",
        "name": "T-CHOICE OR TC PRODUCTS TRAILERS"
    },
    "TCI": {
        "id": "TCI",
        "name": "TALBOT-CARLSON"
    },
    "TCIN": {
        "id": "TCIN",
        "name": "TC INDUSTRIES"
    },
    "TCIP": {
        "id": "TCIP",
        "name": "HERCULIFTMFD. BY TCI POWER PRODUCTS"
    },
    "TCMC": {
        "id": "TCMC",
        "name": "TCM CORPORATION & TCM LIFT TRUCKS"
    },
    "TCMH": {
        "id": "TCMH",
        "name": "TOWN AND COUNTRY MANUFACTURED HOME"
    },
    "TCOR": {
        "id": "TCOR",
        "name": "TRANS2 CORPORATION MICHIGAN"
    },
    "TCRW": {
        "id": "TCRW",
        "name": "TRIPLE CROWN TRAILERS OCALA"
    },
    "TCSN": {
        "id": "TCSN",
        "name": "TECH SUN TRAILERS SEABROOK"
    },
    "TCTD": {
        "id": "TCTD",
        "name": "TC TEARDROPS"
    },
    "TCTL": {
        "id": "TCTL",
        "name": "TC TRAILERS WICHITA FALLS TX"
    },
    "TCWI": {
        "id": "TCWI",
        "name": "THUNDER CHOPPER WORKS INC."
    },
    "TDCM": {
        "id": "TDCM",
        "name": "TDC MANUFACTURING"
    },
    "TDEN": {
        "id": "TDEN",
        "name": "T & D ENTERPRISES"
    },
    "TDMI": {
        "id": "TDMI",
        "name": "THERM DYNAMICS MANUFACTURING INC TEA SD"
    },
    "TDSL": {
        "id": "TDSL",
        "name": "T AND D SCOTT LLC"
    },
    "TDTL": {
        "id": "TDTL",
        "name": "TEXAS DISCOUNT TRAILER HOUSTON TX"
    },
    "TDYN": {
        "id": "TDYN",
        "name": "TERRADYNE ARMORED VEHICLES"
    },
    "TEAR": {
        "id": "TEAR",
        "name": "TEAR DROP"
    },
    "TEBB": {
        "id": "TEBB",
        "name": "TEBBEN MFG."
    },
    "TEC": {
        "id": "TEC",
        "name": "TEC"
    },
    "TECL": {
        "id": "TECL",
        "name": "TECHLINE ENGINEERING"
    },
    "TECM": {
        "id": "TECM",
        "name": "TECUMSEH PRODUCTS"
    },
    "TECN": {
        "id": "TECN",
        "name": "TECNOMA"
    },
    "TECU": {
        "id": "TECU",
        "name": "TECUMSEH TRAILERS MANITOBA CANADA"
    },
    "TEEE": {
        "id": "TEEE",
        "name": "TRIPLE E CANADA"
    },
    "TEEH": {
        "id": "TEEH",
        "name": "T&E ENTERPRISES OF HERSCHER"
    },
    "TEEN": {
        "id": "TEEN",
        "name": "TEE NEE TRAILER"
    },
    "TEJA": {
        "id": "TEJA",
        "name": "TEJAS TRAILERS"
    },
    "TELS": {
        "id": "TELS",
        "name": "TEL STAR TRAILERS"
    },
    "TEMA": {
        "id": "TEMA",
        "name": "TERRAMAC LLC ELBURN IL"
    },
    "TEMC": {
        "id": "TEMC",
        "name": "TEM"
    },
    "TEMI": {
        "id": "TEMI",
        "name": "TEMISKO CANADA"
    },
    "TEMK": {
        "id": "TEMK",
        "name": "TEM KEN TRAILERS MFG."
    },
    "TEMP": {
        "id": "TEMP",
        "name": "TEMPO"
    },
    "TEMS": {
        "id": "TEMS",
        "name": "TEMSA GLOBAL SANAYI TICARET A.S. BUSES"
    },
    "TENA": {
        "id": "TENA",
        "name": "TENNANT"
    },
    "TENN": {
        "id": "TENN",
        "name": "TENNESSEE TRAILERS"
    },
    "TEPE": {
        "id": "TEPE",
        "name": "TEMPE TRAILERS"
    },
    "TEPR": {
        "id": "TEPR",
        "name": "PRESTIGE MFG BY TRIPLE E"
    },
    "TERA": {
        "id": "TERA",
        "name": "TERRA CRUISER DIV.DIV"
    },
    "TERF": {
        "id": "TERF",
        "name": "TERRA-MARINA MFG."
    },
    "TERM": {
        "id": "TERM",
        "name": "TERRAMITE CORP."
    },
    "TERR": {
        "id": "TERR",
        "name": "TERRAVAC CORP.STOCKTON"
    },
    "TERS": {
        "id": "TERS",
        "name": "TERRY INDUSTRIES OF VIRGINIA"
    },
    "TERX": {
        "id": "TERX",
        "name": "TEREX DIV.EUCLID & TEREX PRODUCTS & TEREX ADVANCE COEQ"
    },
    "TESC": {
        "id": "TESC",
        "name": "T.E.S."
    },
    "TESH": {
        "id": "TESH",
        "name": "TESH AND SONS INC"
    },
    "TESI": {
        "id": "TESI",
        "name": "TESTI"
    },
    "TESK": {
        "id": "TESK",
        "name": "TESKE MANUFACTURING MINNESOTA TRAILERS"
    },
    "TESL": {
        "id": "TESL",
        "name": "TESLA MOTORS  CALIFORNIA"
    },
    "TETC": {
        "id": "TETC",
        "name": "TEENE TRAILER COMPANY YOUNGSTOWN"
    },
    "TETO": {
        "id": "TETO",
        "name": "TETON TRAVEL TRAILER"
    },
    "TETT": {
        "id": "TETT",
        "name": "TET"
    },
    "TEWO": {
        "id": "TEWO",
        "name": "WONDER BRAND MFG BY TRIPLE E CANADA"
    },
    "TEXA": {
        "id": "TEXA",
        "name": "TEXAN TRAILER MFG."
    },
    "TEXE": {
        "id": "TEXE",
        "name": "TEXELENT SPRING BRANCH TX"
    },
    "TEXK": {
        "id": "TEXK",
        "name": "TEXARKANA DIV.DIV. OF DIVCO-WAYNE INDUSTRIES"
    },
    "TEXL": {
        "id": "TEXL",
        "name": "TEX WILLETT"
    },
    "TEXM": {
        "id": "TEXM",
        "name": "TEXAS MADE TRAILERS"
    },
    "TEXO": {
        "id": "TEXO",
        "name": "TEXOMA"
    },
    "TEXP": {
        "id": "TEXP",
        "name": "TEXAS PRIDE TRAILERS"
    },
    "TEXS": {
        "id": "TEXS",
        "name": "TEXAS BRAGG ENTERPRISESMT. PLEASANT"
    },
    "TEXT": {
        "id": "TEXT",
        "name": "TEXTRON SPECIALIZED VEHICLES INC THIEF RIVER FALLS MN"
    },
    "TFCN": {
        "id": "TFCN",
        "name": "TRAFCON INDUSTRIES"
    },
    "TFFB": {
        "id": "TFFB",
        "name": "TUFF BOY"
    },
    "TFFT": {
        "id": "TFFT",
        "name": "TUFF TRAILER INC"
    },
    "TFLG": {
        "id": "TFLG",
        "name": "TUFF"
    },
    "TFRT": {
        "id": "TFRT",
        "name": "TUFFR TRAILERS LLC COKATO MN"
    },
    "TGCT": {
        "id": "TGCT",
        "name": "TIGERCAT FORESTRY TIMBER & FORESTRY EQUIPMENT"
    },
    "TGMI": {
        "id": "TGMI",
        "name": "TGMI"
    },
    "TGRL": {
        "id": "TGRL",
        "name": "TIGERLINE TRAILER"
    },
    "TGUY": {
        "id": "TGUY",
        "name": "THE TRAILER GUY LOVELAND CO"
    },
    "TGVS": {
        "id": "TGVS",
        "name": "TRANSEO GLOBAL VEHICLES SOLUTIONS OHIO ARMORED VEHS"
    },
    "THAC": {
        "id": "THAC",
        "name": "THE THACKERY"
    },
    "THAM": {
        "id": "THAM",
        "name": "THAMES"
    },
    "THAR": {
        "id": "THAR",
        "name": "ARIA BRAND MFG BY THOR MOTOR COACH"
    },
    "THAT": {
        "id": "THAT",
        "name": "JOSEPH THATCHER"
    },
    "THAW": {
        "id": "THAW",
        "name": "THAWZALL LLC OR THE MACHINE COMPANY MINNESOTA"
    },
    "THAX": {
        "id": "THAX",
        "name": "AXIS BRAND MFG BY THOR MOTOR COACH"
    },
    "THAY": {
        "id": "THAY",
        "name": "THAY"
    },
    "THBD": {
        "id": "THBD",
        "name": "THROUGHBRED MOTORSPORTS INC TROUP"
    },
    "THBL": {
        "id": "THBL",
        "name": "THURO-BILT MFG BY ROSEBURG TRAILER WORKS"
    },
    "THBT": {
        "id": "THBT",
        "name": "THUNDERBOLT TRAILER"
    },
    "THBX": {
        "id": "THBX",
        "name": "THIBODEAUX FABRICATORS"
    },
    "THCK": {
        "id": "THCK",
        "name": "THUNDER CREEK EQUIPMENT"
    },
    "THCP": {
        "id": "THCP",
        "name": "COMPASS BRAND MFG BY THOR MOTOR COACH INC"
    },
    "THDL": {
        "id": "THDL",
        "name": "DELANO MFG BY THOR MOTOR COACH INC"
    },
    "THEE": {
        "id": "THEE",
        "name": "THEE KIT BOYNTON BEACH"
    },
    "THEM": {
        "id": "THEM",
        "name": "THE METAL SHOP JENA"
    },
    "THEU": {
        "id": "THEU",
        "name": "THEURER ATLANTIC"
    },
    "THFM": {
        "id": "THFM",
        "name": "FUN MOVER BRAND MFG BY THOR MOTOR COACH INC"
    },
    "THFT": {
        "id": "THFT",
        "name": "FREEDOM TRAVELER BRAND MFG BY THOR MOTOR COACH"
    },
    "THGH": {
        "id": "THGH",
        "name": "THE HIGHLAND GROUP BEACHWOOD"
    },
    "THGM": {
        "id": "THGM",
        "name": "GEMINI BRAND MFG BY THOR MOTOR COACH INC"
    },
    "THHH": {
        "id": "THHH",
        "name": "TRIPLE H TRAILERS SOLON"
    },
    "THIE": {
        "id": "THIE",
        "name": "THIERMANN UTILITY EQUIPMENT DIV.MILWAUKEE"
    },
    "THII": {
        "id": "THII",
        "name": "THOROUGHBRED INDUSTRIES"
    },
    "THIL": {
        "id": "THIL",
        "name": "THIELE"
    },
    "THIM": {
        "id": "THIM",
        "name": "THIMSEN MFG."
    },
    "THMC": {
        "id": "THMC",
        "name": "THOR MOTOR COACH"
    },
    "THMG": {
        "id": "THMG",
        "name": "MAGNITUDE MFG BY THOR MOTOR COACH"
    },
    "THMI": {
        "id": "THMI",
        "name": "MIRAMAR"
    },
    "THMP": {
        "id": "THMP",
        "name": "THOMPSON CONCRETE PUMP TRAILER"
    },
    "THMS": {
        "id": "THMS",
        "name": "THOMAS BUILT BUS INC HIGH POINT NC"
    },
    "THND": {
        "id": "THND",
        "name": "THUNDER BIKES"
    },
    "THNK": {
        "id": "THNK",
        "name": "THINK NORTH AMERICA INC PREVIOUSLY A DIV OF FORD MOTOR"
    },
    "THOA": {
        "id": "THOA",
        "name": "BOATOTERMFD. BY THOMAS MFG."
    },
    "THOC": {
        "id": "THOC",
        "name": "THOR CALIFORNIA"
    },
    "THOE": {
        "id": "THOE",
        "name": "THOMAS EQUIPMENT LTD. NEW BRUNSWICK"
    },
    "THOI": {
        "id": "THOI",
        "name": "OMI MFG BY THOR MOTOR COACH"
    },
    "THOM": {
        "id": "THOM",
        "name": "THOMAS &"
    },
    "THON": {
        "id": "THON",
        "name": "THORNS TRAILER"
    },
    "THOP": {
        "id": "THOP",
        "name": "THOR POWER TOOL"
    },
    "THOR": {
        "id": "THOR",
        "name": "THOROUGHBRED BOAT TRAILER"
    },
    "THOS": {
        "id": "THOS",
        "name": "THOMAS CONVEYOR"
    },
    "THPN": {
        "id": "THPN",
        "name": "THOMPSON"
    },
    "THQT": {
        "id": "THQT",
        "name": "QUANTUM BRAND MFG BY THOR MOTOR COACH INC"
    },
    "THRC": {
        "id": "THRC",
        "name": "THREE C'S"
    },
    "THRE": {
        "id": "THRE",
        "name": "THREE-DIMENSIONAL MOBILES"
    },
    "THRF": {
        "id": "THRF",
        "name": "THREE FEATHERS MANUFACTURING"
    },
    "THRM": {
        "id": "THRM",
        "name": "THREE-WAY CAMPERS MFG."
    },
    "THRU": {
        "id": "THRU",
        "name": "THRU AIR TRAILERS LLC WEST SENECA"
    },
    "THSE": {
        "id": "THSE",
        "name": "THOMSEN EQUIPMENT"
    },
    "THSQ": {
        "id": "THSQ",
        "name": "SEQUENCE MFG BY THOR MOTOR COACH INC"
    },
    "THST": {
        "id": "THST",
        "name": "THISTLE TRAILER"
    },
    "THSY": {
        "id": "THSY",
        "name": "SYNERGY BRAND MFG BY THOR MOTOR COACH INC"
    },
    "THTB": {
        "id": "THTB",
        "name": "TIBRON MFG BY THOR MOTOR COACH INC"
    },
    "THTC": {
        "id": "THTC",
        "name": "TAIWAN HELIO TECHNOLOGY"
    },
    "THTI": {
        "id": "THTI",
        "name": "THOR TRUCKS INC NORTH HOLLYWOOD CA"
    },
    "THUG": {
        "id": "THUG",
        "name": "THUG CUSTOM CYCLES"
    },
    "THUL": {
        "id": "THUL",
        "name": "THULE TRAILERS"
    },
    "THUM": {
        "id": "THUM",
        "name": "THUMPSTAR OR THUMP"
    },
    "THUN": {
        "id": "THUN",
        "name": "THUNDERBIRD CASTLES DIV THUNDERBIRD PRODUCTS"
    },
    "THUR": {
        "id": "THUR",
        "name": "THURSTON"
    },
    "THVG": {
        "id": "THVG",
        "name": "VEGAS BRAND MFG BY THOR MOTOR COACH"
    },
    "THVN": {
        "id": "THVN",
        "name": "VENETIAN BRAND MFG BY THOR MOTOR COACH INC"
    },
    "THWK": {
        "id": "THWK",
        "name": "TOMAHAWK FABRICATING PENNSYLVANIA"
    },
    "THWT": {
        "id": "THWT",
        "name": "THIRD WHEEL TRAILERS INCORPORATED ONTARIO"
    },
    "THWY": {
        "id": "THWY",
        "name": "THRU-WAY TRAILERS"
    },
    "TIAB": {
        "id": "TIAB",
        "name": "TRAILER IN A BAG MARGATE FL"
    },
    "TIAK": {
        "id": "TIAK",
        "name": "TRIANGLE-K TRAILER"
    },
    "TIAN": {
        "id": "TIAN",
        "name": "TIANMA"
    },
    "TIAR": {
        "id": "TIAR",
        "name": "TIARA INDUSTRIES"
    },
    "TIBB": {
        "id": "TIBB",
        "name": "TIBBAN MFG"
    },
    "TIBR": {
        "id": "TIBR",
        "name": "TI-BROOK"
    },
    "TICI": {
        "id": "TICI",
        "name": "TRIBAL IRON CHOPPERS INC FAIR LAWN NJ"
    },
    "TICO": {
        "id": "TICO",
        "name": "TI"
    },
    "TIDE": {
        "id": "TIDE",
        "name": "TIDE CRAFT"
    },
    "TIDW": {
        "id": "TIDW",
        "name": "DORADO MOBILE HOMESMFD. BY TIDWELL INDUSTRIES"
    },
    "TIED": {
        "id": "TIED",
        "name": "TIE DOWN ENGINEERING GEORGIA"
    },
    "TIFF": {
        "id": "TIFF",
        "name": "TIFFIN MOTORHOMES"
    },
    "TIGE": {
        "id": "TIGE",
        "name": "TIGER TRAILERS INC COOKVILLE TX"
    },
    "TIGT": {
        "id": "TIGT",
        "name": "TIGER TRUCK"
    },
    "TIID": {
        "id": "TIID",
        "name": "TINY IDAHOMES"
    },
    "TILM": {
        "id": "TILM",
        "name": "TILMAN DUMP TRAILERS LLC PENNSYLVANIA"
    },
    "TILT": {
        "id": "TILT",
        "name": "TILTON-HILTON"
    },
    "TIMB": {
        "id": "TIMB",
        "name": "TIMBERLINE MFG."
    },
    "TIME": {
        "id": "TIME",
        "name": "TIME OUTT.O. CORP."
    },
    "TIMK": {
        "id": "TIMK",
        "name": "TIMKEN BEARING BOAT TRAILER"
    },
    "TIMM": {
        "id": "TIMM",
        "name": "TIMMINS TRAILERS ONTARIO CANADA"
    },
    "TIMP": {
        "id": "TIMP",
        "name": "TIMPTE"
    },
    "TINC": {
        "id": "TINC",
        "name": "TINY CAMPER COMPANY FLORIDA"
    },
    "TINK": {
        "id": "TINK",
        "name": "TINKER CLUBS OF AMERICA"
    },
    "TINY": {
        "id": "TINY",
        "name": "TINY SMARTHOUSE"
    },
    "TIOG": {
        "id": "TIOG",
        "name": "TIOGA MOTOR HOME"
    },
    "TISO": {
        "id": "TISO",
        "name": "TISONG GROUP"
    },
    "TIST": {
        "id": "TIST",
        "name": "TRI-STATE HOMES"
    },
    "TITA": {
        "id": "TITA",
        "name": "TITAN TRAILER CORP.DIV. CONCORD MOBILE HOMES WOODLAND"
    },
    "TITL": {
        "id": "TITL",
        "name": "TITLEIST TRAVEL TRAILER"
    },
    "TITN": {
        "id": "TITN",
        "name": "TITAN INC OR U S TITAN"
    },
    "TITR": {
        "id": "TITR",
        "name": "TI TRAILERS MIDWAY"
    },
    "TJAA": {
        "id": "TJAA",
        "name": "TJAARDA"
    },
    "TJHR": {
        "id": "TJHR",
        "name": "T & J HORSE TRAILERS - OGDEN"
    },
    "TJJJ": {
        "id": "TJJJ",
        "name": "TRIPLE J"
    },
    "TKEY": {
        "id": "TKEY",
        "name": "TURNKEY INDUSTRIES MAGNOLIA"
    },
    "TKFH": {
        "id": "TKFH",
        "name": "TRIKE-FAHRZEUGBAU 2OOO GMBH GERMANY SPECIAL VEHICLE CONVEYANCE PASS"
    },
    "TKNG": {
        "id": "TKNG",
        "name": "TIMBERKING"
    },
    "TKTC": {
        "id": "TKTC",
        "name": "TRIKETEC GMBH GERMANY"
    },
    "TLAU": {
        "id": "TLAU",
        "name": "AURA RV MFG BY TRAVEL LITE INC NEW PARIS IN"
    },
    "TLCC": {
        "id": "TLCC",
        "name": "TLC CARROSSIERS"
    },
    "TLCM": {
        "id": "TLCM",
        "name": "TLC MANUFACTURING"
    },
    "TLCP": {
        "id": "TLCP",
        "name": "TRAIL CAMPERS WEATHERFORD OK"
    },
    "TLDO": {
        "id": "TLDO",
        "name": "TOLEDO VEHICLES"
    },
    "TLDY": {
        "id": "TLDY",
        "name": "TELEDYNE"
    },
    "TLEM": {
        "id": "TLEM",
        "name": "TRAILS END MFG MARYVILLE MO"
    },
    "TLES": {
        "id": "TLES",
        "name": "EXTENDED STAY RV MFG BY TRAVEL LITE INC NEW PARIS IN"
    },
    "TLEV": {
        "id": "TLEV",
        "name": "EVOKE RV MFG BY TRAVEL LITE INC NEW PARIS IN"
    },
    "TLFC": {
        "id": "TLFC",
        "name": "FALCON BRAND MFG BY TRAVEL LITE"
    },
    "TLFL": {
        "id": "TLFL",
        "name": "F LITE RV MFG BY TRAVEL LITE INC NEW PARIS IN"
    },
    "TLGT": {
        "id": "TLGT",
        "name": "TAILGATOR TRAILERS HILLSBORO"
    },
    "TLHL": {
        "id": "TLHL",
        "name": "TINY LIFESTYLE HOMES LLC VENICE FL"
    },
    "TLIN": {
        "id": "TLIN",
        "name": "TL INDUSTRIES TRAVEL TRAILERS & CAMPERS"
    },
    "TLMC": {
        "id": "TLMC",
        "name": "OZARK TRAILWAY MFD BY TRI-LAKES MFG."
    },
    "TLMD": {
        "id": "TLMD",
        "name": "TRAILER MADE CUSTOM TRAILERS COLORADO TRAILERS _ALSO AUTOHAUS OF"
    },
    "TLMF": {
        "id": "TLMF",
        "name": "T & L MANUFACTURING FAYETTEVILLE"
    },
    "TLMI": {
        "id": "TLMI",
        "name": "TRAILER MANUFACTURE"
    },
    "TLMM": {
        "id": "TLMM",
        "name": "TRAILMASTER MFG."
    },
    "TLMT": {
        "id": "TLMT",
        "name": "TRAIL MASTER"
    },
    "TLOG": {
        "id": "TLOG",
        "name": "TRAILERLOGIC LLC BELTON"
    },
    "TLRA": {
        "id": "TLRA",
        "name": "RAYZR RV MFG BY TRAVEL LITE INC NEW PARIS IN"
    },
    "TLRW": {
        "id": "TLRW",
        "name": "TLR WELDING AND FABRICATING INC PARADISE TX"
    },
    "TLSL": {
        "id": "TLSL",
        "name": "SUPER LITE RV MFG BY TRAVEL LITE INC NEW PARIS IN"
    },
    "TLT": {
        "id": "TLT",
        "name": "T & L TRAILERS SIKESTON"
    },
    "TLTC": {
        "id": "TLTC",
        "name": "THE LITTLE TRAILER"
    },
    "TLTE": {
        "id": "TLTE",
        "name": "TRAVEL LITE"
    },
    "TLTM": {
        "id": "TLTM",
        "name": "TRAILERMAN TRAILERS"
    },
    "TLTR": {
        "id": "TLTR",
        "name": "TRAILITE TRAILER"
    },
    "TLUN": {
        "id": "TLUN",
        "name": "TRAILERS UNLIMITED GEORGIA"
    },
    "TLYN": {
        "id": "TLYN",
        "name": "TELEDYNE CONTINENTAL MOTORS INC"
    },
    "TMAN": {
        "id": "TMAN",
        "name": "TRAILER MANIA TRAILERS INC HIALEAH"
    },
    "TMBK": {
        "id": "TMBK",
        "name": "WOODCREDIT"
    },
    "TMBL": {
        "id": "TMBL",
        "name": "TIMBERLAND RV COMPANY PERU"
    },
    "TMBW": {
        "id": "TMBW",
        "name": "TIMBERWOLF ELKHART"
    },
    "TMC": {
        "id": "TMC",
        "name": "TRANSPORTATION MANUFACTURING CORPORATIONROSWELL"
    },
    "TMCC": {
        "id": "TMCC",
        "name": "THUNDER MOUNTAIN CUSTON CYCLES LOVELAND"
    },
    "TMCI": {
        "id": "TMCI",
        "name": "TMC INC.POSKIN"
    },
    "TMCO": {
        "id": "TMCO",
        "name": "TOM"
    },
    "TMCT": {
        "id": "TMCT",
        "name": "THE METAL CONNECTION WESTBROOK"
    },
    "TMEC": {
        "id": "TMEC",
        "name": "TMEC POWER TECHNOLOGY CORP."
    },
    "TMKO": {
        "id": "TMKO",
        "name": "TOMKO TRAILERS"
    },
    "TMLF": {
        "id": "TMLF",
        "name": "TIMBERLEAF TRAILERS INC DENVER CO"
    },
    "TMMC": {
        "id": "TMMC",
        "name": "TRAILMANOR MANUFACTURING COMPANY LLC HARTINGTON NE"
    },
    "TMMI": {
        "id": "TMMI",
        "name": "T & M MANUFACTURING"
    },
    "TMMY": {
        "id": "TMMY",
        "name": "TOMMY'S TRAILER SALES BAKERSFIELD"
    },
    "TMOT": {
        "id": "TMOT",
        "name": "TRAILER MANUFACTURERS OF TOLEDS"
    },
    "TMPA": {
        "id": "TMPA",
        "name": "TEMP-AIR BURNSVILLE"
    },
    "TMPL": {
        "id": "TMPL",
        "name": "TEMPLES TRAILER SALES"
    },
    "TMRC": {
        "id": "TMRC",
        "name": "T M RACING SPA ITALY TM MOTORCYCLES USA MOTORCYCLES"
    },
    "TMRK": {
        "id": "TMRK",
        "name": "TAMARACK INDUSTRIES"
    },
    "TMSI": {
        "id": "TMSI",
        "name": "THE MACHINE SHOP INC ROSWELL NM"
    },
    "TMTI": {
        "id": "TMTI",
        "name": "TRAIL MAKER TRAILERS INC JOHNSONVILLE SC"
    },
    "TMTL": {
        "id": "TMTL",
        "name": "TM TRAILER"
    },
    "TMTM": {
        "id": "TMTM",
        "name": "TRAILMANOR MFG BY TM INDUSTRIES LLC PARKSTON SD"
    },
    "TMTO": {
        "id": "TMTO",
        "name": "2 MOTO INC"
    },
    "TMTR": {
        "id": "TMTR",
        "name": "TRAVEL MATE TRAILER"
    },
    "TNAI": {
        "id": "TNAI",
        "name": "TEUPEN NORTH AMERICA INC PINEVILL NC"
    },
    "TNFB": {
        "id": "TNFB",
        "name": "TENNFAB KNOXVILLE"
    },
    "TNKC": {
        "id": "TNKC",
        "name": "TANKCON FRP"
    },
    "TNKI": {
        "id": "TNKI",
        "name": "TANK SPORTS"
    },
    "TNKO": {
        "id": "TNKO",
        "name": "TANKKO"
    },
    "TNOB": {
        "id": "TNOB",
        "name": "TIM NOBLES TRAILERS"
    },
    "TNOM": {
        "id": "TNOM",
        "name": "TANOM MOTORS"
    },
    "TNRX": {
        "id": "TNRX",
        "name": "TENTRAX OREGON"
    },
    "TNSC": {
        "id": "TNSC",
        "name": "TRANS"
    },
    "TNTL": {
        "id": "TNTL",
        "name": "TN TRAILERS"
    },
    "TNTT": {
        "id": "TNTT",
        "name": "TNT TRAILER LLC WEISER"
    },
    "TNYH": {
        "id": "TNYH",
        "name": "TINY HOUSE CHATTONOOGA"
    },
    "TNYT": {
        "id": "TNYT",
        "name": "TINY TRAILER ENTIAT"
    },
    "TODC": {
        "id": "TODC",
        "name": "TODCO"
    },
    "TODD": {
        "id": "TODD",
        "name": "TODDY CUSTOM BUILT TRAILERS MFGS STOCK TRAILERS BRADFORD"
    },
    "TOGO": {
        "id": "TOGO",
        "name": "TOW GO TRAILERS TONGANOXIE"
    },
    "TOHA": {
        "id": "TOHA",
        "name": "TOHATSU"
    },
    "TOHO": {
        "id": "TOHO",
        "name": "TOURHOME CAMPER TRAILER"
    },
    "TOKE": {
        "id": "TOKE",
        "name": "TOKEN FLATBED TRAILER"
    },
    "TOLO": {
        "id": "TOLO",
        "name": "TOUR-A-LODGE"
    },
    "TOLV": {
        "id": "TOLV",
        "name": "TOTAL LIVESTOCK CONCEPTS OR TRUCK & LIVESTOCK CONCEPTS TIPTON"
    },
    "TOMB": {
        "id": "TOMB",
        "name": "TOMBERLIN AUTOMOTIVE GROUP"
    },
    "TOMC": {
        "id": "TOMC",
        "name": "TOWMOTOR CORP.SUBSIDIARY CATERPILLAR TRACTOR"
    },
    "TOMH": {
        "id": "TOMH",
        "name": "TOMBSTONE HEARSE CO"
    },
    "TOMO": {
        "id": "TOMO",
        "name": "TOMOS"
    },
    "TOMS": {
        "id": "TOMS",
        "name": "TOM'S TRAILERS MULTIPLE STYLE OF TRAILERS MANUFACTURED MENOMONEE FALLS"
    },
    "TOMT": {
        "id": "TOMT",
        "name": "TOMOTO INDUSTRIES DIV OF HUANSONG INDUSTRIES GRP"
    },
    "TONC": {
        "id": "TONC",
        "name": "TON"
    },
    "TOOE": {
        "id": "TOOE",
        "name": "TOOELE"
    },
    "TOOL": {
        "id": "TOOL",
        "name": "TOOL ENGINEERING & MFG BRIGHAM CITY"
    },
    "TOPB": {
        "id": "TOPB",
        "name": "TOP BRAND TRAILERS"
    },
    "TOPC": {
        "id": "TOPC",
        "name": "TOP CAMPERS"
    },
    "TOPK": {
        "id": "TOPK",
        "name": "TOP KAT"
    },
    "TOPN": {
        "id": "TOPN",
        "name": "TOP NOTCH TRAILERS"
    },
    "TOPP": {
        "id": "TOPP",
        "name": "TOPPER MOBILE HOMES"
    },
    "TOPT": {
        "id": "TOPT",
        "name": "TOP-3 TRAILERS TEXAS"
    },
    "TORA": {
        "id": "TORA",
        "name": "TORA"
    },
    "TORC": {
        "id": "TORC",
        "name": "TORCH INDUSTRIES ELKHART"
    },
    "TORI": {
        "id": "TORI",
        "name": "TORINO_INDUSTRIES"
    },
    "TORK": {
        "id": "TORK",
        "name": "TORK"
    },
    "TORN": {
        "id": "TORN",
        "name": "TORNADO"
    },
    "TORO": {
        "id": "TORO",
        "name": "TORO"
    },
    "TORQ": {
        "id": "TORQ",
        "name": "TORQUE FW"
    },
    "TORR": {
        "id": "TORR",
        "name": "TORROT"
    },
    "TOTA": {
        "id": "TOTA",
        "name": "TOTEM TOTAL TRAILER"
    },
    "TOTC": {
        "id": "TOTC",
        "name": "TOTE"
    },
    "TOTE": {
        "id": "TOTE",
        "name": "TOTEMALL MFD BY BIRMINGHAM MFG."
    },
    "TOTL": {
        "id": "TOTL",
        "name": "TOTAL PERFORMANCE INC. WALLINGFORD"
    },
    "TOTM": {
        "id": "TOTM",
        "name": "TOPLINE TRAILERS MANUFACTURING FORT WORTH TX"
    },
    "TOTR": {
        "id": "TOTR",
        "name": "TOTER INDUSTRIES"
    },
    "TOTT": {
        "id": "TOTT",
        "name": "TOTE' EM TRAILERS INDIAN ORCHARD"
    },
    "TOUA": {
        "id": "TOUA",
        "name": "TOURA PRODUCTS"
    },
    "TOUR": {
        "id": "TOUR",
        "name": "TOURITE MOBILE HOMES MFG."
    },
    "TOWC": {
        "id": "TOWC",
        "name": "TOWNCRIER CAMPER"
    },
    "TOWD": {
        "id": "TOWD",
        "name": "TOW MOR PRODUCTSCANON"
    },
    "TOWE": {
        "id": "TOWE",
        "name": "TOWNER MFG."
    },
    "TOWL": {
        "id": "TOWL",
        "name": "TOW LOW"
    },
    "TOWM": {
        "id": "TOWM",
        "name": "TOW MOBILE FORKLIFT"
    },
    "TOWN": {
        "id": "TOWN",
        "name": "NEW CASTLE MFG BY TOWN & COUNTRY MFG."
    },
    "TOWP": {
        "id": "TOWP",
        "name": "TOW PRO"
    },
    "TOWR": {
        "id": "TOWR",
        "name": "MONROE TOWMASTER LLC"
    },
    "TOYN": {
        "id": "TOYN",
        "name": "TOYNE INC BREDA IA"
    },
    "TOYO": {
        "id": "TOYO",
        "name": "TOYOCAR VAN CONTAINER TRAILER"
    },
    "TOYP": {
        "id": "TOYP",
        "name": "TOYOPET"
    },
    "TOYT": {
        "id": "TOYT",
        "name": "TOYOTA"
    },
    "TPDT": {
        "id": "TPDT",
        "name": "TPD TRAILERS SACRAMENTO"
    },
    "TPHD": {
        "id": "TPHD",
        "name": "TOP HAND TRAILER MFG. OKLAHOMA"
    },
    "TPHT": {
        "id": "TPHT",
        "name": "TOP HAT INDUSTRIES"
    },
    "TPLC": {
        "id": "TPLC",
        "name": "TRANSPORT LEASING COMPANY"
    },
    "TPLI": {
        "id": "TPLI",
        "name": "TRIPOLI MFG."
    },
    "TPLN": {
        "id": "TPLN",
        "name": "TOPLINE MANUFACTURING"
    },
    "TPOD": {
        "id": "TPOD",
        "name": "TERRA POD TRAILERS CHAMBERSBURG PA"
    },
    "TPPL": {
        "id": "TPPL",
        "name": "TRAILER PARTS PLUS"
    },
    "TPPS": {
        "id": "TPPS",
        "name": "TOPPS TRAILER MANUFACTURING INC"
    },
    "TPSS": {
        "id": "TPSS",
        "name": "T"
    },
    "TPST": {
        "id": "TPST",
        "name": "TEMPEST CYCLES INS."
    },
    "TQAA": {
        "id": "TQAA",
        "name": "TRADEWIND INDUSTRIES"
    },
    "TRAA": {
        "id": "TRAA",
        "name": "TRADER HORN TRAILER SUPPLY"
    },
    "TRAB": {
        "id": "TRAB",
        "name": "TRABANT"
    },
    "TRAC": {
        "id": "TRAC",
        "name": "TRAIL AIRE"
    },
    "TRAD": {
        "id": "TRAD",
        "name": "TRAIL CAR BOAT TRAILER"
    },
    "TRAE": {
        "id": "TRAE",
        "name": "TRAIL HAVEN DIV."
    },
    "TRAF": {
        "id": "TRAF",
        "name": "TRAILMASTER TANKS"
    },
    "TRAG": {
        "id": "TRAG",
        "name": "TRAILCO-NORWIN"
    },
    "TRAH": {
        "id": "TRAH",
        "name": "ART ROLL MFG."
    },
    "TRAI": {
        "id": "TRAI",
        "name": "TRAILEX"
    },
    "TRAJ": {
        "id": "TRAJ",
        "name": "TRAIL-IT COACH MFG."
    },
    "TRAK": {
        "id": "TRAK",
        "name": "TRAILORAMA"
    },
    "TRAL": {
        "id": "TRAL",
        "name": "TRAIL-OR-FLOATS"
    },
    "TRAM": {
        "id": "TRAM",
        "name": "TRAIL-WIDE CORP."
    },
    "TRAN": {
        "id": "TRAN",
        "name": "TRANS CANADA RENT-A-TRAILER SYSTEM"
    },
    "TRAO": {
        "id": "TRAO",
        "name": "TRANSCRAFT CORP.ANNA"
    },
    "TRAP": {
        "id": "TRAP",
        "name": "TRANSIT"
    },
    "TRAQ": {
        "id": "TRAQ",
        "name": "TRANSPORTERAMERICAN TRAILER & MFG."
    },
    "TRAR": {
        "id": "TRAR",
        "name": "TRANSVAIR"
    },
    "TRAS": {
        "id": "TRAS",
        "name": "TRAVEL EQUIPMENT CORP."
    },
    "TRAU": {
        "id": "TRAU",
        "name": "TRAVEL INDUSTRIES"
    },
    "TRAV": {
        "id": "TRAV",
        "name": "TRAVOY MOTOR HOME"
    },
    "TRAW": {
        "id": "TRAW",
        "name": "TRAVELAIRE TRAILER MFG."
    },
    "TRAY": {
        "id": "TRAY",
        "name": "TRAVELMASTER"
    },
    "TRAZ": {
        "id": "TRAZ",
        "name": "TRAVELPAK CAR TOPPER SALES"
    },
    "TRBA": {
        "id": "TRBA",
        "name": "TRAVETTE MFG."
    },
    "TRBB": {
        "id": "TRBB",
        "name": "TRAV-L-HOMES"
    },
    "TRBC": {
        "id": "TRBC",
        "name": "TRAV OTEL INDUSTRIES"
    },
    "TRBD": {
        "id": "TRBD",
        "name": "TRAVOIS TRAILER"
    },
    "TRBE": {
        "id": "TRBE",
        "name": "TRANSFER"
    },
    "TRBF": {
        "id": "TRBF",
        "name": "TRAINLINER"
    },
    "TRBL": {
        "id": "TRBL",
        "name": "TRAILBLAZER TRAILER"
    },
    "TRBO": {
        "id": "TRBO",
        "name": "TRAIL BOSS OR TRAILBOSS TRAILERS"
    },
    "TRBR": {
        "id": "TRBR",
        "name": "TRAIL BREAKER"
    },
    "TRBT": {
        "id": "TRBT",
        "name": "TRAUNBILT TRAILERS LLC GARDNERVILLE NV"
    },
    "TRBZ": {
        "id": "TRBZ",
        "name": "TRAIL BLAZER"
    },
    "TRCA": {
        "id": "TRCA",
        "name": "TRAILER CORPORATION OF AMERICA EASLEY"
    },
    "TRCC": {
        "id": "TRCC",
        "name": "TRACKER CORP.BOAT TRAILERS WINCHESTER"
    },
    "TRCF": {
        "id": "TRCF",
        "name": "TRAILCRAFT BOAT TRAILER"
    },
    "TRCI": {
        "id": "TRCI",
        "name": "TRANSPORTATION COLLABORATIVE"
    },
    "TRCO": {
        "id": "TRCO",
        "name": "TRAILER COACH METAL SPEC.VANCOUVER"
    },
    "TRCP": {
        "id": "TRCP",
        "name": "TRACPAC SNOWMOBILE TRAILER"
    },
    "TRCR": {
        "id": "TRCR",
        "name": "TRAVEL CAR MOTOR HOME"
    },
    "TRDD": {
        "id": "TRDD",
        "name": "TRAC"
    },
    "TRDS": {
        "id": "TRDS",
        "name": "TEARDROPS NW SALEM OREGON"
    },
    "TREA": {
        "id": "TREA",
        "name": "TREASURE CHEST PRODUCTS"
    },
    "TREB": {
        "id": "TREB",
        "name": "TRAILER REBUILDERS INC DUNDEE FLORIDA"
    },
    "TREC": {
        "id": "TREC",
        "name": "TREV"
    },
    "TREK": {
        "id": "TREK",
        "name": "TREK"
    },
    "TREL": {
        "id": "TREL",
        "name": "TRELAND MANUFACTURING MICHIGAN FORESTRY EQUIP"
    },
    "TREM": {
        "id": "TREM",
        "name": "TREMCAR"
    },
    "TRET": {
        "id": "TRET",
        "name": "TRAIL-ET HORSE TRAILER"
    },
    "TREX": {
        "id": "TREX",
        "name": "TRAILERS EXPRESS"
    },
    "TRFL": {
        "id": "TRFL",
        "name": "TRAIL FLIGHT"
    },
    "TRFR": {
        "id": "TRFR",
        "name": "TRAILER WHEEL AND FRAME"
    },
    "TRFX": {
        "id": "TRFX",
        "name": "TRAFFIX DEVICES INC SAN CLEMENTE CA"
    },
    "TRGG": {
        "id": "TRGG",
        "name": "TRIGGER TECHNICS B.V. NETHERLANDS THREE WHEELED VEHCILES"
    },
    "TRGR": {
        "id": "TRGR",
        "name": "TRAEGER"
    },
    "TRGT": {
        "id": "TRGT",
        "name": "TARGET TRAILER MFG."
    },
    "TRHE": {
        "id": "TRHE",
        "name": "HERITAGE EDITION MFG BY TRAIL BOSS CONVERSIONS"
    },
    "TRHK": {
        "id": "TRHK",
        "name": "TRAIL HAWK"
    },
    "TRHM": {
        "id": "TRHM",
        "name": "TRAVELO HOMES"
    },
    "TRHO": {
        "id": "TRHO",
        "name": "TRAIL HORSE"
    },
    "TRHW": {
        "id": "TRHW",
        "name": "TRAILERS AND HITCHES WINDER"
    },
    "TRI": {
        "id": "TRI",
        "name": "TRI-WAY HD SNOWMOBILE TRAILER"
    },
    "TRIA": {
        "id": "TRIA",
        "name": "TRAIL MATE TRAILER"
    },
    "TRIB": {
        "id": "TRIB",
        "name": "TRIPLE B MFG."
    },
    "TRIC": {
        "id": "TRIC",
        "name": "TRI STATE TANK CORPORATION KANSAS CITY"
    },
    "TRIF": {
        "id": "TRIF",
        "name": "TRIFUN"
    },
    "TRIG": {
        "id": "TRIG",
        "name": "TRIGGS-MINER CORP."
    },
    "TRIH": {
        "id": "TRIH",
        "name": "TRIHAWK INC."
    },
    "TRII": {
        "id": "TRII",
        "name": "TRANSPORTATION RESOURCES INDUSTRY INC FORT WORTH"
    },
    "TRIK": {
        "id": "TRIK",
        "name": "TRIKE"
    },
    "TRIL": {
        "id": "TRIL",
        "name": "CHANCEYMFD. BY TRAIL-O-MATIC"
    },
    "TRIM": {
        "id": "TRIM",
        "name": "PULLMAN TRAILMOBILE DIV OF PULLMAN"
    },
    "TRIN": {
        "id": "TRIN",
        "name": "TRINITY PRODUCTS"
    },
    "TRIP": {
        "id": "TRIP",
        "name": "TRIPLE R TRAILER STOCKTON"
    },
    "TRIS": {
        "id": "TRIS",
        "name": "TRIUMPH SALES & SERVICE"
    },
    "TRIT": {
        "id": "TRIT",
        "name": "TRI-STAR CORP."
    },
    "TRIU": {
        "id": "TRIU",
        "name": "TRIUMPH AUTOMOBILES"
    },
    "TRIV": {
        "id": "TRIV",
        "name": "TRIVELLATO"
    },
    "TRIW": {
        "id": "TRIW",
        "name": "TRI-WAY TRAILER"
    },
    "TRJN": {
        "id": "TRJN",
        "name": "TROJAN INDUSTRIES"
    },
    "TRKE": {
        "id": "TRKE",
        "name": "TRIKEE AG SWITZERLAND TRICYCLES"
    },
    "TRKG": {
        "id": "TRKG",
        "name": "TRAIL KING TRAVEL TRAILER"
    },
    "TRKK": {
        "id": "TRKK",
        "name": "TRAKKER TRAILERS"
    },
    "TRKM": {
        "id": "TRKM",
        "name": "TRACKER MARINE"
    },
    "TRKR": {
        "id": "TRKR",
        "name": "TC TREKKER OR TREKKER BY WELLS CARGO MARKED TREKKER"
    },
    "TRKS": {
        "id": "TRKS",
        "name": "THE TRIKE SHOP"
    },
    "TRKT": {
        "id": "TRKT",
        "name": "TRICKER TRAILERS"
    },
    "TRLA": {
        "id": "TRLA",
        "name": "TRAIL-A-LONG MFG."
    },
    "TRLB": {
        "id": "TRLB",
        "name": "TRAIL BOSS TRAILERS INC"
    },
    "TRLC": {
        "id": "TRLC",
        "name": "TRAIL"
    },
    "TRLD": {
        "id": "TRLD",
        "name": "TRAIL DUST TRAILERS BORING"
    },
    "TRLE": {
        "id": "TRLE",
        "name": "TRAIL-EASY TRAVEL TRAILER"
    },
    "TRLG": {
        "id": "TRLG",
        "name": "TRAILER GIANT"
    },
    "TRLI": {
        "id": "TRLI",
        "name": "TRAIL IT HORSE TRAILER"
    },
    "TRLK": {
        "id": "TRLK",
        "name": "TRAIL KING INDUSTRIES DIV W.A.S."
    },
    "TRLL": {
        "id": "TRLL",
        "name": "TRILLIUM TRAILER"
    },
    "TRLM": {
        "id": "TRLM",
        "name": "TRAIL MASTER TRAILERS"
    },
    "TRLN": {
        "id": "TRLN",
        "name": "SKEETER MFD BY TRAILERS"
    },
    "TRLO": {
        "id": "TRLO",
        "name": "TRAVEL LONG INC."
    },
    "TRLP": {
        "id": "TRLP",
        "name": "TRL ENTERPRISES INC LOVELAND CO"
    },
    "TRLR": {
        "id": "TRLR",
        "name": "UNPUBLISHED TRAILER MFR."
    },
    "TRLT": {
        "id": "TRLT",
        "name": "TRAILERS INTERNATIONAL"
    },
    "TRLW": {
        "id": "TRLW",
        "name": "TRAILER WORLD OZARK"
    },
    "TRLY": {
        "id": "TRLY",
        "name": "TROLLY BOATS"
    },
    "TRMA": {
        "id": "TRMA",
        "name": "TRAC-MACHINERY CORP.SUBSIDIARY OF E. D. ETNYRE &"
    },
    "TRMB": {
        "id": "TRMB",
        "name": "TRAILMOBILE CANADA & TRAILMOBILE TRAILER U.S. ARE SAME CO TRAILMOBILE TRAILER"
    },
    "TRMC": {
        "id": "TRMC",
        "name": "TRAMCO"
    },
    "TRMF": {
        "id": "TRMF",
        "name": "T & R MANUFACTURING"
    },
    "TRMI": {
        "id": "TRMI",
        "name": "TRAVELER MFG. INC.CONCORDIA"
    },
    "TRMN": {
        "id": "TRMN",
        "name": "TRANSMISSION GENERIC CODE FOR USE ONLY WHEN MANUFACTURER IS NOT LISTED"
    },
    "TRMP": {
        "id": "TRMP",
        "name": "TRIUMPH MACHINERY"
    },
    "TRMR": {
        "id": "TRMR",
        "name": "TRAILMANOR"
    },
    "TRMT": {
        "id": "TRMT",
        "name": "TRAMONT CORPORATION MILWAUKEE"
    },
    "TRNC": {
        "id": "TRNC",
        "name": "TRINITY INDUSTRIES"
    },
    "TRND": {
        "id": "TRND",
        "name": "TREND MOTOR SPORTS"
    },
    "TRNH": {
        "id": "TRNH",
        "name": "TRANSHAUL INC."
    },
    "TRNI": {
        "id": "TRNI",
        "name": "TOURNIER MANUFACTURING INC WATERLOO IOWA TRAILERS ADDED"
    },
    "TRNP": {
        "id": "TRNP",
        "name": "TRANSPORT EQUIPMENT"
    },
    "TRNS": {
        "id": "TRNS",
        "name": "TRANSLINER INSULATED TANK TRAILER"
    },
    "TRNT": {
        "id": "TRNT",
        "name": "TRANSPORT TRAILERS FENNVILLE"
    },
    "TRNY": {
        "id": "TRNY",
        "name": "TRINITY TRAILERS WITHEE"
    },
    "TROH": {
        "id": "TROH",
        "name": "TROJAN HOMES"
    },
    "TROJ": {
        "id": "TROJ",
        "name": "TROJAN"
    },
    "TROM": {
        "id": "TROM",
        "name": "TROMBLY WOODWORKING"
    },
    "TROP": {
        "id": "TROP",
        "name": "TROPICANA MFG."
    },
    "TROQ": {
        "id": "TROQ",
        "name": "TROPHY TRAVEL TRAILER"
    },
    "TROR": {
        "id": "TROR",
        "name": "TROYER MFG."
    },
    "TROT": {
        "id": "TROT",
        "name": "TROTWOOD TRAILER"
    },
    "TROU": {
        "id": "TROU",
        "name": "TROY INDUSTRIES"
    },
    "TROX": {
        "id": "TROX",
        "name": "TROXELL TRAILER MFG.FT. WORTH"
    },
    "TROY": {
        "id": "TROY",
        "name": "TROYLER CORP."
    },
    "TRPC": {
        "id": "TRPC",
        "name": "TROPIC MANUFACTURING"
    },
    "TRPE": {
        "id": "TRPE",
        "name": "TERRAPLANE"
    },
    "TRPH": {
        "id": "TRPH",
        "name": "TROPHY HOMES"
    },
    "TRPL": {
        "id": "TRPL",
        "name": "TRAILER PLACE GROVE HILL AL"
    },
    "TRPM": {
        "id": "TRPM",
        "name": "TRP MOTORS LLC ASHVILLE"
    },
    "TRPR": {
        "id": "TRPR",
        "name": "TRAMPER TRAVEL TRAILER"
    },
    "TRPT": {
        "id": "TRPT",
        "name": "TRI-SPORT"
    },
    "TRQN": {
        "id": "TRQN",
        "name": "TRAVEL QUEEN MOTOR HOME"
    },
    "TRQU": {
        "id": "TRQU",
        "name": "TRAVEL QUEEN COACHES"
    },
    "TRRB": {
        "id": "TRRB",
        "name": "TRAILER REPAIR AND REBUILDING HOUSTON"
    },
    "TRRD": {
        "id": "TRRD",
        "name": "TRI-ROD"
    },
    "TRRI": {
        "id": "TRRI",
        "name": "AQUARIUS BOAT TRAILERS MFD. BY TRAIL-RITE"
    },
    "TRRM": {
        "id": "TRRM",
        "name": "TERRAMARC INDUSTRIES"
    },
    "TRRR": {
        "id": "TRRR",
        "name": "TRIPLR R TRAILERS INC. BOONEVILLE"
    },
    "TRRT": {
        "id": "TRRT",
        "name": "TRAILERETTE TRAILER"
    },
    "TRRV": {
        "id": "TRRV",
        "name": "TROUT RIVER INDUSTRIES PRINCE EDWARD ISLAND"
    },
    "TRRY": {
        "id": "TRRY",
        "name": "TERRY'S UTILITY TRAILER OR TERRY W. BECK WARRENVILLE"
    },
    "TRSG": {
        "id": "TRSG",
        "name": "TRANSGLOBAL NEW YORK FLATBED TRAILERS"
    },
    "TRSM": {
        "id": "TRSM",
        "name": "TRANSMOBILE TRAILER"
    },
    "TRSP": {
        "id": "TRSP",
        "name": "TRANS-SPORT"
    },
    "TRST": {
        "id": "TRST",
        "name": "TRAIL STAR TENT TRAILER"
    },
    "TRSU": {
        "id": "TRSU",
        "name": "TRAVEL SUPREME MOTORHOMES"
    },
    "TRSY": {
        "id": "TRSY",
        "name": "TRI-PAK SYSTEMS"
    },
    "TRTB": {
        "id": "TRTB",
        "name": "TRAIL BOSS CUSTOM MFG BY TRAIL BOSS CONVERSIONS INC"
    },
    "TRTC": {
        "id": "TRTC",
        "name": "TRAILTECH GRAVELBOURG"
    },
    "TRTE": {
        "id": "TRTE",
        "name": "TR-TEC"
    },
    "TRTG": {
        "id": "TRTG",
        "name": "TARTER GATE WEST CORINNE"
    },
    "TRTI": {
        "id": "TRTI",
        "name": "TARTER INDUSTRIES"
    },
    "TRTK": {
        "id": "TRTK",
        "name": "TERRA TRACK"
    },
    "TRTL": {
        "id": "TRTL",
        "name": "TRIAD TRAILERS LTD.NEW MILFORD"
    },
    "TRTM": {
        "id": "TRTM",
        "name": "T & R TRAILER MFG."
    },
    "TRTN": {
        "id": "TRTN",
        "name": "TRITON TRAILER CORP.ALLENTOWN"
    },
    "TRTO": {
        "id": "TRTO",
        "name": "TRITON & TRITON LIMITED MFG BY PALM HARBOR HOMES"
    },
    "TRTQ": {
        "id": "TRTQ",
        "name": "TRANSTEQ TRANSPORTATION TECHNIQUES LLC HYBRID ELEC VEHS BUSES"
    },
    "TRTR": {
        "id": "TRTR",
        "name": "TRAIL TRAMP TRUCK & TRAILER EQUIPMENT"
    },
    "TRTS": {
        "id": "TRTS",
        "name": "TROTTERS MFG INC BUFFALO"
    },
    "TRTT": {
        "id": "TRTT",
        "name": "TRAVELINER TRAVEL TRAILER"
    },
    "TRTY": {
        "id": "TRTY",
        "name": "TRINITY INDUSTRIES"
    },
    "TRUC": {
        "id": "TRUC",
        "name": "TRUCO"
    },
    "TRUE": {
        "id": "TRUE",
        "name": "TRUCK EQUIPMENT SERVICE"
    },
    "TRUK": {
        "id": "TRUK",
        "name": "UNPUBLISHED TRUCK MFR."
    },
    "TRUL": {
        "id": "TRUL",
        "name": "TRUELOCK- HEAVY DUTY REEL OR SPOOL TRAILERS"
    },
    "TRUM": {
        "id": "TRUM",
        "name": "TRIUMPH MOTOR"
    },
    "TRUN": {
        "id": "TRUN",
        "name": "TRAILE RUNNER FW"
    },
    "TRUT": {
        "id": "TRUT",
        "name": "TRU-TRAILER"
    },
    "TRUW": {
        "id": "TRUW",
        "name": "TRU-TOW TRAILERS ORANGE PARK"
    },
    "TRVA": {
        "id": "TRVA",
        "name": "COZY CRAFT MFD BY TRAVELCRAFT"
    },
    "TRVC": {
        "id": "TRVC",
        "name": "TRAV"
    },
    "TRVE": {
        "id": "TRVE",
        "name": "TRAVELEZE INDUSTRIES"
    },
    "TRVL": {
        "id": "TRVL",
        "name": "TRAVEL HOME"
    },
    "TRVM": {
        "id": "TRVM",
        "name": "TRAVELINER MOTOR HOME"
    },
    "TRVN": {
        "id": "TRVN",
        "name": "TRIVAN TRUCK BODY"
    },
    "TRVO": {
        "id": "TRVO",
        "name": "STOW AND GOMFD. BY TRV CORP."
    },
    "TRVR": {
        "id": "TRVR",
        "name": "TRAILEVATOR DIV MAGLINE"
    },
    "TRVS": {
        "id": "TRVS",
        "name": "TRAVIS TRAILER"
    },
    "TRVT": {
        "id": "TRVT",
        "name": "TRAVERSTON"
    },
    "TRVU": {
        "id": "TRVU",
        "name": "TRAVEL UNITS"
    },
    "TRWD": {
        "id": "TRWD",
        "name": "TRAVEL WORLD"
    },
    "TRWK": {
        "id": "TRWK",
        "name": "TRAILER WORKS"
    },
    "TRWS": {
        "id": "TRWS",
        "name": "TRAILER WELD SHOP BEND"
    },
    "TRXX": {
        "id": "TRXX",
        "name": "TRAXX TRAILERS"
    },
    "TRYB": {
        "id": "TRYB",
        "name": "TROY-BILT FARM & GARDEN EQUIPMENT"
    },
    "TRYC": {
        "id": "TRYC",
        "name": "TRY"
    },
    "TSCC": {
        "id": "TSCC",
        "name": "AKTIVSEE TUCKER SNO-CAT CORPORATION"
    },
    "TSCO": {
        "id": "TSCO",
        "name": "TES"
    },
    "TSEI": {
        "id": "TSEI",
        "name": "TSE INTERNATIONAL"
    },
    "TSLF": {
        "id": "TSLF",
        "name": "TOP SHELF TRAILERS LLC YULEE FL"
    },
    "TSMI": {
        "id": "TSMI",
        "name": "TRAILER SALES OF MICHIGAN INC OR APPALACHIAN TRAILERS OF MI INC JACKSON MI"
    },
    "TSML": {
        "id": "TSML",
        "name": "TOP SHELF MANUFACTURING LLC AVONDALE AZ"
    },
    "TSNY": {
        "id": "TSNY",
        "name": "TRAILER SALES OF NEW YORK"
    },
    "TSSY": {
        "id": "TSSY",
        "name": "TESSY"
    },
    "TSTL": {
        "id": "TSTL",
        "name": "TEAM SPIRIT TRAILERS ELKHART"
    },
    "TSWL": {
        "id": "TSWL",
        "name": "T AND S WELDING PEARSON"
    },
    "TTAN": {
        "id": "TTAN",
        "name": "TITAN HOMES NEW YORK DIVISION OF CHAMPION HOMES"
    },
    "TTCI": {
        "id": "TTCI",
        "name": "THE TRAILER COMPANY"
    },
    "TTDT": {
        "id": "TTDT",
        "name": "TIMELESS TEAR DROP TRAILERS"
    },
    "TTEC": {
        "id": "TTEC",
        "name": "TRANSTECH INTERMODAL INDUSTRIES MONCTON"
    },
    "TTEM": {
        "id": "TTEM",
        "name": "TOTEM EQUIPMENT & SUPPLY"
    },
    "TTHL": {
        "id": "TTHL",
        "name": "TOY-TOTE INNOVATIVE HAULERS LLC LAKE HAVASU CITY AZ"
    },
    "TTII": {
        "id": "TTII",
        "name": "T & T INDUSTTRIES"
    },
    "TTIM": {
        "id": "TTIM",
        "name": "TITAN TRAILER MANUFACTURING"
    },
    "TTIN": {
        "id": "TTIN",
        "name": "TITAN TRAILERS INC."
    },
    "TTKI": {
        "id": "TTKI",
        "name": "THOMPSON TANK INC DOWNEY CA"
    },
    "TTLR": {
        "id": "TTLR",
        "name": "THE TRAILER CHOP"
    },
    "TTMC": {
        "id": "TTMC",
        "name": "CYCLE-MATE MFD BY T & T INDUSTRIES"
    },
    "TTMI": {
        "id": "TTMI",
        "name": "TRINITY TRAILER MEG."
    },
    "TTRK": {
        "id": "TTRK",
        "name": "THE TRIKE SHOP WHITE BEAR LAKE"
    },
    "TTSC": {
        "id": "TTSC",
        "name": "TEXAS TRAILER SERVICE"
    },
    "TTSI": {
        "id": "TTSI",
        "name": "TRANSPORT SYSTEMS"
    },
    "TTTH": {
        "id": "TTTH",
        "name": "TT TECHNOLOGIES INC AURORA IL"
    },
    "TTTI": {
        "id": "TTTI",
        "name": "TRANSPORT TRAILERS"
    },
    "TTTL": {
        "id": "TTTL",
        "name": "TIMELESS TRAVEL TRAILERS LLC WHEAT RIDGE CO"
    },
    "TTTR": {
        "id": "TTTR",
        "name": "TRANS-TRAIL TRAILER"
    },
    "TTUL": {
        "id": "TTUL",
        "name": "T & T UNLIMITED"
    },
    "TTWO": {
        "id": "TTWO",
        "name": "T-2 SERVICES"
    },
    "TUBO": {
        "id": "TUBO",
        "name": "TURNBOW TRAILER"
    },
    "TUCE": {
        "id": "TUCE",
        "name": "AL TUCKER TRAILER"
    },
    "TUCK": {
        "id": "TUCK",
        "name": "TUCKER"
    },
    "TUCR": {
        "id": "TUCR",
        "name": "SNO-CATMFD. BY TUCKER-SNO-CAT CORP."
    },
    "TUFB": {
        "id": "TUFB",
        "name": "TUFBY CONVERTER DOLLY"
    },
    "TUFC": {
        "id": "TUFC",
        "name": "TUFF CAT TRAILERS"
    },
    "TUFF": {
        "id": "TUFF",
        "name": "TUFFY"
    },
    "TUFN": {
        "id": "TUFN",
        "name": "TUFF-N-LITE"
    },
    "TUFT": {
        "id": "TUFT",
        "name": "TUFF THOM TRAILERS"
    },
    "TUKA": {
        "id": "TUKA",
        "name": "TUK-A-WAY-TRAILER"
    },
    "TULA": {
        "id": "TULA",
        "name": "TULA MOTORZIKLY ZAVOD"
    },
    "TULT": {
        "id": "TULT",
        "name": "TULSA TRIKES TULSA"
    },
    "TUMB": {
        "id": "TUMB",
        "name": "TUMBLEWEED TINY HOUSE COMPANY COLORADO SPRINGS"
    },
    "TUND": {
        "id": "TUND",
        "name": "TUNDRA TRAVEL TRAILERS & FIFTH WHEEL TRAILERS"
    },
    "TUOH": {
        "id": "TUOH",
        "name": "SHANGHAI TUOHE IMP & EXP"
    },
    "TURB": {
        "id": "TURB",
        "name": "TURTLEBACK TRAILERS LLC PHOENIX ARIZONA"
    },
    "TURH": {
        "id": "TURH",
        "name": "TURTLE HULL TRAILERS"
    },
    "TURM": {
        "id": "TURM",
        "name": "TURTLE MOUNTAIN"
    },
    "TURN": {
        "id": "TURN",
        "name": "TURNER"
    },
    "TURT": {
        "id": "TURT",
        "name": "TURTLE TOP SPECIALITY TRANSPORTATION VEHICLES LIMOS"
    },
    "TUSA": {
        "id": "TUSA",
        "name": "TRAILERS USA INC"
    },
    "TUSC": {
        "id": "TUSC",
        "name": "TUSCANY MFG BY THOR MOTOR COACH INC"
    },
    "TUTB": {
        "id": "TUTB",
        "name": "TURTLE TOP BUS MFG BY FOREST RIVER INC"
    },
    "TUTR": {
        "id": "TUTR",
        "name": "TULE TROOPER"
    },
    "TVLC": {
        "id": "TVLC",
        "name": "TRAVELCRAFT MOTOR HOME"
    },
    "TVLN": {
        "id": "TVLN",
        "name": "TRAVALONG TRAILERS"
    },
    "TVPB": {
        "id": "TVPB",
        "name": "T AND B BRAND MFG BY PLEASANT VALLEY TEARDROP TRAILERS"
    },
    "TVR": {
        "id": "TVR",
        "name": "TVR"
    },
    "TVRE": {
        "id": "TVRE",
        "name": "TOTAL VEHICLE RE MANUFACTURING UNITED KINGDOM"
    },
    "TWAM": {
        "id": "TWAM",
        "name": "TWAM"
    },
    "TWEN": {
        "id": "TWEN",
        "name": "TWENTIETH CENTURY MFG."
    },
    "TWFM": {
        "id": "TWFM",
        "name": "TWF MANUFACTURING SHIPPENSBURG"
    },
    "TWGB": {
        "id": "TWGB",
        "name": "TAIWAN GOLDEN BEE"
    },
    "TWIC": {
        "id": "TWIC",
        "name": "NECEDAH FLYERS NFD BY TWIN CITY RACING"
    },
    "TWIG": {
        "id": "TWIG",
        "name": "TWIN GROVE MFG."
    },
    "TWIL": {
        "id": "TWIL",
        "name": "TWILIGHT COACH MFG."
    },
    "TWIN": {
        "id": "TWIN",
        "name": "TWIN TRAILER"
    },
    "TWIS": {
        "id": "TWIS",
        "name": "TWIST N GO MOTOR SCOOTERS & CYCLES"
    },
    "TWIT": {
        "id": "TWIT",
        "name": "TWILITE MOBILE HOME MFG."
    },
    "TWIW": {
        "id": "TWIW",
        "name": "TODD'S WELDING"
    },
    "TWKL": {
        "id": "TWKL",
        "name": "TWINKLE HEARSES"
    },
    "TWLD": {
        "id": "TWLD",
        "name": "TRU-WELD TRAILERS RIALTO CA"
    },
    "TWLT": {
        "id": "TWLT",
        "name": "TOWLITE"
    },
    "TWMI": {
        "id": "TWMI",
        "name": "TRAILS WEST MANUFACTURING OF IDAHO"
    },
    "TWN": {
        "id": "TWN",
        "name": "TWN"
    },
    "TWNO": {
        "id": "TWNO",
        "name": "TW-N-ONE TRAILERS"
    },
    "TWOG": {
        "id": "TWOG",
        "name": "2-G TRAILER CO"
    },
    "TWOT": {
        "id": "TWOT",
        "name": "28O TRAILERS"
    },
    "TWRI": {
        "id": "TWRI",
        "name": "HAWKE TRAILERS INC FORMERLY"
    },
    "TWRT": {
        "id": "TWRT",
        "name": "TOWRITE MANUFACTURING BUNN LEVEL"
    },
    "TWSD": {
        "id": "TWSD",
        "name": "TWISTER DISPLAY EAST LIVERPOOL OHIO TRAILER MOUNTED AMUSEMENTS"
    },
    "TWST": {
        "id": "TWST",
        "name": "TWISTER"
    },
    "TWTM": {
        "id": "TWTM",
        "name": "TWISTER TRAILER MANUFACTURING INC."
    },
    "TWTS": {
        "id": "TWTS",
        "name": "TRANS WORLD TRANSPORTATION SERVICES"
    },
    "TXCK": {
        "id": "TXCK",
        "name": "CRICKET BRAND MFG BY TAXA INC"
    },
    "TXCR": {
        "id": "TXCR",
        "name": "TXC MFG LLC"
    },
    "TXLN": {
        "id": "TXLN",
        "name": "TEXLINE TRAILERS LLC MFG BY A1 CUSTOM TRAILERS SULPHUR SPRINGS TX"
    },
    "TXMN": {
        "id": "TXMN",
        "name": "MANTIS MFG BY TAXA INC"
    },
    "TXMX": {
        "id": "TXMX",
        "name": "TEX MEX TRAILERS"
    },
    "TXNX": {
        "id": "TXNX",
        "name": "TEX-NEX TRAILERS"
    },
    "TXTM": {
        "id": "TXTM",
        "name": "TIGER MOTH BRAND MFG BY TAXA"
    },
    "TXTR": {
        "id": "TXTR",
        "name": "TEXSTAR RV AND TRAILERS INC ANNA TX"
    },
    "TXTS": {
        "id": "TXTS",
        "name": "TEXAS TRAILER SUPPLY"
    },
    "TXTT": {
        "id": "TXTT",
        "name": "TRAXCAVATOR TRACT"
    },
    "TXUG": {
        "id": "TXUG",
        "name": "TEXAS UNDERGROUND"
    },
    "TXWB": {
        "id": "TXWB",
        "name": "WOOLLY BEAR MFG BY TAXA INC"
    },
    "TYAN": {
        "id": "TYAN",
        "name": "TYRAN"
    },
    "TYBE": {
        "id": "TYBE",
        "name": "TYBEX CORP.BANGOR"
    },
    "TYCB": {
        "id": "TYCB",
        "name": "TRAILERS Y CASETAS BONANZA S. A.MEXI"
    },
    "TYCL": {
        "id": "TYCL",
        "name": "TOOCYLINDER MOTORCYCLE REPAIR NEW GLOUCESTER"
    },
    "TYCO": {
        "id": "TYCO",
        "name": "TOYOCO"
    },
    "TYEC": {
        "id": "TYEC",
        "name": "THE TYE"
    },
    "TYGR": {
        "id": "TYGR",
        "name": "TYGAR MFG."
    },
    "TYLE": {
        "id": "TYLE",
        "name": "TYLER COACH MFG."
    },
    "TYLH": {
        "id": "TYLH",
        "name": "TAYLORS'S HITCHIN' POST SILVERTON"
    },
    "TYLM": {
        "id": "TYLM",
        "name": "TAYLOR MADE CHOPPERS FLORIDA"
    },
    "TYLR": {
        "id": "TYLR",
        "name": "TCI"
    },
    "TYLT": {
        "id": "TYLT",
        "name": "TAYLOR TRAILERS"
    },
    "TYMC": {
        "id": "TYMC",
        "name": "TYOCO"
    },
    "TYRS": {
        "id": "TYRS",
        "name": "TYRSOL"
    },
    "TYSO": {
        "id": "TYSO",
        "name": "TYSON SKYLARK TRAILER"
    },
    "TYTL": {
        "id": "TYTL",
        "name": "TYTAL USA"
    },
    "UALL": {
        "id": "UALL",
        "name": "UNITED ALLOY"
    },
    "UAZ": {
        "id": "UAZ",
        "name": "UAZ"
    },
    "UBCO": {
        "id": "UBCO",
        "name": "YADEA TECHNOLOGY GROUP"
    },
    "UBUS": {
        "id": "UBUS",
        "name": "U.S. BUS BUILT ON CHEVY"
    },
    "UCHW": {
        "id": "UCHW",
        "name": "UNITED COACHWORKS"
    },
    "UCTC": {
        "id": "UCTC",
        "name": "UNIVERSAL COMPOSITE LLC"
    },
    "UDMP": {
        "id": "UDMP",
        "name": "U-DUMP"
    },
    "UHAU": {
        "id": "UHAU",
        "name": "U-HAUL PHOENIX"
    },
    "ULAC": {
        "id": "ULAC",
        "name": "ULTRA ACQUISITION CORPORATION RIVERSIDE"
    },
    "ULGC": {
        "id": "ULGC",
        "name": "ULTRA GOLF CART INC CALIFORNIA - LOW"
    },
    "ULLH": {
        "id": "ULLH",
        "name": "ULLMAN AUTO SALES"
    },
    "ULMC": {
        "id": "ULMC",
        "name": "ULTRA MOTORCYCLE"
    },
    "ULPC": {
        "id": "ULPC",
        "name": "UNLIMITED POWER CORP."
    },
    "ULTI": {
        "id": "ULTI",
        "name": "ULTIMATE TRAILERS LAKEVIEW TERRACE"
    },
    "ULTM": {
        "id": "ULTM",
        "name": "ULTIMA MOTORCYCLE"
    },
    "ULTR": {
        "id": "ULTR",
        "name": "ULTRA"
    },
    "ULTS": {
        "id": "ULTS",
        "name": "ULTIMA SPORTS"
    },
    "UMOG": {
        "id": "UMOG",
        "name": "UNIMOG MULTI PURPOSE VEHICLES UTILITY"
    },
    "UNCC": {
        "id": "UNCC",
        "name": "UNIVERSAL CYCLE CORPORATION"
    },
    "UNCR": {
        "id": "UNCR",
        "name": "UNIT CRANE & SHOVEL CORP."
    },
    "UNEX": {
        "id": "UNEX",
        "name": "UNITED EXPRESS LINE"
    },
    "UNFW": {
        "id": "UNFW",
        "name": "UNITED FABRICATION & WELDING LTD. ALBERTA"
    },
    "UNGE": {
        "id": "UNGE",
        "name": "UNGER MOTOR HOME"
    },
    "UNHY": {
        "id": "UNHY",
        "name": "UNITED HYDRAULICS"
    },
    "UNIA": {
        "id": "UNIA",
        "name": "UNION MACHINE & TOOL WORKS"
    },
    "UNIC": {
        "id": "UNIC",
        "name": "UNICAR"
    },
    "UNID": {
        "id": "UNID",
        "name": "UNITED FIBERGLASS"
    },
    "UNIE": {
        "id": "UNIE",
        "name": "UNITED MFG."
    },
    "UNIF": {
        "id": "UNIF",
        "name": "MOUNTAINEER LAWN & GARDEN TRACTOR MFD. BY UNITEDFARM TOOLS"
    },
    "UNIG": {
        "id": "UNIG",
        "name": "UNI-GO MOTORCYCLE TRAILERS"
    },
    "UNII": {
        "id": "UNII",
        "name": "UNIVISION INDUSTRIES LTD."
    },
    "UNIL": {
        "id": "UNIL",
        "name": "UNITED MOBILE SALES"
    },
    "UNIM": {
        "id": "UNIM",
        "name": "UNITED STATES ALUMINUM"
    },
    "UNIN": {
        "id": "UNIN",
        "name": "UNION CITY"
    },
    "UNIO": {
        "id": "UNIO",
        "name": "UNITED STATES MOBILE HOMES"
    },
    "UNIP": {
        "id": "UNIP",
        "name": "UNIPOWER"
    },
    "UNIR": {
        "id": "UNIR",
        "name": "UNIVERSAL TRACTOR FOREIGN TRADE"
    },
    "UNIS": {
        "id": "UNIS",
        "name": "UNITED STATES MOBILE HOMES FLORIDA"
    },
    "UNIT": {
        "id": "UNIT",
        "name": "UNITED TRAILER SERVICE & SUPPLY"
    },
    "UNIV": {
        "id": "UNIV",
        "name": "UNIVERSAL CAMPER MFG."
    },
    "UNLI": {
        "id": "UNLI",
        "name": "UNILLI ATV'S ETC"
    },
    "UNMD": {
        "id": "UNMD",
        "name": "UNITED MODULAR PERRIS"
    },
    "UNMO": {
        "id": "UNMO",
        "name": "UNITED MOTORS UNITED MOTORS OF AMERICA MOTORCYCLES"
    },
    "UNMT": {
        "id": "UNMT",
        "name": "UNIAO METALOMECANICA LDS PORTUGAL ATV'S & CYCLES"
    },
    "UNSP": {
        "id": "UNSP",
        "name": "UNITED SPECIALTIES"
    },
    "UNST": {
        "id": "UNST",
        "name": "UNION STANDARD TRAILERS"
    },
    "UNTD": {
        "id": "UNTD",
        "name": "UNITED ROCK PICKER"
    },
    "UNTL": {
        "id": "UNTL",
        "name": "UNITED TRAILERS"
    },
    "UNTY": {
        "id": "UNTY",
        "name": "UNITY MFG BY TRIPLE E RECREATIONAL VEHICLES CANADA"
    },
    "UNVE": {
        "id": "UNVE",
        "name": "UNVERFERTH-MCCURDY MFG."
    },
    "UNVH": {
        "id": "UNVH",
        "name": "UNIVERSAL HOVERCRAFT ROCKFORD"
    },
    "UNVT": {
        "id": "UNVT",
        "name": "UNIVERSAL TRAILERS"
    },
    "UPTC": {
        "id": "UPTC",
        "name": "U.P. TRUCK CENTER INC QUINNESEC MI"
    },
    "URAL": {
        "id": "URAL",
        "name": "URAL MOTORCYCLES OF AMERICA PREVIOUSLY OR ALSO KNOWN AS: IRBIT"
    },
    "URIL": {
        "id": "URIL",
        "name": "URILI FLATBED TRAILER"
    },
    "USA": {
        "id": "USA",
        "name": "U.S. ARMY MILITARY VEHICLE - GENERIC CODE-USE WHEN VEHICLE MAKE IS UNKNOWN"
    },
    "USAC": {
        "id": "USAC",
        "name": "USA MOTOR CORP.BREMEN"
    },
    "USAF": {
        "id": "USAF",
        "name": "U.S. AIRFORCE MILITARY VEHICLE - GENERIC CODE-USE WHEN VEHICLEMAKE IS"
    },
    "USAM": {
        "id": "USAM",
        "name": "USA MOTORTOYS"
    },
    "USAV": {
        "id": "USAV",
        "name": "U-SAVE TRAILERSPOMONA"
    },
    "USCA": {
        "id": "USCA",
        "name": "U.S. CARGO"
    },
    "USCG": {
        "id": "USCG",
        "name": "U.S. COAST GUARD MILITARY VEHICLE - GENERIC CODE-USE WHEN VEHICLE MAKE IN"
    },
    "USCH": {
        "id": "USCH",
        "name": "USA CHOPPERS"
    },
    "USCR": {
        "id": "USCR",
        "name": "U-SCREEN USA"
    },
    "USCW": {
        "id": "USCW",
        "name": "U.S. COACHWORKS"
    },
    "USEL": {
        "id": "USEL",
        "name": "U.S. ELECTRICAR CORP."
    },
    "USJT": {
        "id": "USJT",
        "name": "US JETTING LLC ALPHARETTA GA"
    },
    "USMC": {
        "id": "USMC",
        "name": "U.S. MARINE CORPS MILITARY VEHICLE - GENERIC CODE-USE WHEN VEHICLE MAKE IS"
    },
    "USN": {
        "id": "USN",
        "name": "U.S. NAVY MILITARY VEHICLE - GENERIC CODE-USE WHEN VEHICLE MAKE IS UNKNOWN"
    },
    "USPF": {
        "id": "USPF",
        "name": "US PREFAB SARASOTA FL"
    },
    "USSM": {
        "id": "USSM",
        "name": "U.S. SUZUKI MOTOR CORP."
    },
    "USSV": {
        "id": "USSV",
        "name": "US SPECIALTY VEHICLES LLC RANCHO CUCAMONGA CA"
    },
    "USTL": {
        "id": "USTL",
        "name": "US TRAILER"
    },
    "USTN": {
        "id": "USTN",
        "name": "US TITAN IMPORTS"
    },
    "USTS": {
        "id": "USTS",
        "name": "USTS MANUFACTURING. INC CANTON"
    },
    "UTAH": {
        "id": "UTAH",
        "name": "UTAH KING MOTOR HOME"
    },
    "UTBC": {
        "id": "UTBC",
        "name": "UTILITY TOOL & BODY"
    },
    "UTCG": {
        "id": "UTCG",
        "name": "UNIVERSAL TRAILER CARGO GROUP"
    },
    "UTE": {
        "id": "UTE",
        "name": "UTE TRAILER"
    },
    "UTEL": {
        "id": "UTEL",
        "name": "UTELINER MOTOR HOME"
    },
    "UTER": {
        "id": "UTER",
        "name": "EDGE RACE COACHES BRAND MFG BY UNIVERSAL TRAILER CARGO INC"
    },
    "UTHM": {
        "id": "UTHM",
        "name": "UTAH MOBILE HOMES"
    },
    "UTIL": {
        "id": "UTIL",
        "name": "UTILITY TRAILER MANUFACTURING COMPANY CITY OF INDUSTRY"
    },
    "UTIM": {
        "id": "UTIM",
        "name": "UTILIMASTER CORPORATION"
    },
    "UTMT": {
        "id": "UTMT",
        "name": "UTILITY MATE MERLIN"
    },
    "UTOP": {
        "id": "UTOP",
        "name": "UTOPIA COACH CORP."
    },
    "UTSM": {
        "id": "UTSM",
        "name": "STATUS MOTOR COACH BRAND MFG BY UNIVERSAL TRAILER CARGO INC"
    },
    "VACA": {
        "id": "VACA",
        "name": "VACATION HOMES"
    },
    "VACD": {
        "id": "VACD",
        "name": "BIG SUR MFD BY VACATION INDUSTRIES"
    },
    "VACE": {
        "id": "VACE",
        "name": "VACATIONAR MOTOR HOME TRUCK"
    },
    "VACG": {
        "id": "VACG",
        "name": "VACATION WAGON"
    },
    "VACN": {
        "id": "VACN",
        "name": "VAC-CON INDUSTRIAL VACUUMING"
    },
    "VACR": {
        "id": "VACR",
        "name": "VECTOR AEROMOTIVE CORPORATION"
    },
    "VACT": {
        "id": "VACT",
        "name": "VAC TRON EQUIPMENT LLC OKAHUMPKA FL"
    },
    "VADA": {
        "id": "VADA",
        "name": "VADA OPEN TOP CATTLE TRAILER"
    },
    "VADO": {
        "id": "VADO",
        "name": "VADOR"
    },
    "VAEN": {
        "id": "VAEN",
        "name": "VA ENTERPRISES"
    },
    "VAGA": {
        "id": "VAGA",
        "name": "VAGABOND DIV GUERDON INDUSTRIES"
    },
    "VAGZ": {
        "id": "VAGZ",
        "name": "VACTOR"
    },
    "VAL": {
        "id": "VAL",
        "name": "VAL"
    },
    "VALA": {
        "id": "VALA",
        "name": "VALIANT MOBILE HOMES"
    },
    "VALB": {
        "id": "VALB",
        "name": "VALLA BELLA GROUP"
    },
    "VALC": {
        "id": "VALC",
        "name": "VALLEY CAMPERS"
    },
    "VALE": {
        "id": "VALE",
        "name": "VALE MOTOR COMPANY OR VALE SPECIAL MAIDA VALE ENGLAND"
    },
    "VALF": {
        "id": "VALF",
        "name": "VALLEY CRAFT FABRICATIONS"
    },
    "VALG": {
        "id": "VALG",
        "name": "VALLEY ENGINEERING"
    },
    "VALH": {
        "id": "VALH",
        "name": "VALHALLA MARINE INNOVATIONS"
    },
    "VALI": {
        "id": "VALI",
        "name": "VALIANT"
    },
    "VALK": {
        "id": "VALK",
        "name": "VALKRIE"
    },
    "VALL": {
        "id": "VALL",
        "name": "VALLEY FOUNDRY & MACHINE WORKS"
    },
    "VALU": {
        "id": "VALU",
        "name": "VALUE UTILITY TRAILER"
    },
    "VALV": {
        "id": "VALV",
        "name": "VALLEY VIEW CUSTOM TRAILER MANUFACTURERS SCHENECTADY NEW YORK"
    },
    "VALW": {
        "id": "VALW",
        "name": "VALEW QUALITY TRUCK BODIES"
    },
    "VALY": {
        "id": "VALY",
        "name": "VALLEY TRAILERS CYNTHIANA OH"
    },
    "VANA": {
        "id": "VANA",
        "name": "VAN ART"
    },
    "VANB": {
        "id": "VANB",
        "name": "VAN BIBBER ENTERPRISES"
    },
    "VANC": {
        "id": "VANC",
        "name": "VAN CAMP EQUIPMENT"
    },
    "VAND": {
        "id": "VAND",
        "name": "VANDERHALL MOTOR WORKS"
    },
    "VANG": {
        "id": "VANG",
        "name": "VANGUARD"
    },
    "VANL": {
        "id": "VANL",
        "name": "VAN LAND INC"
    },
    "VANM": {
        "id": "VANM",
        "name": "VAN AMERICAN"
    },
    "VANO": {
        "id": "VANO",
        "name": "VAN CAR CORP."
    },
    "VANP": {
        "id": "VANP",
        "name": "VANCE CAMPERS"
    },
    "VANQ": {
        "id": "VANQ",
        "name": "VANQUISH V8 MOTORCYCLES FLORIDA"
    },
    "VANR": {
        "id": "VANR",
        "name": "VAN GUARD TRAILER"
    },
    "VANS": {
        "id": "VANS",
        "name": "VANSON BOAT TRAILERS"
    },
    "VANT": {
        "id": "VANT",
        "name": "VANETTE"
    },
    "VANV": {
        "id": "VANV",
        "name": "VAN VEEN"
    },
    "VAQU": {
        "id": "VAQU",
        "name": "VAQUERO TRAILER"
    },
    "VATC": {
        "id": "VATC",
        "name": "VAN TECH"
    },
    "VAUX": {
        "id": "VAUX",
        "name": "VAUXHALL"
    },
    "VCAT": {
        "id": "VCAT",
        "name": "VACATIONEER TRAIL TRAILER"
    },
    "VCHO": {
        "id": "VCHO",
        "name": "V8 CHOPPERS LLC"
    },
    "VCLV": {
        "id": "VCLV",
        "name": "VAN CONVERSIONS OF LEHIGH VALLEY"
    },
    "VCTL": {
        "id": "VCTL",
        "name": "VICTORY TRAILERS ROCKY MOUNT"
    },
    "VCTX": {
        "id": "VCTX",
        "name": "VECTRIX CORPORATION NEWPORT"
    },
    "VCTY": {
        "id": "VCTY",
        "name": "VICTORY MOTORCYCLES"
    },
    "VDYN": {
        "id": "VDYN",
        "name": "VERADYN DENVER"
    },
    "VEAM": {
        "id": "VEAM",
        "name": "VEHICULOS AUTOMARES MEXACANO S. A.DE C. V."
    },
    "VEEN": {
        "id": "VEEN",
        "name": "V.E. ENTERPRISES SPRINGER"
    },
    "VEGA": {
        "id": "VEGA",
        "name": "VEGAS MFG."
    },
    "VEGL": {
        "id": "VEGL",
        "name": "VEGLIA"
    },
    "VELI": {
        "id": "VELI",
        "name": "VELIE MOTORS CORPORATION MOLINE IL"
    },
    "VELO": {
        "id": "VELO",
        "name": "VELOREX MOTORCYCLES & SIDECARS"
    },
    "VELT": {
        "id": "VELT",
        "name": "VELTEN TOOL AND FAB"
    },
    "VENC": {
        "id": "VENC",
        "name": "VENUS COACHES"
    },
    "VENG": {
        "id": "VENG",
        "name": "VENGEANCE MOTORCYCLES DIV OF VENGEANCE PERFORMANCE PRODUCTS"
    },
    "VENM": {
        "id": "VENM",
        "name": "VENTURA MFG. AND IMPLEMENT"
    },
    "VENT": {
        "id": "VENT",
        "name": "VENTOURA CORP."
    },
    "VENU": {
        "id": "VENU",
        "name": "VENUS"
    },
    "VERA": {
        "id": "VERA",
        "name": "VERSATILE POWER CORP.GRANTSBURG"
    },
    "VERI": {
        "id": "VERI",
        "name": "VERITAS"
    },
    "VERM": {
        "id": "VERM",
        "name": "VERMETTE MACHINE"
    },
    "VERN": {
        "id": "VERN",
        "name": "VERMEER MANUFACTURING"
    },
    "VERS": {
        "id": "VERS",
        "name": "VERSATILE MANUFACTURING"
    },
    "VERT": {
        "id": "VERT",
        "name": "VERTEX"
    },
    "VERU": {
        "id": "VERU",
        "name": "VERUCCI MOTORCYCLE MANUFACTURING COMPANY"
    },
    "VESE": {
        "id": "VESE",
        "name": "VESELY"
    },
    "VESP": {
        "id": "VESP",
        "name": "VESPA"
    },
    "VET": {
        "id": "VET",
        "name": "VET BOAT TRAILER"
    },
    "VETL": {
        "id": "VETL",
        "name": "VALIANT EXPEDITION TRAILERS LLC WASILLA AK"
    },
    "VETR": {
        "id": "VETR",
        "name": "VENTRAC MFG BY VENTURE PRODUCTS INC ORRVILLE OH"
    },
    "VFAB": {
        "id": "VFAB",
        "name": "VAL-FAB"
    },
    "VFAR": {
        "id": "VFAR",
        "name": "VALLEY FARM INDUSTRIES LLC BENKELMAN NE"
    },
    "VFOR": {
        "id": "VFOR",
        "name": "V-FORCE CUSTOMS ROCK TAVERN"
    },
    "VHMC": {
        "id": "VHMC",
        "name": "VIRGINIA HOMES MFG. CORP.BOYDTON"
    },
    "VHPG": {
        "id": "VHPG",
        "name": "TROY"
    },
    "VIBO": {
        "id": "VIBO",
        "name": "DYNAPAC MFG."
    },
    "VIBT": {
        "id": "VIBT",
        "name": "VIBROTECH"
    },
    "VICH": {
        "id": "VICH",
        "name": "VICTORIAN HOMES"
    },
    "VICN": {
        "id": "VICN",
        "name": "VICON FARM MACHINERY"
    },
    "VICO": {
        "id": "VICO",
        "name": "VICTORY IMPLEMENT"
    },
    "VICR": {
        "id": "VICR",
        "name": "VICTOR MOBILE HOMESDIV. DEROSE INDUSTRIES"
    },
    "VICT": {
        "id": "VICT",
        "name": "VICTORIA"
    },
    "VIKI": {
        "id": "VIKI",
        "name": "VIKING TRAILERS"
    },
    "VIKR": {
        "id": "VIKR",
        "name": "VIKING RECREATIONAL VEHICLES"
    },
    "VIKT": {
        "id": "VIKT",
        "name": "VIKING TRAILERS LIMITED ONTARIO"
    },
    "VILL": {
        "id": "VILL",
        "name": "VILLIERS"
    },
    "VINC": {
        "id": "VINC",
        "name": "BLACK KNIGHT"
    },
    "VIND": {
        "id": "VIND",
        "name": "VINDALE CORP."
    },
    "VINT": {
        "id": "VINT",
        "name": "VINTAGE HOMES"
    },
    "VIPC": {
        "id": "VIPC",
        "name": "VIPER CARGO"
    },
    "VIPE": {
        "id": "VIPE",
        "name": "VIPER TRAILERS"
    },
    "VIPR": {
        "id": "VIPR",
        "name": "VIPER MOTORCYCLE COMPANY HOPKINS"
    },
    "VIRG": {
        "id": "VIRG",
        "name": "VIRGINIA MOBILE HOMES INDUSTRIES"
    },
    "VIRI": {
        "id": "VIRI",
        "name": "VIRGINIAN COACH CAMPER CORP."
    },
    "VISA": {
        "id": "VISA",
        "name": "VISA MFG BY GULF STREAM COACH"
    },
    "VISC": {
        "id": "VISC",
        "name": "VISCOUNT TRAILERS"
    },
    "VISQ": {
        "id": "VISQ",
        "name": "VISTA QUEEN TRAILER"
    },
    "VIST": {
        "id": "VIST",
        "name": "VISTA LINER COACH & TRAILER"
    },
    "VITA": {
        "id": "VITA",
        "name": "VITACCI MOTORCYCLES INC TEXAS"
    },
    "VITL": {
        "id": "VITL",
        "name": "VIMAR TRAILERS SA DE CV GOMEZ PALACIO DURANGO MEXICO"
    },
    "VITO": {
        "id": "VITO",
        "name": "VITO MFG."
    },
    "VIVA": {
        "id": "VIVA",
        "name": "VIVA MOTOR HOME"
    },
    "VIVC": {
        "id": "VIVC",
        "name": "VICTORY VALLEY TRAILERS APPLE VALLEY CALIFORNIA"
    },
    "VIVI": {
        "id": "VIVI",
        "name": "VIVIAN INDUSTRIAL PLASTICS VIVIAN"
    },
    "VIXN": {
        "id": "VIXN",
        "name": "VIXEN MOTOR COMPANY PONTIAC"
    },
    "VKNG": {
        "id": "VKNG",
        "name": "VIKING SNOWMOBILES"
    },
    "VKST": {
        "id": "VKST",
        "name": "VKING-VEE"
    },
    "VLBC": {
        "id": "VLBC",
        "name": "BEACON BRAND MFG BY VANLEIGH RV INC"
    },
    "VLCN": {
        "id": "VLCN",
        "name": "VULCAN WORKS"
    },
    "VLCT": {
        "id": "VLCT",
        "name": "VELOCETTE"
    },
    "VLFA": {
        "id": "VLFA",
        "name": "VLF AUTOMOTIVE"
    },
    "VLGH": {
        "id": "VLGH",
        "name": "VANLEIGH RV BURNSVILLE"
    },
    "VLLA": {
        "id": "VLLA",
        "name": "VILLAGER TRAVEL TRAILER"
    },
    "VLMT": {
        "id": "VLMT",
        "name": "VOLTA MOTOR COMPANY SL"
    },
    "VLPN": {
        "id": "VLPN",
        "name": "PINECREST MFG BY VANLEIGH"
    },
    "VLTG": {
        "id": "VLTG",
        "name": "VOLTAGE VEHICLES CALIFORNIA ELECTRIC VEH'S"
    },
    "VLVI": {
        "id": "VLVI",
        "name": "VILANO BRAND MFG BY VANLEIGH RV"
    },
    "VMAC": {
        "id": "VMAC",
        "name": "VER MAC OR SIGNALISATION VER MAC INC CANADA AND HOUSTON TX"
    },
    "VMCB": {
        "id": "VMCB",
        "name": "V"
    },
    "VMER": {
        "id": "VMER",
        "name": "VEMEER MANUFACTURING COMPANY PELLA"
    },
    "VMSL": {
        "id": "VMSL",
        "name": "VERTIGO MOTORS SL BARCELONA SPAIN"
    },
    "VMTL": {
        "id": "VMTL",
        "name": "VIMETAL - JUAN CARLOS VIDAURI AGUILERA MEXICO"
    },
    "VNAE": {
        "id": "VNAE",
        "name": "AERO MFG BY VINTAGE TRAILERS"
    },
    "VNBN": {
        "id": "VNBN",
        "name": "BANDIT MFG BY VINTAGE TRAILERS LTD."
    },
    "VNCA": {
        "id": "VNCA",
        "name": "VINTAGE CARGO MFG BY VINTAGE TRAILERS LTD."
    },
    "VNCC": {
        "id": "VNCC",
        "name": "VENTURA COACH CORP. LUMBERTON"
    },
    "VNCO": {
        "id": "VNCO",
        "name": "VAN"
    },
    "VNCY": {
        "id": "VNCY",
        "name": "CRYSTAL MFG BY VINTAGE TRAILERS"
    },
    "VNDN": {
        "id": "VNDN",
        "name": "VANDEN PLAS"
    },
    "VNGI": {
        "id": "VNGI",
        "name": "VANGUARD INC."
    },
    "VNGM": {
        "id": "VNGM",
        "name": "VANGUARD INDUSTRIES OF MICHIGAN"
    },
    "VNHL": {
        "id": "VNHL",
        "name": "VAN HOOL BUSES & MOTOR COACHES"
    },
    "VNIN": {
        "id": "VNIN",
        "name": "INTIMIDATOR MFG BY VINTAGE TRAILERS"
    },
    "VNJS": {
        "id": "VNJS",
        "name": "VN AND J SALES ST LOUIS"
    },
    "VNMO": {
        "id": "VNMO",
        "name": "VAN MOR ENTERPRISES"
    },
    "VNOU": {
        "id": "VNOU",
        "name": "OUTLAW MFG BY VINTAGE TRAILERS LTD."
    },
    "VNOV": {
        "id": "VNOV",
        "name": "VINTAGE OVERLAND"
    },
    "VNSE": {
        "id": "VNSE",
        "name": "VINTAGE ELITE"
    },
    "VNST": {
        "id": "VNST",
        "name": "VINTAGE STACKER MFG BY VINTAGE TRAILERS LTD."
    },
    "VNTA": {
        "id": "VNTA",
        "name": "VENTANA AND VENTANA LE MFG BY NEWMAR CORP"
    },
    "VNTC": {
        "id": "VNTC",
        "name": "VANGUARD NATIONAL TRAILER CORPORATION MONON"
    },
    "VNTG": {
        "id": "VNTG",
        "name": "VANTAGE DUMP TRAILERS"
    },
    "VNTL": {
        "id": "VNTL",
        "name": "VINTAGE TRAILERS"
    },
    "VNTO": {
        "id": "VNTO",
        "name": "VENTO MOTORCYCLES"
    },
    "VNTR": {
        "id": "VNTR",
        "name": "VENTURI PARIS S. A."
    },
    "VNTT": {
        "id": "VNTT",
        "name": "VENTURE TECHNOLOGIES"
    },
    "VNTU": {
        "id": "VNTU",
        "name": "VENTURA TENT CAMPER"
    },
    "VNTV": {
        "id": "VNTV",
        "name": "VANTAGE VEHICLE INTERNATIONAL"
    },
    "VOGA": {
        "id": "VOGA",
        "name": "VOLGA"
    },
    "VOGU": {
        "id": "VOGU",
        "name": "VOGUE MOTOR HOME"
    },
    "VOIS": {
        "id": "VOIS",
        "name": "AVIONS VOISIN FRANCE"
    },
    "VOLK": {
        "id": "VOLK",
        "name": "VOLKSWAGEN"
    },
    "VOLO": {
        "id": "VOLO",
        "name": "VOLOCI ELECTRIC MOTORBIKE ALSO SEE NYCE WHEELS.COM"
    },
    "VOLU": {
        "id": "VOLU",
        "name": "VOLUNTEER MFG. CORP."
    },
    "VOLV": {
        "id": "VOLV",
        "name": "VOLVO"
    },
    "VOOD": {
        "id": "VOOD",
        "name": "VOODOO CHOPPERS"
    },
    "VOUG": {
        "id": "VOUG",
        "name": "VOUGHT INDUSTRIES"
    },
    "VPIC": {
        "id": "VPIC",
        "name": "VIETNAM PRECISION INDUSTRIAL"
    },
    "VPTL": {
        "id": "VPTL",
        "name": "VP TRAILER MFG ONTATIO CANADA"
    },
    "VRAD": {
        "id": "VRAD",
        "name": "VAN RADEN INDUSTRIES"
    },
    "VREN": {
        "id": "VREN",
        "name": "VOR ENDURO MOTORCYCLE"
    },
    "VRMO": {
        "id": "VRMO",
        "name": "VERMONT TRAVELER"
    },
    "VRSA": {
        "id": "VRSA",
        "name": "VERSA TWO WHEEL TRAILER"
    },
    "VRTQ": {
        "id": "VRTQ",
        "name": "VOR TEQ"
    },
    "VRTR": {
        "id": "VRTR",
        "name": "VERTICAL REALITY"
    },
    "VRVI": {
        "id": "VRVI",
        "name": "VRV INC CONWAY ARKANSAS"
    },
    "VSTA": {
        "id": "VSTA",
        "name": "VISTA CUSTOM TRAILERJANE"
    },
    "VSTB": {
        "id": "VSTB",
        "name": "VISTABLUE"
    },
    "VSVC": {
        "id": "VSVC",
        "name": "VT SPECIALIZED VEHICLES CORPORATION WASHINGTON"
    },
    "VTEC": {
        "id": "VTEC",
        "name": "VAC-TEC"
    },
    "VTMH": {
        "id": "VTMH",
        "name": "VINTAGE MANUFACTURED HOME"
    },
    "VTMT": {
        "id": "VTMT",
        "name": "VERTEMATI MOTORCYCLES"
    },
    "VTNR": {
        "id": "VTNR",
        "name": "VENTURE TRAILERS"
    },
    "VTRO": {
        "id": "VTRO",
        "name": "ETAS"
    },
    "VTTR": {
        "id": "VTTR",
        "name": "VALLEY TRUCK TRUCKBEDS AND REPAIR SUNNYSIDE WA"
    },
    "VTWN": {
        "id": "VTWN",
        "name": "V-TWIN CUSTOM MFG."
    },
    "VULC": {
        "id": "VULC",
        "name": "VULCAN TRAILER MFG."
    },
    "VVTL": {
        "id": "VVTL",
        "name": "VICTOR VALLEY TRAILERS MFG CALIFORNIA"
    },
    "VVVA": {
        "id": "VVVA",
        "name": "VIVA MOTORSPORTS"
    },
    "VWTC": {
        "id": "VWTC",
        "name": "TRUCKIN TRAILERMFD. BY V.W.T. CORP."
    },
    "VYGR": {
        "id": "VYGR",
        "name": "VOYAGER TRAILERS"
    },
    "VYTT": {
        "id": "VYTT",
        "name": "VOYAGER TRAVEL TRAILERS NEW YORK"
    },
    "WABC": {
        "id": "WABC",
        "name": "WAB"
    },
    "WACH": {
        "id": "WACH",
        "name": "E.H. WACHS COMPANY"
    },
    "WACK": {
        "id": "WACK",
        "name": "WACKER CORP. OR WACKER NEUSON CORP. WISCONSIN  CONSTRUCTION EQUIPMNT"
    },
    "WADE": {
        "id": "WADE",
        "name": "WADE SERVICES"
    },
    "WAGC": {
        "id": "WAGC",
        "name": "WAGO CAMPERS"
    },
    "WAGL": {
        "id": "WAGL",
        "name": "WAR EAGLE MOTORCYCLES"
    },
    "WAGM": {
        "id": "WAGM",
        "name": "WAGON MASTER TRAILER"
    },
    "WAGN": {
        "id": "WAGN",
        "name": "WAGNER"
    },
    "WAGO": {
        "id": "WAGO",
        "name": "WAGON-HAVEN"
    },
    "WAGS": {
        "id": "WAGS",
        "name": "WAGS UNIQUE MOTORCYCLE TRAILERS DENVER"
    },
    "WAGT": {
        "id": "WAGT",
        "name": "WAGON TRAIN"
    },
    "WALA": {
        "id": "WALA",
        "name": "WARD LAFRANCE INTERNATIONAL INC."
    },
    "WALC": {
        "id": "WALC",
        "name": "WALLACE TRAILERS GOERGIA"
    },
    "WALD": {
        "id": "WALD",
        "name": "WALDON"
    },
    "WALE": {
        "id": "WALE",
        "name": "WALKER TRACTOR MFG."
    },
    "WALH": {
        "id": "WALH",
        "name": "WALSH BODY & TRAILER"
    },
    "WALI": {
        "id": "WALI",
        "name": "WALINGA BODY AND COACH"
    },
    "WALK": {
        "id": "WALK",
        "name": "WALKER STAINLESS EQUIPMENT"
    },
    "WALL": {
        "id": "WALL",
        "name": "WALLSTRONG BOAT TRAILER"
    },
    "WALM": {
        "id": "WALM",
        "name": "WALLSTROM BOAT TRAILER"
    },
    "WALR": {
        "id": "WALR",
        "name": "WALKER MOBILE HOMES"
    },
    "WALT": {
        "id": "WALT",
        "name": "WALTER MOTOR TRUCK"
    },
    "WANA": {
        "id": "WANA",
        "name": "WANAMAKER TRAILER"
    },
    "WANC": {
        "id": "WANC",
        "name": "WABASH NATIONAL CORPORATION LAFAYETE"
    },
    "WAND": {
        "id": "WAND",
        "name": "WANDERER CAMPER"
    },
    "WANE": {
        "id": "WANE",
        "name": "WA-NEE HOMES CORP."
    },
    "WANG": {
        "id": "WANG",
        "name": "WANGLI HARDWARE MANUFACTORY"
    },
    "WARD": {
        "id": "WARD",
        "name": "MONTGOMERY WARD"
    },
    "WARE": {
        "id": "WARE",
        "name": "WARREN MFG."
    },
    "WARH": {
        "id": "WARH",
        "name": "WARHAWK MFG."
    },
    "WARI": {
        "id": "WARI",
        "name": "WARRIOR TRAILERS"
    },
    "WARM": {
        "id": "WARM",
        "name": "WARRIOR MFR.HENDERSON"
    },
    "WARO": {
        "id": "WARO",
        "name": "WAIN-ROY"
    },
    "WARR": {
        "id": "WARR",
        "name": "WARRENVILLE TRAILER MFG."
    },
    "WARS": {
        "id": "WARS",
        "name": "WARSZAWA"
    },
    "WART": {
        "id": "WART",
        "name": "WARTBURG"
    },
    "WARW": {
        "id": "WARW",
        "name": "WARWICK"
    },
    "WASP": {
        "id": "WASP",
        "name": "WASP"
    },
    "WAST": {
        "id": "WAST",
        "name": "WAVERLY STRUCTURES NORTH CAROLINA"
    },
    "WASW": {
        "id": "WASW",
        "name": "WARNER & SWASEY"
    },
    "WATB": {
        "id": "WATB",
        "name": "WATERBLASTING LLC STUART FL"
    },
    "WATF": {
        "id": "WATF",
        "name": "WATFORD"
    },
    "WATR": {
        "id": "WATR",
        "name": "WALTER PRODUCTS & ENGINEERING"
    },
    "WATS": {
        "id": "WATS",
        "name": "WATSON INDUSTRIES"
    },
    "WATT": {
        "id": "WATT",
        "name": "WATT CAMPER"
    },
    "WAUS": {
        "id": "WAUS",
        "name": "WAUSAU EQUIPMENT COMPANY"
    },
    "WAWR": {
        "id": "WAWR",
        "name": "WATER WARS COMPANY  PEQUOT LAKES"
    },
    "WAYC": {
        "id": "WAYC",
        "name": "WAYNE CORP.RICHMOND"
    },
    "WAYF": {
        "id": "WAYF",
        "name": "WAYFARER COACH BRAND MFG BY TIFFIN MOTORHOMES INC"
    },
    "WAYM": {
        "id": "WAYM",
        "name": "WAYMATIC WELDING & FABRICATING"
    },
    "WAYN": {
        "id": "WAYN",
        "name": "WAYNE SWEEPERSWEEPER DIV."
    },
    "WAYP": {
        "id": "WAYP",
        "name": "WAYNE METAL PRODUCTS"
    },
    "WAYS": {
        "id": "WAYS",
        "name": "WAYSIDE CAMPERS"
    },
    "WBAC": {
        "id": "WBAC",
        "name": "ACCESS MFG BY WINNEBAGO"
    },
    "WBAD": {
        "id": "WBAD",
        "name": "ADVENTURER MFG BY WINNEBAGO"
    },
    "WBAS": {
        "id": "WBAS",
        "name": "ASPECT MFG BY WINNEBAGO"
    },
    "WBBT": {
        "id": "WBBT",
        "name": "BOLDT MFG BY WINNEBAGO INDUSTRIES INC"
    },
    "WBBV": {
        "id": "WBBV",
        "name": "BRAVE BRAND MFG BY WINNEBAGO"
    },
    "WBCA": {
        "id": "WBCA",
        "name": "CAMBRIA MFG BY WINNEBAGO"
    },
    "WBDS": {
        "id": "WBDS",
        "name": "DESTINATION"
    },
    "WBEL": {
        "id": "WBEL",
        "name": "ELLIPSE MFG BY WINNEBAGO"
    },
    "WBER": {
        "id": "WBER",
        "name": "ERA MFG BY WINNEBAGO"
    },
    "WBFU": {
        "id": "WBFU",
        "name": "FUSE BRAND"
    },
    "WBFZ": {
        "id": "WBFZ",
        "name": "FORZA MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBGS": {
        "id": "WBGS",
        "name": "WESTBROOK GREENHOUSE SYSTEMS"
    },
    "WBHL": {
        "id": "WBHL",
        "name": "W B HILL"
    },
    "WBHZ": {
        "id": "WBHZ",
        "name": "HORIZON WINNEBAGO INDUSTRIES INC"
    },
    "WBIM": {
        "id": "WBIM",
        "name": "IMPULSE MFG BY WINNEBAGO"
    },
    "WBIN": {
        "id": "WBIN",
        "name": "INSTINCT BRAND MFG BY WINNEBAGO"
    },
    "WBIT": {
        "id": "WBIT",
        "name": "INTENT WINNEBAGO INDUSTRIES INC"
    },
    "WBJO": {
        "id": "WBJO",
        "name": "JOURNEY MFG BY WINNEBAGO"
    },
    "WBLT": {
        "id": "WBLT",
        "name": "LATITUDE"
    },
    "WBME": {
        "id": "WBME",
        "name": "MERIDIAN MFG BY WINNEBAGO"
    },
    "WBMI": {
        "id": "WBMI",
        "name": "MINNIE"
    },
    "WBNA": {
        "id": "WBNA",
        "name": "NAVION MFG BY WINNEBAGO"
    },
    "WBOK": {
        "id": "WBOK",
        "name": "OUTLOOK MFG BY WINNEBAGO"
    },
    "WBPS": {
        "id": "WBPS",
        "name": "PASEO"
    },
    "WBPT": {
        "id": "WBPT",
        "name": "PORTO MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBRE": {
        "id": "WBRE",
        "name": "REYO MFG BY WINNEBAGO"
    },
    "WBRL": {
        "id": "WBRL",
        "name": "REVEL WINNEBAGO INDUSTRIES INC"
    },
    "WBRM": {
        "id": "WBRM",
        "name": "REMINGTON"
    },
    "WBRV": {
        "id": "WBRV",
        "name": "RAVEN TT & RAVEN FW MFG BY WINNEBAGO"
    },
    "WBSC": {
        "id": "WBSC",
        "name": "SUNSET CREEK TT & SUNSET CREEK FW MFG BY WINNEBAGO"
    },
    "WBSI": {
        "id": "WBSI",
        "name": "SIGHTSEER MFG BY WINNEBAGO"
    },
    "WBSN": {
        "id": "WBSN",
        "name": "SCORPION BRAND MFG BY WINNEBAGO"
    },
    "WBSO": {
        "id": "WBSO",
        "name": "SOLEI MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBSP": {
        "id": "WBSP",
        "name": "SPIRIT MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBSS": {
        "id": "WBSS",
        "name": "SUNSTAR MFG BY WINNEBAGO"
    },
    "WBSU": {
        "id": "WBSU",
        "name": "SUNCRUISER MFG BY WINNEBAGO"
    },
    "WBSV": {
        "id": "WBSV",
        "name": "SUNOVA MFG BY"
    },
    "WBSY": {
        "id": "WBSY",
        "name": "SPYDER BRAND MFG BY WINNEBAGO"
    },
    "WBTB": {
        "id": "WBTB",
        "name": "TRIBUTE MODEL MFG BY WINNEBAGO"
    },
    "WBTH": {
        "id": "WBTH",
        "name": "WIB TECH LLC DENVER CO"
    },
    "WBTO": {
        "id": "WBTO",
        "name": "TOUR MFG BY WINNEBAGO"
    },
    "WBTR": {
        "id": "WBTR",
        "name": "TREND MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBTV": {
        "id": "WBTV",
        "name": "TRAVATO MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBUL": {
        "id": "WBUL",
        "name": "ULTRALITE"
    },
    "WBVE": {
        "id": "WBVE",
        "name": "VIEW MFG BY WINNEBAGO"
    },
    "WBVI": {
        "id": "WBVI",
        "name": "VIA MFG BY WINNEBAGO"
    },
    "WBVS": {
        "id": "WBVS",
        "name": "VISTA MFG BY WINNEBAGO"
    },
    "WBVT": {
        "id": "WBVT",
        "name": "VITA MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBVV": {
        "id": "WBVV",
        "name": "VIVA MFG BY WINNEBAGO INDUSTRIES"
    },
    "WBVY": {
        "id": "WBVY",
        "name": "VOYAGE"
    },
    "WBWD": {
        "id": "WBWD",
        "name": "WINNIE DROP BRAND MFG BY WINNEBAGO"
    },
    "WCAR": {
        "id": "WCAR",
        "name": "WATERCAR FOUNTAIN VALLEY"
    },
    "WCCH": {
        "id": "WCCH",
        "name": "WEST COAST CHOPPERS"
    },
    "WCCI": {
        "id": "WCCI",
        "name": "WESTERN CONSTRUCTION COMPONENTS"
    },
    "WCCW": {
        "id": "WCCW",
        "name": "WILD CAT CRANE AND WELDING INC"
    },
    "WCLH": {
        "id": "WCLH",
        "name": "WEST COAST LEISURE HOMES LIMITED"
    },
    "WCMS": {
        "id": "WCMS",
        "name": "W.C. MANUFACTURING & SPECIALTY"
    },
    "WCTR": {
        "id": "WCTR",
        "name": "WEST COASTER MAILSTER3 WHEELS"
    },
    "WDMZ": {
        "id": "WDMZ",
        "name": "WOOD-MIZER PRODUCTS"
    },
    "WDPP": {
        "id": "WDPP",
        "name": "WERNER DOPPSTADT VELBERT GERMANY TRAILERS"
    },
    "WEAV": {
        "id": "WEAV",
        "name": "WEAVER & SONS DIV VENTOURA CORPORATION"
    },
    "WEBB": {
        "id": "WEBB",
        "name": "WEBBER CONSTRUCTION AND FABRICATION LLC MICHIGAN"
    },
    "WEBE": {
        "id": "WEBE",
        "name": "WEBER"
    },
    "WECR": {
        "id": "WECR",
        "name": "WESTCRAFT MOBILE HOMES"
    },
    "WEDG": {
        "id": "WEDG",
        "name": "WEDGEWOOD HOMES"
    },
    "WEEK": {
        "id": "WEEK",
        "name": "WEEK-N-DER PICKUP CAMPER"
    },
    "WEER": {
        "id": "WEER",
        "name": "WEERES TRAILER"
    },
    "WEHL": {
        "id": "WEHL",
        "name": "WE-HAUL TRAILERS DIVISION OF FOREST RIVER"
    },
    "WEIE": {
        "id": "WEIE",
        "name": "WEIERS TRAILER SALES PHOENIX"
    },
    "WEIL": {
        "id": "WEIL",
        "name": "WEILER"
    },
    "WEIS": {
        "id": "WEIS",
        "name": "AUSTIN WEISS"
    },
    "WELB": {
        "id": "WELB",
        "name": "CIRCLE LMFD. BY WELL-BUILT TRAILERS"
    },
    "WELC": {
        "id": "WELC",
        "name": "WELCH MFG. & ENGINEERING"
    },
    "WELD": {
        "id": "WELD",
        "name": "WELD-IT COMPANY LOS ANGELES"
    },
    "WELL": {
        "id": "WELL",
        "name": "WELLS CARGO"
    },
    "WEMA": {
        "id": "WEMA",
        "name": "WEMAC MFG."
    },
    "WEMH": {
        "id": "WEMH",
        "name": "WEMHOFF COMPANY TARNOV"
    },
    "WEND": {
        "id": "WEND",
        "name": "WENDAX"
    },
    "WENG": {
        "id": "WENG",
        "name": "WEE ENGINEER INC DAYTON IN"
    },
    "WENZ": {
        "id": "WENZ",
        "name": "WENZLAU ENGINEERING"
    },
    "WERG": {
        "id": "WERG",
        "name": "WENGER MFG."
    },
    "WERL": {
        "id": "WERL",
        "name": "WEE ROLL LLC OCALA FL"
    },
    "WERN": {
        "id": "WERN",
        "name": "SIEFMUND WERNER"
    },
    "WERS": {
        "id": "WERS",
        "name": "WERTS CORP."
    },
    "WERT": {
        "id": "WERT",
        "name": "WERTS BILTMFD. BY WERTS WELDING SERVICE"
    },
    "WESC": {
        "id": "WESC",
        "name": "WEST COACH MFG."
    },
    "WESD": {
        "id": "WESD",
        "name": "WEST WIND TRAILER"
    },
    "WESE": {
        "id": "WESE",
        "name": "WESTERN OR WESTERN INTERNATIONAL"
    },
    "WESF": {
        "id": "WESF",
        "name": "WESTFIELD SPORTSCARS UNITED KINGDOM"
    },
    "WESG": {
        "id": "WESG",
        "name": "WESTGO INDUSTRIES"
    },
    "WESH": {
        "id": "WESH",
        "name": "WESTERN COACH CORP."
    },
    "WESI": {
        "id": "WESI",
        "name": "WESTERN DYNAMICS CORP."
    },
    "WESL": {
        "id": "WESL",
        "name": "WESTERN TRAILER COACH"
    },
    "WESM": {
        "id": "WESM",
        "name": "WESTHOLT MFG."
    },
    "WESN": {
        "id": "WESN",
        "name": "WESTINGHOUSE EQUIPPED MOBILE HOME"
    },
    "WESR": {
        "id": "WESR",
        "name": "WESTLAND TRAILER"
    },
    "WESS": {
        "id": "WESS",
        "name": "WESTWARD COACH MFG."
    },
    "WEST": {
        "id": "WEST",
        "name": "WESTWAYS MFG."
    },
    "WETR": {
        "id": "WETR",
        "name": "WESTERN PRODUCTS DIV."
    },
    "WFMI": {
        "id": "WFMI",
        "name": "W.F. MICKEY BODY"
    },
    "WFSC": {
        "id": "WFSC",
        "name": "WOLF MFG BY TAIZHOU ZHONGNENG MOTORCYCLE"
    },
    "WGMF": {
        "id": "WGMF",
        "name": "W.G. MANUFACTURING SEMINOLE TX"
    },
    "WGNS": {
        "id": "WGNS",
        "name": "WAGNER SMITH"
    },
    "WHEE": {
        "id": "WHEE",
        "name": "WHEEL HORSE PRODUCTS"
    },
    "WHEL": {
        "id": "WHEL",
        "name": "WHEEL CAMPERS CORP."
    },
    "WHES": {
        "id": "WHES",
        "name": "WHEEL ESTATE SALES & SERVICE"
    },
    "WHGM": {
        "id": "WHGM",
        "name": "WHITE GMC - PREVIOUSLY WHITE MOTOR"
    },
    "WHGO": {
        "id": "WHGO",
        "name": "WHEEGO ELECTRIC CARS LOW & MEDIUM SPEED VEH'S"
    },
    "WHHA": {
        "id": "WHHA",
        "name": "WHITEHAUL KALER TRAILER"
    },
    "WHIA": {
        "id": "WHIA",
        "name": "WHITE STAR TRAILER"
    },
    "WHIB": {
        "id": "WHIB",
        "name": "WHITE BEAR EQUIPMENT"
    },
    "WHIE": {
        "id": "WHIE",
        "name": "WHITE LINE MFG. & DIST."
    },
    "WHIP": {
        "id": "WHIP",
        "name": "WHIPPET"
    },
    "WHIS": {
        "id": "WHIS",
        "name": "GEORGE WHITE & SONS"
    },
    "WHIT": {
        "id": "WHIT",
        "name": "WHITE MOTOR CORP."
    },
    "WHIY": {
        "id": "WHIY",
        "name": "WHITLEY MFG."
    },
    "WHKA": {
        "id": "WHKA",
        "name": "WHITEHEAD & KALES"
    },
    "WHKE": {
        "id": "WHKE",
        "name": "WHITE'S"
    },
    "WHLE": {
        "id": "WHLE",
        "name": "WHEELER TRUCK TRAILER MORLEY"
    },
    "WHLG": {
        "id": "WHLG",
        "name": "WHIT-LOG"
    },
    "WHMA": {
        "id": "WHMA",
        "name": "WHITE MATERIALS HANDLING"
    },
    "WHSP": {
        "id": "WHSP",
        "name": "WHISPERING PINE CAMPER TRAILER"
    },
    "WHTM": {
        "id": "WHTM",
        "name": "WHITEMAN MFG."
    },
    "WHTN": {
        "id": "WHTN",
        "name": "WHITEMAN INDUSTRIES DIVISION OF MULTI QUIP CORP"
    },
    "WHTP": {
        "id": "WHTP",
        "name": "WHITE PINE CAMPERS"
    },
    "WHWT": {
        "id": "WHWT",
        "name": "WHITE WATER MFG BY RIVERSIDE RV INC"
    },
    "WHZR": {
        "id": "WHZR",
        "name": "WHIZZER"
    },
    "WIBH": {
        "id": "WIBH",
        "name": "WISCONSIN BODY & HOIST CHIPPEWA FALLS"
    },
    "WICH": {
        "id": "WICH",
        "name": "STYLE-CRAFT MOBILE HOMESMFD. BY WICKES HOMES"
    },
    "WICK": {
        "id": "WICK",
        "name": "WICKS ENGINEERING & CONSTRUCTION"
    },
    "WICT": {
        "id": "WICT",
        "name": "WILLIAMS CRAFT"
    },
    "WIGA": {
        "id": "WIGA",
        "name": "WIG-A-WAM"
    },
    "WIGG": {
        "id": "WIGG",
        "name": "WIGGINS LIFTS"
    },
    "WIGW": {
        "id": "WIGW",
        "name": "WIGWAM MOTOR HOME"
    },
    "WILA": {
        "id": "WILA",
        "name": "WILLMAR MANUFACTURING WILLMAR"
    },
    "WILC": {
        "id": "WILC",
        "name": "WILL CRAFT CAMPER TRAILER"
    },
    "WILD": {
        "id": "WILD",
        "name": "WILDCAT"
    },
    "WILF": {
        "id": "WILF",
        "name": "WILDFIRE MOTOR SCOOTERS"
    },
    "WILG": {
        "id": "WILG",
        "name": "WILDGOOSE"
    },
    "WILK": {
        "id": "WILK",
        "name": "WILKENS MANUFACTURING"
    },
    "WILL": {
        "id": "WILL",
        "name": "WILLYS-OVERLAND"
    },
    "WILM": {
        "id": "WILM",
        "name": "SUN-RAY MFD BY WILSON TRAILER MART"
    },
    "WILO": {
        "id": "WILO",
        "name": "WIL-RO"
    },
    "WILR": {
        "id": "WILR",
        "name": "WILLBORN BROTHERS"
    },
    "WILS": {
        "id": "WILS",
        "name": "WILSON"
    },
    "WILT": {
        "id": "WILT",
        "name": "WILLTEN MANUFACTURING"
    },
    "WILW": {
        "id": "WILW",
        "name": "WILLIAMSEN TRUCK EQUIPMENT CORP.MFR. WILLIAMSEN DUMP & SPECIALTRAILER"
    },
    "WILX": {
        "id": "WILX",
        "name": "WILSON TRAILER"
    },
    "WILY": {
        "id": "WILY",
        "name": "WIL-TRAIL CORP."
    },
    "WILZ": {
        "id": "WILZ",
        "name": "WILRAY MANUFACTURINGFT. BENTON"
    },
    "WIMW": {
        "id": "WIMW",
        "name": "MINNIE WINNIE MFG BY WINNEBAGO INDUSTRIES _MOTORHOME"
    },
    "WINC": {
        "id": "WINC",
        "name": "WIN"
    },
    "WIND": {
        "id": "WIND",
        "name": "WINDJAMMER MOTORCOACH"
    },
    "WINE": {
        "id": "WINE",
        "name": "WINDWARD ENTERPRISES"
    },
    "WING": {
        "id": "WING",
        "name": "WINGER MFG."
    },
    "WINI": {
        "id": "WINI",
        "name": "WINSTON INDUSTRIES DOUBLE SPRINGS"
    },
    "WINK": {
        "id": "WINK",
        "name": "WINK TRAILER CORPORATION"
    },
    "WINL": {
        "id": "WINL",
        "name": "WINDER LIBERATOR CAMPER"
    },
    "WINN": {
        "id": "WINN",
        "name": "WINNEBAGO INDUSTRIES"
    },
    "WINP": {
        "id": "WINP",
        "name": "WINPOWER CORP.NEWTON"
    },
    "WINS": {
        "id": "WINS",
        "name": "WINDSOR MOBILE HOMES"
    },
    "WINT": {
        "id": "WINT",
        "name": "WINTER WEISS"
    },
    "WIPT": {
        "id": "WIPT",
        "name": "MELVIN MANUFACTURING CORP.SEE WHIP IT SNOWMOBILES"
    },
    "WISC": {
        "id": "WISC",
        "name": "WISCONSIN TRAILER"
    },
    "WISD": {
        "id": "WISD",
        "name": "WISE WELDING"
    },
    "WISG": {
        "id": "WISG",
        "name": "WISE-CRAFT MFG."
    },
    "WISH": {
        "id": "WISH",
        "name": "WISHBONE TRAILERS"
    },
    "WISO": {
        "id": "WISO",
        "name": "PENN ROYAL MFD BY WISCONSIN HOMES"
    },
    "WITA": {
        "id": "WITA",
        "name": "WISCONSIN TAG-A-LONG TRAILER"
    },
    "WITZ": {
        "id": "WITZ",
        "name": "WITZ"
    },
    "WIZA": {
        "id": "WIZA",
        "name": "WESTERN AUTO SUPPLY"
    },
    "WKND": {
        "id": "WKND",
        "name": "WEEKENDER INDIANA BRAND MFG BY SKYLINE CORP"
    },
    "WKWR": {
        "id": "WKWR",
        "name": "WEEKEND WARRIOR MFG BY OMEGA RV LLC"
    },
    "WLCH": {
        "id": "WLCH",
        "name": "WELCHEL ENTERPRISES LLC OKLAHOMA CITY"
    },
    "WLCO": {
        "id": "WLCO",
        "name": "WIL"
    },
    "WLCT": {
        "id": "WLCT",
        "name": "WILSON CUSTOM TRAILER WILLIAMS"
    },
    "WLDE": {
        "id": "WLDE",
        "name": "WILDERNESS TRAVEL TRAILER"
    },
    "WLDG": {
        "id": "WLDG",
        "name": "WELDING SHOP & MFG."
    },
    "WLDM": {
        "id": "WLDM",
        "name": "WILD METAL MANUFACTURING HIALEAH"
    },
    "WLDN": {
        "id": "WLDN",
        "name": "WILDERNESS MFG BY HEARTLAND RECREATIONAL VEHICLES"
    },
    "WLDR": {
        "id": "WLDR",
        "name": "WELD-RITE"
    },
    "WLDS": {
        "id": "WLDS",
        "name": "WELDSHIP CORPORATION BETHELEM"
    },
    "WLDT": {
        "id": "WLDT",
        "name": "WELDTITE TRAILERS HUNTERSVILLE"
    },
    "WLEQ": {
        "id": "WLEQ",
        "name": "WOODS LINE EQUIPMENT"
    },
    "WLKI": {
        "id": "WLKI",
        "name": "WILKENS INDUSTRIES"
    },
    "WLKR": {
        "id": "WLKR",
        "name": "WALKER MOWERS RIDING & WALKING AND YARD EQUIPMENT ETC."
    },
    "WLLS": {
        "id": "WLLS",
        "name": "WILLYS"
    },
    "WLLY": {
        "id": "WLLY",
        "name": "WILLY DOG CORPORATION - FOOD TRAILERS AND CARTS"
    },
    "WLNG": {
        "id": "WLNG",
        "name": "WULING MOTORS CORP OR WULING GROUP OR LIUZHOU WULING CHINA LSV'S"
    },
    "WLRT": {
        "id": "WLRT",
        "name": "WELD-RITE"
    },
    "WLSN": {
        "id": "WLSN",
        "name": "WILSON ENTERPRISES CHEHALIS"
    },
    "WLST": {
        "id": "WLST",
        "name": "WILSON TRAILER SALES MOBERLY"
    },
    "WLTM": {
        "id": "WLTM",
        "name": "WILSON TRAILER MFG ANSON"
    },
    "WLTR": {
        "id": "WLTR",
        "name": "WALTRON LIMITED"
    },
    "WLWE": {
        "id": "WLWE",
        "name": "WESTERN RECREATION MFG. INC. AND OR WELLS WESTERN TRAILER OGDEN"
    },
    "WMEI": {
        "id": "WMEI",
        "name": "W & M ENTERPRISES"
    },
    "WMET": {
        "id": "WMET",
        "name": "WT-METALL"
    },
    "WMIN": {
        "id": "WMIN",
        "name": "WESTMOR INDUSTRIES"
    },
    "WMRK": {
        "id": "WMRK",
        "name": "WEST-MARK ATWATER CALIFORNIA TRAILERS"
    },
    "WNCH": {
        "id": "WNCH",
        "name": "WINCHESTER AUTOMOBILES"
    },
    "WNCO": {
        "id": "WNCO",
        "name": "WAN"
    },
    "WNDA": {
        "id": "WNDA",
        "name": "WANDA'S TRAILERS MISSOURI"
    },
    "WNDH": {
        "id": "WNDH",
        "name": "WINDHAM MFG."
    },
    "WNGT": {
        "id": "WNGT",
        "name": "WING TRUCK & TRAILER"
    },
    "WNGY": {
        "id": "WNGY",
        "name": "WANGYE POWER"
    },
    "WNHC": {
        "id": "WNHC",
        "name": "WINTON MOTOR CARRIAGE"
    },
    "WNSP": {
        "id": "WNSP",
        "name": "WINDSPORT MFG BY THOR MOTOR COACH INC"
    },
    "WNTB": {
        "id": "WNTB",
        "name": "WINS TRIKE BIKE TRINIDAD"
    },
    "WOEQ": {
        "id": "WOEQ",
        "name": "WOODS EQUIPMENT COMPANY AGRIGULTURE"
    },
    "WOLF": {
        "id": "WOLF",
        "name": "G.T.WOLFE MOBILE HOMES"
    },
    "WOLM": {
        "id": "WOLM",
        "name": "WOLFE MFG."
    },
    "WOLS": {
        "id": "WOLS",
        "name": "WOLSELEY"
    },
    "WOLV": {
        "id": "WOLV",
        "name": "WOLVERINE CAMPER"
    },
    "WOMO": {
        "id": "WOMO",
        "name": "WOODS MOBILETTE COMPANY ARIZONA"
    },
    "WOND": {
        "id": "WOND",
        "name": "WONDER LAND CAMPER"
    },
    "WOOA": {
        "id": "WOOA",
        "name": "WOODLAND CAMPER"
    },
    "WOOC": {
        "id": "WOOC",
        "name": "WOOD"
    },
    "WOOD": {
        "id": "WOOD",
        "name": "WOODILL WILDFIRE"
    },
    "WOOM": {
        "id": "WOOM",
        "name": "RANGER TRAIL TRAILERS MFD BY WOOD MFG."
    },
    "WOON": {
        "id": "WOON",
        "name": "WOODLINE CUSTOM CAMPER"
    },
    "WOOS": {
        "id": "WOOS",
        "name": "WOODSMEN CAMPER"
    },
    "WORH": {
        "id": "WORH",
        "name": "WORK HORSE MFG."
    },
    "WORI": {
        "id": "WORI",
        "name": "WORTHINGTON COMPRESSORS"
    },
    "WORK": {
        "id": "WORK",
        "name": "WORK-N-PLAY"
    },
    "WORL": {
        "id": "WORL",
        "name": "WORLD WIDE INDUSTRIES"
    },
    "WORR": {
        "id": "WORR",
        "name": "WORRELL TRAILER MFG."
    },
    "WORT": {
        "id": "WORT",
        "name": "WORTHINGTON CHAMP"
    },
    "WOTL": {
        "id": "WOTL",
        "name": "WILLIAMSON OCEAN TRAILERS SANDY UTAH"
    },
    "WOWC": {
        "id": "WOWC",
        "name": "WOW CARGO TRAIELRS COCHRAN GA"
    },
    "WPAR": {
        "id": "WPAR",
        "name": "WOODLAND PARK"
    },
    "WPRK": {
        "id": "WPRK",
        "name": "WINTER PARK SERIES MFG BY CHAMPION HOME BUILDERS"
    },
    "WRAG": {
        "id": "WRAG",
        "name": "WRANGLER TRAILERS"
    },
    "WRAN": {
        "id": "WRAN",
        "name": "WRANGLER RECREATIONAL PRODUCTS FLORENCE"
    },
    "WRCK": {
        "id": "WRCK",
        "name": "WINDROCK ROCKWOOD TN"
    },
    "WREM": {
        "id": "WREM",
        "name": "WREM MARKETING CANADA TRAILERS"
    },
    "WRGH": {
        "id": "WRGH",
        "name": "WRIGHT STANDER & SENTAR MOWER MODELS"
    },
    "WRGT": {
        "id": "WRGT",
        "name": "WRIGHT TRAILERS"
    },
    "WRIG": {
        "id": "WRIG",
        "name": "HUNTER'S DREAM MFD BY WRIGHT INDUSTRIES"
    },
    "WRIH": {
        "id": "WRIH",
        "name": "KITTY HAWKMFD. BY WRIGHT BROTHERS"
    },
    "WRKH": {
        "id": "WRKH",
        "name": "WORKHORSE CUSTOM CHASIS"
    },
    "WRKP": {
        "id": "WRKP",
        "name": "WORK AREA PROTECTION CORP. ILLINOIS TRAILERS"
    },
    "WRLC": {
        "id": "WRLC",
        "name": "WORLD CLASS MOTORSPORTS"
    },
    "WRLD": {
        "id": "WRLD",
        "name": "WORLD TRAILERS"
    },
    "WRLK": {
        "id": "WRLK",
        "name": "WARLOCK TRAILERS"
    },
    "WRLT": {
        "id": "WRLT",
        "name": "WORLD TRAILERS OCALA"
    },
    "WRLY": {
        "id": "WRLY",
        "name": "WORLEY WELDING WORKS"
    },
    "WRMG": {
        "id": "WRMG",
        "name": "WHITE RIVER MARINE GROUP OZARK MO"
    },
    "WRMH": {
        "id": "WRMH",
        "name": "WESTRIDGE MOBILE HOME"
    },
    "WRRN": {
        "id": "WRRN",
        "name": "WARREN EQUIPMENT"
    },
    "WRVI": {
        "id": "WRVI",
        "name": "WESTERN RECREATIONAL VEHICLES"
    },
    "WRVW": {
        "id": "WRVW",
        "name": "WOOD RIVER WELDING"
    },
    "WSBT": {
        "id": "WSBT",
        "name": "WONDER STATE BOAT TRAILER"
    },
    "WSDM": {
        "id": "WSDM",
        "name": "WISDOM RIDES"
    },
    "WSID": {
        "id": "WSID",
        "name": "WILD SIDE LLC FRANKLIN"
    },
    "WSK": {
        "id": "WSK",
        "name": "WSK"
    },
    "WSLN": {
        "id": "WSLN",
        "name": "WESTLAND MOTOR COMPANY"
    },
    "WSLW": {
        "id": "WSLW",
        "name": "WINSLOW MOTOR SPORTS GRAFTON OH"
    },
    "WSMF": {
        "id": "WSMF",
        "name": "WIESNER METAL FAB BROOKS"
    },
    "WSNI": {
        "id": "WSNI",
        "name": "WESTERN INNOVATION PERKINS OK"
    },
    "WSTI": {
        "id": "WSTI",
        "name": "WESTERN TRAILERS INC CO-TEM CORP. BOISE IAHO"
    },
    "WSTN": {
        "id": "WSTN",
        "name": "WESTERN - NEIGHBORHOOD ELECTRIC VEH'S"
    },
    "WSTR": {
        "id": "WSTR",
        "name": "WESTERN STAR"
    },
    "WSTX": {
        "id": "WSTX",
        "name": "WES-TEX MANUFACTURING"
    },
    "WTCI": {
        "id": "WTCI",
        "name": "WATSON TRACTOR"
    },
    "WTKI": {
        "id": "WTKI",
        "name": "WESTANK INDUSTRIES"
    },
    "WTMI": {
        "id": "WTMI",
        "name": "WTM"
    },
    "WTML": {
        "id": "WTML",
        "name": "WICHITA TANK MFG."
    },
    "WTMM": {
        "id": "WTMM",
        "name": "WTM INC."
    },
    "WTRN": {
        "id": "WTRN",
        "name": "WORLD TRANS DIV OF REV GROUP"
    },
    "WTSN": {
        "id": "WTSN",
        "name": "WATSONIAN"
    },
    "WTTS": {
        "id": "WTTS",
        "name": "WES"
    },
    "WTWI": {
        "id": "WTWI",
        "name": "CIRCLE J STOCK TRAILER MFG BY WESTERN WORLD INC. PREV KNOWN ASWESTERN"
    },
    "WTXD": {
        "id": "WTXD",
        "name": "WEST TEXAS DOLLIES TOW DOLLIES FOR VEHICLES"
    },
    "WTZR": {
        "id": "WTZR",
        "name": "I.R. WITZER COMPANY"
    },
    "WUMH": {
        "id": "WUMH",
        "name": "WESTERN UNITED MANUFACTURED HOME"
    },
    "WWEK": {
        "id": "WWEK",
        "name": "WESTERWALDER EISENWERK WEST GERMANY"
    },
    "WWFS": {
        "id": "WWFS",
        "name": "WEAVER WFS"
    },
    "WWIN": {
        "id": "WWIN",
        "name": "WESTWARD INDUSTRIES"
    },
    "WWMC": {
        "id": "WWMC",
        "name": "WILD WEST MOTOR COMPANY POWAY"
    },
    "WWTH": {
        "id": "WWTH",
        "name": "WORTHINGTON WELDING"
    },
    "WWTI": {
        "id": "WWTI",
        "name": "WEEKEND WARRIOR TRAILERS INC."
    },
    "WWTL": {
        "id": "WWTL",
        "name": "WILD WEST TRAILERS MT PLEASANT TX"
    },
    "WWTM": {
        "id": "WWTM",
        "name": "W.W. TRAILER MANUFACTURERS"
    },
    "WWTR": {
        "id": "WWTR",
        "name": "WORLDWIDE TRAILER MANUFACTURING"
    },
    "WWWE": {
        "id": "WWWE",
        "name": "WHITEWATER WORTHY EQUIPMENT LLC CENTRAL POINT OR"
    },
    "WWWH": {
        "id": "WWWH",
        "name": "W & W WHITE HORSE TRAILER"
    },
    "WYDR": {
        "id": "WYDR",
        "name": "JOHN T. WYDRO"
    },
    "WYFR": {
        "id": "WYFR",
        "name": "WY FRAME CORP.ELKHART"
    },
    "WYLI": {
        "id": "WYLI",
        "name": "WYLIE MANUFACTURING"
    },
    "WYLS": {
        "id": "WYLS",
        "name": "WYLIE AND SON"
    },
    "WYMH": {
        "id": "WYMH",
        "name": "WAYSIDE MANUFACTURED HOME"
    },
    "WYNN": {
        "id": "WYNN",
        "name": "WYNN TRAILER WORKS"
    },
    "WYNO": {
        "id": "WYNO",
        "name": "WYNONA CORP.NAPPANEE"
    },
    "WYTT": {
        "id": "WYTT",
        "name": "WYATT'S TRAILER SALES LONGVIEW"
    },
    "XAIR": {
        "id": "XAIR",
        "name": "X AIRE MFG BY NEWMAR CORP"
    },
    "XCEL": {
        "id": "XCEL",
        "name": "X-CEL TRAILERS"
    },
    "XCSC": {
        "id": "XCSC",
        "name": "XCS CHOPPERS"
    },
    "XIAN": {
        "id": "XIAN",
        "name": "XIANGYUAN INDUSTRY"
    },
    "XINR": {
        "id": "XINR",
        "name": "XINRI E-VEHICLE"
    },
    "XINY": {
        "id": "XINY",
        "name": "XINYANG INDUSTRY"
    },
    "XIXI": {
        "id": "XIXI",
        "name": "XIAMEN XIASHING MOTORCYCLE"
    },
    "XKEL": {
        "id": "XKEL",
        "name": "XKELETON MOTORCYCLES"
    },
    "XLCT": {
        "id": "XLCT",
        "name": "XL CARTS INC FERNANDINA BEACH FL"
    },
    "XLSP": {
        "id": "XLSP",
        "name": "XL SPECIALIZED TRAILERS MANCHESTER"
    },
    "XLST": {
        "id": "XLST",
        "name": "X-L SPECIALIZED TRAILERS OELWIN"
    },
    "XMOT": {
        "id": "XMOT",
        "name": "XMOTOS CO"
    },
    "XMRK": {
        "id": "XMRK",
        "name": "EXMARK RIDING LAWN MOWER"
    },
    "XNGF": {
        "id": "XNGF",
        "name": "XINGFU MOTORCYCLE"
    },
    "XPFI": {
        "id": "XPFI",
        "name": "XPLORER MOTOR HOME DIV OF FRANK INDUSTRIES"
    },
    "XPLO": {
        "id": "XPLO",
        "name": "XPLORER ENTERPRISES OF CONSTANTINE"
    },
    "XTER": {
        "id": "XTER",
        "name": "XTERRA TRAILERS FLORIDA"
    },
    "XTFF": {
        "id": "XTFF",
        "name": "XTRA TUFF TRAILERS EASTMAN"
    },
    "XTGP": {
        "id": "XTGP",
        "name": "XTREME GREEN PRODUCTS"
    },
    "XTMD": {
        "id": "XTMD",
        "name": "XTREME MOTORCYCLE DESIGN"
    },
    "XTMM": {
        "id": "XTMM",
        "name": "XTREME MANUFACTURING LLC HENDERSON NV"
    },
    "XTRA": {
        "id": "XTRA",
        "name": "X-TRA CAMPER"
    },
    "XTRE": {
        "id": "XTRE",
        "name": "XTREME COMPANIES OGDEN"
    },
    "XTRM": {
        "id": "XTRM",
        "name": "XTREME ATVS"
    },
    "XUAN": {
        "id": "XUAN",
        "name": "SHANDONG XUANYU POWER MACHINERY"
    },
    "XYPW": {
        "id": "XYPW",
        "name": "XY POWERSPORTS OHIO ATV'S"
    },
    "XYZ": {
        "id": "XYZ",
        "name": "XYZ TRAILER"
    },
    "YACH": {
        "id": "YACH",
        "name": "YACHT CLUB"
    },
    "YADR": {
        "id": "YADR",
        "name": "YADRO TRAILERS MILWAUKEE"
    },
    "YAFF": {
        "id": "YAFF",
        "name": "PAUL YAFFE ORIGINALS LLC MOTORCYCLES PHOENIX"
    },
    "YAGE": {
        "id": "YAGE",
        "name": "YANGER MOBILE HOMES"
    },
    "YAKM": {
        "id": "YAKM",
        "name": "YAKIMA PRODUCTS"
    },
    "YALE": {
        "id": "YALE",
        "name": "MATERIALS HANDLING DIV."
    },
    "YAMA": {
        "id": "YAMA",
        "name": "YAMAHA MOTOR CORP"
    },
    "YAMP": {
        "id": "YAMP",
        "name": "YAMPA COACH MFG."
    },
    "YAMT": {
        "id": "YAMT",
        "name": "YAMOTO ATV"
    },
    "YANG": {
        "id": "YANG",
        "name": "YANGZHOU TONGHUA SEMI TRAILER"
    },
    "YANK": {
        "id": "YANK",
        "name": "YANKEE"
    },
    "YANM": {
        "id": "YANM",
        "name": "YANMAR AMERICA CORPORATION GEORGIA"
    },
    "YARB": {
        "id": "YARB",
        "name": "COMET MOTORCYCLE TRAILER MFD BY YARBROUGH MFG."
    },
    "YARD": {
        "id": "YARD",
        "name": "YARD-MAN"
    },
    "YARM": {
        "id": "YARM",
        "name": "YARD MACHINE SUBSIDIARY OF MTD INDUSTRIES"
    },
    "YAXI": {
        "id": "YAXI",
        "name": "YAXI MOTORCYCLE"
    },
    "YAZO": {
        "id": "YAZO",
        "name": "YAZOO MFG."
    },
    "YBWL": {
        "id": "YBWL",
        "name": "Y.B. WELDING"
    },
    "YDSM": {
        "id": "YDSM",
        "name": "YODER SMOKERS HUTCHINSON KS"
    },
    "YELL": {
        "id": "YELL",
        "name": "YELLOWSTONE"
    },
    "YENC": {
        "id": "YENC",
        "name": "YENCO"
    },
    "YENK": {
        "id": "YENK",
        "name": "YENKO"
    },
    "YENT": {
        "id": "YENT",
        "name": "YENTES BROTHERS WELDING LANCASTER"
    },
    "YETT": {
        "id": "YETT",
        "name": "YETTER MFG."
    },
    "YGAM": {
        "id": "YGAM",
        "name": "YOUNG AMERICAN MMANUFACTURED HOME"
    },
    "YING": {
        "id": "YING",
        "name": "YINGANG SCIENCE & TECH GROUP OR CHONGQING YINGANG SCIENCE & TECH GROUP"
    },
    "YINX": {
        "id": "YINX",
        "name": "YINXIANG MOTORCYCLE GROUP OR CHANGQING YINXIANG MOTORCYCLE GROUP"
    },
    "YIRO": {
        "id": "YIRO",
        "name": "YIRO FABRICA MEXICALI MEXICO"
    },
    "YLN": {
        "id": "YLN",
        "name": "YLN"
    },
    "YMCL": {
        "id": "YMCL",
        "name": "YAMAHA MOTOR COMPANY"
    },
    "YMSK": {
        "id": "YMSK",
        "name": "YAMASAKI MOTORCYLE"
    },
    "YMTI": {
        "id": "YMTI",
        "name": "YAMATI"
    },
    "YNCH": {
        "id": "YNCH",
        "name": "YOUNGS CHOPPERS"
    },
    "YNGF": {
        "id": "YNGF",
        "name": "YONGFU MACHINE"
    },
    "YNGH": {
        "id": "YNGH",
        "name": "YONGHENG CAR INDUSTRY"
    },
    "YNGM": {
        "id": "YNGM",
        "name": "YOUNGMAN AUTOMOBILE"
    },
    "YNGQ": {
        "id": "YNGQ",
        "name": "YONGQIANG VEHICLES FACTORY"
    },
    "YNKE": {
        "id": "YNKE",
        "name": "YANKE MACHINE SHOP"
    },
    "YONG": {
        "id": "YONG",
        "name": "YONGHE MOTORCYCLE OR SUZHOU INDUSTRIAL PARK YONGHE MOTORCYCLE SUZHOU"
    },
    "YORK": {
        "id": "YORK",
        "name": "YORK FORKLIFT"
    },
    "YORM": {
        "id": "YORM",
        "name": "YORK MODERN CORP."
    },
    "YOUG": {
        "id": "YOUG",
        "name": "YOUNG CORP."
    },
    "YOUN": {
        "id": "YOUN",
        "name": "YOUNG SPRING & WIRE"
    },
    "YPRO": {
        "id": "YPRO",
        "name": "YARD PRO"
    },
    "YSOB": {
        "id": "YSOB",
        "name": "YSOB"
    },
    "YUBA": {
        "id": "YUBA",
        "name": "YUBA TRAILERS HEBER SPRINGS"
    },
    "YUCH": {
        "id": "YUCH",
        "name": "YUCHI MOBILE HOMES"
    },
    "YUKO": {
        "id": "YUKO",
        "name": "YUKON DELTA"
    },
    "YULE": {
        "id": "YULE",
        "name": "ZHEJIANG YULE NEW ENERGY AUTOMOBILE CHINA"
    },
    "YWAA": {
        "id": "YWAA",
        "name": "YW"
    },
    "ZAPO": {
        "id": "ZAPO",
        "name": "ZAPOROZHETS"
    },
    "ZAPP": {
        "id": "ZAPP",
        "name": "ZAP"
    },
    "ZARC": {
        "id": "ZARC",
        "name": "ZAR CAR"
    },
    "ZBMI": {
        "id": "ZBMI",
        "name": "ZIERKE BUILT MANUFACTURING INC FAIRMONT MN"
    },
    "ZCKY": {
        "id": "ZCKY",
        "name": "ZACKYS OR ZACKY'S CUSTOM RIDES A NORTH AURORA BODY AND PAINT EVERETT"
    },
    "ZCPC": {
        "id": "ZCPC",
        "name": "ZHEJIANG CFMOTO POWER"
    },
    "ZCZY": {
        "id": "ZCZY",
        "name": "ZASTAVIA"
    },
    "ZELI": {
        "id": "ZELI",
        "name": "ZELIGSON TRUCKS & EQUIPMENT"
    },
    "ZENN": {
        "id": "ZENN",
        "name": "ZENN MOTOR COMPNAY LTD. ZENN MODEL"
    },
    "ZEPH": {
        "id": "ZEPH",
        "name": "ZEPHYR MOTORHOMES MFG BY TIFFIN MOTORHOMES"
    },
    "ZERO": {
        "id": "ZERO",
        "name": "ZERO MOTORCYCLES INC CALIFORNIA MOTORCYCLES"
    },
    "ZETA": {
        "id": "ZETA",
        "name": "ZETA"
    },
    "ZETR": {
        "id": "ZETR",
        "name": "ZETOR"
    },
    "ZEVC": {
        "id": "ZEVC",
        "name": "Z ELECTRIC VEHICLE CORPORATION HENDERSON CITY"
    },
    "ZHEJ": {
        "id": "ZHEJ",
        "name": "ZHEJIANG XINGFU MOTORCYCLE MACHINE"
    },
    "ZHEN": {
        "id": "ZHEN",
        "name": "ZHENFEI TOOLS"
    },
    "ZHGG": {
        "id": "ZHGG",
        "name": "ZHONGGONG ELECTRICAL"
    },
    "ZHGM": {
        "id": "ZHGM",
        "name": "ZHONGMAO MACHINERY"
    },
    "ZHGO": {
        "id": "ZHGO",
        "name": "ZHONGMO TECHNOLOGY"
    },
    "ZHIW": {
        "id": "ZHIW",
        "name": "ZHIWEI MOTORCYCLE MANUFACTURING"
    },
    "ZHNG": {
        "id": "ZHNG",
        "name": "TAIZHOU ZHONGNENG"
    },
    "ZHON": {
        "id": "ZHON",
        "name": "ZHONGYU GROUP MOTORCYCLES ETC."
    },
    "ZHPI": {
        "id": "ZHPI",
        "name": "ZHEJIANG PEACE INDUSTRY & TRADE"
    },
    "ZHSA": {
        "id": "ZHSA",
        "name": "ZHEJIANG SHENMAO APPLIANCE"
    },
    "ZIEG": {
        "id": "ZIEG",
        "name": "ZIEGLER"
    },
    "ZIEM": {
        "id": "ZIEM",
        "name": "ZIEMAN MANUFACTURING COMPANY INC"
    },
    "ZIL": {
        "id": "ZIL",
        "name": "ZIL"
    },
    "ZIM": {
        "id": "ZIM",
        "name": "ZIM"
    },
    "ZIMM": {
        "id": "ZIMM",
        "name": "CONSORT MOBILE HOMES MFD BY ZIMMER HOMES CORP."
    },
    "ZIMR": {
        "id": "ZIMR",
        "name": "ZIMMERMAN AUTOMOBILES"
    },
    "ZINI": {
        "id": "ZINI",
        "name": "ZINI AMERICA"
    },
    "ZINK": {
        "id": "ZINK",
        "name": "ZINK ROADSTERS"
    },
    "ZION": {
        "id": "ZION",
        "name": "ZIONS TINY HOMES ST GEORGE UT"
    },
    "ZIPP": {
        "id": "ZIPP",
        "name": "ZIPPER"
    },
    "ZIRC": {
        "id": "ZIRC",
        "name": "ZIRCUS KRONE"
    },
    "ZLMC": {
        "id": "ZLMC",
        "name": "ZHEJIANG LINGYU MOTORCYCLE"
    },
    "ZLMI": {
        "id": "ZLMI",
        "name": "ZHEJIANG LEIKE MACHINERY"
    },
    "ZLSV": {
        "id": "ZLSV",
        "name": "ZIGGY INC"
    },
    "ZMCC": {
        "id": "ZMCC",
        "name": "ZIMMER MOTOR CAR COMPANY SYRACUSE"
    },
    "ZMMN": {
        "id": "ZMMN",
        "name": "ZIMMERMAN TRAILERS"
    },
    "ZNEL": {
        "id": "ZNEL",
        "name": "ZANELLA"
    },
    "ZOBO": {
        "id": "ZOBO",
        "name": "ZOBODA"
    },
    "ZOLL": {
        "id": "ZOLL",
        "name": "ZOLLINGER TRAILER"
    },
    "ZONE": {
        "id": "ZONE",
        "name": "ZONE ELECTRIC CAR"
    },
    "ZONG": {
        "id": "ZONG",
        "name": "ZONGSHEN MOTORCYCLES"
    },
    "ZPYR": {
        "id": "ZPYR",
        "name": "ZEPHYR TRAILER MARKETING"
    },
    "ZUND": {
        "id": "ZUND",
        "name": "ZUNDAPP"
    },
    "ZWIC": {
        "id": "ZWIC",
        "name": "ZWICKAU AUTOMOBILWERK ZWICKAU EISENACH GERMANY"
    },
    "ZXYV": {
        "id": "ZXYV",
        "name": "ZHEJIANG XINGYUE VEHICLE"
    },
    "ZYTD": {
        "id": "ZYTD",
        "name": "ZHEJIANG YADEA TECHNICAL DEV"
    },
    "ZZTL": {
        "id": "ZZTL",
        "name": "ZZ TRAILERS"
    }
};

export const VehicleModelCodeEnum = {
    "ODY": {
        "id": "ODY",
        "name": "ODYSSEY"
    },
    "OLY": {
        "id": "OLY",
        "name": "OLYMPIA"
    },
    "OME": {
        "id": "OME",
        "name": "OMEGA"
    },
    "OMG": {
        "id": "OMG",
        "name": "OMEGA"
    },
    "OPI": {
        "id": "OPI",
        "name": "OPIRUS"
    },
    "OPL": {
        "id": "OPL",
        "name": "OPEL"
    },
    "ORL": {
        "id": "ORL",
        "name": "ORLANDO"
    },
    "OXF": {
        "id": "OXF",
        "name": "OXFORD"
    },
    "OneGL": {
        "id": "100",
        "name": "1GL"
    },
    "OneLS": {
        "id": "1LS",
        "name": "100GL"
    },
    "TwoFourZ": {
        "id": "24Z",
        "name": "200SX210"
    },
    "TwentySixHundredSprint": {
        "id": "26P",
        "name": "2600 SPRINT"
    },
    "TwnetySixHundredSpider": {
        "id": "26S",
        "name": "2600 SPIDER"
    },
    "TwoLS": {
        "id": "2LS",
        "name": "2LS"
    },
    "TwoLT": {
        "id": "2LT",
        "name": "2LT"
    },
    "ThirtyEightLitre": {
        "id": "38L",
        "name": "3.4 LITRE"
    },
    "ThreeGT": {
        "id": "3GT",
        "name": "3000 GT"
    },
    "ThreeLitre": {
        "id": "3L",
        "name": "3 LITRE3500"
    },
    "FourC": {
        "id": "4C",
        "name": "4C"
    },
    "FourRunner": {
        "id": "4RN",
        "name": "4-RUNNER"
    },
    "EightFourtyCI": {
        "id": "84C",
        "name": "840CI"
    },
    "A10": {
        "id": "A10",
        "name": "A99 & 110"
    },
    "A2": {
        "id": "A2",
        "name": "A3"
    },
    "A3": {
        "id": "A3",
        "name": "AA4"
    },
    "A4": {
        "id": "A4",
        "name": "A5"
    },
    "A40": {
        "id": "A40",
        "name": "A40"
    },
    "A5": {
        "id": "A5",
        "name": "AA6"
    },
    "A55": {
        "id": "A55",
        "name": "A55"
    },
    "A6": {
        "id": "A6",
        "name": "A7"
    },
    "A7": {
        "id": "A7",
        "name": "AA8"
    },
    "A8": {
        "id": "A8",
        "name": "ALL"
    },
    "ACC": {
        "id": "ACC",
        "name": "ACCORD"
    },
    "ATO": {
        "id": "ATO",
        "name": "ACCENT"
    },
    "AOW": {
        "id": "AOW",
        "name": "ACCLAIM"
    },
    "ACT": {
        "id": "ACT",
        "name": "ACTIVE E"
    },
    "ACV": {
        "id": "ACV",
        "name": "ACHIEVA"
    },
    "AGR": {
        "id": "AGR",
        "name": "AGERA"
    },
    "AH3": {
        "id": "AH3",
        "name": "ACTIVE HYBRID 3"
    },
    "AH5": {
        "id": "AH5",
        "name": "ACTIVE HYBRID 5"
    },
    "AIR": {
        "id": "AIR",
        "name": "AIRFLYTE"
    },
    "AL": {
        "id": "AL",
        "name": "AL3"
    },
    "ALO": {
        "id": "ALO",
        "name": "ALERO"
    },
    "ALL": {
        "id": "ALL",
        "name": "ALLANTE"
    },
    "ALR": {
        "id": "ALR",
        "name": "ALLURE"
    },
    "ALT": {
        "id": "ALT",
        "name": "ALTIMA"
    },
    "AX": {
        "id": "AX",
        "name": "AM16"
    },
    "AMA": {
        "id": "AMA",
        "name": "AMANTI"
    },
    "AMX": {
        "id": "AMX",
        "name": "AMERICAN"
    },
    "ASC": {
        "id": "ASC",
        "name": "AMIGO"
    },
    "AMP": {
        "id": "AMP",
        "name": "AMPERA ELECTRIC VEHICLE"
    },
    "AP0": {
        "id": "AP0",
        "name": "APOLLO"
    },
    "AR0": {
        "id": "AR0",
        "name": "AERIO"
    },
    "ARI": {
        "id": "ARI",
        "name": "ARIES"
    },
    "ARM": {
        "id": "ARM",
        "name": "ARMADA"
    },
    "AXI": {
        "id": "AXI",
        "name": "ASCENDER"
    },
    "ATL": {
        "id": "ATL",
        "name": "ATLAS"
    },
    "ATR": {
        "id": "ATR",
        "name": "ALTRA-EV"
    },
    "ATS": {
        "id": "ATS",
        "name": "ATS"
    },
    "ATT": {
        "id": "ATT",
        "name": "ATTITUDE"
    },
    "AUB": {
        "id": "AUB",
        "name": "AUBURN"
    },
    "AVO": {
        "id": "AVO",
        "name": "AVALANCHE"
    },
    "AZR": {
        "id": "AZR",
        "name": "AVATAR"
    },
    "AVN": {
        "id": "AVN",
        "name": "AVENGER"
    },
    "AXX": {
        "id": "AXX",
        "name": "AXXESS"
    },
    "AZT": {
        "id": "AZT",
        "name": "AZTEK"
    },
    "AZU": {
        "id": "AZU",
        "name": "AZURE"
    },
    "B0B": {
        "id": "B0B",
        "name": "BOBCAT"
    },
    "B0N": {
        "id": "B0N",
        "name": "BONNEVILLE"
    },
    "B20": {
        "id": "B20",
        "name": "B200"
    },
    "B22": {
        "id": "B22",
        "name": "B2000"
    },
    "B23": {
        "id": "B23",
        "name": "B2200"
    },
    "B25": {
        "id": "B25",
        "name": "B2300"
    },
    "B26": {
        "id": "B26",
        "name": "B2500"
    },
    "B30": {
        "id": "B30",
        "name": "B2600"
    },
    "B40": {
        "id": "B40",
        "name": "B3000"
    },
    "BAC": {
        "id": "BAC",
        "name": "BACI"
    },
    "BAR": {
        "id": "BAR",
        "name": "BARCHETTA"
    },
    "BAV": {
        "id": "BAV",
        "name": "BAVARIA"
    },
    "BEA": {
        "id": "BEA",
        "name": "BEAUMONT SERIES"
    },
    "BED": {
        "id": "BED",
        "name": "B-CLASS"
    },
    "BET": {
        "id": "BET",
        "name": "BERLINA"
    },
    "BLZ": {
        "id": "BLZ",
        "name": "BISCAYNE"
    },
    "BIT": {
        "id": "BIT",
        "name": "BITURBO"
    },
    "BJA": {
        "id": "BJA",
        "name": "BAJA"
    },
    "BLT": {
        "id": "BLT",
        "name": "BLAZER"
    },
    "BRK": {
        "id": "BRK",
        "name": "BOLT"
    },
    "BRE": {
        "id": "BRE",
        "name": "BREEZEWAYBR0"
    },
    "BRM": {
        "id": "BRM",
        "name": "BROADMOOR"
    },
    "BRT": {
        "id": "BRT",
        "name": "BRAT"
    },
    "BRZ": {
        "id": "BRZ",
        "name": "BRZ"
    },
    "BTY": {
        "id": "BTY",
        "name": "BENTAYGA"
    },
    "BUG": {
        "id": "BUG",
        "name": "BEETLE"
    },
    "BVD": {
        "id": "BVD",
        "name": "BRAVADA"
    },
    "C45": {
        "id": "C45",
        "name": "C/K 3500"
    },
    "C0A": {
        "id": "C0A",
        "name": "COROLLA"
    },
    "C20": {
        "id": "C20",
        "name": "C30"
    },
    "C22": {
        "id": "C22",
        "name": "C220"
    },
    "C23": {
        "id": "C23",
        "name": "C230"
    },
    "C24": {
        "id": "C24",
        "name": "C240"
    },
    "C28": {
        "id": "C28",
        "name": "C280"
    },
    "C32": {
        "id": "C32",
        "name": "C320"
    },
    "C36": {
        "id": "C36",
        "name": "C36"
    },
    "C40": {
        "id": "C40",
        "name": "C400"
    },
    "C43": {
        "id": "C43",
        "name": "C43"
    },
    "C55": {
        "id": "C55",
        "name": "C55 AMG"
    },
    "C63": {
        "id": "C63",
        "name": "CL63 AMG"
    },
    "C65": {
        "id": "C65",
        "name": "CL65"
    },
    "C6A": {
        "id": "C6A",
        "name": "C63 AMG"
    },
    "C70": {
        "id": "C70",
        "name": "C0B"
    },
    "C8": {
        "id": "C8",
        "name": "C8 LAVIOLETTE"
    },
    "CAN": {
        "id": "CAN",
        "name": "CANSO SERIES"
    },
    "CTV": {
        "id": "CTV",
        "name": "CAPRICE"
    },
    "CEL": {
        "id": "CEL",
        "name": "CAVALIER"
    },
    "CLI": {
        "id": "CLI",
        "name": "CAVALIER"
    },
    "CNQ": {
        "id": "CNQ",
        "name": "CAVALIER"
    },
    "CAY": {
        "id": "CAY",
        "name": "CAYENNE"
    },
    "CBT": {
        "id": "CBT",
        "name": "COBALT"
    },
    "CC": {
        "id": "CC",
        "name": "CC"
    },
    "CCX": {
        "id": "CCX",
        "name": "CCX"
    },
    "CDZ": {
        "id": "CDZ",
        "name": "CADENZA"
    },
    "CEN": {
        "id": "CEN",
        "name": "CENTURY"
    },
    "CJ5": {
        "id": "CJ5",
        "name": "CHEROKEE"
    },
    "CXP": {
        "id": "CXP",
        "name": "CHEVETTE"
    },
    "ChevyCityExpress": {
        "id": "CHEVY II",
        "name": "CHEVROLET CITY EXPRESSCH2"
    },
    "CHI": {
        "id": "CHI",
        "name": "CHIEFTAIN"
    },
    "CHL": {
        "id": "CHL",
        "name": "CHALLENGER"
    },
    "CHM": {
        "id": "CHM",
        "name": "CHAMPION"
    },
    "CHR": {
        "id": "CHR",
        "name": "C-HR"
    },
    "CHY": {
        "id": "CHY",
        "name": "CHEYENNE"
    },
    "CIM": {
        "id": "CIM",
        "name": "CIMARRON"
    },
    "CIR": {
        "id": "CIR",
        "name": "CIRRUS"
    },
    "CIV": {
        "id": "CIV",
        "name": "CIVIC"
    },
    "CJ6": {
        "id": "CJ6",
        "name": "CJ-5"
    },
    "CJ7": {
        "id": "CJ7",
        "name": "CJ-6"
    },
    "CJ8": {
        "id": "CJ8",
        "name": "CJ-7"
    },
    "CL": {
        "id": "CL",
        "name": "CL"
    },
    "CL2": {
        "id": "CL2",
        "name": "CLA250"
    },
    "CL5": {
        "id": "CL5",
        "name": "CL500CL550"
    },
    "CL6": {
        "id": "CL6",
        "name": "CL600"
    },
    "CLD": {
        "id": "CLD",
        "name": "COLORADO"
    },
    "CL4": {
        "id": "CL4",
        "name": "CLK350"
    },
    "CK5": {
        "id": "CK5",
        "name": "CLK430"
    },
    "CS4": {
        "id": "CS4",
        "name": "CLK63 AMG"
    },
    "CLR": {
        "id": "CLR",
        "name": "CLARUS"
    },
    "CW2": {
        "id": "CW2",
        "name": "CLUB WAGON E150"
    },
    "CW3": {
        "id": "CW3",
        "name": "CLUB WAGON E250"
    },
    "CPR": {
        "id": "CPR",
        "name": "CLUBMAN"
    },
    "CW1": {
        "id": "CW1",
        "name": "C-MAX"
    },
    "CNC": {
        "id": "CNC",
        "name": "CONCORDE"
    },
    "CNF": {
        "id": "CNF",
        "name": "CONFEDERATE"
    },
    "CMD": {
        "id": "CMD",
        "name": "COMANCHE"
    },
    "CMM": {
        "id": "CMM",
        "name": "COMET"
    },
    "CMP": {
        "id": "CMP",
        "name": "COMMANDER"
    },
    "C0U": {
        "id": "C0U",
        "name": "COMMUTER"
    },
    "CRF": {
        "id": "CRF",
        "name": "CORDOBA"
    },
    "CTA": {
        "id": "CTA",
        "name": "CORSAIR"
    },
    "CX5": {
        "id": "CX5",
        "name": "COSMO"
    },
    "CPS": {
        "id": "CPS",
        "name": "COOPER S"
    },
    "CR0": {
        "id": "CR0",
        "name": "CROWN"
    },
    "CRA": {
        "id": "CRA",
        "name": "CRANBROOK"
    },
    "CRK": {
        "id": "CRK",
        "name": "CRICKET"
    },
    "CRL": {
        "id": "CRL",
        "name": "CARAVELLE"
    },
    "CRS": {
        "id": "CRS",
        "name": "CORSICA"
    },
    "CRU": {
        "id": "CRU",
        "name": "CRUISER"
    },
    "CT6": {
        "id": "CT6",
        "name": "CT6CTS"
    },
    "CTK": {
        "id": "CTK",
        "name": "CROSSTREK"
    },
    "CUB": {
        "id": "CUB",
        "name": "CUBE"
    },
    "CYC": {
        "id": "CYC",
        "name": "CUSTOM"
    },
    "CUT": {
        "id": "CUT",
        "name": "CUTLASS"
    },
    "CVC": {
        "id": "CVC",
        "name": "CROWN VICTORIA"
    },
    "CVR": {
        "id": "CVR",
        "name": "CORSA"
    },
    "CVT": {
        "id": "CVT",
        "name": "CORVETTE"
    },
    "CX7": {
        "id": "CX7",
        "name": "CX9"
    },
    "DAK": {
        "id": "DAK",
        "name": "DAKOTA"
    },
    "DAS": {
        "id": "DAS",
        "name": "DASHER"
    },
    "DB5": {
        "id": "DB5",
        "name": "DB-5"
    },
    "DB6": {
        "id": "DB6",
        "name": "DB-6"
    },
    "DB7": {
        "id": "DB7",
        "name": "DB7"
    },
    "DB9": {
        "id": "DB9",
        "name": "DB9"
    },
    "DBS": {
        "id": "DBS",
        "name": "DBS"
    },
    "DIS": {
        "id": "DIS",
        "name": "DEFENDER 90 & 110"
    },
    "DVN": {
        "id": "DVN",
        "name": "DELTA"
    },
    "DEM": {
        "id": "DEM",
        "name": "DEMON"
    },
    "DEN": {
        "id": "DEN",
        "name": "DENALI"
    },
    "DTS": {
        "id": "DTS",
        "name": "DEVILLE"
    },
    "DIP": {
        "id": "DIP",
        "name": "DIPLOMAT"
    },
    "DL": {
        "id": "DL",
        "name": "DL"
    },
    "DLM": {
        "id": "DLM",
        "name": "DELMONT 88"
    },
    "DLT": {
        "id": "DLT",
        "name": "DELTA 88"
    },
    "D8": {
        "id": "D8",
        "name": "DMC-12"
    },
    "DP": {
        "id": "DP",
        "name": "DIPLOMAT"
    },
    "DRA": {
        "id": "DRA",
        "name": "DRAGON"
    },
    "DST": {
        "id": "DST",
        "name": "DESTINO"
    },
    "DUE": {
        "id": "DUE",
        "name": "DUETTOF136"
    },
    "DUR": {
        "id": "DUR",
        "name": "DURANGO"
    },
    "DWN": {
        "id": "DWN",
        "name": "DAWN"
    },
    "E0S": {
        "id": "E0S",
        "name": "EOS"
    },
    "E10": {
        "id": "E10",
        "name": "EB110"
    },
    "E2": {
        "id": "E2",
        "name": "E4"
    },
    "E30": {
        "id": "E30",
        "name": "E250"
    },
    "E32": {
        "id": "E32",
        "name": "E300"
    },
    "E40": {
        "id": "E40",
        "name": "E320"
    },
    "E4": {
        "id": "E4",
        "name": "E6"
    },
    "E42": {
        "id": "E42",
        "name": "E400"
    },
    "E43": {
        "id": "E43",
        "name": "E420"
    },
    "E50": {
        "id": "E50",
        "name": "E430"
    },
    "E45": {
        "id": "E45",
        "name": "ECONOLINE E-450"
    },
    "E5": {
        "id": "E5",
        "name": "E55"
    },
    "E63": {
        "id": "E63",
        "name": "E550"
    },
    "EC1": {
        "id": "EC1",
        "name": "ECONOLINE E150"
    },
    "EC2": {
        "id": "EC2",
        "name": "ECONOLINE E250"
    },
    "EC3": {
        "id": "EC3",
        "name": "ECONOLINE E350"
    },
    "ELA": {
        "id": "ELA",
        "name": "ECLAT"
    },
    "EDV": {
        "id": "EDV",
        "name": "ECLIPSE & ECLIPSE SPYDER GS-T"
    },
    "ECP": {
        "id": "ECP",
        "name": "ESCAPE"
    },
    "ECR": {
        "id": "ECR",
        "name": "ENCORE"
    },
    "ECS": {
        "id": "ECS",
        "name": "ECOSPORT"
    },
    "EDG": {
        "id": "EDG",
        "name": "EDGE"
    },
    "EGT": {
        "id": "EGT",
        "name": "EIGHT"
    },
    "ELS": {
        "id": "ELS",
        "name": "ELAN"
    },
    "ENT": {
        "id": "ENT",
        "name": "ELANTRA"
    },
    "ELC": {
        "id": "ELC",
        "name": "EL CAMINO"
    },
    "ESP": {
        "id": "ESP",
        "name": "ELITE"
    },
    "ENS": {
        "id": "ENS",
        "name": "ENSIGN"
    },
    "EQU": {
        "id": "EQU",
        "name": "ENTOURAGE"
    },
    "ENZ": {
        "id": "ENZ",
        "name": "ENZO"
    },
    "EPC": {
        "id": "EPC",
        "name": "EPIC"
    },
    "EPI": {
        "id": "EPI",
        "name": "EPICA"
    },
    "EQT": {
        "id": "EQT",
        "name": "EQUATOR"
    },
    "EQX": {
        "id": "EQX",
        "name": "EQUINOX"
    },
    "ESC": {
        "id": "ESC",
        "name": "ESCORT"
    },
    "EVR": {
        "id": "EVR",
        "name": "EUROPA"
    },
    "EXG": {
        "id": "EXG",
        "name": "EVORA"
    },
    "EVP": {
        "id": "EVP",
        "name": "EVPLUS"
    },
    "EVT": {
        "id": "EVT",
        "name": "EVERITT 30"
    },
    "E37": {
        "id": "E37",
        "name": "EX35"
    },
    "F0C": {
        "id": "F0C",
        "name": "FOCUS"
    },
    "F0X": {
        "id": "F0X",
        "name": "FOX"
    },
    "F10": {
        "id": "F10",
        "name": "F100"
    },
    "F12": {
        "id": "F12",
        "name": "F12"
    },
    "F25": {
        "id": "F25",
        "name": "F250 SUPERCAB"
    },
    "F45": {
        "id": "F45",
        "name": "F450"
    },
    "FF": {
        "id": "FF",
        "name": "F50"
    },
    "F55": {
        "id": "F55",
        "name": "F550"
    },
    "F65": {
        "id": "F65",
        "name": "F650 SUPER CREW"
    },
    "F75": {
        "id": "F75",
        "name": "F750"
    },
    "F80": {
        "id": "F80",
        "name": "F800"
    },
    "F85": {
        "id": "F85",
        "name": "F-85"
    },
    "FAI": {
        "id": "FAI",
        "name": "FAIRLANE"
    },
    "FAL": {
        "id": "FAL",
        "name": "FALCON"
    },
    "FAR": {
        "id": "FAR",
        "name": "FAIRMONT"
    },
    "FB": {
        "id": "FB",
        "name": "FASTBACK"
    },
    "FBD": {
        "id": "FBD",
        "name": "FIREBIRD"
    },
    "FCX": {
        "id": "FCX",
        "name": "FCX FUEL CELL VEHICLE"
    },
    "FE": {
        "id": "FE",
        "name": "FE2"
    },
    "FES": {
        "id": "FES",
        "name": "FESTIVA"
    },
    "FIR": {
        "id": "FIR",
        "name": "FIRENZA"
    },
    "FRF": {
        "id": "FRF",
        "name": "FIREDOME"
    },
    "FRS": {
        "id": "FRS",
        "name": "FIRELITE"
    },
    "FIT": {
        "id": "FIT",
        "name": "FIT"
    },
    "FJC": {
        "id": "FJC",
        "name": "FJ CRUISER"
    },
    "FLA": {
        "id": "FLA",
        "name": "FLAMINIA"
    },
    "FUL": {
        "id": "FUL",
        "name": "FLAVIA"
    },
    "FLE": {
        "id": "FLE",
        "name": "FLEETLINE"
    },
    "FLX": {
        "id": "FLX",
        "name": "FLEX"
    },
    "FNZ": {
        "id": "FNZ",
        "name": "FORENZA"
    },
    "FTY": {
        "id": "FTY",
        "name": "F-PACE"
    },
    "FR0": {
        "id": "FR0",
        "name": "FRONTENAC"
    },
    "FRE": {
        "id": "FRE",
        "name": "FREESTAR"
    },
    "FSY": {
        "id": "FSY",
        "name": "FREESTYLE"
    },
    "FUR": {
        "id": "FUR",
        "name": "FURY"
    },
    "FUS": {
        "id": "FUS",
        "name": "FUSION"
    },
    "FUT": {
        "id": "FUT",
        "name": "FUTURA"
    },
    "FX3": {
        "id": "FX3",
        "name": "FX 50"
    },
    "F37": {
        "id": "F37",
        "name": "FX35"
    },
    "FX4": {
        "id": "FX4",
        "name": "FX37"
    },
    "FZA": {
        "id": "FZA",
        "name": "FIRENZA"
    },
    "G0L": {
        "id": "G0L",
        "name": "GOLF"
    },
    "G3": {
        "id": "G3",
        "name": "G3"
    },
    "G4": {
        "id": "G4",
        "name": "G4"
    },
    "G40": {
        "id": "G40",
        "name": "GS400"
    },
    "G43": {
        "id": "G43",
        "name": "GS430"
    },
    "G46": {
        "id": "G46",
        "name": "GS460"
    },
    "G47": {
        "id": "G47",
        "name": "GX470"
    },
    "G5": {
        "id": "G5",
        "name": "G5"
    },
    "G6A": {
        "id": "G6A",
        "name": "G550"
    },
    "G6": {
        "id": "G6",
        "name": "G6"
    },
    "G65": {
        "id": "G65",
        "name": "G63 AMG"
    },
    "GLW": {
        "id": "GLW",
        "name": "G65 AMG"
    },
    "G8": {
        "id": "G8",
        "name": "G8"
    },
    "G80": {
        "id": "G80",
        "name": "G90"
    },
    "G90": {
        "id": "G90",
        "name": "G0G"
    },
    "GAL": {
        "id": "GAL",
        "name": "GALAXIE"
    },
    "GCH": {
        "id": "GCH",
        "name": "GRAND CHEROKEE"
    },
    "GL3": {
        "id": "GL3",
        "name": "GELAENDEWAGEN"
    },
    "GHO": {
        "id": "GHO",
        "name": "GHOST"
    },
    "GSP": {
        "id": "GSP",
        "name": "GHIBLI"
    },
    "GIP": {
        "id": "GIP",
        "name": "GIULIA SPRINT"
    },
    "GIS": {
        "id": "GIS",
        "name": "GIULIA SPIDER"
    },
    "GIT": {
        "id": "GIT",
        "name": "GIULIETTA"
    },
    "GIU": {
        "id": "GIU",
        "name": "GIULIA"
    },
    "GL4": {
        "id": "GL4",
        "name": "GL350"
    },
    "G30": {
        "id": "G30",
        "name": "GLA250"
    },
    "GK3": {
        "id": "GK3",
        "name": "GLC300"
    },
    "GLD": {
        "id": "GLD",
        "name": "GOLDEN SPIRIT"
    },
    "GLF": {
        "id": "GLF",
        "name": "GLF"
    },
    "GLI": {
        "id": "GLI",
        "name": "GLI"
    },
    "GLX": {
        "id": "GLX",
        "name": "GCP"
    },
    "GPW": {
        "id": "GPW",
        "name": "GPW"
    },
    "GTU": {
        "id": "GTU",
        "name": "GRAN SPORT"
    },
    "GRD": {
        "id": "GRD",
        "name": "GRAND VILLE"
    },
    "GRM": {
        "id": "GRM",
        "name": "GRAND AM"
    },
    "GS3": {
        "id": "GS3",
        "name": "GS200T"
    },
    "GS350": {
        "id": "GS350",
        "name": "GS300G35"
    },
    "GS4": {
        "id": "GS4",
        "name": "GS400"
    },
    "GSC": {
        "id": "GSC",
        "name": "GSC SAN SEBASTION"
    },
    "GSF": {
        "id": "GSF",
        "name": "GSF"
    },
    "GTO": {
        "id": "GTO",
        "name": "GTO"
    },
    "GTP": {
        "id": "GTP",
        "name": "GTP"
    },
    "GTR": {
        "id": "GTR",
        "name": "GT-R"
    },
    "GTV": {
        "id": "GTV",
        "name": "GT VELOCE"
    },
    "GTX": {
        "id": "GTX",
        "name": "GTX"
    },
    "GVT": {
        "id": "GVT",
        "name": "GRAND VITARA"
    },
    "GX4": {
        "id": "GX4",
        "name": "GX460"
    },
    "HOL": {
        "id": "HOL",
        "name": "HOLIDAY"
    },
    "HOR": {
        "id": "HOR",
        "name": "HORIZON"
    },
    "H25": {
        "id": "H25",
        "name": "HS250H"
    },
    "HAN": {
        "id": "HAN",
        "name": "HANSA"
    },
    "HAW": {
        "id": "HAW",
        "name": "HAWK SERIES"
    },
    "HEA": {
        "id": "HEA",
        "name": "HEALY"
    },
    "HER": {
        "id": "HER",
        "name": "HERALD"
    },
    "HGH": {
        "id": "HGH",
        "name": "HIGHLANDER"
    },
    "HHR": {
        "id": "HHR",
        "name": "HHR"
    },
    "HIA": {
        "id": "HIA",
        "name": "HIACE"
    },
    "HLX": {
        "id": "HLX",
        "name": "HI-LUX"
    },
    "HRV": {
        "id": "HRV",
        "name": "HRV"
    },
    "HU2": {
        "id": "HU2",
        "name": "HU3"
    },
    "HU3": {
        "id": "HU3",
        "name": "HUM"
    },
    "HUM": {
        "id": "HUM",
        "name": "HUMMER"
    },
    "ION": {
        "id": "ION",
        "name": "ION"
    },
    "I3": {
        "id": "I3",
        "name": "I3"
    },
    "I30": {
        "id": "I30",
        "name": "I35"
    },
    "I8": {
        "id": "I8",
        "name": "I8"
    },
    "IA": {
        "id": "IA",
        "name": "IM"
    },
    "ILX": {
        "id": "ILX",
        "name": "ILX"
    },
    "IM": {
        "id": "IM",
        "name": "IQ"
    },
    "INS": {
        "id": "INS",
        "name": "INSIGHT"
    },
    "IS2": {
        "id": "IS2",
        "name": "IS200T"
    },
    "IS3": {
        "id": "IS3",
        "name": "IS300IS350"
    },
    "ISA": {
        "id": "ISA",
        "name": "ISABELLA"
    },
    "ISC": {
        "id": "ISC",
        "name": "ISC"
    },
    "ISE": {
        "id": "ISE",
        "name": "ISETTA"
    },
    "ISF": {
        "id": "ISF",
        "name": "IS-F"
    },
    "J10": {
        "id": "J10",
        "name": "J-10"
    },
    "J30": {
        "id": "J30",
        "name": "JX3"
    },
    "JAC": {
        "id": "JAC",
        "name": "JAC 427 COBRA"
    },
    "JAR": {
        "id": "JAR",
        "name": "JALPA"
    },
    "JCW": {
        "id": "JCW",
        "name": "JCW"
    },
    "JIM": {
        "id": "JIM",
        "name": "JIMNY"
    },
    "JMY": {
        "id": "JMY",
        "name": "JIMMY"
    },
    "JPT": {
        "id": "JPT",
        "name": "JEEPSTER"
    },
    "JTF": {
        "id": "JTF",
        "name": "JETFIRE"
    },
    "JTS": {
        "id": "JTS",
        "name": "JETSTAR"
    },
    "JUK": {
        "id": "JUK",
        "name": "JUKE"
    },
    "JUS": {
        "id": "JUS",
        "name": "JUSTY"
    },
    "KOM": {
        "id": "KOM",
        "name": "KOMBI"
    },
    "K10": {
        "id": "K10",
        "name": "K10"
    },
    "K20": {
        "id": "K20",
        "name": "K20"
    },
    "K30": {
        "id": "K30",
        "name": "K30"
    },
    "K90": {
        "id": "K90",
        "name": "K900"
    },
    "KAD": {
        "id": "KAD",
        "name": "KADETTE"
    },
    "KAP": {
        "id": "KAP",
        "name": "KAPITAN"
    },
    "KAR": {
        "id": "KAR",
        "name": "KARMANN GHIA"
    },
    "KIN": {
        "id": "KIN",
        "name": "KINGSWOOD"
    },
    "KIZ": {
        "id": "KIZ",
        "name": "KIZASHI"
    },
    "KR": {
        "id": "KR",
        "name": "KR200"
    },
    "KR1": {
        "id": "KR1",
        "name": "KR201"
    },
    "LOY": {
        "id": "LOY",
        "name": "LOYALE"
    },
    "L37": {
        "id": "L37",
        "name": "L37"
    },
    "L43": {
        "id": "L43",
        "name": "LS430"
    },
    "L45": {
        "id": "L45",
        "name": "LX450"
    },
    "L46": {
        "id": "L46",
        "name": "LS460"
    },
    "L47": {
        "id": "L47",
        "name": "LX470"
    },
    "L57": {
        "id": "L57",
        "name": "LX570"
    },
    "L6": {
        "id": "L6",
        "name": "L6"
    },
    "L60": {
        "id": "L60",
        "name": "LS600HL"
    },
    "L7": {
        "id": "L7",
        "name": "L7"
    },
    "LAF": {
        "id": "LAF",
        "name": "LAFAYETTE"
    },
    "LAG": {
        "id": "LAG",
        "name": "LAGONDA"
    },
    "LAN": {
        "id": "LAN",
        "name": "LANDALL"
    },
    "LAR": {
        "id": "LAR",
        "name": "LARK SERIES"
    },
    "LBN": {
        "id": "LBN",
        "name": "LASER"
    },
    "LBY": {
        "id": "LBY",
        "name": "LIBERTY"
    },
    "LC5": {
        "id": "LC5",
        "name": "LC500"
    },
    "LCR": {
        "id": "LCR",
        "name": "LACROSSE"
    },
    "LATER": {
        "id": "LATER",
        "name": "LE BARON"
    },
    "LX": {
        "id": "LX",
        "name": "LE CAR"
    },
    "LEO": {
        "id": "LEO",
        "name": "LEONE GL COUPE"
    },
    "LEF": {
        "id": "LEF",
        "name": "LEAF"
    },
    "LES": {
        "id": "LES",
        "name": "LE SABRE"
    },
    "LFA": {
        "id": "LFA",
        "name": "LF-ALS400"
    },
    "LHS": {
        "id": "LHS",
        "name": "LHS"
    },
    "LID": {
        "id": "LID",
        "name": "LIDO"
    },
    "LMA": {
        "id": "LMA",
        "name": "LUMINA APV"
    },
    "LN7": {
        "id": "LN7",
        "name": "LYN"
    },
    "LR": {
        "id": "LR",
        "name": "LAND ROVER"
    },
    "LR3": {
        "id": "LR3",
        "name": "LR3"
    },
    "LR4": {
        "id": "LR4",
        "name": "LR4"
    },
    "LS6": {
        "id": "LS6",
        "name": "LS8"
    },
    "LTD": {
        "id": "LTD",
        "name": "LT2"
    },
    "LUM": {
        "id": "LUM",
        "name": "LUMINA"
    },
    "LUV": {
        "id": "LUV",
        "name": "LUV"
    },
    "LW": {
        "id": "LW",
        "name": "LW-WAGON"
    },
    "MOC": {
        "id": "MOC",
        "name": "MONTE CARLO"
    },
    "MOD": {
        "id": "MOD",
        "name": "MODEL A"
    },
    "M10": {
        "id": "M10",
        "name": "M10"
    },
    "M14": {
        "id": "M14",
        "name": "M14"
    },
    "M15": {
        "id": "M15",
        "name": "M15"
    },
    "M2": {
        "id": "M2",
        "name": "M2M"
    },
    "M3": {
        "id": "M3",
        "name": "M3"
    },
    "M30": {
        "id": "M30",
        "name": "M35"
    },
    "M37": {
        "id": "M37",
        "name": "M45"
    },
    "M4": {
        "id": "M4",
        "name": "M4"
    },
    "M40": {
        "id": "M40",
        "name": "M400"
    },
    "M45": {
        "id": "M45",
        "name": "M56"
    },
    "M5": {
        "id": "M5",
        "name": "M5"
    },
    "M6": {
        "id": "M6",
        "name": "M6"
    },
    "M60": {
        "id": "M60",
        "name": "M60"
    },
    "MEA": {
        "id": "MEA",
        "name": "MAGNUM"
    },
    "MAV": {
        "id": "MAV",
        "name": "MAINLINE"
    },
    "MAL": {
        "id": "MAL",
        "name": "MALIBU"
    },
    "MNR": {
        "id": "MNR",
        "name": "MARAUDER"
    },
    "MTX": {
        "id": "MTX",
        "name": "MARK II"
    },
    "MKL": {
        "id": "MKL",
        "name": "MARK IV"
    },
    "MK5": {
        "id": "MK5",
        "name": "MARK LT"
    },
    "MK6": {
        "id": "MK6",
        "name": "MARK V"
    },
    "MK7": {
        "id": "MK7",
        "name": "MARK VI"
    },
    "MK8": {
        "id": "MK8",
        "name": "MARK VII"
    },
    "MAT": {
        "id": "MAT",
        "name": "MARLIN"
    },
    "MRV": {
        "id": "MRV",
        "name": "MATIZ"
    },
    "MIRAI": {
        "id": "MIRAI",
        "name": "MATRIX"
    },
    "MOA": {
        "id": "MOA",
        "name": "MAVERICK"
    },
    "MZ3": {
        "id": "MZ3",
        "name": "MAZDA 2"
    },
    "MZ5": {
        "id": "MZ5",
        "name": "MAZDA 3"
    },
    "MZ6": {
        "id": "MZ6",
        "name": "MAZDA 5"
    },
    "MIA": {
        "id": "MIA",
        "name": "MAZDA 6"
    },
    "MC1": {
        "id": "MC1",
        "name": "MC1"
    },
    "MER": {
        "id": "MER",
        "name": "MC12"
    },
    "MCB": {
        "id": "MCB",
        "name": "MICROBUS OR BUS"
    },
    "MDX": {
        "id": "MDX",
        "name": "MDX"
    },
    "MLN": {
        "id": "MLN",
        "name": "MEDALIST"
    },
    "MEX": {
        "id": "MEX",
        "name": "MERAK"
    },
    "METRO": {
        "id": "METRO",
        "name": "MONTANA"
    },
    "MGO": {
        "id": "MGO",
        "name": "MONTEGO"
    },
    "MAG": {
        "id": "MAG",
        "name": "MAGNETTE"
    },
    "MGA": {
        "id": "MGA",
        "name": "MGA"
    },
    "MGB": {
        "id": "MGB",
        "name": "MGB"
    },
    "MGC": {
        "id": "MGC",
        "name": "MGC"
    },
    "MGG": {
        "id": "MGG",
        "name": "MGB/GT"
    },
    "MGT": {
        "id": "MGT",
        "name": "MGC/GT"
    },
    "MID": {
        "id": "MID",
        "name": "MIDGET"
    },
    "MOY": {
        "id": "MOY",
        "name": "MIGI"
    },
    "MII": {
        "id": "MII",
        "name": "MINI SERIES"
    },
    "MNH": {
        "id": "MNH",
        "name": "MILAN"
    },
    "MKS": {
        "id": "MKS",
        "name": "MKT"
    },
    "MKX": {
        "id": "MKX",
        "name": "MKZ"
    },
    "ML3": {
        "id": "ML3",
        "name": "ML250 BLUE TEC"
    },
    "ML4": {
        "id": "ML4",
        "name": "ML400"
    },
    "M50": {
        "id": "M50",
        "name": "ML430"
    },
    "ML5": {
        "id": "ML5",
        "name": "ML500"
    },
    "M55": {
        "id": "M55",
        "name": "ML55"
    },
    "MUS": {
        "id": "MUS",
        "name": "MODEL T"
    },
    "MTN": {
        "id": "MTN",
        "name": "MONTEREY"
    },
    "MYS": {
        "id": "MYS",
        "name": "MOUNTAINEER"
    },
    "MPV": {
        "id": "MPV",
        "name": "MX3"
    },
    "MR2": {
        "id": "MR2",
        "name": "MR2"
    },
    "MRY": {
        "id": "MRY",
        "name": "MANTA RAY"
    },
    "MTZ": {
        "id": "MTZ",
        "name": "MATIZMTA"
    },
    "MUR": {
        "id": "MUR",
        "name": "MURANO"
    },
    "MX3": {
        "id": "MX3",
        "name": "MX6"
    },
    "N0V": {
        "id": "N0V",
        "name": "NOVA"
    },
    "N20": {
        "id": "N20",
        "name": "NV200"
    },
    "NEO": {
        "id": "NEO",
        "name": "NEON"
    },
    "NIT": {
        "id": "NIT",
        "name": "NEON"
    },
    "NEW": {
        "id": "NEW",
        "name": "NEW YORKER"
    },
    "NIA": {
        "id": "NIA",
        "name": "NIAGARA"
    },
    "NRO": {
        "id": "NRO",
        "name": "NIRO"
    },
    "NSX": {
        "id": "NSX",
        "name": "NSX"
    },
    "NV1": {
        "id": "NV1",
        "name": "NV1500"
    },
    "NV2": {
        "id": "NV2",
        "name": "NV2500"
    },
    "NV3": {
        "id": "NV3",
        "name": "NV3500"
    },
    "NX": {
        "id": "NX",
        "name": "NX"
    },
    "NX2": {
        "id": "NX2",
        "name": "NC200T"
    },
    "NX3": {
        "id": "NX3",
        "name": "NX300H"
    },
    "POL": {
        "id": "POL",
        "name": "POLO"
    },
    "PAL": {
        "id": "PAL",
        "name": "PALINURO"
    },
    "PHO": {
        "id": "PHO",
        "name": "PARISIENNE"
    },
    "PUR": {
        "id": "PUR",
        "name": "PASSION"
    },
    "PAT": {
        "id": "PAT",
        "name": "PATRIOT"
    },
    "PRD": {
        "id": "PRD",
        "name": "PATRICIAN"
    },
    "PCM": {
        "id": "PCM",
        "name": "PACEMAN"
    },
    "PL4": {
        "id": "PL4",
        "name": "PLUS 4 SERIES"
    },
    "PL8": {
        "id": "PL8",
        "name": "PLUS 8 SERIES"
    },
    "PLA": {
        "id": "PLA",
        "name": "PLAZA"
    },
    "PNT": {
        "id": "PNT",
        "name": "POINTER"
    },
    "PRV": {
        "id": "PRV",
        "name": "PREVIA"
    },
    "PTH": {
        "id": "PTH",
        "name": "PATHFINDER"
    },
    "Q1": {
        "id": "Q1",
        "name": "Q1"
    },
    "Q3": {
        "id": "Q3",
        "name": "Q3"
    },
    "Q5": {
        "id": "Q5",
        "name": "Q5"
    },
    "Q45": {
        "id": "Q45",
        "name": "Q45"
    },
    "Q50": {
        "id": "Q50",
        "name": "Q50"
    },
    "Q60": {
        "id": "Q60",
        "name": "Q60"
    },
    "Q70": {
        "id": "Q70",
        "name": "Q70"
    },
    "QCK": {
        "id": "QCK",
        "name": "QUICK SILVER"
    },
    "QTM": {
        "id": "QTM",
        "name": "QUANTUM"
    },
    "Q56": {
        "id": "Q56",
        "name": "QX50"
    },
    "QX6": {
        "id": "QX6",
        "name": "QX56"
    },
    "QX7": {
        "id": "QX7",
        "name": "QX60"
    },
    "QX8": {
        "id": "QX8",
        "name": "QX70"
    },
    "ROA": {
        "id": "ROA",
        "name": "ROADMASTER"
    },
    "ROD": {
        "id": "ROD",
        "name": "ROADSTER"
    },
    "RON": {
        "id": "RON",
        "name": "RONDO"
    },
    "R10": {
        "id": "R10",
        "name": "R10"
    },
    "R12": {
        "id": "R12",
        "name": "R-10"
    },
    "R16": {
        "id": "R16",
        "name": "R-15"
    },
    "R17": {
        "id": "R17",
        "name": "R-16"
    },
    "R4": {
        "id": "R4",
        "name": "R-17"
    },
    "R20": {
        "id": "R20",
        "name": "R20"
    },
    "R30": {
        "id": "R30",
        "name": "RC300"
    },
    "R32": {
        "id": "R32",
        "name": "R32"
    },
    "R33": {
        "id": "R33",
        "name": "RX330"
    },
    "R50": {
        "id": "R50",
        "name": "R350"
    },
    "R5": {
        "id": "R5",
        "name": "R-5"
    },
    "R8": {
        "id": "R8",
        "name": "R-8"
    },
    "RA": {
        "id": "RA",
        "name": "RAMBLER AMERICAN"
    },
    "RAB": {
        "id": "RAB",
        "name": "RABBIT"
    },
    "RAG": {
        "id": "RAG",
        "name": "RAGE"
    },
    "RVR": {
        "id": "RVR",
        "name": "RAIDER"
    },
    "RAL": {
        "id": "RAL",
        "name": "RALLYE"
    },
    "RAM": {
        "id": "RAM",
        "name": "RAMBLER"
    },
    "R55": {
        "id": "R55",
        "name": "RAM 4500"
    },
    "RCH": {
        "id": "RCH",
        "name": "RAM 5500"
    },
    "R0Y": {
        "id": "R0Y",
        "name": "RAM CHARGER"
    },
    "RAP": {
        "id": "RAP",
        "name": "RAPIER"
    },
    "RAV": {
        "id": "RAV",
        "name": "RAV4"
    },
    "RC": {
        "id": "RC",
        "name": "RAMBLER CLASSIC"
    },
    "RC2": {
        "id": "RC2",
        "name": "RC200T"
    },
    "RC3": {
        "id": "RC3",
        "name": "RC350"
    },
    "RCF": {
        "id": "RCF",
        "name": "RC F"
    },
    "RCV": {
        "id": "RCV",
        "name": "RAM MPV CARGO VAN"
    },
    "RDS": {
        "id": "RDS",
        "name": "ROADSTER"
    },
    "RDV": {
        "id": "RDV",
        "name": "RENDEZVOUS"
    },
    "RDX": {
        "id": "RDX",
        "name": "RDX"
    },
    "REA": {
        "id": "REA",
        "name": "REATTA"
    },
    "REB": {
        "id": "REB",
        "name": "REBEL"
    },
    "REK": {
        "id": "REK",
        "name": "REKORDVCT3000 ME"
    },
    "REL": {
        "id": "REL",
        "name": "RELIANT"
    },
    "RK": {
        "id": "RK",
        "name": "RGT"
    },
    "RI0": {
        "id": "RI0",
        "name": "RIO"
    },
    "RIC": {
        "id": "RIC",
        "name": "RICHELIEU"
    },
    "RID": {
        "id": "RID",
        "name": "RIDEAU"
    },
    "RIV": {
        "id": "RIV",
        "name": "RIVIERA"
    },
    "RT1": {
        "id": "RT1",
        "name": "RK COUPE & SPYDER"
    },
    "RL": {
        "id": "RL",
        "name": "RL"
    },
    "RLX": {
        "id": "RLX",
        "name": "RLX"
    },
    "RN0": {
        "id": "RN0",
        "name": "RENO"
    },
    "RNR": {
        "id": "RNR",
        "name": "RANIER"
    },
    "RPD": {
        "id": "RPD",
        "name": "RAPIDE"
    },
    "RR": {
        "id": "RR",
        "name": "RAMBLER ROGUE"
    },
    "RRU": {
        "id": "RRU",
        "name": "ROAD RUNNER"
    },
    "RRV": {
        "id": "RRV",
        "name": "RANGE ROVER"
    },
    "RS3": {
        "id": "RS3",
        "name": "RS4"
    },
    "RS4": {
        "id": "RS4",
        "name": "RS5"
    },
    "RS5": {
        "id": "RS5",
        "name": "RS6"
    },
    "RS6": {
        "id": "RS6",
        "name": "RS7"
    },
    "RSX": {
        "id": "RSX",
        "name": "RSX"
    },
    "RTN": {
        "id": "RTN",
        "name": "ROUTAN"
    },
    "RX": {
        "id": "RX",
        "name": "RX"
    },
    "RX2": {
        "id": "RX2",
        "name": "RX3"
    },
    "RX3": {
        "id": "RX3",
        "name": "RX300"
    },
    "RX4": {
        "id": "RX4",
        "name": "RX4"
    },
    "RX7": {
        "id": "RX7",
        "name": "RX7"
    },
    "RX8": {
        "id": "RX8",
        "name": "RX8"
    },
    "SOM": {
        "id": "SOM",
        "name": "SOMERSET"
    },
    "SOR": {
        "id": "SOR",
        "name": "SORENTO"
    },
    "S10": {
        "id": "S10",
        "name": "S10"
    },
    "S14": {
        "id": "S14",
        "name": "S14"
    },
    "S15": {
        "id": "S15",
        "name": "S15"
    },
    "S20": {
        "id": "S20",
        "name": "S2000"
    },
    "S33": {
        "id": "S33",
        "name": "S-33"
    },
    "S4": {
        "id": "S4",
        "name": "S4"
    },
    "S60": {
        "id": "S60",
        "name": "S60"
    },
    "S70": {
        "id": "S70",
        "name": "S70"
    },
    "S80": {
        "id": "S80",
        "name": "S80"
    },
    "S90": {
        "id": "S90",
        "name": "S90"
    },
    "SAF": {
        "id": "SAF",
        "name": "SAFARI"
    },
    "SAR": {
        "id": "SAR",
        "name": "SALON"
    },
    "SAM": {
        "id": "SAM",
        "name": "SAMURAI"
    },
    "SAP": {
        "id": "SAP",
        "name": "SAPPORO"
    },
    "SAT": {
        "id": "SAT",
        "name": "SATELLITE"
    },
    "SAV": {
        "id": "SAV",
        "name": "SAVANA"
    },
    "SAX": {
        "id": "SAX",
        "name": "SAXON"
    },
    "SB": {
        "id": "SB",
        "name": "SQUAREBACKSUR"
    },
    "SC": {
        "id": "SC",
        "name": "SC"
    },
    "S43": {
        "id": "S43",
        "name": "SC400"
    },
    "SCA": {
        "id": "SCA",
        "name": "SCAMP"
    },
    "SNI": {
        "id": "SNI",
        "name": "SCEPTRE"
    },
    "SCI": {
        "id": "SCI",
        "name": "SCIROCCO"
    },
    "S0N": {
        "id": "S0N",
        "name": "SCOUPE"
    },
    "SDK": {
        "id": "SDK",
        "name": "SIDEKICK"
    },
    "SE": {
        "id": "SE",
        "name": "SE"
    },
    "SHM": {
        "id": "SHM",
        "name": "SEBRING"
    },
    "SED": {
        "id": "SED",
        "name": "SEDONA"
    },
    "SHA": {
        "id": "SHA",
        "name": "SENECA"
    },
    "SEP": {
        "id": "SEP",
        "name": "SEPHIA"
    },
    "SER": {
        "id": "SER",
        "name": "SILVER SERAPH"
    },
    "SEN": {
        "id": "SEN",
        "name": "SE-V6"
    },
    "SFT": {
        "id": "SFT",
        "name": "SWIFT"
    },
    "SPY": {
        "id": "SPY",
        "name": "SHAMAL"
    },
    "SIC": {
        "id": "SIC",
        "name": "SILVER CLOUD"
    },
    "SID": {
        "id": "SID",
        "name": "SILVER DAWN"
    },
    "SIG": {
        "id": "SIG",
        "name": "SIGNET"
    },
    "SIL": {
        "id": "SIL",
        "name": "SILHOUETTE"
    },
    "SIS": {
        "id": "SIS",
        "name": "SILVER SHADOW"
    },
    "SIW": {
        "id": "SIW",
        "name": "SILVER WRAITH"
    },
    "SKH": {
        "id": "SKH",
        "name": "SKYHAWK"
    },
    "SKT": {
        "id": "SKT",
        "name": "SKIS AND TRACKS"
    },
    "SKW": {
        "id": "SKW",
        "name": "SKIS AND WHEELS"
    },
    "SL": {
        "id": "SL",
        "name": "SL"
    },
    "SL5": {
        "id": "SL5",
        "name": "SL400"
    },
    "SL6": {
        "id": "SL6",
        "name": "SL550"
    },
    "SL2": {
        "id": "SL2",
        "name": "SL63 AMG"
    },
    "SK2": {
        "id": "SK2",
        "name": "SLK230"
    },
    "SL3": {
        "id": "SL3",
        "name": "SLK32"
    },
    "SLV": {
        "id": "SLV",
        "name": "SILVERADO"
    },
    "SLX": {
        "id": "SLX",
        "name": "SLX"
    },
    "SMT": {
        "id": "SMT",
        "name": "SMART CITY VEHICLE"
    },
    "SNA": {
        "id": "SNA",
        "name": "SIENNA"
    },
    "SP6": {
        "id": "SP6",
        "name": "SPORT 6"
    },
    "SPC": {
        "id": "SPC",
        "name": "SPECTRA"
    },
    "SQU": {
        "id": "SQU",
        "name": "SPECIAL"
    },
    "SPR": {
        "id": "SPR",
        "name": "SPIRIT"
    },
    "SPK": {
        "id": "SPK",
        "name": "SPARK"
    },
    "SPN": {
        "id": "SPN",
        "name": "SPRINT"
    },
    "SPS": {
        "id": "SPS",
        "name": "SPORTS SEDAN"
    },
    "SPT": {
        "id": "SPT",
        "name": "SPORT"
    },
    "SPW": {
        "id": "SPW",
        "name": "SPARROW"
    },
    "SQ5": {
        "id": "SQ5",
        "name": "S90"
    },
    "SQA": {
        "id": "SQA",
        "name": "SEQUOIA"
    },
    "STN": {
        "id": "STN",
        "name": "SQUIRE"
    },
    "SR5": {
        "id": "SR5",
        "name": "SR5"
    },
    "SRA": {
        "id": "SRA",
        "name": "SIERRA"
    },
    "SRX": {
        "id": "SRX",
        "name": "SRX"
    },
    "SS": {
        "id": "SS",
        "name": "SS"
    },
    "SSK": {
        "id": "SSK",
        "name": "SSK300"
    },
    "STD": {
        "id": "STD",
        "name": "STANDARD"
    },
    "STM": {
        "id": "STM",
        "name": "STYLE MASTER"
    },
    "SWI": {
        "id": "SWI",
        "name": "STRATUS"
    },
    "STS": {
        "id": "STS",
        "name": "STS"
    },
    "STV": {
        "id": "STV",
        "name": "STELVIO"
    },
    "STY": {
        "id": "STY",
        "name": "STYLE LINE"
    },
    "SOV": {
        "id": "SOV",
        "name": "S-TYPE"
    },
    "SUB": {
        "id": "SUB",
        "name": "SUBURBAN"
    },
    "SUF": {
        "id": "SUF",
        "name": "SUNFIRE"
    },
    "SNF": {
        "id": "SNF",
        "name": "SUNBIRD"
    },
    "SUR": {
        "id": "SUR",
        "name": "SUNRUNNER"
    },
    "SVX": {
        "id": "SVX",
        "name": "SVX"
    },
    "SW": {
        "id": "SW",
        "name": "SW"
    },
    "SX4": {
        "id": "SX4",
        "name": "SX4"
    },
    "SYC": {
        "id": "SYC",
        "name": "SYCLONE"
    },
    "T0W": {
        "id": "T0W",
        "name": "TOWNSMAN"
    },
    "T10": {
        "id": "T10",
        "name": "T-100"
    },
    "TG0": {
        "id": "TG0",
        "name": "T-1000"
    },
    "T15": {
        "id": "T15",
        "name": "T-150"
    },
    "TAC": {
        "id": "TAC",
        "name": "TACOMA"
    },
    "TAH": {
        "id": "TAH",
        "name": "TAHOE"
    },
    "TAL": {
        "id": "TAL",
        "name": "TALBO"
    },
    "TBR": {
        "id": "TBR",
        "name": "TURBO R"
    },
    "TBZ": {
        "id": "TBZ",
        "name": "TRAIL BLAZER"
    },
    "TC": {
        "id": "TC",
        "name": "T0W"
    },
    "TER": {
        "id": "TER",
        "name": "TERCEL"
    },
    "TK": {
        "id": "TK",
        "name": "TERRANO"
    },
    "TTN": {
        "id": "TTN",
        "name": "TERRANO II"
    },
    "TIP": {
        "id": "TIP",
        "name": "TESTAROSSA"
    },
    "TF": {
        "id": "TF",
        "name": "TF SERIES"
    },
    "TGN": {
        "id": "TGN",
        "name": "THE THING"
    },
    "THU": {
        "id": "THU",
        "name": "THUNDERBIRD"
    },
    "TI": {
        "id": "TI",
        "name": "TI"
    },
    "TRG": {
        "id": "TRG",
        "name": "TIGUAN"
    },
    "TLX": {
        "id": "TLX",
        "name": "TLX"
    },
    "TNC": {
        "id": "TNC",
        "name": "TOWN & COUNTRY"
    },
    "TR0": {
        "id": "TR0",
        "name": "TROFEO"
    },
    "TR3": {
        "id": "TR3",
        "name": "TR-3"
    },
    "TR4": {
        "id": "TR4",
        "name": "TR-4"
    },
    "TR6": {
        "id": "TR6",
        "name": "TR6"
    },
    "TR7": {
        "id": "TR7",
        "name": "TR7"
    },
    "TR8": {
        "id": "TR8",
        "name": "TR8"
    },
    "TRC": {
        "id": "TRC",
        "name": "TRANSIT CONNECT"
    },
    "TRD": {
        "id": "TRD",
        "name": "TRADESMAN"
    },
    "TRK": {
        "id": "TRK",
        "name": "TRACKER"
    },
    "TRL": {
        "id": "TRL",
        "name": "TRAIL DUSTER"
    },
    "TRS": {
        "id": "TRS",
        "name": "TOURIST"
    },
    "TRW": {
        "id": "TRW",
        "name": "TRACKS AND WHEELS"
    },
    "TRX": {
        "id": "TRX",
        "name": "TRAX"
    },
    "TRZ": {
        "id": "TRZ",
        "name": "TERRAZA"
    },
    "TSX": {
        "id": "TSX",
        "name": "TSX"
    },
    "TTS": {
        "id": "TTS",
        "name": "TTRS"
    },
    "TUN": {
        "id": "TUN",
        "name": "TUNDRA"
    },
    "TUS": {
        "id": "TUS",
        "name": "TUSCAN"
    },
    "U10": {
        "id": "U10",
        "name": "U100"
    },
    "UN0": {
        "id": "UN0",
        "name": "ULYSSES"
    },
    "V0L": {
        "id": "V0L",
        "name": "VOLARE"
    },
    "V10": {
        "id": "V10",
        "name": "V20"
    },
    "V12": {
        "id": "V12",
        "name": "VAN"
    },
    "V15": {
        "id": "V15",
        "name": "V1500"
    },
    "V20": {
        "id": "V20",
        "name": "V30"
    },
    "V25": {
        "id": "V25",
        "name": "V2500"
    },
    "V30": {
        "id": "V30",
        "name": "VEG"
    },
    "V35": {
        "id": "V35",
        "name": "V3500"
    },
    "V40": {
        "id": "V40",
        "name": "V40"
    },
    "V50": {
        "id": "V50",
        "name": "V50"
    },
    "V60": {
        "id": "V60",
        "name": "V60"
    },
    "V70": {
        "id": "V70",
        "name": "V70"
    },
    "V90": {
        "id": "V90",
        "name": "V90"
    },
    "VAR": {
        "id": "VAR",
        "name": "VANAGON"
    },
    "VEC": {
        "id": "VEC",
        "name": "VECTOR"
    },
    "VEL": {
        "id": "VEL",
        "name": "VELOX"
    },
    "VCZ": {
        "id": "VCZ",
        "name": "VELOSTER"
    },
    "VIB": {
        "id": "VIB",
        "name": "VENTURA"
    },
    "VER": {
        "id": "VER",
        "name": "VERANO"
    },
    "VIG": {
        "id": "VIG",
        "name": "VIGOR"
    },
    "VIL": {
        "id": "VIL",
        "name": "VILLAGERMED"
    },
    "VIP": {
        "id": "VIP",
        "name": "VIP"
    },
    "VIS": {
        "id": "VIS",
        "name": "VISTA CRUISER1900"
    },
    "VIV": {
        "id": "VIV",
        "name": "VIVA"
    },
    "VIX": {
        "id": "VIX",
        "name": "VIXEN"
    },
    "VLR": {
        "id": "VLR",
        "name": "VELAR"
    },
    "VNQ": {
        "id": "VNQ",
        "name": "VANQUISH"
    },
    "VNZ": {
        "id": "VNZ",
        "name": "VENZA"
    },
    "VRN": {
        "id": "VRN",
        "name": "VERONA"
    },
    "VUE": {
        "id": "VUE",
        "name": "VUE"
    },
    "VYR": {
        "id": "VYR",
        "name": "VEYRON"
    },
    "WES": {
        "id": "WES",
        "name": "WESTMINSTER"
    },
    "WHE": {
        "id": "WHE",
        "name": "WHEELS ONLY"
    },
    "WIL": {
        "id": "WIL",
        "name": "WILDCAT"
    },
    "WIN": {
        "id": "WIN",
        "name": "WINDSTAR"
    },
    "WRA": {
        "id": "WRA",
        "name": "WRAITH"
    },
    "WRG": {
        "id": "WRG",
        "name": "WRANGLER"
    },
    "WRX": {
        "id": "WRX",
        "name": "WRX"
    },
    "X1": {
        "id": "X1",
        "name": "X1"
    },
    "X3": {
        "id": "X3",
        "name": "X3"
    },
    "X4": {
        "id": "X4",
        "name": "X4"
    },
    "X5": {
        "id": "X5",
        "name": "X5"
    },
    "X6": {
        "id": "X6",
        "name": "X6"
    },
    "X90": {
        "id": "X90",
        "name": "X-90"
    },
    "XA": {
        "id": "XA",
        "name": "XB"
    },
    "XB": {
        "id": "XB",
        "name": "XD"
    },
    "XBA": {
        "id": "XBA",
        "name": "XEBRA"
    },
    "XC6": {
        "id": "XC6",
        "name": "XC60"
    },
    "XC7": {
        "id": "XC7",
        "name": "XC70"
    },
    "XC9": {
        "id": "XC9",
        "name": "XC90"
    },
    "XE": {
        "id": "XE",
        "name": "XF"
    },
    "XF": {
        "id": "XF",
        "name": "XJ"
    },
    "XJ4": {
        "id": "XJ4",
        "name": "XJ12"
    },
    "XJ8": {
        "id": "XJ8",
        "name": "XJC"
    },
    "XJC": {
        "id": "XJC",
        "name": "XJL"
    },
    "XJL": {
        "id": "XJL",
        "name": "XJR"
    },
    "XJR": {
        "id": "XJR",
        "name": "XJS"
    },
    "XK": {
        "id": "XK",
        "name": "XJS"
    },
    "XK8": {
        "id": "XK8",
        "name": "XKR"
    },
    "XL": {
        "id": "XL",
        "name": "XL"
    },
    "XL7": {
        "id": "XL7",
        "name": "XL-7"
    },
    "XLR": {
        "id": "XLR",
        "name": "XT5"
    },
    "XRT": {
        "id": "XRT",
        "name": "XRT"
    },
    "XS5": {
        "id": "XS5",
        "name": "XS500"
    },
    "XT5": {
        "id": "XT5",
        "name": "XTS"
    },
    "XT6": {
        "id": "XT6",
        "name": "XT6"
    },
    "XTC": {
        "id": "XTC",
        "name": "XT COUPE"
    },
    "XV2": {
        "id": "XV2",
        "name": "XV4"
    },
    "YAR": {
        "id": "YAR",
        "name": "YARIS"
    },
    "YUG": {
        "id": "YUG",
        "name": "YUGO"
    },
    "YUK": {
        "id": "YUK",
        "name": "YUKON"
    },
    "Z3": {
        "id": "Z3",
        "name": "Z3"
    },
    "Z4": {
        "id": "Z4",
        "name": "Z4"
    },
    "Z8": {
        "id": "Z8",
        "name": "Z8"
    },
    "ZDX": {
        "id": "ZDX",
        "name": "ZDX164"
    },
    "ZEN": {
        "id": "ZEN",
        "name": "ZENN"
    },
    "ZEP": {
        "id": "ZEP",
        "name": "ZEPHYR"
    }
};

export const LicenseStatusEnum = {
    "Active": {
        "id": "Active",
        "name": "Active"
    },
    "Expired": {
        "id": "Expired",
        "name": "Expired"
    },
    "Suspended": {
        "id": "Suspended",
        "name": "Suspended"
    }
};

export const FaceMatchResultTypeEnum = {
    "CandidateList": {
        "id": "CandidateList",
        "name": "CandidateList"
    },
    "ClosestMatch": {
        "id": "ClosestMatch",
        "name": "ClosestMatch"
    },
    "ExactMatch": {
        "id": "ExactMatch",
        "name": "ExactMatch"
    }
};

export const ConversationParticipantTypeEnum = {
    "User": {
        "id": "User",
        "name": "User"
    },
    "Division": {
        "id": "Division",
        "name": "Division"
    }
};

export const PurposeCodeEnum = {
    "Applicant": {
        "code": "A",
        "id": "A",
        "name": "Applicant"
    },
    "CriminalJustice": {
        "code": "C",
        "id": "C",
        "name": "CriminalJustice"
    },
    "Employment": {
        "code": "E",
        "id": "E",
        "name": "Employment"
    },
    "FirearmSales": {
        "code": "F",
        "id": "F",
        "name": "FirearmSales"
    },
    "JudicalEmployment": {
        "code": "J",
        "id": "J",
        "name": "JudicalEmployment"
    },
    "WantsAndWarrants": {
        "code": "W",
        "id": "W",
        "name": "WantsAndWarrants"
    }
};

export const TattooLocationEnum = {
    "Head": {
        "id": 1,
        "name": "Head",
        "shortName": "HEAD"
    },
    "Face": {
        "id": 2,
        "name": "Face",
        "shortName": "FACE"
    },
    "Neck": {
        "id": 3,
        "name": "Neck",
        "shortName": "NECK"
    },
    "ShoulderRight": {
        "id": 4,
        "name": "Shoulder Right",
        "shortName": "SR"
    },
    "ShoulderLeft": {
        "id": 5,
        "name": "Shoulder Left",
        "shortName": "SL"
    },
    "UpperArmLeft": {
        "id": 6,
        "name": "Upper Arm Left",
        "shortName": "UAL"
    },
    "UpperArmRight": {
        "id": 7,
        "name": "Upper Arm Right",
        "shortName": "UAR"
    },
    "LowerArmLeft": {
        "id": 8,
        "name": "Lower Arm Left",
        "shortName": "LAL"
    },
    "LowerArmRight": {
        "id": 9,
        "name": "Lower Arm Right",
        "shortName": "LAR"
    },
    "Hand": {
        "id": 10,
        "name": "Hand",
        "shortName": "HAND"
    },
    "Chest": {
        "id": 11,
        "name": "Chest",
        "shortName": "CHEST"
    },
    "Stomach": {
        "id": 12,
        "name": "Stomach",
        "shortName": "STOMACH"
    },
    "UpperBack": {
        "id": 13,
        "name": "Upper Back",
        "shortName": "UB"
    },
    "MidBack": {
        "id": 14,
        "name": "Mid Back",
        "shortName": "MB"
    },
    "LowerBack": {
        "id": 15,
        "name": "Lower Back",
        "shortName": "LB"
    },
    "UpperLegRight": {
        "id": 16,
        "name": "Upper Leg Right",
        "shortName": "ULR"
    },
    "UpperLegLeft": {
        "id": 17,
        "name": "Upper Leg Left",
        "shortName": "ULL"
    },
    "LowerLegRight": {
        "id": 18,
        "name": "Lower Leg Right",
        "shortName": "LLR"
    },
    "LowerLegLeft": {
        "id": 19,
        "name": "Lower Leg Left",
        "shortName": "LLL"
    },
    "FootRight": {
        "id": 20,
        "name": "Foot Right",
        "shortName": "FR"
    },
    "FootLeft": {
        "id": 21,
        "name": "Foot Left",
        "shortName": "FL"
    }
};

export const TattooColorEnum = {
    "Black": {
        "code": "",
        "id": 1,
        "name": "Black"
    },
    "Blue": {
        "code": "",
        "id": 2,
        "name": "Blue"
    },
    "Red": {
        "code": "",
        "id": 3,
        "name": "Red"
    },
    "Green": {
        "code": "",
        "id": 4,
        "name": "Green"
    },
    "Yellow": {
        "code": "",
        "id": 5,
        "name": "Yellow"
    },
    "Purple": {
        "code": "",
        "id": 6,
        "name": "Purple"
    },
    "Pink": {
        "code": "",
        "id": 7,
        "name": "Pink"
    },
    "White": {
        "code": "",
        "id": 8,
        "name": "White"
    }
};

export const AFISOptionEnum = {
    "StateId": {
        "id": 1,
        "name": "State Id"
    },
    "DrivingLicense": {
        "id": 2,
        "name": "Driving License"
    },
    "SSN": {
        "id": 3,
        "name": "SSN"
    },
    "Race": {
        "id": 4,
        "name": "Race"
    }
};

export const MustacheEnum = {
    "None": {
        "id": 1,
        "name": "None"
    },
    "Thin": {
        "id": 2,
        "name": "Thin"
    },
    "Medium": {
        "id": 3,
        "name": "Medium"
    },
    "FuManchu": {
        "id": 4,
        "name": "Fu Manchu"
    },
    "Handlebar": {
        "id": 5,
        "name": "Handlebar"
    }
};

export const BeardEnum = {
    "None": {
        "id": 1,
        "name": "None"
    },
    "Thin": {
        "id": 2,
        "name": "Thin"
    },
    "Medium": {
        "id": 3,
        "name": "Medium"
    },
    "Heavy": {
        "id": 4,
        "name": "Heavy"
    },
    "Goatee": {
        "id": 5,
        "name": "Goatee"
    }
};

export const HairColorEnum = {
    "Black": {
        "id": 1,
        "name": "Black"
    },
    "Brown": {
        "id": 2,
        "name": "Brown"
    },
    "Red": {
        "id": 3,
        "name": "Red"
    },
    "White": {
        "id": 4,
        "name": "White"
    }
};

export const HairStyleEnum = {
    "AboveEar": {
        "id": 1,
        "name": "Above ear"
    },
    "Afro": {
        "id": 2,
        "name": "Afro"
    },
    "BaldBack": {
        "id": 3,
        "name": "Bald Back"
    },
    "BaldCompletely": {
        "id": 4,
        "name": "Bald Completely"
    },
    "BaldFront": {
        "id": 5,
        "name": "Bald Front"
    },
    "BelowShoulder": {
        "id": 6,
        "name": "Below Shoulder"
    },
    "Braided": {
        "id": 7,
        "name": "Braided"
    },
    "CrewCut": {
        "id": 8,
        "name": "Crew Cut"
    },
    "Curley": {
        "id": 9,
        "name": "Curley"
    },
    "Design": {
        "id": 10,
        "name": "Design"
    },
    "EarToShoulder": {
        "id": 11,
        "name": "EarToShoulder"
    },
    "Other": {
        "id": 12,
        "name": "Other"
    },
    "Straight": {
        "id": 13,
        "name": "Straight"
    },
    "Wavy": {
        "id": 14,
        "name": "Wavy"
    },
    "PonyTail": {
        "id": 15,
        "name": "Pony Tail"
    }
};

export const BuildEnum = {
    "Emaciated": {
        "id": 1,
        "name": "Emaciated"
    },
    "Light": {
        "id": 2,
        "name": "Light"
    },
    "Medium": {
        "id": 3,
        "name": "Medium"
    },
    "Heavy": {
        "id": 4,
        "name": "Heavy"
    },
    "Obese": {
        "id": 5,
        "name": "Obese"
    }
};

export const EyesightEnum = {
    "Normal": {
        "id": 1,
        "name": "Normal"
    },
    "Glasses": {
        "id": 2,
        "name": "Glasses"
    }
};

export const IntelSubtypeEnum = {
    "Vehicles": {
        "id": 1,
        "name": "Vehicles",
        "property": "vehicles"
    },
    "Subjects": {
        "id": 2,
        "name": "Subjects",
        "property": "subjects"
    },
    "Tattoos": {
        "id": 3,
        "name": "Tattoos",
        "property": "tattoos"
    },
    "Addresses": {
        "id": 4,
        "name": "Addresses",
        "property": "addresses"
    },
    "SocialMediaProfile": {
        "id": 5,
        "name": "Social Media Profile",
        "property": "socialMediaProfiles"
    }
};

export const SocialMediaTypeEnum = {
    "All": {
        "id": 1,
        "name": "All"
    },
    "Facebook": {
        "id": 2,
        "name": "Facebook"
    },
    "Twitter": {
        "id": 3,
        "name": "Twitter"
    },
    "Instagram": {
        "id": 4,
        "name": "Instagram"
    },
    "LinkedIn": {
        "id": 5,
        "name": "LinkedIn"
    },
    "Reddit": {
        "id": 6,
        "name": "Reddit"
    },
    "Snapchat": {
        "id": 7,
        "name": "Snapchat"
    },
    "YouTube": {
        "id": 8,
        "name": "YouTube"
    },
    "GooglePlus": {
        "id": 9,
        "name": "Google +"
    },
    "Medium": {
        "id": 10,
        "name": "Medium"
    },
    "Other": {
        "id": 11,
        "name": "Other"
    }
};

export const VehicleLocationEnum = {
    "Location1": {
        "code": "Location1",
        "id": 1,
        "name": "Location 1"
    },
    "Location2": {
        "code": "Location2",
        "id": 2,
        "name": "Location 2"
    },
    "Location3": {
        "code": "Location3",
        "id": 3,
        "name": "Location 3"
    }
};

export const MobilePaymentAppTypeEnum = {
    "ApplePay": {
        "id": 1,
        "name": "Apple Pay"
    },
    "SamsungPay": {
        "id": 2,
        "name": "Samsung Pay"
    },
    "GooglePay": {
        "id": 3,
        "name": "Google Pay"
    },
    "Zelle": {
        "id": 4,
        "name": "Zelle"
    },
    "CashApp": {
        "id": 5,
        "name": "Cash App"
    },
    "Venmo": {
        "id": 6,
        "name": "Venmo"
    },
    "GyftCard": {
        "id": 7,
        "name": "Gyft Card"
    },
    "Xoom": {
        "id": 8,
        "name": "Xoom"
    },
    "Spendee": {
        "id": 9,
        "name": "Spendee"
    },
    "CirclePay": {
        "id": 10,
        "name": "Circle Pay"
    },
    "AmazonPayment": {
        "id": 11,
        "name": "Amazon Payment"
    },
    "SquareCash": {
        "id": 12,
        "name": "Square Cash"
    },
    "Paypal": {
        "id": 13,
        "name": "Paypal"
    }
};

export const CryptoCurrencyTypeEnum = {
    "Bitcoin": {
        "id": 1,
        "name": "Bitcoin",
        "shortName": "BTC"
    },
    "Ethereum": {
        "id": 2,
        "name": "Ethereum",
        "shortName": "ETH"
    },
    "Tether": {
        "id": 3,
        "name": "Tether",
        "shortName": "USDT"
    },
    "BNB": {
        "id": 4,
        "name": "BNB",
        "shortName": "BNB"
    },
    "USDCoin": {
        "id": 5,
        "name": "USD Coin",
        "shortName": "USDC"
    },
    "Cardano": {
        "id": 6,
        "name": "Cardano",
        "shortName": "ADA"
    },
    "Solana": {
        "id": 7,
        "name": "Solana",
        "shortName": "SOL"
    },
    "XRP": {
        "id": 8,
        "name": "XRP",
        "shortName": "XRP"
    },
    "Terra": {
        "id": 9,
        "name": "Terra",
        "shortName": "LUNA"
    },
    "Dogecoin": {
        "id": 10,
        "name": "Dogecoin",
        "shortName": "DOGE"
    },
    "Polkadot": {
        "id": 11,
        "name": "Polkadot",
        "shortName": "DOT"
    },
    "Avalanche": {
        "id": 12,
        "name": "Avalanche",
        "shortName": "AVAX"
    },
    "BinanceUSD": {
        "id": 13,
        "name": "Binance USD",
        "shortName": "BUSD"
    },
    "Polygon": {
        "id": 14,
        "name": "Polygon",
        "shortName": "MATIC"
    },
    "ShibaInu": {
        "id": 15,
        "name": "Shiba Inu",
        "shortName": "SHIB"
    },
    "TerraUSD": {
        "id": 16,
        "name": "TerraUSD",
        "shortName": "UST"
    },
    "Cronos": {
        "id": 17,
        "name": "Cronos",
        "shortName": "CRO"
    },
    "WrappedBitcoin": {
        "id": 18,
        "name": "Wrapped Bitcoin",
        "shortName": "WBTC"
    },
    "Dai": {
        "id": 19,
        "name": "Dai",
        "shortName": "DAI"
    },
    "Chainlink": {
        "id": 20,
        "name": "Chainlink",
        "shortName": "LINK"
    },
    "Cosmos": {
        "id": 21,
        "name": "Cosmos",
        "shortName": "ATOM"
    },
    "Litecoin": {
        "id": 22,
        "name": "Litecoin",
        "shortName": "LTC"
    },
    "Uniswap": {
        "id": 23,
        "name": "Uniswap",
        "shortName": "UNI"
    },
    "NEARProtocol": {
        "id": 24,
        "name": "NEAR Protocol",
        "shortName": "NEAR"
    },
    "Algorand": {
        "id": 25,
        "name": "Algorand",
        "shortName": "ALGO"
    },
    "TRON": {
        "id": 26,
        "name": "TRON",
        "shortName": "TRX"
    },
    "FTXToken": {
        "id": 27,
        "name": "FTX Token",
        "shortName": "FTT"
    },
    "BitcoinCash": {
        "id": 28,
        "name": "Bitcoin Cash",
        "shortName": "BCH"
    },
    "Fantom": {
        "id": 29,
        "name": "Fantom",
        "shortName": "FTM"
    },
    "Stellar": {
        "id": 30,
        "name": "Stellar",
        "shortName": "XLM"
    },
    "Decentraland": {
        "id": 31,
        "name": "Decentraland",
        "shortName": "MANA"
    },
    "BitcoinBEP2": {
        "id": 32,
        "name": "Bitcoin BEP2",
        "shortName": "BTCB"
    },
    "Hedera": {
        "id": 33,
        "name": "Hedera",
        "shortName": "HBAR"
    },
    "InternetComputer": {
        "id": 34,
        "name": "Internet Computer",
        "shortName": "ICP"
    },
    "TheSandbox": {
        "id": 35,
        "name": "The Sandbox",
        "shortName": "SAND"
    },
    "VeChain": {
        "id": 36,
        "name": "VeChain",
        "shortName": "VET"
    },
    "EthereumClassic": {
        "id": 37,
        "name": "Ethereum Classic",
        "shortName": "ETC"
    },
    "UNUSSEDLEO": {
        "id": 38,
        "name": "UNUS SED LEO",
        "shortName": "LEO"
    },
    "Filecoin": {
        "id": 39,
        "name": "Filecoin",
        "shortName": "FIL"
    },
    "AxieInfinity": {
        "id": 40,
        "name": "Axie Infinity",
        "shortName": "AXS"
    },
    "Klaytn": {
        "id": 41,
        "name": "Klaytn",
        "shortName": "KLAY"
    },
    "Helium": {
        "id": 42,
        "name": "Helium",
        "shortName": "HNT"
    },
    "Tezos": {
        "id": 43,
        "name": "Tezos",
        "shortName": "XTZ"
    },
    "Elrond": {
        "id": 44,
        "name": "Elrond",
        "shortName": "EGLD"
    },
    "ThetaNetwork": {
        "id": 45,
        "name": "Theta Network",
        "shortName": "THETA"
    },
    "Monero": {
        "id": 46,
        "name": "Monero",
        "shortName": "XMR"
    },
    "IOTA": {
        "id": 47,
        "name": "IOTA",
        "shortName": "MIOTA"
    },
    "EOS": {
        "id": 48,
        "name": "EOS",
        "shortName": "EOS"
    },
    "Harmony": {
        "id": 49,
        "name": "Harmony",
        "shortName": "ONE"
    },
    "Flow": {
        "id": 50,
        "name": "Flow",
        "shortName": "FLOW"
    },
    "Other": {
        "id": 51,
        "name": "Other",
        "shortName": ""
    }
};

