class LocalStorage {
  set = (key, value) => {
    localStorage.setItem(key, value);
  }

  setObj = (key, value) => {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
  }

  get = (key) => localStorage.getItem(key)

  getObj = (key) => {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  remove = (key) => {
    localStorage.removeItem(key);
  }

  removeAll = () => localStorage.clear();
}

export default new LocalStorage();
