import React from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';
import { UserContext } from 'Components/UserProvider';

export default (propName = 'user') => hoistStatics((Component) => {
  const WithUser = (props) => (
    <UserContext.Consumer>
      {user => (
        <Component
          {...props}
          {...({ [propName]: user })}
        />
      )}
    </UserContext.Consumer>
  );
  WithUser.displayName = wrapDisplayName(Component, 'withUser');
  return WithUser;
});
