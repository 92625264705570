export function validateSsn(ssn) { // eslint-disable-line
  if (!ssn) {
    return false;
  }
  // http://rion.io/2013/09/10/validating-social-security-numbers-through-regular-expressions-2/
  const ssnRegex = /^(?!219099999|078051120)(?!666|000|9\d{2})(\d{3}|\*\*\*)-?(?!00)(\d{2}|\*\*)-?(?!0{4})(\d{4}|\*\*\*\*)$/;
  return !!ssnRegex.test(ssn);
}

// Regex to Validate US Phone Number.
// eslint-ignore no-unused-vars
export function isMobilePhone(phone) {
  const regex = new RegExp('^((\\+1|1)?( |-)?)?(\\(([2-9])(?:\\d(?!\\5)\\d|(?!\\5)\\d\\d)\\)|([2-9])(?:\\d(?!\\6)\\d|(?!\\6)\\d\\d))( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$', 'gm');
  return regex.test(phone);
}

export function isValidPhone(phone) {
  const regex = new RegExp('^[\\+]?[0-9]{0,3}\\W?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$');
  return regex.test(phone);
}

export function isEmail(email) {
  const regex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$', 'gm');
  return regex.test(email);
}

export function isUrl(url) {
  const regex = new RegExp('https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,}', 'gm');
  return regex.test(url);
}
